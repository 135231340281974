.carousel-no {
  height: 580px;
  overflow: hidden;
  width: 1440px;
}

.carousel-no .overlap-group {
  height: 714px;
  left: -116px;
  position: relative;
  top: -60px;
  width: 1684px;
}

.carousel-no .rectangle {
  height: 580px;
  left: 463px;
  position: absolute;
  top: 60px;
  width: 1093px;
}

.carousel-no .div {
  background: linear-gradient(
    180deg,
    rgb(0, 0, 0) 0%,
    rgb(7, 17, 3) 25%,
    rgb(31.88, 31.88, 31.88) 44.79%,
    rgb(46.75, 46.75, 46.75) 61.46%,
    rgba(0, 0, 0, 0) 100%
  );
  filter: blur(80px);
  height: 714px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1684px;
}

.carousel-no .frame {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
  left: 196px;
  position: absolute;
  top: 242px;
}

.carousel-no .text-wrapper {
  color: #ffffff;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 35px;
  font-weight: 600;
  letter-spacing: -0.53px;
  line-height: 49px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

/* .carousel-no .frame-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
} */

.carousel-no .our-client-and {
  color: #f6f7f8;
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  margin-top: -1px;
  position: relative;
  width: 436px;
}

.carousel-no .button {
  all: unset;
  align-items: center;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.carousel-no .text-wrapper-2 {
  color: #ffffff;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 17px;
  font-weight: 700;
  letter-spacing: -0.17px;
  line-height: 23.8px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.carousel-no .img {
  height: 24px;
  position: relative;
  width: 24px;
}

.carousel-no .text-wrapper-3 {
  color: #ffffff;
  font-family: var(--h4-REG-font-family);
  font-size: var(--h4-REG-font-size);
  font-style: var(--h4-REG-font-style);
  font-weight: var(--h4-REG-font-weight);
  height: 41px;
  left: 196px;
  letter-spacing: var(--h4-REG-letter-spacing);
  line-height: var(--h4-REG-line-height);
  position: absolute;
  top: 120px;
  white-space: nowrap;
}

.carousel-no .frame-3 {
  align-items: flex-start;
  display: inline-flex;
  gap: 5px;
  left: 196px;
  position: absolute;
  top: 560px;
}

.carousel-no .ellipse {
  background-color: #d9d9d952;
  border-radius: 5.5px;
  height: 11px;
  position: relative;
  width: 11px;
}

.carousel-no .ellipse-2 {
  background-color: var(--neutral-30);
  border-radius: 5.5px;
  height: 11px;
  position: relative;
  width: 11px;
}
