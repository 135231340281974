.solutiondropdown {
    position: absolute;
    top: 50px;
    left: 0;
    z-index: 10;
    border-radius: 8px;
    background: var(--neutral-10, #F6F7F8);
    box-shadow: 4px 9px 32px 0px rgba(174, 174, 174, 0.12);
    display: flex;
    width: 224px;
    padding: 4px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
}

.solutiondropdown .nav-item {
    display: flex;
    width: 224px;
    padding: 14px 20px;
    align-items: center;
    gap: 10px;
    background: #FFF;
}

.solutiondropdown .nav-item p {
    color: var(--neutral-90, #202020);
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 19.6px */
    letter-spacing: -0.14px;
    text-transform: capitalize;
}

@media (max-width: 450px) {
    .solutiondropdown {
        top: 90px;

    }
}