.CTA-msp {
  border-radius: 56px;
  align-items: center;
  padding: 12px 16px;
  display: inline-flex;
  position: relative;
}

.CTA-msp .this-is-who-we-are {
  color: #fff;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: relative;
}

.CTA-msp .arrow-forward {
  width: 24px;
  height: 24px;
  position: relative;
}

.CTA-msp.hover {
  background-color: var(--primary-02);
  gap: 8px;
}

.CTA-msp.default {
  border: 1px solid #fff;
  gap: 10px;
  transition: all .3s ease-in-out;
}

.CTA-msp.default:hover {
  gap: 8px;
}

.state-default-wrapper .text-wrapper-4-drop {
  color: var(--secondary-03);
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: relative;
}

.state-default-wrapper:hover .text-wrapper-4-drop {
  color: #fff;
}

.mobile-nav {
  z-index: 1111;
  width: 390px;
  height: 844px;
  position: fixed;
  top: 0;
}

.mobile-nav .div-4-drop {
  background-color: var(--shadeswhite);
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 390px;
  height: 100%;
  padding: 32px;
  display: flex;
  overflow-y: scroll;
}

.mobile-nav .frame-94-drop {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.mobile-nav .logo-4-drop {
  object-fit: cover;
  width: 100px;
  height: 47px;
  position: relative;
}

.mobile-nav .close-drop {
  width: 32px;
  position: relative;
}

.mobile-nav .holder-wrapper-drop {
  justify-content: space-around;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  display: flex;
  position: relative;
}

.mobile-nav .holder-drop {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 16px;
  display: flex;
  position: relative;
}

.mobile-nav .frame-95-drop {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  padding: 10px 0;
  display: flex;
  position: relative;
}

.mobile-nav .frame-95-dropdown {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 10px 0;
  display: flex;
  position: relative;
}

.mobile-nav .dropdown-wrapper {
  flex-direction: column;
  align-items: center;
  gap: 16px;
  display: flex;
}

.mobile-nav .dropdown-wrapper .submenu {
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-top: 16px;
  display: flex;
}

.mobile-nav .dropdown-text-wrapper {
  color: #000;
  letter-spacing: -.18px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
  position: relative;
}

.mobile-nav .text-wrapper-75-drop {
  color: #000;
  letter-spacing: -.18px;
  white-space: nowrap;
  background: none;
  border: none;
  outline: none;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 18px;
  font-weight: 600;
  line-height: 25.2px;
  position: relative;
}

.mobile-nav .design-component-instance-node {
  border-color: var(--primary-02) !important;
  align-self: stretch !important;
  width: 100% !important;
  height: 69px !important;
  display: flex !important;
}

.mobile-nav .CTA-14-drop {
  color: #000 !important;
  letter-spacing: -.18px !important;
  margin-top: unset !important;
  font-size: 18px !important;
  line-height: 25.2px !important;
}

.mobile-na {
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  width: 390px;
  height: 60px;
  padding: 16px;
  position: absolute;
  top: 0;
  display: flex !important;
}

.mobile-na .logo-mobile {
  width: 60px;
  height: 30px;
}

.mobile-na button {
  background: none;
  border: none;
  outline: none;
}

.moile-na .menu-mobile {
  width: 32px;
  height: 32px;
}

.msp {
  background-color: #fff;
  flex-direction: row;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.msp .carousel {
  width: 100%;
  height: 450px;
}

.msp .div-2 {
  background-color: #fff;
  position: relative;
}

.msp .header {
  width: 390px !important;
  height: 60px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.msp .footer {
  background-color: var(--white);
  width: 390px;
  height: 860px;
  position: absolute;
  top: 4595px;
  left: 0;
  overflow: hidden;
}

.msp .overlap-group-2 {
  width: 390px;
  height: 907px;
  position: relative;
}

.msp .frame-4 {
  background-color: var(--neutral-90);
  width: 390px;
  height: 907px;
  position: absolute;
  top: 0;
  left: 0;
}

.msp .frame-5 {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: absolute;
  top: 115px;
  left: 26px;
}

.msp .text-wrapper-5 {
  color: #fff;
  font-family: var(--paragraph-ALCPS-font-family);
  font-size: var(--paragraph-ALCPS-font-size);
  font-style: var(--paragraph-ALCPS-font-style);
  font-weight: var(--paragraph-ALCPS-font-weight);
  letter-spacing: var(--paragraph-ALCPS-letter-spacing);
  line-height: var(--paragraph-ALCPS-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.msp .p {
  color: #f6f7f8;
  font-family: var(--paragraph-REG-font-family);
  font-size: var(--paragraph-REG-font-size);
  font-style: var(--paragraph-REG-font-style);
  font-weight: var(--paragraph-REG-font-weight);
  letter-spacing: var(--paragraph-REG-letter-spacing);
  line-height: var(--paragraph-REG-line-height);
  width: 324px;
  position: relative;
}

.msp .DIV {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 113px;
  display: flex;
  position: absolute;
  top: 272px;
  left: 195px;
}

.msp .text-wrapper-6 {
  color: var(--white);
  letter-spacing: -.2px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.msp .text-wrapper-7 {
  color: var(--white);
  letter-spacing: -.2px;
  white-space: nowrap;
  width: fit-content;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.msp .DIV-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 113px;
  display: flex;
  position: absolute;
  top: 272px;
  left: 26px;
}

.msp .frame-6 {
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 583px;
  left: 69px;
}

.msp .group {
  width: 37px;
  height: 52px;
  position: relative;
}

.msp .text-wrapper-8 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 38px;
  left: 1px;
}

.msp .face-book {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 0;
  left: 0;
}

.msp .frame-7 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.msp .frame-8 {
  flex: none;
  position: relative;
}

.msp .text-wrapper-9 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  width: fit-content;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: relative;
}

.msp .facebook {
  width: 35px;
  height: 35px;
  position: relative;
}

.msp .frame-9 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.msp .logo {
  width: 116px;
  height: 56px;
  position: absolute;
  top: 39px;
  left: 24px;
}

.msp .frame-wrapper {
  width: 295px;
  height: 64px;
  position: absolute;
  top: 732px;
  left: 48px;
}

.msp .frame-10 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 295px;
  display: flex;
  position: relative;
}

.msp .text-wrapper-10 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.msp .ellipse-3 {
  background-color: var(--grey-01);
  border-radius: 1px / 1.5px;
  width: 2px;
  height: 3px;
  position: relative;
}

.msp .text-wrapper-11 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  text-align: center;
  align-self: stretch;
  position: relative;
}

.msp .overlap {
  position: absolute;
  left: 0;
}

.msp .rectangle-2 {
  background-color: #e1e4e9;
  width: 1440px;
  height: 567px;
  position: absolute;
  top: 0;
  left: 0;
}

.msp .element {
  object-fit: cover;
  width: 566px;
  height: 565px;
  position: absolute;
  top: 258px;
  left: 431px;
}

.msp .frame-11 {
  justify-content: center;
  align-items: center;
  gap: 392px;
  display: inline-flex;
  position: absolute;
  top: 168px;
  left: 77px;
}

.msp .component {
  width: 437px;
  height: 180px;
  position: relative;
}

.msp .rely-on {
  color: #0000;
  letter-spacing: -.75px;
  width: 484px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 50px;
  font-weight: 400;
  line-height: 60px;
  position: absolute;
  top: -1px;
  left: 0;
}

.msp .span {
  color: #2c3e47;
}

.msp .text-wrapper-12 {
  color: #4d9f98;
}

.msp .frame-12 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: relative;
}

.msp .text-wrapper-13 {
  color: #000;
  letter-spacing: -.2px;
  width: 450px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.msp .CTA-instance {
  border: unset !important;
  border-color: unset !important;
  background-color: #4d9f98 !important;
  flex: none !important;
  justify-content: center !important;
  align-items: flex-end !important;
  width: 182px !important;
  display: flex !important;
}

.msp .instance-node {
  margin-left: -1px !important;
  font-weight: 700 !important;
}

.msp .CTA-2 {
  margin-right: -1px !important;
}

.msp .rectangle-3 {
  background-color: #12212ff0;
  width: 1440px;
  height: 929px;
  position: absolute;
  top: 526px;
  left: 0;
}

.msp .rectangle-4 {
  width: 256px;
  height: 824px;
  position: absolute;
  top: 326px;
  left: 1184px;
}

.msp .rectangle-5 {
  width: 269px;
  height: 824px;
  position: absolute;
  top: 304px;
  left: 0;
}

.msp .frame-13 {
  align-items: center;
  gap: 108px;
  display: inline-flex;
  position: absolute;
  top: 609px;
  left: 81px;
}

.msp .frame-14 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 6px;
  display: inline-flex;
  position: relative;
}

.msp .text-wrapper-14 {
  color: #fff;
  letter-spacing: -.68px;
  width: 189px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 45px;
  font-weight: 600;
  line-height: 63px;
  position: relative;
}

.msp .managing-a-company-s {
  color: #d9d9d9;
  letter-spacing: -.18px;
  width: 528px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
  position: relative;
}

.msp .div-wrapper {
  align-items: center;
  gap: 8px;
  width: 648px;
  display: flex;
  position: relative;
}

.msp .frame-15 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 12px;
  margin-right: -23px;
  display: inline-flex;
  position: relative;
}

.msp .frame-16 {
  flex: none;
  align-items: flex-start;
  gap: 59px;
  display: inline-flex;
  position: relative;
}

.msp .frame-17 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 15px;
  display: inline-flex;
  position: relative;
}

.msp .frame-18 {
  flex: none;
  align-items: center;
  gap: 17px;
  display: inline-flex;
  position: relative;
}

.msp .vector {
  width: 16px;
  height: 16px;
  position: relative;
}

.msp .frame-19 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.msp .text-wrapper-15 {
  color: var(--neutral-0);
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  width: 189px;
  margin-top: -1px;
  position: relative;
}

.msp .text-wrapper-16 {
  color: var(--neutral-0);
  letter-spacing: -.2px;
  width: 204px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.msp .div-3 {
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.msp .text-wrapper-17 {
  color: var(--neutral-0);
  letter-spacing: -.2px;
  width: 343px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.msp .procurement-and {
  color: var(--neutral-0);
  letter-spacing: -.2px;
  width: 320px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.msp .group-2 {
  background: linear-gradient(#1e454d 0%, #2967749e 100%);
  border-radius: 16px;
  width: 347px;
  height: 334px;
  position: absolute;
  top: 984px;
  left: 1013px;
}

.msp .frame-20 {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 262px;
  display: flex;
  position: absolute;
  top: 43px;
  left: 34px;
}

.msp .text-wrapper-18 {
  color: var(--neutral-40);
  letter-spacing: -.29px;
  width: 201px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 29px;
  font-weight: 600;
  line-height: 40.6px;
  position: relative;
}

.msp .frame-21 {
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 262px;
  display: flex;
  position: absolute;
  top: 104px;
  left: 34px;
}

.msp .frame-22 {
  flex: none;
  align-items: center;
  gap: 17px;
  margin-right: -40px;
  display: inline-flex;
  position: relative;
}

.msp .text-wrapper-19 {
  color: var(--neutral-40);
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  width: 269px;
  margin-top: -1px;
  position: relative;
}

.msp .text-wrapper-20 {
  color: var(--neutral-40);
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  width: 204px;
  margin-top: -1px;
  position: relative;
}

.msp .frame-23 {
  flex: none;
  align-items: center;
  gap: 17px;
  margin-right: -75px;
  display: inline-flex;
  position: relative;
}

.msp .text-wrapper-21 {
  color: var(--neutral-40);
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  width: 304px;
  margin-top: -1px;
  position: relative;
}

.msp .text-wrapper-22 {
  color: #d9d9d9;
  letter-spacing: -.82px;
  width: 196px;
  height: 40px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 33px;
  font-weight: 400;
  line-height: 39.6px;
  position: absolute;
  top: 851px;
  left: 627px;
}

.msp .group-3 {
  width: 504px;
  height: 442px;
  position: absolute;
  top: 930px;
  left: 81px;
}

.msp .overlap-2 {
  width: 146px;
  height: 168px;
  position: absolute;
  top: 274px;
  left: 344px;
}

.msp .group-4 {
  width: 22px;
  height: 135px;
  position: absolute;
  top: 0;
  left: 63px;
}

.msp .rectangle-6 {
  background-color: #f6b3b8;
  border-radius: 4px;
  width: 160px;
  height: 37px;
  position: absolute;
  top: 39px;
  left: 0;
}

.msp .rectangle-7 {
  background-color: #006682;
  border-radius: 4px;
  width: 160px;
  height: 37px;
  position: absolute;
  top: 85px;
  left: 0;
}

.msp .rectangle-8 {
  background-color: #4d9f98;
  border-radius: 4px;
  width: 160px;
  height: 36px;
  position: absolute;
  top: 132px;
  left: 0;
}

.msp .text-wrapper-23 {
  color: #212f3c;
  font-family: var(--paragraph-MED-font-family);
  font-size: var(--paragraph-MED-font-size);
  font-style: var(--paragraph-MED-font-style);
  font-weight: var(--paragraph-MED-font-weight);
  letter-spacing: var(--paragraph-MED-letter-spacing);
  line-height: var(--paragraph-MED-line-height);
  white-space: nowrap;
  width: 99px;
  height: 21px;
  position: absolute;
  top: 47px;
  left: 28px;
}

.msp .text-wrapper-24 {
  color: #fff;
  font-family: var(--paragraph-MED-font-family);
  font-size: var(--paragraph-MED-font-size);
  font-style: var(--paragraph-MED-font-style);
  font-weight: var(--paragraph-MED-font-weight);
  letter-spacing: var(--paragraph-MED-letter-spacing);
  line-height: var(--paragraph-MED-line-height);
  white-space: nowrap;
  width: 90px;
  height: 21px;
  position: absolute;
  top: 93px;
  left: 32px;
}

.msp .text-wrapper-25 {
  color: #fff;
  font-family: var(--paragraph-MED-font-family);
  font-size: var(--paragraph-MED-font-size);
  font-style: var(--paragraph-MED-font-style);
  font-weight: var(--paragraph-MED-font-weight);
  letter-spacing: var(--paragraph-MED-letter-spacing);
  line-height: var(--paragraph-MED-line-height);
  white-space: nowrap;
  width: 122px;
  height: 21px;
  position: absolute;
  top: 139px;
  left: 13px;
}

.msp .group-wrapper {
  width: 498px;
  height: 274px;
  position: absolute;
  top: 0;
  left: 0;
}

.msp .group-5 {
  width: 508px;
  height: 274px;
  position: relative;
}

.msp .overlap-3 {
  background-color: #00dfc2;
  border-radius: 4px;
  width: 220px;
  height: 49px;
  position: absolute;
  top: 0;
  left: 136px;
}

.msp .text-wrapper-26 {
  color: #212f3c;
  letter-spacing: -.29px;
  white-space: nowrap;
  width: 162px;
  height: 18px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 29px;
  font-weight: 600;
  line-height: 10px;
  position: absolute;
  top: 15px;
  left: 31px;
}

.msp .overlap-4 {
  width: 344px;
  height: 186px;
  position: absolute;
  top: 55px;
  left: 0;
}

.msp .overlap-5 {
  width: 344px;
  height: 141px;
  position: absolute;
  top: 0;
  left: 0;
}

.msp .rectangle-9 {
  background-color: #f6b3b8;
  border-radius: 4px;
  width: 165px;
  height: 32px;
  position: absolute;
  top: 103px;
  left: 0;
}

.msp .line {
  width: 192px;
  height: 3px;
  position: absolute;
  top: 118px;
  left: 152px;
}

.msp .ellipse-4 {
  object-fit: cover;
  width: 42px;
  height: 42px;
  position: absolute;
  top: 97px;
  left: 173px;
}

.msp .text-wrapper-27 {
  color: #212f3c;
  font-family: var(--subtitle-BLD-font-family);
  font-size: var(--subtitle-BLD-font-size);
  font-style: var(--subtitle-BLD-font-style);
  font-weight: var(--subtitle-BLD-font-weight);
  letter-spacing: var(--subtitle-BLD-letter-spacing);
  line-height: var(--subtitle-BLD-line-height);
  white-space: nowrap;
  width: 146px;
  height: 19px;
  position: absolute;
  top: 109px;
  left: 4px;
}

.msp .ellipse-5 {
  object-fit: cover;
  width: 42px;
  height: 42px;
  position: absolute;
  top: 99px;
  left: 277px;
}

.msp .group-6 {
  width: 22px;
  height: 119px;
  position: absolute;
  top: 0;
  left: 235px;
}

.msp .group-7 {
  width: 22px;
  height: 52px;
  position: absolute;
  top: 135px;
  left: 66px;
}

.msp .overlap-6 {
  background-color: var(--neutral-0);
  border-radius: 4px;
  width: 165px;
  height: 32px;
  position: absolute;
  top: 242px;
  left: 4px;
}

.msp .text-wrapper-28 {
  color: #212f3c;
  font-family: var(--subtitle-BLD-font-family);
  font-size: var(--subtitle-BLD-font-size);
  font-style: var(--subtitle-BLD-font-style);
  font-weight: var(--subtitle-BLD-font-weight);
  letter-spacing: var(--subtitle-BLD-letter-spacing);
  line-height: var(--subtitle-BLD-line-height);
  white-space: nowrap;
  width: 119px;
  height: 19px;
  position: absolute;
  top: 6px;
  left: 16px;
}

.msp .overlap-group-3 {
  background-color: var(--neutral-0);
  border-radius: 4px;
  width: 160px;
  height: 32px;
  position: absolute;
  top: 242px;
  left: 344px;
}

.msp .text-wrapper-29 {
  color: #212f3c;
  font-family: var(--subtitle-BLD-font-family);
  font-size: var(--subtitle-BLD-font-size);
  font-style: var(--subtitle-BLD-font-style);
  font-weight: var(--subtitle-BLD-font-weight);
  letter-spacing: var(--subtitle-BLD-letter-spacing);
  line-height: var(--subtitle-BLD-line-height);
  white-space: nowrap;
  width: 46px;
  height: 19px;
  position: absolute;
  top: 7px;
  left: 60px;
}

.msp .overlap-7 {
  width: 154px;
  height: 32px;
  position: absolute;
  top: 158px;
  left: 344px;
}

.msp .rectangle-10 {
  background-color: #f6b3b8;
  border-radius: 4px;
  width: 160px;
  height: 32px;
  position: absolute;
  top: 0;
  left: 0;
}

.msp .text-wrapper-30 {
  color: #212f3c;
  font-family: var(--subtitle-BLD-font-family);
  font-size: var(--subtitle-BLD-font-size);
  font-style: var(--subtitle-BLD-font-style);
  font-weight: var(--subtitle-BLD-font-weight);
  letter-spacing: var(--subtitle-BLD-letter-spacing);
  line-height: var(--subtitle-BLD-line-height);
  white-space: nowrap;
  width: 144px;
  height: 19px;
  position: absolute;
  top: 4px;
  left: 10px;
}

.msp .group-8 {
  width: 22px;
  height: 52px;
  position: absolute;
  top: 190px;
  left: 406px;
}

.msp .frame-24 {
  background: linear-gradient(#1e454d 0%, #22647299 100%);
  border-radius: 16px;
  width: 347px;
  height: 334px;
  position: absolute;
  top: 984px;
  left: 650px;
}

.msp .text-wrapper-31 {
  color: var(--neutral-40);
  letter-spacing: -.29px;
  width: 360px;
  height: 41px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 29px;
  font-weight: 600;
  line-height: 40.6px;
  position: absolute;
  top: 42px;
  left: 38px;
}

.msp .frame-25 {
  align-items: center;
  gap: 17px;
  display: inline-flex;
  position: absolute;
  top: 104px;
  left: 38px;
}

.msp .frame-26 {
  align-items: center;
  gap: 17px;
  display: inline-flex;
  position: absolute;
  top: 136px;
  left: 38px;
}

.msp .frame-27 {
  align-items: center;
  gap: 17px;
  display: inline-flex;
  position: absolute;
  top: 168px;
  left: 38px;
}

.msp .frame-28 {
  align-items: center;
  gap: 17px;
  display: inline-flex;
  position: absolute;
  top: 200px;
  left: 38px;
}

.msp .frame-29 {
  align-items: center;
  gap: 17px;
  display: inline-flex;
  position: absolute;
  top: 232px;
  left: 38px;
}

.msp .frame-30 {
  align-items: center;
  gap: 17px;
  display: inline-flex;
  position: absolute;
  top: 264px;
  left: 38px;
}

.msp .header-2 {
  background-color: #e1e4e9;
  justify-content: space-between;
  align-items: center;
  width: 1440px;
  padding: 16px 80px;
  display: flex;
  position: absolute;
  top: 0;
  left: 1px;
}

.msp .logo-2 {
  object-fit: cover;
  width: 145px;
  height: 67.67px;
  position: relative;
}

.msp .frame-31 {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 90.33px;
  padding: 10px 8px;
  display: flex;
  position: relative;
}

.msp .text-wrapper-32 {
  color: #000;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: relative;
}

.msp .text-wrapper-33 {
  color: #000;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-left: -2.83px;
  margin-right: -2.83px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: relative;
}

.msp .frame-32 {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 80px;
  padding: 10px 8px;
  display: flex;
  position: relative;
}

.msp .CTA-3 {
  border-color: var(--primary-02) !important;
  width: 179px !important;
  height: 69px !important;
  display: flex !important;
}

.msp .CTA-4 {
  color: #000 !important;
  margin-top: unset !important;
}

.msp .frame-33 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  width: 358px;
  display: flex;
  position: absolute;
  top: 481px;
  left: 16px;
}

.msp .frame-34 {
  flex: none;
  width: 358px;
  position: relative;
}

.msp .frame-35 {
  background-color: #e1e4e9;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: 390px;
  height: 535px;
  padding: 60px 16px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.msp .rely-on-2 {
  color: #0000;
  letter-spacing: -.54px;
  align-self: stretch;
  font-family: Source Sans Pro, Helvetica;
  font-size: 36px;
  font-weight: 400;
  line-height: 43.2px;
  position: relative;
}

.msp .frame-36 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 24px;
  width: 358px;
  display: flex;
  position: relative;
}

.msp .text-wrapper-34 {
  color: #000;
  letter-spacing: -.2px;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.msp .frame-37 {
  height: 63px;
  position: relative;
}

.msp .element-2 {
  object-fit: cover;
  width: 192px;
  height: 236px;
  position: absolute;
  top: 299px;
  left: 198px;
}

.msp .overlap-wrapper {
  width: 390px;
  height: 1642px;
  position: absolute;
  top: 535px;
  left: 0;
  overflow: hidden;
}

.msp .overlap-8 {
  background-color: #172c36;
  height: 1733px;
  position: relative;
}

.msp .overlap-9 {
  width: 374px;
  height: 381px;
  position: absolute;
  top: 0;
  left: 16px;
}

.msp .rectangle-11 {
  width: 262px;
  height: 367px;
  position: absolute;
  top: 0;
  left: 112px;
}

.msp .frame-38 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  width: 358px;
  display: flex;
  position: absolute;
  top: 76px;
  left: 0;
}

.msp .frame-39 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 6px;
  width: 100%;
  display: flex;
  position: relative;
}

.msp .text-wrapper-35 {
  color: #fff;
  letter-spacing: -.48px;
  width: 189px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 600;
  line-height: 44.8px;
  position: relative;
}

.msp .managing-a-company-s-2 {
  color: #d9d9d9;
  letter-spacing: -.18px;
  align-self: stretch;
  font-family: Source Sans Pro, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
  position: relative;
}

.msp .frame-40 {
  flex: none;
  align-items: flex-start;
  gap: 38px;
  display: inline-flex;
  position: relative;
}

.msp .frame-41 {
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  width: 150px;
  display: flex;
  position: relative;
}

.msp .frame-42 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 17px;
  width: 100%;
  display: flex;
  position: relative;
}

.msp .frame-43 {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  display: flex;
  position: relative;
}

.msp .text-wrapper-36 {
  color: var(--neutral-0);
  letter-spacing: -.14px;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  position: relative;
}

.msp .text-wrapper-37 {
  color: var(--neutral-0);
  letter-spacing: -.14px;
  flex: 1;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  position: relative;
}

.msp .frame-44 {
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  width: 170px;
  display: flex;
  position: relative;
}

.msp .frame-45 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.msp .overlap-10 {
  width: 374px;
  height: 395px;
  position: absolute;
  top: 1247px;
  left: 16px;
}

.msp .rectangle-12 {
  width: 337px;
  height: 222px;
  position: absolute;
  top: 173px;
  left: 37px;
}

.msp .group-9 {
  background: linear-gradient(#1e454d 0%, #2967749e 100%);
  border-radius: 16px;
  width: 358px;
  height: 334px;
  position: absolute;
  top: 0;
  left: 0;
}

.msp .frame-46 {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 267px;
  display: flex;
  position: absolute;
  top: 43px;
  left: 40px;
}

.msp .frame-47 {
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 267px;
  display: flex;
  position: absolute;
  top: 104px;
  left: 40px;
}

.msp .frame-48 {
  flex: none;
  align-items: center;
  gap: 17px;
  margin-right: -35.47px;
  display: inline-flex;
  position: relative;
}

.msp .frame-49 {
  flex: none;
  align-items: center;
  gap: 17px;
  margin-right: -70.47px;
  display: inline-flex;
  position: relative;
}

.msp .text-wrapper-38 {
  color: #d9d9d9;
  letter-spacing: -.82px;
  width: 196px;
  height: 40px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 33px;
  font-weight: 400;
  line-height: 39.6px;
  position: absolute;
  top: 456px;
  left: 97px;
}

.msp .group-10 {
  width: 364px;
  height: 318px;
  position: absolute;
  top: 531px;
  left: 16px;
}

.msp .overlap-11 {
  width: 105px;
  height: 121px;
  position: absolute;
  top: 197px;
  left: 247px;
}

.msp .group-11 {
  width: 16px;
  height: 97px;
  position: absolute;
  top: 0;
  left: 46px;
}

.msp .rectangle-13 {
  background-color: #f6b3b8;
  border-radius: 2.88px;
  width: 105px;
  height: 27px;
  position: absolute;
  top: 28px;
  left: 0;
}

.msp .rectangle-14 {
  background-color: #006682;
  border-radius: 2.88px;
  width: 105px;
  height: 27px;
  position: absolute;
  top: 61px;
  left: 0;
}

.msp .rectangle-15 {
  background-color: #4d9f98;
  border-radius: 2.88px;
  width: 105px;
  height: 26px;
  position: absolute;
  top: 95px;
  left: 0;
}

.msp .text-wrapper-39 {
  color: #212f3c;
  letter-spacing: -.1px;
  white-space: nowrap;
  width: 71px;
  height: 15px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10.1px;
  font-weight: 600;
  line-height: 14.1px;
  position: absolute;
  top: 34px;
  left: 20px;
}

.msp .text-wrapper-40 {
  color: #fff;
  letter-spacing: -.1px;
  white-space: nowrap;
  width: 65px;
  height: 15px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10.1px;
  font-weight: 600;
  line-height: 14.1px;
  position: absolute;
  top: 67px;
  left: 23px;
}

.msp .text-wrapper-41 {
  color: #fff;
  letter-spacing: -.1px;
  white-space: nowrap;
  width: 88px;
  height: 15px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10.1px;
  font-weight: 600;
  line-height: 14.1px;
  position: absolute;
  top: 100px;
  left: 9px;
}

.msp .group-12 {
  width: 358px;
  height: 197px;
  position: absolute;
  top: 0;
  left: 0;
}

.msp .group-13 {
  width: 368px;
  height: 197px;
  position: relative;
}

.msp .overlap-12 {
  background-color: #00dfc2;
  border-radius: 2.88px;
  width: 158px;
  height: 35px;
  position: absolute;
  top: 0;
  left: 98px;
}

.msp .text-wrapper-42 {
  color: #212f3c;
  letter-spacing: -.21px;
  white-space: nowrap;
  width: 116px;
  height: 13px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20.8px;
  font-weight: 600;
  line-height: 29.2px;
  position: absolute;
  top: 11px;
  left: 22px;
}

.msp .overlap-13 {
  width: 247px;
  height: 134px;
  position: absolute;
  top: 40px;
  left: 0;
}

.msp .overlap-group-4 {
  width: 247px;
  height: 102px;
  position: absolute;
  top: 0;
  left: 0;
}

.msp .rectangle-16 {
  background-color: #f6b3b8;
  border-radius: 2.88px;
  width: 109px;
  height: 23px;
  position: absolute;
  top: 74px;
  left: 0;
}

.msp .line-2 {
  width: 138px;
  height: 2px;
  position: absolute;
  top: 85px;
  left: 109px;
}

.msp .ellipse-6 {
  object-fit: cover;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 70px;
  left: 124px;
}

.msp .text-wrapper-43 {
  color: #212f3c;
  letter-spacing: -.12px;
  white-space: nowrap;
  width: 105px;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 12.2px;
  font-weight: 700;
  line-height: 17.1px;
  position: absolute;
  top: 78px;
  left: 3px;
}

.msp .ellipse-7 {
  object-fit: cover;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 71px;
  left: 199px;
}

.msp .group-14 {
  width: 16px;
  height: 86px;
  position: absolute;
  top: 0;
  left: 169px;
}

.msp .group-15 {
  width: 16px;
  height: 37px;
  position: absolute;
  top: 97px;
  left: 47px;
}

.msp .overlap-14 {
  background-color: var(--neutral-0);
  border-radius: 2.88px;
  width: 105px;
  height: 23px;
  position: absolute;
  top: 174px;
  left: 3px;
}

.msp .text-wrapper-44 {
  color: #212f3c;
  letter-spacing: -.12px;
  white-space: nowrap;
  width: 86px;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 12.2px;
  font-weight: 700;
  line-height: 17.1px;
  position: absolute;
  top: 5px;
  left: 12px;
}

.msp .overlap-15 {
  background-color: var(--neutral-0);
  border-radius: 2.88px;
  width: 105px;
  height: 23px;
  position: absolute;
  top: 174px;
  left: 247px;
}

.msp .text-wrapper-45 {
  color: #212f3c;
  letter-spacing: -.12px;
  white-space: nowrap;
  width: 33px;
  height: 13px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 12.2px;
  font-weight: 700;
  line-height: 17.1px;
  position: absolute;
  top: 5px;
  left: 43px;
}

.msp .overlap-16 {
  width: 111px;
  height: 23px;
  position: absolute;
  top: 113px;
  left: 247px;
}

.msp .rectangle-17 {
  background-color: #f6b3b8;
  border-radius: 2.88px;
  width: 105px;
  height: 23px;
  position: absolute;
  top: 0;
  left: 0;
}

.msp .text-wrapper-46 {
  color: #212f3c;
  letter-spacing: -.12px;
  white-space: nowrap;
  width: 104px;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 12.2px;
  font-weight: 700;
  line-height: 17.1px;
  position: absolute;
  top: 3px;
  left: 7px;
}

.msp .group-16 {
  width: 16px;
  height: 37px;
  position: absolute;
  top: 137px;
  left: 292px;
}

.msp .overlap-group-wrapper {
  width: 347px;
  height: 334px;
  position: absolute;
  top: 881px;
  left: 18px;
}

.msp .overlap-17 {
  width: 398px;
  height: 334px;
  position: relative;
}

.msp .rectangle-18 {
  background: linear-gradient(#1e454d 0%, #22647299 100%);
  border-radius: 16px;
  width: 356px;
  height: 334px;
  position: absolute;
  top: 0;
  left: 0;
}

.msp .carousel-2 {
  width: 390px;
  height: 796px;
  position: absolute;
  top: 2749px;
  left: 0;
  overflow: hidden;
}

.msp .frame-50 {
  width: 667px;
  height: 1425px;
  position: absolute;
  top: 0;
  left: 0;
}

.msp .overlap-group-5 {
  width: 687px;
  height: 923px;
  position: relative;
  top: -24px;
  left: -134px;
}

.msp .rectangle-19 {
  width: 390px;
  height: 485px;
  position: absolute;
  top: 24px;
  left: 134px;
}

.msp .frame-51 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  height: 261px;
  display: inline-flex;
  position: absolute;
  top: 166px;
  left: 214px;
}

.msp .shutterstock {
  object-fit: cover;
  width: 390px;
  height: 441px;
  position: absolute;
  top: 379px;
  left: 134px;
}

.msp .rectangle-20 {
  filter: blur(80px);
  background: linear-gradient(to right, #589994 50%, #fcfcfc00);
  width: 923px;
  height: 687px;
  position: absolute;
  top: 118px;
  left: -118px;
  transform: rotate(90deg);
}

.msp .frame-52 {
  flex-direction: column;
  align-items: flex-start;
  gap: 33px;
  display: inline-flex;
  position: absolute;
  top: 106px;
  left: 150px;
}

.msp .we-are-your-growth {
  color: #fff;
  letter-spacing: -.22px;
  width: 358px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 22px;
  font-weight: 600;
  line-height: 30.8px;
  position: relative;
}

.msp .CTA-5 {
  border: unset !important;
  border-color: unset !important;
  background-color: #2e5053 !important;
  flex: none !important;
  justify-content: center !important;
  align-items: flex-end !important;
  width: 127px !important;
  display: flex !important;
}

.msp .CTA-6 {
  margin-left: -2px !important;
  font-weight: 700 !important;
}

.msp .CTA-7 {
  margin-right: -2px !important;
}

.msp .frame-53 {
  width: 1440px;
  height: 580px;
  position: absolute;
  top: 0;
  left: 1440px;
  overflow: hidden;
}

.msp .rectangle-21 {
  width: 1383px;
  height: 634px;
  position: absolute;
  top: -3795px;
  left: 5946px;
}

.msp .overlap-18 {
  filter: blur(80px);
  background: linear-gradient(#000 0%, #071103 25%, #202020 44.79%, #2f2f2f 61.46%, #0000 100%);
  width: 1684px;
  height: 714px;
  position: absolute;
  top: -60px;
  left: -116px;
}

.msp .frame-54 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: absolute;
  top: 242px;
  left: 196px;
}

.msp .text-wrapper-47 {
  color: var(--neutral-0);
  letter-spacing: -.53px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 600;
  line-height: 49px;
  position: relative;
}

.msp .frame-55 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.msp .our-client-and {
  color: #f6f7f8;
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  width: 436px;
  margin-top: -1px;
  position: relative;
}

.msp .CTA-8 {
  all: unset;
  box-sizing: border-box;
  flex: none;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.msp .text-wrapper-48 {
  color: var(--neutral-0);
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 23.8px;
  position: relative;
}

.msp .arrow-forward-2 {
  width: 24px;
  height: 24px;
  margin-top: -4167px;
  margin-right: -5519px;
  position: relative;
}

.msp .text-wrapper-49 {
  color: var(--neutral-0);
  font-family: var(--h4-REG-font-family);
  font-size: var(--h4-REG-font-size);
  font-style: var(--h4-REG-font-style);
  font-weight: var(--h4-REG-font-weight);
  letter-spacing: var(--h4-REG-letter-spacing);
  line-height: var(--h4-REG-line-height);
  white-space: nowrap;
  height: 41px;
  position: absolute;
  top: 120px;
  left: 196px;
}

.msp .frame-56 {
  align-items: flex-start;
  gap: 5px;
  display: inline-flex;
  position: absolute;
  top: 560px;
  left: 196px;
}

.msp .ellipse-8 {
  background-color: #d9d9d952;
  border-radius: 5.5px;
  width: 11px;
  height: 11px;
  position: relative;
}

.msp .ellipse-9 {
  background-color: var(--neutral-30);
  border-radius: 5.5px;
  width: 11px;
  height: 11px;
  position: relative;
}

.msp .carousel-03 {
  position: absolute !important;
  top: 0 !important;
  left: 2880px !important;
}

.msp .carousel-instance {
  margin-top: -4190px !important;
  margin-right: -4079px !important;
}

.msp .overlap-19 {
  position: absolute;
}

.msp .frame-57 {
  position: absolute;
  top: 0;
}

.msp .overlap-group-6 {
  position: relative;
}

.msp .frame-58 {
  width: 1449px;
  height: 380px;
  position: absolute;
  top: 0;
  left: 0;
}

.msp .overlap-group-7 {
  background-color: #4d4d4d;
  width: 1456px;
  height: 380px;
}

.msp .frame-59 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 32px;
  display: inline-flex;
  position: relative;
  top: 69px;
  left: 152px;
}

.msp .frame-60 {
  flex-direction: column;
  flex: none;
  align-items: flex-end;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.msp .text-wrapper-50 {
  color: #fff;
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.msp .are-we-a-good-fit {
  color: var(--neutral-10);
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  text-align: right;
  width: 429px;
  position: relative;
}

.msp .CTA-9 {
  all: unset;
  background-color: var(--secondary-04);
  box-sizing: border-box;
  flex: none;
  align-items: center;
  gap: 10px;
  padding: 12px 16px;
  display: inline-flex;
  position: relative;
}

.msp .text-wrapper-51 {
  color: #fff;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: relative;
}

.msp .arrow-forward-3 {
  width: 24px;
  height: 24px;
  position: relative;
}

.msp .frame-61 {
  position: absolute;
  top: 0;
}

.msp .overlap-20 {
  background-color: var(--neutral-10);
  width: 720px;
  height: 380px;
  position: absolute;
  top: 0;
  left: 0;
}

.msp .frame-62 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 32px;
  display: inline-flex;
  position: relative;
  top: 49px;
  left: 128px;
}

.msp .text-wrapper-52 {
  color: var(--secondary-04);
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.msp .text-wrapper-53 {
  color: var(--neutral-80);
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  text-align: right;
  width: 500px;
  position: relative;
}

.msp .frame-63 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 52px;
  left: 817px;
}

.msp .text-wrapper-54 {
  color: #fff;
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.msp .text-wrapper-55 {
  color: var(--neutral-10);
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  width: 429px;
  position: relative;
}

.msp .CTA-10 {
  cursor: pointer;
  background-color: var(--secondary-02);
  box-sizing: border-box;
  border: none;
  outline: none;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  display: inline-flex;
  position: absolute;
  top: 259px;
  left: 817px;
}

.msp .text-wrapper-56 {
  color: var(--neutral-0);
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.msp .frame-64 {
  flex-direction: column;
  align-items: center;
  gap: 25px;
  display: inline-flex;
  position: absolute;
  top: 76px;
  left: 0;
}

.msp .frame-65 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 51px;
  margin-left: 50px;
  display: inline-flex;
  position: relative;
}

.msp .frame-66 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 26px;
  display: inline-flex;
  position: relative;
}

.msp .frame-67 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.msp .frame-68 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.msp .text-wrapper-57 {
  color: var(--neutral-60);
  letter-spacing: -.2px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  position: relative;
}

.msp .frame-69 {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 29px;
  display: inline-flex;
  position: relative;
}

.msp .mercedes-benz-logo {
  object-fit: cover;
  width: 148px;
  height: 37px;
  position: relative;
}

.msp .GE-healthcare-logo {
  object-fit: cover;
  width: 98px;
  height: 55px;
  position: relative;
}

.msp .sony-logo {
  object-fit: cover;
  width: 74px;
  height: 13px;
  position: relative;
}

.msp .shell-logo {
  object-fit: cover;
  width: 69px;
  height: 39px;
  position: relative;
}

.msp .amazon {
  object-fit: cover;
  width: 70px;
  height: 33px;
  position: relative;
}

.msp .wipro-logo-new-og {
  object-fit: cover;
  width: 102px;
  height: 53px;
  position: relative;
}

.msp .footer-2 {
  background-color: var(--white);
  width: 1441px;
  height: 292px;
  position: absolute;
  top: 377px;
  left: 0;
}

.msp .overlap-21 {
  width: 1441px;
  height: 292px;
  position: relative;
}

.msp .frame-70 {
  background-color: var(--neutral-90);
  width: 1441px;
  height: 292px;
  position: absolute;
  top: 0;
  left: 0;
}

.msp .DIV-3 {
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 53px;
  left: 738px;
}

.msp .text-wrapper-58 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1233px;
}

.msp .text-wrapper-59 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1282px;
}

.msp .text-wrapper-60 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1331px;
}

.msp .text-wrapper-61 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1187px;
}

.msp .text-wrapper-62 {
  color: var(--white);
  letter-spacing: -.2px;
  white-space: nowrap;
  height: 28px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: absolute;
  top: 100px;
  left: 738px;
}

.msp .text-wrapper-63 {
  color: var(--white);
  letter-spacing: -.2px;
  white-space: nowrap;
  height: 28px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: absolute;
  top: 100px;
  left: 869px;
}

.msp .frame-71 {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: absolute;
  top: 115px;
  left: 81px;
}

.msp .group-17 {
  width: 37px;
  height: 52px;
  position: absolute;
  top: 152px;
  left: 1140px;
}

.msp .linkedin {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1187px;
}

.msp .facebook-2 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1234px;
}

.msp .facebook-3 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1281px;
}

.msp .facebook-4 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1328px;
}

.msp .group-18 {
  width: 400px;
  height: 17px;
  position: absolute;
  top: 242px;
  left: 501px;
}

.msp .frame-72 {
  justify-content: center;
  align-items: center;
  gap: 5px;
  display: inline-flex;
  position: relative;
}

.msp .text-wrapper-64 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.msp .ellipse-10 {
  background-color: var(--grey-01);
  border-radius: 1.5px;
  width: 3px;
  height: 3px;
  position: relative;
}

.msp .logo-3 {
  width: 116px;
  height: 56px;
  position: absolute;
  top: 39px;
  left: 76px;
}

.msp .frame-73 {
  flex-direction: column;
  align-items: center;
  gap: 92px;
  display: inline-flex;
  position: absolute;
  top: 366px;
  left: 185px;
}

.msp .frame-74 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-end;
  gap: 32px;
  width: 328px;
  display: flex;
  position: relative;
}

.msp .frame-75 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 24px;
  width: 328px;
  display: flex;
  position: relative;
}

.msp .text-wrapper-65 {
  color: var(--secondary-04);
  letter-spacing: -.8px;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.4px;
  position: relative;
}

.msp .text-wrapper-66 {
  color: var(--neutral-80);
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  text-align: center;
  width: 297px;
  position: relative;
}

.msp .group-19 {
  width: 390px;
  height: 360px;
  position: relative;
}

.msp .frame-76 {
  height: 360px;
}

.msp .overlap-group-8 {
  background-color: var(--neutral-70);
  width: 1440px;
  height: 360px;
}

.msp .frame-77 {
  flex-direction: column;
  align-items: center;
  gap: 24px;
  display: inline-flex;
  position: relative;
  top: 85px;
  left: 41px;
}

.msp .text-wrapper-67 {
  color: var(--neutral-0);
  letter-spacing: -.8px;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.4px;
  position: relative;
}

.msp .are-we-a-good-fit-2 {
  color: #f6f7f8;
  letter-spacing: -.2px;
  text-align: center;
  width: 309px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.msp .CTA-11 {
  cursor: pointer;
  background-color: var(--secondary-02);
  box-sizing: border-box;
  border: none;
  outline: none;
  flex: none;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  display: inline-flex;
  position: relative;
}

.msp .frame-78 {
  align-items: flex-start;
  gap: 40px;
  width: 100%;
  position: absolute;
  top: 2261px;
  left: 16px;
  display: inline-flex !important;
}

.msp .frame-79 {
  background-color: var(--neutral-0);
  border: 1px solid;
  border-color: var(--neutral-50);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  width: 298px;
  margin-top: -1px;
  margin-bottom: -1px;
  margin-left: -1px;
  display: flex;
  position: relative;
}

.msp .deepmind {
  object-fit: cover;
  width: 296px;
  height: 163px;
  position: relative;
}

.msp .frame-80 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 14px;
  width: 100%;
  padding: 0 32px;
  display: flex;
  position: relative;
}

.msp .frame-81 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.msp .text-wrapper-68 {
  color: #1f1f1f;
  white-space: nowrap;
  letter-spacing: -.36px;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 28.8px;
  position: relative;
}

.msp .automate-the-entire {
  color: var(--neutral-60);
  letter-spacing: -.14px;
  align-self: stretch;
  font-family: Source Sans Pro, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  position: relative;
}

.msp .frame-82 {
  flex: none;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.msp .text-wrapper-69 {
  color: #4d9f98;
  letter-spacing: -.15px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 15px;
  font-weight: 700;
  line-height: 21px;
  position: relative;
}

.msp .frame-83 {
  flex: none;
  align-items: flex-start;
  gap: 10px;
  margin-right: -2px;
  display: inline-flex;
  position: relative;
}

.msp .frame-84 {
  background-color: var(--neutral-0);
  border: 1px solid;
  border-color: var(--neutral-50);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  width: 298px;
  margin-top: -1px;
  margin-bottom: -1px;
  display: flex;
  position: relative;
}

.msp .frame-85 {
  background-color: var(--neutral-0);
  border: 1px solid;
  border-color: var(--neutral-50);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  width: 298px;
  margin-top: -1px;
  margin-bottom: -1px;
  margin-right: -1px;
  display: flex;
  position: relative;
}

.msp .overlap-22 {
  width: 1483px;
  height: 1070px;
  position: absolute;
  top: 1456px;
  left: -11px;
}

.msp .overlap-23 {
  width: 1483px;
  height: 1068px;
  position: absolute;
  top: 2px;
  left: 0;
}

.msp .group-20 {
  width: 1483px;
  height: 416px;
  position: absolute;
  top: 652px;
  left: 0;
}

.msp .overlap-24 {
  width: 1481px;
  height: 416px;
  position: relative;
}

.msp .carousel-3 {
  width: 1481px;
  height: 416px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.msp .frame-86 {
  width: 1440px;
  height: 683px;
  position: absolute;
  top: 0;
  left: 0;
}

.msp .overlap-group-9 {
  width: 1722px;
  height: 790px;
  position: relative;
  top: -81px;
  left: -186px;
}

.msp .rectangle-22 {
  width: 1429px;
  height: 416px;
  position: absolute;
  top: 81px;
  left: 197px;
}

.msp .shutterstock-2 {
  object-fit: cover;
  width: 860px;
  height: 709px;
  position: absolute;
  top: 81px;
  left: 766px;
}

.msp .rectangle-23 {
  background: linear-gradient(to right, #589994 50%, #fcfcfc00);
  width: 1722px;
  height: 790px;
  position: absolute;
  top: 0;
  left: 0;
}

.msp .CTA-12 {
  border: unset !important;
  border-color: unset !important;
  background-color: #2e5053 !important;
  justify-content: center !important;
  align-items: flex-end !important;
  width: 182px !important;
  display: flex !important;
  position: absolute !important;
  top: 367px !important;
  left: 234px !important;
}

.msp .CTA-13 {
  font-weight: 700 !important;
}

.msp .rectangle-24 {
  width: 1383px;
  height: 634px;
  position: absolute;
  top: 668px;
  left: 5957px;
}

.msp .arrow-forward-4 {
  width: 24px;
  height: 24px;
  margin-bottom: -296px;
  margin-right: -5530px;
  position: relative;
}

.msp .carousel-03-instance {
  margin-bottom: -273px !important;
  margin-right: -4090px !important;
}

.msp .we-are-your-growth-2 {
  color: #fff;
  letter-spacing: -.29px;
  width: 604px;
  height: 118px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 29px;
  font-weight: 600;
  line-height: 40.6px;
  position: absolute;
  top: 102px;
  left: 47px;
}

.msp .frame-87 {
  background-color: var(--neutral-0);
  border: 1px solid;
  border-color: var(--neutral-50);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  width: 434px;
  height: 654px;
  padding: 0 0 94px;
  display: flex;
  position: absolute;
  top: 0;
  left: 512px;
}

.msp .deepmind-hereyczt {
  align-self: stretch;
  width: 100%;
  height: 304.5px;
  position: relative;
}

.msp .frame-88 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 14px;
  width: 100%;
  margin-bottom: -50.5px;
  padding: 0 48px;
  display: flex;
  position: relative;
}

.msp .text-wrapper-70 {
  color: #1f1f1f;
  letter-spacing: -.53px;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 400;
  line-height: 42px;
  position: relative;
}

.msp .text-wrapper-71 {
  color: var(--neutral-60);
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  align-self: stretch;
  position: relative;
}

.msp .text-wrapper-72 {
  color: #4d9f98;
  font-family: var(--subtitle-BLD-font-family);
  font-size: var(--subtitle-BLD-font-size);
  font-style: var(--subtitle-BLD-font-style);
  font-weight: var(--subtitle-BLD-font-weight);
  letter-spacing: var(--subtitle-BLD-letter-spacing);
  line-height: var(--subtitle-BLD-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.msp .frame-89 {
  background-color: var(--neutral-0);
  border: 1px solid;
  border-color: var(--neutral-50);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  width: 434px;
  height: 654px;
  padding: 0 0 94px;
  display: flex;
  position: absolute;
  top: 0;
  left: 40px;
}

.msp .frame-90 {
  background-color: var(--neutral-0);
  border: 1px solid;
  border-color: var(--neutral-50);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  width: 434px;
  height: 654px;
  padding: 0 0 94px;
  display: flex;
  position: absolute;
  top: 0;
  left: 984px;
}

.msp .frame-91 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 14px;
  width: 100%;
  margin-bottom: -50.5px;
  padding: 0 24px;
  display: flex;
  position: relative;
}

.msp .frame-92 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.msp .text-wrapper-73 {
  color: #1f1f1f;
  letter-spacing: -.53px;
  width: 369px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 400;
  line-height: 42px;
  position: relative;
}

.msp .text-wrapper-74 {
  color: var(--neutral-60);
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  width: 383px;
  position: relative;
}

.msp .overlap-25 {
  background-color: #f6f7f8;
  width: 1447px;
  height: 288px;
  position: absolute;
  top: 2691px;
  left: 0;
}

.msp .frame-93 {
  flex-direction: column;
  align-items: center;
  gap: 25px;
  display: inline-flex;
  position: relative;
  top: 71px;
  left: 153px;
}

.msp .mercedes-benz-logo-2 {
  object-fit: cover;
  width: 248px;
  height: 63px;
  position: relative;
}

.msp .GE-healthcare-logo-2 {
  object-fit: cover;
  width: 164px;
  height: 92px;
  position: relative;
}

.msp .sony-logo-2 {
  object-fit: cover;
  width: 123px;
  height: 22px;
  position: relative;
}

.msp .shell-logo-2 {
  object-fit: cover;
  width: 115px;
  height: 65px;
  position: relative;
}

.msp .amazon-2 {
  object-fit: cover;
  width: 178px;
  height: 55px;
  position: relative;
}

.msp .wipro-logo-new-og-2 {
  object-fit: cover;
  width: 171px;
  height: 89px;
  position: relative;
}

.header-2-shared {
  z-index: 1;
  background-color: #0000;
  justify-content: space-between;
  align-items: center;
  width: 1440px;
  padding: 16px 80px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.logo-shared {
  width: 162px;
  height: 75px;
  position: relative;
}

.holder-shared {
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.frame-33-shared {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 90.33px;
  padding: 10px 8px;
  display: flex;
  position: relative;
}

.text-wrapper-16-shared {
  color: #000;
  letter-spacing: -.17px;
  white-space: nowrap;
  cursor: pointer;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: relative;
}

.text-wrapper-17-shared {
  color: #000;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-left: -2.83px;
  margin-right: -2.83px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: relative;
}

.frame-34-shared {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 80px;
  padding: 10px 8px;
  display: flex;
  position: relative;
}

.CTA-8-shared {
  border-color: var(--primary-02) !important;
  width: 179px !important;
  height: 69px !important;
  display: flex !important;
}

.CTA-9-shared {
  color: #000 !important;
  margin-top: unset !important;
}

.state-default-wrapper-shared {
  all: unset;
  border: 1px solid;
  border: 1px solid var(--primary-02, #4d9f98);
  box-sizing: border-box;
  background: #fff;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 12px 24px;
  transition: all .3s;
  display: inline-flex;
  position: relative;
}

.state-default-wrapper-shared .text-wrapper-4-shared {
  color: var(--secondary-03);
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  transition: all .3s;
  position: relative;
}

.state-default-wrapper-shared.default-shared-true {
  border-radius: 40px;
}

.state-default-wrapper-shared:hover {
  background: var(--primary-02) !important;
  border-radius: 45px !important;
}

.state-default-wrapper-shared:hover .text-wrapper-4-shared {
  color: #fff !important;
}

.solutiondropdown {
  background: var(--neutral-10, #f6f7f8);
  border-radius: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 224px;
  padding: 4px 0;
  display: flex;
  position: absolute;
  top: 50px;
  left: 0;
  overflow: hidden;
  box-shadow: 4px 9px 32px #aeaeae1f;
}

.solutiondropdown .sub_menu {
  background: #f9f9f7;
  gap: 10px;
  width: 224px;
  padding: 14px 20px 14px 50px;
  display: flex;
}

.solutiondropdown .sub_menu p {
  color: var(--neutral-90, #202020);
  letter-spacing: -.14px;
  text-transform: capitalize;
  font-family: Source Sans Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.solutiondropdown {
  z-index: 10;
  background: var(--neutral-10, #f6f7f8);
  border-radius: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 224px;
  padding: 4px 0;
  display: flex;
  position: absolute;
  top: 50px;
  left: 0;
  box-shadow: 4px 9px 32px #aeaeae1f;
}

.solutiondropdown .nav-item {
  background: #fff;
  align-items: center;
  gap: 10px;
  width: 224px;
  padding: 14px 20px;
  display: flex;
}

.solutiondropdown .nav-item p {
  color: var(--neutral-90, #202020);
  letter-spacing: -.14px;
  text-transform: capitalize;
  font-family: Source Sans Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

@media (width <= 450px) {
  .solutiondropdown {
    top: 90px;
  }
}

.footer-2-shared {
  z-index: 10;
  position: absolute;
  bottom: 0;
  left: 0;
}

.frame-39-shared {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: absolute;
  top: 115px;
  left: 81px;
}

.frame-41-shared {
  justify-content: center;
  align-items: center;
  gap: 5px;
  display: inline-flex;
  position: relative;
}

.overlap-8-shared {
  height: 292px;
  position: relative;
}

.frame-49-shared {
  background-color: var(--neutral-90);
  width: 1441px;
  height: 292px;
  position: absolute;
  top: 0;
  left: 0;
}

.DIV-2-shared {
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  width: 116px;
  height: 57px;
  display: inline-flex;
  position: absolute;
  top: 53px;
  left: 738px;
}

.DIV-10-shared {
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 153px;
  left: 738px;
}

.DIV-10-shared1 {
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 153px;
  left: 538px;
}

.text-wrapper-29-shared {
  color: var(--white);
  letter-spacing: -.2px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.text-wrapper-30-shared {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1233px;
}

.text-wrapper-31-shared {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1282px;
}

.text-wrapper-32-shared {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1331px;
}

.text-wrapper-33-shared {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1187px;
}

.text-wrapper-34-shared {
  color: var(--white);
  letter-spacing: -.2px;
  white-space: nowrap;
  height: 28px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: absolute;
  top: 100px;
  left: 738px;
}

.text-wrapper-35-shared {
  color: var(--white);
  letter-spacing: -.2px;
  white-space: nowrap;
  height: 28px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: absolute;
  top: 100px;
  left: 869px;
}

.text-wrapper-36-shared {
  color: #fff;
  letter-spacing: -.21px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 14px;
  font-weight: 700;
  line-height: 19.6px;
  position: relative;
}

.text-wrapper-37-shared {
  color: var(--neutral-10);
  font-family: var(--paragraph-REG-font-family);
  font-size: var(--paragraph-REG-font-size);
  font-style: var(--paragraph-REG-font-style);
  font-weight: var(--paragraph-REG-font-weight);
  letter-spacing: var(--paragraph-REG-letter-spacing);
  line-height: var(--paragraph-REG-line-height);
  width: 324px;
  position: relative;
}

.group-12-shared {
  width: 37px;
  height: 52px;
  position: absolute;
  top: 152px;
  left: 1140px;
}

.text-wrapper-38-shared {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 38px;
  left: 1px;
}

.face-book-shared {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 0;
  left: 0;
}

.linkedin-shared {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1187px;
}

.facebook-shared {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1234px;
}

.facebook-2-shared {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1281px;
}

.facebook-3-shared {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1328px;
}

.group-13-shared {
  width: 400px;
  height: 17px;
  position: absolute;
  top: 242px;
  left: 501px;
}

.text-wrapper-39-shared {
  color: var(--grey-01);
  letter-spacing: -.12px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.logo-3-shared {
  width: 116px;
  height: 56px;
  position: absolute;
  top: 39px;
  left: 76px;
}

.footer-3-shared {
  background-color: var(--white);
  width: 390px;
  height: 860px;
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.overlap-16-shared {
  width: 390px;
  height: 907px;
  position: relative;
}

.frame-73-shared {
  background-color: var(--neutral-90);
  width: 390px;
  height: 907px;
  position: absolute;
  top: 0;
  left: 0;
}

.frame-74-shared {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: absolute;
  top: 115px;
  left: 26px;
}

.DIV-3-shared {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 113px;
  display: flex;
  position: absolute;
  top: 272px;
  left: 195px;
}

.text-wrapper-53-shared {
  color: var(--white);
  letter-spacing: -.2px;
  white-space: nowrap;
  width: fit-content;
  padding: 0;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.DIV-4-shared {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 113px;
  display: flex;
  position: absolute;
  top: 272px;
  left: 26px;
}

.frame-75-shared {
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 583px;
  left: 69px;
}

.group-20-shared {
  width: 37px;
  height: 52px;
  position: relative;
}

.frame-76-shared {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.linkedin-2-shared {
  flex: none;
  position: relative;
}

.text-wrapper-54-shared {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  width: fit-content;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: relative;
}

.facebook-4-shared {
  width: 35px;
  height: 35px;
  position: relative;
}

.frame-77-shared {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.logo-4-shared {
  width: 116px;
  height: 56px;
  position: absolute;
  top: 39px;
  left: 24px;
}

.logo-4-shared1 {
  width: 116px;
  height: 56px;
  position: absolute;
  top: -82px;
  left: 64px;
}

.logo-4-share2 {
  width: 116px;
  height: 56px;
  position: absolute;
  top: 332px;
  left: 24px;
}

.group-21-shared {
  width: 295px;
  height: 1564px;
  position: absolute;
  top: 732px;
  left: 48px;
}

.frame-78-shared {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 295px;
  display: flex;
  position: relative;
}

.text-wrapper-55-shared {
  color: var(--grey-01);
  letter-spacing: -.12px;
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.ellipse-14-shared {
  background-color: var(--grey-01);
  border-radius: 1px / 1.5px;
  width: 2px;
  height: 3px;
  position: relative;
}

.text-wrapper-56-shared {
  color: var(--grey-01);
  letter-spacing: -.12px;
  text-align: center;
  align-self: stretch;
  font-family: Source Sans Pro, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.popup {
  z-index: 9999;
  background: #0000004d;
  justify-content: center;
  align-items: center;
  width: 1440px;
  display: flex;
  position: fixed;
  left: 0;
}

.lead-form {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 1000px;
  display: flex;
}

.lead-form .lead-div {
  background-color: var(--neutralwhite);
  width: 1000px;
  height: 630px;
  position: relative;
}

.lead-form .lead-frame {
  flex-direction: column;
  align-items: center;
  gap: 40px;
  display: inline-flex;
  position: absolute;
  top: 51px;
  left: 189px;
}

.lead-form .lead-everything-you-need {
  width: 580.27px;
  height: 100px;
  position: relative;
}

.lead-form .lead-frame-2 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 31px;
  display: inline-flex;
  position: relative;
}

.lead-form .lead-frame-3 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: relative;
}

.lead-form .lead-frame-4 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.lead-form .lead-frame-5 {
  flex: none;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.lead-form .lead-component-form {
  border: .5px solid;
  border-color: var(--neutral-grey);
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  gap: 362px;
  width: 316px;
  padding: 14.5px 16px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.lead-form .lead-frame-6 {
  flex: 1;
  align-items: center;
  gap: 362px;
  display: flex;
  position: relative;
}

.lead-form input, textarea {
  color: #6f6f6f;
  font-family: Source Sans Pro, Helvetica;
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  background: none;
}

.lead-form input::placeholder, textarea::placeholder {
  color: #6f6f6f;
  font-family: Source Sans Pro, Helvetica;
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
}

.lead-form .lead-frame-wrapper {
  border: .5px solid;
  border-color: var(--neutral-grey);
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  gap: 362px;
  width: 294px;
  padding: 14.5px 16px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.lead-form .lead-div-wrapper-lead {
  border: .5px solid;
  border-color: var(--neutral-grey);
  border-radius: 4px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 362px;
  width: 636px;
  padding: 14.5px 16px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.lead-form .lead-component-2 {
  border: .5px solid;
  border-color: var(--neutral-grey);
  border-radius: 4px;
  justify-content: center;
  align-items: flex-start;
  gap: 362px;
  width: 636px;
  max-width: 636px;
  height: 112px;
  max-height: 150px;
  padding: 14.5px 16px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.lead-form .lead-CTA {
  all: unset;
  box-sizing: border-box;
  width: 636px;
  height: 74px;
  position: relative;
}

.lead-form .lead-btn {
  all: unset;
  box-sizing: border-box;
  background-color: #4d9f98;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 636px;
  height: 74px;
  padding: 16px 126px;
  display: flex;
  position: relative;
}

.lead-form .lead-text-wrapper {
  all: unset;
  box-sizing: border-box;
  font-family: var(--subtitle-BLD-font-family);
  color: var(--neutral-white, #fff);
  text-align: center;
  text-align: center;
  width: fit-content;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.lead-form .lead-frame-7 {
  background-color: var(--neutral-30);
  border-radius: 8px;
  flex: none;
  align-items: center;
  gap: 16px;
  width: 636px;
  padding: 16px;
  display: flex;
  position: relative;
}

.lead-form .lead-frame-8 {
  flex: 1;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.lead-form .lead-frame-9 {
  flex: none;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.lead-form .lead-missed-video-call {
  background-color: var(--secondary-02);
  border-radius: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 44px;
  height: 44px;
  padding: 12px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.lead-form .lead-calendar-today {
  width: 24px;
  height: 24px;
  margin: -2px;
  position: relative;
}

.lead-form .lead-group-2 {
  width: 296px;
  height: 40px;
  margin-right: -2px;
  position: relative;
}

.lead-form .lead-p {
  color: #6e6e6e;
  letter-spacing: -.1px;
  width: 294px;
  height: 40px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

.lead-form .lead-frame-10 {
  flex: none;
  align-items: flex-start;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.lead-form .lead-btn-wrapper {
  all: unset;
  box-sizing: border-box;
  border: 1px solid #000;
  border-radius: 4px;
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  padding: 0 1px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.lead-form .lead-CTA-wrapper {
  all: unset;
  box-sizing: border-box;
  border-radius: 4px;
  flex: none;
  align-items: center;
  gap: 10px;
  padding: 16px 12px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.lead-form .lead-button {
  all: unset;
  box-sizing: border-box;
  color: #3d3d3d;
  letter-spacing: 0;
  text-align: center;
  width: fit-content;
  margin-top: -2px;
  font-family: Neue Haas Grotesk Text Pro-75Bold, Helvetica;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.lead-form .lead-CTA-2 {
  all: unset;
  box-sizing: border-box;
  border: 1px solid #000;
  border-radius: 4px;
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.lead-form .lead-btn-2 {
  all: unset;
  box-sizing: border-box;
  border-radius: 4px;
  flex: none;
  align-items: center;
  gap: 10px;
  padding: 16px 12px;
  display: inline-flex;
  position: relative;
}

.lead-form .lead-close {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.lead-form .lead-close-button {
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  width: 38px;
  height: 20px;
  position: absolute;
  top: 26px;
  left: 945px;
}

@media (width <= 450px) {
  .popup {
    width: 390px;
  }

  .lead-form {
    width: 380px;
  }

  .lead-form .lead-div {
    width: 380px;
    height: 600px;
  }

  .lead-form .lead-frame {
    gap: 32px;
    width: 100%;
    padding: 24px 16px 16px;
    top: 0;
    left: 0;
  }

  .lead-form .lead-everything-you-need {
    width: 300px;
    height: auto;
  }

  .lead-form .lead-frame-5 {
    flex-direction: column;
    gap: 16px;
  }

  .lead-form input, textarea, .lead-frame-2, .lead-frame-3, .lead-frame-4, .lead-frame-5, .lead-component-form, .lead-frame-wrapper, .lead-div-wrapper-lead, .lead-component-2, .lead-frame-7 {
    width: 100% !important;
  }

  .lead-form input, textarea, .lead-form input::placeholder, textarea::placeholder {
    font-size: 16px;
  }

  .lead-form .lead-CTA, .lead-btn {
    width: 100% !important;
    height: 56px !important;
  }

  .lead-form .lead-btn {
    padding: 16px 0;
  }

  .lead-form .lead-frame-8 {
    flex-direction: column;
  }

  .lead-form .lead-p {
    width: 282px !important;
  }

  .lead-form .lead-frame-7 {
    padding: 8px;
  }

  .lead-form .lead-close-button {
    top: 5px;
    left: 341px;
  }

  .lead-form .lead-CTA-wrapper, .lead-btn-2 {
    padding: 12px !important;
  }
}

@font-face {
  font-family: revicons;
  fallback: fallback;
  src: url("revicons.93d74edb.woff") format("woff"), url("revicons.6716ab81.ttf") format("ttf"), url("revicons.80a7204d.eot") format("ttf");
}

.react-multi-carousel-list {
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.react-multi-carousel-track {
  transform-style: preserve-3d;
  backface-visibility: hidden;
  will-change: transform, transition;
  flex-direction: row;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  position: relative;
}

.react-multiple-carousel__arrow {
  z-index: 1000;
  opacity: 1;
  cursor: pointer;
  background: #00000080;
  border: 0;
  border-radius: 35px;
  outline: 0;
  min-width: 43px;
  min-height: 43px;
  transition: all .5s;
  position: absolute;
}

.react-multiple-carousel__arrow:hover {
  background: #000c;
}

.react-multiple-carousel__arrow:before {
  color: #fff;
  text-align: center;
  z-index: 2;
  font-family: revicons;
  font-size: 20px;
  display: block;
  position: relative;
}

.react-multiple-carousel__arrow:disabled {
  cursor: default;
  background: #00000080;
}

.react-multiple-carousel__arrow--left {
  left: calc(4% + 1px);
}

.react-multiple-carousel__arrow--left:before {
  content: "";
}

.react-multiple-carousel__arrow--right {
  right: calc(4% + 1px);
}

.react-multiple-carousel__arrow--right:before {
  content: "";
}

.react-multi-carousel-dot-list {
  text-align: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.react-multi-carousel-dot button {
  opacity: 1;
  box-shadow: none;
  cursor: pointer;
  border: 2px solid gray;
  border-radius: 50%;
  outline: 0;
  width: 12px;
  height: 12px;
  margin: 0 6px 0 0;
  padding: 0;
  transition: background .5s;
  display: inline-block;
}

.react-multi-carousel-dot button:hover:active, .react-multi-carousel-dot--active button {
  background: #080808;
}

.react-multi-carousel-item {
  transform-style: preserve-3d;
  backface-visibility: hidden;
}

@media (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .react-multi-carousel-item {
    flex-shrink: 0 !important;
  }

  .react-multi-carousel-track {
    overflow: visible !important;
  }
}

[dir="rtl"].react-multi-carousel-list {
  direction: rtl;
}

.rtl.react-multiple-carousel__arrow--right {
  left: calc(4% + 1px);
  right: auto;
}

.rtl.react-multiple-carousel__arrow--right:before {
  content: "";
}

.rtl.react-multiple-carousel__arrow--left {
  left: auto;
  right: calc(4% + 1px);
}

.rtl.react-multiple-carousel__arrow--left:before {
  content: "";
}

.CTA.default {
  border: 1px solid #fff;
  gap: 10px;
  background-color: #0000 !important;
}

.CTA-wrapper {
  all: unset;
  box-sizing: border-box;
  background-color: #4d9f98;
  border-radius: 56px;
  justify-content: center;
  align-items: flex-end;
  gap: 10px;
  width: 182px;
  padding: 12px 16px;
  display: flex;
  position: relative;
}

.CTA-wrapper .text-wrapper {
  color: #fff;
  letter-spacing: -.15px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 15px;
  font-weight: 700;
  line-height: 21px;
  position: relative;
}

.CTA-wrapper .img {
  width: 20px;
  height: 20px;
  position: relative;
}

.property-default {
  background-color: #2e5053;
  width: 1280px;
  height: 440px;
}

.property-default .overlap-group-2 {
  height: 440px;
  position: relative;
}

.property-default .rectangle-wrapper {
  background-image: url("shutterstock-1485872414-1-6.ebd7ada9.webp");
  background-size: 100% 100%;
  width: 1280px;
  height: 440px;
  position: absolute;
  top: 0;
  left: 0;
}

.property-default .rectangle-4 {
  background-blend-mode: multiply;
  background: linear-gradient(#0000 0%, #040608 100%);
  width: 440px;
  height: 835px;
  position: relative;
  top: -198px;
  left: 197px;
  transform: rotate(90deg);
}

.property-default .frame-6 {
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 110px;
  left: 64px;
}

.property-default .div-wrapper {
  flex-direction: column;
  align-items: flex-start;
  gap: 35px;
  display: inline-flex;
  position: relative;
  height: 188px !important;
}

.property-default-wrapper .div {
  color: var(--neutral-0);
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.property-default-wrapper .arrow-forward-2 {
  width: 24px;
  height: 24px;
  position: relative;
}

.property-default-wrapper.property-1-hover {
  background-color: #4d9f98;
  gap: 8px;
}

.property-default-wrapper.property-1-default {
  border: 1px solid;
  border-color: var(--neutral-0);
  gap: 10px;
  transition: all .3s ease-in-out;
}

.property-default-wrapper.property-1-default:hover {
  background-color: #4d9f98;
  gap: 8px;
}

.service {
  background-color: #2e5053;
}

.service .frame {
  position: absolute;
  top: 0;
  left: 0;
}

.service .shutterstock {
  object-fit: cover;
  width: 747px;
  height: 648px;
  position: absolute;
  top: 0;
  left: 0;
}

.service .rectangle {
  background-blend-mode: multiply;
  background: linear-gradient(#0000 0%, #040608 100%);
  width: 748px;
  height: 398px;
  position: absolute;
  top: 250px;
  left: 0;
}

.service .rectangle-2 {
  background-blend-mode: multiply;
  position: relative;
}

.service .shutterstock-2 {
  object-fit: cover;
  width: 594px;
  height: 747px;
  position: absolute;
  top: -51px;
  left: -39px;
}

.service .frame-2 {
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
}

.service .frame-3 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 12px;
  position: relative;
}

.service .hire-train-deploy {
  letter-spacing: -1.05px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 42px;
  font-weight: 600;
  line-height: 50.4px;
  position: relative;
}

.service .administrative {
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 600;
  position: relative;
}

.service .we-are-committed-to {
  color: #fff;
  width: 492px;
  position: relative;
}

.service .CTA-instance {
  flex: none !important;
}

.service .overlap-group {
  background-image: url("shutterstock-1485872414-1-8.dd5c307a.webp");
  background-size: 100% 100%;
  width: 515px;
  height: 648px;
  position: relative;
}

.service .rectangle-3 {
  background-blend-mode: multiply;
  background: linear-gradient(#0000 0%, #040608 100%);
  width: 515px;
  height: 575px;
  position: absolute;
  top: 73px;
  left: 0;
}

.service .frame-4 {
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  width: 385px;
  display: flex;
  position: absolute;
  top: 276px;
  left: 64px;
}

.service .frame-5 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
  display: flex;
  position: relative;
}

.service .text-wrapper-61 {
  color: var(--neutral-0, #fff);
  letter-spacing: -.2px;
  font-family: Source Sans Pro;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.service .text-wrapper-2 {
  color: var(--neutral-0);
  letter-spacing: -.88px;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 600;
  line-height: 42px;
  position: relative;
}

.service .p {
  color: #fff;
  letter-spacing: -.2px;
  width: 349px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.service.card-default {
  width: 757px;
  height: 648px;
  overflow: hidden;
}

.service.card-FLX, .service.card-RPO {
  width: 1280px;
  height: 440px;
}

.service.card-ITS {
  width: 525px;
  height: 648px;
}

.service.card-GIC {
  height: 648px;
}

.service.state-hover.card-HRD {
  width: 515px;
  height: 648px;
}

.service.state-hover.card-GIC {
  width: 748px;
}

.service.state-default.card-HRD {
  width: 518px;
  height: 646px;
}

.service.card-GIC.state-default {
  width: 747px;
}

.service.card-default .overlap-17 {
  position: relative;
}

.service.card-FLX .overlap-17 {
  height: 440px;
  position: relative;
}

.service.state-default .overlap-17 {
  position: relative;
}

.service.card-HRD .overlap-17 {
  height: 646px;
}

.service.card-RPO .overlap-17 {
  height: 438px;
  position: relative;
}

.service.card-ITS .overlap-17, .service.card-GIC .overlap-17 {
  height: 646px;
  position: relative;
}

.service.card-default.state-default .overlap-17 {
  height: 720px;
}

.service.state-hover.card-RPO .overlap-17, .service.state-hover.card-FLX .overlap-17 {
  width: 1272px;
}

.service.card-default.state-hover .overlap-17 {
  width: 781px;
  height: 650px;
  left: -24px;
}

.service.state-default.card-HRD .overlap-17 {
  width: 515px;
}

.service.card-FLX .frame {
  height: 440px;
}

.service.card-HRD .frame {
  width: 515px;
  height: 646px;
}

.service.card-RPO .frame {
  height: 438px;
}

.service.card-ITS .frame {
  object-fit: cover;
  background-image: url("shutterstock_1485872414.fff27a49.webp");
  background-position: 100% 100%;
  width: 525px;
  height: 646px;
}

.service.card-ITS .frame:after {
  content: "";
  background-blend-mode: multiply;
  background: linear-gradient(#0000 0%, #040608 100%);
  width: 100%;
  height: 100%;
  position: absolute;
}

.service.card-GIC .frame {
  height: 646px;
}

.service.state-default.card-FLX .frame {
  background-position: 50%;
  background-size: cover;
  width: 1280px;
}

.service.card-default.state-default .frame {
  width: 757px;
  height: 720px;
}

.service.state-hover.card-RPO .frame {
  object-fit: cover;
  background-image: url("shutterstock-1485872414-1-4.d64f31a7.webp");
  background-size: 100% 100%;
}

.service.state-hover.card-FLX .frame {
  object-fit: cover;
  background-image: url("shutterstock-1485872414-1-7.357c1681.webp");
  background-size: 100% 100%;
}

.service.state-hover.card-GIC .frame {
  object-fit: cover;
  background-image: url("shutterstock_1485872414_1.1c336788.webp");
  background-size: 100% 100%;
  width: 748px;
}

.service.card-default.state-hover .frame {
  object-fit: cover;
  background-image: url("shutterstock_1485872414_2.996a90a1.webp");
  background-size: 100% 100%;
  width: 781px;
  height: 650px;
}

.service.card-RPO.state-default .frame {
  width: 1280px;
}

.service.card-GIC.state-default .frame {
  width: 747px;
}

.service.card-default .overlap-group-11 {
  background-position: 50%;
  background-size: cover;
}

.service.card-HRD .overlap-group-11 {
  background-size: 100% 100%;
  width: 518px;
  height: 646px;
}

.service.card-RPO .overlap-group-11 {
  height: 440px;
}

.service.card-ITS .overlap-group-11 {
  background-position: 50%;
  background-size: cover;
  height: 648px;
}

.service.card-GIC .overlap-group-11 {
  position: relative;
}

.service.state-default.card-FLX .overlap-group-11 {
  background-blend-mode: multiply;
  background: linear-gradient(#0000 0%, #040608 100%);
  width: 440px;
  height: 835px;
  position: relative;
  top: -198px;
  left: 197px;
  transform: rotate(90deg);
}

.service.card-default.state-default .overlap-group-11 {
  height: 648px;
}

.service.state-hover.card-RPO .overlap-group-11 {
  background-position: 50%;
  background-size: cover;
  width: 1280px;
}

.service.state-hover.card-FLX .overlap-group-11 {
  background-size: 100% 100%;
  width: 1280px;
  height: 440px;
}

.service.state-hover.card-GIC .overlap-group-11 {
  background-position: 50%;
  background-size: cover;
  width: 801px;
  height: 708px;
  top: -31px;
  left: -34px;
}

.service.card-default.state-hover .overlap-group-11 {
  height: 720px;
}

.service.card-RPO.state-default .overlap-group-11 {
  background-size: 100% 100%;
}

.service.card-GIC.state-default .overlap-group-11 {
  width: 748px;
  height: 648px;
}

.service.card-default .rectangle-2 {
  background: linear-gradient(#0000 0%, #040608 100%);
  width: 757px;
}

.service.card-FLX .rectangle-2 {
  background: linear-gradient(#0000 0%, #040608 100%);
  width: 440px;
  height: 835px;
  top: -197px;
  left: 197px;
  transform: rotate(90deg);
}

.service.card-HRD .rectangle-2 {
  background: linear-gradient(#0000 0%, #040608 100%);
  width: 515px;
  height: 442px;
  top: 204px;
}

.service.card-RPO .rectangle-2 {
  background: linear-gradient(#0000 0%, #040608 100%);
  width: 440px;
  height: 835px;
  top: -197px;
  left: 197px;
  transform: rotate(90deg);
}

.service.card-ITS .rectangle-2 {
  background: linear-gradient(#0000 0%, #040608 88.49%);
  width: 525px;
  height: 493px;
  top: 155px;
}

.service.card-GIC .rectangle-2 {
  background: linear-gradient(#0000 0%, #040608 100%);
  width: 748px;
  height: 520px;
  top: 159px;
  left: 34px;
}

.service.card-default.state-default .rectangle-2 {
  height: 465px;
  top: 183px;
}

.service.card-default.state-hover .rectangle-2 {
  height: 650px;
  left: 24px;
}

.service.card-default .frame-2 {
  gap: 32px;
  display: inline-flex;
}

.service.card-FLX .frame-2 {
  display: inline-flex;
}

.service.state-default .frame-2 {
  gap: 32px;
  left: 64px;
}

.service.card-HRD .frame-2 {
  gap: 32px;
  width: 385px;
  display: flex;
  top: 453px;
  left: 64px;
}

.service.card-RPO .frame-2 {
  gap: 32px;
  display: inline-flex;
  left: 64px;
}

.service.card-ITS .frame-2 {
  gap: 32px;
  width: 385px;
  display: flex;
}

.service.card-GIC .frame-2 {
  display: inline-flex;
}

.service.state-default.card-FLX .frame-2 {
  top: 194px;
}

.service.card-ITS.state-default .frame-2 {
  top: 284px;
}

.service.card-default.state-default .frame-2 {
  top: 404px;
}

.service.state-hover.card-RPO .frame-2 {
  top: 94px;
}

.service.state-hover.card-FLX .frame-2 {
  gap: 48px;
  top: 85px;
  left: 67px;
}

.service.state-hover.card-GIC .frame-2 {
  gap: 48px;
  top: 313px;
  left: 67px;
}

.service.card-default.state-hover .frame-2 {
  top: 303px;
  left: 88px;
}

.service.state-hover.card-ITS .frame-2 {
  top: 248px;
  left: 26px;
}

.service.card-RPO.state-default .frame-2 {
  top: 186px;
}

.service.card-GIC.state-default .frame-2 {
  top: 284px;
}

.service.card-default .frame-3, .service.card-FLX .frame-3 {
  display: inline-flex;
}

.service.card-HRD .frame-3 {
  align-self: stretch;
  width: 100%;
  display: flex;
}

.service.card-RPO .frame-3 {
  display: inline-flex;
}

.service.card-ITS .frame-3 {
  align-self: stretch;
  width: 100%;
  display: flex;
}

.service.card-GIC .frame-3 {
  display: inline-flex;
}

.service.card-default .hire-train-deploy {
  color: var(--neutral-0);
  width: 477px;
}

.service.card-FLX .hire-train-deploy {
  color: #fff;
  width: 477px;
}

.service.card-HRD .hire-train-deploy {
  color: var(--neutral-0);
  align-self: stretch;
}

.service.card-RPO .hire-train-deploy {
  color: var(--neutral-0);
  width: 477px;
}

.service.card-ITS .hire-train-deploy {
  color: var(--neutral-0);
  width: 417px;
  margin-right: -32px;
}

.service.card-GIC .hire-train-deploy {
  color: #fff;
  width: 379px;
}

.service.card-default .administrative, .service.card-FLX .administrative, .service.card-HRD .administrative {
  color: #fff;
  letter-spacing: -.53px;
  width: fit-content;
  line-height: 49px;
  background-color: #0000 !important;
}

.service.card-RPO .administrative {
  color: var(--neutral-0);
  letter-spacing: -.88px;
  width: fit-content;
  line-height: 42px;
  background-color: #0000 !important;
}

.service.card-ITS .administrative {
  color: #fff;
  letter-spacing: -.53px;
  align-self: stretch;
  width: fit-content;
  line-height: 49px;
  background-color: #0000 !important;
}

.service.card-GIC .administrative {
  color: #fff;
  letter-spacing: -.53px;
  width: fit-content;
  line-height: 49px;
  background-color: #0000 !important;
}

.service.card-default .we-are-committed-to, .service.card-FLX .we-are-committed-to {
  letter-spacing: -.2px;
  align-self: stretch;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}

.service.card-HRD .we-are-committed-to {
  letter-spacing: -.2px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}

.service.card-RPO .we-are-committed-to {
  letter-spacing: -.2px;
  width: 420px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}

.service.card-ITS .we-are-committed-to {
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  align-self: stretch;
}

.service.card-GIC .we-are-committed-to {
  letter-spacing: -.2px;
  align-self: stretch;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}

.services {
  background-color: #fff;
  flex-direction: row;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.services .carousel {
  width: 100%;
  height: auto;
}

.services .div-2 {
  background-color: #fff;
  position: relative;
}

.services .header-instance {
  width: 390px !important;
  height: 60px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.services .overlap {
  position: absolute;
}

.services .rectangle-5 {
  background-color: #172c36;
  position: absolute;
  left: 0;
}

.services .overlap-group-wrapper {
  background-color: #2e5053;
  width: 358px;
  height: 360px;
  position: absolute;
  top: 735px;
  left: 16px;
  overflow: hidden;
}

.services .overlap-group-3 {
  width: 747px;
  height: 646px;
  position: relative;
}

.services .frame-7 {
  width: 747px;
  height: 646px;
  position: absolute;
  top: 0;
  left: 0;
}

.services .shutterstock-3 {
  object-fit: cover;
  width: 584px;
  height: 507px;
  position: absolute;
  top: 0;
  left: 0;
}

.services .rectangle-6 {
  background-blend-mode: multiply;
  background: linear-gradient(#0000 0%, #040608 100%);
  width: 358px;
  height: 371px;
  position: absolute;
  top: 0;
  left: 0;
}

.services .frame-8 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 238px;
  display: flex;
  position: absolute;
  top: 164px;
  left: 46px;
}

.services .frame-9 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 12px;
  width: 238px;
  display: flex;
  position: relative;
}

.services .text-wrapper-5 {
  color: #fff;
  letter-spacing: -.8px;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 600;
  line-height: 38.4px;
  position: relative;
}

.services .text-wrapper-5-p {
  color: var(--neutral-0, #fff);
  letter-spacing: -.17px;
  align-self: stretch;
  width: 476px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  position: relative;
}

.services .instance-node {
  flex: none !important;
}

.services .overlap-wrapper {
  background-color: #2e5053;
  width: 358px;
  height: 360px;
  position: absolute;
  top: 357px;
  left: 16px;
  overflow: hidden;
}

.services .rectangle-7 {
  background-blend-mode: multiply;
  width: 358px;
  height: 360px;
  position: absolute;
  top: 0;
  left: 0;
}

.services .frame-10 {
  position: absolute;
}

.services .CTA-2 {
  display: flex !important;
  position: absolute !important;
  top: 174px !important;
  left: 104px !important;
}

.services .innovative-talent {
  text-align: center;
  font-family: Source Sans Pro, Helvetica;
  position: absolute;
}

.services .transforming-through {
  color: var(--secondary-02);
  letter-spacing: -.8px;
  text-align: center;
  height: 76px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 600;
  line-height: 38.4px;
  position: absolute;
  top: -1px;
  left: 46px;
}

.services .text-wrapper-6 {
  color: var(--neutral-10);
  letter-spacing: -.17px;
  text-align: center;
  width: 541px;
  height: 48px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 400;
  line-height: 23.8px;
  position: absolute;
  top: 78px;
  left: 95px;
}

.services .service-2 {
  background-color: #2e5053;
  width: 358px;
  height: 360px;
  position: absolute;
  top: 1111px;
  left: 16px;
  overflow: hidden;
}

.services .overlap-group-4 {
  width: 584px;
  height: 510px;
  position: relative;
  top: -3px;
}

.services .shutterstock-4 {
  object-fit: cover;
  width: 584px;
  height: 507px;
  position: absolute;
  top: 3px;
  left: 0;
}

.services .shutterstock-5 {
  object-fit: cover;
  width: 359px;
  height: 443px;
  position: absolute;
  top: 0;
  left: 0;
}

.services .service-3 {
  background-color: #2e5053;
  width: 358px;
  height: 360px;
  position: absolute;
  top: 1487px;
  left: 16px;
  overflow: hidden;
}

.services .shutterstock-6 {
  object-fit: cover;
  width: 358px;
  height: 360px;
  position: absolute;
  top: 0;
  left: 0;
}

.services .frame-11 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 238px;
  display: flex;
  position: absolute;
  top: 126px;
  left: 46px;
}

.services .service-4 {
  background-color: #2e5053;
  width: 358px;
  height: 360px;
  position: absolute;
  top: 1863px;
  left: 16px;
  overflow: hidden;
}

.services .shutterstock-7 {
  object-fit: cover;
  width: 358px;
  height: 360px;
  position: absolute;
  top: 0;
  left: 0;
}

.services .frame-12 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 238px;
  display: flex;
  position: absolute;
  top: 202px;
  left: 46px;
}

.services .service-5 {
  background-color: #2e5053;
  width: 358px;
  height: 360px;
  position: absolute;
  top: 2239px;
  left: 16px;
  overflow: hidden;
}

.services .shutterstock-8 {
  object-fit: cover;
  width: 438px;
  height: 375px;
  position: absolute;
  top: -10px;
  left: -9px;
}

.services .service-6 {
  background-color: #2e5053;
  width: 358px;
  height: 360px;
  position: absolute;
  top: 2615px;
  left: 16px;
  overflow: hidden;
}

.services .frame-13 {
  background-color: var(--neutral-10);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 14px;
  width: 1438px;
  padding: 60px 16px;
  display: flex;
  position: absolute;
  top: 3004px;
  left: 0;
  overflow-x: scroll;
}

.services .frame-14 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.services .text-wrapper-7 {
  color: #2c3e47;
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  text-align: center;
  margin-top: -1px;
  position: relative;
}

.services .frame-15 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 16px;
  width: 405px;
  display: flex;
  position: relative;
}

.services .text-wrapper-8 {
  color: #1f1f1f;
  letter-spacing: -.8px;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.4px;
  position: relative;
}

.services .text-wrapper-9 {
  color: #363636;
  font-family: var(--paragraph-REG-font-family);
  font-size: var(--paragraph-REG-font-size);
  font-style: var(--paragraph-REG-font-style);
  font-weight: var(--paragraph-REG-font-weight);
  letter-spacing: var(--paragraph-REG-letter-spacing);
  line-height: var(--paragraph-REG-line-height);
  align-self: stretch;
  position: relative;
}

.services .frame-16 {
  flex: none;
  align-items: center;
  gap: 24px;
  width: 915px;
  display: flex;
  position: relative;
}

.services .frame-wrapper-services {
  background: conic-gradient(from 180deg, #4d9f98 0deg, #2e5053 360deg);
  border-radius: 12px;
  width: 321px;
  height: 293px;
  position: relative;
  overflow: hidden;
}

.services .frame-wrapper-in {
  background-image: url("frame-3800.7e28e8a4.svg");
  background-size: 100% 100%;
  border-radius: 12px;
  width: 321px;
  height: 100%;
  position: absolute;
  overflow: hidden;
}

.services .frame-17 {
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  display: inline-flex;
  position: relative;
  top: 117px;
  left: 32px;
}

.services .frame-18 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.services .text-wrapper-10 {
  color: var(--neutral-0);
  letter-spacing: -.2px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  position: relative;
}

.services .text-wrapper-11 {
  color: #e9e9e9;
  font-family: var(--paragraph-REG-font-family);
  font-size: var(--paragraph-REG-font-size);
  font-style: var(--paragraph-REG-font-style);
  font-weight: var(--paragraph-REG-font-weight);
  letter-spacing: var(--paragraph-REG-letter-spacing);
  line-height: var(--paragraph-REG-line-height);
  width: 281px;
  position: relative;
}

.services .button {
  all: unset;
  box-sizing: border-box;
  background-color: #4d9f98;
  border-radius: 56px;
  flex: none;
  align-items: center;
  gap: 10px;
  padding: 12px 16px;
  display: inline-flex;
  position: relative;
}

.services .this-is-who-we-are-2 {
  color: var(--neutral-0);
  letter-spacing: -.14px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 14px;
  font-weight: 700;
  line-height: 19.6px;
  position: relative;
}

.services .arrow-forward-3 {
  width: 17px;
  height: 17px;
  position: relative;
}

.services .frame-19 {
  background-color: #1f1f1f;
  border-radius: 12px;
  width: 328px;
  height: 293px;
  position: relative;
  overflow: hidden;
}

.services .overlap-group-5 {
  width: 906px;
  height: 891px;
  position: absolute;
  top: -218px;
  left: -282px;
}

.services .group {
  width: 29px;
  height: 288px;
  position: absolute;
  top: 218px;
  left: 282px;
}

.services .ellipse {
  filter: blur(90px);
  background: radial-gradient(50% 50%, #0711037a 39.06%, #2e505300 100%);
  border-radius: 224.63px / 273.03px;
  width: 449px;
  height: 546px;
  position: absolute;
  top: 72px;
  left: 129px;
  transform: rotate(-127.7deg);
}

.services .ellipse-2 {
  filter: blur(90px);
  background: radial-gradient(50% 50%, #4d9f9866 46.4%, #2e505300 100%);
  border-radius: 245.12px / 230px;
  width: 490px;
  height: 460px;
  position: absolute;
  top: 325px;
  left: 325px;
  transform: rotate(44.92deg);
}

.services .frame-20 {
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 335px;
  left: 318px;
}

.services .text-wrapper-12 {
  color: #e9e9e9;
  font-family: var(--paragraph-REG-font-family);
  font-size: var(--paragraph-REG-font-size);
  font-style: var(--paragraph-REG-font-style);
  font-weight: var(--paragraph-REG-font-weight);
  letter-spacing: var(--paragraph-REG-letter-spacing);
  line-height: var(--paragraph-REG-line-height);
  width: 214px;
  position: relative;
}

.services .arrow-forward-4 {
  width: 17px;
  height: 17px;
  margin-top: -6422.5px;
  margin-right: -9905px;
  position: relative;
}

.services .group-2 {
  width: 642px;
  height: 554px;
  position: absolute;
  top: -6270px;
  left: 10779px;
}

.services .frame-21 {
  background-color: #df0000b8;
  border-radius: 12px;
  width: 321px;
  height: 293px;
  margin-right: -103px;
  position: relative;
  overflow: hidden;
}

.services .overlap-2 {
  width: 867px;
  height: 872px;
  position: absolute;
  top: -200px;
  left: -253px;
}

.services .ellipse-3 {
  filter: blur(90px);
  background: radial-gradient(50% 50%, #4d9f9866 46.4%, #2e505300 100%);
  border-radius: 242.51px / 227.55px;
  width: 485px;
  height: 455px;
  position: absolute;
  top: 312px;
  left: 292px;
  transform: rotate(45.54deg);
}

.services .ellipse-4 {
  filter: blur(90px);
  background: radial-gradient(50% 50%, #9e000d7a 39.06%, #9e000d00 100%);
  border-radius: 268.93px / 269.38px;
  width: 538px;
  height: 539px;
  position: absolute;
  top: 108px;
  left: 108px;
  transform: rotate(-127.1deg);
}

.services .frame-22 {
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 317px;
  left: 282px;
}

.services .arrow-forward-5 {
  width: 17px;
  height: 17px;
  margin-top: -6422.5px;
  margin-right: -9560px;
  position: relative;
}

.services .overlap-3 {
  width: 881px;
  height: 786px;
  position: absolute;
  top: -6234px;
  left: 9306px;
}

.services .group-3 {
  width: 679px;
  height: 786px;
  position: absolute;
  top: 0;
  left: 202px;
}

.services .group-4 {
  width: 799px;
  height: 698px;
  position: absolute;
  top: 2px;
  left: 0;
}

.services .frame-23 {
  background-color: var(--neutral-10);
  align-items: center;
  gap: 14px;
  width: 1438px;
  height: 506px;
  padding: 0 80px;
  display: flex;
  position: absolute;
  top: 3181px;
  left: 3px;
}

.services .text-wrapper-13 {
  color: #1f1f1f;
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.services .frame-24 {
  align-self: stretch;
  align-items: center;
  gap: 24px;
  width: 938px;
  padding: 56px 0;
  display: flex;
  position: relative;
}

.services .frame-25 {
  background: conic-gradient(from 180deg, #4d9f98 0deg, #2e5053 360deg);
  border-radius: 12px;
  width: 470px;
  height: 430px;
  margin-top: -18px;
  margin-bottom: -18px;
  position: relative;
  overflow: hidden;
}

.services .overlap-group-6 {
  background-image: url("frame-3800-3.04fbd8d4.svg");
  background-size: 100% 100%;
  width: 480px;
  height: 430px;
}

.services .frame-26 {
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: relative;
  top: 194px;
  left: 75px;
}

.services .frame-27 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.services .text-wrapper-14 {
  color: var(--neutral-0);
  letter-spacing: -.24px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 33.6px;
  position: relative;
}

.services .CTA-3 {
  border: unset !important;
  border-color: unset !important;
  background-color: #4d9f98 !important;
  flex: none !important;
}

.services .CTA-4 {
  color: var(--neutral-0, #fff);
  font-family: var(--subtitle-BLD-font-family) !important;
  font-size: var(--subtitle-BLD-font-size) !important;
  font-style: var(--subtitle-BLD-font-style) !important;
  font-weight: var(--subtitle-BLD-font-weight) !important;
  letter-spacing: var(--subtitle-BLD-letter-spacing) !important;
  line-height: var(--subtitle-BLD-line-height) !important;
}

.services .frame-28 {
  background-color: #1f1f1f;
  border-radius: 12px;
  width: 480px;
  height: 430px;
  margin-top: -18px;
  margin-bottom: -18px;
  margin-right: -59px;
  position: relative;
  overflow: hidden;
}

.services .overlap-4 {
  width: 1757px;
  height: 1592px;
  position: relative;
  top: -654px;
  left: -531px;
}

.services .group-5 {
  width: 1119px;
  height: 1028px;
  position: absolute;
  top: 0;
  left: 0;
}

.services .group-6 {
  width: 1173px;
  height: 1220px;
  position: absolute;
  top: 130px;
  left: 584px;
}

.services .ellipse-5 {
  filter: blur(90px);
  background: radial-gradient(50% 50%, #0711037a 39.06%, #2e505300 100%);
  border-radius: 329.31px / 399.97px;
  width: 659px;
  height: 800px;
  position: absolute;
  top: 391px;
  left: 306px;
  transform: rotate(-127.62deg);
}

.services .ellipse-6 {
  filter: blur(90px);
  background: radial-gradient(50% 50%, #4d9f9866 46.4%, #2e505300 100%);
  border-radius: 359.21px / 337.06px;
  width: 718px;
  height: 674px;
  position: absolute;
  top: 762px;
  left: 593px;
  transform: rotate(45deg);
}

.services .frame-29 {
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  width: 299px;
  display: flex;
  position: absolute;
  top: 848px;
  left: 611px;
}

.services .frame-30 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.services .text-wrapper-15 {
  color: #e9e9e9;
  font-family: var(--paragraph-REG-font-family);
  font-size: var(--paragraph-REG-font-size);
  font-style: var(--paragraph-REG-font-style);
  font-weight: var(--paragraph-REG-font-weight);
  letter-spacing: var(--paragraph-REG-letter-spacing);
  line-height: var(--paragraph-REG-line-height);
  width: 230px;
  position: relative;
}

.services .frame-31 {
  background-color: #df0000b8;
  border-radius: 12px;
  width: 470px;
  height: 430px;
  margin-top: -18px;
  margin-bottom: -18px;
  margin-right: -553px;
  position: relative;
  overflow: hidden;
}

.services .overlap-5 {
  width: 1536px;
  height: 1852px;
  position: relative;
  top: -786px;
  left: -414px;
}

.services .ellipse-7 {
  filter: blur(90px);
  background: radial-gradient(50% 50%, #4d9f9866 46.4%, #2e505300 100%);
  border-radius: 355.49px / 333.57px;
  width: 711px;
  height: 667px;
  position: absolute;
  top: 902px;
  left: 471px;
  transform: rotate(45.6deg);
}

.services .ellipse-8 {
  filter: blur(90px);
  background: radial-gradient(50% 50%, #9e000d7a 39.06%, #9e000d00 100%);
  border-radius: 394.34px / 394.76px;
  width: 789px;
  height: 790px;
  position: absolute;
  top: 602px;
  left: 201px;
  transform: rotate(-127.04deg);
}

.services .group-7 {
  width: 1204px;
  height: 1348px;
  position: absolute;
  top: 504px;
  left: 295px;
}

.services .group-8 {
  width: 1536px;
  height: 1541px;
  position: absolute;
  top: 0;
  left: 0;
}

.services .frame-32 {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: absolute;
  top: 950px;
  left: 494px;
}

.services .get-the-assistance {
  color: #e9e9e9;
  font-family: var(--paragraph-REG-font-family);
  font-size: var(--paragraph-REG-font-size);
  font-style: var(--paragraph-REG-font-style);
  font-weight: var(--paragraph-REG-font-weight);
  letter-spacing: var(--paragraph-REG-letter-spacing);
  line-height: var(--paragraph-REG-line-height);
  width: 272px;
  position: relative;
}

.services .CTA-5 {
  margin-right: -1px !important;
}

.services .CTA-6 {
  border: unset !important;
  border-color: unset !important;
  background-color: #4d9f98 !important;
  justify-content: center !important;
  align-items: flex-end !important;
  width: 182px !important;
  transition: all .3s !important;
  display: flex !important;
  position: absolute !important;
  top: 329px !important;
  left: 629px !important;
}

.services .CTA-7 {
  margin-left: -1px !important;
  font-weight: 700 !important;
}

.services .service-instance {
  position: absolute !important;
  top: 896px !important;
  left: 82px !important;
}

.services .service-7 {
  position: absolute !important;
  top: 894px !important;
  left: 837px !important;
}

.services .service-8 {
  position: absolute !important;
  top: 1552px !important;
  left: 79px !important;
}

.services .property-default-instance {
  position: absolute !important;
  top: 428px !important;
  left: 82px !important;
}

.services .property-1-default-instance {
  background-image: url("shutterstock-1485872414-1-3.d7987b8d.svg") !important;
}

.services .service-9 {
  position: absolute !important;
  top: 2006px !important;
  left: 79px !important;
}

.services .service-10 {
  position: absolute !important;
  top: 2008px !important;
  left: 610px !important;
}

.services .service-11 {
  position: absolute !important;
  top: 2675px !important;
  left: 83px !important;
}

.services .overlap-6 {
  background: var(--secondary-04, #2c3e47);
  position: absolute;
}

.services .group-11 {
  width: 1440px;
  height: 360px;
  position: absolute;
  top: 0;
  left: 0;
}

.services .overlap-7 {
  height: 360px;
  position: relative;
}

.services .frame-42 {
  background-color: var(--neutral-70);
  width: 1440px;
  height: 360px;
  position: absolute;
  top: 0;
  left: 0;
}

.services .frame-43 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 32px;
  display: inline-flex;
  position: relative;
  top: 69px;
  left: 152px;
}

.services .frame-44 {
  flex-direction: column;
  flex: none;
  align-items: flex-end;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.services .text-wrapper-25 {
  color: var(--neutral-0);
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.services .are-we-a-good-fit {
  color: var(--neutral-10);
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  text-align: right;
  width: 429px;
  position: relative;
}

.services .CTA-10 {
  all: unset;
  box-sizing: border-box;
  background-color: #2c3e47;
  flex: none;
  align-items: center;
  gap: 10px;
  padding: 12px 16px;
  display: inline-flex;
  position: relative;
}

.services .text-wrapper-26 {
  color: var(--neutral-0);
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.services .arrow-forward-6 {
  width: 24px;
  height: 24px;
  position: relative;
}

.services .frame-45 {
  background-color: var(--secondary-03);
  width: 720px;
  height: 360px;
  position: absolute;
  top: 0;
  left: 0;
}

.services .overlap-group-8 {
  background-color: var(--neutral-10);
  width: 720px;
  height: 360px;
  position: absolute;
  top: 0;
  left: 0;
}

.services .frame-46 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 32px;
  display: inline-flex;
  position: relative;
  top: 49px;
  left: 128px;
}

.services .text-wrapper-27 {
  color: #2c3e47;
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.services .you-are-just-one {
  color: #363636;
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  text-align: right;
  width: 500px;
  position: relative;
}

.services .frame-47 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 52px;
  left: 817px;
}

.services .frame-48 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.services .text-wrapper-28 {
  color: var(--neutral-0);
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.services .why-waste-time-by {
  color: var(--neutral-10);
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  width: 532px;
  position: relative;
}

.services .CTA-11 {
  cursor: pointer;
  background-color: var(--secondary-02);
  box-sizing: border-box;
  border: none;
  outline: none;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  display: inline-flex;
  position: absolute;
  top: 259px;
  left: 817px;
}

.services .container {
  align-items: center;
  gap: 24px;
  width: 843px;
  height: 354px;
  padding: 56px 0;
  display: flex;
  position: absolute;
  top: 325px;
  left: 0;
  overflow: hidden;
}

.services .BFSI {
  background-image: url("rectangle-24.79f91441.webp");
  width: 264px;
  height: 240px;
  position: relative;
}

.services .text-wrapper-40 {
  color: #fff;
  letter-spacing: -.24px;
  white-space: nowrap;
  height: 34px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
  position: absolute;
  top: 179px;
  left: 27px;
}

.services .IT-services {
  background-image: url("rectangle-23.69c6a4d4.webp");
  background-position: 50%;
  background-size: cover;
  width: 263px;
  height: 240px;
  position: relative;
}

.services .telecom {
  background-image: url("rectangle-25.0cfc0629.webp");
  background-size: 100% 100%;
  width: 263px;
  height: 240px;
  position: relative;
}

.services .overlap-group-9 {
  width: 79px;
  height: 34px;
  position: relative;
  top: 179px;
  left: 27px;
}

.services .text-wrapper-41 {
  color: #fff;
  letter-spacing: -.24px;
  white-space: nowrap;
  height: 34px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
  position: absolute;
  top: 0;
  left: 0;
}

.services .telecom-2 {
  background-image: url("rectangle-25-1.60d7a48a.webp");
  background-position: 50%;
  background-size: cover;
  width: 263px;
  height: 240px;
  position: relative;
}

.services .telecom-3 {
  background-image: url("rectangle-25-2.09a31b27.webp");
  background-position: 50%;
  background-size: cover;
  width: 263px;
  height: 240px;
  position: relative;
}

.services .frame-50 {
  flex-direction: column;
  align-items: center;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 60px;
  left: 233px;
}

.services .frame-51 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 12px;
  display: inline-flex;
  position: relative;
}

.services .text-wrapper-42 {
  color: #fff;
  font-family: var(--h3-REG-font-family);
  font-size: var(--h3-REG-font-size);
  font-style: var(--h3-REG-font-style);
  font-weight: var(--h3-REG-font-weight);
  letter-spacing: var(--h3-REG-letter-spacing);
  line-height: var(--h3-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.services .are-we-a-good-fit-2 {
  color: var(--neutral-30);
  letter-spacing: -.18px;
  text-align: center;
  width: 326px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
  position: relative;
}

.services .CTA-instance-wrapper {
  flex: none;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.services .CTA-12 {
  font-weight: 700 !important;
}

.services .CTA-13 {
  border: unset !important;
  border-color: unset !important;
  background-color: #4d9f98 !important;
  justify-content: center !important;
  align-items: flex-end !important;
  width: 182px !important;
  display: flex !important;
}

.services .frame-52 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 465px;
  display: flex;
  position: absolute;
  top: 4762px;
  left: 3160px;
}

.services .text-wrapper-43 {
  color: #4d9f98;
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.services .text-wrapper-44 {
  color: var(--neutral-0);
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  text-align: center;
  width: 465px;
  margin-top: -1px;
  position: relative;
}

.services .text-wrapper-45 {
  color: var(--neutral-0);
  letter-spacing: -.17px;
  text-align: center;
  width: 465px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 400;
  line-height: 23.8px;
  position: relative;
}

.services .frame-53 {
  background-color: #1f1f1f;
  width: 480px;
  height: 430px;
  position: absolute;
  top: 5004px;
  left: 3155px;
  overflow: hidden;
}

.services .group-14 {
  width: 940px;
  height: 812px;
  position: absolute;
  top: -2568px;
  left: 6616px;
}

.services .group-15 {
  width: 940px;
  height: 812px;
  position: absolute;
  top: -2276px;
  left: 8373px;
}

.services .overlap-9 {
  width: 1328px;
  height: 1306px;
  position: absolute;
  top: -368px;
  left: -414px;
}

.services .ellipse-10 {
  filter: blur(90px);
  background: radial-gradient(50% 50%, #0711037a 39.06%, #2e505300 100%);
  border-radius: 329.31px / 399.97px;
  width: 659px;
  height: 800px;
  position: absolute;
  top: 105px;
  left: 189px;
  transform: rotate(-127.62deg);
}

.services .ellipse-11 {
  filter: blur(90px);
  background: radial-gradient(50% 50%, #4d9f9866 46.4%, #2e505300 100%);
  border-radius: 359.21px / 337.06px;
  width: 718px;
  height: 674px;
  position: absolute;
  top: 476px;
  left: 476px;
  transform: rotate(45deg);
}

.services .frame-54 {
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 552px;
  left: 494px;
}

.services .CTA-14 {
  margin-top: -2484px !important;
  margin-right: -7067px !important;
}

.services .frame-55 {
  background-color: #df0000b8;
  width: 470px;
  height: 430px;
  position: absolute;
  top: 5004px;
  left: 3643px;
  overflow: hidden;
}

.services .overlap-10 {
  width: 1271px;
  height: 1278px;
  position: absolute;
  top: -342px;
  left: -371px;
}

.services .ellipse-12 {
  filter: blur(90px);
  background: radial-gradient(50% 50%, #4d9f9866 46.4%, #2e505300 100%);
  border-radius: 355.49px / 333.57px;
  width: 711px;
  height: 667px;
  position: absolute;
  top: 457px;
  left: 428px;
  transform: rotate(45.6deg);
}

.services .ellipse-13 {
  filter: blur(90px);
  background: radial-gradient(50% 50%, #9e000d7a 39.06%, #9e000d00 100%);
  border-radius: 394.34px / 394.76px;
  width: 789px;
  height: 790px;
  position: absolute;
  top: 158px;
  left: 158px;
  transform: rotate(-127.04deg);
}

.services .frame-56 {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: absolute;
  top: 526px;
  left: 447px;
}

.services .text-wrapper-46 {
  color: #e9e9e9;
  font-family: var(--paragraph-REG-font-family);
  font-size: var(--paragraph-REG-font-size);
  font-style: var(--paragraph-REG-font-style);
  font-weight: var(--paragraph-REG-font-weight);
  letter-spacing: var(--paragraph-REG-letter-spacing);
  line-height: var(--paragraph-REG-line-height);
  width: 244px;
  position: relative;
}

.services .overlap-11 {
  width: 1290px;
  height: 1154px;
  position: absolute;
  top: -2223px;
  left: 6245px;
}

.services .group-16 {
  width: 995px;
  height: 1154px;
  position: absolute;
  top: 0;
  left: 295px;
}

.services .group-17 {
  width: 1171px;
  height: 1024px;
  position: absolute;
  top: 3px;
  left: 0;
}

.services .frame-57 {
  background: conic-gradient(from 180deg, #4d9f98 0deg, #2e5053 360deg);
  width: 470px;
  height: 430px;
  position: absolute;
  top: 5004px;
  left: 2677px;
  overflow: hidden;
}

.services .frame-58 {
  width: 480px;
  height: 430px;
  position: absolute;
  top: -2158px;
  left: 7625px;
}

.services .frame-59 {
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 184px;
  left: 75px;
}

.services .CTA-15 {
  border: unset !important;
  border-color: unset !important;
  background-color: #2c3e47 !important;
  flex: none !important;
}

.services .CTA-16 {
  margin-top: -2484px !important;
  margin-right: -7549.59px !important;
}

.services .rectangle-8 {
  background-color: #f6f7f8;
  width: 1440px;
  height: 307px;
  position: absolute;
  top: 4039px;
  left: 0;
}

.services .frame-60 {
  position: absolute;
}

.services .overlap-12 {
  position: relative;
}

.services .section-industries {
  background-color: var(--secondary-04);
  width: 1440px;
  height: 356px;
  position: absolute;
  top: 0;
  left: 0;
}

.services .frame-61 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 75px;
  left: 80px;
}

.services .frame-62 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: relative;
}

.services .are-we-a-good-fit-3 {
  color: var(--neutral-30);
  letter-spacing: -.2px;
  width: 326px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.services .frame-63 {
  flex-direction: column;
  align-items: center;
  gap: 25px;
  display: inline-flex;
  position: absolute;
  top: 423px;
  left: 152px;
}

.services .frame-64 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 51px;
  display: inline-flex;
  position: relative;
}

.services .frame-65 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 26px;
  display: inline-flex;
  position: relative;
}

.services .frame-66 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.services .frame-67 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.services .text-wrapper-47 {
  color: var(--neutral-60);
  letter-spacing: -.2px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  position: relative;
}

.services .frame-68 {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 29px;
  display: inline-flex;
  position: relative;
}

.services .mercedes-benz-logo {
  object-fit: cover;
  width: 248px;
  height: 63px;
  position: relative;
}

.services .GE-healthcare-logo {
  object-fit: cover;
  width: 164px;
  height: 92px;
  position: relative;
}

.services .sony-logo {
  object-fit: cover;
  width: 123px;
  height: 22px;
  position: relative;
}

.services .shell-logo {
  object-fit: cover;
  width: 115px;
  height: 65px;
  position: relative;
}

.services .amazon {
  object-fit: cover;
  width: 178px;
  height: 55px;
  position: relative;
}

.services .wipro-logo-new-og {
  object-fit: cover;
  width: 171px;
  height: 89px;
  position: relative;
}

.services .container-2 {
  align-items: center;
  gap: 24px;
  width: 860px;
  height: 354px;
  padding: 56px 0;
  display: flex;
  position: absolute;
  top: 2px;
  left: 551px;
  overflow: hidden;
}

.services .BFSI-2 {
  background-image: url("rectangle-24-1.b02836c3.webp");
  background-size: 100% 100%;
  width: 264px;
  height: 240px;
  position: relative;
}

.services .BG-image {
  width: 283px;
  height: 335px;
  position: absolute;
  top: 0;
  left: 0;
}

.services .UI-element {
  background-color: #fff;
  position: absolute;
}

.services .checkmark {
  background-color: var(--primary-light);
  border-radius: 21.86px;
  width: 44px;
  height: 44px;
  position: absolute;
  top: 19px;
  left: 21px;
}

.services .icon {
  width: 23px;
  height: 23px;
  position: absolute;
  top: 10px;
  left: 10px;
}

.services .headline {
  color: var(--dark-green);
  letter-spacing: 0;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 16.4px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 18px;
  left: 78px;
}

.services .mockup-text {
  background: var(--border, #20484f1a);
  border-radius: 8.2px;
  width: 186px;
  height: 8px;
  position: absolute;
  top: 51px;
  left: 83px;
}

.services .mockup-text-2 {
  background: var(--border, #20484f1a);
  border-radius: 8.2px;
  width: 124px;
  height: 8px;
  position: absolute;
  top: 66px;
  left: 83px;
}

.services .fake-button {
  background: var(--Primary-Green, #b8e4da);
  border-radius: 5.46px;
  width: 75px;
  height: 25px;
  position: absolute;
  top: 90px;
  left: 83px;
}

.services .arrow-forward-ios {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 18px;
  left: 18px;
}

.services .hero-sections {
  background-color: var(--pale-grey);
  width: 1447px;
  height: 598px;
  position: absolute;
  top: 4040px;
  left: -4px;
}

.services .overlap-13 {
  width: 596px;
  height: 153px;
  position: absolute;
  top: 90px;
  left: 80px;
}

.services .headline-2 {
  color: var(--secondary-03);
  letter-spacing: -1.8px;
  width: 579px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 60px;
  font-weight: 700;
  line-height: 68.7px;
  position: absolute;
  top: 84px;
  left: 17px;
}

.services .copy-of-CYBER-SEC {
  object-fit: cover;
  width: 178px;
  height: 86px;
  position: absolute;
  top: 0;
  left: 0;
}

.services .overlap-14 {
  width: 393px;
  height: 434px;
  position: absolute;
  top: 86px;
  left: 908px;
}

.services .BG-image-2 {
  width: 319px;
  height: 377px;
  position: absolute;
  top: 0;
  left: 74px;
}

.services .UI-element-2 {
  background-color: #fff;
  border-radius: 9.23px;
  width: 332px;
  height: 148px;
  position: absolute;
  top: 285px;
  left: 0;
  box-shadow: 0 3.08px 30.76px #8a8a8a0a;
}

.services .icon-wrapper {
  background-color: var(--primary-light);
  border-radius: 24.61px;
  width: 49px;
  height: 49px;
  position: absolute;
  top: 22px;
  left: 24px;
}

.services .icon-2 {
  width: 26px;
  height: 26px;
  position: absolute;
  top: 12px;
  left: 12px;
}

.services .headline-3 {
  color: var(--dark-green);
  letter-spacing: 0;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 18.5px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 21px;
  left: 88px;
}

.services .mockup-text-3 {
  background: var(--border, #20484f1a);
  border-radius: 9.23px;
  width: 210px;
  height: 8px;
  position: absolute;
  top: 57px;
  left: 93px;
}

.services .mockup-text-4 {
  background: var(--border, #20484f1a);
  border-radius: 9.23px;
  width: 140px;
  height: 8px;
  position: absolute;
  top: 75px;
  left: 93px;
}

.services .fake-button-2 {
  background: var(--Primary-Green, #b8e4da);
  border-radius: 6.15px;
  width: 85px;
  height: 28px;
  position: absolute;
  top: 102px;
  left: 93px;
}

.services .at-diguro-cyber-wrapper {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: absolute;
  top: 285px;
  left: 97px;
}

.services .at-diguro-cyber {
  color: var(--neutral-80);
  letter-spacing: -.17px;
  width: 626px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 400;
  line-height: 23.8px;
  position: relative;
}

.services .span {
  color: #363636;
  letter-spacing: -.17px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 400;
  line-height: 23.8px;
}

.services .text-wrapper-48 {
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
}

.services .group-18 {
  width: 326px;
  height: 521px;
  position: absolute;
  top: 4423px;
  left: 37px;
}

.services .headline-4 {
  color: var(--secondary-03);
  letter-spacing: -.96px;
  white-space: nowrap;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 700;
  line-height: 36.6px;
  position: absolute;
  top: 73px;
  left: 0;
}

.services .CTA-17 {
  border: unset !important;
  border-color: unset !important;
  background-color: #4d9f98 !important;
  justify-content: center !important;
  align-items: flex-end !important;
  width: 182px !important;
  display: flex !important;
  position: absolute !important;
  top: 473px !important;
  left: 0 !important;
}

.services .copy-of-CYBER-SEC-2 {
  width: 161px;
  height: 66px;
  position: absolute;
  top: 0;
  left: 0;
}

.services .frame-69 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: absolute;
  top: 117px;
  left: 0;
}

.services .at-diguro-cyber-2 {
  color: var(--neutral-80);
  letter-spacing: -.16px;
  width: 324px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  position: relative;
}

.services .text-wrapper-49 {
  color: #363636;
  letter-spacing: -.16px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
}

.services .text-wrapper-50 {
  letter-spacing: -.17px;
  font-size: 17px;
  line-height: 23.8px;
}

.services .text-wrapper-51 {
  font-weight: 600;
}

.services .overlap-15 {
  width: 742px;
  height: 307px;
  position: absolute;
  top: 4389px;
  left: -185px;
}

.services .rectangle-9 {
  background-color: #f6f7f8;
  width: 390px;
  height: 307px;
  position: absolute;
  top: 0;
  left: 185px;
}

.services .frame-70 {
  flex-direction: column;
  align-items: center;
  gap: 25px;
  display: inline-flex;
  position: absolute;
  top: 76px;
  left: 0;
}

.services .mercedes-benz-logo-2 {
  object-fit: cover;
  width: 148px;
  height: 37px;
  position: relative;
}

.services .GE-healthcare-logo-2 {
  object-fit: cover;
  width: 98px;
  height: 55px;
  position: relative;
}

.services .sony-logo-2 {
  object-fit: cover;
  width: 74px;
  height: 13px;
  position: relative;
}

.services .shell-logo-2 {
  object-fit: cover;
  width: 69px;
  height: 39px;
  position: relative;
}

.services .amazon-2 {
  object-fit: cover;
  width: 70px;
  height: 33px;
  position: relative;
}

.services .wipro-logo-new-og-2 {
  object-fit: cover;
  width: 102px;
  height: 53px;
  position: relative;
}

.services .group-19 {
  width: 390px;
  height: 360px;
  position: absolute;
  top: 4694px;
  left: 0;
}

.services .frame-71 {
  height: 360px;
}

.services .overlap-group-10 {
  background-color: var(--neutral-70);
  width: 1440px;
  height: 360px;
}

.services .frame-72 {
  flex-direction: column;
  align-items: center;
  gap: 24px;
  display: inline-flex;
  position: relative;
  top: 85px;
  left: 41px;
}

.services .text-wrapper-52 {
  color: var(--neutral-0);
  letter-spacing: -.8px;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.4px;
  position: relative;
}

.services .are-we-a-good-fit-4 {
  color: var(--neutral-10);
  letter-spacing: -.2px;
  text-align: center;
  width: 309px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.services .CTA-18 {
  cursor: pointer;
  background-color: var(--secondary-02);
  box-sizing: border-box;
  border: none;
  outline: none;
  flex: none;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  display: inline-flex;
  position: relative;
}

.hire {
  flex-direction: row;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.hire .div-2 {
  position: relative;
}

.hire .header {
  width: 390px !important;
  height: 60px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.hire .overlap-group-wrapper {
  background-color: #172c36;
  width: 390px;
  height: 442px;
  position: absolute;
  top: 60px;
  left: 0;
}

.hire .overlap-group-2 {
  height: 442px;
  position: relative;
}

.hire .rectangle-2 {
  width: 262px;
  height: 367px;
  position: absolute;
  top: 0;
  left: 128px;
}

.hire .rectangle-3 {
  width: 267px;
  height: 339px;
  position: absolute;
  top: 103px;
  left: 0;
}

.hire .frame-4 {
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 0 54px;
  display: inline-flex;
  position: absolute;
  top: 86px;
  left: 0;
}

.hire .text-wrapper-5 {
  color: var(--secondary-02);
  white-space: nowrap;
  letter-spacing: -.8px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 600;
  line-height: 38.4px;
  position: relative;
}

.hire .with-over-global {
  color: #0000;
  letter-spacing: -.16px;
  text-align: center;
  width: 282px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  position: relative;
}

.hire .span {
  color: #fff;
}

.hire .text-wrapper-6 {
  color: #00dfc2;
}

.hire .CTA-instance {
  flex: none !important;
}

.hire .footer {
  background-color: var(--white);
  width: 390px;
  height: 860px;
  position: absolute;
  top: 6880px;
  left: 0;
  overflow: hidden;
}

.hire .overlap {
  width: 390px;
  height: 907px;
  position: relative;
}

.hire .frame-5 {
  background-color: var(--neutral-90);
  width: 390px;
  height: 907px;
  position: absolute;
  top: 0;
  left: 0;
}

.hire .frame-6 {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: absolute;
  top: 115px;
  left: 26px;
}

.hire .text-wrapper-7 {
  color: #fff;
  font-family: var(--paragraph-ALCPS-font-family);
  font-size: var(--paragraph-ALCPS-font-size);
  font-style: var(--paragraph-ALCPS-font-style);
  font-weight: var(--paragraph-ALCPS-font-weight);
  letter-spacing: var(--paragraph-ALCPS-letter-spacing);
  line-height: var(--paragraph-ALCPS-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.hire .text-wrapper-8 {
  color: #f6f7f8;
  font-family: var(--paragraph-REG-font-family);
  font-size: var(--paragraph-REG-font-size);
  font-style: var(--paragraph-REG-font-style);
  font-weight: var(--paragraph-REG-font-weight);
  letter-spacing: var(--paragraph-REG-letter-spacing);
  line-height: var(--paragraph-REG-line-height);
  width: 324px;
  position: relative;
}

.hire .DIV {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 113px;
  display: flex;
  position: absolute;
  top: 272px;
  left: 195px;
}

.hire .text-wrapper-9 {
  color: var(--white);
  letter-spacing: -.2px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.hire .text-wrapper-10 {
  color: var(--white);
  letter-spacing: -.2px;
  white-space: nowrap;
  width: fit-content;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.hire .DIV-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 113px;
  display: flex;
  position: absolute;
  top: 272px;
  left: 26px;
}

.hire .frame-7 {
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 583px;
  left: 69px;
}

.hire .group {
  width: 37px;
  height: 52px;
  position: relative;
}

.hire .text-wrapper-11 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 38px;
  left: 1px;
}

.hire .face-book {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 0;
  left: 0;
}

.hire .frame-8 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.hire .linkedin {
  flex: none;
  position: relative;
}

.hire .text-wrapper-12 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  width: fit-content;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: relative;
}

.hire .facebook {
  width: 35px;
  height: 35px;
  position: relative;
}

.hire .frame-9 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.hire .logo {
  width: 116px;
  height: 56px;
  position: absolute;
  top: 39px;
  left: 24px;
}

.hire .frame-wrapper {
  width: 295px;
  height: 64px;
  position: absolute;
  top: 732px;
  left: 48px;
}

.hire .frame-10 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 295px;
  display: flex;
  position: relative;
}

.hire .text-wrapper-13 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.hire .ellipse-3 {
  background-color: var(--grey-01);
  border-radius: 1px / 1.5px;
  width: 2px;
  height: 3px;
  position: relative;
}

.hire .text-wrapper-14 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  text-align: center;
  align-self: stretch;
  position: relative;
}

.hire .frame-11 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  width: 358px;
  display: flex;
  position: absolute;
  top: 541px;
  left: 16px;
}

.hire .div-wrapper {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 16px;
  width: 358px;
  display: flex;
  position: relative;
}

.hire .text-wrapper-15 {
  color: #2e5053;
  letter-spacing: -.24px;
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  position: relative;
}

.hire .frame-12 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  display: flex;
  position: relative;
}

.hire .frame-13 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 4px;
  width: 100%;
  display: flex;
  position: relative;
}

.hire .text-wrapper-16 {
  color: #5c5c5c;
  letter-spacing: -.48px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 700;
  line-height: 38.4px;
  position: relative;
}

.hire .text-wrapper-17 {
  color: var(--neutral-70);
  letter-spacing: -.16px;
  white-space: nowrap;
  width: fit-content;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  position: relative;
}

.hire .text-wrapper-18 {
  color: #5c5c5c;
  letter-spacing: -.48px;
  text-align: center;
  width: 69px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 700;
  line-height: 38.4px;
  position: relative;
}

.hire .text-wrapper-19 {
  color: #060606;
  letter-spacing: -.16px;
  white-space: nowrap;
  width: fit-content;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  position: relative;
}

.hire .frame-14 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.hire .overlap-2 {
  position: absolute;
}

.hire .group-2 {
  background-color: var(--neutral-40);
  width: 1441px;
  height: 1051px;
  position: absolute;
  top: 0;
  left: 0;
}

.hire .frame-15 {
  flex-direction: column;
  align-items: flex-start;
  gap: 22px;
  width: 572px;
  height: 393px;
  padding: 32px 0;
  display: flex;
  position: relative;
  top: 53px;
  left: 735px;
}

.hire .frame-16 {
  flex: none;
  align-items: flex-start;
  gap: 16px;
  width: 497.74px;
  display: flex;
  position: relative;
}

.hire .frame-17 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 4px;
  display: flex;
  position: relative;
}

.hire .text-wrapper-20 {
  color: #1f1f1f;
  letter-spacing: -.75px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  position: relative;
}

.hire .joulestowatts-brings {
  color: #1f1f1f;
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  width: 563px;
  position: relative;
}

.hire .instance-node {
  border: unset !important;
  border-color: unset !important;
  background-color: #4d9f98 !important;
  flex: none !important;
  justify-content: center !important;
  align-items: flex-end !important;
  width: 182px !important;
  display: flex !important;
}

.hire .CTA-2 {
  font-family: var(--subtitle-BLD-font-family) !important;
  font-size: var(--subtitle-BLD-font-size) !important;
  font-style: var(--subtitle-BLD-font-style) !important;
  font-weight: var(--subtitle-BLD-font-weight) !important;
  letter-spacing: var(--subtitle-BLD-letter-spacing) !important;
  line-height: var(--subtitle-BLD-line-height) !important;
  margin-left: -1px !important;
}

.hire .CTA-3 {
  margin-right: -1px !important;
}

.hire .frame-18 {
  flex-direction: column;
  align-items: center;
  gap: 55px;
  width: 1277px;
  display: flex;
  position: absolute;
  top: 528px;
  left: 84px;
}

.hire .frame-19 {
  flex: none;
  align-items: flex-start;
  gap: 8px;
  width: 1278px;
  margin-left: -.5px;
  margin-right: -.5px;
  display: flex;
  position: relative;
}

.hire .frame-20 {
  background-color: #4d9f98;
  background-image: url("frame-3800-5.992477b3.svg");
  background-size: 100% 100%;
  width: 418px;
  height: 430px;
  position: relative;
}

.hire .frame-21 {
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: relative;
  top: 158px;
  left: 49px;
}

.hire .frame-22 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.hire .text-wrapper-21 {
  color: var(--neutral-0);
  letter-spacing: -.24px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 33.6px;
  position: relative;
}

.hire .text-wrapper-22 {
  color: var(--neutral-30);
  font-family: var(--paragraph-REG-font-family);
  font-size: var(--paragraph-REG-font-size);
  font-style: var(--paragraph-REG-font-style);
  font-weight: var(--paragraph-REG-font-weight);
  letter-spacing: var(--paragraph-REG-letter-spacing);
  line-height: var(--paragraph-REG-line-height);
  width: 312px;
  position: relative;
}

.hire .frame-23 {
  background-color: #1f1f1f;
  width: 426px;
  height: 430px;
  position: relative;
  overflow: hidden;
}

.hire .overlap-group-3 {
  width: 1231px;
  height: 1245px;
  position: relative;
  top: -334px;
  left: -394px;
}

.hire .group-3 {
  width: 426px;
  height: 374px;
  position: absolute;
  top: 334px;
  left: 394px;
}

.hire .group-4 {
  width: 386px;
  height: 430px;
  position: absolute;
  top: 334px;
  left: 433px;
}

.hire .ellipse-4 {
  filter: blur(90px);
  background: radial-gradient(50% 50%, #0711037a 39.06%, #2e505300 100%);
  border-radius: 316.02px / 372.38px;
  width: 632px;
  height: 745px;
  position: absolute;
  top: 99px;
  left: 170px;
  transform: rotate(-124.37deg);
}

.hire .ellipse-5 {
  filter: blur(90px);
  background: radial-gradient(50% 50%, #4d9f9866 46.4%, #2e505300 100%);
  border-radius: 339.61px / 318.67px;
  width: 679px;
  height: 637px;
  position: absolute;
  top: 461px;
  left: 428px;
  transform: rotate(48.41deg);
}

.hire .frame-24 {
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  width: 323px;
  display: flex;
  position: absolute;
  top: 492px;
  left: 457px;
}

.hire .frame-25 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 16px;
  width: 372px;
  margin-right: -48.95px;
  display: flex;
  position: relative;
}

.hire .text-wrapper-23 {
  color: var(--neutral-0);
  letter-spacing: -.24px;
  width: 372px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 33.6px;
  position: relative;
}

.hire .a-focused-customized {
  color: var(--neutral-30);
  font-family: var(--paragraph-REG-font-family);
  font-size: var(--paragraph-REG-font-size);
  font-style: var(--paragraph-REG-font-style);
  font-weight: var(--paragraph-REG-font-weight);
  letter-spacing: var(--paragraph-REG-letter-spacing);
  line-height: var(--paragraph-REG-line-height);
  width: 307px;
  position: relative;
}

.hire .overlap-wrapper {
  background-color: #df0000b8;
  width: 418px;
  height: 430px;
  position: relative;
  overflow: hidden;
}

.hire .overlap-3 {
  width: 1182px;
  height: 1220px;
  position: relative;
  top: -309px;
  left: -356px;
}

.hire .ellipse-6 {
  filter: blur(90px);
  background: radial-gradient(50% 50%, #4d9f9866 46.4%, #2e505300 100%);
  border-radius: 336.81px / 316.05px;
  width: 674px;
  height: 632px;
  position: absolute;
  top: 442px;
  left: 386px;
  transform: rotate(48.95deg);
}

.hire .ellipse-7 {
  filter: blur(90px);
  background: radial-gradient(50% 50%, #9e000d7a 39.06%, #9e000d00 100%);
  border-radius: 379.09px / 368.41px;
  width: 758px;
  height: 737px;
  position: absolute;
  top: 152px;
  left: 138px;
  transform: rotate(-123.86deg);
}

.hire .group-5 {
  width: 418px;
  height: 430px;
  position: absolute;
  top: 309px;
  left: 356px;
}

.hire .frame-26 {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: absolute;
  top: 469px;
  left: 400px;
}

.hire .a-comprehensive {
  color: var(--neutral-30);
  font-family: var(--paragraph-REG-font-family);
  font-size: var(--paragraph-REG-font-size);
  font-style: var(--paragraph-REG-font-style);
  font-weight: var(--paragraph-REG-font-weight);
  letter-spacing: var(--paragraph-REG-letter-spacing);
  line-height: var(--paragraph-REG-line-height);
  width: 272px;
  position: relative;
}

.hire .group-6 {
  width: 421px;
  height: 347px;
  position: absolute;
  top: 81px;
  left: 115px;
}

.hire .overlap-4 {
  height: 347px;
  position: relative;
}

.hire .mask-group {
  width: 347px;
  height: 347px;
  position: absolute;
  top: 0;
  left: 53px;
}

.hire .mask-group-2 {
  width: 120px;
  height: 120px;
  position: absolute;
  top: 17px;
  left: 301px;
}

.hire .mask-group-3 {
  width: 54px;
  height: 54px;
  position: absolute;
  top: 252px;
  left: 0;
}

.hire .frame-27 {
  background-color: #d2d2d2;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  width: 390px;
  padding: 40px 16px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.hire .group-7 {
  width: 358px;
  height: 295.31px;
  position: relative;
}

.hire .overlap-group-4 {
  height: 295px;
  position: relative;
}

.hire .mask-group-4 {
  width: 295px;
  height: 295px;
  position: absolute;
  top: 0;
  left: 45px;
}

.hire .mask-group-5 {
  width: 102px;
  height: 102px;
  position: absolute;
  top: 14px;
  left: 256px;
}

.hire .mask-group-6 {
  width: 46px;
  height: 46px;
  position: absolute;
  top: 214px;
  left: 0;
}

.hire .frame-28 {
  flex-direction: column;
  align-items: flex-start;
  gap: 22px;
  width: 358px;
  height: 393px;
  padding: 32px 0;
  display: flex;
  position: relative;
}

.hire .frame-29 {
  flex: none;
  align-items: flex-start;
  gap: 16px;
  width: 497.74px;
  margin-right: -139.74px;
  display: flex;
  position: relative;
}

.hire .text-wrapper-24 {
  color: #1f1f1f;
  letter-spacing: -.48px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.4px;
  position: relative;
}

.hire .joulestowatts-brings-2 {
  color: #1f1f1f;
  letter-spacing: -.16px;
  width: 358px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  position: relative;
}

.hire .frame-30 {
  background-color: #4d9f98;
  background-image: url("frame-3800-4.84faff68.svg");
  background-size: 100% 100%;
  width: 390px;
  height: 300px;
  position: absolute;
  top: 808px;
  left: 0;
}

.hire .frame-31 {
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: relative;
  top: 85px;
  left: 39px;
}

.hire .frame-32 {
  background-color: #1f1f1f;
  width: 390px;
  height: 300px;
  position: absolute;
  top: 2125px;
  left: 0;
  overflow: hidden;
}

.hire .overlap-5 {
  width: 1024px;
  height: 974px;
  position: relative;
  top: -293px;
  left: -309px;
}

.hire .group-8 {
  width: 390px;
  height: 272px;
  position: absolute;
  top: 293px;
  left: 309px;
}

.hire .group-9 {
  width: 347px;
  height: 300px;
  position: absolute;
  top: 293px;
  left: 352px;
}

.hire .ellipse-8 {
  filter: blur(90px);
  background: radial-gradient(50% 50%, #0711037a 39.06%, #2e505300 100%);
  border-radius: 244.53px / 308.67px;
  width: 489px;
  height: 617px;
  position: absolute;
  top: 79px;
  left: 149px;
  transform: rotate(-131.91deg);
}

.hire .ellipse-9 {
  filter: blur(90px);
  background: radial-gradient(50% 50%, #4d9f9866 46.4%, #2e505300 100%);
  border-radius: 272.02px / 255.25px;
  width: 544px;
  height: 510px;
  position: absolute;
  top: 348px;
  left: 379px;
  transform: rotate(40.65deg);
}

.hire .frame-33 {
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  width: 296px;
  display: flex;
  position: absolute;
  top: 378px;
  left: 352px;
}

.hire .frame-34 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 16px;
  width: 372px;
  margin-right: -76.25px;
  display: flex;
  position: relative;
}

.hire .overlap-6 {
  position: absolute;
  left: 0;
}

.hire .frame-35 {
  width: 1440px;
  height: 519px;
  position: absolute;
  top: 100px;
  left: 0;
  overflow: hidden;
}

.hire .overlap-group-5 {
  background-color: #172c36;
  height: 687px;
  position: relative;
}

.hire .frame-36 {
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 35px;
  display: inline-flex;
  position: absolute;
  top: 130px;
  left: 378px;
}

.hire .frame-37 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 31px;
  display: inline-flex;
  position: relative;
}

.hire .text-wrapper-25 {
  color: var(--secondary-02);
  white-space: nowrap;
  font-family: var(--title-mega-font-family);
  font-size: var(--title-mega-font-size);
  font-style: var(--title-mega-font-style);
  font-weight: var(--title-mega-font-weight);
  letter-spacing: var(--title-mega-letter-spacing);
  line-height: var(--title-mega-line-height);
  letter-spacing: -1.8px;
  width: 445px;
  margin-top: -1px;
  font-family: Source Sans Pro;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 101.5%;
  position: relative;
}

.hire .with-over-global-2 {
  color: #0000;
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  text-align: center;
  width: 684px;
  position: relative;
}

.hire .text-wrapper-26 {
  color: #fff;
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
}

.hire .text-wrapper-27 {
  color: #00dfc2;
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
}

.hire .rectangle-4 {
  width: 407px;
  height: 471px;
  position: absolute;
  top: 0;
  left: 1033px;
}

.hire .rectangle-5 {
  width: 467px;
  height: 315px;
  position: absolute;
  top: 204px;
  left: 0;
}

.hire .header-2 {
  background-color: #0000;
  justify-content: space-between;
  align-items: center;
  width: 1440px;
  padding: 16px 80px;
  display: flex;
  position: absolute;
  top: 0;
  left: 10px;
}

.hire .logo-2 {
  object-fit: cover;
  width: 145px;
  height: 67.67px;
  position: relative;
}

.hire .holder {
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.hire .frame-38 {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 90.33px;
  padding: 10px 8px;
  display: flex;
  position: relative;
}

.hire .text-wrapper-28 {
  color: #000;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: relative;
}

.hire .text-wrapper-29 {
  color: #000;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-left: -2.83px;
  margin-right: -2.83px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: relative;
}

.hire .frame-39 {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 80px;
  padding: 10px 8px;
  display: flex;
  position: relative;
}

.hire .CTA-4 {
  border-color: var(--primary-02) !important;
  width: 179px !important;
  height: 69px !important;
  display: flex !important;
}

.hire .CTA-5 {
  color: #000 !important;
  margin-top: unset !important;
}

.hire .frame-40 {
  background-color: #df0000b8;
  width: 390px;
  height: 300px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.hire .overlap-7 {
  width: 991px;
  height: 963px;
  position: relative;
  top: -279px;
  left: -276px;
}

.hire .ellipse-10 {
  filter: blur(90px);
  background: radial-gradient(50% 50%, #4d9f9866 46.4%, #2e505300 100%);
  border-radius: 272.02px / 255.25px;
  width: 544px;
  height: 510px;
  position: absolute;
  top: 337px;
  left: 347px;
  transform: rotate(40.65deg);
}

.hire .ellipse-11 {
  filter: blur(90px);
  background: radial-gradient(50% 50%, #9e000d7a 39.06%, #9e000d00 100%);
  border-radius: 295.09px / 308.67px;
  width: 590px;
  height: 617px;
  position: absolute;
  top: 117px;
  left: 132px;
  transform: rotate(-131.91deg);
}

.hire .group-10 {
  width: 390px;
  height: 300px;
  position: absolute;
  top: 279px;
  left: 276px;
}

.hire .frame-41 {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: absolute;
  top: 376px;
  left: 321px;
}

.hire .frame-42 {
  background: linear-gradient(#030303 0%, #1c3845 100%);
  width: 392px;
  height: 1050px;
  position: absolute;
  top: 299px;
  left: 0;
}

.hire .overlap-8 {
  width: 390px;
  height: 1050px;
  position: relative;
}

.hire .overlap-9 {
  width: 390px;
  height: 1050px;
  position: absolute;
  top: 0;
  left: 0;
}

.hire .text-wrapper-30 {
  -webkit-text-stroke: 1px #000;
  color: #f6f7f8;
  letter-spacing: -1.05px;
  text-align: center;
  text-shadow: 0 4px 4px #00000040;
  white-space: nowrap;
  width: 234px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 42px;
  font-weight: 600;
  line-height: 50.4px;
  position: absolute;
  top: 38px;
  left: 78px;
}

.hire .rectangle-6 {
  width: 254px;
  height: 355px;
  position: absolute;
  top: 0;
  left: 136px;
}

.hire .rectangle-7 {
  width: 256px;
  height: 200px;
  position: absolute;
  top: 850px;
  left: 0;
}

.hire .frame-43 {
  width: 116px;
  height: 855px;
  position: absolute;
  top: 27px;
  left: 3px;
}

.hire .overlap-group-6 {
  height: 838px;
  position: relative;
  top: 25px;
}

.hire .frame-44 {
  flex-direction: column;
  align-items: center;
  width: 15px;
  height: 838px;
  display: flex;
  position: absolute;
  top: 0;
  left: 49px;
}

.hire .vector {
  width: 11.31px;
  height: 11.32px;
  position: relative;
}

.hire .frame-45 {
  background: linear-gradient(#828282 0%, #00dfc2 100%);
  flex: 1;
  width: 3px;
  margin-top: -2px;
  position: relative;
}

.hire .vector-2 {
  width: 11.31px;
  height: 11.32px;
  margin-top: -2px;
  position: relative;
}

.hire .frame-46 {
  width: 116px;
  height: 727px;
  position: absolute;
  top: 103px;
  left: 0;
}

.hire .frame-47 {
  align-items: center;
  gap: 24px;
  display: inline-flex;
  position: absolute;
  top: 173px;
  left: 127px;
}

.hire .text-wrapper-31 {
  color: var(--neutral-70);
  letter-spacing: -1.2px;
  opacity: .6;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 48px;
  font-weight: 900;
  line-height: 57.6px;
  position: relative;
}

.hire .text-wrapper-32 {
  color: #f6f7f8;
  letter-spacing: -.18px;
  width: fit-content;
  font-family: Source Sans Pro, Helvetica;
  font-size: 18px;
  font-weight: 600;
  line-height: 25.2px;
  position: relative;
}

.hire .frame-48 {
  align-items: center;
  gap: 8px;
  height: 62px;
  display: inline-flex;
  position: absolute;
  top: 295px;
  left: 127px;
}

.hire .text-wrapper-33 {
  color: var(--neutral-70);
  letter-spacing: -1.2px;
  opacity: .6;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  font-family: Source Sans Pro, Helvetica;
  font-size: 48px;
  font-weight: 900;
  line-height: 57.6px;
  position: relative;
}

.hire .frame-49 {
  align-items: center;
  gap: 8px;
  height: 62px;
  display: inline-flex;
  position: absolute;
  top: 421px;
  left: 127px;
}

.hire .frame-50 {
  align-items: center;
  gap: 23px;
  display: inline-flex;
  position: absolute;
  top: 547px;
  left: 127px;
}

.hire .frame-51 {
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 669px;
  left: 127px;
}

.hire .frame-52 {
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 791px;
  left: 127px;
}

.hire .frame-53 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 3822px;
  left: 16px;
}

.hire .text-wrapper-34 {
  color: #1f1f1f;
  letter-spacing: -.8px;
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.4px;
  position: relative;
}

.hire .frame-54 {
  flex: none;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  display: inline-flex;
  overflow: hidden;
}

.hire .frame-54-scroller {
  flex-direction: column;
  align-items: center;
  width: 1000px;
  display: inline-flex;
}

.hire .frame-54-position {
  width: 390px;
  position: absolute;
  top: 3889px;
  left: 16px;
  overflow: scroll;
}

.hire .group-11 {
  width: 304.33px;
  height: 613px;
  position: relative;
}

.hire .div-3 {
  background: #202020;
  border-radius: 8px;
  width: 300px;
  height: 196px;
  position: absolute;
  top: 0;
  left: 0;
}

.hire .group-12 {
  background-image: url("rectangle-125.6cb03ae4.svg");
  background-size: 100% 100%;
  width: 300px;
  height: 196px;
  position: absolute;
  top: 0;
  left: 0;
}

.hire .frame-55 {
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 300px;
  display: flex;
  position: relative;
  top: 63px;
}

.hire .rectangle-8 {
  align-self: stretch;
  width: 100%;
  height: 133px;
  position: relative;
}

.hire .frame-56 {
  flex-direction: column;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 53px;
  left: 96px;
}

.hire .text-wrapper-35 {
  color: #1f1f1f;
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.hire .text-wrapper-36 {
  color: var(--neutral-0);
  letter-spacing: -.24px;
  white-space: nowrap;
  height: 34px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  position: absolute;
  top: 13px;
  left: 126px;
}

.hire .overlap-10 {
  background-image: url("rectangle-123.0170d1e3.svg");
  background-size: 100% 100%;
  width: 300px;
  height: 133px;
  position: absolute;
  top: 202px;
  left: 0;
}

.hire .technical-test-wrapper {
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 222px;
  display: flex;
  position: relative;
  top: 28px;
  left: 37px;
}

.hire .technical-test {
  color: #1f1f1f;
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  text-align: center;
  width: fit-content;
  margin-top: -1px;
  margin-left: -9.87px;
  margin-right: -9.87px;
  position: relative;
}

.hire .overlap-11 {
  background-image: url("group-45.9aa34969.png");
  background-size: 100% 100%;
  width: 300px;
  height: 133px;
  position: absolute;
  top: 341px;
  left: 0;
}

.hire .text-wrapper-37 {
  color: #1f1f1f;
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  text-align: center;
  width: 118px;
  height: 56px;
  position: absolute;
  top: 39px;
  left: 96px;
}

.hire .group-13 {
  width: 302px;
  height: 133px;
  position: absolute;
  top: 480px;
  left: 0;
}

.hire .quality-graphic-desi-wrapper {
  background-image: url("rectangle-135.d183183b.svg");
  background-size: 100% 100%;
  width: 300px;
  height: 133px;
  position: relative;
}

.hire .quality-graphic-desi {
  width: 119px;
  height: 46px;
  position: absolute;
  top: 42px;
  left: 90px;
}

.hire .group-14 {
  width: 314.33px;
  height: 1030px;
  position: relative;
}

.hire .overlap-group-7 {
  background: #2e5053;
  border-radius: 8px;
  height: 196px;
  position: relative;
}

.hire .rectangle-9 {
  width: 50px;
  height: 196px;
  position: absolute;
  top: 0;
  left: 0;
}

.hire .frame-57 {
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 300px;
  display: flex;
  position: absolute;
  top: 63px;
  left: 0;
}

.hire .frame-58 {
  width: 158px;
  height: 56px;
  position: absolute;
  top: 53px;
  left: 51px;
}

.hire .certification-based {
  color: #1f1f1f;
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  text-align: center;
  height: 56px;
  position: absolute;
  top: 40px;
  left: 95px;
}

.hire .text-wrapper-38 {
  color: var(--neutral-0);
  letter-spacing: -.24px;
  white-space: nowrap;
  height: 34px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  position: absolute;
  top: 13px;
  left: 125px;
}

.hire .group-15 {
  width: 300px;
  height: 133px;
  position: absolute;
  top: 202px;
  left: 0;
}

.hire .client-specific {
  color: #1f1f1f;
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  text-align: center;
  width: 168px;
  height: 84px;
  position: absolute;
  top: 227px;
  left: 68px;
}

.hire .overlap-12 {
  width: 300px;
  height: 133px;
  position: absolute;
  top: 341px;
  left: 0;
}

.hire .group-16 {
  width: 300px;
  height: 133px;
  position: absolute;
  top: 0;
  left: 0;
}

.hire .instructor-led {
  color: #1f1f1f;
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  text-align: center;
  width: 236px;
  height: 84px;
  position: absolute;
  top: 28px;
  left: 35px;
}

.hire .group-17 {
  width: 300px;
  height: 133px;
  position: absolute;
  top: 619px;
  left: 0;
}

.hire .group-18 {
  width: 300px;
  height: 133px;
  position: absolute;
  top: 897px;
  left: 0;
}

.hire .overlap-13 {
  width: 262px;
  height: 133px;
  position: absolute;
  top: 480px;
  left: 0;
}

.hire .text-wrapper-39 {
  color: #1f1f1f;
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  text-align: center;
  width: 218px;
  height: 56px;
  position: absolute;
  top: 36px;
  left: 44px;
}

.hire .group-19 {
  width: 300px;
  height: 133px;
  position: absolute;
  top: 758px;
  left: 0;
}

.hire .feedback-fine-tuning {
  color: #1f1f1f;
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  text-align: center;
  width: 169px;
  height: 84px;
  position: absolute;
  top: 643px;
  left: 68px;
}

.hire .text-wrapper-40 {
  color: #1f1f1f;
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  text-align: center;
  width: 151px;
  height: 28px;
  position: absolute;
  top: 932px;
  left: 77px;
}

.hire .projects-and {
  color: #1f1f1f;
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  text-align: center;
  width: 200px;
  height: 84px;
  position: absolute;
  top: 794px;
  left: 53px;
}

.hire .group-20 {
  width: 310.33px;
  height: 752px;
  margin-right: -10px;
  position: relative;
}

.hire .rectangle-10 {
  width: 300px;
  height: 196px;
  position: absolute;
  top: -6626px;
  left: 8638px;
}

.hire .frame-59 {
  background-color: #1f1f1f;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 300px;
  display: flex;
  position: absolute;
  top: 63px;
  left: 0;
}

.hire .rectangle-11 {
  align-self: stretch;
  width: 100%;
  height: 133px;
  margin-top: -6689px;
  margin-right: -7989.33px;
  position: relative;
}

.hire .text-wrapper-41 {
  color: #1f1f1f;
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  text-align: center;
  white-space: nowrap;
  height: 28px;
  position: absolute;
  top: 47px;
  left: 89px;
}

.hire .text-wrapper-42 {
  color: var(--neutral-0);
  letter-spacing: -.24px;
  white-space: nowrap;
  height: 34px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  position: absolute;
  top: 13px;
  left: 115px;
}

.hire .group-21 {
  width: 300px;
  height: 133px;
  position: absolute;
  top: -6424px;
  left: 8638px;
}

.hire .group-22 {
  width: 300px;
  height: 133px;
  position: absolute;
  top: -6285px;
  left: 8638px;
}

.hire .group-23 {
  width: 300px;
  height: 133px;
  position: absolute;
  top: -6007px;
  left: 8638px;
}

.hire .dedicated-HR-support {
  color: #1f1f1f;
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  text-align: center;
  width: 165px;
  height: 84px;
  position: absolute;
  top: 241px;
  left: 73px;
}

.hire .constant-support-and {
  color: #1f1f1f;
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  text-align: center;
  width: 131px;
  height: 84px;
  position: absolute;
  top: 380px;
  left: 91px;
}

.hire .performance-based {
  color: #1f1f1f;
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  text-align: center;
  width: 146px;
  height: 84px;
  position: absolute;
  top: 658px;
  left: 84px;
}

.hire .group-24 {
  width: 300px;
  height: 133px;
  position: absolute;
  top: -6146px;
  left: 8638px;
}

.hire .continuous {
  color: #1f1f1f;
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  text-align: center;
  width: 171px;
  height: 84px;
  position: absolute;
  top: 519px;
  left: 73px;
}

.hire .frame-60 {
  flex-direction: column;
  align-items: center;
  gap: 54px;
  width: 1277px;
  display: flex;
  position: absolute;
  top: 2685px;
  left: 83px;
}

.hire .frame-61 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.hire .text-wrapper-43 {
  color: #1f1f1f;
  letter-spacing: -1.05px;
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 42px;
  font-weight: 400;
  line-height: 50.4px;
  position: relative;
}

.hire .frame-62 {
  flex: none;
  align-items: flex-start;
  gap: 24px;
  width: 1279px;
  margin-left: -1px;
  margin-right: -1px;
  display: flex;
  position: relative;
}

.hire .group-25 {
  flex: 1;
  height: 613px;
  position: relative;
}

.hire .overlap-14 {
  width: 363px;
  height: 196px;
  position: absolute;
  top: 0;
  left: 0;
}

.hire .group-26 {
  background-image: url("rectangle-125-3.05d6f877.svg");
  background-size: 100% 100%;
  width: 363px;
  height: 196px;
  position: absolute;
  top: 0;
  left: 0;
}

.hire .frame-63 {
  background: #f6f7f8;
  border-radius: 8px;
  width: 365px;
  height: 133px;
  margin-left: -.8px;
  position: relative;
  top: 64px;
}

.hire .frame-64 {
  flex-direction: column;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: relative;
  top: 52px;
  left: 123px;
}

.hire .text-wrapper-44 {
  color: var(--neutral-0);
  letter-spacing: -.24px;
  width: 48px;
  height: 34px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  position: absolute;
  top: 13px;
  left: 160px;
}

.hire .overlap-15 {
  background-image: url("rectangle-123-1.654c51f7.svg");
  background-size: 100% 100%;
  width: 363px;
  height: 133px;
  position: absolute;
  top: 202px;
  left: 0;
}

.hire .frame-65 {
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 269px;
  display: flex;
  position: relative;
  top: 41px;
  left: 44px;
}

.hire .technical-test-2 {
  color: #1f1f1f;
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  text-align: center;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.hire .overlap-16 {
  background-image: url("group-45-3.3f8c6433.png");
  background-size: 100% 100%;
  width: 363px;
  height: 133px;
  position: absolute;
  top: 341px;
  left: 0;
}

.hire .text-wrapper-45 {
  color: #1f1f1f;
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  width: 143px;
  height: 28px;
  position: absolute;
  top: 53px;
  left: 115px;
}

.hire .group-27 {
  width: 365px;
  height: 133px;
  position: absolute;
  top: 480px;
  left: 0;
}

.hire .img-wrapper {
  background-image: url("rectangle-135-1.a546f074.svg");
  background-size: 100% 100%;
  width: 363px;
  height: 133px;
  position: relative;
}

.hire .quality-graphic-desi-2 {
  width: 172px;
  height: 18px;
  position: absolute;
  top: 60px;
  left: 92px;
}

.hire .frame-66 {
  align-self: stretch;
  width: 51.5px;
  position: relative;
}

.hire .group-28 {
  flex: 1;
  height: 1030px;
  position: relative;
}

.hire .group-29 {
  background-image: url("rectangle-125-4.cf41efdd.svg");
  background-size: 100% 100%;
  width: 363px;
  height: 196px;
  position: absolute;
  top: 0;
  left: 0;
}

.hire .text-wrapper-46 {
  color: var(--neutral-0);
  letter-spacing: -.24px;
  width: 58px;
  height: 34px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  position: absolute;
  top: 13px;
  left: 155px;
}

.hire .overlap-17 {
  background-image: url("group-44-2.f22b489f.png");
  background-size: 100% 100%;
  width: 363px;
  height: 133px;
  position: absolute;
  top: 202px;
  left: 0;
}

.hire .client-specific-2 {
  color: #1f1f1f;
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  text-align: center;
  width: 205px;
  height: 56px;
  position: absolute;
  top: 41px;
  left: 83px;
}

.hire .instructor-led-2 {
  color: #1f1f1f;
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  text-align: center;
  width: 288px;
  height: 56px;
  position: absolute;
  top: 41px;
  left: 43px;
}

.hire .overlap-18 {
  background-image: url("group-45-3.3f8c6433.png");
  background-size: 100% 100%;
  width: 363px;
  height: 133px;
  position: absolute;
  top: 619px;
  left: 0;
}

.hire .feedback-fine-tuning-2 {
  color: #1f1f1f;
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  text-align: center;
  width: 206px;
  height: 56px;
  position: absolute;
  top: 41px;
  left: 83px;
}

.hire .overlap-19 {
  background-image: url("group-45-3.3f8c6433.png");
  background-size: 100% 100%;
  width: 363px;
  height: 133px;
  position: absolute;
  top: 897px;
  left: 0;
}

.hire .text-wrapper-47 {
  color: #1f1f1f;
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  text-align: center;
  width: 184px;
  height: 28px;
  position: absolute;
  top: 55px;
  left: 94px;
}

.hire .overlap-20 {
  background-image: url("group-46-2.7f449f60.png");
  background-size: 100% 100%;
  width: 363px;
  height: 133px;
  position: absolute;
  top: 480px;
  left: 0;
}

.hire .text-wrapper-48 {
  color: #1f1f1f;
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  text-align: center;
  width: 266px;
  height: 28px;
  position: absolute;
  top: 50px;
  left: 53px;
}

.hire .projects-and-wrapper {
  background-image: url("group-46-2.7f449f60.png");
  background-size: 100% 100%;
  width: 363px;
  height: 133px;
  position: absolute;
  top: 758px;
  left: 0;
}

.hire .projects-and-2 {
  color: #1f1f1f;
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  text-align: center;
  width: 244px;
  height: 56px;
  position: absolute;
  top: 36px;
  left: 64px;
}

.hire .group-30 {
  flex: 1;
  height: 752px;
  margin-right: -10px;
  position: relative;
}

.hire .group-31 {
  background-image: url("rectangle-125-5.ac4f8a3f.svg");
  background-size: 100% 100%;
  width: 363px;
  height: 196px;
  position: absolute;
  top: 0;
  left: 0;
}

.hire .frame-67 {
  background-color: #1f1f1f;
  height: 123px;
  position: relative;
  top: 63px;
}

.hire .overlap-group-8 {
  background: #f6f7f8;
  border-radius: 8px;
  width: 365px;
  height: 133px;
  margin-left: -.8px;
  position: relative;
}

.hire .text-wrapper-49 {
  color: var(--neutral-0);
  letter-spacing: -.24px;
  width: 79px;
  height: 34px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  position: absolute;
  top: 13px;
  left: 139px;
}

.hire .dedicated-HR-support-2 {
  color: #1f1f1f;
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  text-align: center;
  width: 200px;
  height: 56px;
  position: absolute;
  top: 39px;
  left: 89px;
}

.hire .constant-support-and-2 {
  color: #1f1f1f;
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  text-align: center;
  width: 159px;
  height: 56px;
  position: absolute;
  top: 39px;
  left: 110px;
}

.hire .performance-based-2 {
  color: #1f1f1f;
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  text-align: center;
  width: 177px;
  height: 56px;
  position: absolute;
  top: 39px;
  left: 101px;
}

.hire .continuous-2 {
  color: #1f1f1f;
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  text-align: center;
  width: 206px;
  height: 56px;
  position: absolute;
  top: 39px;
  left: 89px;
}

.hire .carousel-2 {
  position: absolute;
  left: 0;
  overflow: hidden;
}

.hire .frame-68 {
  position: absolute;
  top: 0;
  left: 0;
}

.hire .overlap-group-9 {
  position: relative;
}

.hire .rectangle-12 {
  height: 485px;
  position: absolute;
}

.hire .frame-69 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  height: 261px;
  display: inline-flex;
  position: absolute;
}

.hire .shutterstock {
  object-fit: cover;
  position: absolute;
}

.hire .rectangle-13 {
  filter: blur(40px);
  background: linear-gradient(90deg, #589993 5.95%, #4d9f98 20.62% 48.33%, #fcfcfc00 71.42%);
  position: absolute;
}

.hire .frame-70 {
  flex-direction: column;
  align-items: flex-start;
  gap: 33px;
  display: inline-flex;
  position: absolute;
}

.hire .we-are-your-growth {
  color: #fff;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-weight: 600;
  position: relative;
}

.hire .class {
  border: unset !important;
  border-color: unset !important;
  background-color: #2e5053 !important;
  flex: none !important;
  justify-content: center !important;
  align-items: flex-end !important;
  width: 127px !important;
  display: flex !important;
}

.hire .class-2 {
  border: unset !important;
  border-color: unset !important;
  background-color: #2e5053 !important;
  flex: none !important;
  justify-content: center !important;
  align-items: flex-end !important;
  width: 182px !important;
  display: flex !important;
}

.hire .class-3 {
  margin-right: -2px !important;
}

.hire .class-4 {
  font-family: var(--subtitle-BLD-font-family) !important;
  font-size: var(--subtitle-BLD-font-size) !important;
  font-style: var(--subtitle-BLD-font-style) !important;
  font-weight: var(--subtitle-BLD-font-weight) !important;
  letter-spacing: var(--subtitle-BLD-letter-spacing) !important;
  line-height: var(--subtitle-BLD-line-height) !important;
  margin-left: -2px !important;
}

.hire .class-5 {
  font-family: var(--subtitle-BLD-font-family) !important;
  font-size: var(--subtitle-BLD-font-size) !important;
  font-style: var(--subtitle-BLD-font-style) !important;
  font-weight: var(--subtitle-BLD-font-weight) !important;
  letter-spacing: var(--subtitle-BLD-letter-spacing) !important;
  line-height: var(--subtitle-BLD-line-height) !important;
}

.hire .frame-71 {
  width: 1440px;
  height: 580px;
  position: absolute;
  top: 0;
  left: 1440px;
  overflow: hidden;
}

.hire .rectangle-14 {
  width: 1383px;
  height: 634px;
  position: absolute;
  left: 7561px;
}

.hire .overlap-21 {
  filter: blur(80px);
  background: linear-gradient(#000 0%, #071103 25%, #202020 44.79%, #2f2f2f 61.46%, #0000 100%);
  width: 1684px;
  height: 714px;
  position: absolute;
  top: -60px;
  left: -116px;
}

.hire .frame-72 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: absolute;
  top: 242px;
  left: 196px;
}

.hire .text-wrapper-50 {
  color: var(--neutral-0);
  letter-spacing: -.53px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 600;
  line-height: 49px;
  position: relative;
}

.hire .frame-73 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.hire .our-client-and {
  color: #f6f7f8;
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  width: 436px;
  margin-top: -1px;
  position: relative;
}

.hire .CTA-6 {
  all: unset;
  box-sizing: border-box;
  flex: none;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.hire .text-wrapper-51 {
  color: var(--neutral-0);
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 23.8px;
  position: relative;
}

.hire .arrow-forward-3 {
  width: 24px;
  height: 24px;
  margin-right: -7134px;
  position: relative;
}

.hire .text-wrapper-52 {
  color: var(--neutral-0);
  font-family: var(--h4-REG-font-family);
  font-size: var(--h4-REG-font-size);
  font-style: var(--h4-REG-font-style);
  font-weight: var(--h4-REG-font-weight);
  letter-spacing: var(--h4-REG-letter-spacing);
  line-height: var(--h4-REG-line-height);
  white-space: nowrap;
  height: 41px;
  position: absolute;
  top: 120px;
  left: 196px;
}

.hire .frame-74 {
  align-items: flex-start;
  gap: 5px;
  display: inline-flex;
  position: absolute;
  top: 560px;
  left: 196px;
}

.hire .ellipse-12 {
  background-color: #d9d9d952;
  border-radius: 5.5px;
  width: 11px;
  height: 11px;
  position: relative;
}

.hire .ellipse-13 {
  background-color: var(--neutral-30);
  border-radius: 5.5px;
  width: 11px;
  height: 11px;
  position: relative;
}

.hire .class-6 {
  margin-top: -8193px !important;
  margin-right: -5694px !important;
}

.hire .class-7 {
  margin-top: -1538px !important;
  margin-right: -5694px !important;
}

.hire .carousel-03 {
  position: absolute !important;
  top: 0 !important;
  left: 2880px !important;
}

.hire .frame-75 {
  flex-direction: column;
  align-items: center;
  gap: 25px;
  display: inline-flex;
  position: absolute;
  top: 4455px;
  left: 161px;
}

.hire .frame-76 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 51px;
  display: inline-flex;
  position: relative;
}

.hire .frame-77 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 26px;
  display: inline-flex;
  position: relative;
}

.hire .frame-78 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.hire .frame-79 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.hire .text-wrapper-53 {
  color: var(--neutral-60);
  letter-spacing: -.2px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  position: relative;
}

.hire .frame-80 {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 29px;
  display: inline-flex;
  position: relative;
}

.hire .mercedes-benz-logo {
  object-fit: cover;
  width: 248px;
  height: 63px;
  position: relative;
}

.hire .GE-healthcare-logo {
  object-fit: cover;
  width: 164px;
  height: 92px;
  position: relative;
}

.hire .sony-logo {
  object-fit: cover;
  width: 123px;
  height: 22px;
  position: relative;
}

.hire .shell-logo {
  object-fit: cover;
  width: 115px;
  height: 65px;
  position: relative;
}

.hire .amazon {
  object-fit: cover;
  width: 178px;
  height: 55px;
  position: relative;
}

.hire .wipro-logo-new-og {
  object-fit: cover;
  width: 171px;
  height: 89px;
  position: relative;
}

.hire .frame-81 {
  position: absolute;
}

.hire .frame-82 {
  width: 742px;
  height: 307px;
  position: absolute;
  top: 0;
  left: 0;
}

.hire .overlap-group-10 {
  height: 307px;
  position: relative;
}

.hire .rectangle-15 {
  background-color: #f6f7f8;
  width: 390px;
  height: 307px;
  position: absolute;
  top: 0;
  left: 185px;
}

.hire .frame-83 {
  flex-direction: column;
  align-items: center;
  gap: 25px;
  display: inline-flex;
  position: absolute;
  top: 76px;
  left: 0;
}

.hire .mercedes-benz-logo-2 {
  object-fit: cover;
  width: 148px;
  height: 37px;
  position: relative;
}

.hire .GE-healthcare-logo-2 {
  object-fit: cover;
  width: 98px;
  height: 55px;
  position: relative;
}

.hire .sony-logo-2 {
  object-fit: cover;
  width: 74px;
  height: 13px;
  position: relative;
}

.hire .shell-logo-2 {
  object-fit: cover;
  width: 69px;
  height: 39px;
  position: relative;
}

.hire .amazon-2 {
  object-fit: cover;
  width: 70px;
  height: 33px;
  position: relative;
}

.hire .wipro-logo-new-og-2 {
  object-fit: cover;
  width: 102px;
  height: 53px;
  position: relative;
}

.hire .frame-84 {
  flex-direction: column;
  align-items: center;
  gap: 92px;
  display: inline-flex;
  position: absolute;
  top: 366px;
  left: 185px;
}

.hire .frame-85 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-end;
  gap: 32px;
  width: 328px;
  display: flex;
  position: relative;
}

.hire .frame-86 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 24px;
  width: 328px;
  display: flex;
  position: relative;
}

.hire .text-wrapper-54 {
  color: var(--secondary-04);
  letter-spacing: -.8px;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.4px;
  position: relative;
}

.hire .text-wrapper-55 {
  color: var(--neutral-80);
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  text-align: center;
  width: 297px;
  position: relative;
}

.hire .group-32 {
  width: 390px;
  height: 360px;
  position: relative;
}

.hire .frame-87 {
  height: 360px;
}

.hire .overlap-group-11 {
  background-color: var(--neutral-70);
  width: 1440px;
  height: 360px;
}

.hire .frame-88 {
  flex-direction: column;
  align-items: center;
  gap: 24px;
  display: inline-flex;
  position: relative;
  top: 85px;
  left: 41px;
}

.hire .text-wrapper-56 {
  color: var(--neutral-0);
  letter-spacing: -.8px;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.4px;
  position: relative;
}

.hire .are-we-a-good-fit {
  color: #f6f7f8;
  letter-spacing: -.2px;
  text-align: center;
  width: 309px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.hire .CTA-7 {
  cursor: pointer;
  background-color: var(--secondary-02);
  box-sizing: border-box;
  border: none;
  outline: none;
  flex: none;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  display: inline-flex;
  position: relative;
}

.hire .text-wrapper-57 {
  color: var(--neutral-0);
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.hire .arrow-forward-4 {
  width: 24px;
  height: 24px;
  position: relative;
}

.hire .frame-89 {
  height: 713px;
}

.hire .overlap-22 {
  width: 1451px;
  height: 713px;
  position: relative;
}

.hire .group-33 {
  width: 1451px;
  height: 713px;
  position: absolute;
  top: 0;
  left: 0;
}

.hire .overlap-group-12 {
  background: linear-gradient(#030303 0%, #1c3845 100%);
  width: 1449px;
  height: 713px;
  position: relative;
}

.hire .frame-90 {
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  width: 290px;
  display: flex;
  position: absolute;
  top: 486px;
  left: -3px;
}

.hire .text-wrapper-58 {
  color: #f6f7f8;
  letter-spacing: -.22px;
  text-align: right;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 22px;
  font-weight: 600;
  line-height: 30.8px;
  position: relative;
}

.hire .text-wrapper-59 {
  color: var(--neutral-70);
  letter-spacing: -1.93px;
  opacity: .6;
  text-align: right;
  white-space: nowrap;
  height: 92px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 77px;
  font-weight: 900;
  line-height: 92.4px;
  position: absolute;
  top: -16px;
  left: 98px;
}

.hire .frame-91 {
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  width: 290px;
  height: 62px;
  display: flex;
  position: absolute;
  top: 301px;
  left: 51px;
}

.hire .text-wrapper-60 {
  color: var(--neutral-70);
  letter-spacing: -1.93px;
  opacity: .6;
  text-align: right;
  white-space: nowrap;
  height: 92px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 77px;
  font-weight: 900;
  line-height: 92.4px;
  position: absolute;
  top: -16px;
  left: 36px;
}

.hire .overlap-23 {
  width: 905px;
  height: 473px;
  position: absolute;
  top: 172px;
  left: 297px;
}

.hire .frame-92 {
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  width: 221px;
  height: 62px;
  display: flex;
  position: absolute;
  top: 8px;
  left: -33px;
}

.hire .text-wrapper-61 {
  color: var(--neutral-70);
  letter-spacing: -1.93px;
  opacity: .6;
  text-align: right;
  white-space: nowrap;
  height: 92px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 77px;
  font-weight: 900;
  line-height: 92.4px;
  position: absolute;
  top: -16px;
  left: -25px;
}

.hire .text-wrapper-62 {
  -webkit-text-stroke: 1px #000;
  color: #f6f7f8;
  letter-spacing: -1.05px;
  text-align: center;
  text-shadow: 0 4px 4px #00000040;
  white-space: nowrap;
  width: 234px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 42px;
  font-weight: 600;
  line-height: 50.4px;
  position: absolute;
  top: 276px;
  left: 291px;
}

.hire .layer {
  width: 636px;
  height: 437px;
  position: absolute;
  top: 36px;
  left: 83px;
}

.hire .frame-93 {
  align-items: center;
  gap: 23px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 607px;
}

.hire .text-wrapper-63 {
  color: #f6f7f8;
  letter-spacing: -.22px;
  width: fit-content;
  font-family: Source Sans Pro, Helvetica;
  font-size: 22px;
  font-weight: 600;
  line-height: 30.8px;
  position: relative;
}

.hire .text-wrapper-64 {
  color: var(--neutral-70);
  letter-spacing: -1.93px;
  opacity: .6;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 77px;
  font-weight: 900;
  line-height: 92.4px;
  position: relative;
}

.hire .frame-94 {
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 301px;
  left: 1055px;
}

.hire .frame-95 {
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 478px;
  left: 1118px;
}

.hire .group-34 {
  width: 182px;
  height: 190px;
  position: absolute;
  top: 429px;
  left: 307px;
}

.hire .group-35 {
  width: 254px;
  height: 166px;
  position: absolute;
  top: 263px;
  left: 319px;
}

.hire .group-36 {
  width: 140px;
  height: 149px;
  position: absolute;
  top: 158px;
  left: 537px;
}

.hire .group-37 {
  width: 182px;
  height: 190px;
  position: absolute;
  top: 138px;
  left: 712px;
}

.hire .group-38 {
  width: 197px;
  height: 197px;
  position: absolute;
  top: 252px;
  left: 852px;
}

.hire .group-39 {
  width: 197px;
  height: 197px;
  position: absolute;
  top: 423px;
  left: 909px;
}

.hire .rectangle-16 {
  width: 573px;
  height: 259px;
  position: absolute;
  top: 0;
  left: 0;
}

.hire .rectangle-17 {
  width: 1067px;
  height: 232px;
  position: absolute;
  top: 481px;
  left: 193px;
}

.hire .frame-96 {
  flex-direction: column;
  align-items: center;
  gap: 69px;
  display: inline-flex;
  position: absolute;
  top: 664px;
  left: 88px;
}

.hire .frame-97 {
  flex: none;
  align-items: center;
  gap: 199px;
  display: inline-flex;
  position: relative;
}

.hire .text-wrapper-65 {
  color: #2e5053;
  letter-spacing: -.28px;
  width: 501px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 28px;
  font-weight: 600;
  line-height: 39.2px;
  position: relative;
}

.hire .frame-98 {
  flex: none;
  align-items: flex-start;
  gap: 61px;
  display: inline-flex;
  position: relative;
}

.hire .text-wrapper-66 {
  color: #999;
  letter-spacing: -.75px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 50px;
  font-weight: 700;
  line-height: 60px;
  position: relative;
}

.hire .text-wrapper-67 {
  color: var(--neutral-70);
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  width: 128px;
  height: 48px;
  position: relative;
}

.hire .text-wrapper-68 {
  color: #999;
  letter-spacing: -.75px;
  text-align: center;
  width: 69px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 50px;
  font-weight: 700;
  line-height: 60px;
  position: relative;
}

.hire .text-wrapper-69 {
  color: var(--neutral-70);
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  white-space: nowrap;
  width: 121px;
  position: relative;
}

.hire .frame-99 {
  flex-direction: column;
  flex: none;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.hire .text-wrapper-70 {
  white-space: nowrap;
  color: var(--neutral-70);
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  width: 193px;
  height: 29px;
  position: relative;
}

.hire .group-40 {
  width: 1449px;
  height: 380px;
  position: absolute;
  top: 4661px;
  left: -9px;
}

.hire .overlap-24 {
  height: 380px;
  position: relative;
}

.hire .frame-100 {
  width: 1449px;
  height: 380px;
  position: absolute;
  top: 0;
  left: 0;
}

.hire .overlap-group-13 {
  background-color: #4d4d4d;
  width: 1456px;
  height: 380px;
  position: relative;
}

.hire .frame-101 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 69px;
  left: 152px;
}

.hire .frame-102 {
  flex-direction: column;
  flex: none;
  align-items: flex-end;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.hire .text-wrapper-71 {
  color: #fff;
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.hire .are-we-a-good-fit-2 {
  color: var(--neutral-10);
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  text-align: right;
  width: 429px;
  position: relative;
}

.hire .CTA-8 {
  all: unset;
  background-color: var(--secondary-04);
  box-sizing: border-box;
  flex: none;
  align-items: center;
  gap: 10px;
  padding: 12px 16px;
  display: inline-flex;
  position: relative;
}

.hire .text-wrapper-72 {
  color: #fff;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: relative;
}

.hire .CTA-9 {
  cursor: pointer;
  background-color: var(--secondary-02);
  box-sizing: border-box;
  border: none;
  outline: none;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  display: inline-flex;
  position: absolute;
  top: 267px;
  left: 822px;
}

.hire .frame-103 {
  background-color: var(--secondary-03);
  width: 724px;
  height: 380px;
  position: absolute;
  top: 0;
  left: 0;
}

.hire .overlap-25 {
  background-color: var(--neutral-10);
  width: 720px;
  height: 380px;
  position: absolute;
  top: 0;
  left: 0;
}

.hire .frame-104 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 32px;
  display: inline-flex;
  position: relative;
  top: 49px;
  left: 128px;
}

.hire .text-wrapper-73 {
  color: var(--secondary-04);
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.hire .text-wrapper-74 {
  color: var(--neutral-80);
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  text-align: right;
  width: 500px;
  position: relative;
}

.hire .frame-105 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 52px;
  left: 817px;
}

.hire .text-wrapper-75 {
  color: #fff;
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.hire .text-wrapper-76 {
  color: var(--neutral-10);
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  width: 429px;
  position: relative;
}

.hire .footer-2 {
  background-color: var(--white);
  width: 1441px;
  height: 292px;
  position: absolute;
  top: 5041px;
  left: 0;
}

.hire .overlap-26 {
  width: 1441px;
  height: 292px;
  position: relative;
}

.hire .frame-106 {
  background-color: var(--neutral-90);
  width: 1441px;
  height: 292px;
  position: absolute;
  top: 0;
  left: 0;
}

.hire .DIV-3 {
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 53px;
  left: 738px;
}

.hire .text-wrapper-77 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1233px;
}

.hire .text-wrapper-78 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1282px;
}

.hire .text-wrapper-79 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1331px;
}

.hire .text-wrapper-80 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1187px;
}

.hire .text-wrapper-81 {
  color: var(--white);
  letter-spacing: -.2px;
  white-space: nowrap;
  height: 28px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: absolute;
  top: 100px;
  left: 738px;
}

.hire .text-wrapper-82 {
  color: var(--white);
  letter-spacing: -.2px;
  white-space: nowrap;
  height: 28px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: absolute;
  top: 100px;
  left: 869px;
}

.hire .frame-107 {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: absolute;
  top: 115px;
  left: 81px;
}

.hire .group-41 {
  width: 37px;
  height: 52px;
  position: absolute;
  top: 152px;
  left: 1140px;
}

.hire .linkedin-2 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1187px;
}

.hire .facebook-2 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1234px;
}

.hire .facebook-3 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1281px;
}

.hire .facebook-4 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1328px;
}

.hire .group-42 {
  width: 400px;
  height: 17px;
  position: absolute;
  top: 242px;
  left: 501px;
}

.hire .frame-108 {
  justify-content: center;
  align-items: center;
  gap: 5px;
  display: inline-flex;
  position: relative;
}

.hire .text-wrapper-83 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.hire .ellipse-14 {
  background-color: var(--grey-01);
  border-radius: 1.5px;
  width: 3px;
  height: 3px;
  position: relative;
}

.hire .logo-3 {
  width: 116px;
  height: 56px;
  position: absolute;
  top: 39px;
  left: 76px;
}

.consulting {
  background-color: #fff;
  flex-direction: row;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.consulting .div-2 {
  background-color: #fff;
  position: relative;
}

.consulting .header {
  width: 390px !important;
  height: 60px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.consulting .frame-4 {
  flex-direction: column;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: -275px;
  left: 361px;
}

.consulting .let-s-connect-you-ve {
  color: #0000;
  letter-spacing: -.75px;
  text-align: center;
  width: 940px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 50px;
  font-weight: 400;
  line-height: 60px;
  position: relative;
}

.consulting .span {
  color: #00dfc2;
  font-weight: 700;
}

.consulting .text-wrapper-5 {
  color: #fff;
  font-family: var(--h1-REG-font-family);
  font-size: var(--h1-REG-font-size);
  font-style: var(--h1-REG-font-style);
  font-weight: var(--h1-REG-font-weight);
  letter-spacing: var(--h1-REG-letter-spacing);
  line-height: var(--h1-REG-line-height);
}

.consulting .text-wrapper-6 {
  color: #f6f7f8;
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  text-align: center;
  width: 897px;
  position: relative;
}

.consulting .overlap {
  position: absolute;
}

.consulting .rectangle-2 {
  background-color: #f6f7f8;
  width: 1450px;
  height: 670px;
  position: absolute;
  top: 94px;
  left: 17px;
}

.consulting .businessman-showing {
  object-fit: cover;
  width: 1450px;
  height: 670px;
  position: absolute;
  top: 94px;
  left: 17px;
}

.consulting .overlap-group-wrapper {
  background-color: var(--neutral-10);
  width: 1456px;
  height: 674px;
  position: absolute;
  top: 94px;
  left: 12px;
  overflow: hidden;
}

.consulting .overlap-group-2 {
  width: 1455px;
  height: 1178px;
  position: relative;
}

.consulting .ellipse-3 {
  border: 2px solid;
  border-color: var(--neutral-40);
  border-radius: 261px;
  width: 522px;
  height: 522px;
  position: absolute;
  top: 189px;
  left: 835px;
}

.consulting .ellipse-4 {
  background-color: #fff;
  border-radius: 233px;
  width: 466px;
  height: 466px;
  position: absolute;
  top: 217px;
  left: 863px;
}

.consulting .ellipse-5 {
  object-fit: cover;
  width: 436px;
  height: 436px;
  position: absolute;
  top: 232px;
  left: 878px;
}

.consulting .rectangle-3 {
  object-fit: cover;
  width: 1440px;
  height: 800px;
  position: absolute;
  top: 0;
  left: 0;
}

.consulting .image {
  object-fit: cover;
  width: 1440px;
  height: 674px;
  position: absolute;
  top: 0;
  left: 15px;
}

.consulting .rectangle-4 {
  mix-blend-mode: multiply;
  background: linear-gradient(90deg, #000 -13.4%, #d9d9d900 93.01%);
  width: 1454px;
  height: 680px;
  position: absolute;
  top: 0;
  left: 0;
}

.consulting .rectangle-5 {
  background-color: #d9d9d9;
  width: 1435px;
  height: 498px;
  position: absolute;
  top: 680px;
  left: 19px;
}

.consulting .frame-wrapper {
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
  display: inline-flex;
  position: absolute;
  top: 239px;
  left: 107px;
}

.consulting .frame-5 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 32px;
  width: 100%;
  display: flex;
  position: relative;
}

.consulting .group {
  width: 538px;
  height: 183px;
  margin-right: -2px;
  position: relative;
}

.consulting .text-wrapper-7 {
  color: var(--neutral-0, #fff);
  font-family: var(--title-mega-font-family);
  font-size: var(--title-mega-font-size);
  font-style: var(--title-mega-font-style);
  font-weight: var(--title-mega-font-weight);
  letter-spacing: var(--title-mega-letter-spacing);
  line-height: var(--title-mega-line-height);
  letter-spacing: -1.8px;
  width: 536px;
  font-family: Source Sans Pro;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 101.5%;
  position: absolute;
  top: 0;
  left: 0;
}

.consulting .frame-6 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.consulting .we-at-joulestowatts {
  color: var(--neutral-0);
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  width: 519px;
  margin-top: -1px;
  position: relative;
}

.consulting .CTA-instance {
  border: unset !important;
  border-color: unset !important;
  background-color: #4d9f98 !important;
  flex: none !important;
  justify-content: center !important;
  align-items: flex-end !important;
  width: 182px !important;
  display: flex !important;
}

.consulting .instance-node {
  margin-left: -1px !important;
  font-weight: 700 !important;
}

.consulting .CTA-2 {
  margin-right: -1px !important;
}

.consulting .div-wrapper {
  background-color: #f6f7f8;
  width: 1479px;
  height: 837px;
  position: absolute;
  top: 1830px;
  left: 0;
}

.consulting .frame-7 {
  flex-direction: column;
  align-items: center;
  gap: 54px;
  width: 1297px;
  height: 42px;
  display: flex;
  position: relative;
  top: 120px;
  left: 94px;
}

.consulting .frame-8 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 16px;
  width: 100%;
  margin-bottom: -7.77px;
  display: flex;
  position: relative;
}

.consulting .text-wrapper-8 {
  color: #1f1f1f;
  letter-spacing: -1.05px;
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 42px;
  font-weight: 600;
  line-height: 50.4px;
  position: relative;
}

.consulting .overlap-wrapper {
  position: absolute;
  overflow: hidden;
}

.consulting .overlap-2 {
  position: relative;
}

.consulting .frame-9 {
  background-color: var(--neutral-10);
  width: 1456px;
  height: 674px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.consulting .overlap-group-3 {
  width: 1435px;
  height: 1003px;
  position: absolute;
  top: 175px;
  left: 19px;
}

.consulting .ellipse-6 {
  border: 2px solid;
  border-color: var(--neutral-40);
  border-radius: 261px;
  width: 522px;
  height: 522px;
  position: absolute;
  top: 14px;
  left: 816px;
}

.consulting .ellipse-7 {
  background-color: #fff;
  border-radius: 233px;
  width: 466px;
  height: 466px;
  position: absolute;
  top: 42px;
  left: 844px;
}

.consulting .rectangle-6 {
  mix-blend-mode: multiply;
  background: linear-gradient(#000 0%, #d9d9d900 100%);
  width: 639px;
  height: 298px;
  position: absolute;
  top: 0;
  left: 625px;
}

.consulting .rectangle-7 {
  background-color: #d9d9d9;
  width: 1435px;
  height: 498px;
  position: absolute;
  top: 505px;
  left: 0;
}

.consulting .ellipse-8 {
  object-fit: cover;
  width: 436px;
  height: 436px;
  position: absolute;
  top: -2265px;
  left: 6302px;
}

.consulting .image-2 {
  object-fit: cover;
  width: 390px;
  height: 442px;
  position: absolute;
  top: 0;
  left: 0;
}

.consulting .frame-10 {
  z-index: 1;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 358px;
  padding: 0 54px;
  display: flex;
  position: absolute;
  top: 86px;
  left: 16px;
}

.consulting .text-wrapper-9 {
  color: #fff;
  letter-spacing: -.8px;
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 600;
  line-height: 38.4px;
  position: relative;
}

.consulting .we-at-joulestowatts-2 {
  color: var(--neutral-0);
  letter-spacing: -.16px;
  text-align: center;
  width: 282px;
  margin-left: -16px;
  margin-right: -16px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  position: relative;
}

.consulting .CTA-3 {
  flex: none !important;
}

.consulting .group-2 {
  width: 833px;
  height: 570px;
  position: absolute;
  top: 0;
  left: 352px;
}

.consulting .text-wrapper-10 {
  color: #f6f7f8;
  letter-spacing: -1.05px;
  text-align: center;
  white-space: nowrap;
  width: 81px;
  height: 41px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 42px;
  font-weight: 700;
  line-height: 50.4px;
  position: absolute;
  top: 250px;
  left: 736px;
}

.consulting .text-wrapper-11 {
  color: #4d9f98;
  letter-spacing: -.24px;
  width: 176px;
  height: 34px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  position: absolute;
  top: 59px;
  left: 222px;
}

.consulting .text-wrapper-12 {
  color: #4d9f98;
  letter-spacing: -.24px;
  width: 176px;
  height: 34px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  position: absolute;
  top: 67px;
  left: 1156px;
}

.consulting .text-wrapper-13 {
  color: #4d9f98;
  letter-spacing: -.24px;
  width: 227px;
  height: 34px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  position: absolute;
  top: 387px;
  left: 189px;
}

.consulting .text-wrapper-14 {
  color: #4d9f98;
  letter-spacing: -.24px;
  width: 276px;
  height: 34px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  position: absolute;
  top: 378px;
  left: 1156px;
}

.consulting .frame-11 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 48px;
  width: 378px;
  height: 140px;
  display: flex;
  position: absolute;
  top: 107px;
  left: 0;
}

.consulting .frame-12 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-end;
  gap: 32px;
  width: 100%;
  margin-top: -2px;
  margin-bottom: -2px;
  display: flex;
  position: relative;
}

.consulting .text-wrapper-15 {
  color: #2c3e47;
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  text-align: right;
  width: 242px;
  margin-top: -1px;
  position: relative;
}

.consulting .frame-13 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 32px;
  width: 378px;
  height: 94px;
  display: flex;
  position: absolute;
  top: 435px;
  left: 0;
}

.consulting .frame-14 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 24px;
  margin-top: -1px;
  margin-bottom: -1px;
  display: inline-flex;
  position: relative;
}

.consulting .frame-15 {
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
  width: 254px;
  height: 168px;
  display: flex;
  position: absolute;
  top: 111px;
  left: 1156px;
}

.consulting .frame-16 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 24px;
  margin-right: -4px;
  display: inline-flex;
  position: relative;
}

.consulting .text-wrapper-16 {
  color: #2c3e47;
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  width: 258px;
  margin-top: -1px;
  position: relative;
}

.consulting .frame-17 {
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
  width: 254px;
  height: 168px;
  display: flex;
  position: absolute;
  top: 422px;
  left: 1160px;
}

.consulting .group-3 {
  width: 63px;
  height: 58px;
  position: absolute;
  top: 86px;
  left: 442px;
}

.consulting .vector {
  width: 64px;
  height: 64px;
  position: absolute;
  top: 78px;
  left: 1042px;
}

.consulting .vector-2 {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 427px;
  left: 461px;
}

.consulting .vector-3 {
  width: 74px;
  height: 74px;
  position: absolute;
  top: 402px;
  left: 436px;
}

.consulting .group-4 {
  width: 65px;
  height: 59px;
  position: absolute;
  top: 405px;
  left: 1040px;
}

.consulting .group-5 {
  width: 1479px;
  height: 847px;
  position: absolute;
  top: 1014px;
  left: 0;
}

.consulting .overlap-3 {
  height: 848px;
  position: relative;
}

.consulting .group-6 {
  background-color: var(--neutral-0);
  width: 1479px;
  height: 801px;
  position: absolute;
  top: 0;
  left: 0;
}

.consulting .frame-18 {
  background-color: var(--neutral-0);
  width: 1297px;
  height: 51px;
  position: relative;
  top: 49px;
  left: 94px;
}

.consulting .frame-19 {
  background-color: var(--neutral-0);
  border: 1px solid;
  border-color: var(--neutral-50);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 39px;
  width: 494px;
  height: 654px;
  padding: 0 0 56px;
  display: flex;
  position: absolute;
  top: 194px;
  left: 27px;
}

.consulting .deepmind-wrapper {
  align-self: stretch;
  width: 100%;
  height: 260px;
  position: relative;
}

.consulting .deepmind {
  object-fit: cover;
  width: 568px;
  height: 350px;
  position: absolute;
  top: -75px;
  left: -76px;
}

.consulting .frame-20 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 14px;
  width: 100%;
  padding: 0 56px 0 80px;
  display: flex;
  position: relative;
}

.consulting .frame-21 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  padding: 8px 0 0;
  display: flex;
  position: relative;
}

.consulting .text-wrapper-17 {
  color: #4d4d4d;
  letter-spacing: -.53px;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 400;
  line-height: 42px;
  position: relative;
}

.consulting .frame-22 {
  flex: none;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.consulting .text-wrapper-18 {
  color: #4d9f98;
  font-family: var(--subtitle-BLD-font-family);
  font-size: var(--subtitle-BLD-font-size);
  font-style: var(--subtitle-BLD-font-style);
  font-weight: var(--subtitle-BLD-font-weight);
  letter-spacing: var(--subtitle-BLD-letter-spacing);
  line-height: var(--subtitle-BLD-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.consulting .frame-23 {
  background-color: var(--neutral-0);
  border: 1px solid;
  border-color: var(--neutral-50);
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 494px;
  height: 654px;
  padding: 0 0 40px;
  display: flex;
  position: absolute;
  top: 194px;
  left: 975px;
}

.consulting .deepmind-hereyczt {
  object-fit: cover;
  align-self: stretch;
  width: 100%;
  height: 343px;
  position: relative;
}

.consulting .frame-24 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 14px;
  width: 100%;
  padding: 0 80px 0 56px;
  display: flex;
  position: relative;
}

.consulting .frame-25 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.consulting .support {
  color: #4d4d4d;
  letter-spacing: -.53px;
  width: 368px;
  margin-top: -1px;
  margin-right: -12px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 400;
  line-height: 42px;
  position: relative;
}

.consulting .frame-26 {
  flex: none;
  position: relative;
}

.consulting .frame-27 {
  background-color: var(--neutral-0);
  border: 1px solid;
  border-color: var(--neutral-50);
  width: 458px;
  height: 654px;
  position: absolute;
  top: 194px;
  left: 519px;
  overflow: hidden;
}

.consulting .frame-28 {
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  width: 456px;
  padding: 0 48px;
  display: flex;
  position: absolute;
  top: 372px;
  left: 0;
}

.consulting .technology-driven {
  color: #4d9f98;
  font-family: var(--subtitle-BLD-font-family);
  font-size: var(--subtitle-BLD-font-size);
  font-style: var(--subtitle-BLD-font-style);
  font-weight: var(--subtitle-BLD-font-weight);
  letter-spacing: var(--subtitle-BLD-letter-spacing);
  line-height: var(--subtitle-BLD-line-height);
  width: 359px;
  margin-top: -1px;
  position: relative;
}

.consulting .deepmind-hereyczt-wrapper {
  width: 589px;
  height: 415px;
  position: absolute;
  top: 0;
  left: 0;
}

.consulting .deepmind-2 {
  object-fit: cover;
  width: 480px;
  height: 343px;
  position: absolute;
  top: 1px;
  left: 0;
}

.consulting .rectangle-8 {
  background-color: var(--neutral-30);
  width: 1441px;
  height: 442px;
  position: absolute;
  top: 764px;
  left: 27px;
}

.consulting .our-processes-enable {
  color: #2e5053;
  letter-spacing: -.29px;
  width: 730px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 29px;
  font-weight: 600;
  line-height: 40.6px;
  position: absolute;
  top: 832px;
  left: 106px;
}

.consulting .rectangle-9 {
  mix-blend-mode: overlay;
  width: 602px;
  height: 631px;
  position: absolute;
  top: 700px;
  left: 865px;
}

.consulting .sebastian-svenson {
  width: 522px;
  height: 429px;
  position: absolute;
  top: 777px;
  left: 866px;
}

.consulting .header-2 {
  background-color: #0000;
  justify-content: space-between;
  align-items: center;
  width: 1440px;
  padding: 16px 80px;
  display: flex;
  position: absolute;
  top: 0;
  left: 31px;
}

.consulting .logo {
  object-fit: cover;
  width: 145px;
  height: 67.67px;
  position: relative;
}

.consulting .holder {
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.consulting .frame-29 {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 90.33px;
  padding: 10px 8px;
  display: flex;
  position: relative;
}

.consulting .text-wrapper-19 {
  color: #000;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: relative;
}

.consulting .text-wrapper-20 {
  color: #000;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-left: -2.83px;
  margin-right: -2.83px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: relative;
}

.consulting .frame-30 {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 80px;
  padding: 10px 8px;
  display: flex;
  position: relative;
}

.consulting .CTA-4 {
  border-color: var(--primary-02) !important;
  width: 179px !important;
  height: 69px !important;
  display: flex !important;
}

.consulting .CTA-5 {
  color: #000 !important;
  margin-top: unset !important;
}

.consulting .rectangle-10 {
  mix-blend-mode: multiply;
  background: linear-gradient(#000 0%, #d9d9d900 100%);
  width: 442px;
  height: 390px;
  position: absolute;
  top: 26px;
  left: -26px;
  transform: rotate(-90deg);
}

.consulting .group-7 {
  width: 1440px;
  height: 380px;
  position: absolute;
  top: 4206px;
  left: 1px;
}

.consulting .overlap-4 {
  height: 380px;
  position: relative;
}

.consulting .frame-31 {
  background-color: #4d4d4d;
  width: 1440px;
  height: 380px;
  position: absolute;
  top: 0;
  left: 0;
}

.consulting .frame-32 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 69px;
  left: 152px;
}

.consulting .frame-33 {
  flex-direction: column;
  flex: none;
  align-items: flex-end;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.consulting .text-wrapper-21 {
  color: #fff;
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.consulting .are-we-a-good-fit {
  color: var(--neutral-10);
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  text-align: right;
  width: 429px;
  position: relative;
}

.consulting .CTA-6 {
  all: unset;
  background-color: var(--secondary-04);
  box-sizing: border-box;
  flex: none;
  align-items: center;
  gap: 10px;
  padding: 12px 16px;
  display: inline-flex;
  position: relative;
}

.consulting .text-wrapper-22 {
  color: #fff;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: relative;
}

.consulting .arrow-forward-3 {
  width: 24px;
  height: 24px;
  position: relative;
}

.consulting .CTA-7 {
  cursor: pointer;
  background-color: var(--secondary-02);
  box-sizing: border-box;
  border: none;
  outline: none;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  display: inline-flex;
  position: absolute;
  top: 267px;
  left: 821px;
}

.consulting .text-wrapper-23 {
  color: var(--neutral-0);
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.consulting .frame-34 {
  background-color: #fff;
  width: 720px;
  height: 380px;
  position: absolute;
  top: 0;
  left: 0;
}

.consulting .overlap-group-4 {
  background-color: var(--neutral-10);
  width: 720px;
  height: 380px;
  position: absolute;
  top: 0;
  left: 0;
}

.consulting .frame-35 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 32px;
  display: inline-flex;
  position: relative;
  top: 49px;
  left: 128px;
}

.consulting .text-wrapper-24 {
  color: var(--secondary-04);
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.consulting .text-wrapper-25 {
  color: var(--neutral-80);
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  text-align: right;
  width: 500px;
  position: relative;
}

.consulting .frame-36 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 52px;
  left: 817px;
}

.consulting .text-wrapper-26 {
  color: #fff;
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.consulting .text-wrapper-27 {
  color: var(--neutral-10);
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  width: 429px;
  position: relative;
}

.consulting .group-8 {
  width: 1212px;
  height: 493px;
  position: absolute;
  top: 2742px;
  left: 107px;
}

.consulting .delivery-model-wrapper {
  align-items: center;
  gap: 88px;
  width: 1012px;
  display: flex;
  position: absolute;
  top: 0;
  left: 60px;
}

.consulting .delivery-model {
  color: #1f1f1f;
  letter-spacing: -.53px;
  text-align: center;
  width: 1012px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 600;
  line-height: 42px;
  position: relative;
}

.consulting .overlap-5 {
  width: 1236px;
  height: 363px;
  position: absolute;
  top: 130px;
  left: 0;
}

.consulting .group-9 {
  width: 1236px;
  height: 363px;
  position: absolute;
  top: 0;
  left: 0;
}

.consulting .overlap-6 {
  width: 1212px;
  height: 363px;
  position: relative;
}

.consulting .overlap-group-5 {
  width: 1187px;
  height: 363px;
  position: absolute;
  top: 0;
  left: 0;
}

.consulting .group-10 {
  width: 155px;
  height: 22px;
  position: absolute;
  top: 37px;
  left: 157px;
}

.consulting .group-11 {
  width: 155px;
  height: 22px;
  position: absolute;
  top: 37px;
  left: 388px;
}

.consulting .group-12 {
  width: 190px;
  height: 22px;
  position: absolute;
  top: 37px;
  left: 592px;
}

.consulting .group-13 {
  width: 191px;
  height: 22px;
  position: absolute;
  top: 37px;
  left: 829px;
}

.consulting .group-14 {
  width: 22px;
  height: 155px;
  position: absolute;
  top: 64px;
  left: 1094px;
}

.consulting .group-15 {
  width: 155px;
  height: 22px;
  position: absolute;
  top: 232px;
  left: 909px;
}

.consulting .group-16 {
  width: 198px;
  height: 22px;
  position: absolute;
  top: 232px;
  left: 666px;
}

.consulting .group-17 {
  width: 203px;
  height: 22px;
  position: absolute;
  top: 231px;
  left: 378px;
}

.consulting .rectangle-11 {
  background-color: #2e5053;
  border-radius: 4px;
  width: 190px;
  height: 89px;
  position: absolute;
  top: 8px;
  left: 0;
}

.consulting .rectangle-12 {
  background-color: #2e5053;
  border-radius: 4px;
  width: 190px;
  height: 89px;
  position: absolute;
  top: 8px;
  left: 997px;
}

.consulting .text-wrapper-28 {
  color: #fff;
  font-family: var(--subtitle-BLD-font-family);
  font-size: var(--subtitle-BLD-font-size);
  font-style: var(--subtitle-BLD-font-style);
  font-weight: var(--subtitle-BLD-font-weight);
  letter-spacing: var(--subtitle-BLD-letter-spacing);
  line-height: var(--subtitle-BLD-line-height);
  width: 132px;
  height: 70px;
  position: absolute;
  top: 18px;
  left: 29px;
}

.consulting .premier-lounge-offer {
  color: #fff;
  font-family: var(--subtitle-BLD-font-family);
  font-size: var(--subtitle-BLD-font-size);
  font-style: var(--subtitle-BLD-font-style);
  font-weight: var(--subtitle-BLD-font-weight);
  letter-spacing: var(--subtitle-BLD-letter-spacing);
  line-height: var(--subtitle-BLD-line-height);
  width: 132px;
  height: 70px;
  position: absolute;
  top: 17px;
  left: 1026px;
}

.consulting .requirements {
  color: #2e5053;
  letter-spacing: -.17px;
  width: 153px;
  height: 70px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: absolute;
  top: 115px;
  left: 279px;
}

.consulting .evaluation {
  color: #2e5053;
  letter-spacing: -.17px;
  width: 209px;
  height: 70px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: absolute;
  top: 115px;
  left: 452px;
}

.consulting .evaluation-2 {
  color: #2e5053;
  letter-spacing: -.17px;
  width: 175px;
  height: 70px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: absolute;
  top: 307px;
  left: 835px;
}

.consulting .text-wrapper-29 {
  color: #2e5053;
  letter-spacing: var(--subtitle-MED-letter-spacing);
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: var(--subtitle-MED-line-height);
  text-align: center;
  width: 134px;
  height: 70px;
  position: absolute;
  top: 115px;
  left: 742px;
}

.consulting .candidate-selection {
  color: #2e5053;
  letter-spacing: var(--subtitle-MED-letter-spacing);
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: var(--subtitle-MED-line-height);
  text-align: center;
  width: 225px;
  height: 70px;
  position: absolute;
  top: 307px;
  left: 511px;
}

.consulting .ellipse-9 {
  background-color: #d9d9d9;
  border: 3px solid #b6b6b6;
  border-radius: 50px;
  width: 100px;
  height: 100px;
  position: absolute;
  top: 1px;
  left: 298px;
}

.consulting .ellipse-10 {
  background-color: #d9d9d9;
  border: 3px solid #b6b6b6;
  border-radius: 50px;
  width: 100px;
  height: 100px;
  position: absolute;
  top: 0;
  left: 529px;
}

.consulting .ellipse-11 {
  background-color: #d9d9d9;
  border: 3px solid #b6b6b6;
  border-radius: 50px;
  width: 100px;
  height: 100px;
  position: absolute;
  top: 187px;
  left: 312px;
}

.consulting .ellipse-12 {
  background-color: #d9d9d9;
  border: 3px solid #b6b6b6;
  border-radius: 50px;
  width: 100px;
  height: 100px;
  position: absolute;
  top: 194px;
  left: 572px;
}

.consulting .ellipse-13 {
  background-color: #d9d9d9;
  border: 3px solid #b6b6b6;
  border-radius: 50px;
  width: 100px;
  height: 100px;
  position: absolute;
  top: 2px;
  left: 756px;
}

.consulting .ellipse-14 {
  background-color: #d9d9d9;
  border: 3px solid #b6b6b6;
  border-radius: 50px;
  width: 100px;
  height: 100px;
  position: absolute;
  top: 193px;
  left: 1053px;
}

.consulting .ellipse-15 {
  background-color: #d9d9d9;
  border: 3px solid #b6b6b6;
  border-radius: 50px;
  width: 100px;
  height: 100px;
  position: absolute;
  top: 193px;
  left: 821px;
}

.consulting .overlap-7 {
  background-color: #2e5053;
  border-radius: 4px;
  width: 228px;
  height: 183px;
  position: absolute;
  top: 174px;
  left: 0;
}

.consulting .overlap-8 {
  width: 150px;
  height: 142px;
  position: absolute;
  top: 0;
  left: 31px;
}

.consulting .text-wrapper-30 {
  color: #fff;
  letter-spacing: -.17px;
  width: 91px;
  height: 70px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: absolute;
  top: 21px;
  left: 8px;
}

.consulting .turn-around-time-hit {
  color: #fff;
  font-family: var(--paragraph-REG-font-family);
  font-size: var(--paragraph-REG-font-size);
  font-style: var(--paragraph-REG-font-style);
  font-weight: var(--paragraph-REG-font-weight);
  letter-spacing: var(--paragraph-REG-letter-spacing);
  line-height: var(--paragraph-REG-line-height);
  width: 150px;
  height: 77px;
  position: absolute;
  top: 65px;
  left: 0;
}

.consulting .text-wrapper-31 {
  color: #fff;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: 123px;
  height: 20px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: absolute;
  top: 142px;
  left: 39px;
}

.consulting .text-wrapper-32 {
  color: #2e5053;
  letter-spacing: -.17px;
  text-align: center;
  width: 198px;
  height: 70px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: absolute;
  top: 307px;
  left: 263px;
}

.consulting .delivery-team {
  color: #2e5053;
  letter-spacing: -.17px;
  text-align: center;
  width: 202px;
  height: 70px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: absolute;
  top: 307px;
  left: 1010px;
}

.consulting .group-18 {
  width: 40px;
  height: 49px;
  position: absolute;
  top: 25px;
  left: 328px;
}

.consulting .graph {
  width: 57px;
  height: 57px;
  position: absolute;
  top: 22px;
  left: 550px;
}

.consulting .people {
  width: 45px;
  height: 45px;
  position: absolute;
  top: 221px;
  left: 1082px;
}

.consulting .search-client {
  width: 52px;
  height: 52px;
  position: absolute;
  top: 216px;
  left: 845px;
}

.consulting .verified-account {
  width: 63px;
  height: 63px;
  position: absolute;
  top: 207px;
  left: 331px;
}

.consulting .connect {
  width: 49px;
  height: 49px;
  position: absolute;
  top: 220px;
  left: 597px;
}

.consulting .people-2 {
  width: 52px;
  height: 52px;
  position: absolute;
  top: 26px;
  left: 780px;
}

.consulting .overlap-9 {
  width: 1440px;
  height: 867px;
  position: absolute;
  top: 3336px;
  left: 0;
}

.consulting .rectangle-13 {
  background-color: #f6f7f8;
  width: 1439px;
  height: 290px;
  position: absolute;
  top: 577px;
  left: 1px;
}

.consulting .carousel-2 {
  width: 1440px;
  height: 580px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.consulting .frame-37 {
  width: 1440px;
  height: 683px;
  position: absolute;
  top: 0;
  left: 0;
}

.consulting .overlap-group-6 {
  width: 1744px;
  height: 948px;
  position: relative;
  top: -81px;
  left: -186px;
}

.consulting .rectangle-14 {
  width: 1440px;
  height: 580px;
  position: absolute;
  top: 81px;
  left: 186px;
}

.consulting .shutterstock {
  object-fit: cover;
  width: 1098px;
  height: 580px;
  position: absolute;
  top: 81px;
  left: 528px;
}

.consulting .rectangle-15 {
  background: linear-gradient(to right, #589994 50%, #fcfcfc00);
  width: 1744px;
  height: 790px;
  position: absolute;
  top: 0;
  left: 0;
}

.consulting .CTA-8 {
  border: unset !important;
  border-color: unset !important;
  background-color: #2e5053 !important;
  justify-content: center !important;
  align-items: flex-end !important;
  width: 182px !important;
  display: flex !important;
  position: absolute !important;
  top: 452px !important;
  left: 234px !important;
}

.consulting .CTA-9 {
  font-weight: 700 !important;
}

.consulting .we-are-your-growth {
  color: #fff;
  letter-spacing: -.29px;
  width: 587px;
  height: 164px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 29px;
  font-weight: 600;
  line-height: 40.6px;
  position: absolute;
  top: 214px;
  left: 234px;
}

.consulting .rectangle-16 {
  background-color: #d9d9d9;
  width: 1440px;
  height: 290px;
  position: absolute;
  top: 658px;
  left: 186px;
}

.consulting .frame-38 {
  width: 1440px;
  height: 580px;
  position: absolute;
  top: 0;
  left: 1440px;
  overflow: hidden;
}

.consulting .rectangle-17 {
  width: 1383px;
  height: 634px;
  position: absolute;
  top: -558px;
  left: 4331px;
}

.consulting .overlap-10 {
  filter: blur(80px);
  background: linear-gradient(#000 0%, #071103 25%, #202020 44.79%, #2f2f2f 61.46%, #0000 100%);
  width: 1684px;
  height: 714px;
  position: absolute;
  top: -60px;
  left: -116px;
}

.consulting .frame-39 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: absolute;
  top: 242px;
  left: 196px;
}

.consulting .text-wrapper-33 {
  color: var(--neutral-0);
  letter-spacing: -.53px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 600;
  line-height: 49px;
  position: relative;
}

.consulting .our-client-and {
  color: #f6f7f8;
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  width: 436px;
  margin-top: -1px;
  position: relative;
}

.consulting .CTA-10 {
  all: unset;
  box-sizing: border-box;
  flex: none;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.consulting .text-wrapper-34 {
  color: var(--neutral-0);
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 23.8px;
  position: relative;
}

.consulting .arrow-forward-4 {
  width: 24px;
  height: 24px;
  margin-top: -930px;
  margin-right: -3904px;
  position: relative;
}

.consulting .text-wrapper-35 {
  color: var(--neutral-0);
  font-family: var(--h4-REG-font-family);
  font-size: var(--h4-REG-font-size);
  font-style: var(--h4-REG-font-style);
  font-weight: var(--h4-REG-font-weight);
  letter-spacing: var(--h4-REG-letter-spacing);
  line-height: var(--h4-REG-line-height);
  white-space: nowrap;
  height: 41px;
  position: absolute;
  top: 120px;
  left: 196px;
}

.consulting .frame-40 {
  align-items: flex-start;
  gap: 5px;
  display: inline-flex;
  position: absolute;
  top: 560px;
  left: 196px;
}

.consulting .ellipse-16 {
  background-color: #d9d9d952;
  border-radius: 5.5px;
  width: 11px;
  height: 11px;
  position: relative;
}

.consulting .ellipse-17 {
  background-color: var(--neutral-30);
  border-radius: 5.5px;
  width: 11px;
  height: 11px;
  position: relative;
}

.consulting .carousel-03 {
  position: absolute !important;
  top: 0 !important;
  left: 2880px !important;
}

.consulting .carousel-instance {
  margin-top: -953px !important;
  margin-right: -2464px !important;
}

.consulting .frame-41 {
  flex-direction: column;
  align-items: center;
  gap: 25px;
  display: inline-flex;
  position: absolute;
  top: 649px;
  left: 149px;
}

.consulting .frame-42 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 51px;
  display: inline-flex;
  position: relative;
}

.consulting .frame-43 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 26px;
  display: inline-flex;
  position: relative;
}

.consulting .frame-44 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.consulting .frame-45 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.consulting .text-wrapper-36 {
  color: var(--neutral-90);
  letter-spacing: -.2px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  position: relative;
}

.consulting .frame-46 {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 29px;
  display: inline-flex;
  position: relative;
}

.consulting .mercedes-benz-logo {
  object-fit: cover;
  width: 248px;
  height: 63px;
  position: relative;
}

.consulting .GE-healthcare-logo {
  object-fit: cover;
  width: 164px;
  height: 92px;
  position: relative;
}

.consulting .sony-logo {
  object-fit: cover;
  width: 123px;
  height: 22px;
  position: relative;
}

.consulting .shell-logo {
  object-fit: cover;
  width: 115px;
  height: 65px;
  position: relative;
}

.consulting .amazon {
  object-fit: cover;
  width: 178px;
  height: 55px;
  position: relative;
}

.consulting .wipro-logo-new-og {
  object-fit: cover;
  width: 171px;
  height: 89px;
  position: relative;
}

.consulting .footer {
  background-color: var(--white);
  width: 1441px;
  height: 292px;
  position: absolute;
  top: 4586px;
  left: -1px;
}

.consulting .overlap-11 {
  width: 1441px;
  height: 292px;
  position: relative;
}

.consulting .frame-47 {
  background-color: var(--neutral-90);
  width: 1441px;
  height: 292px;
  position: absolute;
  top: 0;
  left: 0;
}

.consulting .DIV {
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 53px;
  left: 738px;
}

.consulting .text-wrapper-37 {
  color: var(--white);
  letter-spacing: -.2px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.consulting .text-wrapper-38 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1233px;
}

.consulting .text-wrapper-39 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1282px;
}

.consulting .text-wrapper-40 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1331px;
}

.consulting .text-wrapper-41 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1187px;
}

.consulting .text-wrapper-42 {
  color: var(--white);
  letter-spacing: -.2px;
  white-space: nowrap;
  height: 28px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: absolute;
  top: 100px;
  left: 738px;
}

.consulting .text-wrapper-43 {
  color: var(--white);
  letter-spacing: -.2px;
  white-space: nowrap;
  height: 28px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: absolute;
  top: 100px;
  left: 869px;
}

.consulting .frame-48 {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: absolute;
  top: 115px;
  left: 81px;
}

.consulting .text-wrapper-44 {
  color: #fff;
  font-family: var(--paragraph-ALCPS-font-family);
  font-size: var(--paragraph-ALCPS-font-size);
  font-style: var(--paragraph-ALCPS-font-style);
  font-weight: var(--paragraph-ALCPS-font-weight);
  letter-spacing: var(--paragraph-ALCPS-letter-spacing);
  line-height: var(--paragraph-ALCPS-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.consulting .text-wrapper-45 {
  color: #f6f7f8;
  font-family: var(--paragraph-REG-font-family);
  font-size: var(--paragraph-REG-font-size);
  font-style: var(--paragraph-REG-font-style);
  font-weight: var(--paragraph-REG-font-weight);
  letter-spacing: var(--paragraph-REG-letter-spacing);
  line-height: var(--paragraph-REG-line-height);
  width: 324px;
  position: relative;
}

.consulting .group-19 {
  width: 37px;
  height: 52px;
  position: absolute;
  top: 152px;
  left: 1140px;
}

.consulting .text-wrapper-46 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 38px;
  left: 1px;
}

.consulting .face-book {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 0;
  left: 0;
}

.consulting .linkedin {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1187px;
}

.consulting .facebook {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1234px;
}

.consulting .facebook-2 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1281px;
}

.consulting .facebook-3 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1328px;
}

.consulting .group-20 {
  width: 400px;
  height: 17px;
  position: absolute;
  top: 242px;
  left: 501px;
}

.consulting .frame-49 {
  justify-content: center;
  align-items: center;
  gap: 5px;
  display: inline-flex;
  position: relative;
}

.consulting .text-wrapper-47 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.consulting .ellipse-18 {
  background-color: var(--grey-01);
  border-radius: 1.5px;
  width: 3px;
  height: 3px;
  position: relative;
}

.consulting .logo-2 {
  width: 116px;
  height: 56px;
  position: absolute;
  top: 39px;
  left: 76px;
}

.consulting .footer-2 {
  background-color: var(--white);
  width: 390px;
  height: 860px;
  position: absolute;
  top: 6958px;
  left: 0;
  overflow: hidden;
}

.consulting .overlap-12 {
  width: 390px;
  height: 907px;
  position: relative;
}

.consulting .frame-50 {
  background-color: var(--neutral-90);
  width: 390px;
  height: 907px;
  position: absolute;
  top: 0;
  left: 0;
}

.consulting .frame-51 {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: absolute;
  top: 115px;
  left: 26px;
}

.consulting .DIV-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 113px;
  display: flex;
  position: absolute;
  top: 272px;
  left: 195px;
}

.consulting .text-wrapper-48 {
  color: var(--white);
  letter-spacing: -.2px;
  white-space: nowrap;
  width: fit-content;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.consulting .DIV-3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 113px;
  display: flex;
  position: absolute;
  top: 272px;
  left: 26px;
}

.consulting .frame-52 {
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 583px;
  left: 69px;
}

.consulting .group-21 {
  width: 37px;
  height: 52px;
  position: relative;
}

.consulting .frame-53 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.consulting .text-wrapper-49 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  width: fit-content;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: relative;
}

.consulting .facebook-4 {
  width: 35px;
  height: 35px;
  position: relative;
}

.consulting .frame-54 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.consulting .logo-3 {
  width: 116px;
  height: 56px;
  position: absolute;
  top: 39px;
  left: 24px;
}

.consulting .group-22 {
  width: 295px;
  height: 64px;
  position: absolute;
  top: 732px;
  left: 48px;
}

.consulting .frame-55 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 295px;
  display: flex;
  position: relative;
}

.consulting .text-wrapper-50 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.consulting .ellipse-19 {
  background-color: var(--grey-01);
  border-radius: 1px / 1.5px;
  width: 2px;
  height: 3px;
  position: relative;
}

.consulting .text-wrapper-51 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  text-align: center;
  align-self: stretch;
  position: relative;
}

.consulting .frame-56 {
  background-color: #e9e9e9;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 390px;
  height: 655px;
  padding: 40px 10px;
  display: flex;
  position: absolute;
  top: 502px;
  left: 0;
}

.consulting .frame-57 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  width: 358px;
  display: flex;
  position: relative;
}

.consulting .our-processes-enable-wrapper {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 16px;
  width: 358px;
  display: flex;
  position: relative;
}

.consulting .our-processes-enable-2 {
  color: #2e5053;
  letter-spacing: -.24px;
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  position: relative;
}

.consulting .frame-58 {
  width: 355px;
  height: 261px;
  position: relative;
}

.consulting .sebastian-svenson-wrapper {
  background-image: url("rectangle-138-2.a9b385cd.svg");
  background-size: 100% 100%;
  width: 390px;
  height: 575px;
  position: relative;
  top: -200px;
  left: -16px;
}

.consulting .sebastian-svenson-2 {
  width: 318px;
  height: 261px;
  position: absolute;
  top: 200px;
  left: 33px;
}

.consulting .frame-59 {
  background-color: #f6f7f8;
  width: 390px;
  height: 1217px;
  position: absolute;
  top: 2684px;
  left: 0;
}

.consulting .overlap-13 {
  width: 336px;
  height: 598px;
  position: absolute;
  top: 40px;
  left: 8px;
}

.consulting .overlap-14 {
  width: 336px;
  height: 325px;
  position: absolute;
  top: 0;
  left: 0;
}

.consulting .frame-60 {
  width: 124px;
  height: 138px;
  position: absolute;
  top: 0;
  left: 125px;
}

.consulting .overlap-group-7 {
  height: 138px;
  position: relative;
}

.consulting .vector-4 {
  width: 118px;
  height: 118px;
  position: absolute;
  top: 10px;
  left: 3px;
}

.consulting .vector-5 {
  width: 118px;
  height: 34px;
  position: absolute;
  top: 0;
  left: 3px;
}

.consulting .vector-6 {
  width: 124px;
  height: 40px;
  position: absolute;
  top: 98px;
  left: 0;
}

.consulting .text-wrapper-52 {
  color: #f6f7f8;
  letter-spacing: -.55px;
  text-align: center;
  white-space: nowrap;
  width: 43px;
  height: 22px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 22px;
  font-weight: 700;
  line-height: 26.4px;
  position: absolute;
  top: 55px;
  left: 41px;
}

.consulting .overlap-15 {
  width: 116px;
  height: 212px;
  position: absolute;
  top: 63px;
  left: 0;
}

.consulting .frame-61 {
  width: 73px;
  height: 119px;
  position: absolute;
  top: 0;
  left: 43px;
}

.consulting .frame-62 {
  width: 98px;
  height: 98px;
  position: absolute;
  top: 114px;
  left: 0;
}

.consulting .overlap-16 {
  width: 238px;
  height: 262px;
  position: absolute;
  top: 63px;
  left: 98px;
}

.consulting .frame-63 {
  width: 73px;
  height: 259px;
  position: absolute;
  top: 0;
  left: 165px;
}

.consulting .vector-7 {
  width: 5px;
  height: 5px;
  position: absolute;
  top: 257px;
  left: 229px;
}

.consulting .frame-64 {
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;
  display: inline-flex;
  position: absolute;
  top: 126px;
  left: 0;
}

.consulting .text-wrapper-53 {
  color: #4d9f98;
  letter-spacing: -.18px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 18px;
  font-weight: 600;
  line-height: 25.2px;
  position: relative;
}

.consulting .text-wrapper-54 {
  color: #2c3e47;
  letter-spacing: -.16px;
  width: 202px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  position: relative;
}

.consulting .group-23 {
  width: 4px;
  height: 321px;
  position: absolute;
  top: 277px;
  left: 47px;
}

.consulting .frame-65 {
  width: 98px;
  height: 98px;
  position: absolute;
  top: 640px;
  left: 8px;
}

.consulting .frame-66 {
  width: 98px;
  height: 98px;
  position: absolute;
  top: 928px;
  left: 289px;
}

.consulting .frame-67 {
  width: 98px;
  height: 98px;
  position: absolute;
  top: 377px;
  left: 289px;
}

.consulting .group-24 {
  width: 4px;
  height: 443px;
  position: absolute;
  top: 475px;
  left: 336px;
}

.consulting .frame-68 {
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;
  display: inline-flex;
  position: absolute;
  top: 651px;
  left: 106px;
}

.consulting .frame-69 {
  flex-direction: column;
  align-items: flex-end;
  gap: 1px;
  display: inline-flex;
  position: absolute;
  top: 396px;
  left: 70px;
}

.consulting .text-wrapper-55 {
  color: #4d9f98;
  letter-spacing: -.18px;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 18px;
  font-weight: 600;
  line-height: 25.2px;
  position: relative;
}

.consulting .text-wrapper-56 {
  color: #2c3e47;
  letter-spacing: -.16px;
  text-align: right;
  width: 202px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  position: relative;
}

.consulting .frame-70 {
  flex-direction: column;
  align-items: flex-end;
  gap: 1px;
  display: inline-flex;
  position: absolute;
  top: 936px;
  left: 70px;
}

.consulting .carousel-3 {
  width: 390px;
  height: 796px;
  position: absolute;
  top: 5112px;
  left: 0;
  overflow: hidden;
}

.consulting .frame-71 {
  width: 667px;
  height: 1425px;
  position: absolute;
  top: 0;
  left: 0;
}

.consulting .overlap-group-8 {
  width: 687px;
  height: 923px;
  position: relative;
  top: -24px;
  left: -134px;
}

.consulting .rectangle-18 {
  width: 390px;
  height: 485px;
  position: absolute;
  top: 24px;
  left: 134px;
}

.consulting .frame-72 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  height: 261px;
  display: inline-flex;
  position: absolute;
  top: 166px;
  left: 214px;
}

.consulting .shutterstock-2 {
  object-fit: cover;
  width: 390px;
  height: 441px;
  position: absolute;
  top: 379px;
  left: 134px;
}

.consulting .rectangle-19 {
  filter: blur(40px);
  background: linear-gradient(90deg, #589993 5.95%, #4d9f98 20.62% 48.33%, #fcfcfc00 71.42%);
  width: 923px;
  height: 687px;
  position: absolute;
  top: 118px;
  left: -118px;
  transform: rotate(90deg);
}

.consulting .frame-73 {
  flex-direction: column;
  align-items: flex-start;
  gap: 33px;
  display: inline-flex;
  position: absolute;
  top: 106px;
  left: 150px;
}

.consulting .we-are-your-growth-2 {
  color: #fff;
  letter-spacing: -.22px;
  width: 358px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 22px;
  font-weight: 600;
  line-height: 30.8px;
  position: relative;
}

.consulting .CTA-11 {
  border: unset !important;
  border-color: unset !important;
  background-color: #2e5053 !important;
  flex: none !important;
  justify-content: center !important;
  align-items: flex-end !important;
  width: 127px !important;
  display: flex !important;
}

.consulting .CTA-12 {
  margin-left: -2px !important;
  font-weight: 700 !important;
}

.consulting .CTA-13 {
  margin-right: -2px !important;
}

.consulting .rectangle-20 {
  width: 1383px;
  height: 634px;
  position: absolute;
  top: -7576px;
  left: 4331px;
}

.consulting .arrow-forward-5 {
  width: 24px;
  height: 24px;
  margin-top: -7948px;
  margin-right: -3904px;
  position: relative;
}

.consulting .carousel-03-instance {
  margin-top: -7971px !important;
  margin-right: -2464px !important;
}

.consulting .frame-74 {
  width: 742px;
  height: 1050px;
  position: absolute;
  top: 5908px;
  left: -185px;
}

.consulting .frame-75 {
  width: 742px;
  height: 307px;
  position: absolute;
  top: 0;
  left: 0;
}

.consulting .overlap-group-9 {
  height: 307px;
  position: relative;
}

.consulting .rectangle-21 {
  background-color: #f6f7f8;
  width: 390px;
  height: 307px;
  position: absolute;
  top: 0;
  left: 185px;
}

.consulting .frame-76 {
  flex-direction: column;
  align-items: center;
  gap: 25px;
  display: inline-flex;
  position: absolute;
  top: 76px;
  left: 0;
}

.consulting .text-wrapper-57 {
  color: var(--neutral-60);
  letter-spacing: -.2px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  position: relative;
}

.consulting .mercedes-benz-logo-2 {
  object-fit: cover;
  width: 148px;
  height: 37px;
  position: relative;
}

.consulting .GE-healthcare-logo-2 {
  object-fit: cover;
  width: 98px;
  height: 55px;
  position: relative;
}

.consulting .sony-logo-2 {
  object-fit: cover;
  width: 74px;
  height: 13px;
  position: relative;
}

.consulting .shell-logo-2 {
  object-fit: cover;
  width: 69px;
  height: 39px;
  position: relative;
}

.consulting .amazon-2 {
  object-fit: cover;
  width: 70px;
  height: 33px;
  position: relative;
}

.consulting .wipro-logo-new-og-2 {
  object-fit: cover;
  width: 102px;
  height: 53px;
  position: relative;
}

.consulting .frame-77 {
  flex-direction: column;
  align-items: center;
  gap: 92px;
  display: inline-flex;
  position: absolute;
  top: 366px;
  left: 185px;
}

.consulting .frame-78 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-end;
  gap: 32px;
  width: 328px;
  display: flex;
  position: relative;
}

.consulting .frame-79 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 24px;
  width: 328px;
  display: flex;
  position: relative;
}

.consulting .text-wrapper-58 {
  color: var(--secondary-04);
  letter-spacing: -.8px;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.4px;
  position: relative;
}

.consulting .text-wrapper-59 {
  color: var(--neutral-80);
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  text-align: center;
  width: 297px;
  position: relative;
}

.consulting .group-25 {
  width: 390px;
  height: 360px;
  position: relative;
}

.consulting .frame-80 {
  height: 360px;
}

.consulting .overlap-group-10 {
  background-color: var(--neutral-70);
  width: 1440px;
  height: 360px;
}

.consulting .frame-81 {
  flex-direction: column;
  align-items: center;
  gap: 24px;
  display: inline-flex;
  position: relative;
  top: 85px;
  left: 41px;
}

.consulting .text-wrapper-60 {
  color: var(--neutral-0);
  letter-spacing: -.8px;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.4px;
  position: relative;
}

.consulting .are-we-a-good-fit-2 {
  color: #f6f7f8;
  letter-spacing: -.2px;
  text-align: center;
  width: 309px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.consulting .CTA-14 {
  cursor: pointer;
  background-color: var(--secondary-02);
  box-sizing: border-box;
  border: none;
  outline: none;
  flex: none;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  display: inline-flex;
  position: relative;
}

.consulting .frame-82 {
  background-color: var(--neutral-0);
  border: 1px solid;
  border-color: var(--neutral-50);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  width: 353px;
  padding: 0 0 32px;
  display: flex;
  position: absolute;
  top: 1212px;
  left: 18px;
  overflow: hidden;
}

.consulting .deepmind-3 {
  object-fit: cover;
  width: 391.81px;
  height: 240px;
  margin-right: -38.81px;
  position: relative;
}

.consulting .frame-83 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 14px;
  width: 100%;
  padding: 0 32px;
  display: flex;
  position: relative;
}

.consulting .text-wrapper-61 {
  color: #4d4d4d;
  letter-spacing: -.42px;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 28px;
  font-weight: 400;
  line-height: 33.6px;
  position: relative;
}

.consulting .frame-84 {
  background-color: var(--neutral-0);
  border: 1px solid;
  border-color: var(--neutral-50);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  width: 353px;
  padding: 0 0 32px;
  display: flex;
  position: absolute;
  top: 1696px;
  left: 18px;
}

.consulting .deepmind-4 {
  object-fit: cover;
  width: 351px;
  height: 240px;
  position: relative;
}

.consulting .frame-85 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 10px;
  width: 300px;
  margin-right: -13px;
  display: flex;
  position: relative;
}

.consulting .frame-86 {
  flex: none;
  align-items: flex-start;
  gap: 10px;
  width: 199px;
  display: flex;
  position: relative;
}

.consulting .text-wrapper-62 {
  color: #4d9f98;
  font-family: var(--subtitle-BLD-font-family);
  font-size: var(--subtitle-BLD-font-size);
  font-style: var(--subtitle-BLD-font-style);
  font-weight: var(--subtitle-BLD-font-weight);
  letter-spacing: var(--subtitle-BLD-letter-spacing);
  line-height: var(--subtitle-BLD-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-right: -39px;
  position: relative;
}

.consulting .technology-driven-wrapper {
  flex: none;
  align-items: flex-start;
  gap: 10px;
  width: 300px;
  display: flex;
  position: relative;
}

.consulting .technology-driven-2 {
  color: #4d9f98;
  font-family: var(--subtitle-BLD-font-family);
  font-size: var(--subtitle-BLD-font-size);
  font-style: var(--subtitle-BLD-font-style);
  font-weight: var(--subtitle-BLD-font-weight);
  letter-spacing: var(--subtitle-BLD-letter-spacing);
  line-height: var(--subtitle-BLD-line-height);
  width: 300px;
  margin-top: -1px;
  position: relative;
}

.consulting .frame-87 {
  background-color: var(--neutral-0);
  border: 1px solid;
  border-color: var(--neutral-50);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  width: 353px;
  padding: 0 0 32px;
  display: flex;
  position: absolute;
  top: 2180px;
  left: 18px;
  overflow: hidden;
}

.consulting .deepmind-5 {
  object-fit: cover;
  width: 369px;
  height: 240px;
  margin-right: -16px;
  position: relative;
}

.consulting .text-wrapper-63 {
  color: #4d9f98;
  font-family: var(--subtitle-BLD-font-family);
  font-size: var(--subtitle-BLD-font-size);
  font-style: var(--subtitle-BLD-font-style);
  font-weight: var(--subtitle-BLD-font-weight);
  letter-spacing: var(--subtitle-BLD-letter-spacing);
  line-height: var(--subtitle-BLD-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.consulting .text-wrapper-64 {
  color: #1f1f1f;
  letter-spacing: -.53px;
  text-align: center;
  white-space: nowrap;
  height: 42px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 600;
  line-height: 42px;
  position: absolute;
  top: 3949px;
  left: 85px;
}

.consulting .overlap-17 {
  width: 319px;
  height: 833px;
  position: absolute;
  top: 4031px;
  left: 25px;
}

.consulting .frame-88 {
  background-color: #2e5053;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 9px 29px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 67px;
  overflow: hidden;
}

.consulting .text-wrapper-65 {
  color: #fff;
  letter-spacing: -.16px;
  text-align: center;
  width: 132px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 22.4px;
  position: relative;
}

.consulting .frame-89 {
  width: 255px;
  height: 766px;
  position: absolute;
  top: 57px;
  left: 29px;
}

.consulting .line {
  width: 17px;
  height: 3px;
  position: absolute;
  top: 547px;
  left: 175px;
}

.consulting .line-2 {
  width: 3px;
  height: 11px;
  position: absolute;
  top: 638px;
  left: 228px;
}

.consulting .line-3 {
  width: 3px;
  height: 11px;
  position: absolute;
  top: 822px;
  left: 97px;
}

.consulting .line-4 {
  width: 11px;
  height: 3px;
  position: absolute;
  top: 683px;
  left: 148px;
}

.consulting .frame-90 {
  width: 43px;
  height: 22px;
  position: absolute;
  top: 215px;
  left: 82px;
}

.consulting .line-5 {
  width: 3px;
  height: 23px;
  position: absolute;
  top: 71px;
  left: 159px;
}

.consulting .line-6 {
  width: 3px;
  height: 80px;
  position: absolute;
  top: 309px;
  left: 281px;
}

.consulting .frame-91 {
  width: 81px;
  height: 96px;
  position: absolute;
  top: 195px;
  left: 0;
}

.consulting .text-wrapper-66 {
  color: #2e5053;
  letter-spacing: -.14px;
  white-space: nowrap;
  height: 20px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.6px;
  position: absolute;
  top: 63px;
  left: 0;
}

.consulting .img-wrapper {
  background-color: #d9d9d9;
  border: 1.83px solid #b6b6b6;
  border-radius: 30.45px;
  width: 61px;
  height: 61px;
  position: absolute;
  top: 0;
  left: 12px;
}

.consulting .group-26 {
  width: 24px;
  height: 30px;
  position: absolute;
  top: 13px;
  left: 16px;
}

.consulting .frame-92 {
  width: 81px;
  height: 96px;
  position: absolute;
  top: 520px;
  left: 57px;
}

.consulting .delivery-team-2 {
  color: #2e5053;
  letter-spacing: -.14px;
  text-align: center;
  width: 115px;
  height: 60px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.6px;
  position: absolute;
  top: 63px;
  left: -17px;
}

.consulting .img-2 {
  width: 28px;
  height: 28px;
  position: absolute;
  top: 14px;
  left: 15px;
}

.consulting .frame-93 {
  width: 81px;
  height: 96px;
  position: absolute;
  top: 659px;
  left: 57px;
}

.consulting .text-wrapper-67 {
  color: #2e5053;
  letter-spacing: -.14px;
  text-align: center;
  width: 103px;
  height: 60px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.6px;
  position: absolute;
  top: 64px;
  left: -10px;
}

.consulting .frame-94 {
  width: 81px;
  height: 96px;
  position: absolute;
  top: 195px;
  left: 119px;
}

.consulting .text-wrapper-68 {
  color: #2e5053;
  letter-spacing: -.14px;
  white-space: nowrap;
  height: 20px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.6px;
  position: absolute;
  top: 63px;
  left: 10px;
}

.consulting .frame-95 {
  width: 43px;
  height: 22px;
  position: absolute;
  top: 215px;
  left: 203px;
}

.consulting .frame-96 {
  width: 81px;
  height: 96px;
  position: absolute;
  top: 520px;
  left: 188px;
}

.consulting .evaluation-3 {
  color: #2e5053;
  letter-spacing: -.14px;
  white-space: nowrap;
  height: 20px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.6px;
  position: absolute;
  top: 63px;
  left: 8px;
}

.consulting .frame-97 {
  width: 81px;
  height: 96px;
  position: absolute;
  top: 659px;
  left: 188px;
}

.consulting .overlap-18 {
  width: 120px;
  height: 101px;
  position: relative;
  left: -18px;
}

.consulting .candidate-selection-2 {
  color: #2e5053;
  letter-spacing: -.14px;
  text-align: center;
  height: 40px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.6px;
  position: absolute;
  top: 61px;
  left: 0;
}

.consulting .ellipse-20 {
  background-color: #d9d9d9;
  border: 1.83px solid #b6b6b6;
  border-radius: 30.45px;
  width: 61px;
  height: 61px;
  position: absolute;
  top: 0;
  left: 30px;
}

.consulting .verified-account-2 {
  width: 28px;
  height: 28px;
  position: absolute;
  top: 15px;
  left: 46px;
}

.consulting .frame-98 {
  width: 81px;
  height: 96px;
  position: absolute;
  top: 195px;
  left: 238px;
}

.consulting .text-wrapper-69 {
  color: #2e5053;
  letter-spacing: -.14px;
  text-align: center;
  width: 92px;
  height: 40px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.6px;
  position: absolute;
  top: 63px;
  left: 0;
}

.consulting .premier-lounge-offer-wrapper {
  background-color: #2e5053;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 183px;
  padding: 9px 29px;
  display: flex;
  position: absolute;
  top: 365px;
  left: 30px;
  overflow: hidden;
}

.consulting .premier-lounge-offer-2 {
  color: #fff;
  letter-spacing: -.16px;
  text-align: center;
  width: 173px;
  margin-top: -1px;
  margin-left: -24px;
  margin-right: -24px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 22.4px;
  position: relative;
}

.consulting .frame-99 {
  background-color: #2e5053;
  border-radius: 4px;
  width: 228px;
  height: 183px;
  position: absolute;
  top: 4874px;
  left: 73px;
}

.consulting .overlap-19 {
  width: 150px;
  height: 142px;
  position: absolute;
  top: -1px;
  left: 31px;
}

.consulting .text-wrapper-70 {
  color: #fff;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: 123px;
  height: 20px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: absolute;
  top: 141px;
  left: 39px;
}

.rpo {
  background-color: #fff;
  flex-direction: row;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.rpo .div-2 {
  background-color: #fff;
  position: relative;
}

.rpo .header {
  width: 390px !important;
  height: 60px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.rpo .overlap-wrapper {
  width: 390px;
  height: 442px;
  position: absolute;
  top: 60px;
  left: 0;
  overflow: hidden;
}

.rpo .overlap {
  width: 1456px;
  height: 674px;
  position: relative;
}

.rpo .frame-4 {
  background-color: var(--neutral-10);
  width: 1456px;
  height: 674px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.rpo .overlap-2 {
  width: 1454px;
  height: 1178px;
  position: absolute;
  top: 0;
  left: 0;
}

.rpo .overlap-group-2 {
  width: 1435px;
  height: 1003px;
  position: absolute;
  top: 175px;
  left: 19px;
}

.rpo .ellipse-3 {
  border: 2px solid;
  border-color: var(--neutral-40);
  border-radius: 261px;
  width: 522px;
  height: 522px;
  position: absolute;
  top: 14px;
  left: 816px;
}

.rpo .ellipse-4 {
  background-color: #fff;
  border-radius: 233px;
  width: 466px;
  height: 466px;
  position: absolute;
  top: 42px;
  left: 844px;
}

.rpo .rectangle-2 {
  mix-blend-mode: multiply;
  background: linear-gradient(#000 0%, #d9d9d900 100%);
  width: 639px;
  height: 298px;
  position: absolute;
  top: 0;
  left: 625px;
}

.rpo .rectangle-3 {
  background-color: #d9d9d9;
  width: 1435px;
  height: 498px;
  position: absolute;
  top: 505px;
  left: 0;
}

.rpo .friendly-partners {
  object-fit: cover;
  width: 390px;
  height: 442px;
  position: absolute;
  top: 0;
  left: 0;
}

.rpo .ellipse-5 {
  object-fit: cover;
  width: 436px;
  height: 436px;
  position: absolute;
  top: -792px;
  left: 4687px;
}

.rpo .frame-5 {
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 358px;
  padding: 0 54px;
  display: flex;
  position: absolute;
  top: 86px;
  left: 16px;
}

.rpo .hire-the-right {
  color: #0000;
  letter-spacing: -.8px;
  text-align: center;
  width: 304px;
  margin-top: -1px;
  margin-left: -27px;
  margin-right: -27px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 600;
  line-height: 38.4px;
  position: relative;
}

.rpo .span {
  color: #fff;
}

.rpo .text-wrapper-5 {
  color: #00dfc2;
}

.rpo .text-wrapper-6 {
  color: var(--neutral-0);
  letter-spacing: -.16px;
  text-align: center;
  width: 282px;
  margin-left: -16px;
  margin-right: -16px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  position: relative;
}

.rpo .CTA-instance {
  flex: none !important;
}

.rpo .footer {
  background-color: var(--white);
  width: 390px;
  height: 860px;
  position: absolute;
  top: 4293px;
  left: 0;
  overflow: hidden;
}

.rpo .overlap-3 {
  width: 390px;
  height: 907px;
  position: relative;
}

.rpo .frame-6 {
  background-color: var(--neutral-90);
  width: 390px;
  height: 907px;
  position: absolute;
  top: 0;
  left: 0;
}

.rpo .frame-7 {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: absolute;
  top: 115px;
  left: 26px;
}

.rpo .text-wrapper-7 {
  color: #fff;
  font-family: var(--paragraph-ALCPS-font-family);
  font-size: var(--paragraph-ALCPS-font-size);
  font-style: var(--paragraph-ALCPS-font-style);
  font-weight: var(--paragraph-ALCPS-font-weight);
  letter-spacing: var(--paragraph-ALCPS-letter-spacing);
  line-height: var(--paragraph-ALCPS-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.rpo .text-wrapper-8 {
  color: #f6f7f8;
  font-family: var(--paragraph-REG-font-family);
  font-size: var(--paragraph-REG-font-size);
  font-style: var(--paragraph-REG-font-style);
  font-weight: var(--paragraph-REG-font-weight);
  letter-spacing: var(--paragraph-REG-letter-spacing);
  line-height: var(--paragraph-REG-line-height);
  width: 324px;
  position: relative;
}

.rpo .DIV {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 113px;
  display: flex;
  position: absolute;
  top: 272px;
  left: 195px;
}

.rpo .text-wrapper-9 {
  color: var(--white);
  letter-spacing: -.2px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.rpo .text-wrapper-10 {
  color: var(--white);
  letter-spacing: -.2px;
  white-space: nowrap;
  width: fit-content;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.rpo .DIV-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 113px;
  display: flex;
  position: absolute;
  top: 272px;
  left: 26px;
}

.rpo .frame-8 {
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 583px;
  left: 69px;
}

.rpo .group {
  width: 37px;
  height: 52px;
  position: relative;
}

.rpo .text-wrapper-11 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 38px;
  left: 1px;
}

.rpo .face-book {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 0;
  left: 0;
}

.rpo .frame-9 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.rpo .linkedin {
  flex: none;
  position: relative;
}

.rpo .text-wrapper-12 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  width: fit-content;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: relative;
}

.rpo .facebook {
  width: 35px;
  height: 35px;
  position: relative;
}

.rpo .frame-10 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.rpo .logo {
  width: 116px;
  height: 56px;
  position: absolute;
  top: 39px;
  left: 24px;
}

.rpo .frame-wrapper {
  width: 295px;
  height: 64px;
  position: absolute;
  top: 732px;
  left: 48px;
}

.rpo .frame-11 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 295px;
  display: flex;
  position: relative;
}

.rpo .text-wrapper-13 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.rpo .ellipse-6 {
  background-color: var(--grey-01);
  border-radius: 1px / 1.5px;
  width: 2px;
  height: 3px;
  position: relative;
}

.rpo .text-wrapper-14 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  text-align: center;
  align-self: stretch;
  position: relative;
}

.rpo .overlap-4 {
  position: absolute;
}

.rpo .frame-12 {
  position: absolute;
  top: 0;
}

.rpo .frame-13 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  width: 358px;
  display: flex;
  position: absolute;
  top: 40px;
  left: 16px;
}

.rpo .div-wrapper {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 16px;
  width: 358px;
  display: flex;
  position: relative;
}

.rpo .text-wrapper-15 {
  color: #2e5053;
  letter-spacing: -.24px;
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  position: relative;
}

.rpo .overlap-group-wrapper {
  width: 355px;
  height: 261px;
  position: relative;
}

.rpo .overlap-group-3 {
  width: 390px;
  height: 575px;
  position: relative;
  top: -27px;
  left: -16px;
}

.rpo .abstract-geometric {
  width: 389px;
  height: 363px;
  position: absolute;
  top: 20px;
  left: 1px;
}

.rpo .rectangle-4 {
  mix-blend-mode: overlay;
  width: 390px;
  height: 575px;
  position: absolute;
  top: 0;
  left: 0;
}

.rpo .frame-14 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 356px;
  display: flex;
  position: absolute;
  top: 593px;
  left: 17px;
}

.rpo .text-wrapper-16 {
  color: #1f1f1f;
  letter-spacing: -.8px;
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 700;
  line-height: 38.4px;
  position: relative;
}

.rpo .joulestowatts {
  color: #1f1f1f;
  letter-spacing: -.16px;
  text-align: center;
  width: 356px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  position: relative;
}

.rpo .frame-15 {
  flex: none;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  height: 550px;
  display: inline-flex;
  overflow: hidden;
}

.frame-15-scroller {
  flex-direction: column;
  align-items: center;
  width: 1000px;
  display: inline-flex;
}

.frame-15-position {
  width: 390px;
  position: absolute;
  top: 1298px;
  left: 18px;
  overflow: scroll;
}

.rpo .group-wrapper {
  width: 300px;
  height: 550px;
  box-shadow: 0 4px 15px #0000001c;
}

.rpo .group-2 {
  width: 302px;
  height: 550px;
}

.rpo .overlap-group-4 {
  width: 300px;
  height: 550px;
  position: relative;
}

.rpo .rectangle-5 {
  width: 300px;
  height: 196px;
  position: absolute;
  top: 0;
  left: 0;
}

.rpo .frame-16 {
  background-color: #fff;
  border-radius: 0 0 8px 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
  width: 300px;
  padding: 52px 32px;
  display: flex;
  position: absolute;
  top: 72px;
  left: 0;
}

.rpo .frame-17 {
  flex: none;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.rpo .vector {
  width: 16px;
  height: 16px;
  position: relative;
}

.rpo .text-wrapper-17 {
  color: #1f1f1f;
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: -.5px;
  line-height: var(--h6-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.rpo .frame-18 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.rpo .systems-process {
  color: #1f1f1f;
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  width: 206px;
  margin-top: -1px;
  position: relative;
}

.rpo .text-wrapper-18 {
  color: var(--neutral-0);
  letter-spacing: -.2px;
  text-align: center;
  width: 191px;
  height: 44px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 21.6px;
  position: absolute;
  top: 13px;
  left: 54px;
}

.rpo .group-3 {
  width: 300px;
  height: 550px;
  box-shadow: 0 4px 15px #0000001c;
}

.rpo .rectangle-6 {
  background: #297c75;
  border-radius: 8px;
  width: 100%;
  height: 196px;
  position: absolute;
  top: 0;
  left: 0;
}

.rpo .frame-19 {
  background-color: #fff;
  border-radius: 0 0 8px 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
  width: 300px;
  height: 478px;
  padding: 52px 32px;
  display: flex;
  position: absolute;
  top: 72px;
  left: 0;
}

.rpo .frame-20 {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.rpo .partner-s-submission {
  color: #1f1f1f;
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  width: 208px;
  margin-top: -1px;
  position: relative;
}

.rpo .frame-21 {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-right: -15px;
  display: inline-flex;
  position: relative;
}

.rpo .selection-onboarding {
  color: #1f1f1f;
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  width: 227px;
  margin-top: -1px;
  position: relative;
}

.rpo .contractor {
  color: var(--neutral-0);
  letter-spacing: -.2px;
  text-align: center;
  width: 229px;
  height: 44px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 21.6px;
  position: absolute;
  top: 13px;
  left: 36px;
}

.rpo .group-4 {
  width: 300px;
  height: 550px;
  box-shadow: 0 4px 15px #0000001c;
}

.rpo .group-5 {
  width: 302px;
  height: 550px;
  position: relative;
}

.rpo .rectangle-7 {
  width: 300px;
  height: 196px;
  position: absolute;
  top: -2764px;
  left: 3791px;
}

.rpo .vector-2 {
  width: 16px;
  height: 16px;
  margin-top: -2882px;
  margin-right: -2933px;
  position: relative;
}

.rpo .vector-3 {
  width: 16px;
  height: 16px;
  margin-top: -2935px;
  margin-right: -3042px;
  position: relative;
}

.rpo .vector-4 {
  width: 16px;
  height: 16px;
  margin-top: -2988px;
  margin-right: -3032px;
  position: relative;
}

.rpo .vector-5 {
  width: 16px;
  height: 16px;
  margin-top: -3041px;
  margin-right: -2924px;
  position: relative;
}

.rpo .vector-6 {
  width: 16px;
  height: 16px;
  margin-top: -3094px;
  margin-right: -2948px;
  position: relative;
}

.rpo .vector-7 {
  width: 16px;
  height: 16px;
  margin-top: -3133px;
  margin-right: -2909px;
  position: relative;
}

.rpo .vector-8 {
  width: 16px;
  height: 16px;
  margin-top: -3228px;
  margin-right: -2970px;
  position: relative;
}

.rpo .group-6 {
  width: 1456px;
  height: 380px;
  position: absolute;
  top: 249px;
  left: 0;
}

.rpo .overlap-5 {
  height: 380px;
  position: relative;
}

.rpo .frame-22 {
  width: 1456px;
  height: 380px;
  position: absolute;
  top: 0;
  left: 0;
}

.rpo .overlap-group-5 {
  background-color: #4d4d4d;
  width: 1457px;
  height: 380px;
}

.rpo .frame-23 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 32px;
  display: inline-flex;
  position: relative;
  top: 69px;
  left: 152px;
}

.rpo .frame-24 {
  flex-direction: column;
  flex: none;
  align-items: flex-end;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.rpo .text-wrapper-19 {
  color: #fff;
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.rpo .are-we-a-good-fit {
  color: var(--neutral-10);
  letter-spacing: -.24px;
  text-align: right;
  width: 429px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
  position: relative;
}

.rpo .CTA-2 {
  all: unset;
  background-color: var(--secondary-04);
  box-sizing: border-box;
  flex: none;
  align-items: center;
  gap: 10px;
  padding: 12px 16px;
  display: inline-flex;
  position: relative;
}

.rpo .text-wrapper-20 {
  color: #fff;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: relative;
}

.rpo .arrow-forward-3 {
  width: 24px;
  height: 24px;
  position: relative;
}

.rpo .frame-25 {
  width: 728px;
  height: 380px;
  position: absolute;
  top: 0;
  left: 0;
}

.rpo .overlap-6 {
  background-color: var(--neutral-10);
  width: 720px;
  height: 380px;
  position: absolute;
  top: 0;
  left: 0;
}

.rpo .frame-26 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 32px;
  display: inline-flex;
  position: relative;
  top: 49px;
  left: 128px;
}

.rpo .text-wrapper-21 {
  color: var(--secondary-04);
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.rpo .text-wrapper-22 {
  color: var(--neutral-80);
  letter-spacing: -.24px;
  text-align: right;
  width: 500px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
  position: relative;
}

.rpo .frame-27 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 52px;
  left: 817px;
}

.rpo .frame-28 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.rpo .text-wrapper-23 {
  color: #fff;
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.rpo .text-wrapper-24 {
  color: var(--neutral-10);
  letter-spacing: -.24px;
  width: 429px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
  position: relative;
}

.rpo .CTA-3 {
  cursor: pointer;
  background-color: var(--secondary-02);
  box-sizing: border-box;
  border: none;
  outline: none;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  display: inline-flex;
  position: absolute;
  top: 259px;
  left: 817px;
}

.rpo .text-wrapper-25 {
  color: var(--neutral-0);
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.rpo .frame-29 {
  flex-direction: column;
  align-items: center;
  gap: 25px;
  display: inline-flex;
  position: absolute;
  top: 28px;
  left: 148px;
}

.rpo .frame-30 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 51px;
  margin-left: 58px;
  display: inline-flex;
  position: relative;
}

.rpo .frame-31 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 26px;
  display: inline-flex;
  position: relative;
}

.rpo .frame-32 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.rpo .text-wrapper-26 {
  color: var(--neutral-60);
  letter-spacing: -.2px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  position: relative;
}

.rpo .frame-33 {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 29px;
  display: inline-flex;
  position: relative;
}

.rpo .mercedes-benz-logo {
  object-fit: cover;
  width: 248px;
  height: 63px;
  position: relative;
}

.rpo .GE-healthcare-logo {
  object-fit: cover;
  width: 164px;
  height: 92px;
  position: relative;
}

.rpo .sony-logo {
  object-fit: cover;
  width: 123px;
  height: 22px;
  position: relative;
}

.rpo .shell-logo {
  object-fit: cover;
  width: 115px;
  height: 65px;
  position: relative;
}

.rpo .amazon {
  object-fit: cover;
  width: 178px;
  height: 55px;
  position: relative;
}

.rpo .wipro-logo-new-og {
  object-fit: cover;
  width: 171px;
  height: 89px;
  position: relative;
}

.rpo .frame-34 {
  flex-direction: column;
  align-items: flex-start;
  gap: 44px;
  width: 357px;
  display: flex;
  position: absolute;
  top: 780px;
  left: 16px;
}

.rpo .frame-35 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 32px;
  width: 100%;
  display: flex;
  position: relative;
}

.rpo .frame-36 {
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 178px;
  display: flex;
  position: relative;
}

.rpo .group-7 {
  width: 50.69px;
  height: 59px;
  position: relative;
}

.rpo .text-wrapper-27 {
  color: #000;
  letter-spacing: -.2px;
  text-align: center;
  width: 172px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  position: relative;
}

.rpo .frame-37 {
  flex-direction: column;
  flex: 1;
  align-items: center;
  gap: 12px;
  display: flex;
  position: relative;
}

.rpo .group-8 {
  width: 49.39px;
  height: 56.22px;
  position: relative;
}

.rpo .text-wrapper-28 {
  color: #000;
  letter-spacing: -.2px;
  text-align: center;
  width: 152px;
  margin-left: -2.5px;
  margin-right: -2.5px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  position: relative;
}

.rpo .group-9 {
  width: 57px;
  height: 50px;
  position: relative;
}

.rpo .text-wrapper-29 {
  color: #000;
  letter-spacing: -.2px;
  text-align: center;
  width: 91px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  position: relative;
}

.rpo .group-10 {
  width: 52.35px;
  height: 50.18px;
  position: relative;
}

.rpo .text-wrapper-30 {
  color: #000;
  letter-spacing: -.2px;
  white-space: nowrap;
  width: fit-content;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  position: relative;
}

.rpo .frame-38 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 41px;
  width: 100%;
  display: flex;
  position: relative;
}

.rpo .group-11 {
  width: 65.89px;
  height: 55.15px;
  position: relative;
}

.rpo .group-12 {
  width: 58px;
  height: 56.87px;
  margin-top: -.5px;
  position: relative;
}

.rpo .text-wrapper-31 {
  color: #000;
  letter-spacing: -.2px;
  text-align: center;
  width: 177px;
  margin-left: -9.5px;
  margin-right: -9.5px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  position: relative;
}

.rpo .carousel-2 {
  width: 390px;
  height: 796px;
  position: absolute;
  top: 2447px;
  left: 0;
  overflow: hidden;
}

.rpo .frame-39 {
  width: 667px;
  height: 1425px;
  position: absolute;
  top: 0;
  left: 0;
}

.rpo .overlap-group-6 {
  width: 687px;
  height: 923px;
  position: relative;
  top: -24px;
  left: -134px;
}

.rpo .rectangle-8 {
  width: 390px;
  height: 485px;
  position: absolute;
  top: 24px;
  left: 134px;
}

.rpo .frame-40 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  height: 261px;
  display: inline-flex;
  position: absolute;
  top: 166px;
  left: 214px;
}

.rpo .shutterstock {
  object-fit: cover;
  width: 390px;
  height: 441px;
  position: absolute;
  top: 379px;
  left: 134px;
}

.rpo .rectangle-9 {
  background: linear-gradient(90deg, #589993 5.95%, #4d9f98 20.62% 48.33%, #fcfcfc00 71.42%);
  width: 923px;
  height: 687px;
  position: absolute;
  top: 118px;
  left: -118px;
  transform: rotate(90deg);
}

.rpo .frame-41 {
  flex-direction: column;
  align-items: flex-start;
  gap: 33px;
  display: inline-flex;
  position: absolute;
  top: 106px;
  left: 150px;
}

.rpo .we-are-your-growth {
  color: #fff;
  letter-spacing: -.22px;
  width: 358px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 22px;
  font-weight: 600;
  line-height: 30.8px;
  position: relative;
}

.rpo .instance-node {
  border: unset !important;
  border-color: unset !important;
  background-color: #2e5053 !important;
  flex: none !important;
  justify-content: center !important;
  align-items: flex-end !important;
  width: 127px !important;
  display: flex !important;
}

.rpo .CTA-4 {
  font-family: var(--subtitle-BLD-font-family) !important;
  font-size: var(--subtitle-BLD-font-size) !important;
  font-style: var(--subtitle-BLD-font-style) !important;
  font-weight: var(--subtitle-BLD-font-weight) !important;
  letter-spacing: var(--subtitle-BLD-letter-spacing) !important;
  line-height: var(--subtitle-BLD-line-height) !important;
  margin-left: -2px !important;
}

.rpo .CTA-5 {
  margin-right: -2px !important;
}

.rpo .frame-42 {
  width: 1440px;
  height: 580px;
  position: absolute;
  top: 0;
  left: 1440px;
  overflow: hidden;
}

.rpo .rectangle-10 {
  width: 1383px;
  height: 634px;
  position: absolute;
  top: -3438px;
  left: 2716px;
}

.rpo .overlap-7 {
  filter: blur(80px);
  background: linear-gradient(#000 0%, #071103 25%, #202020 44.79%, #2f2f2f 61.46%, #0000 100%);
  width: 1684px;
  height: 714px;
  position: absolute;
  top: -60px;
  left: -116px;
}

.rpo .frame-43 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: absolute;
  top: 242px;
  left: 196px;
}

.rpo .text-wrapper-32 {
  color: var(--neutral-0);
  letter-spacing: -.53px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 600;
  line-height: 49px;
  position: relative;
}

.rpo .our-client-and {
  color: #f6f7f8;
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  width: 436px;
  margin-top: -1px;
  position: relative;
}

.rpo .CTA-6 {
  all: unset;
  box-sizing: border-box;
  flex: none;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.rpo .text-wrapper-33 {
  color: var(--neutral-0);
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 23.8px;
  position: relative;
}

.rpo .arrow-forward-4 {
  width: 24px;
  height: 24px;
  margin-top: -3810px;
  margin-right: -2289px;
  position: relative;
}

.rpo .text-wrapper-34 {
  color: var(--neutral-0);
  font-family: var(--h4-REG-font-family);
  font-size: var(--h4-REG-font-size);
  font-style: var(--h4-REG-font-style);
  font-weight: var(--h4-REG-font-weight);
  letter-spacing: var(--h4-REG-letter-spacing);
  line-height: var(--h4-REG-line-height);
  white-space: nowrap;
  height: 41px;
  position: absolute;
  top: 120px;
  left: 196px;
}

.rpo .frame-44 {
  align-items: flex-start;
  gap: 5px;
  display: inline-flex;
  position: absolute;
  top: 560px;
  left: 196px;
}

.rpo .ellipse-7 {
  background-color: #d9d9d952;
  border-radius: 5.5px;
  width: 11px;
  height: 11px;
  position: relative;
}

.rpo .ellipse-8 {
  background-color: var(--neutral-30);
  border-radius: 5.5px;
  width: 11px;
  height: 11px;
  position: relative;
}

.rpo .carousel-03 {
  position: absolute !important;
  top: 0 !important;
  left: 2880px !important;
}

.rpo .carousel-instance {
  margin-top: -3833px !important;
  margin-right: -849px !important;
}

.rpo .overlap-8 {
  position: absolute;
}

.rpo .rectangle-11 {
  width: 1440px;
  height: 1910px;
  position: absolute;
  top: 729px;
  left: 0;
}

.rpo .text-wrapper-35 {
  color: #000;
  letter-spacing: -.24px;
  white-space: nowrap;
  height: 34px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  position: absolute;
  top: 1446px;
  left: 623px;
}

.rpo .rectangle-12 {
  width: 592px;
  height: 827px;
  position: absolute;
  top: 1630px;
  left: 0;
}

.rpo .text-wrapper-36 {
  color: #000;
  letter-spacing: -.24px;
  white-space: nowrap;
  height: 34px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  position: absolute;
  top: 1446px;
  left: 1086px;
}

.rpo .text-wrapper-37 {
  color: #000;
  letter-spacing: -.24px;
  white-space: nowrap;
  height: 34px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  position: absolute;
  top: 1643px;
  left: 210px;
}

.rpo .text-wrapper-38 {
  color: #000;
  letter-spacing: -.24px;
  white-space: nowrap;
  height: 34px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  position: absolute;
  top: 1643px;
  left: 683px;
}

.rpo .text-wrapper-39 {
  color: #000;
  letter-spacing: -.24px;
  white-space: nowrap;
  height: 34px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  position: absolute;
  top: 1643px;
  left: 992px;
}

.rpo .text-wrapper-40 {
  color: #2e5053;
  letter-spacing: -.53px;
  width: 604px;
  height: 196px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 600;
  line-height: 49px;
  position: absolute;
  top: 815px;
  left: 80px;
}

.rpo .frame-45 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 1154px;
  left: 407px;
}

.rpo .text-wrapper-41 {
  color: #1f1f1f;
  letter-spacing: -1.05px;
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 42px;
  font-weight: 700;
  line-height: 50.4px;
  position: relative;
}

.rpo .joulestowatts-2 {
  color: #1f1f1f;
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  text-align: center;
  width: 615px;
  position: relative;
}

.rpo .abstract-geometric-2 {
  width: 455px;
  height: 421px;
  position: absolute;
  top: 741px;
  left: 845px;
}

.rpo .rectangle-13 {
  mix-blend-mode: overlay;
  width: 570px;
  height: 631px;
  position: absolute;
  top: 599px;
  left: 870px;
}

.rpo .group-13 {
  width: 363px;
  height: 602px;
  position: absolute;
  top: 1855px;
  left: 79px;
  box-shadow: 0 4px 15px #0000001c;
}

.rpo .group-14 {
  height: 602px;
}

.rpo .overlap-group-7 {
  width: 363px;
  height: 602px;
  position: relative;
}

.rpo .img-2 {
  width: 363px;
  height: 196px;
  position: absolute;
  top: 0;
  left: 0;
}

.rpo .frame-46 {
  background-image: url("rectangle-110-7.a74601cb.svg");
  background-size: 100% 100%;
  width: 363px;
  height: 539px;
  position: absolute;
  top: 63px;
  left: 0;
}

.rpo .frame-47 {
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 52px;
  left: 30px;
}

.rpo .frame-48 {
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 110px;
  left: 30px;
}

.rpo .frame-49 {
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 168px;
  left: 30px;
}

.rpo .frame-50 {
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 226px;
  left: 30px;
}

.rpo .frame-51 {
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 284px;
  left: 30px;
}

.rpo .frame-52 {
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 342px;
  left: 30px;
}

.rpo .frame-53 {
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 400px;
  left: 30px;
}

.rpo .text-wrapper-42 {
  color: var(--neutral-0);
  letter-spacing: -.2px;
  text-align: center;
  width: 231px;
  height: 44px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 21.6px;
  position: absolute;
  top: 1863px;
  left: 144px;
}

.rpo .group-15 {
  width: 363px;
  height: 602px;
  position: absolute;
  top: 1855px;
  left: 995px;
  box-shadow: 0 4px 15px #0000001c;
}

.rpo .overlap-9 {
  width: 365px;
  height: 602px;
  position: relative;
}

.rpo .group-16 {
  width: 365px;
  height: 196px;
  position: absolute;
  top: 0;
  left: 0;
}

.rpo .overlap-group-8 {
  background-image: url("group-43.f12d60c2.png");
  background-size: 100% 100%;
  width: 363px;
  height: 196px;
  position: relative;
}

.rpo .text-wrapper-43 {
  color: var(--neutral-0);
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  height: 44px;
  position: absolute;
  top: 23px;
  left: 39px;
}

.rpo .rectangle-14 {
  width: 363px;
  height: 539px;
  position: absolute;
  top: 63px;
  left: 0;
}

.rpo .frame-54 {
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 108px;
  left: 30px;
}

.rpo .frame-55 {
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 166px;
  left: 30px;
}

.rpo .frame-56 {
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 224px;
  left: 30px;
}

.rpo .group-17 {
  width: 365px;
  height: 602px;
  position: absolute;
  top: 1855px;
  left: 537px;
  box-shadow: 0 0 15px #0000001c;
}

.rpo .contractor-2 {
  color: var(--neutral-0);
  letter-spacing: -.2px;
  text-align: center;
  width: 231px;
  height: 44px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 21.6px;
  position: absolute;
  top: 8px;
  left: 70px;
}

.rpo .frame-57 {
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 109px;
  left: 30px;
}

.rpo .frame-58 {
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 167px;
  left: 30px;
}

.rpo .frame-59 {
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 225px;
  left: 30px;
}

.rpo .frame-60 {
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 283px;
  left: 30px;
}

.rpo .frame-61 {
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 341px;
  left: 30px;
}

.rpo .frame-62 {
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 399px;
  left: 30px;
}

.rpo .frame-63 {
  width: 742px;
  height: 307px;
  position: absolute;
  top: 0;
  left: 0;
}

.rpo .overlap-group-9 {
  height: 307px;
  position: relative;
}

.rpo .rectangle-15 {
  background-color: #f6f7f8;
  width: 390px;
  height: 307px;
  position: absolute;
  top: 0;
  left: 185px;
}

.rpo .frame-64 {
  flex-direction: column;
  align-items: center;
  gap: 25px;
  display: inline-flex;
  position: absolute;
  top: 76px;
  left: 0;
}

.rpo .mercedes-benz-logo-2 {
  object-fit: cover;
  width: 148px;
  height: 37px;
  position: relative;
}

.rpo .GE-healthcare-logo-2 {
  object-fit: cover;
  width: 98px;
  height: 55px;
  position: relative;
}

.rpo .sony-logo-2 {
  object-fit: cover;
  width: 74px;
  height: 13px;
  position: relative;
}

.rpo .shell-logo-2 {
  object-fit: cover;
  width: 69px;
  height: 39px;
  position: relative;
}

.rpo .amazon-2 {
  object-fit: cover;
  width: 70px;
  height: 33px;
  position: relative;
}

.rpo .wipro-logo-new-og-2 {
  object-fit: cover;
  width: 102px;
  height: 53px;
  position: relative;
}

.rpo .frame-65 {
  flex-direction: column;
  align-items: center;
  display: inline-flex;
  position: absolute;
}

.rpo .frame-66 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-end;
  gap: 32px;
  width: 328px;
  display: flex;
  position: relative;
}

.rpo .frame-67 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 24px;
  width: 328px;
  display: flex;
  position: relative;
}

.rpo .text-wrapper-44 {
  color: var(--secondary-04);
  letter-spacing: -.8px;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.4px;
  position: relative;
}

.rpo .text-wrapper-45 {
  color: var(--neutral-80);
  letter-spacing: -.24px;
  text-align: center;
  width: 297px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
  position: relative;
}

.rpo .group-18 {
  width: 390px;
  height: 360px;
  position: relative;
}

.rpo .frame-68 {
  height: 360px;
}

.rpo .overlap-group-10 {
  background-color: var(--neutral-70);
  width: 1440px;
  height: 360px;
}

.rpo .frame-69 {
  flex-direction: column;
  align-items: center;
  gap: 24px;
  display: inline-flex;
  position: relative;
  top: 85px;
  left: 41px;
}

.rpo .text-wrapper-46 {
  color: var(--neutral-0);
  letter-spacing: -.8px;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.4px;
  position: relative;
}

.rpo .are-we-a-good-fit-2 {
  color: #f6f7f8;
  letter-spacing: -.2px;
  text-align: center;
  width: 309px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.rpo .CTA-7 {
  cursor: pointer;
  background-color: var(--secondary-02);
  box-sizing: border-box;
  border: none;
  outline: none;
  flex: none;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  display: inline-flex;
  position: relative;
}

.rpo .group-19 {
  width: 58px;
  height: 67.51px;
  position: relative;
}

.rpo .text-wrapper-47 {
  color: #000;
  letter-spacing: -.24px;
  white-space: nowrap;
  width: fit-content;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  position: relative;
}

.rpo .group-20 {
  width: 66px;
  height: 45px;
  position: absolute;
  top: 1575px;
  left: 700px;
}

.rpo .group-21 {
  width: 58px;
  height: 48px;
  position: absolute;
  top: 1574px;
  left: 1124px;
}

.rpo .group-22 {
  width: 52px;
  height: 39px;
  position: absolute;
  top: 1585px;
  left: 224px;
}

.rpo .group-23 {
  width: 49px;
  height: 56px;
  position: absolute;
  top: 1336px;
  left: 708px;
}

.rpo .group-24 {
  width: 57px;
  height: 43px;
  position: absolute;
  top: 1343px;
  left: 1124px;
}

.rpo .friendly-partners-2 {
  width: 1440px;
  height: 633px;
  position: absolute;
  top: 96px;
  left: 0;
}

.rpo .frame-70 {
  flex-direction: column;
  align-items: flex-start;
  gap: 33px;
  display: inline-flex;
  position: absolute;
  top: 243px;
  left: 80px;
}

.rpo .hire-the-right-2 {
  color: #0000;
  letter-spacing: -1.8px;
  width: 751px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 60px;
  font-weight: 600;
  line-height: 60.9px;
  position: relative;
}

.rpo .text-wrapper-48 {
  color: #fff;
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  width: 540px;
  position: relative;
}

.rpo .CTA-8 {
  border: unset !important;
  border-color: unset !important;
  background-color: #4d9f98 !important;
  flex: none !important;
  justify-content: center !important;
  align-items: flex-end !important;
  width: 182px !important;
  display: flex !important;
}

.rpo .CTA-9 {
  font-family: var(--subtitle-BLD-font-family) !important;
  font-size: var(--subtitle-BLD-font-size) !important;
  font-style: var(--subtitle-BLD-font-style) !important;
  font-weight: var(--subtitle-BLD-font-weight) !important;
  letter-spacing: var(--subtitle-BLD-letter-spacing) !important;
  line-height: var(--subtitle-BLD-line-height) !important;
  margin-left: -1px !important;
}

.rpo .CTA-10 {
  margin-right: -1px !important;
}

.rpo .header-2 {
  background-color: #0000;
  justify-content: space-between;
  align-items: center;
  width: 1440px;
  padding: 16px 80px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.rpo .logo-2 {
  object-fit: cover;
  width: 145px;
  height: 67.67px;
  position: relative;
}

.rpo .holder {
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.rpo .frame-71 {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 90.33px;
  padding: 10px 8px;
  display: flex;
  position: relative;
}

.rpo .text-wrapper-49 {
  color: #000;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: relative;
}

.rpo .text-wrapper-50 {
  color: #000;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-left: -2.83px;
  margin-right: -2.83px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: relative;
}

.rpo .frame-72 {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 80px;
  padding: 10px 8px;
  display: flex;
  position: relative;
}

.rpo .CTA-11 {
  border-color: var(--primary-02) !important;
  width: 179px !important;
  height: 69px !important;
  display: flex !important;
}

.rpo .CTA-12 {
  color: #000 !important;
  margin-top: unset !important;
}

.rpo .footer-2 {
  background-color: var(--white);
  width: 1441px;
  height: 292px;
  position: absolute;
  top: 3261px;
  left: 0;
}

.rpo .overlap-10 {
  width: 1441px;
  height: 292px;
  position: relative;
}

.rpo .frame-73 {
  background-color: var(--neutral-90);
  width: 1441px;
  height: 292px;
  position: absolute;
  top: 0;
  left: 0;
}

.rpo .DIV-3 {
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 53px;
  left: 738px;
}

.rpo .text-wrapper-51 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1233px;
}

.rpo .text-wrapper-52 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1282px;
}

.rpo .text-wrapper-53 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1331px;
}

.rpo .text-wrapper-54 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1187px;
}

.rpo .text-wrapper-55 {
  color: var(--white);
  letter-spacing: -.2px;
  white-space: nowrap;
  height: 28px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: absolute;
  top: 100px;
  left: 738px;
}

.rpo .text-wrapper-56 {
  color: var(--white);
  letter-spacing: -.2px;
  white-space: nowrap;
  height: 28px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: absolute;
  top: 100px;
  left: 869px;
}

.rpo .frame-74 {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: absolute;
  top: 115px;
  left: 81px;
}

.rpo .group-25 {
  width: 37px;
  height: 52px;
  position: absolute;
  top: 152px;
  left: 1140px;
}

.rpo .linkedin-2 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1187px;
}

.rpo .facebook-2 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1234px;
}

.rpo .facebook-3 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1281px;
}

.rpo .facebook-4 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1328px;
}

.rpo .group-26 {
  width: 400px;
  height: 17px;
  position: absolute;
  top: 242px;
  left: 501px;
}

.rpo .frame-75 {
  justify-content: center;
  align-items: center;
  gap: 5px;
  display: inline-flex;
  position: relative;
}

.rpo .text-wrapper-57 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.rpo .ellipse-9 {
  background-color: var(--grey-01);
  border-radius: 1.5px;
  width: 3px;
  height: 3px;
  position: relative;
}

.rpo .logo-3 {
  width: 116px;
  height: 56px;
  position: absolute;
  top: 39px;
  left: 76px;
}

.carousel-wrapper .with-efficient-and {
  color: #f6f7f8;
  letter-spacing: -.17px;
  width: 461px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 400;
  line-height: 23.8px;
  position: relative;
}

.carousel-wrapper .text-wrapper-5 {
  color: var(--neutral-0);
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 23.8px;
  position: relative;
}

.carousel-wrapper .arrow-forward-3 {
  width: 24px;
  height: 24px;
  position: relative;
}

.carousel-wrapper .text-wrapper-6 {
  color: var(--neutral-0);
  font-family: var(--h4-REG-font-family);
  font-size: var(--h4-REG-font-size);
  font-style: var(--h4-REG-font-style);
  font-weight: var(--h4-REG-font-weight);
  letter-spacing: var(--h4-REG-letter-spacing);
  line-height: var(--h4-REG-line-height);
  white-space: nowrap;
  height: 41px;
  position: absolute;
  top: 118px;
  left: 243px;
}

.CTA {
  all: unset;
  box-sizing: border-box;
  background-color: #4d9f98;
  border-radius: 56px;
  justify-content: center;
  align-items: flex-end;
  gap: 10px;
  width: 182px;
  padding: 12px 16px;
  transition: all .3s ease-in-out;
  display: flex;
  position: relative;
}

.CTA:hover {
  gap: 8px;
}

.property-default-wrapper .this-is-who-we-are {
  color: #fff;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: relative;
}

.property-default-wrapper.default {
  border: 1px solid #fff;
  gap: 10px;
  transition: all .3s ease-in-out;
}

.property-default-wrapper.default:hover {
  gap: 8px;
}

.state-default-wrapper .text-wrapper-7 {
  color: var(--secondary-03);
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: relative;
}

.state-default-wrapper:hover .text-wrapper-7 {
  color: #fff;
}

.administrative {
  background-color: #fff;
  flex-direction: row;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.administrative .carousel {
  width: 100%;
  height: auto;
}

.administrative .div-2 {
  background-color: #fff;
  position: relative;
}

.administrative .overlap-wrapper {
  width: 390px;
  height: 536px;
  position: absolute;
  top: 60px;
  left: 0;
  overflow: hidden;
}

.administrative .overlap {
  width: 1456px;
  height: 674px;
  position: relative;
}

.administrative .frame-7 {
  background-color: var(--neutral-10);
  width: 1456px;
  height: 674px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.administrative .overlap-2 {
  width: 1706px;
  height: 1182px;
  position: absolute;
  top: -4px;
  left: -252px;
}

.administrative .overlap-group-3 {
  width: 1435px;
  height: 1003px;
  position: absolute;
  top: 179px;
  left: 271px;
}

.administrative .ellipse-5 {
  border: 2px solid;
  border-color: var(--neutral-40);
  border-radius: 261px;
  width: 522px;
  height: 522px;
  position: absolute;
  top: 14px;
  left: 816px;
}

.administrative .ellipse-6 {
  background-color: #fff;
  border-radius: 233px;
  width: 466px;
  height: 466px;
  position: absolute;
  top: 42px;
  left: 844px;
}

.administrative .rectangle-4 {
  mix-blend-mode: multiply;
  background: linear-gradient(#000 0%, #d9d9d900 100%);
  width: 639px;
  height: 298px;
  position: absolute;
  top: 0;
  left: 625px;
}

.administrative .rectangle-5 {
  background-color: #d9d9d9;
  width: 1435px;
  height: 498px;
  position: absolute;
  top: 505px;
  left: 0;
}

.administrative .overlap-3 {
  width: 695px;
  height: 540px;
  position: absolute;
  top: 0;
  left: 0;
}

.administrative .two-collegues {
  object-fit: cover;
  width: 390px;
  height: 536px;
  position: absolute;
  top: 4px;
  left: 252px;
}

.administrative .friendly-partners {
  background: linear-gradient(#2c3e47 0%, #2c3e4700 100%);
  width: 695px;
  height: 540px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(180deg);
}

.administrative .ellipse-7 {
  object-fit: cover;
  width: 436px;
  height: 436px;
  position: absolute;
  top: -1413px;
  left: 3072px;
}

.administrative .frame-8 {
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 358px;
  padding: 0 54px;
  display: flex;
  position: absolute;
  top: 86px;
  left: 16px;
}

.administrative .standardise-your {
  color: #0000;
  letter-spacing: -.8px;
  text-align: center;
  width: 304px;
  margin-top: -1px;
  margin-left: -27px;
  margin-right: -27px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 600;
  line-height: 38.4px;
  position: relative;
}

.administrative .span {
  color: #fff;
}

.administrative .text-wrapper-8 {
  color: #00dfc2;
}

.administrative .text-wrapper-9 {
  color: var(--neutral-0);
  letter-spacing: -.16px;
  text-align: center;
  width: 282px;
  margin-left: -16px;
  margin-right: -16px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  position: relative;
}

.administrative .CTA-instance {
  flex: none !important;
}

.administrative .frame-wrapper {
  width: 295px;
  height: 64px;
  position: absolute;
  top: 732px;
  left: 48px;
}

.administrative .frame-14 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 295px;
  display: flex;
  position: relative;
}

.administrative .text-wrapper-16 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.administrative .ellipse-8 {
  background-color: var(--grey-01);
  border-radius: 1px / 1.5px;
  width: 2px;
  height: 3px;
  position: relative;
}

.administrative .text-wrapper-17 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  text-align: center;
  align-self: stretch;
  position: relative;
}

.administrative .overlap-5 {
  position: absolute;
}

.administrative .frame-15 {
  background-color: #f6f7f8;
  position: absolute;
}

.administrative .frame-16 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 39px;
  left: 35px;
}

.administrative .div-wrapper {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.administrative .text-wrapper-18 {
  color: #294b51;
  letter-spacing: -.8px;
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 600;
  line-height: 38.4px;
  position: relative;
}

.administrative .frame-17 {
  flex-wrap: wrap;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 48px 32px;
  width: 326px;
  display: flex;
  position: relative;
}

.administrative .text-wrapper-19 {
  -webkit-text-stroke: .5px #2e5053;
  color: #2e5053;
  letter-spacing: -.75px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1.5px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 50px;
  font-weight: 400;
  line-height: 60px;
  position: relative;
}

.administrative .text-wrapper-20 {
  color: var(--neutral-70);
  letter-spacing: -.17px;
  text-align: center;
  width: fit-content;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 400;
  line-height: 23.8px;
  position: relative;
}

.administrative .frame-18 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.administrative .vector {
  width: 44px;
  height: 52px;
  margin-top: -.5px;
  position: relative;
}

.administrative .text-wrapper-21 {
  color: var(--neutral-70);
  letter-spacing: -.17px;
  width: 109px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 400;
  line-height: 23.8px;
  position: relative;
}

.administrative .group-2 {
  width: 73.35px;
  height: 52.98px;
  margin-top: -.5px;
  position: relative;
}

.administrative .text-wrapper-22 {
  color: var(--neutral-70);
  letter-spacing: -.17px;
  text-align: center;
  width: 125px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 400;
  line-height: 23.8px;
  position: relative;
}

.administrative .vector-2 {
  width: 49px;
  height: 56px;
  margin-top: -.5px;
  position: relative;
}

.administrative .text-wrapper-23 {
  color: var(--neutral-70);
  letter-spacing: -.17px;
  width: 123px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 400;
  line-height: 23.8px;
  position: relative;
}

.administrative .frame-19 {
  flex-direction: column;
  flex: 1;
  align-items: center;
  display: flex;
  position: relative;
}

.administrative .text-wrapper-24 {
  color: var(--neutral-70);
  letter-spacing: -.17px;
  text-align: center;
  width: 68px;
  height: 55px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 400;
  line-height: 23.8px;
  position: relative;
}

.administrative .rectangle-6 {
  background-color: #2c3e47;
  width: 393px;
  height: 951px;
  position: absolute;
  top: 583px;
  left: 0;
}

.administrative .two-collegues-2, .administrative .rectangle-7 {
  position: absolute;
}

.administrative .frame-20 {
  flex-direction: column;
  align-items: flex-start;
  display: inline-flex;
  position: absolute;
}

.administrative .frame-21 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 16px;
  margin-bottom: 30px;
  display: inline-flex;
  position: relative;
}

.administrative .standardise-your-wrapper {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.administrative .standardise-your-2 {
  color: #0000;
  letter-spacing: -.53px;
  width: 565px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 700;
  line-height: 49px;
  position: relative;
}

.administrative .text-wrapper-25 {
  color: #f6f7f8;
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  width: 492px;
  position: relative;
}

.administrative .instance-node {
  border: unset !important;
  border-color: unset !important;
  background-color: #4d9f98 !important;
  flex: none !important;
  justify-content: center !important;
  align-items: flex-end !important;
  width: 182px !important;
  display: flex !important;
}

.administrative .CTA-3 {
  font-family: var(--subtitle-BLD-font-family) !important;
  font-size: var(--subtitle-BLD-font-size) !important;
  font-style: var(--subtitle-BLD-font-style) !important;
  font-weight: var(--subtitle-BLD-font-weight) !important;
  letter-spacing: var(--subtitle-BLD-letter-spacing) !important;
  line-height: var(--subtitle-BLD-line-height) !important;
  margin-left: -1px !important;
}

.administrative .CTA-4 {
  margin-right: -1px !important;
}

.administrative .frame-22 {
  flex: none;
  align-items: flex-start;
  gap: 48px;
  width: 344px;
  display: flex;
  position: relative;
}

.administrative .frame-23 {
  flex-direction: column;
  flex: 1;
  align-items: center;
  gap: 16px;
  display: flex;
  position: relative;
}

.administrative .img-wrapper {
  background-color: #2e5053;
  border-radius: 16px;
  width: 83.55px;
  height: 82.76px;
  position: relative;
}

.administrative .payroll {
  width: 44px;
  height: 44px;
  position: absolute;
  top: 19px;
  left: 20px;
}

.administrative .text-wrapper-26 {
  white-space: nowrap;
  color: #fff;
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  width: 134px;
  position: relative;
}

.administrative .frame-24 {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 16px;
  display: flex;
  position: relative;
}

.administrative .accounting {
  width: 43px;
  height: 43px;
  position: absolute;
  top: 17px;
  left: 20px;
}

.administrative .text-wrapper-27 {
  color: #fff;
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.administrative .name-tag {
  width: 46px;
  height: 46px;
  position: absolute;
  top: 18px;
  left: 19px;
}

.administrative .text-wrapper-28 {
  color: #fff;
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  text-align: center;
  width: 146px;
  position: relative;
}

.administrative .money-yours {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 21px;
  left: 22px;
}

.administrative .text-wrapper-29 {
  white-space: nowrap;
  color: #fff;
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  width: 120px;
  position: relative;
}

.administrative .file {
  width: 42px;
  height: 39px;
  position: absolute;
  top: 22px;
  left: 21px;
}

.administrative .insurance-agent {
  width: 42px;
  height: 42px;
  position: absolute;
  top: 20px;
  left: 21px;
}

.administrative .text-wrapper-30 {
  color: #fff;
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  text-align: center;
  width: 148px;
  position: relative;
}

.administrative .worker {
  width: 37px;
  height: 37px;
  position: absolute;
  top: 23px;
  left: 23px;
}

.administrative .text-wrapper-31 {
  white-space: nowrap;
  color: #fff;
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  width: 148px;
  position: relative;
}

.administrative .shop {
  width: 38px;
  height: 38px;
  position: absolute;
  top: 22px;
  left: 23px;
}

.administrative .frame-25 {
  width: 390px;
  height: 1188px;
  position: absolute;
  top: 1534px;
  left: 0;
}

.administrative .text-wrapper-32 {
  color: #1f1f1f;
  letter-spacing: -.8px;
  text-align: center;
  width: 371px;
  height: 38px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.4px;
  position: absolute;
  top: 44px;
  left: 10px;
}

.administrative .overlap-group-wrapper {
  width: 278px;
  height: 340px;
  position: absolute;
  top: 104px;
  left: 48px;
}

.administrative .overlap-group-4 {
  width: 296px;
  height: 329px;
  position: relative;
  top: 5px;
  left: 1px;
}

.administrative .vector-3 {
  width: 254px;
  height: 254px;
  position: absolute;
  top: 16px;
  left: 10px;
}

.administrative .group-3 {
  width: 275px;
  height: 329px;
  position: absolute;
  top: 0;
  left: 0;
}

.administrative .text-wrapper-33 {
  color: #4d9f98;
  letter-spacing: -.36px;
  white-space: nowrap;
  width: 142px;
  height: 39px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  position: absolute;
  top: 65px;
  left: 65px;
}

.administrative .online-system-driven {
  color: var(--neutral-60);
  letter-spacing: -.12px;
  width: 174px;
  height: 113px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 12px;
  font-weight: 600;
  line-height: 16.8px;
  position: absolute;
  top: 110px;
  left: 55px;
}

.administrative .frame-26 {
  background-image: url("group-28.8ccfec57.png");
  background-size: 100% 100%;
  width: 160px;
  height: 160px;
  position: absolute;
  top: 169px;
  left: 136px;
}

.administrative .add-user-group-man {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 58px;
  left: 70px;
}

.administrative .vector-4 {
  width: 35px;
  height: 22px;
  position: absolute;
  top: 144px;
  left: -17px;
}

.administrative .frame-27 {
  width: 278px;
  height: 340px;
  position: absolute;
  top: 465px;
  left: 48px;
}

.administrative .text-wrapper-34 {
  color: #4d9f98;
  letter-spacing: -.36px;
  white-space: nowrap;
  height: 34px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  position: absolute;
  top: 70px;
  left: 70px;
}

.administrative .system-driven {
  color: var(--neutral-60);
  letter-spacing: -.12px;
  width: 174px;
  height: 103px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 12px;
  font-weight: 600;
  line-height: 16.8px;
  position: absolute;
  top: 104px;
  left: 51px;
}

.administrative .frame-28 {
  background-image: url("group-30.63c311fa.png");
  background-size: 100% 100%;
  width: 160px;
  height: 160px;
  position: absolute;
  top: 169px;
  left: 136px;
}

.administrative .add-user-group-man-2 {
  width: 34px;
  height: 34px;
  position: absolute;
  top: 58px;
  left: 70px;
}

.administrative .frame-29 {
  width: 278px;
  height: 340px;
  position: absolute;
  top: 826px;
  left: 48px;
}

.administrative .group-4 {
  width: 275px;
  height: 275px;
  position: absolute;
  top: 0;
  left: 0;
}

.administrative .text-wrapper-35 {
  color: #4d9f98;
  letter-spacing: -.36px;
  white-space: nowrap;
  height: 34px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  position: absolute;
  top: 71px;
  left: 78px;
}

.administrative .online-support {
  color: var(--neutral-60);
  letter-spacing: -.12px;
  width: 174px;
  height: 68px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 12px;
  font-weight: 600;
  line-height: 16.8px;
  position: absolute;
  top: 114px;
  left: 59px;
}

.administrative .customer-support {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 61px;
  left: 71px;
}

.administrative .logo-2 {
  object-fit: cover;
  width: 145px;
  height: 67.67px;
  position: relative;
}

.administrative .holder {
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.administrative .frame-30 {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 90.33px;
  padding: 10px 8px;
  display: flex;
  position: relative;
}

.administrative .text-wrapper-36 {
  color: #000;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: relative;
}

.administrative .text-wrapper-37 {
  color: #000;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-left: -2.83px;
  margin-right: -2.83px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: relative;
}

.administrative .frame-31 {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 80px;
  padding: 10px 8px;
  display: flex;
  position: relative;
}

.administrative .CTA-5 {
  border-color: var(--primary-02) !important;
  width: 179px !important;
  height: 69px !important;
  display: flex !important;
}

.administrative .CTA-6 {
  color: #000 !important;
  margin-top: unset !important;
}

.administrative .carousel-2 {
  width: 390px;
  height: 796px;
  position: absolute;
  top: 3327px;
  left: 0;
  overflow: hidden;
}

.administrative .frame-32 {
  width: 667px;
  height: 1425px;
  position: absolute;
  top: 0;
  left: 0;
}

.administrative .overlap-group-5 {
  width: 687px;
  height: 923px;
  position: relative;
  top: -24px;
  left: -134px;
}

.administrative .rectangle-8 {
  width: 390px;
  height: 485px;
  position: absolute;
  top: 24px;
  left: 134px;
}

.administrative .frame-33 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  height: 261px;
  display: inline-flex;
  position: absolute;
  top: 166px;
  left: 214px;
}

.administrative .shutterstock {
  width: 390px;
  height: 401px;
  position: absolute;
  top: 419px;
  left: 134px;
}

.administrative .rectangle-9 {
  filter: blur(40px);
  background: linear-gradient(90deg, #589993 5.95%, #4d9f98 20.62% 48.33%, #fcfcfc00 71.42%);
  width: 923px;
  height: 687px;
  position: absolute;
  top: 118px;
  left: -118px;
  transform: rotate(90deg);
}

.administrative .frame-34 {
  flex-direction: column;
  align-items: flex-start;
  gap: 33px;
  display: inline-flex;
  position: absolute;
  top: 106px;
  left: 150px;
}

.administrative .we-are-your-growth {
  color: #fff;
  letter-spacing: -.22px;
  width: 358px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 22px;
  font-weight: 600;
  line-height: 30.8px;
  position: relative;
}

.administrative .CTA-7 {
  border: unset !important;
  border-color: unset !important;
  background-color: #2e5053 !important;
  flex: none !important;
  justify-content: center !important;
  align-items: flex-end !important;
  width: 127px !important;
  display: flex !important;
}

.administrative .CTA-8 {
  font-family: var(--subtitle-BLD-font-family) !important;
  font-size: var(--subtitle-BLD-font-size) !important;
  font-style: var(--subtitle-BLD-font-style) !important;
  font-weight: var(--subtitle-BLD-font-weight) !important;
  letter-spacing: var(--subtitle-BLD-letter-spacing) !important;
  line-height: var(--subtitle-BLD-line-height) !important;
  margin-left: -2px !important;
}

.administrative .CTA-9 {
  margin-right: -2px !important;
}

.administrative .frame-35 {
  width: 1440px;
  height: 580px;
  position: absolute;
  top: 0;
  left: 1440px;
  overflow: hidden;
}

.administrative .rectangle-10 {
  width: 1383px;
  height: 634px;
  position: absolute;
  top: -4939px;
  left: 1101px;
}

.administrative .overlap-6 {
  filter: blur(80px);
  background: linear-gradient(#000 0%, #071103 25%, #202020 44.79%, #2f2f2f 61.46%, #0000 100%);
  width: 1684px;
  height: 714px;
  position: absolute;
  top: -60px;
  left: -116px;
}

.administrative .frame-36 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: absolute;
  top: 242px;
  left: 196px;
}

.administrative .text-wrapper-38 {
  color: var(--neutral-0);
  letter-spacing: -.53px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 600;
  line-height: 49px;
  position: relative;
}

.administrative .frame-37 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.administrative .our-client-and-2 {
  color: #f6f7f8;
  letter-spacing: -.17px;
  width: 436px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 400;
  line-height: 23.8px;
  position: relative;
}

.administrative .CTA-10 {
  all: unset;
  box-sizing: border-box;
  flex: none;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.administrative .text-wrapper-39 {
  color: var(--neutral-0);
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 23.8px;
  position: relative;
}

.administrative .arrow-forward-4 {
  width: 24px;
  height: 24px;
  margin-top: -5311px;
  margin-right: -674px;
  position: relative;
}

.administrative .text-wrapper-40 {
  color: var(--neutral-0);
  font-family: var(--h4-REG-font-family);
  font-size: var(--h4-REG-font-size);
  font-style: var(--h4-REG-font-style);
  font-weight: var(--h4-REG-font-weight);
  letter-spacing: var(--h4-REG-letter-spacing);
  line-height: var(--h4-REG-line-height);
  white-space: nowrap;
  height: 41px;
  position: absolute;
  top: 120px;
  left: 196px;
}

.administrative .frame-38 {
  align-items: flex-start;
  gap: 5px;
  display: inline-flex;
  position: absolute;
  top: 560px;
  left: 196px;
}

.administrative .ellipse-9 {
  background-color: #d9d9d952;
  border-radius: 5.5px;
  width: 11px;
  height: 11px;
  position: relative;
}

.administrative .ellipse-10 {
  background-color: #e9e9e9;
  border-radius: 5.5px;
  width: 11px;
  height: 11px;
  position: relative;
}

.administrative .carousel-03 {
  position: absolute !important;
  top: 0 !important;
  left: 2880px !important;
}

.administrative .carousel-instance {
  margin-top: -5334px !important;
  margin-left: -673px !important;
}

.administrative .overlap-7 {
  position: absolute;
}

.administrative .rectangle-11 {
  background-color: #f6f7f8;
  width: 1440px;
  height: 264px;
  position: absolute;
  top: 0;
  left: 6px;
}

.administrative .rectangle-12 {
  background-color: #2c3e47;
  width: 1446px;
  height: 733px;
  position: absolute;
  top: 264px;
  left: 0;
}

.administrative .rectangle-13 {
  width: 637px;
  height: 824px;
  position: absolute;
  top: 242px;
  left: 6px;
}

.administrative .rectangle-14 {
  width: 577px;
  height: 824px;
  position: absolute;
  top: 219px;
  left: 869px;
}

.administrative .text-wrapper-41 {
  color: #1f1f1f;
  letter-spacing: -1.05px;
  text-align: center;
  width: 906px;
  height: 50px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 42px;
  font-weight: 400;
  line-height: 50.4px;
  position: absolute;
  top: 1050px;
  left: 257px;
}

.administrative .frame-39 {
  width: 742px;
  height: 307px;
  position: absolute;
  top: 0;
  left: 0;
}

.administrative .overlap-group-6 {
  height: 307px;
  position: relative;
}

.administrative .rectangle-15 {
  background-color: #f6f7f8;
  width: 390px;
  height: 307px;
  position: absolute;
  top: 0;
  left: 185px;
}

.administrative .frame-40 {
  flex-direction: column;
  align-items: center;
  gap: 25px;
  display: inline-flex;
  position: absolute;
  top: 76px;
  left: 0;
}

.administrative .frame-41 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 51px;
  display: inline-flex;
  position: relative;
}

.administrative .frame-42 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 26px;
  display: inline-flex;
  position: relative;
}

.administrative .frame-43 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.administrative .text-wrapper-42 {
  color: var(--neutral-60);
  letter-spacing: -.2px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  position: relative;
}

.administrative .frame-44 {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 29px;
  display: inline-flex;
  position: relative;
}

.administrative .mercedes-benz-logo {
  object-fit: cover;
  width: 148px;
  height: 37px;
  position: relative;
}

.administrative .GE-healthcare-logo {
  object-fit: cover;
  width: 98px;
  height: 55px;
  position: relative;
}

.administrative .sony-logo {
  object-fit: cover;
  width: 74px;
  height: 13px;
  position: relative;
}

.administrative .shell-logo {
  object-fit: cover;
  width: 69px;
  height: 39px;
  position: relative;
}

.administrative .amazon {
  object-fit: cover;
  width: 70px;
  height: 33px;
  position: relative;
}

.administrative .wipro-logo-new-og {
  object-fit: cover;
  width: 102px;
  height: 53px;
  position: relative;
}

.administrative .frame-45 {
  flex-direction: column;
  align-items: center;
  display: inline-flex;
  position: absolute;
}

.administrative .frame-46 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-end;
  gap: 32px;
  width: 328px;
  display: flex;
  position: relative;
}

.administrative .frame-47 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 24px;
  width: 328px;
  display: flex;
  position: relative;
}

.administrative .text-wrapper-43 {
  color: var(--secondary-04);
  letter-spacing: -.8px;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.4px;
  position: relative;
}

.administrative .text-wrapper-44 {
  color: var(--neutral-80);
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  text-align: center;
  width: 297px;
  position: relative;
}

.administrative .group-5 {
  width: 390px;
  height: 360px;
  position: relative;
}

.administrative .frame-48 {
  height: 360px;
}

.administrative .overlap-group-7 {
  background-color: var(--neutral-70);
  width: 1440px;
  height: 360px;
}

.administrative .frame-49 {
  flex-direction: column;
  align-items: center;
  gap: 24px;
  display: inline-flex;
  position: relative;
  top: 85px;
  left: 41px;
}

.administrative .text-wrapper-45 {
  color: var(--neutral-0);
  letter-spacing: -.8px;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.4px;
  position: relative;
}

.administrative .are-we-a-good-fit {
  color: #f6f7f8;
  letter-spacing: -.2px;
  text-align: center;
  width: 309px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.administrative .CTA-11 {
  cursor: pointer;
  background-color: var(--secondary-02);
  box-sizing: border-box;
  border: none;
  outline: none;
  flex: none;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  display: inline-flex;
  position: relative;
}

.administrative .text-wrapper-46 {
  color: var(--neutral-0);
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.administrative .arrow-forward-5 {
  width: 24px;
  height: 24px;
  position: relative;
}

.administrative .frame-50 {
  flex: none;
  width: 497.74px;
  position: relative;
}

.administrative .frame-51 {
  align-items: center;
  gap: 17px;
  display: inline-flex;
  position: absolute;
  top: 91px;
  left: 86px;
}

.administrative .text-wrapper-47 {
  color: #294b51;
  letter-spacing: -1.05px;
  width: 421px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 42px;
  font-weight: 600;
  line-height: 50.4px;
  position: relative;
}

.administrative .frame-52 {
  flex: none;
  align-items: flex-start;
  gap: 61px;
  display: inline-flex;
  position: relative;
}

.administrative .frame-53 {
  flex-direction: column;
  flex: none;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.administrative .text-wrapper-48 {
  -webkit-text-stroke: .5px #2e5053;
  color: #2e5053;
  letter-spacing: -.75px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1.5px;
  margin-left: -.5px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 50px;
  font-weight: 400;
  line-height: 60px;
  position: relative;
}

.administrative .frame-54 {
  flex-direction: column;
  align-items: center;
  gap: 74px;
  display: inline-flex;
  position: absolute;
  top: 512px;
  left: 220px;
}

.administrative .frame-55 {
  flex: none;
  align-items: flex-start;
  gap: 155px;
  display: inline-flex;
  position: relative;
}

.administrative .frame-56 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.administrative .text-wrapper-49 {
  white-space: nowrap;
  color: #fff;
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  width: 168px;
  position: relative;
}

.administrative .frame-57 {
  flex: none;
  align-items: flex-end;
  gap: 131px;
  width: 987px;
  display: flex;
  position: relative;
}

.administrative .text-wrapper-50 {
  color: #fff;
  white-space: nowrap;
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  width: 212px;
  position: relative;
}

.administrative .frame-58 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-right: -2px;
  display: inline-flex;
  position: relative;
}

.administrative .joulestowatts {
  color: #0000;
  letter-spacing: -.29px;
  text-align: center;
  width: 681px;
  height: 123px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 29px;
  font-weight: 600;
  line-height: 40.6px;
  position: absolute;
  top: 305px;
  left: 361px;
}

.administrative .overlap-8 {
  width: 1446px;
  height: 454px;
  position: absolute;
  top: 2207px;
  left: 0;
}

.administrative .carousel-3 {
  width: 1446px;
  height: 454px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.administrative .frame-59 {
  background-size: 100% 100%;
  width: 1440px;
  height: 454px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.administrative .overlap-group-8 {
  width: 1722px;
  height: 790px;
  position: relative;
  top: -81px;
  left: -186px;
}

.administrative .shutterstock-2 {
  width: 765px;
  height: 454px;
  position: absolute;
  top: 81px;
  left: 861px;
}

.administrative .rectangle-16 {
  filter: blur(40px);
  background: linear-gradient(90deg, #589993 5.95%, #4d9f98 20.62% 48.33%, #fcfcfc00 71.42%);
  width: 1722px;
  height: 790px;
  position: absolute;
  top: 0;
  left: 0;
}

.administrative .carousel-02 {
  position: absolute !important;
  top: 0 !important;
  left: 1440px !important;
}

.administrative .carousel-02-instance {
  width: 2600px !important;
  height: 1265px !important;
}

.administrative .frame-60 {
  width: 1383px !important;
  height: 634px !important;
  top: 631px !important;
  left: 1217px !important;
}

.administrative .frame-61 {
  margin-bottom: -199px !important;
  margin-right: -674px !important;
}

.administrative .carousel-03-instance {
  margin-bottom: -176px !important;
  margin-left: -673px !important;
}

.administrative .CTA-12 {
  border: unset !important;
  border-color: unset !important;
  background-color: #2e5053 !important;
  justify-content: center !important;
  align-items: flex-end !important;
  width: 182px !important;
  display: flex !important;
  position: absolute !important;
  top: 318px !important;
  left: 73px !important;
}

.administrative .CTA-13 {
  font-family: var(--subtitle-BLD-font-family) !important;
  font-size: var(--subtitle-BLD-font-size) !important;
  font-style: var(--subtitle-BLD-font-style) !important;
  font-weight: var(--subtitle-BLD-font-weight) !important;
  letter-spacing: var(--subtitle-BLD-letter-spacing) !important;
  line-height: var(--subtitle-BLD-line-height) !important;
}

.administrative .we-are-your-growth-2 {
  color: #fff;
  letter-spacing: -.29px;
  width: 587px;
  height: 164px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 29px;
  font-weight: 600;
  line-height: 40.6px;
  position: absolute;
  top: 80px;
  left: 73px;
}

.administrative .group-6 {
  width: 1440px;
  height: 380px;
  position: absolute;
  top: 3011px;
  left: 0;
}

.administrative .overlap-9 {
  height: 380px;
  position: relative;
}

.administrative .frame-62 {
  background-color: #4d4d4d;
  width: 1440px;
  height: 380px;
  position: absolute;
  top: 0;
  left: 0;
}

.administrative .frame-63 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 69px;
  left: 152px;
}

.administrative .frame-64 {
  flex-direction: column;
  flex: none;
  align-items: flex-end;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.administrative .text-wrapper-51 {
  color: #fff;
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.administrative .are-we-a-good-fit-2 {
  color: var(--neutral-10);
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  text-align: right;
  width: 429px;
  position: relative;
}

.administrative .CTA-14 {
  all: unset;
  background-color: var(--secondary-04);
  box-sizing: border-box;
  flex: none;
  align-items: center;
  gap: 10px;
  padding: 12px 16px;
  display: inline-flex;
  position: relative;
}

.administrative .text-wrapper-52 {
  color: #fff;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: relative;
}

.administrative .CTA-15 {
  cursor: pointer;
  background-color: var(--secondary-02);
  box-sizing: border-box;
  border: none;
  outline: none;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  display: inline-flex;
  position: absolute;
  top: 267px;
  left: 819px;
}

.administrative .frame-65 {
  background-color: var(--secondary-03);
  width: 720px;
  height: 380px;
  position: absolute;
  top: 0;
  left: 0;
}

.administrative .overlap-group-9 {
  background-color: var(--neutral-10);
  width: 720px;
  height: 380px;
  position: absolute;
  top: 0;
  left: 0;
}

.administrative .frame-66 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 32px;
  display: inline-flex;
  position: relative;
  top: 49px;
  left: 128px;
}

.administrative .text-wrapper-53 {
  color: var(--secondary-04);
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.administrative .text-wrapper-54 {
  color: var(--neutral-80);
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  text-align: right;
  width: 500px;
  position: relative;
}

.administrative .frame-67 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 52px;
  left: 817px;
}

.administrative .text-wrapper-55 {
  color: #fff;
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.administrative .text-wrapper-56 {
  color: var(--neutral-10);
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  width: 429px;
  position: relative;
}

.administrative .frame-68 {
  width: 1440px;
  height: 356px;
  position: absolute;
  top: 3391px;
  left: 2px;
}

.administrative .overlap-10 {
  height: 356px;
  position: relative;
}

.administrative .section-industries {
  background-color: var(--secondary-04);
  width: 1440px;
  height: 356px;
  position: absolute;
  top: 0;
  left: 0;
}

.administrative .frame-69 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: relative;
  top: 75px;
  left: 80px;
}

.administrative .frame-70 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: relative;
}

.administrative .text-wrapper-57 {
  color: #fff;
  font-family: var(--h3-REG-font-family);
  font-size: var(--h3-REG-font-size);
  font-style: var(--h3-REG-font-style);
  font-weight: var(--h3-REG-font-weight);
  letter-spacing: var(--h3-REG-letter-spacing);
  line-height: var(--h3-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.administrative .are-we-a-good-fit-3 {
  color: var(--neutral-30);
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  width: 326px;
  position: relative;
}

.administrative .CTA-wrapper {
  flex: none;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.administrative .CTA-16 {
  border: unset !important;
  border-color: unset !important;
  background-color: #4d9f98 !important;
  justify-content: center !important;
  align-items: flex-end !important;
  width: 182px !important;
  display: flex !important;
}

.administrative .container {
  align-items: center;
  gap: 24px;
  width: 843px;
  height: 354px;
  padding: 56px 0;
  display: flex;
  position: absolute;
  top: 2px;
  left: 551px;
  overflow: hidden;
}

.administrative .BFSI {
  background-image: url("rectangle-24-2.b02836c3.webp");
  background-size: 100% 100%;
  width: 264px;
  height: 240px;
  position: relative;
}

.administrative .text-wrapper-58 {
  color: #fff;
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  white-space: nowrap;
  height: 34px;
  position: absolute;
  top: 179px;
  left: 27px;
}

.administrative .div-wrapper-2 {
  background-image: url("rectangle-23.69c6a4d4.webp");
  background-position: 50%;
  background-size: cover;
  width: 263px;
  height: 240px;
  position: relative;
}

.administrative .telecom {
  background-image: url("rectangle-25-1.740fe525.webp");
  background-size: 100% 100%;
  width: 263px;
  height: 240px;
  position: relative;
}

.administrative .telecom-3 {
  background-image: url("rectangle-25-2.60d7a48a.webp");
  background-position: 50%;
  background-size: cover;
  width: 263px;
  height: 240px;
  position: relative;
}

.administrative .telecom-4 {
  background-image: url("rectangle-25-3.09a31b27.webp");
  background-position: 50%;
  background-size: cover;
  width: 263px;
  height: 240px;
  position: relative;
}

.administrative .overlap-group-10 {
  width: 79px;
  height: 34px;
  position: relative;
  top: 179px;
  left: 27px;
}

.administrative .text-wrapper-59 {
  color: #fff;
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  white-space: nowrap;
  height: 34px;
  position: absolute;
  top: 0;
  left: 0;
}

.administrative .arrow {
  background-color: #fff;
  border-radius: 30px;
  width: 60px;
  height: 60px;
  position: absolute;
  top: 151px;
  left: 1365px;
  box-shadow: -3px 1px 16px #00000040;
}

.administrative .arrow-forward-ios {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 18px;
  left: 18px;
}

.administrative .group-7 {
  width: 1300px;
  height: 441px;
  position: absolute;
  top: 1741px;
  left: 20px;
}

.administrative .overlap-11 {
  height: 441px;
  position: relative;
}

.administrative .frame-71 {
  width: 1300px;
  height: 441px;
  position: absolute;
  top: 0;
  left: 0;
}

.administrative .overlap-group-11 {
  background-image: url("group-51.fbf08a4e.webp");
  background-size: 100% 100%;
  width: 1231px;
  height: 386px;
  position: relative;
  left: 70px;
}

.administrative .text-wrapper-60 {
  color: #4d9f98;
  letter-spacing: -.53px;
  white-space: nowrap;
  width: 183px;
  height: 51px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 600;
  line-height: 49px;
  position: absolute;
  top: 134px;
  left: 85px;
}

.administrative .text-wrapper-61 {
  color: #4d9f98;
  letter-spacing: -.53px;
  white-space: nowrap;
  width: 115px;
  height: 51px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 600;
  line-height: 49px;
  position: absolute;
  top: 122px;
  left: 527px;
}

.administrative .text-wrapper-62 {
  color: #4d9f98;
  letter-spacing: -.53px;
  white-space: nowrap;
  width: 129px;
  height: 51px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 600;
  line-height: 49px;
  position: absolute;
  top: 121px;
  left: 949px;
}

.administrative .online-system-driven-2 {
  color: var(--neutral-60);
  font-family: var(--paragraph-MED-font-family);
  font-size: var(--paragraph-MED-font-size);
  font-style: var(--paragraph-MED-font-style);
  font-weight: var(--paragraph-MED-font-weight);
  letter-spacing: var(--paragraph-MED-letter-spacing);
  line-height: var(--paragraph-MED-line-height);
  width: 226px;
  height: 103px;
  position: absolute;
  top: 201px;
  left: 65px;
}

.administrative .system-driven-2 {
  color: var(--neutral-60);
  font-family: var(--paragraph-MED-font-family);
  font-size: var(--paragraph-MED-font-size);
  font-style: var(--paragraph-MED-font-style);
  font-weight: var(--paragraph-MED-font-weight);
  letter-spacing: var(--paragraph-MED-letter-spacing);
  line-height: var(--paragraph-MED-line-height);
  width: 226px;
  height: 103px;
  position: absolute;
  top: 192px;
  left: 506px;
}

.administrative .online-support-2 {
  color: var(--neutral-60);
  font-family: var(--paragraph-MED-font-family);
  font-size: var(--paragraph-MED-font-size);
  font-style: var(--paragraph-MED-font-style);
  font-weight: var(--paragraph-MED-font-weight);
  letter-spacing: var(--paragraph-MED-letter-spacing);
  line-height: var(--paragraph-MED-line-height);
  width: 226px;
  height: 83px;
  position: absolute;
  top: 189px;
  left: 928px;
}

.administrative .payroll-2 {
  width: 47px;
  height: 47px;
  position: absolute;
  top: 67px;
  left: 704px;
}

.administrative .customer-support-2 {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 67px;
  left: 1135px;
}

.administrative .add-user-group-man-3 {
  width: 43px;
  height: 43px;
  position: absolute;
  top: 71px;
  left: 348px;
}

.administrative .overlap-12 {
  background-color: #f6f7f8;
  width: 1446px;
  height: 350px;
  position: absolute;
  top: 2661px;
  left: 0;
}

.administrative .frame-72 {
  flex-direction: column;
  align-items: center;
  gap: 25px;
  display: inline-flex;
  position: relative;
  top: 103px;
  left: 156px;
}

.administrative .mercedes-benz-logo-2 {
  object-fit: cover;
  width: 248px;
  height: 63px;
  position: relative;
}

.administrative .GE-healthcare-logo-2 {
  object-fit: cover;
  width: 164px;
  height: 92px;
  position: relative;
}

.administrative .sony-logo-2 {
  object-fit: cover;
  width: 123px;
  height: 22px;
  position: relative;
}

.administrative .shell-logo-2 {
  object-fit: cover;
  width: 115px;
  height: 65px;
  position: relative;
}

.administrative .amazon-2 {
  object-fit: cover;
  width: 178px;
  height: 55px;
  position: relative;
}

.administrative .wipro-logo-new-og-2 {
  object-fit: cover;
  width: 171px;
  height: 89px;
  position: relative;
}

.carousel-no .rectangle {
  width: 1093px;
  height: 580px;
  position: absolute;
  top: 60px;
  left: 463px;
}

.carousel-no .text-wrapper {
  color: #fff;
  letter-spacing: -.53px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 600;
  line-height: 49px;
  position: relative;
}

.carousel-no .our-client-and {
  color: #f6f7f8;
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  width: 436px;
  margin-top: -1px;
  position: relative;
}

.carousel-wrapper {
  width: 1440px;
  height: 580px;
  overflow: hidden;
}

.carousel-wrapper .overlap-group-2 {
  width: 1805px;
  height: 714px;
  position: relative;
  top: -58px;
  left: -163px;
}

.carousel-wrapper .rectangle-2 {
  object-fit: cover;
  width: 1087px;
  height: 656px;
  position: absolute;
  top: 58px;
  left: 718px;
}

.carousel-wrapper .rectangle-3 {
  filter: blur(80px);
  background: linear-gradient(#000 0%, #071103 25%, #202020 44.79%, #2f2f2f 61.46%, #0000 89.06%);
  width: 1676px;
  height: 714px;
  position: absolute;
  top: 0;
  left: 0;
}

.carousel-wrapper .frame-4 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: absolute;
  top: 214px;
  left: 243px;
}

.carousel-wrapper .p {
  color: #fff;
  letter-spacing: -.53px;
  width: 396px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 600;
  line-height: 49px;
  position: relative;
}

.carousel-wrapper .frame-5 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.carousel-wrapper .with-efficient-and {
  color: #f6f7f8;
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  width: 461px;
  margin-top: -1px;
  position: relative;
}

.carousel-wrapper .CTA-2 {
  all: unset;
  box-sizing: border-box;
  flex: none;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.carousel-wrapper .text-wrapper-4 {
  color: #fff;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 23.8px;
  position: relative;
}

.carousel-wrapper .arrow-forward-2 {
  width: 24px;
  height: 24px;
  position: relative;
}

.carousel-wrapper .text-wrapper-5 {
  color: #fff;
  font-family: var(--h4-REG-font-family);
  font-size: var(--h4-REG-font-size);
  font-style: var(--h4-REG-font-style);
  font-weight: var(--h4-REG-font-weight);
  letter-spacing: var(--h4-REG-letter-spacing);
  line-height: var(--h4-REG-line-height);
  white-space: nowrap;
  height: 41px;
  position: absolute;
  top: 118px;
  left: 243px;
}

.carousel-wrapper .frame-6 {
  align-items: flex-start;
  gap: 5px;
  display: inline-flex;
  position: absolute;
  top: 558px;
  left: 243px;
}

.carousel-wrapper .ellipse-3 {
  background-color: #f6f7f852;
  border-radius: 5.5px;
  width: 11px;
  height: 11px;
  position: relative;
}

.carousel-wrapper .ellipse-4 {
  background-color: var(--neutral-30);
  border-radius: 5.5px;
  width: 11px;
  height: 11px;
  position: relative;
}

.CTA.default {
  border: 1px solid;
  border-color: var(--neutral-0);
  gap: 10px;
}

.state-default-wrapper .text-wrapper-6 {
  color: var(--secondary-03);
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.state-default-wrapper:hover .text-wrapper-6 {
  color: var(--neutral-0);
}

.offerletter {
  background-color: #fff;
  flex-direction: row;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.offerletter .div-2 {
  position: relative;
}

.offerletter .header {
  width: 390px !important;
  height: 60px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.offerletter .overlap-wrapper {
  background-color: #202020;
  width: 390px;
  height: 574px;
  position: absolute;
  top: 60px;
  left: 0;
  overflow: hidden;
}

.offerletter .overlap {
  height: 579px;
  position: relative;
}

.offerletter .frame-7 {
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 358px;
  padding: 0 54px;
  display: flex;
  position: absolute;
  top: 86px;
  left: 16px;
}

.offerletter .one-stop-solution {
  color: #0000;
  letter-spacing: -.8px;
  text-align: center;
  width: 281px;
  margin-top: -1px;
  margin-left: -15.5px;
  margin-right: -15.5px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 600;
  line-height: 38.4px;
  position: relative;
}

.offerletter .span {
  color: #fff;
}

.offerletter .text-wrapper-7 {
  color: #00dfc2;
}

.offerletter .text-wrapper-8 {
  color: #fff;
  letter-spacing: -.16px;
  text-align: center;
  width: 282px;
  margin-left: -16px;
  margin-right: -16px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  position: relative;
}

.offerletter .overlap-group-wrapper {
  width: 231px;
  height: 280.8px;
  position: relative;
}

.offerletter .overlap-group-3 {
  width: 283px;
  height: 307px;
  position: relative;
  left: -26px;
}

.offerletter .ellipse-5 {
  border: 52.5px solid;
  border-color: var(--secondary-02);
  border-radius: 141.75px;
  width: 283px;
  height: 283px;
  position: absolute;
  top: 24px;
  left: 0;
}

.offerletter .smiling-confident {
  object-fit: cover;
  width: 185px;
  height: 276px;
  position: absolute;
  top: 0;
  left: 63px;
}

.offerletter .rectangle-4 {
  width: 294px;
  height: 367px;
  position: absolute;
  top: 207px;
  left: 0;
}

.offerletter .rectangle-5 {
  width: 294px;
  height: 370px;
  position: absolute;
  top: 0;
  left: 96px;
}

.offerletter .footer {
  background-color: var(--white);
  width: 390px;
  height: 860px;
  position: absolute;
  top: 5311px;
  left: 0;
  overflow: hidden;
}

.offerletter .overlap-2 {
  width: 390px;
  height: 907px;
  position: relative;
}

.offerletter .frame-8 {
  background-color: #1f1f1f;
  width: 390px;
  height: 907px;
  position: absolute;
  top: 0;
  left: 0;
}

.offerletter .frame-9 {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: absolute;
  top: 115px;
  left: 26px;
}

.offerletter .text-wrapper-9 {
  color: var(--neutral-0);
  font-family: var(--paragraph-ALCPS-font-family);
  font-size: var(--paragraph-ALCPS-font-size);
  font-style: var(--paragraph-ALCPS-font-style);
  font-weight: var(--paragraph-ALCPS-font-weight);
  letter-spacing: var(--paragraph-ALCPS-letter-spacing);
  line-height: var(--paragraph-ALCPS-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.offerletter .text-wrapper-10 {
  color: #f6f7f8;
  font-family: var(--paragraph-REG-font-family);
  font-size: var(--paragraph-REG-font-size);
  font-style: var(--paragraph-REG-font-style);
  font-weight: var(--paragraph-REG-font-weight);
  letter-spacing: var(--paragraph-REG-letter-spacing);
  line-height: var(--paragraph-REG-line-height);
  width: 324px;
  position: relative;
}

.offerletter .DIV {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 113px;
  display: flex;
  position: absolute;
  top: 272px;
  left: 195px;
}

.offerletter .text-wrapper-11 {
  color: var(--white);
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.offerletter .text-wrapper-12 {
  color: var(--white);
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.offerletter .DIV-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 113px;
  display: flex;
  position: absolute;
  top: 272px;
  left: 26px;
}

.offerletter .frame-10 {
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 583px;
  left: 69px;
}

.offerletter .group {
  width: 37px;
  height: 52px;
  position: relative;
}

.offerletter .text-wrapper-13 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 38px;
  left: 1px;
}

.offerletter .face-book {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 0;
  left: 0;
}

.offerletter .frame-11 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.offerletter .linkedin {
  flex: none;
  position: relative;
}

.offerletter .text-wrapper-14 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  width: fit-content;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: relative;
}

.offerletter .facebook {
  width: 35px;
  height: 35px;
  position: relative;
}

.offerletter .frame-12 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.offerletter .logo {
  width: 116px;
  height: 56px;
  position: absolute;
  top: 39px;
  left: 24px;
}

.offerletter .frame-wrapper {
  width: 295px;
  height: 64px;
  position: absolute;
  top: 732px;
  left: 48px;
}

.offerletter .frame-13 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 295px;
  display: flex;
  position: relative;
}

.offerletter .text-wrapper-15 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.offerletter .ellipse-6 {
  background-color: var(--grey-01);
  border-radius: 1px / 1.5px;
  width: 2px;
  height: 3px;
  position: relative;
}

.offerletter .text-wrapper-16 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  text-align: center;
  align-self: stretch;
  position: relative;
}

.offerletter .overlap-3 {
  position: absolute;
}

.offerletter .div-wrapper {
  position: absolute;
  top: 0;
}

.offerletter .overlap-group-4 {
  position: relative;
}

.offerletter .rectangle-6 {
  background-color: #f6f7f8;
  width: 390px;
  height: 307px;
  position: absolute;
  top: 0;
  left: 185px;
}

.offerletter .frame-14 {
  flex-direction: column;
  align-items: center;
  gap: 25px;
  display: inline-flex;
  position: absolute;
  top: 76px;
  left: 0;
}

.offerletter .frame-15 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 51px;
  display: inline-flex;
  position: relative;
}

.offerletter .frame-16 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 26px;
  display: inline-flex;
  position: relative;
}

.offerletter .frame-17 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.offerletter .frame-18 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.offerletter .text-wrapper-17 {
  color: var(--neutral-60);
  letter-spacing: -.2px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  position: relative;
}

.offerletter .frame-19 {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 29px;
  display: inline-flex;
  position: relative;
}

.offerletter .mercedes-benz-logo {
  object-fit: cover;
  width: 148px;
  height: 37px;
  position: relative;
}

.offerletter .GE-healthcare-logo {
  object-fit: cover;
  width: 98px;
  height: 55px;
  position: relative;
}

.offerletter .sony-logo {
  object-fit: cover;
  width: 74px;
  height: 13px;
  position: relative;
}

.offerletter .shell-logo {
  object-fit: cover;
  width: 69px;
  height: 39px;
  position: relative;
}

.offerletter .amazon {
  object-fit: cover;
  width: 70px;
  height: 33px;
  position: relative;
}

.offerletter .wipro-logo-new-og {
  object-fit: cover;
  width: 102px;
  height: 53px;
  position: relative;
}

.offerletter .ellipse-7 {
  filter: blur(57px);
  background-color: #d9d9d952;
  border-radius: 184px / 48.7px;
  width: 368px;
  height: 97px;
  position: absolute;
  top: 285px;
  left: 304px;
  transform: rotate(-5.02deg);
}

.offerletter .partner {
  object-fit: cover;
  width: 424px;
  height: 398px;
  position: absolute;
  top: 0;
  left: 0;
}

.offerletter .group-2 {
  width: 631px;
  height: 247px;
  position: absolute;
  top: 87px;
  left: 693px;
}

.offerletter .CTA-instance {
  background-color: var(--primary-02) !important;
  border: unset !important;
  border-color: unset !important;
  justify-content: center !important;
  align-items: flex-end !important;
  width: 182px !important;
  display: flex !important;
  position: absolute !important;
  top: 199px !important;
  left: 0 !important;
}

.offerletter .instance-node {
  font-family: var(--subtitle-BLD-font-family) !important;
  font-size: var(--subtitle-BLD-font-size) !important;
  font-style: var(--subtitle-BLD-font-style) !important;
  font-weight: var(--subtitle-BLD-font-weight) !important;
  letter-spacing: var(--subtitle-BLD-letter-spacing) !important;
  line-height: var(--subtitle-BLD-line-height) !important;
  margin-left: -1px !important;
}

.offerletter .CTA-3 {
  margin-right: -1px !important;
}

.offerletter .one-stop-centralized-wrapper {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 629px;
  display: flex;
  position: absolute;
  top: 0;
  left: 2px;
}

.offerletter .one-stop-centralized {
  color: #2e5053;
  letter-spacing: -.3px;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 30px;
  font-weight: 600;
  line-height: 42px;
  position: relative;
}

.offerletter .text {
  width: 260px;
  height: 50px;
  position: absolute;
  top: 617px;
  left: 635px;
}

.offerletter .text-wrapper-18 {
  color: #2e5053;
  letter-spacing: -1.05px;
  text-align: center;
  white-space: nowrap;
  font-family: Source Sans Pro, Helvetica;
  font-size: 42px;
  font-weight: 400;
  line-height: 50.4px;
  position: absolute;
  top: 0;
  left: 0;
}

.offerletter .rectangle-7 {
  background-color: #f6f7f8;
  width: 1445px;
  height: 1514px;
  position: absolute;
  top: 421px;
  left: 0;
}

.offerletter .text-wrapper-19 {
  color: #2e5053;
  letter-spacing: -.63px;
  text-align: center;
  width: 770px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 42px;
  font-weight: 600;
  line-height: 58.8px;
  position: absolute;
  top: 479px;
  left: 356px;
}

.offerletter .ellipse-8 {
  border: .8px solid #64b4ad;
  border-radius: 327px;
  width: 654px;
  height: 654px;
  position: absolute;
  top: 894px;
  left: 408px;
}

.offerletter .ellipse-9 {
  border: .8px solid #64b4ad;
  border-radius: 435.6px;
  width: 871px;
  height: 871px;
  position: absolute;
  top: 785px;
  left: 299px;
}

.offerletter .ellipse-10 {
  border: .8px solid #64b4ad;
  border-radius: 528.57px;
  width: 1057px;
  height: 1057px;
  position: absolute;
  top: 692px;
  left: 206px;
}

.offerletter .group-3 {
  background: linear-gradient(#2e5053 0%, #2c3e47 100%);
  border-radius: 100px;
  width: 200px;
  height: 200px;
  position: absolute;
  top: 785px;
  left: 265px;
  box-shadow: 0 4px 12px #7878781f;
}

.offerletter .frame-20 {
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 140px;
  height: 117px;
  display: flex;
  position: relative;
  top: 30px;
  left: 30px;
}

.offerletter .image-wrapper {
  width: 74px;
  height: 74px;
  position: relative;
}

.offerletter .image {
  object-fit: cover;
  width: 108px;
  height: 106px;
  position: absolute;
  top: -6px;
  left: -17px;
}

.offerletter .encrypted-document {
  color: #fff;
  letter-spacing: -.17px;
  text-align: center;
  width: 157px;
  margin-bottom: -16.25px;
  margin-left: -8.54px;
  margin-right: -8.54px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: relative;
}

.offerletter .group-4 {
  background: linear-gradient(#2e5053 0%, #2c3e47 100%);
  border-radius: 100px;
  width: 200px;
  height: 200px;
  position: absolute;
  top: 1312px;
  left: 179px;
  box-shadow: 0 4px 12px #7878781f;
}

.offerletter .frame-21 {
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 165px;
  display: flex;
  position: relative;
  top: 31px;
  left: 17px;
}

.offerletter .img-wrapper {
  width: 65.45px;
  height: 65.45px;
  position: relative;
}

.offerletter .image-2 {
  object-fit: cover;
  width: 97px;
  height: 96px;
  position: absolute;
  top: -15px;
  left: -16px;
}

.offerletter .text-wrapper-20 {
  color: #fff;
  letter-spacing: -.17px;
  text-align: center;
  width: 157px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: relative;
}

.offerletter .group-5 {
  background: linear-gradient(#2e5053 0%, #2c3e47 100%);
  border-radius: 100px;
  width: 200px;
  height: 200px;
  position: absolute;
  top: 1593px;
  left: 683px;
  box-shadow: 0 4px 12px #7878781f;
}

.offerletter .image-3 {
  object-fit: cover;
  width: 99px;
  height: 96px;
  position: absolute;
  top: -4px;
  left: -10px;
}

.offerletter .group-6 {
  background: linear-gradient(#2e5053 0%, #2c3e47 100%);
  border-radius: 100px;
  width: 200px;
  height: 200px;
  position: absolute;
  top: 1193px;
  left: 1121px;
  box-shadow: 0 4px 12px #7878781f;
}

.offerletter .frame-22 {
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 140px;
  height: 136px;
  display: flex;
  position: relative;
  top: 32px;
  left: 30px;
}

.offerletter .image-4 {
  object-fit: cover;
  width: 84px;
  height: 82px;
  position: absolute;
  top: -4px;
  left: -5px;
}

.offerletter .flagship-referral {
  color: #fff;
  letter-spacing: -.17px;
  text-align: center;
  width: fit-content;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: relative;
}

.offerletter .group-7 {
  background: linear-gradient(#2e5053 0%, #2c3e47 100%);
  border-radius: 100px;
  width: 200px;
  height: 200px;
  position: absolute;
  top: 675px;
  left: 841px;
  box-shadow: 0 4px 12px #7878781f;
}

.offerletter .frame-23 {
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 165px;
  display: flex;
  position: relative;
  top: 30px;
  left: 18px;
}

.offerletter .frame-24 {
  width: 68.45px;
  height: 68.45px;
  position: relative;
}

.offerletter .image-5 {
  object-fit: cover;
  width: 86px;
  height: 85px;
  position: absolute;
  top: -11px;
  left: -8px;
}

.offerletter .mask-group {
  background-image: url("mask-group-1.7b179089.webp");
  background-size: 100% 100%;
  width: 547px;
  height: 344px;
  position: absolute;
  top: 1042px;
  left: 465px;
  box-shadow: 5px 5px 25px #00000012;
}

.offerletter .kisspng-macbook-air {
  object-fit: cover;
  width: 706px;
  height: 428px;
  position: absolute;
  top: 1020px;
  left: 381px;
}

.offerletter .image-6 {
  width: 249px;
  height: 360px;
  position: absolute;
  top: 1041px;
  left: 870px;
}

.offerletter .carousel-2 {
  width: 1440px;
  height: 488px;
  position: absolute;
  top: 1923px;
  left: 2px;
  overflow: hidden;
}

.offerletter .frame-25 {
  width: 1440px;
  height: 466px;
  position: absolute;
  top: 0;
  left: 0;
}

.offerletter .overlap-group-5 {
  width: 1776px;
  height: 697px;
  position: relative;
  top: -58px;
  left: -186px;
}

.offerletter .rectangle-8 {
  width: 1440px;
  height: 485px;
  position: absolute;
  top: 58px;
  left: 186px;
}

.offerletter .rectangle-9 {
  filter: blur(40px);
  background: linear-gradient(90deg, #589993 5.95%, #4d9f98 20.62% 31.87%, #597274 50.62%, #fcfcfc00 71.42%);
  width: 1776px;
  height: 697px;
  position: absolute;
  top: 0;
  left: 0;
}

.offerletter .text-wrapper-21 {
  color: #f6f7f8;
  font-family: var(--h3-REG-font-family);
  font-size: var(--h3-REG-font-size);
  font-style: var(--h3-REG-font-style);
  font-weight: var(--h3-REG-font-weight);
  letter-spacing: var(--h3-REG-letter-spacing);
  line-height: var(--h3-REG-line-height);
  width: 617px;
  height: 245px;
  position: absolute;
  top: 168px;
  left: 277px;
}

.offerletter .carousel-02 {
  position: absolute !important;
  top: 0 !important;
  left: 1440px !important;
}

.offerletter .carousel-instance {
  width: 2082px !important;
  height: 813px !important;
  top: -159px !important;
  left: -514px !important;
}

.offerletter .carousel-02-instance {
  width: 1383px !important;
  height: 634px !important;
  top: 0 !important;
  left: 0 !important;
}

.offerletter .frame-26 {
  top: 99px !important;
  left: 398px !important;
}

.offerletter .frame-27 {
  top: 341px !important;
  left: 594px !important;
}

.offerletter .frame-28 {
  margin-top: -531px !important;
  margin-left: -848px !important;
}

.offerletter .frame-29 {
  top: 219px !important;
  left: 594px !important;
}

.offerletter .frame-30 {
  top: 659px !important;
  left: 594px !important;
}

.offerletter .carousel-03 {
  position: absolute !important;
  top: 0 !important;
  left: 2880px !important;
}

.offerletter .carousel-03-instance {
  margin-top: -554px !important;
  margin-left: -2288px !important;
}

.offerletter .frame-31 {
  flex-direction: column;
  align-items: center;
  gap: 92px;
  display: inline-flex;
  position: absolute;
  top: 366px;
  left: 185px;
}

.offerletter .frame-32 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-end;
  gap: 32px;
  width: 328px;
  display: flex;
  position: relative;
}

.offerletter .frame-33 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 24px;
  width: 328px;
  display: flex;
  position: relative;
}

.offerletter .text-wrapper-22 {
  color: #2c3e47;
  letter-spacing: -.8px;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.4px;
  position: relative;
}

.offerletter .text-wrapper-23 {
  color: var(--neutral-80);
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  text-align: center;
  width: 297px;
  position: relative;
}

.offerletter .group-8 {
  width: 390px;
  height: 360px;
  position: relative;
}

.offerletter .frame-34 {
  height: 360px;
}

.offerletter .overlap-group-6 {
  background-color: #4d4d4d;
  width: 1440px;
  height: 360px;
}

.offerletter .frame-35 {
  flex-direction: column;
  align-items: center;
  gap: 24px;
  display: inline-flex;
  position: relative;
  top: 85px;
  left: 41px;
}

.offerletter .text-wrapper-24 {
  color: #fff;
  letter-spacing: -.8px;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.4px;
  position: relative;
}

.offerletter .are-we-a-good-fit {
  color: #f6f7f8;
  letter-spacing: -.2px;
  text-align: center;
  width: 309px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.offerletter .CTA-4 {
  cursor: pointer;
  background-color: var(--secondary-02);
  box-sizing: border-box;
  border: none;
  outline: none;
  flex: none;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  display: inline-flex;
  position: relative;
}

.offerletter .text-wrapper-25 {
  color: #fff;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: relative;
}

.offerletter .arrow-forward-3 {
  width: 24px;
  height: 24px;
  position: relative;
}

.offerletter .frame-36 {
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 390px;
  height: 511px;
  padding: 40px 10px;
  display: flex;
  position: absolute;
  top: 634px;
  left: 0;
}

.offerletter .frame-37 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  width: 358px;
  margin-bottom: -40px;
  display: flex;
  position: relative;
}

.offerletter .frame-38 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 16px;
  width: 358px;
  display: flex;
  position: relative;
}

.offerletter .one-stop-centralized-2 {
  color: #2e5053;
  letter-spacing: -.24px;
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  position: relative;
}

.offerletter .frame-39 {
  width: 355px;
  height: 261px;
  position: relative;
}

.offerletter .partner-wrapper {
  background-size: 100% 100%;
  width: 390px;
  height: 575px;
  position: absolute;
  top: -200px;
  left: -16px;
}

.offerletter .partner-x {
  object-fit: cover;
  width: 254px;
  height: 238px;
  position: absolute;
  top: 200px;
  left: 67px;
}

.offerletter .ellipse-11 {
  filter: blur(57px);
  background-color: #d9d9d952;
  border-radius: 184px / 48.7px;
  width: 368px;
  height: 97px;
  position: absolute;
  top: 308px;
  left: 453px;
  transform: rotate(-5.02deg);
}

.offerletter .overlap-4 {
  width: 1440px;
  height: 1014px;
  position: absolute;
  top: 0;
  left: 0;
}

.offerletter .frame-40 {
  background-color: var(--neutral-90);
  width: 1440px;
  height: 925px;
  position: absolute;
  top: 89px;
  left: 0;
  overflow: hidden;
}

.offerletter .overlap-5 {
  width: 1068px;
  height: 998px;
  position: absolute;
  top: 0;
  left: 0;
}

.offerletter .rectangle-10 {
  width: 437px;
  height: 922px;
  position: absolute;
  top: 0;
  left: 0;
}

.offerletter .one-stop-solution-2 {
  color: #0000;
  letter-spacing: -.75px;
  text-align: center;
  width: 657px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 50px;
  font-weight: 600;
  line-height: 60px;
  position: absolute;
  top: 76px;
  left: 391px;
}

.offerletter .text-wrapper-26 {
  color: #e9e9e9;
}

.offerletter .the-offerletter {
  color: #0000;
  letter-spacing: -.3px;
  text-align: center;
  width: 603px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: absolute;
  top: 218px;
  left: 418px;
}

.offerletter .group-9 {
  width: 694px;
  height: 673px;
  position: absolute;
  top: 325px;
  left: 374px;
}

.offerletter .overlap-group-7 {
  height: 673px;
  position: relative;
}

.offerletter .mask-group-2 {
  width: 694px;
  height: 547px;
  position: absolute;
  top: 53px;
  left: 0;
}

.offerletter .smiling-confident-2 {
  object-fit: cover;
  width: 450px;
  height: 673px;
  position: absolute;
  top: 0;
  left: 156px;
}

.offerletter .rectangle-11 {
  width: 628px;
  height: 624px;
  position: absolute;
  top: 3069px;
  left: 1874px;
}

.offerletter .rectangle-12 {
  width: 564px;
  height: 426px;
  position: absolute;
  top: 89px;
  left: 876px;
}

.offerletter .header-2 {
  background-color: #f6f7f8;
  justify-content: space-between;
  align-items: center;
  width: 1440px;
  padding: 16px 80px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.offerletter .logo-2 {
  width: 116px;
  height: 56.67px;
  position: relative;
}

.offerletter .holder {
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.offerletter .frame-41 {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 90.33px;
  padding: 10px 8px;
  display: flex;
  position: relative;
}

.offerletter .text-wrapper-27 {
  color: #1f1f1f;
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.offerletter .text-wrapper-28 {
  color: #1f1f1f;
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-left: -2.83px;
  margin-right: -2.83px;
  position: relative;
}

.offerletter .frame-42 {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 80px;
  padding: 10px 8px;
  display: flex;
  position: relative;
}

.offerletter .CTA-5 {
  border-color: #4d9f98 !important;
  flex: none !important;
}

.offerletter .CTA-6 {
  color: #4d9f98 !important;
}

.offerletter .vector {
  width: 24px;
  height: 18px;
  position: absolute;
  top: 5737px;
  left: 1379px;
}

.offerletter .solutions {
  width: 1280px;
  height: 424px;
  position: absolute;
  top: 3709px;
  left: 63px;
}

.offerletter .frame-43 {
  width: 624px;
  height: 196px;
  position: absolute;
  top: 0;
  left: 0;
}

.offerletter .overlap-group-8 {
  height: 196px;
  position: relative;
}

.offerletter .rectangle-13 {
  background-color: var(--neutral-30);
  border-radius: 0 12px 12px 0;
  width: 328px;
  height: 196px;
  position: absolute;
  top: 0;
  left: 296px;
}

.offerletter .rectangle-14 {
  width: 296px;
  height: 196px;
  position: absolute;
  top: 0;
  left: 0;
}

.offerletter .frame-44 {
  flex-direction: column;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 65px;
  left: 67px;
}

.offerletter .text-wrapper-29 {
  color: var(--neutral-90);
  letter-spacing: -.24px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  position: relative;
}

.offerletter .text-wrapper-30 {
  color: var(--neutral-90);
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.offerletter .joulestowatts-offer {
  color: var(--neutral-90);
  letter-spacing: -.24px;
  text-align: center;
  height: 68px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  position: absolute;
  top: 63px;
  left: 361px;
}

.offerletter .ellipse-12 {
  background-color: var(--secondary-04);
  border-radius: 32px;
  width: 64px;
  height: 64px;
  position: absolute;
  top: 66px;
  left: 264px;
}

.offerletter .arrow-right {
  width: 26px;
  height: 26px;
  position: absolute;
  top: 85px;
  left: 283px;
}

.offerletter .frame-45 {
  width: 624px;
  height: 196px;
  position: absolute;
  top: 228px;
  left: 0;
}

.offerletter .rectangle-15 {
  background-color: #f6f7f8;
  border-radius: 12px 0 0 12px;
  width: 296px;
  height: 196px;
  position: absolute;
  top: 0;
  left: 0;
}

.offerletter .frame-46 {
  flex-direction: column;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 65px;
  left: 63px;
}

.offerletter .joulestowatts {
  color: var(--neutral-90);
  letter-spacing: -.24px;
  text-align: center;
  height: 68px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  position: absolute;
  top: 63px;
  left: 353px;
}

.offerletter .frame-47 {
  width: 624px;
  height: 196px;
  position: absolute;
  top: 0;
  left: 656px;
}

.offerletter .frame-48 {
  flex-direction: column;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 41px;
  left: 46px;
}

.offerletter .crowd-sourcing {
  color: var(--neutral-90);
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  text-align: center;
  width: fit-content;
  position: relative;
}

.offerletter .joulestowatts-offer-2 {
  color: var(--neutral-90);
  letter-spacing: -.24px;
  text-align: center;
  height: 68px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  position: absolute;
  top: 63px;
  left: 332px;
}

.offerletter .frame-49 {
  width: 624px;
  height: 196px;
  position: absolute;
  top: 228px;
  left: 656px;
}

.offerletter .frame-50 {
  flex-direction: column;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 48px;
  left: 54px;
}

.offerletter .talent-gap {
  color: var(--neutral-90);
  letter-spacing: -.24px;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  position: relative;
}

.offerletter .text-wrapper-31 {
  color: var(--neutral-90);
  letter-spacing: -.24px;
  text-align: center;
  white-space: nowrap;
  height: 34px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  position: absolute;
  top: 80px;
  left: 355px;
}

.offerletter .frame-51 {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 3498px;
  left: 285px;
}

.offerletter .text-wrapper-32 {
  color: var(--neutral-90);
  letter-spacing: -1.05px;
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 42px;
  font-weight: 400;
  line-height: 50.4px;
  position: relative;
}

.offerletter .text-wrapper-33 {
  color: var(--neutral-60);
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  text-align: center;
  width: 827px;
  position: relative;
}

.offerletter .overlap-6 {
  width: 1440px;
  height: 380px;
  position: absolute;
  top: 4556px;
  left: -1px;
}

.offerletter .group-10 {
  width: 1440px;
  height: 380px;
  position: absolute;
  top: 0;
  left: 0;
}

.offerletter .overlap-7 {
  height: 380px;
  position: relative;
}

.offerletter .frame-52 {
  background-color: var(--neutral-70);
  width: 1440px;
  height: 380px;
  position: absolute;
  top: 0;
  left: 0;
}

.offerletter .frame-53 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 32px;
  display: inline-flex;
  position: relative;
  top: 69px;
  left: 152px;
}

.offerletter .frame-54 {
  flex-direction: column;
  flex: none;
  align-items: flex-end;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.offerletter .text-wrapper-34 {
  color: var(--neutral-0);
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.offerletter .are-we-a-good-fit-2 {
  color: var(--neutral-10);
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  text-align: right;
  width: 429px;
  position: relative;
}

.offerletter .CTA-7 {
  all: unset;
  box-sizing: border-box;
  background-color: #2c3e47;
  flex: none;
  align-items: center;
  gap: 10px;
  padding: 12px 16px;
  display: inline-flex;
  position: relative;
}

.offerletter .text-wrapper-35 {
  color: var(--neutral-0);
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.offerletter .frame-55 {
  background-color: var(--secondary-03);
  width: 720px;
  height: 380px;
  position: absolute;
  top: 0;
  left: 0;
}

.offerletter .overlap-group-9 {
  background-color: var(--neutral-10);
  width: 720px;
  height: 380px;
  position: absolute;
  top: 0;
  left: 0;
}

.offerletter .frame-56 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 32px;
  display: inline-flex;
  position: relative;
  top: 49px;
  left: 128px;
}

.offerletter .text-wrapper-36 {
  color: #2c3e47;
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.offerletter .text-wrapper-37 {
  color: var(--neutral-80);
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  text-align: right;
  width: 500px;
  position: relative;
}

.offerletter .frame-57 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 52px;
  left: 817px;
}

.offerletter .frame-58 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.offerletter .text-wrapper-38 {
  color: var(--neutral-0);
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.offerletter .text-wrapper-39 {
  color: var(--neutral-10);
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  width: 429px;
  position: relative;
}

.offerletter .CTA-8 {
  cursor: pointer;
  background-color: var(--secondary-02);
  box-sizing: border-box;
  border: none;
  outline: none;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  display: inline-flex;
  position: absolute;
  top: 259px;
  left: 817px;
}

.offerletter .overlap-8 {
  background-color: #f6f7f8;
  position: absolute;
}

.offerletter .frame-59 {
  flex-direction: column;
  align-items: center;
  gap: 25px;
  display: inline-flex;
  position: relative;
  top: 103px;
  left: 161px;
}

.offerletter .mercedes-benz-logo-2 {
  object-fit: cover;
  width: 248px;
  height: 63px;
  position: relative;
}

.offerletter .GE-healthcare-logo-2 {
  object-fit: cover;
  width: 164px;
  height: 92px;
  position: relative;
}

.offerletter .sony-logo-2 {
  object-fit: cover;
  width: 123px;
  height: 22px;
  position: relative;
}

.offerletter .shell-logo-2 {
  object-fit: cover;
  width: 115px;
  height: 65px;
  position: relative;
}

.offerletter .amazon-2 {
  object-fit: cover;
  width: 178px;
  height: 55px;
  position: relative;
}

.offerletter .wipro-logo-new-og-2 {
  object-fit: cover;
  width: 171px;
  height: 89px;
  position: relative;
}

.offerletter .text-wrapper-40 {
  color: #2e5053;
  letter-spacing: -.36px;
  text-align: center;
  width: 288px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  position: absolute;
  top: 57px;
  left: 53px;
}

.offerletter .frame-60 {
  width: 361px;
  height: 361px;
  position: absolute;
  top: 174px;
  left: 14px;
}

.offerletter .overlap-9 {
  border-radius: 180.5px;
  height: 361px;
  position: relative;
}

.offerletter .ellipse-13 {
  border: .8px solid #64b4ad;
  border-radius: 112px / 111.5px;
  width: 224px;
  height: 223px;
  position: absolute;
  top: 69px;
  left: 68px;
}

.offerletter .ellipse-14 {
  border: .8px solid #64b4ad;
  border-radius: 149px / 148px;
  width: 298px;
  height: 296px;
  position: absolute;
  top: 33px;
  left: 31px;
}

.offerletter .ellipse-15 {
  border: .8px solid #64b4ad;
  border-radius: 180.5px;
  width: 361px;
  height: 361px;
  position: absolute;
  top: 0;
  left: 0;
}

.offerletter .group-11 {
  width: 304px;
  height: 180px;
  position: absolute;
  top: 91px;
  left: 30px;
}

.offerletter .overlap-group-10 {
  width: 318px;
  height: 193px;
  position: relative;
  top: -5px;
}

.offerletter .mask-group-3 {
  background-image: url("mask-group-8.3514a7f4.webp");
  background-size: 100% 100%;
  width: 230px;
  height: 144px;
  position: absolute;
  top: 14px;
  left: 35px;
  box-shadow: 5px 5px 25px #00000012;
}

.offerletter .kisspng-macbook-air-2 {
  object-fit: cover;
  width: 296px;
  height: 180px;
  position: absolute;
  top: 5px;
  left: 0;
}

.offerletter .image-7 {
  width: 147px;
  height: 193px;
  position: absolute;
  top: 0;
  left: 172px;
}

.offerletter .group-12 {
  background: linear-gradient(#2e5053 0%, #2c3e47 100%);
  border-radius: 74.93px;
  width: 150px;
  height: 150px;
  position: absolute;
  top: 567px;
  left: 25px;
  box-shadow: 0 3px 8.99px #7878781f;
}

.offerletter .frame-61 {
  flex-direction: column;
  align-items: center;
  gap: 8.99px;
  width: 105px;
  height: 88px;
  display: flex;
  position: relative;
  top: 22px;
  left: 22px;
}

.offerletter .frame-62 {
  width: 55.45px;
  height: 55.45px;
  position: relative;
}

.offerletter .image-8 {
  object-fit: cover;
  width: 81px;
  height: 79px;
  position: absolute;
  top: -4px;
  left: -12px;
}

.offerletter .encrypted-document-2 {
  color: #fff;
  letter-spacing: -.13px;
  text-align: center;
  width: 117.64px;
  margin-bottom: -12.21px;
  margin-left: -6.4px;
  margin-right: -6.4px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 12.7px;
  font-weight: 600;
  line-height: 17.8px;
  position: relative;
}

.offerletter .group-13 {
  background: linear-gradient(#2e5053 0%, #2c3e47 100%);
  border-radius: 74.93px;
  width: 150px;
  height: 150px;
  position: absolute;
  top: 749px;
  left: 25px;
  box-shadow: 0 3px 8.99px #7878781f;
}

.offerletter .frame-63 {
  flex-direction: column;
  align-items: center;
  gap: 11.99px;
  width: 124px;
  height: 97px;
  display: flex;
  position: relative;
  top: 23px;
  left: 13px;
}

.offerletter .frame-64 {
  width: 49.04px;
  height: 49.04px;
  position: relative;
}

.offerletter .image-9 {
  object-fit: cover;
  width: 72px;
  height: 72px;
  position: absolute;
  top: -11px;
  left: -12px;
}

.offerletter .text-wrapper-41 {
  color: #fff;
  letter-spacing: -.13px;
  text-align: center;
  width: 117.64px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 12.7px;
  font-weight: 600;
  line-height: 17.8px;
  position: relative;
}

.offerletter .group-14 {
  background: linear-gradient(#2e5053 0%, #2c3e47 100%);
  border-radius: 74.93px;
  width: 150px;
  height: 150px;
  position: absolute;
  top: 931px;
  left: 120px;
  box-shadow: 0 3px 8.99px #7878781f;
}

.offerletter .frame-65 {
  flex-direction: column;
  align-items: center;
  gap: 11.99px;
  width: 124px;
  height: 103px;
  display: flex;
  position: relative;
  top: 23px;
  left: 13px;
}

.offerletter .image-10 {
  object-fit: cover;
  width: 74px;
  height: 72px;
  position: absolute;
  top: -3px;
  left: -7px;
}

.offerletter .group-15 {
  background: linear-gradient(#2e5053 0%, #2c3e47 100%);
  border-radius: 74.93px;
  width: 150px;
  height: 150px;
  position: absolute;
  top: 749px;
  left: 217px;
  box-shadow: 0 3px 8.99px #7878781f;
}

.offerletter .frame-66 {
  flex-direction: column;
  align-items: center;
  gap: 8.99px;
  width: 105px;
  height: 102px;
  display: flex;
  position: relative;
  top: 24px;
  left: 22px;
}

.offerletter .image-11 {
  object-fit: cover;
  width: 63px;
  height: 61px;
  position: absolute;
  top: -3px;
  left: -3px;
}

.offerletter .flagship-referral-2 {
  color: #fff;
  letter-spacing: -.13px;
  text-align: center;
  width: fit-content;
  font-family: Source Sans Pro, Helvetica;
  font-size: 12.7px;
  font-weight: 600;
  line-height: 17.8px;
  position: relative;
}

.offerletter .group-16 {
  background: linear-gradient(#2e5053 0%, #2c3e47 100%);
  border-radius: 74.93px;
  width: 150px;
  height: 150px;
  position: absolute;
  top: 567px;
  left: 217px;
  box-shadow: 0 3px 8.99px #7878781f;
}

.offerletter .frame-67 {
  flex-direction: column;
  align-items: center;
  gap: 11.99px;
  width: 124px;
  height: 99px;
  display: flex;
  position: relative;
  top: 22px;
  left: 13px;
}

.offerletter .frame-68 {
  width: 51.29px;
  height: 51.29px;
  position: relative;
}

.offerletter .image-12 {
  object-fit: cover;
  width: 64px;
  height: 64px;
  position: absolute;
  top: -8px;
  left: -6px;
}

.offerletter .footer-2 {
  background-color: var(--white);
  width: 1441px;
  height: 292px;
  position: absolute;
  top: 4936px;
  left: 6px;
}

.offerletter .overlap-10 {
  width: 1441px;
  height: 292px;
  position: relative;
}

.offerletter .frame-69 {
  background-color: #1f1f1f;
  width: 1441px;
  height: 292px;
  position: absolute;
  top: 0;
  left: 0;
}

.offerletter .DIV-3 {
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 53px;
  left: 738px;
}

.offerletter .text-wrapper-42 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1233px;
}

.offerletter .text-wrapper-43 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1282px;
}

.offerletter .text-wrapper-44 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1331px;
}

.offerletter .text-wrapper-45 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1187px;
}

.offerletter .text-wrapper-46 {
  color: var(--white);
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  white-space: nowrap;
  height: 28px;
  position: absolute;
  top: 100px;
  left: 738px;
}

.offerletter .text-wrapper-47 {
  color: var(--white);
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  white-space: nowrap;
  height: 28px;
  position: absolute;
  top: 100px;
  left: 869px;
}

.offerletter .frame-70 {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: absolute;
  top: 115px;
  left: 81px;
}

.offerletter .group-17 {
  width: 37px;
  height: 52px;
  position: absolute;
  top: 152px;
  left: 1140px;
}

.offerletter .linkedin-2 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1187px;
}

.offerletter .facebook-2 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1234px;
}

.offerletter .facebook-3 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1281px;
}

.offerletter .facebook-4 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1328px;
}

.offerletter .group-18 {
  width: 400px;
  height: 17px;
  position: absolute;
  top: 242px;
  left: 501px;
}

.offerletter .frame-71 {
  justify-content: center;
  align-items: center;
  gap: 5px;
  display: inline-flex;
  position: relative;
}

.offerletter .text-wrapper-48 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.offerletter .ellipse-16 {
  background-color: var(--grey-01);
  border-radius: 1.5px;
  width: 3px;
  height: 3px;
  position: relative;
}

.offerletter .logo-3 {
  width: 116px;
  height: 56px;
  position: absolute;
  top: 39px;
  left: 76px;
}

.offerletter .group-19 {
  width: 342px;
  height: 1083px;
  position: absolute;
  top: 3141px;
  left: 26px;
}

.offerletter .text-wrapper-49 {
  color: var(--neutral-90);
  letter-spacing: -.8px;
  text-align: center;
  width: 328px;
  height: 76px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.4px;
  position: absolute;
  top: 0;
  left: 4px;
}

.offerletter .text-wrapper-50 {
  color: var(--neutral-60);
  letter-spacing: -.16px;
  text-align: center;
  width: 328px;
  height: 132px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  position: absolute;
  top: 92px;
  left: 4px;
}

.offerletter .solutions-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 251px;
  left: 0;
}

.offerletter .frame-72 {
  width: 338px;
  height: 196px;
  position: relative;
}

.offerletter .rectangle-16 {
  background-color: var(--neutral-30);
  border-radius: 0 12px 12px 0;
  width: 169px;
  height: 196px;
  position: absolute;
  top: 0;
  left: 169px;
}

.offerletter .rectangle-17 {
  width: 169px;
  height: 196px;
  position: absolute;
  top: 0;
  left: 0;
}

.offerletter .frame-73 {
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 109px;
  display: flex;
  position: absolute;
  top: 65px;
  left: 17px;
}

.offerletter .text-wrapper-51 {
  color: var(--neutral-90);
  letter-spacing: -.18px;
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 18px;
  font-weight: 600;
  line-height: 25.2px;
  position: relative;
}

.offerletter .regular-skill {
  color: var(--neutral-90);
  letter-spacing: -.12px;
  text-align: center;
  align-self: stretch;
  font-family: Source Sans Pro, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.offerletter .text-wrapper-52 {
  color: var(--neutral-90);
  letter-spacing: -.18px;
  text-align: center;
  width: 115px;
  height: 50px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 18px;
  font-weight: 600;
  line-height: 25.2px;
  position: absolute;
  top: 76px;
  left: 213px;
}

.offerletter .arrow-right-wrapper {
  background-color: var(--secondary-04);
  border-radius: 32px;
  width: 64px;
  height: 64px;
  position: absolute;
  top: 66px;
  left: 137px;
}

.offerletter .arrow-right-2 {
  width: 26px;
  height: 26px;
  position: absolute;
  top: 19px;
  left: 19px;
}

.offerletter .rectangle-18 {
  background-color: #f6f7f8;
  border-radius: 12px 0 0 12px;
  width: 169px;
  height: 196px;
  position: absolute;
  top: 0;
  left: 0;
}

.offerletter .frame-74 {
  flex-direction: column;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 65px;
  left: 21px;
}

.offerletter .text-wrapper-53 {
  color: var(--neutral-90);
  letter-spacing: -.18px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 18px;
  font-weight: 600;
  line-height: 25.2px;
  position: relative;
}

.offerletter .niche-skill {
  color: var(--neutral-90);
  letter-spacing: -.12px;
  text-align: center;
  width: 89px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.offerletter .text-wrapper-54 {
  color: var(--neutral-90);
  letter-spacing: -.18px;
  text-align: center;
  width: 126px;
  height: 50px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 18px;
  font-weight: 600;
  line-height: 25.2px;
  position: absolute;
  top: 72px;
  left: 201px;
}

.offerletter .frame-75 {
  background-color: var(--secondary-04);
  border-radius: 32px;
  width: 64px;
  height: 64px;
  position: absolute;
  top: 66px;
  left: 131px;
}

.offerletter .frame-76 {
  flex-direction: column;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 47px;
  left: 15px;
}

.offerletter .crowd-sourcing-2 {
  color: var(--neutral-90);
  letter-spacing: -.12px;
  text-align: center;
  width: 123px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.offerletter .joulestowatts-offer-3 {
  color: var(--neutral-90);
  letter-spacing: -.18px;
  text-align: center;
  width: 125px;
  height: 100px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 18px;
  font-weight: 600;
  line-height: 25.2px;
  position: absolute;
  top: 47px;
  left: 201px;
}

.offerletter .frame-77 {
  flex-direction: column;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 45px;
  left: 15px;
}

.offerletter .talent-gap-2 {
  color: var(--neutral-90);
  letter-spacing: -.18px;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 18px;
  font-weight: 600;
  line-height: 25.2px;
  position: relative;
}

.offerletter .text-wrapper-55 {
  color: var(--neutral-90);
  letter-spacing: -.12px;
  text-align: center;
  width: 108px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.offerletter .text-wrapper-56 {
  color: var(--neutral-90);
  letter-spacing: -.18px;
  text-align: center;
  width: 115px;
  height: 50px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 18px;
  font-weight: 600;
  line-height: 25.2px;
  position: absolute;
  top: 72px;
  left: 206px;
}

.offerletter .frame-78 {
  background-color: var(--neutral-90);
  border-radius: 32px;
  width: 64px;
  height: 64px;
  position: absolute;
  top: 66px;
  left: 137px;
}

.offerletter .carousel-3 {
  width: 390px;
  height: 796px;
  position: absolute;
  top: 2289px;
  left: 0;
  overflow: hidden;
}

.offerletter .frame-79 {
  width: 667px;
  height: 1425px;
  position: absolute;
  top: 0;
  left: 0;
}

.offerletter .overlap-group-11 {
  width: 687px;
  height: 923px;
  position: relative;
  top: -24px;
  left: -134px;
}

.offerletter .rectangle-19 {
  width: 390px;
  height: 485px;
  position: absolute;
  top: 24px;
  left: 134px;
}

.offerletter .frame-80 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  height: 261px;
  display: inline-flex;
  position: absolute;
  top: 166px;
  left: 214px;
}

.offerletter .rectangle-20 {
  width: 1000px;
  height: 401px;
  position: absolute;
  top: 419px;
  left: -464px;
}

.offerletter .rectangle-21 {
  filter: blur(40px);
  background: linear-gradient(90deg, #589993 5.95%, #4d9f98 20.62% 48.33%, #fcfcfc00 71.42%);
  width: 923px;
  height: 687px;
  position: absolute;
  top: 118px;
  left: -118px;
  transform: rotate(90deg);
}

.offerletter .frame-81 {
  flex-direction: column;
  align-items: flex-start;
  gap: 33px;
  display: inline-flex;
  position: absolute;
  top: 106px;
  left: 150px;
}

.offerletter .we-are-your-growth {
  color: #fff;
  letter-spacing: -.22px;
  width: 358px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 22px;
  font-weight: 600;
  line-height: 30.8px;
  position: relative;
}

.offerletter .CTA-9 {
  border: unset !important;
  border-color: unset !important;
  background-color: #2e5053 !important;
  flex: none !important;
  justify-content: center !important;
  align-items: flex-end !important;
  width: 127px !important;
  display: flex !important;
}

.offerletter .CTA-10 {
  font-family: var(--subtitle-BLD-font-family) !important;
  font-size: var(--subtitle-BLD-font-size) !important;
  font-style: var(--subtitle-BLD-font-style) !important;
  font-weight: var(--subtitle-BLD-font-weight) !important;
  letter-spacing: var(--subtitle-BLD-letter-spacing) !important;
  line-height: var(--subtitle-BLD-line-height) !important;
  margin-left: -2px !important;
}

.offerletter .CTA-11 {
  margin-right: -2px !important;
}

.offerletter .frame-82 {
  width: 1440px;
  height: 580px;
  position: absolute;
  top: 0;
  left: 1440px;
  overflow: hidden;
}

.offerletter .rectangle-22 {
  width: 1383px;
  height: 634px;
  position: absolute;
  top: -5053px;
  left: -514px;
}

.offerletter .overlap-11 {
  filter: blur(80px);
  background: linear-gradient(#000 0%, #071103 25%, #202020 44.79%, #2f2f2f 61.46%, #0000 100%);
  width: 1684px;
  height: 714px;
  position: absolute;
  top: -60px;
  left: -116px;
}

.offerletter .frame-83 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: absolute;
  top: 242px;
  left: 196px;
}

.offerletter .text-wrapper-57 {
  color: #fff;
  letter-spacing: -.53px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 600;
  line-height: 49px;
  position: relative;
}

.offerletter .our-client-and-2 {
  color: #f6f7f8;
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  width: 436px;
  margin-top: -1px;
  position: relative;
}

.offerletter .CTA-12 {
  all: unset;
  box-sizing: border-box;
  flex: none;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.offerletter .text-wrapper-58 {
  color: #fff;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 23.8px;
  position: relative;
}

.offerletter .arrow-forward-4 {
  width: 24px;
  height: 24px;
  margin-top: -5425px;
  margin-left: -848px;
  position: relative;
}

.offerletter .text-wrapper-59 {
  color: #fff;
  font-family: var(--h4-REG-font-family);
  font-size: var(--h4-REG-font-size);
  font-style: var(--h4-REG-font-style);
  font-weight: var(--h4-REG-font-weight);
  letter-spacing: var(--h4-REG-letter-spacing);
  line-height: var(--h4-REG-line-height);
  white-space: nowrap;
  height: 41px;
  position: absolute;
  top: 120px;
  left: 196px;
}

.offerletter .frame-84 {
  align-items: flex-start;
  gap: 5px;
  display: inline-flex;
  position: absolute;
  top: 560px;
  left: 196px;
}

.offerletter .ellipse-17 {
  background-color: #d9d9d952;
  border-radius: 5.5px;
  width: 11px;
  height: 11px;
  position: relative;
}

.offerletter .ellipse-18 {
  background-color: var(--neutral-30);
  border-radius: 5.5px;
  width: 11px;
  height: 11px;
  position: relative;
}

.offerletter .carousel-4 {
  margin-top: -5448px !important;
  margin-left: -2288px !important;
}

.state-default-wrapper .text-wrapper-4 {
  color: var(--secondary-03);
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: relative;
}

.lounge {
  background-color: #fff;
  flex-direction: row;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.lounge .div-2 {
  background-color: #fff;
  position: relative;
}

.lounge .header {
  width: 390px !important;
  height: 60px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.lounge .overlap-wrapper {
  background-color: #202020;
  width: 390px;
  height: 574px;
  position: absolute;
  top: 60px;
  left: 0;
  overflow: hidden;
}

.lounge .overlap {
  width: 374px;
  height: 617px;
  position: relative;
  left: 16px;
}

.lounge .frame-4 {
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 358px;
  padding: 0 54px;
  display: flex;
  position: absolute;
  top: 86px;
  left: 0;
}

.lounge .india-s-first-of-its {
  -webkit-text-stroke: 1px #000;
  color: #0000;
  letter-spacing: -.48px;
  text-align: center;
  width: 300px;
  margin-top: -2px;
  margin-left: -26px;
  margin-right: -24px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 600;
  line-height: 38.4px;
  position: relative;
}

.lounge .span {
  color: #f6f7f8;
}

.lounge .text-wrapper-5 {
  color: #00dfc2;
}

.lounge .premiere-lounge {
  color: var(--neutral-0);
  letter-spacing: -.16px;
  text-align: center;
  width: 282px;
  margin-left: -16px;
  margin-right: -16px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  position: relative;
}

.lounge .overlap-group-wrapper {
  width: 367px;
  height: 275px;
  margin-left: -58.5px;
  margin-right: -58.5px;
  position: relative;
}

.lounge .overlap-group-2 {
  width: 368px;
  height: 232px;
  position: relative;
}

.lounge .mask-group {
  width: 329px;
  height: 222px;
  position: absolute;
  top: 10px;
  left: 21px;
}

.lounge .smiley-businesswoman {
  width: 368px;
  height: 232px;
  position: absolute;
  top: 0;
  left: 0;
}

.lounge .rectangle-2 {
  width: 345px;
  height: 295px;
  position: absolute;
  top: 0;
  left: 29px;
}

.lounge .footer {
  background-color: var(--white);
  width: 390px;
  height: 860px;
  position: absolute;
  top: 6936px;
  left: 0;
  overflow: hidden;
}

.lounge .overlap-2 {
  width: 390px;
  height: 907px;
  position: relative;
}

.lounge .frame-5 {
  background-color: var(--neutral-90);
  width: 390px;
  height: 907px;
  position: absolute;
  top: 0;
  left: 0;
}

.lounge .frame-6 {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: absolute;
  top: 115px;
  left: 26px;
}

.lounge .text-wrapper-6 {
  color: #fff;
  font-family: var(--paragraph-ALCPS-font-family);
  font-size: var(--paragraph-ALCPS-font-size);
  font-style: var(--paragraph-ALCPS-font-style);
  font-weight: var(--paragraph-ALCPS-font-weight);
  letter-spacing: var(--paragraph-ALCPS-letter-spacing);
  line-height: var(--paragraph-ALCPS-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.lounge .p {
  color: #f6f7f8;
  font-family: var(--paragraph-REG-font-family);
  font-size: var(--paragraph-REG-font-size);
  font-style: var(--paragraph-REG-font-style);
  font-weight: var(--paragraph-REG-font-weight);
  letter-spacing: var(--paragraph-REG-letter-spacing);
  line-height: var(--paragraph-REG-line-height);
  width: 324px;
  position: relative;
}

.lounge .DIV {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 113px;
  display: flex;
  position: absolute;
  top: 272px;
  left: 195px;
}

.lounge .text-wrapper-7 {
  color: var(--white);
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.lounge .text-wrapper-8 {
  color: var(--white);
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.lounge .DIV-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 113px;
  display: flex;
  position: absolute;
  top: 272px;
  left: 26px;
}

.lounge .frame-7 {
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 583px;
  left: 69px;
}

.lounge .group {
  width: 37px;
  height: 52px;
  position: relative;
}

.lounge .text-wrapper-9 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 38px;
  left: 1px;
}

.lounge .face-book {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 0;
  left: 0;
}

.lounge .frame-8 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.lounge .linkedin {
  flex: none;
  position: relative;
}

.lounge .text-wrapper-10 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  width: fit-content;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: relative;
}

.lounge .facebook {
  width: 35px;
  height: 35px;
  position: relative;
}

.lounge .frame-9 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.lounge .logo {
  width: 116px;
  height: 56px;
  position: absolute;
  top: 39px;
  left: 24px;
}

.lounge .frame-wrapper {
  width: 295px;
  height: 64px;
  position: absolute;
  top: 732px;
  left: 48px;
}

.lounge .frame-10 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 295px;
  display: flex;
  position: relative;
}

.lounge .text-wrapper-11 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.lounge .ellipse-3 {
  background-color: var(--grey-01);
  border-radius: 1px / 1.5px;
  width: 2px;
  height: 3px;
  position: relative;
}

.lounge .text-wrapper-12 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  text-align: center;
  align-self: stretch;
  position: relative;
}

.lounge .overlap-3 {
  position: absolute;
}

.lounge .rectangle-3 {
  background-color: #020f0e;
  width: 1440px;
  height: 661px;
  position: absolute;
  top: 88px;
  left: 0;
}

.lounge .rectangle-4 {
  width: 564px;
  height: 426px;
  position: absolute;
  top: 89px;
  left: 876px;
}

.lounge .mike-u {
  width: 1440px;
  height: 544px;
  position: absolute;
  top: 747px;
  left: 0;
}

.lounge .india-s-first-of-its-2 {
  -webkit-text-stroke: 1px #000;
  color: #0000;
  letter-spacing: -.75px;
  width: 484px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 50px;
  font-weight: 600;
  line-height: 60px;
  position: absolute;
  top: 263px;
  left: 79px;
}

.lounge .premiere-lounge-2 {
  color: #f6f7f8;
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  width: 490px;
  position: absolute;
  top: 461px;
  left: 80px;
}

.lounge .context {
  flex-direction: column;
  align-items: flex-start;
  gap: 41px;
  width: 680px;
  display: flex;
  position: absolute;
  top: 911px;
  left: 627px;
}

.lounge .with-a-dedicated {
  color: #f6f7f8;
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.lounge .CTA-instance {
  border: unset !important;
  border-color: unset !important;
  background-color: #4d9f98 !important;
  justify-content: center !important;
  align-items: flex-end !important;
  width: 182px !important;
  display: flex !important;
  position: absolute !important;
  top: 572px !important;
  left: 80px !important;
}

.lounge .instance-node {
  font-family: var(--subtitle-BLD-font-family) !important;
  font-size: var(--subtitle-BLD-font-size) !important;
  font-style: var(--subtitle-BLD-font-style) !important;
  font-weight: var(--subtitle-BLD-font-weight) !important;
  letter-spacing: var(--subtitle-BLD-letter-spacing) !important;
  line-height: var(--subtitle-BLD-line-height) !important;
  margin-left: -1px !important;
}

.lounge .CTA-2 {
  margin-right: -1px !important;
}

.lounge .mask-group-2 {
  width: 694px;
  height: 560px;
  position: absolute;
  top: 182px;
  left: 677px;
}

.lounge .smiley-businesswoman-2 {
  width: 807px;
  height: 581px;
  position: absolute;
  top: 161px;
  left: 633px;
}

.lounge .header-2 {
  background-color: #f6f7f8;
  justify-content: space-between;
  align-items: center;
  width: 1440px;
  padding: 16px 80px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.lounge .logo-2 {
  width: 116px;
  height: 56.67px;
  position: relative;
}

.lounge .holder {
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.lounge .div-wrapper {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 90.33px;
  padding: 10px 8px;
  display: flex;
  position: relative;
}

.lounge .text-wrapper-13 {
  color: var(--neutral-90);
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: relative;
}

.lounge .text-wrapper-14 {
  color: var(--neutral-90);
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-left: -2.83px;
  margin-right: -2.83px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: relative;
}

.lounge .frame-11 {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 80px;
  padding: 10px 8px;
  display: flex;
  position: relative;
}

.lounge .CTA-3 {
  border-color: var(--primary-02) !important;
  flex: none !important;
}

.lounge .CTA-4 {
  color: var(--primary-02) !important;
}

.lounge .frame-12 {
  width: 742px;
  height: 307px;
  position: absolute;
  top: 0;
  left: 0;
}

.lounge .overlap-group-3 {
  height: 307px;
  position: relative;
}

.lounge .rectangle-5 {
  background-color: #f6f7f8;
  width: 390px;
  height: 307px;
  position: absolute;
  top: 0;
  left: 185px;
}

.lounge .frame-13 {
  flex-direction: column;
  align-items: center;
  gap: 25px;
  display: inline-flex;
  position: absolute;
  top: 76px;
  left: 0;
}

.lounge .frame-14 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 51px;
  display: inline-flex;
  position: relative;
}

.lounge .frame-15 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 26px;
  display: inline-flex;
  position: relative;
}

.lounge .frame-16 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.lounge .frame-17 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.lounge .text-wrapper-15 {
  color: var(--neutral-60);
  letter-spacing: -.2px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  position: relative;
}

.lounge .frame-18 {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 29px;
  display: inline-flex;
  position: relative;
}

.lounge .mercedes-benz-logo {
  object-fit: cover;
  width: 148px;
  height: 37px;
  position: relative;
}

.lounge .GE-healthcare-logo {
  object-fit: cover;
  width: 98px;
  height: 55px;
  position: relative;
}

.lounge .sony-logo {
  object-fit: cover;
  width: 74px;
  height: 13px;
  position: relative;
}

.lounge .shell-logo {
  object-fit: cover;
  width: 69px;
  height: 39px;
  position: relative;
}

.lounge .amazon {
  object-fit: cover;
  width: 70px;
  height: 33px;
  position: relative;
}

.lounge .wipro-logo-new-og {
  object-fit: cover;
  width: 102px;
  height: 53px;
  position: relative;
}

.lounge .frame-19 {
  flex-direction: column;
  align-items: center;
  gap: 92px;
  display: inline-flex;
  position: absolute;
  top: 366px;
  left: 185px;
}

.lounge .frame-20 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-end;
  gap: 32px;
  width: 328px;
  display: flex;
  position: relative;
}

.lounge .frame-21 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 24px;
  width: 328px;
  display: flex;
  position: relative;
}

.lounge .text-wrapper-16 {
  color: var(--secondary-04);
  letter-spacing: -.8px;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.4px;
  position: relative;
}

.lounge .text-wrapper-17 {
  color: var(--neutral-80);
  letter-spacing: -.24px;
  text-align: center;
  width: 297px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
  position: relative;
}

.lounge .group-2 {
  width: 390px;
  height: 360px;
  position: relative;
}

.lounge .frame-22 {
  height: 360px;
}

.lounge .overlap-group-4 {
  background-color: var(--neutral-70);
  width: 1440px;
  height: 360px;
}

.lounge .frame-23 {
  flex-direction: column;
  align-items: center;
  gap: 24px;
  display: inline-flex;
  position: relative;
  top: 85px;
  left: 41px;
}

.lounge .text-wrapper-18 {
  color: var(--neutral-0);
  letter-spacing: -.8px;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.4px;
  position: relative;
}

.lounge .are-we-a-good-fit {
  color: #f6f7f8;
  letter-spacing: -.2px;
  text-align: center;
  width: 309px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.lounge .CTA-5 {
  cursor: pointer;
  background-color: var(--secondary-02);
  box-sizing: border-box;
  border: none;
  outline: none;
  flex: none;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  display: inline-flex;
  position: relative;
}

.lounge .text-wrapper-19 {
  color: var(--neutral-0);
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.lounge .arrow-forward-2 {
  width: 24px;
  height: 24px;
  position: relative;
}

.lounge .overlap-4 {
  position: absolute;
}

.lounge .carousel-2 {
  width: 1444px;
  height: 488px;
  position: absolute;
  top: 829px;
  left: 0;
  overflow: hidden;
}

.lounge .frame-24 {
  width: 1444px;
  height: 488px;
  position: absolute;
  top: 0;
  left: 0;
}

.lounge .overlap-group-5 {
  width: 1776px;
  height: 742px;
  position: relative;
  top: -58px;
  left: -186px;
}

.lounge .pexels-pavel {
  object-fit: cover;
  width: 1096px;
  height: 731px;
  position: absolute;
  top: 11px;
  left: 585px;
}

.lounge .rectangle-6 {
  filter: blur(40px);
  background: linear-gradient(90deg, #589993 5.95%, #4d9f98 20.62% 31.87%, #597274 50.62%, #fcfcfc00 71.42%);
  width: 1776px;
  height: 697px;
  position: absolute;
  top: 0;
  left: 0;
}

.lounge .joulestowatt-s {
  color: #f6f7f8;
  font-family: var(--h3-REG-font-family);
  font-size: var(--h3-REG-font-size);
  font-style: var(--h3-REG-font-style);
  font-weight: var(--h3-REG-font-weight);
  letter-spacing: var(--h3-REG-letter-spacing);
  line-height: var(--h3-REG-line-height);
  width: 617px;
  height: 245px;
  position: absolute;
  top: 168px;
  left: 277px;
}

.lounge .carousel-03 {
  position: absolute !important;
  top: 0 !important;
  left: 2880px !important;
}

.lounge .carousel-instance {
  margin-top: -711px !important;
  margin-left: -3898px !important;
}

.lounge .rectangle-7 {
  position: absolute;
  top: 0;
}

.lounge .ellipse-4 {
  background-color: #d9d9d9;
  border-radius: 207px;
  width: 414px;
  height: 414px;
  position: absolute;
  top: 227px;
  left: 505px;
}

.lounge .frame-25 {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 39px;
  left: 350px;
}

.lounge .text-wrapper-20 {
  color: #1f1f1f;
  letter-spacing: -1.05px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 42px;
  font-weight: 400;
  line-height: 50.4px;
  position: relative;
}

.lounge .logo-wrapper {
  background-color: #fff;
  border-radius: 103px;
  width: 206px;
  height: 206px;
  position: absolute;
  top: 326px;
  left: 608px;
  box-shadow: 0 4px 28px #00dfc23d;
}

.lounge .logo-3 {
  width: 159px;
  height: 73px;
  position: absolute;
  top: 71px;
  left: 29px;
}

.lounge .frame-26 {
  width: 481px;
  height: 127px;
  position: absolute;
  top: 182px;
  left: 805px;
}

.lounge .frame-27 {
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 24px;
  left: 148px;
}

.lounge .text-wrapper-21 {
  color: #1f1f1f;
  letter-spacing: -.22px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 22px;
  font-weight: 600;
  line-height: 30.8px;
  position: relative;
}

.lounge .text-wrapper-22 {
  color: var(--neutral-70);
  font-family: var(--paragraph-REG-font-family);
  font-size: var(--paragraph-REG-font-size);
  font-style: var(--paragraph-REG-font-style);
  font-weight: var(--paragraph-REG-font-weight);
  letter-spacing: var(--paragraph-REG-letter-spacing);
  line-height: var(--paragraph-REG-line-height);
  width: 333px;
  position: relative;
}

.lounge .text-wrapper-23 {
  color: #acafaf;
  letter-spacing: -1.62px;
  opacity: .79;
  text-align: right;
  white-space: nowrap;
  height: 78px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 65px;
  font-weight: 900;
  line-height: 78px;
  position: absolute;
  top: -28px;
  left: 266px;
}

.lounge .frame-28 {
  background-color: #2c3e47;
  border-radius: 10.58px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8.73px;
  padding: 4.36px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 8px 6px 32px #0000001f;
}

.lounge .rectangle-8 {
  background-color: #2c3e47;
  border-radius: 10.58px;
  width: 119.05px;
  height: 118.44px;
  position: relative;
}

.lounge .suitcase {
  width: 63px;
  height: 63px;
  position: absolute;
  top: 32px;
  left: 32px;
}

.lounge .frame-29 {
  width: 481px;
  height: 130px;
  position: absolute;
  top: 363px;
  left: 908px;
}

.lounge .frame-30 {
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 148px;
}

.lounge .post-your-project {
  color: #1f1f1f;
  letter-spacing: -.22px;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 22px;
  font-weight: 600;
  line-height: 30.8px;
  position: relative;
}

.lounge .text-wrapper-24 {
  color: var(--neutral-70);
  font-family: var(--paragraph-REG-font-family);
  font-size: var(--paragraph-REG-font-size);
  font-style: var(--paragraph-REG-font-style);
  font-weight: var(--paragraph-REG-font-weight);
  letter-spacing: var(--paragraph-REG-letter-spacing);
  line-height: var(--paragraph-REG-line-height);
  width: 282px;
  position: relative;
}

.lounge .text-wrapper-25 {
  color: #000;
  letter-spacing: -1.62px;
  opacity: .25;
  text-align: right;
  white-space: nowrap;
  height: 78px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 65px;
  font-weight: 900;
  line-height: 78px;
  position: absolute;
  top: -9px;
  left: 198px;
}

.lounge .frame-31 {
  background-color: #2c3e47;
  border-radius: 10.58px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8.73px;
  padding: 4.36px;
  display: inline-flex;
  position: absolute;
  top: 1px;
  left: 0;
  box-shadow: 8px 6px 32px #0000001f;
}

.lounge .blueprint {
  width: 57px;
  height: 57px;
  position: absolute;
  top: 35px;
  left: 35px;
}

.lounge .frame-32 {
  width: 481px;
  height: 127px;
  position: absolute;
  top: 559px;
  left: 807px;
}

.lounge .frame-33 {
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 14px;
  left: 148px;
}

.lounge .text-wrapper-26 {
  color: #000;
  letter-spacing: -1.62px;
  opacity: .25;
  text-align: right;
  white-space: nowrap;
  height: 78px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 65px;
  font-weight: 900;
  line-height: 78px;
  position: absolute;
  top: -30px;
  left: 282px;
}

.lounge .frame-34 {
  background-color: #2c3e47;
  border-radius: 10.58px;
  width: 128px;
  height: 127px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 8px 6px 32px #0000001f;
}

.lounge .consultant-wrapper {
  background-color: #2c3e47;
  border-radius: 10.58px;
  width: 119px;
  height: 118px;
  position: relative;
  top: 4px;
  left: 4px;
}

.lounge .consultant {
  width: 64px;
  height: 64px;
  position: absolute;
  top: 27px;
  left: 28px;
}

.lounge .frame-35 {
  width: 481px;
  height: 127px;
  position: absolute;
  top: 561px;
  left: 119px;
}

.lounge .frame-36 {
  height: 127px;
  position: relative;
}

.lounge .frame-37 {
  background-color: #2c3e47;
  border-radius: 10.58px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8.73px;
  padding: 4.36px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 353px;
  box-shadow: 8px 6px 32px #0000001f;
}

.lounge .candidate {
  width: 67px;
  height: 67px;
  position: absolute;
  top: 30px;
  left: 31px;
}

.lounge .frame-38 {
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 9px;
  left: 0;
}

.lounge .text-wrapper-27 {
  color: #1f1f1f;
  letter-spacing: -.22px;
  text-align: right;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 22px;
  font-weight: 600;
  line-height: 30.8px;
  position: relative;
}

.lounge .text-wrapper-28 {
  color: var(--neutral-70);
  letter-spacing: -.14px;
  text-align: right;
  width: 333px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  position: relative;
}

.lounge .text-wrapper-29 {
  color: #000;
  letter-spacing: -1.62px;
  opacity: .25;
  text-align: right;
  white-space: nowrap;
  height: 78px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 65px;
  font-weight: 900;
  line-height: 78px;
  position: absolute;
  top: -13px;
  left: 10px;
}

.lounge .frame-39 {
  width: 405px;
  height: 130px;
  position: absolute;
  top: 363px;
  left: 109px;
}

.lounge .frame-40 {
  background-color: #2c3e47;
  border-radius: 10.58px;
  width: 128px;
  height: 127px;
  position: absolute;
  top: 3px;
  left: 277px;
  box-shadow: 8px 6px 32px #0000001f;
}

.lounge .clipboard-wrapper {
  background-color: #2c3e47;
  border-radius: 10.58px;
  width: 119px;
  height: 118px;
  position: relative;
  top: 4px;
  left: 5px;
}

.lounge .clipboard {
  width: 59px;
  height: 59px;
  position: absolute;
  top: 30px;
  left: 30px;
}

.lounge .frame-41 {
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  width: 257px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.lounge .text-wrapper-30 {
  color: #1f1f1f;
  letter-spacing: -.22px;
  text-align: right;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 22px;
  font-weight: 600;
  line-height: 30.8px;
  position: relative;
}

.lounge .text-wrapper-31 {
  color: var(--neutral-70);
  font-family: var(--paragraph-REG-font-family);
  font-size: var(--paragraph-REG-font-size);
  font-style: var(--paragraph-REG-font-style);
  font-weight: var(--paragraph-REG-font-weight);
  letter-spacing: var(--paragraph-REG-letter-spacing);
  line-height: var(--paragraph-REG-line-height);
  text-align: right;
  align-self: stretch;
  position: relative;
}

.lounge .text-wrapper-32 {
  color: #000;
  letter-spacing: -1.62px;
  opacity: .25;
  text-align: right;
  white-space: nowrap;
  height: 78px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 65px;
  font-weight: 900;
  line-height: 78px;
  position: absolute;
  top: -9px;
  left: 0;
}

.lounge .frame-42 {
  width: 438px;
  height: 127px;
  position: absolute;
  top: 182px;
  left: 166px;
}

.lounge .frame-43 {
  background-color: #2c3e47;
  border-radius: 10.58px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8.73px;
  padding: 4.36px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 310px;
  box-shadow: 8px 6px 32px #0000001f;
}

.lounge .dollar {
  width: 53px;
  height: 53px;
  position: absolute;
  top: 37px;
  left: 37px;
}

.lounge .frame-44 {
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  width: 290px;
  display: flex;
  position: absolute;
  top: 9px;
  left: 0;
}

.lounge .simplified-global {
  color: var(--neutral-70);
  font-family: var(--paragraph-REG-font-family);
  font-size: var(--paragraph-REG-font-size);
  font-style: var(--paragraph-REG-font-style);
  font-weight: var(--paragraph-REG-font-weight);
  letter-spacing: var(--paragraph-REG-letter-spacing);
  line-height: var(--paragraph-REG-line-height);
  text-align: right;
  width: 333px;
  margin-left: -43px;
  position: relative;
}

.lounge .text-wrapper-33 {
  color: #000;
  letter-spacing: -1.62px;
  opacity: .25;
  text-align: right;
  white-space: nowrap;
  height: 78px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 65px;
  font-weight: 900;
  line-height: 78px;
  position: absolute;
  top: -17px;
  left: -8px;
}

.lounge .an-AI-driven {
  color: #1f1f1f;
  letter-spacing: -.16px;
  text-align: center;
  width: 344px;
  height: 66px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  position: absolute;
  top: 680px;
  left: 18px;
}

.lounge .frame-45 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 18px;
  display: inline-flex;
  position: absolute;
  top: 56px;
  left: 41px;
}

.lounge .text-wrapper-34 {
  color: #2e5053;
  letter-spacing: -.32px;
  text-align: center;
  width: 306px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 600;
  line-height: 44.8px;
  position: relative;
}

.lounge .frame-46 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
  display: flex;
  position: relative;
}

.lounge .frame-47 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 4px;
  width: 100%;
  display: flex;
  position: relative;
}

.lounge .text-wrapper-35 {
  color: #999;
  letter-spacing: -.6px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  position: relative;
}

.lounge .text-wrapper-36 {
  color: var(--neutral-70);
  white-space: nowrap;
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  width: 140px;
  height: 48px;
  position: relative;
}

.lounge .text-wrapper-37 {
  color: #999;
  letter-spacing: -.6px;
  text-align: center;
  width: 107px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  position: relative;
}

.lounge .text-wrapper-38 {
  white-space: nowrap;
  color: var(--neutral-70);
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  width: 95px;
  position: relative;
}

.lounge .frame-48 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.lounge .text-wrapper-39 {
  color: var(--neutral-70);
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  width: 193px;
  height: 29px;
  position: relative;
}

.lounge .text-wrapper-40 {
  color: #2e5053;
  letter-spacing: -.32px;
  text-align: center;
  width: 306px;
  height: 90px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 600;
  line-height: 44.8px;
  position: absolute;
  top: 572px;
  left: 41px;
}

.lounge .frame-49 {
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  display: inline-flex;
  position: absolute;
  top: 779px;
  left: 0;
}

.lounge .frame-50 {
  background-color: #d3d3d3;
  background-image: linear-gradient(0deg, #0c2422 0%, #0000 100%), url("rectangle-91.e8b43c51.webp"), none;
  background-position: 50%;
  background-repeat: repeat, repeat, no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll, scroll;
  background-origin: padding-box, padding-box, padding-box;
  background-clip: border-box, border-box, border-box;
  width: 392px;
  height: 355.61px;
  position: relative;
}

.lounge .frame-51 {
  flex-direction: column;
  align-items: center;
  gap: 9.92px;
  display: inline-flex;
  position: relative;
  top: 178px;
  left: 59px;
}

.lounge .div-3 {
  color: var(--neutral-0);
  letter-spacing: -.32px;
  text-align: center;
  width: fit-content;
  margin-top: -.83px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 44.8px;
  position: relative;
}

.lounge .text-wrapper-41 {
  font-weight: 700;
}

.lounge .text-wrapper-42 {
  letter-spacing: -.18px;
  font-size: 18px;
  font-weight: 700;
  line-height: 25.2px;
}

.lounge .text-wrapper-43 {
  color: var(--neutral-0);
  letter-spacing: -.16px;
  text-align: center;
  width: 275.39px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  position: relative;
}

.lounge .frame-52 {
  background-color: #d3d3d3;
  background-image: linear-gradient(0deg, #0c2422 0%, #0000 100%), url("rectangle-92.19eb58e1.webp"), none;
  background-position: 50%;
  background-repeat: repeat, repeat, no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll, scroll;
  background-origin: padding-box, padding-box, padding-box;
  background-clip: border-box, border-box, border-box;
  width: 392px;
  height: 355.61px;
  position: relative;
}

.lounge .frame-53 {
  flex-direction: column;
  align-items: center;
  gap: 9.92px;
  display: inline-flex;
  position: relative;
  top: 178px;
  left: 58px;
}

.lounge .frame-54 {
  background-color: #d3d3d3;
  background-image: linear-gradient(0deg, #0c2422 0%, #0000 100%), url("rectangle-93.bc772acc.webp"), none;
  background-position: 50%;
  background-repeat: repeat, repeat, no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll, scroll;
  background-origin: padding-box, padding-box, padding-box;
  background-clip: border-box, border-box, border-box;
  width: 392px;
  height: 355.61px;
  position: relative;
}

.lounge .context-wrapper {
  background-image: url("mike-u-jujypgv4rag-unsplash-1.0b4a1218.webp");
  background-size: 100% 100%;
  width: 390px;
  height: 544px;
  position: absolute;
  top: 634px;
  left: 0;
}

.lounge .with-a-dedicated-wrapper {
  flex-direction: column;
  align-items: flex-start;
  gap: 41px;
  width: 252px;
  display: flex;
  position: relative;
  top: 62px;
  left: 66px;
}

.lounge .with-a-dedicated-2 {
  color: #f6f7f8;
  letter-spacing: -.2px;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.lounge .frame-55 {
  background-color: #d3d3d3;
  background-image: linear-gradient(0deg, #0c2422 0%, #0000 100%), url("rectangle-91.e8b43c51.webp"), none;
  background-position: 50%;
  background-repeat: repeat, repeat, no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll, scroll;
  background-origin: padding-box, padding-box, padding-box;
  background-clip: border-box, border-box, border-box;
  width: 474px;
  height: 430px;
  position: absolute;
  top: 1835px;
  left: -3px;
}

.lounge .frame-56 {
  flex-direction: column;
  align-items: center;
  gap: 12px;
  display: inline-flex;
  position: relative;
  top: 215px;
  left: 71px;
}

.lounge .div-4 {
  color: var(--neutral-0);
  letter-spacing: -.42px;
  text-align: center;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 42px;
  font-weight: 400;
  line-height: 58.8px;
  position: relative;
}

.lounge .text-wrapper-44 {
  letter-spacing: -.24px;
  font-size: 24px;
  font-weight: 700;
  line-height: 33.6px;
}

.lounge .text-wrapper-45 {
  color: var(--neutral-0);
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  text-align: center;
  width: 333px;
  position: relative;
}

.lounge .frame-57 {
  background-color: #d3d3d3;
  background-image: linear-gradient(0deg, #0c2422 0%, #0000 100%), url("rectangle-92.19eb58e1.webp"), none;
  background-position: 50%;
  background-repeat: repeat, repeat, no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll, scroll;
  background-origin: padding-box, padding-box, padding-box;
  background-clip: border-box, border-box, border-box;
  width: 474px;
  height: 430px;
  position: absolute;
  top: 1835px;
  left: 480px;
}

.lounge .frame-58 {
  flex-direction: column;
  align-items: center;
  gap: 12px;
  display: inline-flex;
  position: relative;
  top: 215px;
  left: 70px;
}

.lounge .frame-59 {
  background-color: #d3d3d3;
  background-image: linear-gradient(0deg, #0c2422 0%, #0000 100%), url("rectangle-93.bc772acc.webp"), none;
  background-position: 50%;
  background-repeat: repeat, repeat, no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll, scroll;
  background-origin: padding-box, padding-box, padding-box;
  background-clip: border-box, border-box, border-box;
  width: 474px;
  height: 430px;
  position: absolute;
  top: 1834px;
  left: 963px;
}

.lounge .frame-60 {
  width: 876px;
  height: 138px;
  position: absolute;
  top: 1638px;
  left: 279px;
}

.lounge .text-wrapper-46 {
  color: #1f1f1f;
  letter-spacing: -1.05px;
  text-align: center;
  width: 876px;
  height: 50px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 42px;
  font-weight: 600;
  line-height: 50.4px;
  position: absolute;
  top: -6px;
  left: 0;
}

.lounge .an-AI-driven-2 {
  color: #1f1f1f;
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  text-align: center;
  width: 876px;
  height: 48px;
  position: absolute;
  top: 77px;
  left: 0;
}

.lounge .frame-61 {
  flex-direction: column;
  align-items: flex-start;
  display: inline-flex;
  position: absolute;
}

.lounge .text-wrapper-47 {
  color: #1f1f1f;
  letter-spacing: -1.05px;
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 42px;
  font-weight: 400;
  line-height: 50.4px;
  position: relative;
}

.lounge .text-wrapper-48 {
  color: var(--neutral-60);
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  text-align: center;
  width: 827px;
  position: relative;
}

.lounge .frame-62 {
  width: 347.87px;
  height: 92.01px;
  position: relative;
}

.lounge .frame-63 {
  flex-direction: column;
  align-items: flex-start;
  gap: 5.79px;
  display: inline-flex;
  position: absolute;
  top: 17px;
  left: 108px;
}

.lounge .text-wrapper-49 {
  color: #1f1f1f;
  letter-spacing: -.16px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -.72px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 15.9px;
  font-weight: 600;
  line-height: 22.3px;
  position: relative;
}

.lounge .easily-find-quality {
  color: var(--neutral-70);
  letter-spacing: -.1px;
  width: 240.95px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10.1px;
  font-weight: 400;
  line-height: 14.2px;
  position: relative;
}

.lounge .frame-64 {
  background-color: #2c3e47;
  border-radius: 7.66px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6.31px;
  padding: 3.16px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 5.79px 4.34px 23.15px #0000001f;
}

.lounge .rectangle-9 {
  background-color: #2c3e47;
  border-radius: 7.66px;
  width: 86.14px;
  height: 85.7px;
  position: relative;
}

.lounge .suitcase-2 {
  width: 46px;
  height: 46px;
  position: absolute;
  top: 23px;
  left: 23px;
}

.lounge .frame-65 {
  width: 348px;
  height: 93px;
  position: relative;
}

.lounge .frame-66 {
  flex-direction: column;
  align-items: flex-start;
  gap: 5.79px;
  width: 241px;
  display: flex;
  position: absolute;
  top: 0;
  left: 108px;
}

.lounge .post-your-project-2 {
  color: #1f1f1f;
  letter-spacing: -.16px;
  width: fit-content;
  margin-top: -.72px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 15.9px;
  font-weight: 600;
  line-height: 22.3px;
  position: relative;
}

.lounge .text-wrapper-50 {
  color: var(--neutral-70);
  letter-spacing: -.1px;
  width: 241px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10.1px;
  font-weight: 400;
  line-height: 14.2px;
  position: relative;
}

.lounge .frame-67 {
  background-color: #2c3e47;
  border-radius: 7.66px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6.31px;
  padding: 3.16px;
  display: inline-flex;
  position: absolute;
  top: 1px;
  left: 0;
  box-shadow: 5.79px 4.34px 23.15px #0000001f;
}

.lounge .blueprint-2 {
  width: 41px;
  height: 41px;
  position: absolute;
  top: 25px;
  left: 25px;
}

.lounge .frame-68 {
  width: 347.78px;
  height: 91.89px;
  position: relative;
}

.lounge .frame-69 {
  flex-direction: column;
  align-items: flex-start;
  gap: 5.79px;
  width: 241px;
  display: flex;
  position: absolute;
  top: 3px;
  left: 108px;
}

.lounge .text-wrapper-51 {
  color: #1f1f1f;
  letter-spacing: -.16px;
  width: 162px;
  margin-top: -.72px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 15.9px;
  font-weight: 600;
  line-height: 22.3px;
  position: relative;
}

.lounge .frame-70 {
  background-color: #2c3e47;
  border-radius: 7.66px;
  width: 92px;
  height: 92px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 5.79px 4.34px 23.15px #0000001f;
}

.lounge .img-wrapper {
  background-color: #2c3e47;
  border-radius: 7.66px;
  width: 86px;
  height: 86px;
  position: relative;
  top: 3px;
  left: 3px;
}

.lounge .consultant-2 {
  width: 46px;
  height: 46px;
  position: absolute;
  top: 20px;
  left: 20px;
}

.lounge .frame-71 {
  width: 349px;
  height: 92px;
  position: relative;
}

.lounge .frame-72 {
  width: 323px;
  height: 92px;
  position: relative;
}

.lounge .candidate-2 {
  width: 48px;
  height: 48px;
  position: absolute;
  top: 22px;
  left: 22px;
}

.lounge .frame-73 {
  flex-direction: column;
  align-items: flex-end;
  gap: 5.79px;
  width: 210px;
  display: flex;
  position: absolute;
  top: -2px;
  left: 108px;
}

.lounge .text-wrapper-52 {
  color: #1f1f1f;
  letter-spacing: -.16px;
  align-self: stretch;
  margin-top: -.72px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 15.9px;
  font-weight: 600;
  line-height: 22.3px;
  position: relative;
}

.lounge .text-wrapper-53 {
  color: var(--neutral-70);
  letter-spacing: -.1px;
  align-self: stretch;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10.1px;
  font-weight: 400;
  line-height: 14.2px;
  position: relative;
}

.lounge .frame-74 {
  width: 348px;
  height: 92px;
  position: relative;
}

.lounge .clipboard-2 {
  width: 43px;
  height: 43px;
  position: absolute;
  top: 22px;
  left: 22px;
}

.lounge .frame-75 {
  flex-direction: column;
  align-items: flex-end;
  gap: 5.79px;
  width: 209px;
  display: flex;
  position: absolute;
  top: 10px;
  left: 108px;
}

.lounge .frame-76 {
  width: 348px;
  height: 91px;
  position: relative;
}

.lounge .frame-77 {
  background-color: #2c3e47;
  border-radius: 7.66px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6.31px;
  padding: 3.16px;
  display: inline-flex;
  position: absolute;
  top: -1px;
  left: 0;
  box-shadow: 5.79px 4.34px 23.15px #0000001f;
}

.lounge .dollar-2 {
  width: 38px;
  height: 38px;
  position: absolute;
  top: 27px;
  left: 27px;
}

.lounge .frame-78 {
  flex-direction: column;
  align-items: flex-end;
  gap: 5.79px;
  width: 210px;
  display: flex;
  position: absolute;
  top: 6px;
  left: 108px;
}

.lounge .solutions {
  width: 1280px;
  height: 424px;
  position: absolute;
  top: 3859px;
  left: 77px;
}

.lounge .frame-79 {
  width: 624px;
  height: 196px;
  position: absolute;
  top: 0;
  left: 0;
}

.lounge .overlap-group-6 {
  height: 196px;
  position: relative;
}

.lounge .rectangle-10 {
  background-color: var(--neutral-30);
  border-radius: 0 12px 12px 0;
  width: 328px;
  height: 196px;
  position: absolute;
  top: 0;
  left: 296px;
}

.lounge .rectangle-11 {
  background-color: #f6f7f8;
  border-radius: 12px 0 0 12px;
  width: 296px;
  height: 196px;
  position: absolute;
  top: 0;
  left: 0;
}

.lounge .frame-80 {
  flex-direction: column;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 65px;
  left: 67px;
}

.lounge .text-wrapper-54 {
  color: #1f1f1f;
  letter-spacing: -.24px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  position: relative;
}

.lounge .text-wrapper-55 {
  color: #1f1f1f;
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.lounge .joulestowatts-offer {
  color: #1f1f1f;
  letter-spacing: -.24px;
  text-align: center;
  height: 68px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  position: absolute;
  top: 63px;
  left: 361px;
}

.lounge .div-5 {
  background-color: #2c3e47;
  border-radius: 32px;
  width: 64px;
  height: 64px;
  position: absolute;
  top: 66px;
  left: 264px;
}

.lounge .arrow-right {
  width: 26px;
  height: 26px;
  position: absolute;
  top: 19px;
  left: 19px;
}

.lounge .frame-81 {
  width: 624px;
  height: 196px;
  position: absolute;
  top: 228px;
  left: 0;
}

.lounge .frame-82 {
  flex-direction: column;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 65px;
  left: 63px;
}

.lounge .joulestowatts {
  color: #1f1f1f;
  letter-spacing: -.24px;
  text-align: center;
  height: 68px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  position: absolute;
  top: 63px;
  left: 353px;
}

.lounge .arrow-right-2 {
  width: 26px;
  height: 26px;
  position: absolute;
  top: 85px;
  left: 283px;
}

.lounge .frame-83 {
  width: 624px;
  height: 196px;
  position: absolute;
  top: 0;
  left: 656px;
}

.lounge .frame-84 {
  flex-direction: column;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 41px;
  left: 46px;
}

.lounge .crowd-sourcing {
  color: #1f1f1f;
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  text-align: center;
  width: fit-content;
  position: relative;
}

.lounge .joulestowatts-offer-2 {
  color: #1f1f1f;
  letter-spacing: -.24px;
  text-align: center;
  height: 68px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  position: absolute;
  top: 63px;
  left: 332px;
}

.lounge .frame-85 {
  width: 624px;
  height: 196px;
  position: absolute;
  top: 228px;
  left: 656px;
}

.lounge .frame-86 {
  flex-direction: column;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 48px;
  left: 54px;
}

.lounge .talent-gap {
  color: #1f1f1f;
  letter-spacing: -.24px;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  position: relative;
}

.lounge .text-wrapper-56 {
  color: #1f1f1f;
  letter-spacing: -.24px;
  text-align: center;
  white-space: nowrap;
  height: 34px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  position: absolute;
  top: 80px;
  left: 355px;
}

.lounge .overlap-5 {
  width: 1444px;
  height: 662px;
  position: absolute;
  top: 4726px;
  left: -4px;
}

.lounge .group-3 {
  width: 1440px;
  height: 380px;
  position: absolute;
  top: 0;
  left: 0;
}

.lounge .overlap-6 {
  height: 380px;
  position: relative;
}

.lounge .frame-87 {
  background-color: #4d4d4d;
  width: 1440px;
  height: 380px;
  position: absolute;
  top: 0;
  left: 0;
}

.lounge .frame-88 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 69px;
  left: 152px;
}

.lounge .frame-89 {
  flex-direction: column;
  flex: none;
  align-items: flex-end;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.lounge .text-wrapper-57 {
  color: #fff;
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.lounge .are-we-a-good-fit-2 {
  color: var(--neutral-10);
  letter-spacing: -.24px;
  text-align: right;
  width: 429px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
  position: relative;
}

.lounge .CTA-6 {
  all: unset;
  background-color: var(--secondary-04);
  box-sizing: border-box;
  flex: none;
  align-items: center;
  gap: 10px;
  padding: 12px 16px;
  display: inline-flex;
  position: relative;
}

.lounge .text-wrapper-58 {
  color: #fff;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: relative;
}

.lounge .CTA-7 {
  cursor: pointer;
  background-color: var(--secondary-02);
  box-sizing: border-box;
  border: none;
  outline: none;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  display: inline-flex;
  position: absolute;
  top: 267px;
  left: 818px;
}

.lounge .frame-90 {
  background-color: var(--secondary-03);
  width: 720px;
  height: 380px;
  position: absolute;
  top: 0;
  left: 0;
}

.lounge .overlap-group-7 {
  background: var(--neutral-10, #f6f7f8);
  width: 720px;
  height: 380px;
  position: absolute;
  top: 0;
  left: 0;
}

.lounge .frame-91 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 32px;
  display: inline-flex;
  position: relative;
  top: 49px;
  left: 128px;
}

.lounge .text-wrapper-59 {
  color: var(--secondary-04);
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.lounge .text-wrapper-60 {
  color: var(--neutral-80);
  letter-spacing: -.24px;
  text-align: right;
  width: 500px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
  position: relative;
}

.lounge .frame-92 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 52px;
  left: 817px;
}

.lounge .frame-93 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.lounge .text-wrapper-61 {
  color: #fff;
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.lounge .text-wrapper-62 {
  color: var(--neutral-10);
  letter-spacing: -.24px;
  width: 429px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
  position: relative;
}

.lounge .footer-2 {
  background-color: var(--white);
  width: 1441px;
  height: 292px;
  position: absolute;
  top: 370px;
  left: 3px;
}

.lounge .overlap-7 {
  width: 1441px;
  height: 292px;
  position: relative;
}

.lounge .frame-94 {
  background-color: var(--neutral-90);
  width: 1441px;
  height: 292px;
  position: absolute;
  top: 0;
  left: 0;
}

.lounge .DIV-3 {
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 53px;
  left: 738px;
}

.lounge .text-wrapper-63 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1233px;
}

.lounge .text-wrapper-64 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1282px;
}

.lounge .text-wrapper-65 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1331px;
}

.lounge .text-wrapper-66 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1187px;
}

.lounge .text-wrapper-67 {
  color: var(--white);
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  white-space: nowrap;
  height: 28px;
  position: absolute;
  top: 100px;
  left: 738px;
}

.lounge .text-wrapper-68 {
  color: var(--white);
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  white-space: nowrap;
  height: 28px;
  position: absolute;
  top: 100px;
  left: 869px;
}

.lounge .frame-95 {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: absolute;
  top: 115px;
  left: 81px;
}

.lounge .group-4 {
  width: 37px;
  height: 52px;
  position: absolute;
  top: 152px;
  left: 1140px;
}

.lounge .linkedin-2 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1187px;
}

.lounge .facebook-2 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1234px;
}

.lounge .facebook-3 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1281px;
}

.lounge .facebook-4 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1328px;
}

.lounge .group-5 {
  width: 400px;
  height: 17px;
  position: absolute;
  top: 242px;
  left: 501px;
}

.lounge .frame-96 {
  justify-content: center;
  align-items: center;
  gap: 5px;
  display: inline-flex;
  position: relative;
}

.lounge .text-wrapper-69 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.lounge .ellipse-5 {
  background-color: var(--grey-01);
  border-radius: 1.5px;
  width: 3px;
  height: 3px;
  position: relative;
}

.lounge .logo-4 {
  width: 116px;
  height: 56px;
  position: absolute;
  top: 39px;
  left: 76px;
}

.lounge .overlap-8 {
  background-color: #f6f7f8;
  width: 1446px;
  height: 350px;
  position: absolute;
  top: 4376px;
  left: -9px;
}

.lounge .frame-97 {
  flex-direction: column;
  align-items: center;
  gap: 25px;
  display: inline-flex;
  position: relative;
  top: 103px;
  left: 161px;
}

.lounge .mercedes-benz-logo-2 {
  object-fit: cover;
  width: 248px;
  height: 63px;
  position: relative;
}

.lounge .GE-healthcare-logo-2 {
  object-fit: cover;
  width: 164px;
  height: 92px;
  position: relative;
}

.lounge .sony-logo-2 {
  object-fit: cover;
  width: 123px;
  height: 22px;
  position: relative;
}

.lounge .shell-logo-2 {
  object-fit: cover;
  width: 115px;
  height: 65px;
  position: relative;
}

.lounge .amazon-2 {
  object-fit: cover;
  width: 178px;
  height: 55px;
  position: relative;
}

.lounge .wipro-logo-new-og-2 {
  object-fit: cover;
  width: 171px;
  height: 89px;
  position: relative;
}

.lounge .frame-98 {
  align-items: flex-start;
  gap: 87px;
  display: inline-flex;
  position: absolute;
  top: 1408px;
  left: 733px;
}

.lounge .text-wrapper-70 {
  color: #999;
  letter-spacing: -.75px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 50px;
  font-weight: 700;
  line-height: 60px;
  position: relative;
}

.lounge .text-wrapper-71 {
  color: #999;
  letter-spacing: -.75px;
  text-align: center;
  width: 107px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 50px;
  font-weight: 700;
  line-height: 60px;
  position: relative;
}

.lounge .frame-99 {
  flex-direction: column;
  flex: none;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.lounge .text-wrapper-72 {
  color: #2e5053;
  letter-spacing: -.28px;
  width: 306px;
  height: 78px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 28px;
  font-weight: 600;
  line-height: 39.2px;
  position: absolute;
  top: 1422px;
  left: 95px;
}

.lounge .carousel-3 {
  width: 390px;
  height: 796px;
  position: absolute;
  top: 5090px;
  left: 0;
  overflow: hidden;
}

.lounge .frame-100 {
  width: 667px;
  height: 1425px;
  position: absolute;
  top: 0;
  left: 0;
}

.lounge .overlap-group-8 {
  width: 742px;
  height: 923px;
  position: relative;
  top: -24px;
  left: -189px;
}

.lounge .rectangle-12 {
  width: 390px;
  height: 485px;
  position: absolute;
  top: 24px;
  left: 189px;
}

.lounge .frame-101 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  height: 261px;
  display: inline-flex;
  position: absolute;
  top: 166px;
  left: 269px;
}

.lounge .pexels-pavel-2 {
  object-fit: cover;
  width: 687px;
  height: 459px;
  position: absolute;
  top: 391px;
  left: 0;
}

.lounge .rectangle-13 {
  filter: blur(40px);
  background: linear-gradient(90deg, #589993 5.95%, #4d9f98 20.62% 48.33%, #fcfcfc00 71.42%);
  width: 923px;
  height: 687px;
  position: absolute;
  top: 118px;
  left: -63px;
  transform: rotate(90deg);
}

.lounge .frame-102 {
  flex-direction: column;
  align-items: flex-start;
  gap: 33px;
  display: inline-flex;
  position: absolute;
  top: 106px;
  left: 205px;
}

.lounge .joulestowatt-s-2 {
  color: #fff;
  letter-spacing: -.22px;
  width: 358px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 22px;
  font-weight: 600;
  line-height: 30.8px;
  position: relative;
}

.lounge .CTA-8 {
  border: unset !important;
  border-color: unset !important;
  background-color: #2e5053 !important;
  flex: none !important;
  justify-content: center !important;
  align-items: flex-end !important;
  width: 127px !important;
  display: flex !important;
}

.lounge .CTA-9 {
  font-family: var(--subtitle-BLD-font-family) !important;
  font-size: var(--subtitle-BLD-font-size) !important;
  font-style: var(--subtitle-BLD-font-style) !important;
  font-weight: var(--subtitle-BLD-font-weight) !important;
  letter-spacing: var(--subtitle-BLD-letter-spacing) !important;
  line-height: var(--subtitle-BLD-line-height) !important;
  margin-left: -2px !important;
}

.lounge .CTA-10 {
  margin-right: -2px !important;
}

.lounge .frame-103 {
  width: 1440px;
  height: 580px;
  position: absolute;
  top: 0;
  left: 1440px;
  overflow: hidden;
}

.lounge .rectangle-14 {
  width: 1383px;
  height: 634px;
  position: absolute;
  top: -8100px;
  left: -2129px;
}

.lounge .overlap-9 {
  filter: blur(80px);
  background: linear-gradient(#000 0%, #071103 25%, #202020 44.79%, #2f2f2f 61.46%, #0000 100%);
  width: 1684px;
  height: 714px;
  position: absolute;
  top: -60px;
  left: -116px;
}

.lounge .frame-104 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: absolute;
  top: 242px;
  left: 196px;
}

.lounge .text-wrapper-73 {
  color: var(--neutral-0);
  letter-spacing: -.53px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 600;
  line-height: 49px;
  position: relative;
}

.lounge .our-client-and {
  color: #f6f7f8;
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  width: 436px;
  margin-top: -1px;
  position: relative;
}

.lounge .CTA-11 {
  all: unset;
  box-sizing: border-box;
  flex: none;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.lounge .text-wrapper-74 {
  color: var(--neutral-0);
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 23.8px;
  position: relative;
}

.lounge .arrow-forward-3 {
  width: 24px;
  height: 24px;
  margin-top: -8472px;
  margin-left: -2463px;
  position: relative;
}

.lounge .text-wrapper-75 {
  color: var(--neutral-0);
  font-family: var(--h4-REG-font-family);
  font-size: var(--h4-REG-font-size);
  font-style: var(--h4-REG-font-style);
  font-weight: var(--h4-REG-font-weight);
  letter-spacing: var(--h4-REG-letter-spacing);
  line-height: var(--h4-REG-line-height);
  white-space: nowrap;
  height: 41px;
  position: absolute;
  top: 120px;
  left: 196px;
}

.lounge .frame-105 {
  align-items: flex-start;
  gap: 5px;
  display: inline-flex;
  position: absolute;
  top: 560px;
  left: 196px;
}

.lounge .ellipse-6 {
  background-color: #d9d9d952;
  border-radius: 5.5px;
  width: 11px;
  height: 11px;
  position: relative;
}

.lounge .ellipse-7 {
  background-color: var(--neutral-30);
  border-radius: 5.5px;
  width: 11px;
  height: 11px;
  position: relative;
}

.lounge .carousel-03-instance {
  margin-top: -8495px !important;
  margin-left: -3903px !important;
}

.lounge .group-6 {
  width: 342px;
  height: 1083px;
  position: absolute;
  top: 3931px;
  left: 26px;
}

.lounge .text-wrapper-76 {
  color: #1f1f1f;
  letter-spacing: -.8px;
  text-align: center;
  width: 328px;
  height: 76px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.4px;
  position: absolute;
  top: 0;
  left: 4px;
}

.lounge .text-wrapper-77 {
  color: var(--neutral-60);
  letter-spacing: -.16px;
  text-align: center;
  width: 328px;
  height: 132px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  position: absolute;
  top: 92px;
  left: 4px;
}

.lounge .solutions-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 251px;
  left: 0;
}

.lounge .frame-106 {
  width: 338px;
  height: 196px;
  position: relative;
}

.lounge .rectangle-15 {
  background-color: var(--neutral-30);
  border-radius: 0 12px 12px 0;
  width: 169px;
  height: 196px;
  position: absolute;
  top: 0;
  left: 169px;
}

.lounge .rectangle-16 {
  width: 169px;
  height: 196px;
  position: absolute;
  top: 0;
  left: 0;
}

.lounge .frame-107 {
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 109px;
  display: flex;
  position: absolute;
  top: 65px;
  left: 17px;
}

.lounge .text-wrapper-78 {
  color: #1f1f1f;
  letter-spacing: -.18px;
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 18px;
  font-weight: 600;
  line-height: 25.2px;
  position: relative;
}

.lounge .regular-skill {
  color: #1f1f1f;
  letter-spacing: -.12px;
  text-align: center;
  align-self: stretch;
  font-family: Source Sans Pro, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.lounge .text-wrapper-79 {
  color: #1f1f1f;
  letter-spacing: -.18px;
  text-align: center;
  width: 115px;
  height: 50px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 18px;
  font-weight: 600;
  line-height: 25.2px;
  position: absolute;
  top: 76px;
  left: 213px;
}

.lounge .arrow-right-wrapper {
  background-color: #2c3e47;
  border-radius: 32px;
  width: 64px;
  height: 64px;
  position: absolute;
  top: 66px;
  left: 137px;
}

.lounge .rectangle-17 {
  background-color: #f6f7f8;
  border-radius: 12px 0 0 12px;
  width: 169px;
  height: 196px;
  position: absolute;
  top: 0;
  left: 0;
}

.lounge .frame-108 {
  flex-direction: column;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 65px;
  left: 21px;
}

.lounge .text-wrapper-80 {
  color: #1f1f1f;
  letter-spacing: -.18px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 18px;
  font-weight: 600;
  line-height: 25.2px;
  position: relative;
}

.lounge .niche-skill {
  color: #1f1f1f;
  letter-spacing: -.12px;
  text-align: center;
  width: 89px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.lounge .text-wrapper-81 {
  color: #1f1f1f;
  letter-spacing: -.18px;
  text-align: center;
  width: 126px;
  height: 50px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 18px;
  font-weight: 600;
  line-height: 25.2px;
  position: absolute;
  top: 72px;
  left: 201px;
}

.lounge .frame-109 {
  background-color: #2c3e47;
  border-radius: 32px;
  width: 64px;
  height: 64px;
  position: absolute;
  top: 66px;
  left: 131px;
}

.lounge .frame-110 {
  flex-direction: column;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 47px;
  left: 15px;
}

.lounge .crowd-sourcing-2 {
  color: #1f1f1f;
  letter-spacing: -.12px;
  text-align: center;
  width: 123px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.lounge .joulestowatts-offer-3 {
  color: #1f1f1f;
  letter-spacing: -.18px;
  text-align: center;
  width: 125px;
  height: 100px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 18px;
  font-weight: 600;
  line-height: 25.2px;
  position: absolute;
  top: 47px;
  left: 201px;
}

.lounge .frame-111 {
  flex-direction: column;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 45px;
  left: 15px;
}

.lounge .talent-gap-2 {
  color: #1f1f1f;
  letter-spacing: -.18px;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 18px;
  font-weight: 600;
  line-height: 25.2px;
  position: relative;
}

.lounge .text-wrapper-82 {
  color: #1f1f1f;
  letter-spacing: -.12px;
  text-align: center;
  width: 108px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.lounge .text-wrapper-83 {
  color: #1f1f1f;
  letter-spacing: -.18px;
  text-align: center;
  width: 115px;
  height: 50px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 18px;
  font-weight: 600;
  line-height: 25.2px;
  position: absolute;
  top: 72px;
  left: 206px;
}

.lounge .frame-112 {
  background-color: #1f1f1f;
  border-radius: 32px;
  width: 64px;
  height: 64px;
  position: absolute;
  top: 66px;
  left: 137px;
}

.lounge .text-wrapper-84 {
  color: #1f1f1f;
  letter-spacing: -.8px;
  text-align: center;
  width: 315px;
  height: 76px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.4px;
  position: absolute;
  top: 3074px;
  left: 38px;
}

.mobile-only {
  display: none !important;
}

.display-content {
  display: contents !important;
}

body {
  overflow-x: hidden;
}

button {
  cursor: pointer !important;
}

.error {
  color: red;
  margin: -22px 0 -15px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 12px;
}

.error-2 {
  color: red;
  margin-top: 5px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 12px;
}

.error-1 {
  color: red;
  margin-top: -10px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 12px;
}

.next-button {
  cursor: pointer;
  background: #fff;
  border: none;
  border-radius: 50%;
  outline: none;
  width: 60px;
  height: 60px;
  position: absolute;
  right: 0;
}

.dot-active-green {
  background: #4d9f98;
  border: none;
  border-radius: 50%;
  outline: none;
  width: 8.95px;
  height: 10.95px;
  margin-left: 4px;
}

.dot-inactive-green {
  background: #c2c2c2;
  border: none;
  border-radius: 50%;
  outline: none;
  width: 8.95px;
  height: 10.95px;
  margin-left: 4px;
}

.dot-active {
  background: #e9e9e9;
  border: none;
  border-radius: 50%;
  outline: none;
  width: 8.95px;
  height: 10.95px;
  margin-left: 4px;
}

.dot-inactive {
  background: #ffffff52;
  border: none;
  border-radius: 50%;
  outline: none;
  width: 8.95px;
  height: 10.95px;
  margin-left: 4px;
}

@media (width <= 450px) {
  .mobile-only {
    display: block !important;
  }

  .desktop-only {
    display: none !important;
  }
}

.carousel-no .img {
  width: 24px;
  height: 24px;
  position: relative;
}

.itcomponent {
  flex-direction: column;
  align-items: flex-start;
  gap: 36px;
  width: 456px;
  padding: 80px 16px 96px 15px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.itcomponent .text-wrapper-4 {
  color: var(--neutral-90);
  letter-spacing: -.8px;
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 600;
  line-height: 38.4px;
  position: relative;
}

.itcomponent .frame-4 {
  flex: none;
  display: inline-flex;
  position: relative;
}

.itcomponent .frame-5 {
  flex: none;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  display: inline-flex;
  position: relative;
}

.itcomponent .CTA-instance {
  border: unset !important;
  border-color: unset !important;
  background-color: #4d9f98 !important;
  flex: none !important;
  justify-content: center !important;
  align-items: flex-end !important;
}

.itcomponent .instance-node {
  font-family: var(--subtitle-BLD-font-family) !important;
  font-size: var(--subtitle-BLD-font-size) !important;
  font-style: var(--subtitle-BLD-font-style) !important;
  font-weight: var(--subtitle-BLD-font-weight) !important;
  letter-spacing: var(--subtitle-BLD-letter-spacing) !important;
  line-height: var(--subtitle-BLD-line-height) !important;
}

.itcomponent .CTA-2 {
  border: unset !important;
  border-color: unset !important;
  background-color: #e6e6e6 !important;
  flex: none !important;
  justify-content: center !important;
  align-items: flex-end !important;
}

.itcomponent .CTA-3 {
  color: #6b6b6b !important;
  font-family: var(--subtitle-BLD-font-family) !important;
  font-size: var(--subtitle-BLD-font-size) !important;
  font-style: var(--subtitle-BLD-font-style) !important;
  font-weight: var(--subtitle-BLD-font-weight) !important;
  letter-spacing: var(--subtitle-BLD-letter-spacing) !important;
  line-height: var(--subtitle-BLD-line-height) !important;
}

.itcomponent .CTA-4 {
  border: unset !important;
  border-color: unset !important;
  background-color: #e6e6e6 !important;
  justify-content: center !important;
  align-items: flex-end !important;
  width: 127px !important;
  display: flex !important;
}

.itcomponent .class {
  flex: none !important;
  justify-content: center !important;
  align-items: flex-end !important;
}

.itcomponent .frame-wrapper {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.itcomponent .frame-6 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 32px;
  width: 406px;
  padding: 32px 0;
  display: flex;
  position: relative;
}

.itcomponent .digital {
  color: #4d4d4d;
  letter-spacing: -.53px;
  width: 330px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 700;
  line-height: 42px;
  position: relative;
}

.itcomponent .our-digital-services {
  color: var(--neutral-60);
  letter-spacing: -.2px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.itcomponent .frame-7 {
  flex: none;
  align-items: flex-start;
  gap: 8px;
  display: flex;
  position: relative;
}

.itcomponent .frame-8 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.itcomponent .frame-9 {
  flex: none;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.itcomponent .vector {
  width: 16px;
  height: 16px;
  position: relative;
}

.itcomponent .time-and-material, .itcomponent .lab-on-hire, .itcomponent .digitalization {
  color: var(--neutral-60);
  letter-spacing: -.2px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.itcomponent .security-testing {
  color: var(--neutral-60);
  letter-spacing: -.2px;
  width: 240px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.itcomponent .functional-testing, .itcomponent .UX-UI, .itcomponent .ERP-testing, .itcomponent .customer-experience {
  color: var(--neutral-60);
  letter-spacing: -.2px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.itcomponent .service-digital {
  flex-direction: column;
  align-items: center;
  gap: 56px;
  width: 390px;
  overflow: scroll;
}

.itcomponent .variant-4 {
  flex-direction: column;
  align-items: center;
  gap: 56px;
}

.itcomponent .variant-2, .itcomponent .variant-3 {
  align-items: flex-start;
  gap: 16px;
}

.itcomponent .variant-5, .itcomponent .variant-6, .itcomponent .variant-7 {
  flex-direction: column;
  align-items: center;
  gap: 56px;
}

.itcomponent .property-1, .itcomponent .property-1-service-digital, .itcomponent .property-1-variant-4 {
  width: 330px;
}

.itcomponent .property-1-variant-2 {
  align-self: stretch;
}

.itcomponent .property-1-variant-3, .itcomponent .property-1-variant-5, .itcomponent .property-1-variant-6, .itcomponent .property-1-variant-7 {
  width: 330px;
}

.itcomponent .property-1-0-service-digital {
  flex-direction: column;
  width: 330px;
}

.itcomponent .property-1-0, .itcomponent .property-1-0-variant-2 {
  flex-direction: column;
  align-self: stretch;
  width: 100%;
}

.itcomponent .property-1-0-variant-3 {
  align-self: stretch;
  width: 100%;
}

.itcomponent .property-1-0-variant-5, .itcomponent .property-1-0-variant-6 {
  flex-direction: column;
  align-self: stretch;
  width: 100%;
}

.itcomponent .property-1-0-variant-7 {
  align-self: stretch;
  width: 100%;
}

.itcomponent .frame-7.property-1-0-service-digital .time-and-material {
  width: 220px;
}

.itcomponent .frame-7.property-1-0-variant-4 .time-and-material, .itcomponent .frame-7.property-1-0-variant-2 .time-and-material {
  width: 240px;
}

.itcomponent .frame-7.property-1-0-variant-3 .time-and-material {
  white-space: nowrap;
  width: fit-content;
}

.itcomponent .frame-7.property-1-0-variant-5 .time-and-material {
  width: 240px;
}

.itcomponent .frame-7.property-1-0-variant-6 .time-and-material, .itcomponent .frame-7.property-1-0-variant-7 .time-and-material {
  white-space: nowrap;
  width: fit-content;
}

.itcomponent .frame-7.property-1-0-service-digital .lab-on-hire {
  width: 220px;
}

.itcomponent .frame-7.property-1-0-variant-4 .lab-on-hire, .itcomponent .frame-7.property-1-0-variant-2 .lab-on-hire {
  width: 240px;
}

.itcomponent .frame-7.property-1-0-variant-3 .lab-on-hire {
  white-space: nowrap;
  width: fit-content;
}

.itcomponent .frame-7.property-1-0-variant-5 .lab-on-hire {
  width: 240px;
}

.itcomponent .frame-7.property-1-0-variant-6 .lab-on-hire, .itcomponent .frame-7.property-1-0-variant-7 .lab-on-hire {
  white-space: nowrap;
  width: fit-content;
}

.itcomponent .frame-7.property-1-0-service-digital .digitalization {
  width: 220px;
}

.itcomponent .frame-7.property-1-0-variant-4 .digitalization, .itcomponent .frame-7.property-1-0-variant-2 .digitalization {
  width: 240px;
}

.itcomponent .frame-7.property-1-0-variant-3 .digitalization {
  white-space: nowrap;
  width: fit-content;
}

.itcomponent .frame-7.property-1-0-variant-5 .digitalization {
  width: 240px;
}

.itcomponent .frame-7.property-1-0-variant-6 .digitalization {
  white-space: nowrap;
  width: fit-content;
}

.itcomponent .frame-7.property-1-0-variant-7 .digitalization {
  width: 240px;
}

.itcomponent .frame-7.property-1-0-service-digital .functional-testing, .itcomponent .frame-7.property-1-0-variant-4 .functional-testing, .itcomponent .frame-7.property-1-0-variant-2 .functional-testing, .itcomponent .frame-7.property-1-0-variant-3 .functional-testing, .itcomponent .frame-7.property-1-0-variant-5 .functional-testing {
  width: 220px;
}

.itcomponent .frame-7.property-1-0-variant-6 .functional-testing {
  white-space: nowrap;
  width: fit-content;
}

.itcomponent .frame-7.property-1-0-variant-7 .functional-testing, .itcomponent .frame-7.property-1-0-service-digital .UX-UI {
  width: 220px;
}

.itcomponent .frame-7.property-1-0-variant-4 .UX-UI {
  white-space: nowrap;
  width: fit-content;
}

.itcomponent .frame-7.property-1-0-variant-2 .UX-UI, .itcomponent .frame-7.property-1-0-variant-3 .UX-UI, .itcomponent .frame-7.property-1-0-variant-5 .UX-UI {
  width: 220px;
}

.itcomponent .frame-7.property-1-0-variant-6 .UX-UI {
  white-space: nowrap;
  width: fit-content;
}

.itcomponent .frame-7.property-1-0-variant-7 .UX-UI, .itcomponent .frame-7.property-1-0-service-digital .ERP-testing, .itcomponent .frame-7.property-1-0-variant-4 .ERP-testing, .itcomponent .frame-7.property-1-0-variant-2 .ERP-testing, .itcomponent .frame-7.property-1-0-variant-3 .ERP-testing, .itcomponent .frame-7.property-1-0-variant-5 .ERP-testing {
  width: 220px;
}

.itcomponent .frame-7.property-1-0-variant-6 .ERP-testing {
  white-space: nowrap;
  width: fit-content;
}

.itcomponent .frame-7.property-1-0-variant-7 .ERP-testing {
  width: 220px;
}

.itcomponent .frame-7.property-1-0-service-digital .customer-experience {
  width: fit-content;
}

.itcomponent .frame-7.property-1-0-variant-4 .customer-experience {
  white-space: nowrap;
  width: fit-content;
}

.itcomponent .frame-7.property-1-0-variant-2 .customer-experience, .itcomponent .frame-7.property-1-0-variant-3 .customer-experience {
  width: fit-content;
}

.itcomponent .frame-7.property-1-0-variant-5 .customer-experience {
  width: 220px;
}

.itcomponent .frame-7.property-1-0-variant-6 .customer-experience, .itcomponent .frame-7.property-1-0-variant-7 .customer-experience {
  width: fit-content;
}

.property-service-wrapper {
  flex-direction: column;
  align-items: flex-start;
  gap: 105px;
  padding: 80px 80px 96px;
  display: inline-flex;
  position: relative;
}

.property-service-wrapper .frame-10 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 56px;
  display: inline-flex;
  position: relative;
}

.property-service-wrapper .text-wrapper-6 {
  color: var(--neutral-90);
  letter-spacing: -1.05px;
  text-align: center;
  width: 1280px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 42px;
  font-weight: 600;
  line-height: 50.4px;
  position: relative;
}

.property-service-wrapper .frame-11 {
  flex: none;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.property-service-wrapper .class-2 {
  border: unset !important;
  border-color: unset !important;
  cursor: pointer !important;
  background-color: #4d9f98 !important;
  justify-content: center !important;
  align-items: flex-end !important;
  width: 127px !important;
  display: flex !important;
}

.property-service-wrapper .class-3 {
  transition: background-color .3s ease-in-out;
  border: unset !important;
  border-color: unset !important;
  cursor: pointer !important;
  background-color: #e6e6e6 !important;
  justify-content: center !important;
  align-items: flex-end !important;
  width: 127px !important;
  display: flex !important;
}

.property-service-wrapper .class-3:hover {
  background-color: #4d9f98 !important;
}

.property-service-wrapper .class-4 {
  font-family: var(--subtitle-BLD-font-family) !important;
  font-size: var(--subtitle-BLD-font-size) !important;
  font-style: var(--subtitle-BLD-font-style) !important;
  font-weight: var(--subtitle-BLD-font-weight) !important;
  letter-spacing: var(--subtitle-BLD-letter-spacing) !important;
  line-height: var(--subtitle-BLD-line-height) !important;
  cursor: pointer !important;
}

.property-service-wrapper .class-5 {
  color: #6b6b6b !important;
  font-family: var(--subtitle-BLD-font-family) !important;
  font-size: var(--subtitle-BLD-font-size) !important;
  font-style: var(--subtitle-BLD-font-style) !important;
  font-weight: var(--subtitle-BLD-font-weight) !important;
  letter-spacing: var(--subtitle-BLD-letter-spacing) !important;
  line-height: var(--subtitle-BLD-line-height) !important;
  cursor: pointer !important;
}

.property-service-wrapper .class-6 {
  cursor: pointer !important;
  flex: none !important;
  justify-content: center !important;
  align-items: flex-end !important;
}

.property-service-wrapper .class-7 {
  transition: background-color .3s ease-in-out;
  cursor: pointer !important;
  border: unset !important;
  border-color: unset !important;
  background-color: #e6e6e6 !important;
  flex: none !important;
  justify-content: center !important;
  align-items: flex-end !important;
}

.property-service-wrapper .class-7:hover {
  background-color: #4d9f98 !important;
}

.property-service-wrapper .class-8 {
  transition: background-color .3s ease-in-out;
  cursor: pointer !important;
  background-color: #e6e6e6 !important;
  flex: none !important;
  justify-content: center !important;
  align-items: flex-end !important;
  gap: 10px !important;
}

.property-service-wrapper .class-8:hover {
  background-color: #4d9f98 !important;
}

.property-service-wrapper .class-9 {
  background-color: var(--primary-02) !important;
  border: unset !important;
  border-color: unset !important;
  cursor: pointer !important;
  flex: none !important;
  justify-content: center !important;
  align-items: flex-end !important;
  gap: 8px !important;
}

.property-service-wrapper .frame-12 {
  flex: none;
  justify-content: space-between;
  align-items: flex-start;
  width: 1280px;
  display: flex;
  position: relative;
}

.property-service-wrapper .frame-13 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: relative;
}

.property-service-wrapper .cloud-services {
  color: #4d4d4d;
  letter-spacing: -.53px;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 700;
  line-height: 42px;
  position: relative;
}

.property-service-wrapper .with-the-help-of {
  color: var(--neutral-60);
  letter-spacing: -.2px;
  width: 580px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.property-service-wrapper .frame-14 {
  flex: none;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.property-service-wrapper .frame-15 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.property-service-wrapper .frame-16 {
  flex: none;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.property-service-wrapper .vector-2 {
  width: 16px;
  height: 16px;
  position: relative;
}

.property-service-wrapper .time-and-material-2, .property-service-wrapper .lab-on-hire-2, .property-service-wrapper .digitalization-2 {
  color: var(--neutral-60);
  letter-spacing: -.2px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.property-service-wrapper .security-testing-2 {
  color: var(--neutral-60);
  letter-spacing: -.2px;
  width: 240px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.property-service-wrapper .frame-17 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.property-service-wrapper .functional-testing-2, .property-service-wrapper .cloud-migration, .property-service-wrapper .ERP-testing-2, .property-service-wrapper .customer-experience-2 {
  color: var(--neutral-60);
  letter-spacing: -.2px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.property-service-wrapper .rectangle-2 {
  width: 650px;
  height: 420px;
  position: relative;
}

.property-service-wrapper .property-1-8-service-app-modernization {
  height: 420px;
  padding: 16px 0;
}

.property-service-wrapper .property-1-8-service-digital {
  align-self: stretch;
  padding: 32px 0;
}

.property-service-wrapper .property-1-8-service-professional-services {
  height: 420px;
  padding: 16px 0;
}

.property-service-wrapper .property-1-8-service-QA-and-testing {
  height: 420px;
  padding: 32px 0;
}

.property-service-wrapper .property-1-8-service-infrastruture-services {
  height: 420px;
  padding: 16px 0;
}

.property-service-wrapper .property-1-8-service-product-engineering, .property-service-wrapper .property-1-8-service-cloud-services {
  height: 420px;
  padding: 32px 0;
}

.property-service-wrapper .service-app-modernization, .property-service-wrapper .service-professional-services, .property-service-wrapper .service-QA-and-testing, .property-service-wrapper .service-infrastruture-services, .property-service-wrapper .service-product-engineering, .property-service-wrapper .service-cloud-services {
  object-fit: cover;
}

.property-service-wrapper .frame-13.property-1-8-service-app-modernization .frame-14 {
  justify-content: space-between;
  width: 514px;
}

.property-service-wrapper .frame-13.property-1-8-service-digital .frame-14 {
  justify-content: space-between;
  width: 482px;
}

.property-service-wrapper .frame-13.property-1-8-service-professional-services .frame-14 {
  align-self: stretch;
  gap: 8px;
  width: 100%;
}

.property-service-wrapper .frame-13.property-1-8-service-QA-and-testing .frame-14, .property-service-wrapper .frame-13.property-1-8-service-infrastruture-services .frame-14 {
  justify-content: space-between;
  width: 580px;
}

.property-service-wrapper .frame-13.property-1-8-service-product-engineering .frame-14 {
  align-self: stretch;
  gap: 8px;
  width: 100%;
}

.property-service-wrapper .frame-13.property-1-8-service-cloud-services .frame-14 {
  justify-content: space-between;
  width: 580px;
}

.property-service-wrapper .frame-13.property-1-8-service-app-modernization .time-and-material-2 {
  white-space: nowrap;
  width: fit-content;
}

.property-service-wrapper .frame-13.property-1-8-service-digital .time-and-material-2 {
  width: 220px;
}

.property-service-wrapper .frame-13.property-1-8-service-professional-services .time-and-material-2 {
  white-space: nowrap;
  width: fit-content;
}

.property-service-wrapper .frame-13.property-1-8-service-QA-and-testing .time-and-material-2, .property-service-wrapper .frame-13.property-1-8-service-infrastruture-services .time-and-material-2 {
  width: 240px;
}

.property-service-wrapper .frame-13.property-1-8-service-product-engineering .time-and-material-2 {
  white-space: nowrap;
  width: fit-content;
}

.property-service-wrapper .frame-13.property-1-8-service-cloud-services .time-and-material-2 {
  width: 240px;
}

.property-service-wrapper .frame-13.property-1-8-service-app-modernization .lab-on-hire-2 {
  white-space: nowrap;
  width: fit-content;
}

.property-service-wrapper .frame-13.property-1-8-service-digital .lab-on-hire-2 {
  width: 220px;
}

.property-service-wrapper .frame-13.property-1-8-service-professional-services .lab-on-hire-2 {
  white-space: nowrap;
  width: fit-content;
}

.property-service-wrapper .frame-13.property-1-8-service-QA-and-testing .lab-on-hire-2, .property-service-wrapper .frame-13.property-1-8-service-infrastruture-services .lab-on-hire-2 {
  width: 240px;
}

.property-service-wrapper .frame-13.property-1-8-service-product-engineering .lab-on-hire-2 {
  white-space: nowrap;
  width: fit-content;
}

.property-service-wrapper .frame-13.property-1-8-service-cloud-services .lab-on-hire-2 {
  width: 240px;
}

.property-service-wrapper .frame-13.property-1-8-service-app-modernization .digitalization-2 {
  white-space: nowrap;
  width: fit-content;
}

.property-service-wrapper .frame-13.property-1-8-service-digital .digitalization-2 {
  width: 220px;
}

.property-service-wrapper .frame-13.property-1-8-service-professional-services .digitalization-2, .property-service-wrapper .frame-13.property-1-8-service-QA-and-testing .digitalization-2, .property-service-wrapper .frame-13.property-1-8-service-infrastruture-services .digitalization-2 {
  width: 240px;
}

.property-service-wrapper .frame-13.property-1-8-service-product-engineering .digitalization-2 {
  white-space: nowrap;
  width: fit-content;
}

.property-service-wrapper .frame-13.property-1-8-service-cloud-services .digitalization-2 {
  width: 240px;
}

.property-service-wrapper .frame-13.property-1-8-service-digital .frame-17 {
  margin-left: -6px;
}

.property-service-wrapper .frame-13.property-1-8-service-app-modernization .functional-testing-2 {
  white-space: nowrap;
  width: fit-content;
}

.property-service-wrapper .frame-13.property-1-8-service-digital .functional-testing-2, .property-service-wrapper .frame-13.property-1-8-service-professional-services .functional-testing-2, .property-service-wrapper .frame-13.property-1-8-service-QA-and-testing .functional-testing-2, .property-service-wrapper .frame-13.property-1-8-service-infrastruture-services .functional-testing-2, .property-service-wrapper .frame-13.property-1-8-service-product-engineering .functional-testing-2, .property-service-wrapper .frame-13.property-1-8-service-cloud-services .functional-testing-2 {
  width: 220px;
}

.property-service-wrapper .frame-13.property-1-8-service-app-modernization .cloud-migration {
  white-space: nowrap;
  width: fit-content;
}

.property-service-wrapper .frame-13.property-1-8-service-digital .cloud-migration, .property-service-wrapper .frame-13.property-1-8-service-professional-services .cloud-migration {
  width: 220px;
}

.property-service-wrapper .frame-13.property-1-8-service-QA-and-testing .cloud-migration {
  white-space: nowrap;
  width: fit-content;
}

.property-service-wrapper .frame-13.property-1-8-service-infrastruture-services .cloud-migration, .property-service-wrapper .frame-13.property-1-8-service-product-engineering .cloud-migration, .property-service-wrapper .frame-13.property-1-8-service-cloud-services .cloud-migration {
  width: 220px;
}

.property-service-wrapper .frame-13.property-1-8-service-app-modernization .ERP-testing-2 {
  white-space: nowrap;
  width: fit-content;
}

.property-service-wrapper .frame-13.property-1-8-service-digital .ERP-testing-2, .property-service-wrapper .frame-13.property-1-8-service-professional-services .ERP-testing-2, .property-service-wrapper .frame-13.property-1-8-service-QA-and-testing .ERP-testing-2, .property-service-wrapper .frame-13.property-1-8-service-infrastruture-services .ERP-testing-2, .property-service-wrapper .frame-13.property-1-8-service-product-engineering .ERP-testing-2, .property-service-wrapper .frame-13.property-1-8-service-cloud-services .ERP-testing-2 {
  width: 220px;
}

.property-service-wrapper .frame-13.property-1-8-service-app-modernization .customer-experience-2, .property-service-wrapper .frame-13.property-1-8-service-digital .customer-experience-2, .property-service-wrapper .frame-13.property-1-8-service-professional-services .customer-experience-2 {
  width: fit-content;
}

.property-service-wrapper .frame-13.property-1-8-service-QA-and-testing .customer-experience-2 {
  white-space: nowrap;
  width: fit-content;
}

.property-service-wrapper .frame-13.property-1-8-service-infrastruture-services .customer-experience-2 {
  width: 220px;
}

.property-service-wrapper .frame-13.property-1-8-service-product-engineering .customer-experience-2, .property-service-wrapper .frame-13.property-1-8-service-cloud-services .customer-experience-2 {
  width: fit-content;
}

.itservices {
  background-color: #fff;
  flex-direction: row;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.itservices .iphone {
  background-color: #fff;
  position: relative;
}

.itservices .header {
  width: 390px !important;
  height: 60px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.itservices .div-wrapper {
  background: linear-gradient(#000c 0%, #0000 100%);
  width: 390px;
  height: 412px;
  position: absolute;
  top: 60px;
  left: 0;
}

.itservices .frame-18 {
  flex-direction: column;
  align-items: center;
  gap: 49px;
  width: 332px;
  display: flex;
  position: relative;
  top: 104px;
  left: 29px;
}

.itservices .frame-19 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 20px;
  width: 100%;
  display: flex;
  position: relative;
}

.itservices .p {
  color: #fff;
  letter-spacing: -1.2px;
  text-align: center;
  width: 356px;
  margin-top: -1px;
  margin-left: -12px;
  margin-right: -12px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 40px;
  font-weight: 600;
  line-height: 40.6px;
  position: relative;
}

.itservices .scale-faster-with-wrapper {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 24px;
  width: 100%;
  display: flex;
  position: relative;
}

.itservices .scale-faster-with {
  color: #fff;
  letter-spacing: -.16px;
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;
  position: relative;
}

.itservices .group-2 {
  width: 295px;
  height: 64px;
  position: absolute;
  top: 732px;
  left: 48px;
}

.itservices .frame-25 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 295px;
  display: flex;
  position: relative;
}

.itservices .text-wrapper-13 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.itservices .ellipse-3 {
  background-color: var(--grey-01);
  border-radius: 1px / 1.5px;
  width: 2px;
  height: 3px;
  position: relative;
}

.itservices .text-wrapper-14 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  text-align: center;
  align-self: stretch;
  position: relative;
}

.itservices .overlap {
  position: absolute;
}

.itservices .blurry-video-of {
  width: 1494px;
  height: 655px;
  position: absolute;
  top: 97px;
  left: 0;
}

.itservices .video {
  width: 1440px;
  height: 660px;
  position: absolute;
  top: 98px;
  left: 54px;
}

.itservices .video:after {
  content: "";
  background: #0000005c;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.itservices .video-mobile {
  width: 390px;
  height: 412px;
  position: absolute;
  top: 0;
  left: 0;
}

.itservices .video-mobile:after {
  content: "";
  background: #0000005c;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.itservices .hero {
  width: 390px;
  height: 611px;
  position: absolute;
  top: 0;
  left: 185px;
}

.itservices .image {
  mix-blend-mode: overlay;
  object-fit: cover;
  width: 303px;
  height: 307px;
  position: absolute;
  top: 287px;
  left: 359px;
}

.itservices .frame-26 {
  z-index: 1;
  flex-direction: column;
  align-items: flex-start;
  display: inline-flex;
  position: absolute;
}

.itservices .frame-27 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 32px;
  width: 100%;
  display: flex;
  position: relative;
}

.itservices .group-3 {
  width: 538px;
  height: 183px;
  margin-right: -2px;
  position: relative;
}

.itservices .text-wrapper-15 {
  color: #fff;
  letter-spacing: -1.8px;
  width: 536px;
  font-family: Source Sans Pro;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 101.5%;
  position: absolute;
  top: 0;
  left: 0;
}

.itservices .frame-28 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.itservices .text-wrapper-16 {
  color: #e1e1e1;
  letter-spacing: -.2px;
  width: 519px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.itservices .CTA-5 {
  border: unset !important;
  border-color: unset !important;
  background-color: #4d9f98 !important;
  flex: none !important;
  justify-content: center !important;
  align-items: flex-end !important;
  width: 182px !important;
  display: flex !important;
}

.itservices .CTA-6 {
  font-family: var(--subtitle-BLD-font-family) !important;
  font-size: var(--subtitle-BLD-font-size) !important;
  font-style: var(--subtitle-BLD-font-style) !important;
  font-weight: var(--subtitle-BLD-font-weight) !important;
  letter-spacing: var(--subtitle-BLD-letter-spacing) !important;
  line-height: var(--subtitle-BLD-line-height) !important;
  margin-left: -1px !important;
}

.itservices .CTA-7 {
  margin-right: -1px !important;
}

.itservices .frame-29 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 24px;
  width: 357px;
  display: flex;
  position: relative;
}

.itservices .text-wrapper-17 {
  color: #fff;
  letter-spacing: -.24px;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  position: relative;
}

.itservices .CTA-8 {
  border: unset !important;
  border-color: unset !important;
  background-color: #fff !important;
  flex: none !important;
  justify-content: center !important;
  align-items: flex-end !important;
  width: 201px !important;
  display: flex !important;
}

.itservices .CTA-9 {
  color: #050606 !important;
  font-family: var(--subtitle-BLD-font-family) !important;
  font-size: var(--subtitle-BLD-font-size) !important;
  font-style: var(--subtitle-BLD-font-style) !important;
  font-weight: var(--subtitle-BLD-font-weight) !important;
  letter-spacing: var(--subtitle-BLD-letter-spacing) !important;
  line-height: var(--subtitle-BLD-line-height) !important;
}

.itservices .carousel-2 {
  width: 390px;
  height: 796px;
  position: absolute;
  top: 1387px;
  left: 185px;
  overflow: hidden;
}

.itservices .overlap-group-wrapper {
  width: 667px;
  height: 1425px;
  position: absolute;
  top: 0;
  left: 0;
}

.itservices .overlap-group-3 {
  width: 738px;
  height: 923px;
  position: relative;
  top: -24px;
  left: -185px;
}

.itservices .rectangle-3 {
  width: 390px;
  height: 485px;
  position: absolute;
  top: 24px;
  left: 185px;
}

.itservices .frame-30 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  height: 261px;
  display: inline-flex;
  position: absolute;
  top: 166px;
  left: 265px;
}

.itservices .shutterstock {
  object-fit: cover;
  width: 732px;
  height: 488px;
  position: absolute;
  top: 419px;
  left: 0;
}

.itservices .rectangle-4 {
  background: linear-gradient(90deg, #589993 5.95%, #4d9f98 20.62% 48.33%, #fcfcfc00 71.42%);
  width: 923px;
  height: 687px;
  position: absolute;
  top: 118px;
  left: -67px;
  transform: rotate(90deg);
}

.itservices .frame-31 {
  flex-direction: column;
  align-items: flex-start;
  gap: 33px;
  display: inline-flex;
  position: absolute;
  top: 106px;
  left: 201px;
}

.itservices .we-are-your-growth {
  color: #fff;
  letter-spacing: -.22px;
  width: 358px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 22px;
  font-weight: 600;
  line-height: 30.8px;
  position: relative;
}

.itservices .CTA-10 {
  margin-right: -2px !important;
}

.itservices .CTA-11 {
  background-color: var(--secondary-03) !important;
  border: unset !important;
  border-color: unset !important;
  flex: none !important;
  justify-content: center !important;
  align-items: flex-end !important;
  width: 127px !important;
  display: flex !important;
}

.itservices .CTA-12 {
  font-family: var(--subtitle-BLD-font-family) !important;
  font-size: var(--subtitle-BLD-font-size) !important;
  font-style: var(--subtitle-BLD-font-style) !important;
  font-weight: var(--subtitle-BLD-font-weight) !important;
  letter-spacing: var(--subtitle-BLD-letter-spacing) !important;
  line-height: var(--subtitle-BLD-line-height) !important;
  margin-left: -2px !important;
}

.itservices .frame-32 {
  width: 1440px;
  height: 580px;
  position: absolute;
  top: 0;
  left: 1440px;
  overflow: hidden;
}

.itservices .rectangle-5 {
  width: 1383px;
  height: 634px;
  position: absolute;
  top: -1894px;
  left: -21856px;
}

.itservices .overlap-2 {
  filter: blur(80px);
  background: linear-gradient(#000 0%, #071103 25%, #202020 44.79%, #2f2f2f 61.46%, #0000 100%);
  width: 1684px;
  height: 714px;
  position: absolute;
  top: -60px;
  left: -116px;
}

.itservices .frame-33 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: absolute;
  top: 242px;
  left: 196px;
}

.itservices .text-wrapper-18 {
  color: var(--neutral-0);
  letter-spacing: -.53px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 600;
  line-height: 49px;
  position: relative;
}

.itservices .our-client-and {
  color: #f6f7f8;
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  width: 436px;
  margin-top: -1px;
  position: relative;
}

.itservices .CTA-13 {
  all: unset;
  box-sizing: border-box;
  flex: none;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.itservices .text-wrapper-19 {
  color: var(--neutral-0);
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 23.8px;
  position: relative;
}

.itservices .arrow-forward-2 {
  width: 24px;
  height: 24px;
  margin-top: -2266px;
  margin-left: -22190px;
  position: relative;
}

.itservices .text-wrapper-20 {
  color: var(--neutral-0);
  font-family: var(--h4-REG-font-family);
  font-size: var(--h4-REG-font-size);
  font-style: var(--h4-REG-font-style);
  font-weight: var(--h4-REG-font-weight);
  letter-spacing: var(--h4-REG-letter-spacing);
  line-height: var(--h4-REG-line-height);
  white-space: nowrap;
  height: 41px;
  position: absolute;
  top: 120px;
  left: 196px;
}

.itservices .frame-34 {
  align-items: flex-start;
  gap: 5px;
  display: inline-flex;
  position: absolute;
  top: 560px;
  left: 196px;
}

.itservices .ellipse-4 {
  background-color: #d9d9d952;
  border-radius: 5.5px;
  width: 11px;
  height: 11px;
  position: relative;
}

.itservices .ellipse-5 {
  background-color: var(--neutral-30);
  border-radius: 5.5px;
  width: 11px;
  height: 11px;
  position: relative;
}

.itservices .carousel-03 {
  position: absolute !important;
  top: 0 !important;
  left: 2880px !important;
}

.itservices .carousel-instance {
  margin-top: -2289px !important;
  margin-left: -23630px !important;
}

.itservices .frame-35 {
  width: 742px;
  height: 1050px;
  position: absolute;
  top: 2064px;
  left: 0;
}

.itservices .frame-36 {
  width: 742px;
  height: 307px;
  position: absolute;
  top: 0;
  left: 0;
}

.itservices .overlap-group-4 {
  height: 307px;
  position: relative;
}

.itservices .rectangle-6 {
  background-color: #f6f7f8;
  width: 390px;
  height: 307px;
  position: absolute;
  top: 0;
  left: 185px;
}

.itservices .frame-37 {
  flex-direction: column;
  align-items: center;
  gap: 25px;
  display: inline-flex;
  position: absolute;
  top: 76px;
  left: 0;
}

.itservices .frame-38 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 51px;
  display: inline-flex;
  position: relative;
}

.itservices .frame-39 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 26px;
  display: inline-flex;
  position: relative;
}

.itservices .frame-40 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.itservices .frame-41 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.itservices .text-wrapper-21 {
  color: var(--neutral-60);
  letter-spacing: -.2px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  position: relative;
}

.itservices .frame-42 {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 29px;
  display: inline-flex;
  position: relative;
}

.itservices .mercedes-benz-logo {
  object-fit: cover;
  width: 148px;
  height: 37px;
  position: relative;
}

.itservices .GE-healthcare-logo {
  object-fit: cover;
  width: 98px;
  height: 55px;
  position: relative;
}

.itservices .sony-logo {
  object-fit: cover;
  width: 74px;
  height: 13px;
  position: relative;
}

.itservices .shell-logo {
  object-fit: cover;
  width: 69px;
  height: 39px;
  position: relative;
}

.itservices .amazon {
  object-fit: cover;
  width: 70px;
  height: 33px;
  position: relative;
}

.itservices .wipro-logo-new-og {
  object-fit: cover;
  width: 102px;
  height: 53px;
  position: relative;
}

.itservices .frame-43 {
  flex-direction: column;
  align-items: center;
  gap: 92px;
  display: inline-flex;
  position: absolute;
  top: 366px;
  left: 185px;
}

.itservices .frame-44 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-end;
  gap: 32px;
  width: 328px;
  display: flex;
  position: relative;
}

.itservices .frame-45 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 24px;
  width: 328px;
  display: flex;
  position: relative;
}

.itservices .text-wrapper-22 {
  color: var(--secondary-04);
  letter-spacing: -.8px;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.4px;
  position: relative;
}

.itservices .text-wrapper-23 {
  color: var(--neutral-80);
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  text-align: center;
  width: 297px;
  position: relative;
}

.itservices .group-4 {
  width: 390px;
  height: 360px;
  position: relative;
}

.itservices .frame-46 {
  height: 360px;
}

.itservices .overlap-group-5 {
  background-color: var(--neutral-70);
  width: 1440px;
  height: 360px;
}

.itservices .frame-47 {
  flex-direction: column;
  align-items: center;
  gap: 24px;
  display: inline-flex;
  position: relative;
  top: 85px;
  left: 41px;
}

.itservices .text-wrapper-24 {
  color: var(--neutral-0);
  letter-spacing: -.8px;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.4px;
  position: relative;
}

.itservices .are-we-a-good-fit {
  color: #f6f7f8;
  letter-spacing: -.2px;
  text-align: center;
  width: 309px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.itservices .CTA-14 {
  cursor: pointer;
  background-color: var(--secondary-02);
  box-sizing: border-box;
  border: none;
  outline: none;
  flex: none;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  display: inline-flex;
  position: relative;
}

.itservices .text-wrapper-25 {
  color: var(--neutral-0);
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.itservices .arrow-forward-3 {
  width: 24px;
  height: 24px;
  position: relative;
}

.itservices .component-26 {
  width: 390px !important;
  position: absolute !important;
  top: 594px !important;
  left: 186px !important;
}

.itservices .component-instance {
  margin-right: -47px !important;
}

.itservices .rectangle-7 {
  background-color: #f6f7f8;
  width: 1439px;
  height: 290px;
  position: absolute;
  top: 2264px;
  left: 55px;
}

.itservices .frame-48 {
  flex-direction: column;
  align-items: center;
  gap: 25px;
  display: inline-flex;
  position: absolute;
  top: 2336px;
  left: 193px;
}

.itservices .text-wrapper-26 {
  color: #1f1f1f;
  letter-spacing: -.2px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  position: relative;
}

.itservices .mercedes-benz-logo-2 {
  object-fit: cover;
  width: 248px;
  height: 63px;
  position: relative;
}

.itservices .GE-healthcare-logo-2 {
  object-fit: cover;
  width: 164px;
  height: 92px;
  position: relative;
}

.itservices .sony-logo-2 {
  object-fit: cover;
  width: 123px;
  height: 22px;
  position: relative;
}

.itservices .shell-logo-2 {
  object-fit: cover;
  width: 115px;
  height: 65px;
  position: relative;
}

.itservices .amazon-2 {
  object-fit: cover;
  width: 178px;
  height: 55px;
  position: relative;
}

.itservices .wipro-logo-new-og-2 {
  object-fit: cover;
  width: 171px;
  height: 89px;
  position: relative;
}

.itservices .image-2 {
  object-fit: cover;
  width: 1453px;
  height: 657px;
  position: absolute;
  top: 752px;
  left: 45px;
}

.itservices .hero-2 {
  width: 1439px;
  height: 679px;
  position: absolute;
  top: 752px;
  left: 55px;
}

.itservices .header-2 {
  background-color: #0000;
  justify-content: space-between;
  align-items: center;
  width: 1440px;
  padding: 16px 80px;
  display: flex;
  position: absolute;
  top: 0;
  left: 58px;
}

.itservices .logo-2 {
  object-fit: cover;
  width: 145px;
  height: 67.67px;
  position: relative;
}

.itservices .holder {
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.itservices .frame-49 {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 90.33px;
  padding: 10px 8px;
  display: flex;
  position: relative;
}

.itservices .text-wrapper-27 {
  color: #000;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: relative;
}

.itservices .text-wrapper-28 {
  color: #000;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-left: -2.83px;
  margin-right: -2.83px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: relative;
}

.itservices .frame-50 {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 80px;
  padding: 10px 8px;
  display: flex;
  position: relative;
}

.itservices .CTA-15 {
  border-color: var(--primary-02) !important;
  width: 179px !important;
  height: 69px !important;
  display: flex !important;
}

.itservices .CTA-16 {
  color: #000 !important;
  margin-top: unset !important;
}

.itservices .group-5 {
  width: 722px;
  height: 328px;
  position: absolute;
  top: 916px;
  left: 687px;
}

.itservices .CTA-17 {
  border: unset !important;
  border-color: unset !important;
  background-color: #fff !important;
  justify-content: center !important;
  align-items: flex-end !important;
  width: 182px !important;
  display: flex !important;
  position: absolute !important;
  top: 280px !important;
  left: 0 !important;
}

.itservices .CTA-18 {
  color: #050606 !important;
  font-family: var(--subtitle-BLD-font-family) !important;
  font-size: var(--subtitle-BLD-font-size) !important;
  font-style: var(--subtitle-BLD-font-style) !important;
  font-weight: var(--subtitle-BLD-font-weight) !important;
  letter-spacing: var(--subtitle-BLD-letter-spacing) !important;
  line-height: var(--subtitle-BLD-line-height) !important;
  margin-left: -1px !important;
}

.itservices .frame-51 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 714px;
  display: flex;
  position: absolute;
  top: 0;
  left: 8px;
}

.itservices .text-wrapper-29 {
  color: #fff;
  letter-spacing: -.32px;
  width: 740px;
  margin-top: -1px;
  margin-right: -26px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 600;
  line-height: 44.8px;
  position: relative;
}

.itservices .component-25 {
  position: absolute !important;
  top: 1429px !important;
  left: 55px !important;
}

.itservices .image-3 {
  mix-blend-mode: overlay;
  object-fit: cover;
  width: 658px;
  height: 541px;
  position: absolute;
  top: 823px;
  left: 66px;
}

.itservices .frame-52 {
  flex-direction: column;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: -275px;
  left: 361px;
}

.itservices .let-s-connect-you-ve {
  color: #0000;
  letter-spacing: -.75px;
  text-align: center;
  width: 940px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 50px;
  font-weight: 400;
  line-height: 60px;
  position: relative;
}

.itservices .span {
  color: #00dfc2;
  font-weight: 700;
}

.itservices .text-wrapper-30 {
  color: #fff;
  font-family: var(--h1-REG-font-family);
  font-size: var(--h1-REG-font-size);
  font-style: var(--h1-REG-font-style);
  font-weight: var(--h1-REG-font-weight);
  letter-spacing: var(--h1-REG-letter-spacing);
  line-height: var(--h1-REG-line-height);
}

.itservices .text-wrapper-31 {
  color: #f6f7f8;
  letter-spacing: -.2px;
  text-align: center;
  width: 897px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.itservices .overlap-3 {
  width: 1441px;
  height: 662px;
  position: absolute;
  top: 3039px;
  left: -1px;
}

.itservices .overlap-wrapper {
  width: 1440px;
  height: 380px;
  position: absolute;
  top: 0;
  left: 1px;
}

.itservices .overlap-4 {
  height: 380px;
  position: relative;
}

.itservices .frame-53 {
  background-color: #4d4d4d;
  width: 1440px;
  height: 380px;
  position: absolute;
  top: 0;
  left: 0;
}

.itservices .frame-54 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 69px;
  left: 152px;
}

.itservices .frame-55 {
  flex-direction: column;
  flex: none;
  align-items: flex-end;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.itservices .text-wrapper-32 {
  color: #fff;
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.itservices .are-we-a-good-fit-2 {
  color: var(--neutral-10);
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  text-align: right;
  width: 429px;
  position: relative;
}

.itservices .CTA-19 {
  all: unset;
  background-color: var(--secondary-04);
  box-sizing: border-box;
  flex: none;
  align-items: center;
  gap: 10px;
  padding: 12px 16px;
  display: inline-flex;
  position: relative;
}

.itservices .text-wrapper-33 {
  color: #fff;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: relative;
}

.itservices .CTA-20 {
  cursor: pointer;
  background-color: var(--secondary-02);
  box-sizing: border-box;
  border: none;
  outline: none;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  display: inline-flex;
  position: absolute;
  top: 267px;
  left: 821px;
}

.itservices .frame-56 {
  background-color: #2e5053;
  width: 720px;
  height: 380px;
  position: absolute;
  top: 0;
  left: 0;
}

.itservices .overlap-group-6 {
  background-color: var(--neutral-10);
  width: 720px;
  height: 380px;
  position: absolute;
  top: 0;
  left: 0;
}

.itservices .frame-57 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 32px;
  display: inline-flex;
  position: relative;
  top: 49px;
  left: 128px;
}

.itservices .text-wrapper-34 {
  color: var(--secondary-04);
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.itservices .text-wrapper-35 {
  color: var(--neutral-80);
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  text-align: right;
  width: 500px;
  position: relative;
}

.itservices .frame-58 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 52px;
  left: 817px;
}

.itservices .text-wrapper-36 {
  color: #fff;
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.itservices .text-wrapper-37 {
  color: var(--neutral-10);
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  width: 429px;
  position: relative;
}

.itservices .footer-2 {
  background-color: var(--white);
  width: 1441px;
  height: 292px;
  position: absolute;
  top: 370px;
  left: 0;
}

.itservices .overlap-5 {
  width: 1441px;
  height: 292px;
  position: relative;
}

.itservices .frame-59 {
  background-color: #1f1f1f;
  width: 1441px;
  height: 292px;
  position: absolute;
  top: 0;
  left: 0;
}

.itservices .DIV-3 {
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 53px;
  left: 738px;
}

.itservices .text-wrapper-38 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1233px;
}

.itservices .text-wrapper-39 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1282px;
}

.itservices .text-wrapper-40 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1331px;
}

.itservices .text-wrapper-41 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1187px;
}

.itservices .text-wrapper-42 {
  color: var(--white);
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  white-space: nowrap;
  height: 28px;
  position: absolute;
  top: 100px;
  left: 738px;
}

.itservices .text-wrapper-43 {
  color: var(--white);
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  white-space: nowrap;
  height: 28px;
  position: absolute;
  top: 100px;
  left: 869px;
}

.itservices .frame-60 {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: absolute;
  top: 115px;
  left: 81px;
}

.itservices .group-6 {
  width: 37px;
  height: 52px;
  position: absolute;
  top: 152px;
  left: 1140px;
}

.itservices .linkedin-2 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1187px;
}

.itservices .facebook-2 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1234px;
}

.itservices .facebook-3 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1281px;
}

.itservices .facebook-4 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1328px;
}

.itservices .group-7 {
  width: 400px;
  height: 17px;
  position: absolute;
  top: 242px;
  left: 501px;
}

.itservices .frame-61 {
  justify-content: center;
  align-items: center;
  gap: 5px;
  display: inline-flex;
  position: relative;
}

.itservices .text-wrapper-44 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.itservices .ellipse-6 {
  background-color: var(--grey-01);
  border-radius: 1.5px;
  width: 3px;
  height: 3px;
  position: relative;
}

.itservices .logo-3 {
  width: 116px;
  height: 56px;
  position: absolute;
  top: 39px;
  left: 76px;
}

.itservices .carousel-3 {
  width: 1440px;
  height: 488px;
  position: absolute;
  top: 2551px;
  left: -1px;
  overflow: hidden;
}

.itservices .frame-62 {
  width: 1440px;
  height: 488px;
  position: absolute;
  top: 0;
  left: 0;
}

.itservices .overlap-group-7 {
  width: 2146px;
  height: 697px;
  position: relative;
  top: -130px;
  left: -231px;
}

.itservices .rectangle-8 {
  width: 1439px;
  height: 485px;
  position: absolute;
  top: 130px;
  left: 232px;
}

.itservices .shutterstock-2 {
  object-fit: cover;
  width: 732px;
  height: 488px;
  position: absolute;
  top: 130px;
  left: 939px;
}

.itservices .rectangle-9 {
  filter: blur(40px);
  background: linear-gradient(90deg, #589993 5.95%, #4d9f98 20.62% 48.33%, #fcfcfc00 71.42%);
  width: 2146px;
  height: 697px;
  position: absolute;
  top: 0;
  left: 0;
}

.itservices .text-wrapper-45 {
  color: #fff;
  letter-spacing: -.28px;
  width: 596px;
  height: 207px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 28px;
  font-weight: 400;
  line-height: 39.2px;
  position: absolute;
  top: 246px;
  left: 311px;
}

.itservices .CTA-21 {
  border: unset !important;
  border-color: unset !important;
  background-color: #2e5053 !important;
  justify-content: center !important;
  align-items: flex-end !important;
  width: 182px !important;
  display: flex !important;
  position: absolute !important;
  top: 473px !important;
  left: 311px !important;
}

.itservices .rectangle-10 {
  width: 1383px;
  height: 634px;
  position: absolute;
  top: -2586px;
  left: -20343px;
}

.itservices .arrow-forward-4 {
  width: 24px;
  height: 24px;
  margin-top: -2958px;
  margin-left: -20677px;
  position: relative;
}

.itservices .carousel-03-instance {
  margin-top: -2981px !important;
  margin-left: -22117px !important;
}

.carousel-no .rectangle {
  object-fit: cover;
  width: 1087px;
  height: 656px;
  position: absolute;
  top: 58px;
  left: 718px;
}

.carousel-no .div {
  filter: blur(80px);
  background: linear-gradient(#000 0%, #071103 25%, #202020 44.79%, #2f2f2f 61.46%, #0000 89.06%);
  width: 1676px;
  height: 714px;
  position: absolute;
  top: 0;
  left: 0;
}

.carousel-no .frame {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: absolute;
  top: 214px;
  left: 243px;
}

.carousel-no .with-efficient-and {
  color: var(--neutral-10);
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  width: 461px;
  margin-top: -1px;
  position: relative;
}

.carousel-no .frame-3 {
  align-items: flex-start;
  gap: 5px;
  display: inline-flex;
  position: absolute;
  top: 558px;
  left: 243px;
}

.carousel-no .ellipse {
  background-color: #f6f7f852;
  border-radius: 5.5px;
  width: 11px;
  height: 11px;
  position: relative;
}

.carousel-no .ellipse-2 {
  background-color: var(--neutral-30);
  border-radius: 5.5px;
  width: 11px;
  height: 11px;
  position: relative;
}

.CTA {
  all: unset;
  box-sizing: border-box;
  background-color: #4d9f98;
  border-radius: 56px;
  justify-content: center;
  align-items: flex-end;
  gap: 10px;
  width: 182px;
  padding: 12px 16px;
  display: flex;
  position: relative;
}

.CTA .text-wrapper {
  color: #fff;
  letter-spacing: -.15px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 15px;
  font-weight: 700;
  line-height: 21px;
  position: relative;
}

.property-default-wrapper {
  border-radius: 56px;
  align-items: center;
  padding: 12px 16px;
  display: inline-flex;
  position: relative;
}

.property-default-wrapper .this-is-who-we-are {
  color: #fff;
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.property-default-wrapper .img {
  width: 24px;
  height: 24px;
  position: relative;
}

.property-default-wrapper.hover {
  background-color: var(--primary-02);
  gap: 8px;
}

.property-default-wrapper.default {
  border: 1px solid #fff;
  gap: 10px;
}

.state-default-wrapper {
  all: unset;
  border: 1px solid;
  border-color: var(--secondary-03);
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 12px 24px;
  display: inline-flex;
  position: relative;
}

.state-default-wrapper .text-wrapper-4 {
  color: var(--secondary-03);
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.state-default-wrapper.default-true {
  border-radius: 40px;
}

.state-default-wrapper:hover {
  background-color: var(--primary-02);
  border-radius: 45px;
}

.state-default-wrapper:hover .text-wrapper-4 {
  color: #fff;
}

.staffing {
  background-color: #fff;
  flex-direction: row;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.staffing .iphone {
  background-color: #fff;
  position: relative;
}

.staffing .header {
  width: 390px !important;
  height: 60px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.staffing .frame-4 {
  position: absolute;
}

.staffing .overlap {
  height: 519px;
  position: relative;
}

.staffing .frame-5 {
  background-color: #f6f7f8;
  height: 519px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 390px !important;
}

.staffing .overlap-2 {
  width: 1454px;
  height: 1178px;
  position: absolute;
  top: 0;
  left: 0;
}

.staffing .overlap-3 {
  width: 1435px;
  height: 1003px;
  position: absolute;
  top: 175px;
  left: 19px;
}

.staffing .ellipse-3 {
  border: 2px solid;
  border-color: var(--neutral-40);
  border-radius: 261px;
  width: 522px;
  height: 522px;
  position: absolute;
  top: 14px;
  left: 816px;
}

.staffing .ellipse-4 {
  background-color: #fff;
  border-radius: 233px;
  width: 466px;
  height: 466px;
  position: absolute;
  top: 42px;
  left: 844px;
}

.staffing .rectangle-2 {
  mix-blend-mode: multiply;
  background: linear-gradient(#000 0%, #d9d9d900 100%);
  width: 639px;
  height: 298px;
  position: absolute;
  top: 0;
  left: 625px;
}

.staffing .rectangle-3 {
  background-color: #d9d9d9;
  width: 1435px;
  height: 498px;
  position: absolute;
  top: 505px;
  left: 0;
}

.staffing .overlap-group-2 {
  width: 394px;
  height: 519px;
  position: absolute;
  top: 0;
  left: 0;
}

.staffing .business-people {
  object-fit: cover;
  width: 390px;
  height: 519px;
  position: absolute;
  top: 0;
  left: 0;
}

.staffing .rectangle-4 {
  background: linear-gradient(#fff 33.86%, #d9d9d900 100%);
  width: 445px;
  height: 394px;
  position: absolute;
  top: 128px;
  left: -25px;
  transform: rotate(-180deg);
}

.staffing .ellipse-5 {
  object-fit: cover;
  width: 436px;
  height: 436px;
  position: absolute;
  top: 164px;
  left: -17410px;
}

.staffing .frame-6 {
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 358px;
  padding: 0 54px;
  display: flex;
  position: absolute;
  top: 154px;
  left: 16px;
}

.staffing .text-wrapper-5 {
  color: #202020;
  letter-spacing: -.8px;
  text-align: center;
  width: 304px;
  margin-top: -1px;
  margin-left: -27px;
  margin-right: -27px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 600;
  line-height: 38.4px;
  position: relative;
}

.staffing .text-wrapper-6 {
  color: #353535;
  letter-spacing: -.16px;
  text-align: center;
  width: 282px;
  margin-left: -16px;
  margin-right: -16px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  position: relative;
}

.staffing .CTA-instance {
  flex: none !important;
}

.staffing .rectangle-5 {
  mix-blend-mode: lighten;
  width: 328px;
  height: 465px;
  position: absolute;
  top: 0;
  left: 1113px;
}

.staffing .business-people-2 {
  width: 988px;
  height: 519px;
  position: absolute;
  top: 89px;
  left: 453px;
}

.staffing .rectangle-6 {
  background: linear-gradient(90deg, #fff 49.83%, #d9d9d900 146.5%);
  width: 976px;
  height: 519px;
  position: absolute;
  top: 89px;
  left: 1px;
}

.staffing .frame-7 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 35px;
  display: inline-flex;
  position: absolute;
  top: 157px;
  left: 81px;
}

.staffing .div-wrapper {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 31px;
  display: inline-flex;
  position: relative;
}

.staffing .text-wrapper-7 {
  color: #2e5053;
  letter-spacing: -.75px;
  width: 573px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 50px;
  font-weight: 600;
  line-height: 60px;
  position: relative;
}

.staffing .joulestowatts {
  color: #0000;
  letter-spacing: -.2px;
  width: 576px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.staffing .span {
  color: #2d2d2d;
}

.staffing .text-wrapper-8 {
  color: #4d9f98;
  font-weight: 600;
}

.staffing .instance-node {
  border: unset !important;
  border-color: unset !important;
  background-color: #4d9f98 !important;
  flex: none !important;
  justify-content: center !important;
  align-items: flex-end !important;
  width: 182px !important;
  display: flex !important;
}

.staffing .CTA-2 {
  font-family: Source Sans Pro, Helvetica !important;
  font-size: 17px !important;
  font-style: unset !important;
  letter-spacing: -.17px !important;
  margin-left: -1px !important;
  font-weight: 700 !important;
  line-height: 23.8px !important;
}

.staffing .CTA-3 {
  margin-right: -1px !important;
}

.staffing .rectangle-7 {
  width: 359px;
  height: 719px;
  position: absolute;
  top: 40px;
  left: 1082px;
}

.staffing .group {
  background-color: #022c27;
  width: 1441px;
  height: 636px;
  position: absolute;
  top: 607px;
  left: 1px;
}

.staffing .group-2 {
  width: 1276px;
  height: 506px;
  position: relative;
  top: 50px;
  left: 86px;
}

.staffing .overlap-4 {
  width: 1274px;
  height: 391px;
  position: absolute;
  top: 115px;
  left: 0;
}

.staffing .overlap-wrapper {
  width: 1274px;
  height: 391px;
  position: absolute;
  top: 0;
  left: 0;
}

.staffing .overlap-5 {
  height: 391px;
  position: relative;
}

.staffing .frame-8 {
  width: 1274px;
  height: 391px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.staffing .overlap-group-3 {
  width: 1063px;
  height: 344px;
  position: absolute;
  top: 64px;
  left: 6px;
}

.staffing .group-3 {
  width: 949px;
  height: 326px;
  position: absolute;
  top: 0;
  left: 0;
}

.staffing .vector {
  width: 30px;
  height: 21px;
  position: absolute;
  top: 116px;
  left: 176px;
}

.staffing .vector-2 {
  width: 30px;
  height: 21px;
  position: absolute;
  top: 116px;
  left: 473px;
}

.staffing .vector-3 {
  width: 30px;
  height: 21px;
  position: absolute;
  top: 116px;
  left: 770px;
}

.staffing .vector-4 {
  width: 30px;
  height: 21px;
  position: absolute;
  top: 116px;
  left: 321px;
}

.staffing .vector-5 {
  width: 30px;
  height: 21px;
  position: absolute;
  top: 117px;
  left: 618px;
}

.staffing .gamification-based {
  color: var(--neutral-10);
  font-family: var(--subtitle-BLD-font-family);
  font-size: var(--subtitle-BLD-font-size);
  font-style: var(--subtitle-BLD-font-style);
  font-weight: var(--subtitle-BLD-font-weight);
  letter-spacing: var(--subtitle-BLD-letter-spacing);
  line-height: var(--subtitle-BLD-line-height);
  text-align: center;
  width: 118px;
  position: absolute;
  top: 165px;
  left: 53px;
}

.staffing .text-wrapper-9 {
  color: var(--neutral-10);
  font-family: var(--subtitle-BLD-font-family);
  font-size: var(--subtitle-BLD-font-size);
  font-style: var(--subtitle-BLD-font-style);
  font-weight: var(--subtitle-BLD-font-weight);
  letter-spacing: var(--subtitle-BLD-letter-spacing);
  line-height: var(--subtitle-BLD-line-height);
  text-align: center;
  width: 118px;
  position: absolute;
  top: 270px;
  left: 202px;
}

.staffing .acceleration-through {
  color: var(--neutral-10);
  font-family: var(--subtitle-BLD-font-family);
  font-size: var(--subtitle-BLD-font-size);
  font-style: var(--subtitle-BLD-font-style);
  font-weight: var(--subtitle-BLD-font-weight);
  letter-spacing: var(--subtitle-BLD-letter-spacing);
  line-height: var(--subtitle-BLD-line-height);
  text-align: center;
  width: 118px;
  position: absolute;
  top: 165px;
  left: 354px;
}

.staffing .real-time-dashboards {
  color: var(--neutral-10);
  font-family: var(--subtitle-BLD-font-family);
  font-size: var(--subtitle-BLD-font-size);
  font-style: var(--subtitle-BLD-font-style);
  font-weight: var(--subtitle-BLD-font-weight);
  letter-spacing: var(--subtitle-BLD-letter-spacing);
  line-height: var(--subtitle-BLD-line-height);
  text-align: center;
  width: 118px;
  position: absolute;
  top: 270px;
  left: 503px;
}

.staffing .text-wrapper-10 {
  color: var(--neutral-10);
  font-family: var(--subtitle-BLD-font-family);
  font-size: var(--subtitle-BLD-font-size);
  font-style: var(--subtitle-BLD-font-style);
  font-weight: var(--subtitle-BLD-font-weight);
  letter-spacing: var(--subtitle-BLD-letter-spacing);
  line-height: var(--subtitle-BLD-line-height);
  text-align: center;
  width: 118px;
  position: absolute;
  top: 270px;
  left: 797px;
}

.staffing .text-wrapper-11 {
  color: var(--neutral-10);
  font-family: var(--subtitle-BLD-font-family);
  font-size: var(--subtitle-BLD-font-size);
  font-style: var(--subtitle-BLD-font-style);
  font-weight: var(--subtitle-BLD-font-weight);
  letter-spacing: var(--subtitle-BLD-letter-spacing);
  line-height: var(--subtitle-BLD-line-height);
  text-align: center;
  width: 118px;
  position: absolute;
  top: 165px;
  left: 946px;
}

.staffing .employee-experience {
  color: var(--neutral-10);
  font-family: var(--subtitle-BLD-font-family);
  font-size: var(--subtitle-BLD-font-size);
  font-style: var(--subtitle-BLD-font-style);
  font-weight: var(--subtitle-BLD-font-weight);
  letter-spacing: var(--subtitle-BLD-letter-spacing);
  line-height: var(--subtitle-BLD-line-height);
  text-align: center;
  width: 118px;
  position: absolute;
  top: 165px;
  left: 647px;
}

.staffing .text-wrapper-12 {
  color: var(--neutral-10);
  font-family: var(--subtitle-BLD-font-family);
  font-size: var(--subtitle-BLD-font-size);
  font-style: var(--subtitle-BLD-font-style);
  font-weight: var(--subtitle-BLD-font-weight);
  letter-spacing: var(--subtitle-BLD-letter-spacing);
  line-height: var(--subtitle-BLD-line-height);
  text-align: center;
  width: 118px;
  position: absolute;
  top: 334px;
  left: 1106px;
}

.staffing .group-4 {
  width: 177px;
  height: 154px;
  position: absolute;
  top: 66px;
  left: 918px;
}

.staffing .group-5 {
  width: 177px;
  height: 154px;
  position: absolute;
  top: 171px;
  left: 1070px;
}

.staffing .vector-6 {
  width: 31px;
  height: 21px;
  position: absolute;
  top: 182px;
  left: 1069px;
}

.staffing .vector-7 {
  width: 31px;
  height: 21px;
  position: absolute;
  top: 182px;
  left: 913px;
}

.staffing .apple-arcade {
  width: 53px;
  height: 53px;
  position: absolute;
  top: 115px;
  left: 94px;
}

.staffing .crowd {
  width: 57px;
  height: 57px;
  position: absolute;
  top: 219px;
  left: 241px;
}

.staffing .automatic-brightness {
  width: 49px;
  height: 49px;
  position: absolute;
  top: 115px;
  left: 393px;
}

.staffing .dashboard-layout {
  width: 47px;
  height: 47px;
  position: absolute;
  top: 219px;
  left: 543px;
}

.staffing .payroll {
  width: 49px;
  height: 49px;
  position: absolute;
  top: 115px;
  left: 696px;
}

.staffing .checked-laptop {
  width: 46px;
  height: 46px;
  position: absolute;
  top: 223px;
  left: 841px;
}

.staffing .group-task {
  width: 46px;
  height: 46px;
  position: absolute;
  top: 121px;
  left: 983px;
}

.staffing .microsoft-mixer {
  width: 44px;
  height: 44px;
  position: absolute;
  top: 228px;
  left: 1137px;
}

.staffing .text-wrapper-13 {
  color: #f6f7f8;
  letter-spacing: -.3px;
  text-align: center;
  width: 828px;
  height: 84px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 30px;
  font-weight: 600;
  line-height: 42px;
  position: absolute;
  top: 0;
  left: 254px;
}

.staffing .header-2 {
  background-color: var(--neutral-10);
  justify-content: space-between;
  align-items: center;
  width: 1440px;
  padding: 16px 80px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.staffing .logo {
  width: 116px;
  height: 56.67px;
  position: relative;
}

.staffing .holder {
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.staffing .frame-9 {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 90.33px;
  padding: 10px 8px;
  display: flex;
  position: relative;
}

.staffing .text-wrapper-14 {
  color: var(--neutral-90);
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.staffing .text-wrapper-15 {
  color: var(--neutral-90);
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-left: -2.83px;
  margin-right: -2.83px;
  position: relative;
}

.staffing .frame-10 {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 80px;
  padding: 10px 8px;
  display: flex;
  position: relative;
}

.staffing .CTA-4 {
  border-color: var(--primary-02) !important;
  flex: none !important;
}

.staffing .CTA-5 {
  color: var(--primary-02) !important;
}

.staffing .footer {
  background-color: var(--white);
  width: 390px;
  height: 860px;
  position: absolute;
  top: 8973px;
  left: 0;
  overflow: hidden;
}

.staffing .overlap-6 {
  width: 390px;
  height: 907px;
  position: relative;
}

.staffing .frame-11 {
  background-color: var(--neutral-90);
  width: 390px;
  height: 907px;
  position: absolute;
  top: 0;
  left: 0;
}

.staffing .frame-12 {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: absolute;
  top: 115px;
  left: 26px;
}

.staffing .text-wrapper-16 {
  color: #fff;
  font-family: var(--paragraph-ALCPS-font-family);
  font-size: var(--paragraph-ALCPS-font-size);
  font-style: var(--paragraph-ALCPS-font-style);
  font-weight: var(--paragraph-ALCPS-font-weight);
  letter-spacing: var(--paragraph-ALCPS-letter-spacing);
  line-height: var(--paragraph-ALCPS-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.staffing .text-wrapper-17 {
  color: var(--neutral-10);
  font-family: var(--paragraph-REG-font-family);
  font-size: var(--paragraph-REG-font-size);
  font-style: var(--paragraph-REG-font-style);
  font-weight: var(--paragraph-REG-font-weight);
  letter-spacing: var(--paragraph-REG-letter-spacing);
  line-height: var(--paragraph-REG-line-height);
  width: 324px;
  position: relative;
}

.staffing .DIV {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 113px;
  display: flex;
  position: absolute;
  top: 272px;
  left: 195px;
}

.staffing .text-wrapper-18 {
  color: var(--white);
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.staffing .text-wrapper-19 {
  color: var(--white);
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.staffing .DIV-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 113px;
  display: flex;
  position: absolute;
  top: 272px;
  left: 26px;
}

.staffing .frame-13 {
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 583px;
  left: 69px;
}

.staffing .group-6 {
  width: 37px;
  height: 52px;
  position: relative;
}

.staffing .text-wrapper-20 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 38px;
  left: 1px;
}

.staffing .face-book {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 0;
  left: 0;
}

.staffing .frame-14 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.staffing .linkedin {
  flex: none;
  position: relative;
}

.staffing .text-wrapper-21 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  width: fit-content;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: relative;
}

.staffing .facebook {
  width: 35px;
  height: 35px;
  position: relative;
}

.staffing .frame-15 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.staffing .logo-2 {
  width: 116px;
  height: 56px;
  position: absolute;
  top: 39px;
  left: 24px;
}

.staffing .frame-wrapper {
  width: 295px;
  height: 64px;
  position: absolute;
  top: 732px;
  left: 48px;
}

.staffing .frame-16 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 295px;
  display: flex;
  position: relative;
}

.staffing .text-wrapper-22 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.staffing .ellipse-6 {
  background-color: var(--grey-01);
  border-radius: 1px / 1.5px;
  width: 2px;
  height: 3px;
  position: relative;
}

.staffing .text-wrapper-23 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  text-align: center;
  align-self: stretch;
  position: relative;
}

.staffing .overlap-7 {
  position: absolute;
  left: 0;
}

.staffing .frame-17, .staffing .frame-18 {
  position: absolute;
}

.staffing .group-7 {
  width: 172.23px;
  height: 153.73px;
  position: relative;
}

.staffing .text-wrapper-24 {
  color: var(--neutral-10);
  font-family: var(--subtitle-BLD-font-family);
  font-size: var(--subtitle-BLD-font-size);
  font-style: var(--subtitle-BLD-font-style);
  font-weight: var(--subtitle-BLD-font-weight);
  letter-spacing: var(--subtitle-BLD-letter-spacing);
  line-height: var(--subtitle-BLD-line-height);
  text-align: center;
  width: 117.56px;
  height: 43.95px;
  position: relative;
}

.staffing .crowd-2 {
  width: 57px;
  height: 57px;
  position: absolute;
  top: 50px;
  left: 57px;
}

.staffing .text-wrapper-25 {
  color: #1f1f1f;
  letter-spacing: -.75px;
  width: 796px;
  height: 120px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 50px;
  font-weight: 600;
  line-height: 60px;
  position: absolute;
  top: 0;
  left: 33px;
}

.staffing .overlap-group-wrapper {
  width: 534px;
  height: 133px;
  position: absolute;
  top: 110px;
  left: 0;
}

.staffing .overlap-8 {
  width: 532px;
  height: 133px;
  position: relative;
}

.staffing .rectangle-8 {
  background-color: #e9e9e9;
  border-radius: 14px;
  width: 449px;
  height: 98px;
  position: absolute;
  top: 11px;
  left: 83px;
}

.staffing .text-wrapper-26 {
  color: #2c3e47;
  letter-spacing: -.24px;
  width: 307px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
  position: absolute;
  top: 26px;
  left: 152px;
}

.staffing .img-wrapper {
  background-image: url("group.ff05e3c3.png");
  background-size: 100% 100%;
  width: 133px;
  height: 133px;
  position: absolute;
  top: 0;
  left: 0;
}

.staffing .connect {
  width: 53px;
  height: 53px;
  position: absolute;
  top: 34px;
  left: 39px;
}

.staffing .group-8 {
  width: 538px;
  height: 133px;
  position: absolute;
  top: 232px;
  left: 0;
}

.staffing .overlap-9 {
  width: 536px;
  height: 133px;
  position: relative;
}

.staffing .rectangle-9 {
  background-color: #e9e9e9;
  border-radius: 14px;
  width: 449px;
  height: 98px;
  position: absolute;
  top: 8px;
  left: 87px;
}

.staffing .text-wrapper-27 {
  color: #2c3e47;
  letter-spacing: -.24px;
  width: 307px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
  position: absolute;
  top: 23px;
  left: 154px;
}

.staffing .process {
  width: 48px;
  height: 48px;
  position: absolute;
  top: 35px;
  left: 43px;
}

.staffing .group-9 {
  width: 534px;
  height: 133px;
  position: absolute;
  top: 476px;
  left: 0;
}

.staffing .rectangle-10 {
  background-color: #e9e9e9;
  border-radius: 14px;
  width: 449px;
  height: 98px;
  position: absolute;
  top: 13px;
  left: 83px;
}

.staffing .text-wrapper-28 {
  color: #2c3e47;
  letter-spacing: -.24px;
  width: 330px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
  position: absolute;
  top: 28px;
  left: 154px;
}

.staffing .time {
  width: 47px;
  height: 47px;
  position: absolute;
  top: 36px;
  left: 43px;
}

.staffing .group-10 {
  width: 534px;
  height: 133px;
  position: absolute;
  top: 354px;
  left: 0;
}

.staffing .workflow {
  width: 41px;
  height: 41px;
  position: absolute;
  top: 38px;
  left: 46px;
}

.staffing .group-11 {
  width: 536px;
  height: 133px;
  position: absolute;
  top: 598px;
  left: 0;
}

.staffing .overlap-10 {
  width: 538px;
  height: 133px;
  position: relative;
}

.staffing .group-12 {
  width: 451px;
  height: 98px;
  position: absolute;
  top: 13px;
  left: 87px;
}

.staffing .overlap-group-4 {
  background-color: #e9e9e9;
  border-radius: 14px;
  width: 449px;
  height: 98px;
  position: relative;
}

.staffing .text-wrapper-29 {
  color: #2c3e47;
  letter-spacing: -.24px;
  width: 330px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
  position: absolute;
  top: 15px;
  left: 67px;
}

.staffing .add-user-group-man {
  width: 44px;
  height: 44px;
  position: absolute;
  top: 37px;
  left: 45px;
}

.staffing .overlap-11 {
  position: absolute;
}

.staffing .frame-19 {
  flex-direction: column;
  align-items: center;
  gap: 12px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 175px;
}

.staffing .group-13 {
  width: 172.22px;
  height: 153.73px;
  position: relative;
}

.staffing .text-wrapper-30 {
  color: var(--neutral-10);
  font-family: var(--subtitle-BLD-font-family);
  font-size: var(--subtitle-BLD-font-size);
  font-style: var(--subtitle-BLD-font-style);
  font-weight: var(--subtitle-BLD-font-weight);
  letter-spacing: var(--subtitle-BLD-letter-spacing);
  line-height: var(--subtitle-BLD-line-height);
  text-align: center;
  width: 117.56px;
  height: 74.26px;
  position: relative;
}

.staffing .dashboard-layout-2 {
  width: 47px;
  height: 47px;
  position: absolute;
  top: 50px;
  left: 62px;
}

.staffing .overlap-group-5 {
  width: 349px;
  height: 684px;
  position: absolute;
  top: 154px;
  left: 0;
}

.staffing .frame-20 {
  flex-direction: column;
  align-items: center;
  gap: 12px;
  display: inline-flex;
  position: absolute;
  top: 146px;
  left: 172px;
}

.staffing .checked-laptop-wrapper {
  background-image: url("group-10.8c627905.png");
  background-size: 100% 100%;
  width: 172.22px;
  height: 153.73px;
  position: relative;
}

.staffing .checked-laptop-2 {
  width: 46px;
  height: 46px;
  position: absolute;
  top: 54px;
  left: 63px;
}

.staffing .frame-21 {
  flex-direction: column;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  position: absolute;
  top: 446px;
  left: 172px;
}

.staffing .microsoft-mixer-wrapper {
  background-image: url("group-11.f3918c71.png");
  background-size: 100% 100%;
  width: 177.09px;
  height: 153.88px;
  position: relative;
}

.staffing .microsoft-mixer-2 {
  width: 44px;
  height: 44px;
  position: absolute;
  top: 57px;
  left: 67px;
}

.staffing .frame-22 {
  flex-direction: column;
  align-items: center;
  gap: 12px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
}

.staffing .payroll-wrapper {
  background-image: url("group-14.b6bd46ed.png");
  background-size: 100% 100%;
  width: 172.22px;
  height: 153.73px;
  position: relative;
}

.staffing .payroll-2 {
  width: 49px;
  height: 49px;
  position: absolute;
  top: 51px;
  left: 67px;
}

.staffing .frame-23 {
  flex-direction: column;
  align-items: center;
  gap: 11px;
  display: inline-flex;
  position: absolute;
  top: 294px;
  left: 0;
}

.staffing .group-task-wrapper {
  background-image: url("group-15.df5d8955.png");
  background-size: 100% 100%;
  width: 177.09px;
  height: 153.88px;
  position: relative;
}

.staffing .group-task-2 {
  width: 46px;
  height: 46px;
  position: absolute;
  top: 55px;
  left: 65px;
}

.staffing .automatic-brightness-wrapper {
  background-image: url("group-13.5996ffa4.png");
  background-size: 100% 100%;
  width: 172.22px;
  height: 153.73px;
  position: relative;
}

.staffing .automatic-brightness-2 {
  width: 49px;
  height: 49px;
  position: absolute;
  top: 51px;
  left: 61px;
}

.staffing .frame-24 {
  flex-direction: column;
  display: inline-flex;
  position: absolute;
}

.staffing .apple-arcade-2 {
  width: 53px;
  height: 53px;
  position: absolute;
  top: 51px;
  left: 59px;
}

.staffing .text-wrapper-31 {
  color: var(--secondary-03, #2e5053);
  text-align: center;
  letter-spacing: -1.8px;
  white-space: nowrap;
  width: 109px;
  height: 48px;
  margin-top: -1px;
  font-family: Source Sans Pro;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 101.5%;
  position: relative;
}

.staffing .text-wrapper-32 {
  color: #2c3e47;
  letter-spacing: -.17px;
  width: 207px;
  height: 67px;
  margin-top: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: relative;
}

.staffing .frame-25 {
  background-color: #d3d3d3;
  background-image: linear-gradient(0deg, #000c 0%, #0000 100%), url("rectangle-56.46f3e746.webp"), none;
  background-position: 50%;
  background-repeat: repeat, repeat, no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll, scroll;
  background-origin: padding-box, padding-box, padding-box;
  background-clip: border-box, border-box, border-box;
  width: 474px;
  height: 560px;
  position: absolute;
  top: 955px;
  left: 0;
}

.staffing .frame-26 {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 362px;
  display: flex;
  position: relative;
  top: 317px;
  left: 50px;
}

.staffing .text-wrapper-33 {
  color: #fff;
  letter-spacing: -.53px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 600;
  line-height: 49px;
  position: relative;
}

.staffing .text-wrapper-34 {
  color: #e9e9e9;
  letter-spacing: -.24px;
  align-self: stretch;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
  position: relative;
}

.staffing .frame-27 {
  background-color: #d3d3d3;
  background-image: linear-gradient(0deg, #000c 0%, #0000 100%), url("rectangle-56-1.f66360ae.webp"), none;
  background-position: 50%;
  background-repeat: repeat, repeat, no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll, scroll;
  background-origin: padding-box, padding-box, padding-box;
  background-clip: border-box, border-box, border-box;
  width: 474px;
  height: 560px;
  position: absolute;
  top: 955px;
  left: 481px;
}

.staffing .frame-28 {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 410px;
  display: flex;
  position: relative;
  top: 317px;
  left: 32px;
}

.staffing .frame-29 {
  width: 474px;
  height: 560px;
  position: absolute;
  top: 955px;
  left: 966px;
}

.staffing .overlap-group-6 {
  background-image: url("rectangle-56-2.985f534d.webp");
  background-size: 100% 100%;
  height: 561px;
}

.staffing .frame-30 {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 364px;
  display: flex;
  position: relative;
  top: 317px;
  left: 32px;
}

.staffing .joulestowatts-uses {
  text-align: center;
  font-family: Source Sans Pro, Helvetica;
  font-weight: 600;
  position: absolute;
}

.staffing .frame-31 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 424px;
  left: 1062px;
}

.staffing .text-wrapper-35 {
  color: var(--secondary-03, #2e5053);
  text-align: center;
  letter-spacing: -1.8px;
  white-space: nowrap;
  width: 110px;
  height: 48px;
  margin-top: -1px;
  font-family: Source Sans Pro;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 101.5%;
  position: relative;
}

.staffing .text-wrapper-36 {
  color: #2c3e47;
  letter-spacing: -.17px;
  width: 198px;
  height: 43px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: relative;
}

.staffing .frame-32 {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 231px;
  left: 1061px;
}

.staffing .text-wrapper-37 {
  color: var(--secondary-03, #2e5053);
  text-align: center;
  letter-spacing: -1.8px;
  text-align: center;
  white-space: nowrap;
  width: 158px;
  height: 48px;
  margin-top: -1px;
  font-family: Source Sans Pro;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 101.5%;
  position: relative;
}

.staffing .text-wrapper-38 {
  color: #2c3e47;
  letter-spacing: -.17px;
  width: 208px;
  height: 65px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: relative;
}

.staffing .overlap-12 {
  background-color: #f6f7f8;
  width: 1446px;
  height: 354px;
  position: absolute;
  top: 4521px;
  left: 0;
}

.staffing .frame-33 {
  flex-direction: column;
  align-items: center;
  gap: 25px;
  display: inline-flex;
  position: relative;
  top: 99px;
  left: 161px;
}

.staffing .frame-34 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 51px;
  display: inline-flex;
  position: relative;
}

.staffing .frame-35 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 26px;
  display: inline-flex;
  position: relative;
}

.staffing .frame-36 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.staffing .frame-37 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.staffing .text-wrapper-39 {
  color: var(--neutral-60);
  letter-spacing: -.2px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  position: relative;
}

.staffing .frame-38 {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 29px;
  display: inline-flex;
  position: relative;
}

.staffing .mercedes-benz-logo {
  object-fit: cover;
  width: 248px;
  height: 63px;
  position: relative;
}

.staffing .GE-healthcare-logo {
  object-fit: cover;
  width: 164px;
  height: 92px;
  position: relative;
}

.staffing .sony-logo {
  object-fit: cover;
  width: 123px;
  height: 22px;
  position: relative;
}

.staffing .shell-logo {
  object-fit: cover;
  width: 115px;
  height: 65px;
  position: relative;
}

.staffing .amazon {
  object-fit: cover;
  width: 178px;
  height: 55px;
  position: relative;
}

.staffing .wipro-logo-new-og {
  object-fit: cover;
  width: 171px;
  height: 89px;
  position: relative;
}

.staffing .overlap-13 {
  position: absolute;
}

.staffing .carousel-2 {
  width: 390px;
  height: 796px;
  position: absolute;
  top: 0;
  left: 185px;
  overflow: hidden;
}

.staffing .frame-39 {
  width: 667px;
  height: 1425px;
  position: absolute;
  top: 0;
  left: 0;
}

.staffing .overlap-group-7 {
  width: 738px;
  height: 923px;
  position: relative;
  top: -24px;
  left: -185px;
}

.staffing .rectangle-11 {
  width: 390px;
  height: 485px;
  position: absolute;
  top: 24px;
  left: 185px;
}

.staffing .frame-40 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  height: 261px;
  display: inline-flex;
  position: absolute;
  top: 166px;
  left: 265px;
}

.staffing .shutterstock {
  object-fit: cover;
  width: 732px;
  height: 488px;
  position: absolute;
  top: 419px;
  left: 0;
}

.staffing .rectangle-12 {
  filter: blur(40px);
  background: linear-gradient(90deg, #589993 5.95%, #4d9f98 20.62% 48.33%, #fcfcfc00 71.42%);
  width: 923px;
  height: 687px;
  position: absolute;
  top: 118px;
  left: -67px;
  transform: rotate(90deg);
}

.staffing .frame-41 {
  flex-direction: column;
  align-items: flex-start;
  gap: 33px;
  display: inline-flex;
  position: absolute;
  top: 106px;
  left: 201px;
}

.staffing .we-are-your-growth {
  color: #fff;
  letter-spacing: -.22px;
  width: 358px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 22px;
  font-weight: 600;
  line-height: 30.8px;
  position: relative;
}

.staffing .CTA-6 {
  border: unset !important;
  border-color: unset !important;
  background-color: #2e5053 !important;
  flex: none !important;
  justify-content: center !important;
  align-items: flex-end !important;
  width: 127px !important;
  display: flex !important;
}

.staffing .CTA-7 {
  font-family: Source Sans Pro, Helvetica !important;
  font-size: 17px !important;
  font-style: unset !important;
  letter-spacing: -.17px !important;
  margin-left: -2px !important;
  font-weight: 700 !important;
  line-height: 23.8px !important;
}

.staffing .CTA-8 {
  margin-right: -2px !important;
}

.staffing .frame-42 {
  width: 1440px;
  height: 580px;
  position: absolute;
  top: 0;
  left: 1440px;
  overflow: hidden;
}

.staffing .rectangle-13 {
  width: 1383px;
  height: 634px;
  position: absolute;
  top: -7281px;
  left: -19381px;
}

.staffing .overlap-14 {
  filter: blur(80px);
  background: linear-gradient(#000 0%, #071103 25%, #202020 44.79%, #2f2f2f 61.46%, #0000 100%);
  width: 1684px;
  height: 714px;
  position: absolute;
  top: -60px;
  left: -116px;
}

.staffing .frame-43 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: absolute;
  top: 242px;
  left: 196px;
}

.staffing .text-wrapper-40 {
  color: var(--neutral-0);
  letter-spacing: -.53px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 600;
  line-height: 49px;
  position: relative;
}

.staffing .frame-44 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.staffing .our-client-and {
  color: var(--neutral-10);
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  width: 436px;
  margin-top: -1px;
  position: relative;
}

.staffing .CTA-9 {
  all: unset;
  box-sizing: border-box;
  flex: none;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.staffing .text-wrapper-41 {
  color: var(--neutral-0);
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 23.8px;
  position: relative;
}

.staffing .arrow-forward-3 {
  width: 24px;
  height: 24px;
  margin-top: -7653px;
  margin-left: -19715px;
  position: relative;
}

.staffing .text-wrapper-42 {
  color: var(--neutral-0);
  font-family: var(--h4-REG-font-family);
  font-size: var(--h4-REG-font-size);
  font-style: var(--h4-REG-font-style);
  font-weight: var(--h4-REG-font-weight);
  letter-spacing: var(--h4-REG-letter-spacing);
  line-height: var(--h4-REG-line-height);
  white-space: nowrap;
  height: 41px;
  position: absolute;
  top: 120px;
  left: 196px;
}

.staffing .frame-45 {
  align-items: flex-start;
  gap: 5px;
  display: inline-flex;
  position: absolute;
  top: 560px;
  left: 196px;
}

.staffing .ellipse-7 {
  background-color: #d9d9d952;
  border-radius: 5.5px;
  width: 11px;
  height: 11px;
  position: relative;
}

.staffing .ellipse-8 {
  background-color: var(--neutral-30);
  border-radius: 5.5px;
  width: 11px;
  height: 11px;
  position: relative;
}

.staffing .carousel-03 {
  position: absolute !important;
  top: 0 !important;
  left: 2880px !important;
}

.staffing .carousel-instance {
  margin-top: -7676px !important;
  margin-left: -21155px !important;
}

.staffing .frame-46 {
  position: absolute;
  left: 0;
}

.staffing .frame-47 {
  width: 742px;
  height: 307px;
  position: absolute;
  top: 0;
  left: 0;
}

.staffing .overlap-group-8 {
  height: 307px;
  position: relative;
}

.staffing .rectangle-14 {
  background-color: #f6f7f8;
  width: 390px;
  height: 307px;
  position: absolute;
  top: 0;
  left: 185px;
}

.staffing .frame-48 {
  flex-direction: column;
  align-items: center;
  gap: 25px;
  display: inline-flex;
  position: absolute;
  top: 76px;
  left: 0;
}

.staffing .mercedes-benz-logo-2 {
  object-fit: cover;
  width: 148px;
  height: 37px;
  position: relative;
}

.staffing .GE-healthcare-logo-2 {
  object-fit: cover;
  width: 98px;
  height: 55px;
  position: relative;
}

.staffing .sony-logo-2 {
  object-fit: cover;
  width: 74px;
  height: 13px;
  position: relative;
}

.staffing .shell-logo-2 {
  object-fit: cover;
  width: 69px;
  height: 39px;
  position: relative;
}

.staffing .amazon-2 {
  object-fit: cover;
  width: 70px;
  height: 33px;
  position: relative;
}

.staffing .wipro-logo-new-og-2 {
  object-fit: cover;
  width: 102px;
  height: 53px;
  position: relative;
}

.staffing .frame-49 {
  flex-direction: column;
  align-items: center;
  gap: 92px;
  display: inline-flex;
  position: absolute;
  top: 366px;
  left: 185px;
}

.staffing .frame-50 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-end;
  gap: 32px;
  width: 328px;
  display: flex;
  position: relative;
}

.staffing .frame-51 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 24px;
  width: 328px;
  display: flex;
  position: relative;
}

.staffing .text-wrapper-43 {
  color: var(--secondary-04);
  letter-spacing: -.8px;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.4px;
  position: relative;
}

.staffing .text-wrapper-44 {
  color: var(--neutral-80);
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  text-align: center;
  width: 297px;
  position: relative;
}

.staffing .group-14 {
  width: 390px;
  height: 360px;
  position: relative;
}

.staffing .frame-52 {
  height: 360px;
}

.staffing .overlap-group-9 {
  background-color: var(--neutral-70);
  width: 1440px;
  height: 360px;
}

.staffing .frame-53 {
  flex-direction: column;
  align-items: center;
  gap: 24px;
  display: inline-flex;
  position: relative;
  top: 85px;
  left: 41px;
}

.staffing .text-wrapper-45 {
  color: var(--neutral-0);
  letter-spacing: -.8px;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.4px;
  position: relative;
}

.staffing .are-we-a-good-fit {
  color: var(--neutral-10);
  letter-spacing: -.2px;
  text-align: center;
  width: 309px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.staffing .CTA-10 {
  cursor: pointer;
  background-color: var(--secondary-02);
  box-sizing: border-box;
  border: none;
  outline: none;
  flex: none;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  display: inline-flex;
  position: relative;
}

.staffing .text-wrapper-46 {
  color: var(--neutral-0);
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: relative;
}

.staffing .arrow-forward-4 {
  width: 24px;
  height: 24px;
  position: relative;
}

.staffing .overlap-15 {
  height: 380px;
  position: relative;
}

.staffing .frame-54 {
  background-color: #4d4d4d;
  width: 1440px;
  height: 380px;
  position: absolute;
  top: 0;
  left: 0;
}

.staffing .frame-55 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 69px;
  left: 152px;
}

.staffing .frame-56 {
  flex-direction: column;
  flex: none;
  align-items: flex-end;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.staffing .text-wrapper-47 {
  color: #fff;
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.staffing .are-we-a-good-fit-2 {
  color: #f6f7f8;
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  text-align: right;
  width: 429px;
  position: relative;
}

.staffing .CTA-11 {
  all: unset;
  background-color: var(--secondary-04);
  box-sizing: border-box;
  flex: none;
  align-items: center;
  gap: 10px;
  padding: 12px 16px;
  display: inline-flex;
  position: relative;
}

.staffing .text-wrapper-48 {
  color: #fff;
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.staffing .text-40 {
  color: var(--secondary-03, #2e5053);
  text-align: center;
  letter-spacing: -1.8px;
  font-family: Source Sans Pro;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 101.5%;
}

.staffing .CTA-12 {
  cursor: pointer;
  background-color: var(--secondary-02);
  box-sizing: border-box;
  border: none;
  outline: none;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  display: inline-flex;
  position: absolute;
  top: 267px;
  left: 817px;
}

.staffing .frame-57 {
  background-color: var(--secondary-03);
  width: 720px;
  height: 380px;
  position: absolute;
  top: 0;
  left: 0;
}

.staffing .overlap-group-10 {
  background-color: #f6f7f8;
  width: 720px;
  height: 380px;
  position: absolute;
  top: 0;
  left: 0;
}

.staffing .frame-58 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 32px;
  display: inline-flex;
  position: relative;
  top: 49px;
  left: 128px;
}

.staffing .text-wrapper-49 {
  color: var(--secondary-04);
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.staffing .text-wrapper-50 {
  color: var(--neutral-80);
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  text-align: right;
  width: 500px;
  position: relative;
}

.staffing .frame-59 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 52px;
  left: 817px;
}

.staffing .text-wrapper-51 {
  color: #fff;
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.staffing .text-wrapper-52 {
  color: #f6f7f8;
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  width: 429px;
  position: relative;
}

.staffing .footer-2 {
  background-color: var(--white);
  width: 1441px;
  height: 292px;
  position: absolute;
  top: 377px;
  left: 0;
}

.staffing .overlap-16 {
  width: 1441px;
  height: 292px;
  position: relative;
}

.staffing .frame-60 {
  background-color: var(--neutral-90);
  width: 1441px;
  height: 292px;
  position: absolute;
  top: 0;
  left: 0;
}

.staffing .DIV-3 {
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 53px;
  left: 738px;
}

.staffing .text-wrapper-53 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1233px;
}

.staffing .text-wrapper-54 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1282px;
}

.staffing .text-wrapper-55 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1331px;
}

.staffing .text-wrapper-56 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1187px;
}

.staffing .text-wrapper-57 {
  color: var(--white);
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  white-space: nowrap;
  height: 28px;
  position: absolute;
  top: 100px;
  left: 738px;
}

.staffing .text-wrapper-58 {
  color: var(--white);
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  white-space: nowrap;
  height: 28px;
  position: absolute;
  top: 100px;
  left: 869px;
}

.staffing .frame-61 {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: absolute;
  top: 115px;
  left: 81px;
}

.staffing .group-15 {
  width: 37px;
  height: 52px;
  position: absolute;
  top: 152px;
  left: 1140px;
}

.staffing .linkedin-2 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1187px;
}

.staffing .facebook-2 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1234px;
}

.staffing .facebook-3 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1281px;
}

.staffing .facebook-4 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1328px;
}

.staffing .group-16 {
  width: 400px;
  height: 17px;
  position: absolute;
  top: 242px;
  left: 501px;
}

.staffing .frame-62 {
  justify-content: center;
  align-items: center;
  gap: 5px;
  display: inline-flex;
  position: relative;
}

.staffing .text-wrapper-59 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.staffing .ellipse-9 {
  background-color: var(--grey-01);
  border-radius: 1.5px;
  width: 3px;
  height: 3px;
  position: relative;
}

.staffing .logo-3 {
  width: 116px;
  height: 56px;
  position: absolute;
  top: 39px;
  left: 76px;
}

.staffing .overlap-17 {
  width: 1446px;
  height: 488px;
  position: absolute;
  top: 4030px;
  left: 0;
}

.staffing .rectangle-15 {
  background-color: #f6f7f8;
  width: 1446px;
  height: 350px;
  position: absolute;
  top: 7px;
  left: 0;
}

.staffing .carousel-3 {
  width: 1440px;
  height: 488px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.staffing .frame-63 {
  width: 1440px;
  height: 488px;
  position: absolute;
  top: 0;
  left: 0;
}

.staffing .overlap-group-11 {
  width: 1757px;
  height: 697px;
  position: relative;
  top: -130px;
  left: -149px;
}

.staffing .rectangle-16 {
  width: 1440px;
  height: 485px;
  position: absolute;
  top: 130px;
  left: 149px;
}

.staffing .shutterstock-2 {
  object-fit: cover;
  width: 732px;
  height: 488px;
  position: absolute;
  top: 130px;
  left: 857px;
}

.staffing .rectangle-17 {
  filter: blur(40px);
  background: linear-gradient(90deg, #589993 5.95%, #4d9f98 20.62% 48.33%, #fcfcfc00 71.42%);
  width: 1757px;
  height: 697px;
  position: absolute;
  top: 0;
  left: 0;
}

.staffing .we-are-your-growth-2 {
  color: #fff;
  letter-spacing: -.22px;
  width: 596px;
  height: 124px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 22px;
  font-weight: 600;
  line-height: 30.8px;
  position: absolute;
  top: 231px;
  left: 229px;
}

.staffing .CTA-13 {
  border: unset !important;
  border-color: unset !important;
  background-color: #2e5053 !important;
  justify-content: center !important;
  align-items: flex-end !important;
  width: 182px !important;
  display: flex !important;
  position: absolute !important;
  top: 412px !important;
  left: 229px !important;
}

.staffing .rectangle-18 {
  width: 1383px;
  height: 634px;
  position: absolute;
  top: -4065px;
  left: -17904px;
}

.staffing .arrow-forward-5 {
  width: 24px;
  height: 24px;
  margin-top: -4437px;
  margin-left: -18238px;
  position: relative;
}

.staffing .carousel-03-instance {
  margin-top: -4460px !important;
  margin-left: -19678px !important;
}

.staffing .group-17 {
  width: 1164px;
  height: 1107px;
  position: absolute;
  top: 2848px;
  left: 139px;
}

.staffing .text-wrapper-60 {
  color: #1f1f1f;
  letter-spacing: -1.05px;
  text-align: center;
  width: 277px;
  height: 50px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 42px;
  font-weight: 600;
  line-height: 50.4px;
  position: absolute;
  top: 0;
  left: 442px;
}

.staffing .row {
  justify-content: center;
  align-items: center;
  gap: 25px;
  display: inline-flex;
  position: absolute;
  top: 253px;
  left: 0;
}

.staffing .box-s {
  background-color: #fff;
  border-radius: 25px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
  width: 370px;
  height: 260px;
  padding: 37px 47px;
  display: flex;
  position: relative;
  box-shadow: 0 18px 40px #7090b01f;
}

.staffing .opened-folder {
  width: 34px;
  height: 34px;
  position: relative;
}

.staffing .header-3 {
  flex-direction: column;
  align-items: center;
  gap: 10px;
  height: 105px;
  margin-left: -3.5px;
  margin-right: -3.5px;
  display: inline-flex;
  position: relative;
}

.staffing .text-wrapper-61 {
  color: #2e5053;
  letter-spacing: -.2px;
  text-align: center;
  white-space: nowrap;
  width: 235px;
  height: 22px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  position: relative;
}

.staffing .text-wrapper-62 {
  color: #1f1f1f;
  font-family: var(--paragraph-REG-font-family);
  font-size: var(--paragraph-REG-font-size);
  font-style: var(--paragraph-REG-font-style);
  font-weight: var(--paragraph-REG-font-weight);
  letter-spacing: var(--paragraph-REG-letter-spacing);
  line-height: var(--paragraph-REG-line-height);
  text-align: center;
  width: 283px;
  height: 53px;
  position: relative;
}

.staffing .box-2 {
  background-color: #fff;
  border-radius: 25px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
  width: 370px;
  height: 260px;
  padding: 37px 58px;
  display: flex;
  position: relative;
  box-shadow: 0 18px 40px #7090b01f;
}

.staffing .info {
  width: 36px;
  height: 36px;
  position: relative;
}

.staffing .header-4 {
  flex-direction: column;
  align-items: center;
  gap: 10px;
  height: 100px;
  margin-left: -4px;
  margin-right: -4px;
  display: inline-flex;
  position: relative;
}

.staffing .text-wrapper-63 {
  color: #2e5053;
  letter-spacing: -.2px;
  text-align: center;
  white-space: nowrap;
  width: 241px;
  height: 22px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  position: relative;
}

.staffing .text-wrapper-64 {
  color: #1f1f1f;
  font-family: var(--paragraph-REG-font-family);
  font-size: var(--paragraph-REG-font-size);
  font-style: var(--paragraph-REG-font-style);
  font-weight: var(--paragraph-REG-font-weight);
  letter-spacing: var(--paragraph-REG-letter-spacing);
  line-height: var(--paragraph-REG-line-height);
  text-align: center;
  width: 262px;
  height: 53px;
  position: relative;
}

.staffing .box-3 {
  background-color: #fff;
  border-radius: 25px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
  width: 370px;
  height: 260px;
  padding: 37px 55px;
  display: flex;
  position: relative;
  box-shadow: 0 18px 40px #7090b01f;
}

.staffing .my-space {
  width: 37px;
  height: 36px;
  position: relative;
}

.staffing .header-5 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 10px;
  margin-left: -3.5px;
  margin-right: -3.5px;
  display: inline-flex;
  position: relative;
}

.staffing .text-wrapper-65 {
  color: #2e5053;
  letter-spacing: -.2px;
  text-align: center;
  white-space: nowrap;
  width: 165px;
  height: 23px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  position: relative;
}

.staffing .we-hold-open-house {
  color: #1f1f1f;
  font-family: var(--paragraph-REG-font-family);
  font-size: var(--paragraph-REG-font-size);
  font-style: var(--paragraph-REG-font-style);
  font-weight: var(--paragraph-REG-font-weight);
  letter-spacing: var(--paragraph-REG-letter-spacing);
  line-height: var(--paragraph-REG-line-height);
  text-align: center;
  width: 267px;
  height: 73px;
  position: relative;
}

.staffing .row-2 {
  justify-content: center;
  align-items: center;
  gap: 25px;
  display: inline-flex;
  position: absolute;
  top: 550px;
  left: 0;
}

.staffing .box-4 {
  background-color: #fff;
  border-radius: 25px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
  width: 370px;
  height: 260px;
  padding: 43px 39px;
  display: flex;
  position: relative;
  box-shadow: 0 18px 40px #7090b01f;
}

.staffing .resume-website {
  width: 42px;
  height: 42px;
  position: relative;
}

.staffing .text-wrapper-66 {
  color: #2e5053;
  letter-spacing: -.2px;
  text-align: center;
  white-space: nowrap;
  width: 198px;
  height: 21px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  position: relative;
}

.staffing .text-wrapper-67 {
  color: #1f1f1f;
  font-family: var(--paragraph-REG-font-family);
  font-size: var(--paragraph-REG-font-size);
  font-style: var(--paragraph-REG-font-style);
  font-weight: var(--paragraph-REG-font-weight);
  letter-spacing: var(--paragraph-REG-letter-spacing);
  line-height: var(--paragraph-REG-line-height);
  text-align: center;
  width: 299px;
  height: 68px;
  position: relative;
}

.staffing .box-5 {
  background-color: #fff;
  border-radius: 25px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
  width: 370px;
  height: 260px;
  padding: 43px 54px;
  display: flex;
  position: relative;
  box-shadow: 0 18px 40px #7090b01f;
}

.staffing .prescrptsion {
  width: 37px;
  height: 30px;
  position: relative;
}

.staffing .text-wrapper-68 {
  color: #2e5053;
  letter-spacing: -.2px;
  text-align: center;
  white-space: nowrap;
  width: 195px;
  height: 20px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  position: relative;
}

.staffing .text-wrapper-69 {
  color: #1f1f1f;
  font-family: var(--paragraph-REG-font-family);
  font-size: var(--paragraph-REG-font-size);
  font-style: var(--paragraph-REG-font-style);
  font-weight: var(--paragraph-REG-font-weight);
  letter-spacing: var(--paragraph-REG-letter-spacing);
  line-height: var(--paragraph-REG-line-height);
  text-align: center;
  width: 269px;
  height: 68px;
  position: relative;
}

.staffing .box-6 {
  background-color: #fff;
  border-radius: 25px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
  width: 370px;
  height: 260px;
  padding: 43px 58px;
  display: flex;
  position: relative;
  box-shadow: 0 18px 40px #7090b01f;
}

.staffing .prize {
  width: 43px;
  height: 42px;
  position: relative;
}

.staffing .header-6 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 10px;
  margin-left: -4px;
  margin-right: -4px;
  display: inline-flex;
  position: relative;
}

.staffing .quarterly-r-r {
  color: #2e5053;
  letter-spacing: -.2px;
  text-align: center;
  white-space: nowrap;
  width: 174px;
  height: 22px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  position: relative;
}

.staffing .text-wrapper-70 {
  color: #1f1f1f;
  font-family: var(--paragraph-REG-font-family);
  font-size: var(--paragraph-REG-font-size);
  font-style: var(--paragraph-REG-font-style);
  font-weight: var(--paragraph-REG-font-weight);
  letter-spacing: var(--paragraph-REG-letter-spacing);
  line-height: var(--paragraph-REG-line-height);
  text-align: center;
  width: 262px;
  height: 67px;
  position: relative;
}

.staffing .row-3 {
  justify-content: center;
  align-items: center;
  gap: 25px;
  display: inline-flex;
  position: absolute;
  top: 847px;
  left: 3px;
}

.staffing .birth-date {
  width: 33px;
  height: 33px;
  position: relative;
}

.staffing .text-wrapper-71 {
  color: #2e5053;
  letter-spacing: -.2px;
  text-align: center;
  white-space: nowrap;
  width: 155px;
  height: 21px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  position: relative;
}

.staffing .computer-chat {
  width: 37px;
  height: 37px;
  position: relative;
}

.staffing .text-wrapper-72 {
  color: #2e5053;
  letter-spacing: -.2px;
  text-align: center;
  white-space: nowrap;
  width: 129px;
  height: 20px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  position: relative;
}

.staffing .text-wrapper-73 {
  color: #2e5053;
  letter-spacing: -.24px;
  text-align: center;
  width: 847px;
  height: 136px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
  position: absolute;
  top: 68px;
  left: 157px;
}

.staffing .text-wrapper-74 {
  color: #1f1f1f;
  letter-spacing: -.48px;
  text-align: center;
  width: 277px;
  height: 76px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 600;
  line-height: 38.4px;
  position: absolute;
  top: 2264px;
  left: 56px;
}

.staffing .frame-64 {
  width: 341px;
  height: 621px;
  position: absolute;
  top: 2359px;
  left: 26px;
}

.staffing .overlap-18 {
  width: 368px;
  height: 621px;
  position: relative;
  left: -25px;
}

.staffing .group-18 {
  width: 365px;
  height: 133px;
  position: absolute;
  top: 0;
  left: 0;
}

.staffing .overlap-group-12 {
  width: 363px;
  height: 133px;
  position: relative;
}

.staffing .rectangle-19 {
  background-color: #e9e9e9;
  border-radius: 14px;
  width: 280px;
  height: 98px;
  position: absolute;
  top: 11px;
  left: 83px;
}

.staffing .text-wrapper-75 {
  color: #2c3e47;
  letter-spacing: -.16px;
  width: 177px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  position: absolute;
  top: 34px;
  left: 136px;
}

.staffing .group-19 {
  background-image: url("group-16.ddb09028.png");
  background-size: 100% 100%;
  width: 133px;
  height: 133px;
  position: absolute;
  top: 0;
  left: 0;
}

.staffing .group-20 {
  width: 368px;
  height: 133px;
  position: absolute;
  top: 122px;
  left: 0;
}

.staffing .overlap-19 {
  width: 366px;
  height: 133px;
  position: relative;
}

.staffing .rectangle-20 {
  background-color: #e9e9e9;
  border-radius: 14px;
  width: 280px;
  height: 98px;
  position: absolute;
  top: 8px;
  left: 86px;
}

.staffing .text-wrapper-76 {
  color: #2c3e47;
  letter-spacing: -.16px;
  width: 209px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  position: absolute;
  top: 35px;
  left: 136px;
}

.staffing .group-21 {
  width: 365px;
  height: 133px;
  position: absolute;
  top: 366px;
  left: 0;
}

.staffing .rectangle-21 {
  background-color: #e9e9e9;
  border-radius: 14px;
  width: 280px;
  height: 98px;
  position: absolute;
  top: 13px;
  left: 83px;
}

.staffing .text-wrapper-77 {
  color: #2c3e47;
  letter-spacing: -.16px;
  width: 191px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  position: absolute;
  top: 40px;
  left: 137px;
}

.staffing .group-22 {
  width: 365px;
  height: 133px;
  position: absolute;
  top: 244px;
  left: 0;
}

.staffing .text-wrapper-78 {
  color: #2c3e47;
  letter-spacing: -.16px;
  width: 191px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  position: absolute;
  top: 28px;
  left: 137px;
}

.staffing .group-23 {
  width: 366px;
  height: 133px;
  position: absolute;
  top: 488px;
  left: 0;
}

.staffing .overlap-20 {
  width: 368px;
  height: 133px;
  position: relative;
}

.staffing .group-24 {
  width: 282px;
  height: 98px;
  position: absolute;
  top: 13px;
  left: 86px;
}

.staffing .overlap-group-13 {
  background-color: #e9e9e9;
  border-radius: 14px;
  width: 280px;
  height: 98px;
  position: relative;
}

.staffing .text-wrapper-79 {
  color: #2c3e47;
  letter-spacing: -.16px;
  width: 191px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  position: absolute;
  top: 15px;
  left: 51px;
}

.staffing .frame-65 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  width: 162px;
  display: flex;
  position: absolute;
  top: 2998px;
  left: 23px;
}

.staffing .text-wrapper-80 {
  color: #2e5053;
  letter-spacing: -1.2px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 40px;
  font-weight: 700;
  line-height: 40.6px;
  position: relative;
}

.staffing .text-wrapper-81 {
  color: #2c3e47;
  letter-spacing: -.14px;
  width: 162px;
  height: 43px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.6px;
  position: relative;
}

.staffing .frame-66 {
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 177px;
  display: flex;
  position: absolute;
  top: 3122px;
  left: 23px;
}

.staffing .text-wrapper-82 {
  color: #2c3e47;
  letter-spacing: -.14px;
  align-self: stretch;
  height: 67px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.6px;
  position: relative;
}

.staffing .frame-67 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  width: 162px;
  display: flex;
  position: absolute;
  top: 3122px;
  left: 200px;
}

.staffing .text-wrapper-83 {
  color: #2c3e47;
  letter-spacing: -.14px;
  align-self: stretch;
  height: 43px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.6px;
  position: relative;
}

.staffing .frame-68 {
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 159px;
  display: flex;
  position: absolute;
  top: 2998px;
  left: 195px;
}

.staffing .text-wrapper-84 {
  color: #2c3e47;
  letter-spacing: -.14px;
  align-self: stretch;
  height: 65px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.6px;
  position: relative;
}

.staffing .text-wrapper-85 {
  color: #1f1f1f;
  letter-spacing: -.8px;
  text-align: center;
  white-space: nowrap;
  height: 38px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 600;
  line-height: 38.4px;
  position: absolute;
  top: 3269px;
  left: 65px;
}

.staffing .frame-69 {
  flex-direction: column;
  align-items: flex-start;
  gap: 7.46px;
  display: inline-flex;
  position: absolute;
  top: 3333px;
  left: -3px;
}

.staffing .frame-70 {
  background-color: #d3d3d3;
  background-image: linear-gradient(0deg, #000c 0%, #0000 100%), url("rectangle-56.46f3e746.webp"), none;
  background-position: 50%;
  background-repeat: repeat, repeat, no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll, scroll;
  background-origin: padding-box, padding-box, padding-box;
  background-clip: border-box, border-box, border-box;
  width: 393px;
  height: 464.3px;
  position: relative;
}

.staffing .frame-71 {
  flex-direction: column;
  align-items: flex-start;
  gap: 13.27px;
  width: 300px;
  display: flex;
  position: relative;
  top: 267px;
  left: 41px;
}

.staffing .text-wrapper-86 {
  color: #fff;
  letter-spacing: -.42px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -.83px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 28px;
  font-weight: 600;
  line-height: 39.2px;
  position: relative;
}

.staffing .text-wrapper-87 {
  color: #e9e9e9;
  letter-spacing: -.18px;
  align-self: stretch;
  font-family: Source Sans Pro, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
  position: relative;
}

.staffing .frame-72 {
  background-color: #d3d3d3;
  background-image: linear-gradient(0deg, #000c 0%, #0000 100%), url("rectangle-56-1.f66360ae.webp"), none;
  background-position: 50%;
  background-repeat: repeat, repeat, no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll, scroll;
  background-origin: padding-box, padding-box, padding-box;
  background-clip: border-box, border-box, border-box;
  width: 393px;
  height: 464.3px;
  position: relative;
}

.staffing .frame-73 {
  flex-direction: column;
  align-items: flex-start;
  gap: 13.27px;
  width: 340px;
  display: flex;
  position: relative;
  top: 267px;
  left: 27px;
}

.staffing .frame-74 {
  width: 393px;
  height: 464.3px;
  position: relative;
}

.staffing .overlap-group-14 {
  background-image: url("rectangle-56-3.06c9aa0c.webp");
  background-size: 100% 100%;
  width: 390px;
  height: 465px;
  position: relative;
  left: 3px;
}

.staffing .frame-75 {
  flex-direction: column;
  align-items: flex-start;
  gap: 13.27px;
  width: 302px;
  display: flex;
  position: relative;
  top: 267px;
  left: 24px;
}

.staffing .overlap-21 {
  width: 370px;
  height: 2326px;
  position: absolute;
  top: 4841px;
  left: 10px;
}

.staffing .frame-76 {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 125px;
  left: 0;
}

.staffing .row-4 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.staffing .row-5 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 25px;
  display: inline-flex;
  position: relative;
}

.staffing .text-wrapper-88 {
  color: #2e5053;
  letter-spacing: -.16px;
  text-align: center;
  width: 355px;
  height: 132px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  position: absolute;
  top: 0;
  left: 6px;
}

.staffing .text-wrapper-89 {
  color: #1f1f1f;
  letter-spacing: -1.05px;
  text-align: center;
  width: 355px;
  height: 50px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 42px;
  font-weight: 600;
  line-height: 50.4px;
  position: absolute;
  top: 4778px;
  left: 16px;
}

.component-about {
  width: 484px;
  height: 180px;
  position: relative;
}

.component-about .bring-the-best-out {
  color: #0000;
  letter-spacing: -.75px;
  width: 484px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 50px;
  font-weight: 400;
  line-height: 60px;
  position: absolute;
  top: -1px;
  left: 0;
}

.component-about .text-wrapper {
  color: #2c3e47;
  font-family: var(--h1-REG-font-family);
  font-size: var(--h1-REG-font-size);
  font-style: var(--h1-REG-font-style);
  font-weight: var(--h1-REG-font-weight);
  letter-spacing: var(--h1-REG-letter-spacing);
  line-height: var(--h1-REG-line-height);
}

.component-about .span {
  color: #4d9f98;
  width: 100px;
  font-weight: 700;
  text-decoration: underline;
  position: relative;
}

.CTA.default {
  border: 1px solid #fff;
  gap: 10px;
  transition: all .3s ease-in-out;
}

.CTA.default:hover {
  gap: 8px;
}

.about {
  background-color: #fff;
  flex-direction: row;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.about .iphone {
  background-color: #fff;
  position: relative;
}

.about .carousel {
  width: 100%;
  height: auto;
}

.about .highlighted-text {
  opacity: 0;
  text-decoration: underline;
  transition: opacity .3s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}

.about .header-instance {
  width: 390px !important;
  height: 60px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.about .overlap {
  width: 1455px;
  height: 1912px;
  position: absolute;
  top: 89px;
  left: -15px;
}

.about .overlap-group {
  width: 1455px;
  height: 1912px;
  position: absolute;
  top: 0;
  left: 0;
}

.about .frame {
  width: 1440px;
  height: 539px;
  position: absolute;
  top: 0;
  left: 15px;
  overflow: hidden;
}

.about .overlap-group-2 {
  background-color: #f6f7f8;
  height: 607px;
  position: relative;
}

.about .overlap-2 {
  width: 643px;
  height: 572px;
  position: absolute;
  top: 0;
  left: 797px;
}

.about .ellipse {
  background-color: #2e5053;
  border-radius: 269px;
  width: 538px;
  height: 538px;
  position: absolute;
  top: 34px;
  left: 47px;
}

.about .rectangle {
  width: 643px;
  height: 539px;
  position: absolute;
  top: 0;
  left: 0;
}

.about .christina {
  width: 554px;
  height: 539px;
  position: absolute;
  top: 0;
  left: 37px;
}

.about .overlap-3 {
  width: 564px;
  height: 344px;
  position: absolute;
  top: 0;
  left: 0;
}

.about .img {
  width: 280px;
  height: 344px;
  position: absolute;
  top: 0;
  left: 0;
}

.about .component-1 {
  position: absolute !important;
  top: 89px !important;
  left: 80px !important;
}

.about .CTA-instance {
  border: unset !important;
  border-color: unset !important;
  background-color: #4d9f98 !important;
  justify-content: center !important;
  align-items: flex-end !important;
  width: 182px !important;
  display: flex !important;
  position: absolute !important;
  top: 385px !important;
  left: 80px !important;
}

.about .instance-node {
  margin-left: -1px !important;
  font-weight: 700 !important;
}

.about .CTA-2 {
  margin-right: -1px !important;
}

.about .p {
  color: #2e5053;
  letter-spacing: -.2px;
  width: 426px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: absolute;
  top: 301px;
  left: 95px;
}

.about .overlap-wrapper {
  background-color: #fff;
  width: 1440px;
  height: 346px;
  position: absolute;
  top: 530px;
  left: 15px;
  overflow: hidden;
}

.about .section-industries-wrapper {
  background-color: #f6f7f8;
  height: 477px;
}

.about .section-industries {
  width: 1440px;
  height: 355px;
}

.about .overlap-group-3 {
  background-color: #2c3e47;
  height: 356px;
  position: relative;
}

.about .container {
  align-items: center;
  gap: 24px;
  width: 1279px;
  height: 354px;
  padding: 56px 0;
  display: flex;
  position: absolute;
  top: 1px;
  left: 82px;
  overflow: hidden;
}

.about .IT-services {
  background-color: #f5f5f5;
  width: 263px;
  height: 240px;
  position: relative;
}

.about .smart-CEO-start-up {
  color: var(--secondary-04);
  letter-spacing: -.24px;
  width: 215px;
  height: 68px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 33.6px;
  position: absolute;
  top: 89px;
  left: 18px;
}

.about .frame-2 {
  width: 71px;
  height: 59px;
  position: absolute;
  top: 22px;
  left: 18px;
}

.about .BFSI {
  background-image: url("rectangle-24.50900037.webp");
  background-size: 100% 100%;
  width: 264px;
  height: 240px;
  position: relative;
}

.about .text-wrapper-2 {
  color: #2c3e47;
  letter-spacing: -.24px;
  width: 227px;
  height: 68px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 33.6px;
  position: absolute;
  top: 85px;
  left: 18px;
}

.about .frame-wrapper {
  align-items: center;
  gap: 13px;
  width: 237px;
  display: flex;
  position: absolute;
  top: 167px;
  left: 18px;
}

.about .frame-3 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.about .by-femina-for {
  color: var(--secondary-04);
  letter-spacing: -.14px;
  width: 221px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  position: relative;
}

.about .frame-4 {
  width: 71px;
  height: 59px;
  position: absolute;
  top: 17px;
  left: 18px;
}

.about .telecom {
  background-image: url("product.360d922c.webp");
  background-position: 50%;
  background-size: cover;
  width: 263px;
  height: 240px;
  position: relative;
}

.about .text-wrapper-3 {
  color: #2c3e47;
  letter-spacing: -.24px;
  width: 215px;
  height: 68px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 33.6px;
  position: absolute;
  top: 85px;
  left: 19px;
}

.about .div-wrapper {
  align-items: center;
  gap: 13px;
  width: 237px;
  display: flex;
  position: absolute;
  top: 165px;
  left: 19px;
}

.about .think-big-event-by-wrapper {
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 228px;
  display: flex;
  position: relative;
}

.about .think-big-event-by {
  color: var(--secondary-04);
  letter-spacing: -.14px;
  width: 228px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  position: relative;
}

.about .frame-5 {
  width: 71px;
  height: 59px;
  position: absolute;
  top: 18px;
  left: 19px;
}

.about .text-wrapper-4 {
  color: #2c3e47;
  letter-spacing: -.24px;
  width: 215px;
  height: 68px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 33.6px;
  position: absolute;
  top: 85px;
  left: 20px;
}

.about .frame-6 {
  align-items: center;
  gap: 13px;
  width: 237px;
  display: flex;
  position: absolute;
  top: 165px;
  left: 20px;
}

.about .frame-7 {
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 237px;
  display: flex;
  position: relative;
}

.about .text-wrapper-5 {
  color: var(--secondary-04);
  letter-spacing: -.14px;
  width: 280px;
  margin-top: -1px;
  margin-right: -43px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  position: relative;
}

.about .frame-8 {
  width: 71px;
  height: 59px;
  position: absolute;
  top: 23px;
  left: 20px;
}

.about .telecom-2 {
  background-image: url("ecommerce.b23a744f.webp");
  background-position: 50%;
  background-size: cover;
  width: 263px;
  height: 240px;
  position: relative;
}

.about .text-wrapper-6 {
  color: #fff;
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  white-space: nowrap;
  height: 34px;
  position: absolute;
  top: 179px;
  left: 27px;
}

.about .frame-9 {
  align-items: center;
  gap: 13px;
  width: 237px;
  display: flex;
  position: absolute;
  top: 226px;
  left: 18px;
}

.about .for-long-term-wrapper {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 4px;
  margin-right: -43px;
  display: inline-flex;
  position: relative;
}

.about .for-long-term {
  color: var(--secondary-04);
  letter-spacing: -.14px;
  width: 280px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  position: relative;
}

.about .arrow {
  background-color: #fff;
  border: none;
  border-radius: 30px;
  outline: none;
  width: 60px;
  height: 60px;
  position: absolute;
  top: 80px;
  left: 270px;
}

.about .arrow-forward-ios {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 18px;
  left: 18px;
}

.about .rectangle-2 {
  background-color: #2e5053;
  width: 1455px;
  height: 599px;
  position: absolute;
  top: 876px;
  left: 0;
}

.about .frame-10 {
  flex-direction: column;
  align-items: flex-start;
  gap: 34px;
  width: 733px;
  display: flex;
  position: absolute;
  top: 1117px;
  left: 629px;
}

.about .being-the-fastest {
  color: var(--neutral-0);
  letter-spacing: -.2px;
  width: 726px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.about .text-wrapper-7 {
  color: #fff;
  letter-spacing: -.2px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}

.about .text-wrapper-8 {
  font-weight: 700;
}

.about .CTA-3 {
  border: unset !important;
  border-color: unset !important;
  background-color: #4d9f98 !important;
  flex: none !important;
  justify-content: center !important;
  align-items: flex-end !important;
  width: 182px !important;
  display: flex !important;
}

.about .element-cube-design {
  width: 440px;
  height: 463px;
  position: absolute;
  top: 970px;
  left: 88px;
}

.about .rectangle-3 {
  width: 424px;
  height: 801px;
  position: absolute;
  top: 728px;
  left: 15px;
}

.about .frame-11 {
  background: url("Rectangle 91.bcd59703.webp") 50% / cover;
  width: 474px;
  height: 430px;
  position: absolute;
  top: 1482px;
  left: 14px;
}

.about .image-1 {
  background: url("Rectangle 91.bcd59703.webp");
}

.about .image-2 {
  background: url("Rectangle 92.f3ae1313.webp");
}

.about .image-3 {
  background: url("Rectangle 93.dd713a3f.webp");
}

.about .frame-12 {
  flex-direction: column;
  align-items: center;
  gap: 12px;
  display: inline-flex;
  position: relative;
  top: 215px;
  left: 71px;
}

.about .div-2 {
  color: var(--neutral-0);
  letter-spacing: -.42px;
  text-align: center;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 42px;
  font-weight: 400;
  line-height: 58.8px;
  position: relative;
}

.about .text-wrapper-9 {
  letter-spacing: -.24px;
  font-size: 24px;
  font-weight: 700;
  line-height: 33.6px;
}

.about .div-3 {
  color: #0000;
  letter-spacing: -.17px;
  text-align: center;
  width: 333px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 400;
  line-height: 23.8px;
  position: relative;
}

.about .text-wrapper-10 {
  color: #00dfc2;
  font-family: var(--subtitle-BLD-font-family);
  font-size: var(--subtitle-BLD-font-size);
  font-style: var(--subtitle-BLD-font-style);
  font-weight: var(--subtitle-BLD-font-weight);
  letter-spacing: var(--subtitle-BLD-letter-spacing);
  line-height: var(--subtitle-BLD-line-height);
}

.about .text-wrapper-11 {
  color: #bcbcbc;
}

.about .frame-13 {
  flex-direction: column;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 970px;
  left: 755px;
}

.about .frame-14 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.about .text-wrapper-12 {
  color: var(--neutral-0);
  letter-spacing: -.2px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  position: relative;
}

.about .text-wrapper-13 {
  color: var(--neutral-0);
  letter-spacing: -.75px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  font-family: Source Sans Pro, Helvetica;
  font-size: 50px;
  font-weight: 400;
  line-height: 60px;
  position: relative;
}

.about .vector {
  width: 393px;
  height: 112px;
  position: absolute;
  top: 1423px;
  left: 15px;
}

.about .overlap-group-wrapper {
  width: 474px;
  height: 430px;
  position: absolute;
  top: 1475px;
  left: 497px;
}

.about .overlap-4 {
  background: url("Rectangle 92.f3ae1313.webp") 50% / cover;
  height: 430px;
  position: relative;
  top: 7px;
}

.about .frame-15 {
  flex-direction: column;
  align-items: center;
  gap: 12px;
  display: inline-flex;
  position: relative;
  top: 208px;
  left: 70px;
}

.about .frame-16 {
  background: url("Rectangle 93.dd713a3f.webp") 50% / cover;
  width: 474px;
  height: 430px;
  position: absolute;
  top: 1481px;
  left: 980px;
}

.about .frame-17 {
  flex-direction: column;
  align-items: center;
  gap: 12px;
  display: inline-flex;
  position: relative;
  top: 215px;
  left: 70px;
}

.about .overlap-5 {
  width: 1306px;
  height: 871px;
  position: absolute;
  top: 2146px;
  left: 134px;
}

.about .rectangle-4 {
  width: 381px;
  height: 801px;
  position: absolute;
  top: -230px;
  left: 925px;
}

.about .frame-18 {
  background-color: #2e5053;
  border-radius: 26px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 41px 66px;
  display: inline-flex;
  position: absolute;
  top: 464px;
  left: 0;
}

.about .frame-19 {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 66px;
  display: inline-flex;
  position: relative;
}

.about .element {
  object-fit: cover;
  width: 299px;
  height: 299px;
  position: relative;
}

.about .frame-20 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  width: 696px;
  display: flex;
  position: relative;
}

.about .frame-21 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  width: 685px;
  display: flex;
  position: relative;
}

.about .text-wrapper-14 {
  color: var(--neutral-30);
  letter-spacing: -.53px;
  width: 162px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 700;
  line-height: 49px;
  position: relative;
}

.about .text-wrapper-15 {
  color: var(--secondary-02);
  letter-spacing: -.2px;
  width: 685px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.about .text-wrapper-16 {
  color: #f6f7f8;
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  width: 685px;
  position: relative;
}

.about .linkedin-circled {
  width: 45px;
  height: 45px;
  position: absolute;
  top: 45px;
  left: 1071px;
}

.about .frame-22 {
  background-color: var(--neutral-80);
  border-radius: 26px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 41px 66px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
}

.about .text-wrapper-17 {
  color: var(--neutral-30);
  letter-spacing: -.53px;
  width: 191px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 700;
  line-height: 49px;
  position: relative;
}

.about .linkedin-circled-2 {
  width: 45px;
  height: 45px;
  position: absolute;
  top: 41px;
  left: 1071px;
}

.about .overlap-6 {
  width: 1511px;
  height: 842px;
  position: absolute;
  top: 2677px;
  left: -28px;
}

.about .rectangle-5 {
  background-color: #2e5053;
  width: 1466px;
  height: 842px;
  position: absolute;
  top: 0;
  left: 45px;
}

.about .seekpng {
  object-fit: cover;
  width: 987px;
  height: 542px;
  position: absolute;
  top: 128px;
  left: 60px;
}

.about .frame-23 {
  flex-direction: column;
  align-items: center;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 128px;
  left: 985px;
}

.about .frame-223 {
  flex-direction: column;
  align-items: center;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 128px;
  left: 85px;
}

.about .frame-24 {
  background-color: #f6f7f8;
  border-radius: 8px;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 10px;
  padding: 11px 10px;
  display: inline-flex;
  position: relative;
}

.about .frame-25 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.about .text-wrapper-18 {
  color: #2e5053;
  letter-spacing: -.24px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  position: relative;
}

.about .text-wrapper-19 {
  color: #4d4d4d;
  letter-spacing: -.17px;
  text-align: center;
  width: 417px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 400;
  line-height: 23.8px;
  position: relative;
}

.about .frame-26 {
  background-color: #f6f7f8;
  border-radius: 8px;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 10px;
  padding: 10px 9px;
  display: inline-flex;
  position: relative;
}

.about .frame-27 {
  background-color: #f6f7f8;
  border-radius: 8px;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 10px;
  padding: 9px;
  display: inline-flex;
  position: relative;
}

.about .frame-28 {
  background-color: #f6f7f8;
  position: absolute;
}

.about .overlap-7 {
  position: relative;
}

.about .rectangle-6 {
  width: 216px;
  height: 434px;
  position: absolute;
  top: 0;
  left: 174px;
}

.about .frame-29 {
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 353px;
  display: flex;
  position: absolute;
  top: 54px;
  left: 18px;
}

.about .bring-the-best-out-2 {
  color: #0000;
  letter-spacing: -.54px;
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 36px;
  font-weight: 400;
  line-height: 43.2px;
  position: relative;
}

.about .text-wrapper-20 {
  color: #2c3e47;
}

.about .text-wrapper-21 {
  color: #4d9f98;
  font-weight: 700;
  text-decoration: underline;
  position: relative;
}

.about .text-wrapper-22 {
  color: #2e5053;
  letter-spacing: -.2px;
  text-align: center;
  align-self: stretch;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.about .frame-30 {
  width: 389px;
  height: 356px;
  position: absolute;
  top: 390px;
  left: 0;
}

.about .overlap-group-4 {
  width: 384px;
  height: 388px;
  position: relative;
  top: -32px;
  left: 5px;
}

.about .ellipse-2 {
  background-color: #2e5053;
  border-radius: 160.68px;
  width: 321px;
  height: 321px;
  position: absolute;
  top: 62px;
  left: 28px;
}

.about .rectangle-7 {
  width: 384px;
  height: 388px;
  position: absolute;
  top: 0;
  left: 0;
}

.about .christina-2 {
  width: 331px;
  height: 342px;
  position: absolute;
  top: 52px;
  left: 23px;
}

.about .CTA-4 {
  border: unset !important;
  border-color: unset !important;
  background-color: #4d9f98 !important;
  justify-content: center !important;
  align-items: flex-end !important;
  width: 182px !important;
  display: flex !important;
  position: absolute !important;
  top: 297px !important;
  left: 104px !important;
}

.about .frame-31 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 51px;
  display: flex;
  position: relative;
}

.about .frame-32 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 26px;
  display: inline-flex;
  position: relative;
}

.about .frame-33 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.about .text-wrapper-23 {
  color: var(--neutral-60);
  letter-spacing: -.2px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  position: relative;
}

.about .frame-34 {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 29px;
  width: 100%;
  display: inline-flex;
  position: relative;
}

.about .mercedes-benz-logo {
  object-fit: cover;
  width: 248px;
  height: 63px;
  position: relative;
}

.about .GE-healthcare-logo {
  object-fit: cover;
  width: 164px;
  height: 92px;
  position: relative;
}

.about .sony-logo {
  object-fit: cover;
  width: 123px;
  height: 22px;
  position: relative;
}

.about .shell-logo {
  object-fit: cover;
  width: 115px;
  height: 65px;
  position: relative;
}

.about .amazon {
  object-fit: cover;
  width: 178px;
  height: 55px;
  position: relative;
}

.about .wipro-logo-new-og {
  object-fit: cover;
  width: 171px;
  height: 89px;
  position: relative;
}

.about .frame-35 {
  position: absolute;
}

.about .group-2 {
  width: 295px;
  height: 64px;
  position: absolute;
  top: 732px;
  left: 48px;
}

.about .frame-40 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 295px;
  display: flex;
  position: relative;
}

.about .text-wrapper-30 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.about .ellipse-3 {
  background-color: var(--grey-01);
  border-radius: 1px / 1.5px;
  width: 2px;
  height: 3px;
  position: relative;
}

.about .text-wrapper-31 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  text-align: center;
  align-self: stretch;
  position: relative;
}

.about .frame-41 {
  width: 742px;
  height: 1050px;
  position: absolute;
  top: 0;
  left: -185px;
}

.about .frame-42 {
  width: 742px;
  height: 307px;
  position: absolute;
  top: 0;
  left: 0;
}

.about .overlap-group-6 {
  height: 307px;
  position: relative;
}

.about .rectangle-8 {
  background-color: #f6f7f8;
  width: 390px;
  height: 307px;
  position: absolute;
  top: 0;
  left: 185px;
}

.about .frame-43 {
  flex-direction: column;
  align-items: center;
  gap: 25px;
  display: inline-flex;
  position: absolute;
  top: 76px;
  left: 0;
}

.about .mercedes-benz-logo-2 {
  object-fit: cover;
  width: 148px;
  height: 37px;
  position: relative;
}

.about .GE-healthcare-logo-2 {
  object-fit: cover;
  width: 98px;
  height: 55px;
  position: relative;
}

.about .sony-logo-2 {
  object-fit: cover;
  width: 74px;
  height: 13px;
  position: relative;
}

.about .shell-logo-2 {
  object-fit: cover;
  width: 69px;
  height: 39px;
  position: relative;
}

.about .amazon-2 {
  object-fit: cover;
  width: 70px;
  height: 33px;
  position: relative;
}

.about .wipro-logo-new-og-2 {
  object-fit: cover;
  width: 102px;
  height: 53px;
  position: relative;
}

.about .frame-44 {
  flex-direction: column;
  align-items: center;
  gap: 92px;
  display: inline-flex;
  position: absolute;
  top: 366px;
  left: 185px;
}

.about .frame-45 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-end;
  gap: 32px;
  width: 328px;
  display: flex;
  position: relative;
}

.about .frame-46 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 24px;
  width: 328px;
  display: flex;
  position: relative;
}

.about .text-wrapper-32 {
  color: #2c3e47;
  letter-spacing: -.8px;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.4px;
  position: relative;
}

.about .text-wrapper-33 {
  color: #363636;
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  text-align: center;
  width: 297px;
  position: relative;
}

.about .group-3 {
  width: 390px;
  height: 360px;
  position: relative;
}

.about .frame-47 {
  height: 360px;
}

.about .overlap-group-7 {
  background-color: #4d4d4d;
  width: 1440px;
  height: 360px;
}

.about .frame-48 {
  flex-direction: column;
  align-items: center;
  gap: 24px;
  display: inline-flex;
  position: relative;
  top: 85px;
  left: 41px;
}

.about .text-wrapper-34 {
  color: var(--neutral-0);
  letter-spacing: -.8px;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.4px;
  position: relative;
}

.about .are-we-a-good-fit {
  color: #f6f7f8;
  letter-spacing: -.2px;
  text-align: center;
  width: 309px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.about .button {
  cursor: pointer;
  background-color: var(--secondary-02);
  box-sizing: border-box;
  border: none;
  outline: none;
  flex: none;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  display: inline-flex;
  position: relative;
}

.about .text-wrapper-35 {
  color: var(--neutral-0);
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.about .arrow-forward-2 {
  width: 24px;
  height: 24px;
  position: relative;
}

.about .overlap-8 {
  height: 360px;
  position: relative;
}

.about .frame-49 {
  background-color: var(--neutral-70);
  width: 1440px;
  height: 360px;
  position: absolute;
  top: 0;
  left: 0;
}

.about .frame-50 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 69px;
  left: 152px;
}

.about .frame-51 {
  flex-direction: column;
  flex: none;
  align-items: flex-end;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.about .text-wrapper-36 {
  color: #fff;
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.about .are-we-a-good-fit-2 {
  color: var(--neutral-10);
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  text-align: right;
  width: 429px;
  position: relative;
}

.about .CTA-5 {
  all: unset;
  box-sizing: border-box;
  background-color: #2c3e47;
  flex: none;
  align-items: center;
  gap: 10px;
  padding: 12px 16px;
  display: inline-flex;
  position: relative;
}

.about .text-wrapper-37 {
  color: #fff;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: relative;
}

.about .CTA-6 {
  cursor: pointer;
  background-color: var(--secondary-02);
  box-sizing: border-box;
  border: none;
  outline: none;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  display: inline-flex;
  position: absolute;
  top: 251px;
  left: 816px;
}

.about .frame-52 {
  background-color: var(--secondary-03);
  width: 720px;
  height: 360px;
  position: absolute;
  top: 0;
  left: 0;
}

.about .overlap-group-8 {
  background-color: var(--neutral-10);
  width: 720px;
  height: 360px;
  position: absolute;
  top: 0;
  left: 0;
}

.about .frame-53 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 32px;
  display: inline-flex;
  position: relative;
  top: 49px;
  left: 128px;
}

.about .text-wrapper-38 {
  color: #2c3e47;
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.about .text-wrapper-39 {
  color: #363636;
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  text-align: right;
  width: 500px;
  position: relative;
}

.about .frame-54 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 52px;
  left: 817px;
}

.about .frame-55 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.about .text-wrapper-40 {
  color: #fff;
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.about .text-wrapper-41 {
  color: var(--neutral-10);
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  width: 429px;
  position: relative;
}

.about .leadership {
  mix-blend-mode: hard-light;
  width: 270px;
  height: 54px;
  position: absolute;
  top: 2065px;
  left: 572px;
}

.about .header-2 {
  background-color: #f6f7f8;
  justify-content: space-between;
  align-items: center;
  width: 1440px;
  padding: 16px 80px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.about .logo-2 {
  width: 116px;
  height: 56.67px;
  position: relative;
}

.about .holder {
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.about .frame-56 {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 90.33px;
  padding: 10px 8px;
  display: flex;
  position: relative;
}

.about .text-wrapper-42 {
  color: var(--neutral-90);
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: relative;
}

.about .text-wrapper-43 {
  color: var(--neutral-90);
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-left: -2.83px;
  margin-right: -2.83px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: relative;
}

.about .frame-57 {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 80px;
  padding: 10px 8px;
  display: flex;
  position: relative;
}

.about .CTA-7 {
  border-color: var(--primary-02) !important;
  flex: none !important;
}

.about .CTA-8 {
  color: var(--primary-02) !important;
}

.about .leadership-wrapper {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 3014px;
  left: 104px;
}

.about .leadership-2 {
  mix-blend-mode: hard-light;
  width: 188.9px;
  height: 38.09px;
  position: relative;
}

.about .section-industries-2 {
  width: 1440px;
  height: 355px;
  position: absolute;
  top: 806px;
  left: 0;
}

.about .container-2 {
  align-items: center;
  gap: 24px;
  width: 1279px;
  height: 354px;
  padding: 56px 0;
  display: flex;
  position: absolute;
  top: 1px;
  left: 18px;
  overflow: scroll hidden;
}

.about .BFSI-2 {
  background: #fff 0 0 / 100% 100%;
  width: 264px;
  height: 240px;
  position: relative;
}

.about .telecom-3 {
  background-image: url("rectangle-25-1.729cd60a.webp");
  background-position: 50%;
  background-size: cover;
  width: 263px;
  height: 240px;
  position: relative;
}

.about .arrow-forward-ios-2 {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 20px;
  left: 20px;
}

.about .overlap-10 {
  width: 394px;
  height: 1814px;
  position: absolute;
  top: 1161px;
  left: 0;
}

.about .rectangle-9 {
  background-color: #2e5053;
  width: 394px;
  height: 753px;
  position: absolute;
  top: 0;
  left: 0;
}

.about .element-d-cube-design {
  width: 252px;
  height: 263px;
  position: absolute;
  top: 443px;
  left: 48px;
}

.about .frame-61 {
  flex-direction: column;
  align-items: flex-start;
  gap: 34px;
  display: inline-flex;
  position: absolute;
  top: 83px;
  left: 42px;
}

.about .being-the-fastest-2 {
  color: var(--neutral-0);
  letter-spacing: -.18px;
  width: 314px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
  position: relative;
}

.about .text-wrapper-51 {
  color: #fff;
  letter-spacing: -.18px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
}

.about .rectangle-10 {
  width: 212px;
  height: 475px;
  position: absolute;
  top: 446px;
  left: 0;
}

.about .frame-62 {
  flex-direction: column;
  align-items: center;
  display: inline-flex;
  position: absolute;
  top: 753px;
  left: 0;
}

.about .frame-63 {
  background-position: 50%;
  background-size: cover;
  width: 390px;
  height: 353.8px;
  position: relative;
}

.about .frame-64 {
  flex-direction: column;
  align-items: center;
  gap: 9.87px;
  display: inline-flex;
  position: relative;
  top: 177px;
  left: 58px;
}

.about .div-4 {
  color: var(--neutral-0);
  letter-spacing: -.36px;
  text-align: center;
  width: fit-content;
  margin-top: -.82px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 36px;
  font-weight: 400;
  line-height: 50.4px;
  position: relative;
}

.about .text-wrapper-52 {
  letter-spacing: -.18px;
  font-size: 18px;
  font-weight: 700;
  line-height: 25.2px;
}

.about .div-5 {
  color: #0000;
  letter-spacing: -.14px;
  text-align: center;
  width: 273.99px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  position: relative;
}

.about .text-wrapper-53 {
  color: #00dfc2;
  font-weight: 700;
}

.about .frame-65 {
  flex-direction: column;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 1494px;
  left: 691px;
}

.about .frame-66 {
  background-color: var(--neutral-80);
  border-radius: 19.49px;
  flex-direction: column;
  align-items: flex-start;
  gap: 7.5px;
  width: 351px;
  padding: 30.74px 20.24px;
  display: flex;
  position: absolute;
  top: 3108px;
  left: 22px;
}

.about .frame-67 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 49.48px;
  width: 100%;
  display: flex;
  position: relative;
}

.about .element-2 {
  object-fit: cover;
  align-self: stretch;
  width: 100%;
  height: 272px;
  position: relative;
}

.about .frame-68 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
  display: flex;
  position: relative;
}

.about .frame-69 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;
  width: 513.75px;
  margin-right: -203.24px;
  display: flex;
  position: relative;
}

.about .text-wrapper-54 {
  color: var(--neutral-30);
  letter-spacing: -.36px;
  width: 143.25px;
  margin-top: -.75px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 33.6px;
  position: relative;
}

.about .linkedin-circled-3 {
  width: 34px;
  height: 34px;
  position: absolute;
  top: 0;
  left: 277px;
}

.about .text-wrapper-55 {
  color: var(--secondary-02);
  letter-spacing: -.16px;
  width: 513.75px;
  margin-right: -203.24px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  position: relative;
}

.about .text-wrapper-56 {
  color: #f6f7f8;
  letter-spacing: -.14px;
  align-self: stretch;
  font-family: Source Sans Pro, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  position: relative;
}

.about .frame-70 {
  background-color: var(--neutral-80);
  border-radius: 19.49px;
  flex-direction: column;
  align-items: flex-start;
  gap: 7.5px;
  width: 351px;
  padding: 30.74px 20.24px;
  display: flex;
  position: absolute;
  top: 3888px;
  left: 22px;
}

.about .element-3 {
  object-fit: cover;
  align-self: stretch;
  width: 100%;
  height: 273px;
  position: relative;
}

.about .linkedin-circled-4 {
  width: 34px;
  height: 34px;
  position: absolute;
  top: 0;
  left: 257px;
}

.about .overlap-11 {
  background-color: #2e5053;
  width: 390px;
  height: 954px;
  position: absolute;
  top: 3890px;
  left: 0;
}

.about .frame-71 {
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 346px;
  display: flex;
  position: absolute;
  top: 280px;
  left: 22px;
}

.about .frame-72 {
  background-color: #f6f7f8;
  border-radius: 8px;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  padding: 11px 10px;
  display: flex;
  position: relative;
}

.about .frame-73 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 8px;
  width: 100%;
  display: flex;
  position: relative;
}

.about .element-floor-sinman {
  color: #4d4d4d;
  letter-spacing: -.17px;
  text-align: center;
  width: 417px;
  margin-left: -45.5px;
  margin-right: -45.5px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 400;
  line-height: 23.8px;
  position: relative;
}

.about .frame-74 {
  background-color: #f6f7f8;
  border-radius: 8px;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  padding: 10px 9px;
  display: flex;
  position: relative;
}

.about .text-wrapper-57 {
  color: #4d4d4d;
  letter-spacing: -.17px;
  text-align: center;
  width: 417px;
  margin-left: -44.5px;
  margin-right: -44.5px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 400;
  line-height: 23.8px;
  position: relative;
}

.about .frame-75 {
  background-color: #f6f7f8;
  border-radius: 8px;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  padding: 9px;
  display: flex;
  position: relative;
}

.about .seekpng-2 {
  object-fit: cover;
  width: 390px;
  height: 214px;
  position: absolute;
  top: 0;
  left: 0;
}

.contact {
  background-color: #fff;
  flex-direction: row;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.contact .iphone {
  background-color: #fff;
  position: relative;
}

.contact .header {
  width: 390px !important;
  height: 60px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.contact .overlap {
  background-color: #d3d3d3;
  background-image: linear-gradient(0deg, #153032cc 0% 100%), url("image-1-2.ade4ee9d.webp"), none;
  background-position: 50%;
  background-repeat: repeat, repeat, no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll, scroll;
  background-origin: padding-box, padding-box, padding-box;
  background-clip: border-box, border-box, border-box;
  width: 390px;
  height: 240px;
  position: absolute;
  top: 60px;
  left: 0;
}

.contact .div {
  color: var(--neutral-10, #f6f7f8);
  text-align: center;
  letter-spacing: -.75px;
  text-align: center;
  white-space: nowrap;
  font-family: Source Sans Pro;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  position: absolute;
  top: 87px;
  left: 85px;
}

.contact .frame-wrapper {
  width: 295px;
  height: 64px;
  position: absolute;
  top: 732px;
  left: 48px;
}

.contact .frame-6 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 295px;
  display: flex;
  position: relative;
}

.contact .text-wrapper-7 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.contact .ellipse {
  background-color: var(--grey-01);
  border-radius: 1px / 1.5px;
  width: 2px;
  height: 3px;
  position: relative;
}

.contact .text-wrapper-8 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  text-align: center;
  align-self: stretch;
  position: relative;
}

.contact .frame-7 {
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  width: 348px;
  display: flex;
  position: absolute;
  top: 354px;
  left: 20px;
}

.contact .frame-8 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.contact .let-s-help-you-find {
  color: #0000;
  letter-spacing: -.48px;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 600;
  line-height: 32px;
  position: relative;
}

.contact .span {
  color: #2c3e47;
}

.contact .text-wrapper-9 {
  color: #00dfc2;
}

.contact .text-wrapper-10 {
  color: #2c3e47;
  letter-spacing: -.3px;
  font-size: 20px;
  line-height: 20px;
}

.contact .frame-9 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 24px;
  width: 90%;
  display: flex;
  position: relative;
}

.contact .frame-10 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 29px;
  width: 100%;
  display: flex;
  position: relative;
}

.contact .input {
  background-color: var(--neutral-10);
  color: var(--neutral-70);
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  white-space: nowrap;
  border: 0;
  border-radius: 8px;
  flex: none;
  align-self: stretch;
  width: 100%;
  margin-top: -1px;
  padding: 16px;
  position: relative;
}

.contact .please-describe-your-wrapper {
  background-color: var(--neutral-10);
  border-radius: 8px;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  height: 246px;
  padding: 16px;
  display: flex;
  position: relative;
}

.contact .please-describe-your {
  color: var(--neutral-70);
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  white-space: nowrap;
  background-color: #0000;
  border: 0;
  flex: none;
  margin-top: -1px;
  padding: 0;
  position: relative;
}

.contact .button {
  all: unset;
  cursor: pointer;
  box-sizing: border-box;
  background-color: #2e5053;
  width: 167px;
  height: 56px;
  position: relative;
}

.contact .text-wrapper-11 {
  color: var(--neutral-0);
  font-family: var(--subtitle-BLD-font-family);
  font-size: var(--subtitle-BLD-font-size);
  font-style: var(--subtitle-BLD-font-style);
  font-weight: var(--subtitle-BLD-font-weight);
  letter-spacing: var(--subtitle-BLD-letter-spacing);
  line-height: var(--subtitle-BLD-line-height);
  white-space: nowrap;
  height: 24px;
  position: absolute;
  top: 15px;
  left: 36px;
}

.contact .arrow-circle-right {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 16px;
  left: 100px;
}

.contact .frame-11 {
  flex-direction: column;
  align-items: flex-start;
  gap: 21px;
  width: 357px;
  display: flex;
  position: absolute;
  top: 1157px;
  left: 20px;
}

.contact .text-wrapper-12 {
  color: var(--secondary-04);
  letter-spacing: -.36px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 36px;
  font-weight: 600;
  line-height: 50.4px;
  position: relative;
}

.contact .frame-12 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 16px;
  width: 357px;
  display: flex;
  position: relative;
}

.contact .div-wrapper {
  background-color: var(--neutral-0);
  border-radius: 6px;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  padding: 16px 28px;
  display: flex;
  position: relative;
  box-shadow: 0 4px 32px #00000012;
}

.contact .div-2 {
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.contact .img {
  width: 36px;
  height: 36px;
  position: relative;
}

.contact .text-wrapper-13 {
  color: var(--secondary-04);
  letter-spacing: -.2px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  position: relative;
}

.contact .text-wrapper-14 {
  color: var(--neutral-70);
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.contact .overlap-2 {
  background-color: #d3d3d3;
  background-image: linear-gradient(0deg, #153032cc 0% 100%), url("image-1-2.ade4ee9d.webp"), none;
  background-position: 50%;
  background-repeat: repeat, repeat, no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll, scroll;
  background-origin: padding-box, padding-box, padding-box;
  background-clip: border-box, border-box, border-box;
  width: 1440px;
  height: 338px;
  position: absolute;
  top: 100px;
  left: 0;
}

.contact .text-wrapper-15 {
  text-align: center;
  white-space: nowrap;
  color: var(--neutral-10, #f6f7f8);
  text-align: center;
  letter-spacing: -.75px;
  font-family: Source Sans Pro;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  position: absolute;
  top: 124px;
  left: 610px;
}

.contact .frame-13 {
  flex-direction: column;
  align-items: flex-start;
  gap: 21px;
  display: inline-flex;
  position: absolute;
  top: 683px;
  left: 886px;
}

.contact .frame-14 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.contact .frame-15 {
  background-color: var(--neutral-0);
  border-radius: 6px;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 10px;
  width: 397px;
  padding: 16px 28px;
  display: flex;
  position: relative;
  box-shadow: 0 4px 32px #00000012;
}

.contact .frame-16 {
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 499px;
  left: 156px;
}

.contact .let-s-help-you-find-2 {
  color: #0000;
  letter-spacing: -.68px;
  width: 657px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 45px;
  font-weight: 600;
  line-height: 45px;
  position: relative;
}

.contact .frame-17 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: relative;
}

.contact .frame-18 {
  flex: none;
  align-items: flex-start;
  gap: 29px;
  display: inline-flex;
  position: relative;
}

.contact .frame-19 {
  background-color: var(--neutral-10);
  color: var(--neutral-70);
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  white-space: nowrap;
  border: 0;
  border-radius: 8px;
  flex: none;
  width: 316px;
  margin-top: -1px;
  padding: 16px;
  position: relative;
}

.contact .subject {
  background-color: var(--neutral-10);
  color: var(--neutral-70);
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  white-space: nowrap;
  border: 0;
  border-radius: 8px;
  flex: none;
  width: 661px;
  margin-top: -1px;
  padding: 16px;
  position: relative;
}

.contact .input-wrapper {
  background-color: var(--neutral-10);
  color: var(--neutral-70);
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  border: 0;
  border-radius: 8px;
  flex: none;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  max-width: 661px;
  height: 246px;
  max-height: 280px;
  margin-top: -1px;
  padding: 16px;
  display: flex;
  position: relative;
}

.contact .section-growth {
  position: absolute;
}

.contact .background-image {
  background-color: #071103;
  height: 540px;
}

.contact .overlap-group-2 {
  width: 1308px;
  height: 708px;
  position: relative;
  top: -82px;
  left: 15px;
}

.contact .pyramids {
  width: 1089px;
  height: 622px;
  position: absolute;
  top: 0;
  left: 0;
}

.contact .frame-20 {
  flex-direction: column;
  align-items: center;
  gap: 24px;
  display: inline-flex;
  position: absolute;
  top: 237px;
  left: 426px;
}

.contact .frame-21 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 8px;
  width: 538px;
  display: flex;
  position: relative;
}

.contact .text-wrapper-16 {
  color: var(--neutral-0);
  letter-spacing: -.54px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 50px;
  font-weight: 600;
  line-height: 120%;
  position: relative;
}

.contact .text-wrapper-17 {
  color: var(--neutral-0);
  letter-spacing: -.2px;
  text-align: center;
  align-self: stretch;
  font-family: Source Sans Pro, Helvetica;
  font-size: 28px;
  font-weight: 400;
  line-height: 120%;
  position: relative;
}

.contact .frame-22 {
  flex-direction: column;
  align-items: center;
  gap: 24px;
  display: inline-flex;
  position: absolute;
  top: 274px;
  left: 870px;
}

.contact .minimal-podium {
  width: 264px;
  height: 259px;
  position: absolute;
  top: 177px;
  left: 576px;
}

.contact .rectangle {
  mix-blend-mode: overlay;
  width: 679px;
  height: 678px;
  position: absolute;
  top: 30px;
  left: 444px;
}

.contact .overlap-wrapper {
  background-color: #071103;
  width: 391px;
  height: 627px;
  position: absolute;
  top: 0;
  left: 0;
}

.contact .overlap-3 {
  width: 390px;
  height: 382px;
  position: relative;
  top: 245px;
  left: 1px;
}

.contact .pyramids-2 {
  width: 390px;
  height: 313px;
  position: absolute;
  top: 69px;
  left: 0;
}

.contact .minimal-podium-2 {
  width: 151px;
  height: 148px;
  position: absolute;
  top: 170px;
  left: 233px;
}

.contact .rectangle-2 {
  mix-blend-mode: overlay;
  width: 318px;
  height: 382px;
  position: absolute;
  top: 0;
  left: 72px;
}

.contact .frame-23 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 338px;
  display: flex;
  position: absolute;
  top: 9px;
  left: 26px;
}

.contact .frame-24 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 8px;
  width: 100%;
  display: flex;
  position: relative;
}

.contact .text-wrapper-18 {
  color: var(--neutral-0);
  letter-spacing: -.36px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  position: relative;
}

.contact .text-wrapper-19 {
  color: var(--neutral-0);
  letter-spacing: -.16px;
  text-align: center;
  align-self: stretch;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  position: relative;
}

.contact .frame-25 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 338px;
  display: flex;
  position: absolute;
  top: 220px;
  left: 27px;
}

.contact .frame-26 {
  width: 593px;
  height: 294px;
  position: absolute;
  top: 1839px;
  left: 88px;
}

.contact .frame-27 {
  background-color: var(--neutral-10);
  border-radius: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 76px 88px;
  display: inline-flex;
  position: relative;
}

.contact .frame-28 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.contact .text-wrapper-20 {
  color: #2e5053;
  letter-spacing: -.29px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 29px;
  font-weight: 600;
  line-height: 40.6px;
  position: relative;
}

.contact .text-wrapper-21 {
  color: var(--neutral-70);
  letter-spacing: -.2px;
  text-align: center;
  width: 417px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.contact .frame-29 {
  background-color: var(--neutral-10);
  border-radius: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 76px 88px;
  display: inline-flex;
  position: absolute;
  top: 2181px;
  left: 88px;
}

.contact .frame-30 {
  background-color: var(--neutral-10);
  border-radius: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 76px 88px;
  display: inline-flex;
  position: absolute;
  top: 1839px;
  left: 729px;
}

.contact .frame-31 {
  background-color: var(--neutral-10);
  border-radius: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 76px 88px;
  display: inline-flex;
  position: absolute;
  top: 2181px;
  left: 729px;
}

.contact .frame-32 {
  background-color: var(--neutral-90);
  width: 1440px;
  height: 217px;
  position: absolute;
  top: 3274px;
  left: 0;
}

.contact .frame-33 {
  justify-content: space-between;
  align-items: center;
  width: 1113px;
  display: flex;
  position: relative;
  top: 56px;
  left: 157px;
}

.contact .text-wrapper-22 {
  color: var(--secondary-02);
  letter-spacing: -.75px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 50px;
  font-weight: 600;
  line-height: 60px;
  position: relative;
}

.contact .text-wrapper-23 {
  color: #fff;
  letter-spacing: -.2px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.contact .group-wrapper {
  background-color: var(--white);
  width: 1441px;
  height: 292px;
  position: absolute;
  top: 3846px;
  left: -1px;
}

.contact .group-2 {
  width: 400px;
  height: 17px;
  position: relative;
  top: 242px;
  left: 501px;
}

.contact .frame-34 {
  justify-content: center;
  align-items: center;
  gap: 5px;
  display: inline-flex;
  position: relative;
}

.contact .text-wrapper-24 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.contact .ellipse-2 {
  background-color: var(--grey-01);
  border-radius: 1.5px;
  width: 3px;
  height: 3px;
  position: relative;
}

.contact .group-3 {
  background-size: 100% 100%;
  position: absolute;
}

.contact .frame-35 {
  background-color: var(--neutral-0);
  border-radius: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 24px;
  position: relative;
  box-shadow: 0 4px 32px #0000001f;
}

.contact .frame-36 {
  width: 340px;
  height: 145px;
  position: relative;
}

.contact .direction {
  width: 35px;
  height: 35px;
  position: absolute;
}

.contact .text-wrapper-25 {
  color: var(--neutral-70);
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  width: 328px;
  position: absolute;
  top: 36px;
  left: 0;
}

.contact .text-wrapper-26 {
  color: #4d9f98;
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  white-space: nowrap;
  position: absolute;
  top: 150px;
  left: 0;
}

.contact .frame-37 {
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;
  position: absolute;
  top: 2px;
  left: 0;
}

.contact .header-2 {
  background-color: var(--neutral-10);
  justify-content: space-between;
  align-items: center;
  width: 1440px;
  padding: 16px 80px;
  display: flex;
  position: absolute;
  top: -2px;
  left: 9px;
}

.contact .logo-2 {
  width: 116px;
  height: 56.67px;
  position: relative;
}

.contact .frame-38 {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 90.33px;
  padding: 10px 8px;
  display: flex;
  position: relative;
}

.contact .text-wrapper-27 {
  color: var(--neutral-90);
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.contact .text-wrapper-28 {
  color: var(--neutral-90);
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-left: -2.83px;
  margin-right: -2.83px;
  position: relative;
}

.contact .frame-39 {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 80px;
  padding: 10px 8px;
  display: flex;
  position: relative;
}

.contact .CTA-instance {
  border-color: var(--primary-02) !important;
  flex: none !important;
}

.contact .instance-node {
  color: var(--primary-02) !important;
}

.contact .overlap-group-wrapper {
  background-color: var(--white);
  width: 1441px;
  height: 292px;
  position: absolute;
  top: 3491px;
  left: 2px;
}

.contact .overlap-4 {
  height: 292px;
  position: relative;
}

.contact .frame-40 {
  background-color: var(--neutral-90);
  width: 1441px;
  height: 292px;
  position: absolute;
  top: 0;
  left: 0;
}

.contact .DIV-3 {
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 53px;
  left: 738px;
}

.contact .text-wrapper-29 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1233px;
}

.contact .text-wrapper-30 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1282px;
}

.contact .text-wrapper-31 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1331px;
}

.contact .text-wrapper-32 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1187px;
}

.contact .text-wrapper-33 {
  color: var(--white);
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  white-space: nowrap;
  height: 28px;
  position: absolute;
  top: 100px;
  left: 738px;
}

.contact .text-wrapper-34 {
  color: var(--white);
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  white-space: nowrap;
  height: 28px;
  position: absolute;
  top: 100px;
  left: 869px;
}

.contact .frame-41 {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: absolute;
  top: 115px;
  left: 81px;
}

.contact .group-4 {
  width: 37px;
  height: 52px;
  position: absolute;
  top: 152px;
  left: 1140px;
}

.contact .linkedin-2 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1187px;
}

.contact .facebook-2 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1234px;
}

.contact .facebook-3 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1281px;
}

.contact .facebook-4 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1328px;
}

.contact .group-5 {
  width: 400px;
  height: 17px;
  position: absolute;
  top: 242px;
  left: 501px;
}

.contact .logo-3 {
  width: 116px;
  height: 56px;
  position: absolute;
  top: 39px;
  left: 76px;
}

.contact .overlap-5 {
  background-size: 100% 100%;
  width: 372px;
  height: 187px;
  position: absolute;
  top: 3058px;
  left: 9px;
}

.contact .frame-42 {
  background-color: var(--neutral-0);
  border-radius: 5.75px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4.79px;
  height: 103px;
  padding: 11.51px;
  display: inline-flex;
  position: relative;
  top: 19px;
  left: 15px;
  box-shadow: 0 1.92px 15.34px #0000001f;
}

.contact .frame-43 {
  width: 162.99px;
  height: 69.51px;
  position: relative;
}

.contact .direction-2 {
  width: 17px;
  height: 17px;
  position: absolute;
  top: 0;
  left: 146px;
}

.contact .text-wrapper-35 {
  color: var(--neutral-70);
  letter-spacing: -.08px;
  width: 157px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 8.1px;
  font-weight: 400;
  line-height: 11.4px;
  position: absolute;
  top: 17px;
  left: 0;
}

.contact .text-wrapper-36 {
  color: #4d9f98;
  letter-spacing: -.08px;
  white-space: nowrap;
  font-family: Source Sans Pro, Helvetica;
  font-size: 8.1px;
  font-weight: 400;
  line-height: 11.4px;
  position: absolute;
  top: 72px;
  left: 0;
}

.contact .frame-44 {
  flex-direction: column;
  align-items: flex-start;
  gap: 1.92px;
  display: inline-flex;
  position: absolute;
  top: 1px;
  left: 0;
}

.contact .text-wrapper-37 {
  color: var(--secondary-04);
  letter-spacing: -.1px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -.48px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 9.6px;
  font-weight: 700;
  line-height: 13.4px;
  position: relative;
}

.contact .frame-45 {
  background-color: var(--neutral-90);
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 394px;
  padding: 41px 15px;
  display: flex;
  position: absolute;
  top: 3323px;
  left: 0;
}

.contact .frame-46 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  display: flex;
  position: relative;
}

.contact .frame-47 {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 60px;
  width: 100%;
  display: flex;
  position: relative;
}

.contact .frame-48 {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 16px;
  display: flex;
  position: relative;
}

.contact .frame-49 {
  flex-direction: column;
  flex: 1;
  align-items: center;
  gap: 16px;
  display: flex;
  position: relative;
}

.contact .text-wrapper-38 {
  color: #fff;
  letter-spacing: -.2px;
  text-align: center;
  width: 116px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.contact .frame-50 {
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
  width: 368px;
  display: flex;
  position: absolute;
  top: 2103px;
  left: 11px;
}

.contact .frame-51 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  padding: 0 16px;
  display: flex;
  position: relative;
}

.contact .frame-52 {
  background-color: var(--neutral-10);
  border-radius: 12px;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  padding: 32px 20px;
  display: flex;
  position: relative;
}

.contact .frame-53 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.contact .text-wrapper-39 {
  color: #2e5053;
  letter-spacing: -.29px;
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 29px;
  font-weight: 600;
  line-height: 40.6px;
  position: relative;
}

.contact .text-wrapper-40 {
  color: var(--neutral-70);
  letter-spacing: -.2px;
  text-align: center;
  align-self: stretch;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.industries {
  background-color: #fff;
  flex-direction: row;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.industries .iphone {
  background-color: #fff;
  position: relative;
}

.industries .overlap {
  position: absolute;
}

.industries .frame {
  background-color: #000;
  width: 1442px;
  height: 474px;
  position: absolute;
  top: 104px;
  left: 5px;
  overflow: hidden;
}

.industries .overlap-group {
  height: 588px;
  position: relative;
  top: -57px;
}

.industries .rectangle {
  background-color: #000;
  width: 1442px;
  height: 474px;
  position: absolute;
  top: 57px;
  left: 0;
}

.industries .hud-ball {
  object-fit: cover;
  width: 785px;
  height: 588px;
  position: absolute;
  top: 0;
  left: 322px;
}

.industries .img {
  width: 309px;
  height: 474px;
  position: absolute;
  top: 57px;
  left: 1131px;
}

.industries .overlap-group-wrapper {
  width: 410px;
  height: 876px;
  position: absolute;
  top: 0;
  left: 0;
}

.industries .div {
  background-color: #000;
  width: 390px;
  height: 876px;
  position: relative;
  left: 10px;
}

.industries .rectangle-2 {
  width: 367px;
  height: 335px;
  position: absolute;
  top: 0;
  left: 0;
}

.industries .overlap-2 {
  width: 358px;
  height: 290px;
  position: absolute;
  top: 586px;
  left: 32px;
}

.industries .rectangle-3 {
  width: 341px;
  height: 290px;
  position: absolute;
  top: 0;
  left: 17px;
}

.industries .p {
  color: var(--neutral-50);
  letter-spacing: -.16px;
  text-align: center;
  width: 171px;
  height: 44px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 22.4px;
  position: absolute;
  top: 82px;
  left: 77px;
}

.industries .text-wrapper-2 {
  color: var(--neutral-50);
  letter-spacing: -.48px;
  text-align: center;
  width: 325px;
  height: 38px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.4px;
  position: absolute;
  top: 135px;
  left: 0;
}

.industries .image {
  object-fit: cover;
  position: absolute;
}

.industries .frame-2 {
  width: 524px;
  height: 120px;
  position: absolute;
  top: 125px;
  left: 50px;
}

.industries .text-wrapper-3 {
  color: var(--neutral-0);
  letter-spacing: -.39px;
  text-align: center;
  width: 304px;
  height: 144px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 26px;
  font-weight: 400;
  line-height: 36.4px;
  position: absolute;
  top: 33px;
  left: 0;
}

.industries .text-wrapper-4 {
  color: var(--neutral-0);
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  text-align: center;
  width: 400px;
  height: 24px;
  position: absolute;
  top: 4px;
  left: -45px;
}

.industries .text-wrapper-5 {
  color: #fff;
  letter-spacing: -.32px;
  text-align: center;
  white-space: nowrap;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 700;
  line-height: 44.8px;
  position: absolute;
  top: 156px;
  left: 654px;
}

.industries .frame-wrapper {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 366px;
  left: 914px;
}

.industries .frame-3 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.industries .text-wrapper-6 {
  color: var(--neutral-50);
  letter-spacing: -.2px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  position: relative;
}

.industries .text-wrapper-7 {
  color: var(--neutral-50);
  letter-spacing: -.75px;
  white-space: nowrap;
  width: fit-content;
  font-family: Source Sans Pro, Helvetica;
  font-size: 50px;
  font-weight: 400;
  line-height: 60px;
  position: relative;
}

.industries .rectangle-4 {
  width: 262px;
  height: 596px;
  position: absolute;
  top: 0;
  left: 5px;
}

.industries .heading {
  align-items: flex-start;
  gap: 16px;
  padding: 0 6px 0 0;
  display: inline-flex;
  position: absolute;
  top: 302px;
  left: 87px;
}

.industries .frame-4 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.industries .text-wrapper-8 {
  color: var(--neutral-0);
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  width: 400px;
  margin-top: -1px;
  position: relative;
}

.industries .text-wrapper-9 {
  color: var(--neutral-0);
  font-family: var(--h3-REG-font-family);
  font-size: var(--h3-REG-font-size);
  font-style: var(--h3-REG-font-style);
  font-weight: var(--h3-REG-font-weight);
  letter-spacing: var(--h3-REG-letter-spacing);
  line-height: var(--h3-REG-line-height);
  width: 424px;
  position: relative;
}

.industries .header {
  background-color: var(--neutral-10);
  justify-content: space-between;
  align-items: center;
  width: 1440px;
  padding: 16px 80px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.industries .logo {
  width: 116px;
  height: 56.67px;
  position: relative;
}

.industries .holder {
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.industries .div-wrapper {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 90.33px;
  padding: 10px 8px;
  display: flex;
  position: relative;
}

.industries .text-wrapper-10 {
  color: var(--neutral-90);
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: relative;
}

.industries .text-wrapper-11 {
  color: var(--neutral-90);
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-left: -2.83px;
  margin-right: -2.83px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: relative;
}

.industries .frame-5 {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 80px;
  padding: 10px 8px;
  display: flex;
  position: relative;
}

.industries .CTA-instance {
  border-color: #4d9f98 !important;
  flex: none !important;
}

.industries .instance-node {
  color: #4d9f98 !important;
}

.industries .overlap-3 {
  position: absolute;
}

.industries .footer {
  background-color: var(--white);
  width: 390px;
  height: 860px;
  position: absolute;
  top: 544px;
  left: 0;
  overflow: hidden;
}

.industries .overlap-4 {
  width: 390px;
  height: 907px;
  position: relative;
}

.industries .frame-6 {
  background-color: var(--neutral-90);
  width: 390px;
  height: 907px;
  position: absolute;
  top: 0;
  left: 0;
}

.industries .frame-7 {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: absolute;
  top: 115px;
  left: 26px;
}

.industries .text-wrapper-12 {
  color: #fff;
  font-family: var(--paragraph-ALCPS-font-family);
  font-size: var(--paragraph-ALCPS-font-size);
  font-style: var(--paragraph-ALCPS-font-style);
  font-weight: var(--paragraph-ALCPS-font-weight);
  letter-spacing: var(--paragraph-ALCPS-letter-spacing);
  line-height: var(--paragraph-ALCPS-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.industries .text-wrapper-13 {
  color: var(--neutral-10);
  font-family: var(--paragraph-REG-font-family);
  font-size: var(--paragraph-REG-font-size);
  font-style: var(--paragraph-REG-font-style);
  font-weight: var(--paragraph-REG-font-weight);
  letter-spacing: var(--paragraph-REG-letter-spacing);
  line-height: var(--paragraph-REG-line-height);
  width: 324px;
  position: relative;
}

.industries .DIV {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 113px;
  display: flex;
  position: absolute;
  top: 272px;
  left: 195px;
}

.industries .text-wrapper-14 {
  color: var(--white);
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.industries .text-wrapper-15 {
  color: var(--white);
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.industries .DIV-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 113px;
  display: flex;
  position: absolute;
  top: 272px;
  left: 26px;
}

.industries .frame-8 {
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 583px;
  left: 69px;
}

.industries .group {
  width: 37px;
  height: 52px;
  position: relative;
}

.industries .text-wrapper-16 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 38px;
  left: 1px;
}

.industries .face-book {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 0;
  left: 0;
}

.industries .linkedin {
  flex: none;
  position: relative;
}

.industries .text-wrapper-17 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  width: fit-content;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: relative;
}

.industries .facebook {
  width: 35px;
  height: 35px;
  position: relative;
}

.industries .frame-9 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.industries .logo-2 {
  width: 116px;
  height: 56px;
  position: absolute;
  top: 39px;
  left: 24px;
}

.industries .group-2 {
  width: 295px;
  height: 64px;
  position: absolute;
  top: 732px;
  left: 48px;
}

.industries .frame-10 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 295px;
  display: flex;
  position: relative;
}

.industries .text-wrapper-18 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.industries .ellipse {
  background-color: var(--grey-01);
  border-radius: 1px / 1.5px;
  width: 2px;
  height: 3px;
  position: relative;
}

.industries .text-wrapper-19 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  text-align: center;
  align-self: stretch;
  position: relative;
}

.industries .rectangle-5 {
  background-color: var(--neutral-30);
  position: absolute;
}

.industries .frame-11 {
  flex-wrap: wrap;
  align-items: center;
  gap: 66.95px 30px;
  width: 342px;
  display: flex;
  position: absolute;
  top: 147px;
  left: 24px;
}

.industries .unknown {
  object-fit: cover;
  width: 111.06px;
  height: 37.37px;
  position: relative;
}

.industries .flipkart-logo {
  object-fit: cover;
  width: 141.16px;
  height: 37.37px;
  position: relative;
}

.industries .edutech-social-share {
  object-fit: cover;
  width: 120.4px;
  height: 62.28px;
  position: relative;
}

.industries .OLX-logo {
  object-fit: cover;
  width: 74px;
  height: 41px;
  position: relative;
}

.industries .element-lam {
  object-fit: cover;
  width: 125.07px;
  height: 45.15px;
  position: relative;
}

.industries .text-wrapper-20 {
  color: #000;
  letter-spacing: -.72px;
  opacity: .68;
  white-space: nowrap;
  width: fit-content;
  font-family: Source Sans Pro, Helvetica;
  font-size: 47.7px;
  font-weight: 900;
  line-height: 66.8px;
  position: relative;
}

.industries .group-3 {
  opacity: .68;
  width: 107px;
  height: 67.42px;
  position: relative;
}

.industries .overlap-group-2 {
  width: 103px;
  height: 67px;
  position: relative;
}

.industries .text-wrapper-21 {
  color: #000;
  letter-spacing: -.72px;
  white-space: nowrap;
  height: 67px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 47.7px;
  font-weight: 900;
  line-height: 66.8px;
  position: absolute;
  top: 0;
  left: 0;
}

.industries .text-wrapper-22 {
  color: #000;
  letter-spacing: -.16px;
  white-space: nowrap;
  height: 15px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10.4px;
  font-weight: 300;
  line-height: 14.5px;
  position: absolute;
  top: 52px;
  left: 42px;
}

.industries .text-wrapper-23 {
  color: #000;
  letter-spacing: -.33px;
  text-align: center;
  width: 346px;
  height: 45px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 22px;
  font-weight: 700;
  line-height: 30.8px;
  position: absolute;
  top: 43px;
  left: 19px;
}

.industries .rectangle-6 {
  background-color: #fff;
  width: 1443px;
  height: 442px;
  position: absolute;
  top: 864px;
  left: 6px;
}

.industries .IT-services {
  color: #000;
  letter-spacing: -.53px;
  white-space: nowrap;
  width: 411px;
  height: 45px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 700;
  line-height: 49px;
  position: absolute;
  top: 933px;
  left: 79px;
}

.industries .group-4 {
  opacity: .89;
  width: 1501px;
  height: 214px;
  position: absolute;
  top: 989px;
  left: 77px;
}

.industries .overlap-5 {
  width: 380px;
  height: 158px;
  position: absolute;
  top: 39px;
  left: 0;
}

.industries .utypaslbyxwfuwhfdzxd {
  object-fit: cover;
  width: 158px;
  height: 158px;
  position: absolute;
  top: 0;
  left: 0;
}

.industries .wipro-logo-new-og {
  object-fit: cover;
  width: 228px;
  height: 119px;
  position: absolute;
  top: 20px;
  left: 152px;
}

.industries .deloitte-logo {
  object-fit: cover;
  width: 179px;
  height: 100px;
  position: absolute;
  top: 68px;
  left: 398px;
}

.industries .group-5 {
  width: 139px;
  height: 132px;
  position: absolute;
  top: 41px;
  left: 596px;
}

.industries .overlap-group-3 {
  height: 132px;
  position: relative;
}

.industries .ey-squarelogo {
  object-fit: cover;
  width: 129px;
  height: 129px;
  position: absolute;
  top: 3px;
  left: 5px;
}

.industries .ey-squarelogo-2 {
  width: 139px;
  height: 60px;
  position: absolute;
  top: 0;
  left: 0;
}

.industries .ae-dbe-f {
  object-fit: cover;
  width: 200px;
  height: 200px;
  position: absolute;
  top: 14px;
  left: 744px;
}

.industries .capgemini-logo {
  object-fit: cover;
  width: 244px;
  height: 57px;
  position: absolute;
  top: 95px;
  left: 970px;
}

.industries .element-accenture {
  object-fit: cover;
  width: 143px;
  height: 208px;
  position: absolute;
  top: 0;
  left: 1224px;
}

.industries .group-6 {
  width: 951px;
  height: 120px;
  position: absolute;
  top: 1454px;
  left: 86px;
}

.industries .unknown-2 {
  object-fit: cover;
  width: 214px;
  height: 72px;
  position: absolute;
  top: 48px;
  left: 0;
}

.industries .flipkart-logo-2 {
  object-fit: cover;
  width: 272px;
  height: 72px;
  position: absolute;
  top: 48px;
  left: 328px;
}

.industries .edutech-social-share-2 {
  object-fit: cover;
  width: 232px;
  height: 120px;
  position: absolute;
  top: 0;
  left: 719px;
}

.industries .overlap-wrapper {
  width: 1440px;
  height: 360px;
  position: absolute;
  top: 1856px;
  left: 0;
}

.industries .overlap-6 {
  height: 360px;
  position: relative;
}

.industries .frame-12 {
  background-color: #4d4d4d;
  width: 1440px;
  height: 360px;
  position: absolute;
  top: 0;
  left: 0;
}

.industries .frame-13 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 32px;
  display: inline-flex;
  position: relative;
  top: 69px;
  left: 152px;
}

.industries .frame-14 {
  flex-direction: column;
  flex: none;
  align-items: flex-end;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.industries .text-wrapper-24 {
  color: #fff;
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.industries .are-we-a-good-fit {
  color: #f6f7f8;
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  text-align: right;
  width: 429px;
  position: relative;
}

.industries .button {
  all: unset;
  box-sizing: border-box;
  background-color: #2c3e47;
  flex: none;
  align-items: center;
  gap: 10px;
  padding: 12px 16px;
  display: inline-flex;
  position: relative;
}

.industries .text-wrapper-25 {
  color: #fff;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: relative;
}

.industries .arrow-forward {
  width: 24px;
  height: 24px;
  position: relative;
}

.industries .frame-15 {
  background-color: #2e5053;
  width: 720px;
  height: 360px;
  position: absolute;
  top: 0;
  left: 0;
}

.industries .overlap-group-4 {
  background-color: #f6f7f8;
  width: 720px;
  height: 360px;
  position: absolute;
  top: 0;
  left: 0;
}

.industries .frame-16 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 32px;
  display: inline-flex;
  position: relative;
  top: 49px;
  left: 128px;
}

.industries .text-wrapper-26 {
  color: #2c3e47;
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.industries .text-wrapper-27 {
  color: var(--neutral-80);
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  text-align: right;
  width: 500px;
  position: relative;
}

.industries .frame-17 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 52px;
  left: 817px;
}

.industries .frame-18 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.industries .text-wrapper-28 {
  color: #fff;
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.industries .text-wrapper-29 {
  color: #f6f7f8;
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  width: 429px;
  position: relative;
}

.industries .text-wrapper-30 {
  color: #000;
  letter-spacing: -.53px;
  white-space: nowrap;
  width: 411px;
  height: 45px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 700;
  line-height: 49px;
  position: absolute;
  top: 1381px;
  left: 85px;
}

.industries .group-7 {
  width: 1454px;
  height: 869px;
  position: absolute;
  top: 0;
  left: 6px;
}

.industries .overlap-7 {
  background-color: var(--secondary-03);
  width: 1452px;
  height: 869px;
  position: relative;
}

.industries .text-wrapper-31 {
  color: #fff;
  letter-spacing: -.53px;
  white-space: nowrap;
  width: 411px;
  height: 45px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 700;
  line-height: 49px;
  position: absolute;
  top: 88px;
  left: 80px;
}

.industries .amazon-wrapper {
  background-color: var(--neutral-0);
  border-radius: 8px;
  width: 387px;
  height: 181px;
  position: absolute;
  top: 175px;
  left: 80px;
  box-shadow: 0 2px 17px #0000001a;
}

.industries .amazon {
  object-fit: cover;
  width: 178px;
  height: 55px;
  position: absolute;
  top: 65px;
  left: 105px;
}

.industries .sony-logo-wrapper {
  background-color: var(--neutral-0);
  border-radius: 8px;
  width: 387px;
  height: 181px;
  position: absolute;
  top: 381px;
  left: 80px;
  box-shadow: 0 2px 17px #0000001a;
}

.industries .sony-logo {
  object-fit: cover;
  width: 256px;
  height: 92px;
  position: absolute;
  top: 45px;
  left: 66px;
}

.industries .env-yodlee-color-wrapper {
  background-color: var(--neutral-0);
  border-radius: 8px;
  width: 387px;
  height: 181px;
  position: absolute;
  top: 587px;
  left: 80px;
  box-shadow: 0 2px 17px #0000001a;
}

.industries .env-yodlee-color {
  object-fit: cover;
  width: 248px;
  height: 83px;
  position: absolute;
  top: 49px;
  left: 78px;
}

.industries .vmware-wrapper {
  background-color: var(--neutral-0);
  border-radius: 8px;
  width: 386px;
  height: 181px;
  position: absolute;
  top: 175px;
  left: 527px;
  box-shadow: 0 2px 17px #0000001a;
}

.industries .vmware {
  object-fit: cover;
  width: 257px;
  height: 42px;
  position: absolute;
  top: 72px;
  left: 67px;
}

.industries .na-logo-black-rgb-wrapper {
  background-color: var(--neutral-0);
  border-radius: 8px;
  width: 386px;
  height: 181px;
  position: absolute;
  top: 381px;
  left: 527px;
  box-shadow: 0 2px 17px #0000001a;
}

.industries .na-logo-black-rgb {
  object-fit: cover;
  width: 257px;
  height: 103px;
  position: absolute;
  top: 38px;
  left: 64px;
}

.industries .smarten-spaces-logo-wrapper {
  background-color: var(--neutral-0);
  border-radius: 8px;
  width: 386px;
  height: 181px;
  position: absolute;
  top: 587px;
  left: 527px;
  box-shadow: 0 2px 17px #0000001a;
}

.industries .smarten-spaces-logo {
  object-fit: cover;
  width: 189px;
  height: 61px;
  position: absolute;
  top: 60px;
  left: 100px;
}

.industries .overlap-8 {
  width: 387px;
  height: 209px;
  position: absolute;
  top: 160px;
  left: 973px;
}

.industries .rectangle-7 {
  background-color: var(--neutral-0);
  border-radius: 8px;
  width: 387px;
  height: 181px;
  position: absolute;
  top: 15px;
  left: 0;
  box-shadow: 0 2px 17px #0000001a;
}

.industries .inmobi-logo {
  object-fit: cover;
  width: 314px;
  height: 209px;
  position: absolute;
  top: 0;
  left: 30px;
}

.industries .xylem-logo-wrapper {
  background-color: var(--neutral-0);
  border-radius: 8px;
  width: 387px;
  height: 181px;
  position: absolute;
  top: 381px;
  left: 973px;
  box-shadow: 0 2px 17px #0000001a;
}

.industries .xylem-logo {
  object-fit: cover;
  width: 183px;
  height: 76px;
  position: absolute;
  top: 51px;
  left: 95px;
}

.industries .group-8 {
  width: 573px;
  height: 129px;
  position: absolute;
  top: 1652px;
  left: 441px;
}

.industries .text-wrapper-32 {
  color: #000;
  letter-spacing: -1.38px;
  opacity: .68;
  white-space: nowrap;
  height: 129px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 92px;
  font-weight: 900;
  line-height: 128.8px;
  position: absolute;
  top: 0;
  left: 0;
}

.industries .group-9 {
  opacity: .68;
  width: 201px;
  height: 129px;
  position: absolute;
  top: 0;
  left: 374px;
}

.industries .overlap-group-5 {
  width: 197px;
  height: 129px;
  position: relative;
}

.industries .text-wrapper-33 {
  color: #000;
  letter-spacing: -1.38px;
  white-space: nowrap;
  height: 129px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 92px;
  font-weight: 900;
  line-height: 128.8px;
  position: absolute;
  top: 0;
  left: 0;
}

.industries .text-wrapper-34 {
  color: #000;
  letter-spacing: -.3px;
  white-space: nowrap;
  height: 28px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 300;
  line-height: 28px;
  position: absolute;
  top: 101px;
  left: 80px;
}

.industries .OLX-logo-2 {
  object-fit: cover;
  width: 121px;
  height: 68px;
  position: absolute;
  top: 1502px;
  left: 1158px;
}

.industries .element-lam-2 {
  object-fit: cover;
  width: 241px;
  height: 87px;
  position: absolute;
  top: 1680px;
  left: 83px;
}

.industries .CTA-2 {
  cursor: pointer;
  background-color: var(--secondary-02);
  box-sizing: border-box;
  border: none;
  outline: none;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  display: inline-flex;
  position: absolute;
  top: 2115px;
  left: 816px;
}

.industries .text-wrapper-35 {
  color: var(--neutral-0);
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.industries .frame-19 {
  background-color: #6fd6b9;
  width: 1458px;
  height: 638px;
  position: absolute;
  top: 1166px;
  left: -4px;
}

.industries .frame-20 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  width: 64px;
  display: flex;
  position: absolute;
  top: 85px;
  left: 84px;
}

.industries .frame-21 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 12px;
  margin-right: -4px;
  display: inline-flex;
  position: relative;
}

.industries .text-wrapper-36 {
  color: #1f1f1f;
  letter-spacing: -.53px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 700;
  line-height: 49px;
  position: relative;
}

.industries .group-10 {
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  align-items: center;
  gap: 60px;
  width: 1240px;
  height: 300px;
  display: grid;
  position: absolute;
  top: 170px;
  left: 84px;
}

.industries .overlap-group-6 {
  background-image: url("rectangle-118-6.a869fe88.webp");
  background-size: 100% 100%;
  width: 272px;
  height: 218px;
  position: relative;
}

.industries .text-wrapper-37 {
  color: var(--neutral-0);
  letter-spacing: -.24px;
  width: 164px;
  height: 34px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
  position: absolute;
  top: 166px;
  left: 57px;
}

.industries .group-11 {
  width: 274px;
  height: 218px;
  position: absolute;
  top: 336px;
  left: 197px;
}

.industries .real-page-wrapper {
  background-image: url("rectangle-118-7.3e721580.webp");
  background-position: 50%;
  background-size: cover;
  width: 272px;
  height: 218px;
  position: relative;
}

.industries .real-page {
  color: var(--neutral-0);
  letter-spacing: -.24px;
  width: 101px;
  height: 34px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
  position: absolute;
  top: 166px;
  left: 90px;
}

.industries .group-12 {
  width: 274px;
  height: 218px;
  position: absolute;
  top: 85px;
  left: 790px;
}

.industries .overlap-9 {
  background-image: url("rectangle-118-8.648607af.webp");
  background-position: 50%;
  background-size: cover;
  width: 272px;
  height: 218px;
  position: relative;
}

.industries .text-wrapper-38 {
  color: var(--neutral-0);
  letter-spacing: -.24px;
  width: 227px;
  height: 34px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
  position: absolute;
  top: 166px;
  left: 21px;
}

.industries .group-13 {
  width: 274px;
  height: 218px;
  position: absolute;
  top: 336px;
  left: 790px;
}

.industries .overlap-10 {
  background-image: url("rectangle-118-9.af60a49c.webp");
  background-position: 50%;
  background-size: cover;
  width: 272px;
  height: 218px;
  position: relative;
}

.industries .text-wrapper-39 {
  color: var(--neutral-0);
  letter-spacing: -.24px;
  width: 177px;
  height: 34px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
  position: absolute;
  top: 166px;
  left: 44px;
}

.industries .group-14 {
  width: 274px;
  height: 218px;
  position: absolute;
  top: 85px;
  left: 495px;
}

.industries .overlap-11 {
  background-image: url("rectangle-118-10.5de927a5.webp");
  background-size: 100% 100%;
  width: 272px;
  height: 218px;
  position: relative;
}

.industries .text-wrapper-40 {
  color: var(--neutral-0);
  letter-spacing: -.24px;
  width: 34px;
  height: 34px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
  position: absolute;
  top: 166px;
  left: 114px;
}

.industries .group-15 {
  width: 274px;
  height: 218px;
  position: absolute;
  top: 336px;
  left: 495px;
}

.industries .overlap-12 {
  background-image: url("rectangle-118-11.3c7e38bf.webp");
  background-position: 50%;
  background-size: cover;
  width: 272px;
  height: 218px;
  position: relative;
}

.industries .text-wrapper-41 {
  color: var(--neutral-0);
  letter-spacing: -.24px;
  width: 45px;
  height: 34px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
  position: absolute;
  top: 166px;
  left: 111px;
}

.industries .group-16 {
  width: 274px;
  height: 218px;
  position: absolute;
  top: 85px;
  left: 1089px;
}

.industries .overlap-13 {
  background-image: url("rectangle-118-12.6ac777f2.webp");
  background-position: 50%;
  background-size: cover;
  width: 272px;
  height: 218px;
  position: relative;
}

.industries .text-wrapper-42 {
  color: var(--neutral-0);
  letter-spacing: -.24px;
  width: 136px;
  height: 34px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
  position: absolute;
  top: 166px;
  left: 57px;
}

.industries .group-17 {
  width: 274px;
  height: 218px;
  position: absolute;
  top: 336px;
  left: 1089px;
}

.industries .overlap-14 {
  background-image: url("rectangle-118-13.6aed958f.webp");
  background-size: 100% 100%;
  width: 272px;
  height: 218px;
  position: relative;
}

.industries .text-wrapper-43 {
  color: var(--neutral-0);
  letter-spacing: -.24px;
  width: 229px;
  height: 34px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
  position: absolute;
  top: 166px;
  left: 23px;
}

.industries .overlap-15 {
  position: absolute;
}

.industries .frame-22 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 338px;
  display: flex;
  position: absolute;
  top: 14px;
  left: 26px;
}

.industries .frame-23 {
  flex: none;
  align-self: stretch;
  width: 100%;
  position: relative;
}

.industries .rectangle-8 {
  background-color: #6fd6b9;
  width: 394px;
  height: 810px;
  position: absolute;
  top: 0;
  left: 0;
}

.industries .frame-24 {
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  row-gap: 100px;
  width: 363px;
  display: grid;
  position: absolute;
  top: 115px;
  left: 28px;
}

.industries .logos-mobile {
  width: 150px;
  height: 68px;
}

.industries .logo-desktop {
  justify-content: center;
  width: 100%;
  display: flex;
}

.industries .logos-mobile img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.industries .group-18 {
  width: 174.88px;
  height: 138.34px;
  position: relative;
}

.industries .overlap-group-7 {
  background-image: url("rectangle-118-17.83d2e79e.webp");
  background-size: 100% 100%;
  width: 173px;
  height: 138px;
  position: relative;
}

.industries .text-wrapper-44 {
  color: var(--neutral-0);
  letter-spacing: -.15px;
  width: 104px;
  height: 21px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 15.2px;
  font-weight: 400;
  line-height: 21.3px;
  position: absolute;
  top: 105px;
  left: 36px;
}

.industries .group-19 {
  width: 174.88px;
  height: 138.34px;
  margin-right: -1.25px;
  position: relative;
}

.industries .overlap-16 {
  background-image: url("rectangle-118-18.f5ad089d.webp");
  background-size: 100% 100%;
  width: 173px;
  height: 138px;
  position: relative;
}

.industries .text-wrapper-45 {
  color: var(--neutral-0);
  letter-spacing: -.15px;
  width: 21px;
  height: 21px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 15.2px;
  font-weight: 400;
  line-height: 21.3px;
  position: absolute;
  top: 105px;
  left: 72px;
}

.industries .overlap-17 {
  background-image: url("rectangle-118-19.cf3c04cc.webp");
  background-position: 50%;
  background-size: cover;
  width: 173px;
  height: 138px;
  position: relative;
}

.industries .text-wrapper-46 {
  color: var(--neutral-0);
  letter-spacing: -.15px;
  width: 144px;
  height: 21px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 15.2px;
  font-weight: 400;
  line-height: 21.3px;
  position: absolute;
  top: 105px;
  left: 14px;
}

.industries .overlap-18 {
  background-image: url("rectangle-118-20.eaf9b73c.webp");
  background-position: 50%;
  background-size: cover;
  width: 173px;
  height: 138px;
  position: relative;
}

.industries .text-wrapper-47 {
  color: var(--neutral-0);
  letter-spacing: -.15px;
  width: 86px;
  height: 21px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 15.2px;
  font-weight: 400;
  line-height: 21.3px;
  position: absolute;
  top: 105px;
  left: 36px;
}

.industries .overlap-19 {
  background-image: url("rectangle-118-21.bbd7667e.webp");
  background-position: 50%;
  background-size: cover;
  width: 173px;
  height: 138px;
  position: relative;
}

.industries .real-page-2 {
  color: var(--neutral-0);
  letter-spacing: -.15px;
  width: 64px;
  height: 21px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 15.2px;
  font-weight: 400;
  line-height: 21.3px;
  position: absolute;
  top: 105px;
  left: 57px;
}

.industries .overlap-20 {
  background-image: url("rectangle-118-22.c9e1fa1c.webp");
  background-position: 50%;
  background-size: cover;
  width: 173px;
  height: 138px;
  position: relative;
}

.industries .text-wrapper-48 {
  color: var(--neutral-0);
  letter-spacing: -.15px;
  width: 29px;
  height: 21px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 15.2px;
  font-weight: 400;
  line-height: 21.3px;
  position: absolute;
  top: 105px;
  left: 71px;
}

.industries .overlap-21 {
  background-image: url("rectangle-118-23.3202f77d.webp");
  background-position: 50%;
  background-size: cover;
  width: 173px;
  height: 138px;
  position: relative;
}

.industries .text-wrapper-49 {
  color: var(--neutral-0);
  letter-spacing: -.15px;
  width: 112px;
  height: 21px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 15.2px;
  font-weight: 400;
  line-height: 21.3px;
  position: absolute;
  top: 105px;
  left: 28px;
}

.industries .overlap-22 {
  background-image: url("rectangle-118-24.1a2f5619.webp");
  background-size: 100% 100%;
  width: 173px;
  height: 138px;
  position: relative;
}

.industries .text-wrapper-50 {
  color: var(--neutral-0);
  letter-spacing: -.15px;
  width: 145px;
  height: 21px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 15.2px;
  font-weight: 400;
  line-height: 21.3px;
  position: absolute;
  top: 105px;
  left: 14px;
}

.industries .text-wrapper-51 {
  color: #1f1f1f;
  letter-spacing: -.53px;
  white-space: nowrap;
  height: 49px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 700;
  line-height: 49px;
  position: absolute;
  top: 37px;
  left: 19px;
}

.industries .text-wrapper-52 {
  color: #000;
  letter-spacing: -.53px;
  white-space: nowrap;
  width: 411px;
  height: 45px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 600;
  line-height: 49px;
  position: absolute;
  top: 0;
  left: 0;
}

.industries .group-20 {
  width: 1235px;
  height: 365px;
  position: absolute;
  top: 88px;
  left: 0;
}

.industries .unknown-3 {
  object-fit: cover;
  width: 230px;
  height: 48px;
  position: absolute;
  top: 42px;
  left: 2px;
}

.industries .mercedes-benz-logo {
  object-fit: cover;
  width: 248px;
  height: 63px;
  position: absolute;
  top: 34px;
  left: 328px;
}

.industries .centurylink-internet {
  object-fit: cover;
  width: 273px;
  height: 129px;
  position: absolute;
  top: 1px;
  left: 652px;
}

.industries .img-2 {
  object-fit: cover;
  width: 235px;
  height: 132px;
  position: absolute;
  top: 0;
  left: 1000px;
}

.industries .northern-trust-logo {
  object-fit: cover;
  width: 251px;
  height: 74px;
  position: absolute;
  top: 255px;
  left: 0;
}

.industries .ge-healthcare-logo {
  object-fit: cover;
  width: 263px;
  height: 146px;
  position: absolute;
  top: 219px;
  left: 325px;
}

.industries .shell-logo-icon {
  object-fit: cover;
  width: 187px;
  height: 93px;
  position: absolute;
  top: 245px;
  left: 656px;
}

.industries .element-pearson-logo {
  object-fit: cover;
  width: 203px;
  height: 61px;
  position: absolute;
  top: 261px;
  left: 1000px;
}

.industries .footer-2 {
  background-color: var(--white);
  width: 1441px;
  height: 292px;
  position: absolute;
  top: 4020px;
  left: 1px;
}

.industries .overlap-23 {
  width: 1441px;
  height: 292px;
  position: relative;
}

.industries .frame-25 {
  background-color: var(--neutral-90);
  width: 1441px;
  height: 292px;
  position: absolute;
  top: 0;
  left: 0;
}

.industries .DIV-3 {
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 53px;
  left: 738px;
}

.industries .text-wrapper-53 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1233px;
}

.industries .text-wrapper-54 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1282px;
}

.industries .text-wrapper-55 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1331px;
}

.industries .text-wrapper-56 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1187px;
}

.industries .text-wrapper-57 {
  color: var(--white);
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  white-space: nowrap;
  height: 28px;
  position: absolute;
  top: 100px;
  left: 738px;
}

.industries .text-wrapper-58 {
  color: var(--white);
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  white-space: nowrap;
  height: 28px;
  position: absolute;
  top: 100px;
  left: 869px;
}

.industries .frame-26 {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: absolute;
  top: 115px;
  left: 81px;
}

.industries .group-21 {
  width: 37px;
  height: 52px;
  position: absolute;
  top: 152px;
  left: 1140px;
}

.industries .linkedin-2 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1187px;
}

.industries .facebook-2 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1234px;
}

.industries .facebook-3 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1281px;
}

.industries .facebook-4 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1328px;
}

.industries .group-22 {
  width: 400px;
  height: 17px;
  position: absolute;
  top: 242px;
  left: 501px;
}

.industries .frame-27 {
  justify-content: center;
  align-items: center;
  gap: 5px;
  display: inline-flex;
  position: relative;
}

.industries .text-wrapper-59 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.industries .ellipse-2 {
  background-color: var(--grey-01);
  border-radius: 1.5px;
  width: 3px;
  height: 3px;
  position: relative;
}

.industries .logo-3 {
  width: 116px;
  height: 56px;
  position: absolute;
  top: 39px;
  left: 76px;
}

.industries .group-23 {
  background-image: url("arrow-forward-ios-1.a1198576.png");
  background-size: 100% 100%;
  width: 344px;
  height: 173px;
  position: absolute;
  top: -8388px;
  left: -7993px;
}

.industries .frame-28 {
  background-color: var(--neutral-0);
  border-radius: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 104px;
  height: 57px;
  padding: 24px;
  display: flex;
  position: relative;
  top: 9px;
  left: 9px;
  box-shadow: 0 4px 32px #0000001f;
}

.industries .frame-29 {
  width: 340px;
  height: 145px;
  margin-bottom: -135.51px;
  margin-right: -283.77px;
  position: relative;
}

.industries .direction {
  width: 35px;
  height: 35px;
  position: absolute;
  top: -14467px;
  left: -8821px;
}

.industries .text-wrapper-60 {
  color: var(--neutral-70);
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  width: 328px;
  position: absolute;
  top: 36px;
  left: 0;
}

.industries .text-wrapper-61 {
  color: var(--primary-02);
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  white-space: nowrap;
  position: absolute;
  top: 150px;
  left: 0;
}

.industries .frame-30 {
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;
  position: absolute;
  top: 2px;
  left: 0;
}

.industries .text-wrapper-62 {
  color: var(--secondary-04);
  letter-spacing: -.2px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  position: relative;
}

.industries .header-7 {
  width: 390px !important;
  height: 60px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.industries .frame-31 {
  flex-direction: column;
  align-items: flex-start;
  gap: 7.76px;
  display: inline-flex;
  position: absolute;
  top: 1119px;
  left: 19px;
}

.industries .frame-32 {
  flex: none;
  align-items: center;
  gap: 57.31px;
  display: inline-flex;
  position: relative;
}

.industries .unknown-4 {
  object-fit: cover;
  width: 137.3px;
  height: 28.65px;
  position: relative;
}

.industries .mercedes-benz-logo-2 {
  object-fit: cover;
  width: 148.04px;
  height: 37.61px;
  position: relative;
}

.industries .frame-33 {
  flex: none;
  align-items: center;
  gap: 44.77px;
  display: inline-flex;
  position: relative;
}

.industries .centurylink-internet-2 {
  object-fit: cover;
  width: 162.96px;
  height: 77px;
  position: relative;
}

.industries .img-3 {
  object-fit: cover;
  width: 140.28px;
  height: 78.8px;
  position: relative;
}

.industries .frame-34 {
  flex: none;
  align-items: center;
  gap: 44.17px;
  display: inline-flex;
  position: relative;
}

.industries .northern-trust-logo-2 {
  object-fit: cover;
  width: 149.83px;
  height: 44.17px;
  position: relative;
}

.industries .ge-healthcare-logo-2 {
  object-fit: cover;
  width: 157px;
  height: 87.15px;
  position: relative;
}

.industries .frame-35 {
  flex: none;
  align-items: center;
  gap: 93.72px;
  display: inline-flex;
  position: relative;
}

.industries .shell-logo-icon-2 {
  object-fit: cover;
  width: 111.63px;
  height: 55.52px;
  position: relative;
}

.industries .element-px-pearson-logo {
  object-fit: cover;
  width: 121.18px;
  height: 36.41px;
  position: relative;
}

.industries .text-wrapper-63 {
  color: #000;
  letter-spacing: -.53px;
  text-align: center;
  white-space: nowrap;
  width: 411px;
  height: 45px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 600;
  line-height: 49px;
  position: absolute;
  top: 990px;
  left: -13px;
}

.industries .group-24 {
  width: 394px;
  height: 254px;
  position: absolute;
  top: 2293px;
  left: -2px;
}

.industries .overlap-24 {
  background-color: var(--secondary-03);
  width: 392px;
  height: 254px;
  position: relative;
}

.industries .text-wrapper-64 {
  color: #fff;
  letter-spacing: -.33px;
  white-space: nowrap;
  height: 31px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 22px;
  font-weight: 700;
  line-height: 30.8px;
  position: absolute;
  top: 15px;
  left: 156px;
}

.industries .img-wrapper {
  background-color: var(--neutral-0);
  border-radius: 2.16px;
  width: 104px;
  height: 49px;
  position: absolute;
  top: 64px;
  left: 22px;
  box-shadow: 0 .54px 4.59px #0000001a;
}

.industries .amazon-2 {
  object-fit: cover;
  width: 48px;
  height: 15px;
  position: absolute;
  top: 18px;
  left: 28px;
}

.industries .overlap-25 {
  background-color: var(--neutral-0);
  border-radius: 2.16px;
  width: 104px;
  height: 49px;
  position: absolute;
  top: 120px;
  left: 22px;
  box-shadow: 0 .54px 4.59px #0000001a;
}

.industries .sony-logo-2 {
  object-fit: cover;
  width: 69px;
  height: 25px;
  position: absolute;
  top: 12px;
  left: 18px;
}

.industries .overlap-26 {
  background-color: var(--neutral-0);
  border-radius: 2.16px;
  width: 104px;
  height: 49px;
  position: absolute;
  top: 175px;
  left: 22px;
  box-shadow: 0 .54px 4.59px #0000001a;
}

.industries .env-yodlee-color-2 {
  object-fit: cover;
  width: 67px;
  height: 22px;
  position: absolute;
  top: 13px;
  left: 21px;
}

.industries .overlap-27 {
  background-color: var(--neutral-0);
  border-radius: 2.16px;
  width: 104px;
  height: 49px;
  position: absolute;
  top: 120px;
  left: 142px;
  box-shadow: 0 .54px 4.59px #0000001a;
}

.industries .na-logo-black-rgb-2 {
  object-fit: cover;
  width: 69px;
  height: 28px;
  position: absolute;
  top: 10px;
  left: 17px;
}

.industries .overlap-group-8 {
  background-color: var(--neutral-0);
  border-radius: 2.16px;
  width: 104px;
  height: 49px;
  position: absolute;
  top: 175px;
  left: 142px;
  box-shadow: 0 .54px 4.59px #0000001a;
}

.industries .smarten-spaces-logo-2 {
  object-fit: cover;
  width: 51px;
  height: 16px;
  position: absolute;
  top: 16px;
  left: 27px;
}

.industries .overlap-28 {
  width: 104px;
  height: 56px;
  position: absolute;
  top: 60px;
  left: 263px;
}

.industries .rectangle-9 {
  background-color: var(--neutral-0);
  border-radius: 2.16px;
  width: 104px;
  height: 49px;
  position: absolute;
  top: 4px;
  left: 0;
  box-shadow: 0 .54px 4.59px #0000001a;
}

.industries .inmobi-logo-2 {
  object-fit: cover;
  width: 85px;
  height: 56px;
  position: absolute;
  top: 0;
  left: 8px;
}

.industries .overlap-29 {
  background-color: var(--neutral-0);
  border-radius: 2.16px;
  width: 104px;
  height: 49px;
  position: absolute;
  top: 120px;
  left: 263px;
  box-shadow: 0 .54px 4.59px #0000001a;
}

.industries .xylem-logo-2 {
  object-fit: cover;
  width: 49px;
  height: 21px;
  position: absolute;
  top: 14px;
  left: 26px;
}

.industries .frame-36 {
  flex-direction: column;
  align-items: flex-start;
  gap: 2.7px;
  display: inline-flex;
  position: absolute;
  top: 64px;
  left: 142px;
}

.industries .rectangle-10 {
  background-color: var(--neutral-0);
  border-radius: 2.16px;
  width: 104.21px;
  height: 48.87px;
  position: relative;
  box-shadow: 0 .54px 4.59px #0000001a;
}

.industries .vmware-2 {
  object-fit: cover;
  width: 69px;
  height: 11px;
  position: absolute;
  top: 19px;
  left: 18px;
}

.industries .overlap-30 {
  width: 678px;
  height: 132px;
  position: absolute;
  top: 2575px;
  left: 19px;
}

.industries .IT-services-2 {
  color: #000;
  letter-spacing: -.33px;
  text-align: center;
  width: 346px;
  height: 45px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 22px;
  font-weight: 700;
  line-height: 30.8px;
  position: absolute;
  top: 0;
  left: 0;
}

.industries .group-25 {
  opacity: .89;
  width: 676px;
  height: 96px;
  position: absolute;
  top: 36px;
  left: 2px;
}

.industries .overlap-31 {
  width: 171px;
  height: 71px;
  position: absolute;
  top: 18px;
  left: 0;
}

.industries .utypaslbyxwfuwhfdzxd-2 {
  object-fit: cover;
  width: 71px;
  height: 71px;
  position: absolute;
  top: 0;
  left: 0;
}

.industries .wipro-logo-new-og-2 {
  object-fit: cover;
  width: 103px;
  height: 54px;
  position: absolute;
  top: 9px;
  left: 68px;
}

.industries .deloitte-logo-2 {
  object-fit: cover;
  width: 81px;
  height: 45px;
  position: absolute;
  top: 31px;
  left: 179px;
}

.industries .group-26 {
  width: 63px;
  height: 59px;
  position: absolute;
  top: 18px;
  left: 268px;
}

.industries .overlap-group-9 {
  height: 59px;
  position: relative;
}

.industries .ey-squarelogo-3 {
  object-fit: cover;
  width: 58px;
  height: 58px;
  position: absolute;
  top: 1px;
  left: 2px;
}

.industries .ey-squarelogo-4 {
  width: 63px;
  height: 27px;
  position: absolute;
  top: 0;
  left: 0;
}

.industries .ae-dbe-f-2 {
  object-fit: cover;
  width: 90px;
  height: 90px;
  position: absolute;
  top: 6px;
  left: 335px;
}

.industries .capgemini-x-logo {
  object-fit: cover;
  width: 110px;
  height: 26px;
  position: absolute;
  top: 43px;
  left: 437px;
}

.industries .element-accenture-2 {
  object-fit: cover;
  width: 125px;
  height: 94px;
  position: absolute;
  top: -11411px;
  left: -7992px;
}

.leadership {
  flex-direction: row;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.leadership .iphone {
  background-color: #fff;
  position: relative;
}

.leadership .footer {
  background-color: var(--white);
  width: 390px;
  height: 860px;
  position: absolute;
  top: 3302px;
  left: 0;
  overflow: hidden;
}

.leadership .overlap-group {
  width: 390px;
  height: 907px;
  position: relative;
}

.leadership .frame {
  background-color: var(--neutral-90);
  width: 390px;
  height: 907px;
  position: absolute;
  top: 0;
  left: 0;
}

.leadership .div {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: absolute;
  top: 115px;
  left: 26px;
}

.leadership .text-wrapper-2 {
  color: #fff;
  font-family: var(--paragraph-ALCPS-font-family);
  font-size: var(--paragraph-ALCPS-font-size);
  font-style: var(--paragraph-ALCPS-font-style);
  font-weight: var(--paragraph-ALCPS-font-weight);
  letter-spacing: var(--paragraph-ALCPS-letter-spacing);
  line-height: var(--paragraph-ALCPS-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.leadership .p {
  color: var(--neutral-10);
  font-family: var(--paragraph-REG-font-family);
  font-size: var(--paragraph-REG-font-size);
  font-style: var(--paragraph-REG-font-style);
  font-weight: var(--paragraph-REG-font-weight);
  letter-spacing: var(--paragraph-REG-letter-spacing);
  line-height: var(--paragraph-REG-line-height);
  width: 324px;
  position: relative;
}

.leadership .DIV {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 113px;
  display: flex;
  position: absolute;
  top: 272px;
  left: 195px;
}

.leadership .text-wrapper-3 {
  color: var(--white);
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.leadership .text-wrapper-4 {
  color: var(--white);
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.leadership .DIV-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 113px;
  display: flex;
  position: absolute;
  top: 272px;
  left: 26px;
}

.leadership .frame-2 {
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 583px;
  left: 69px;
}

.leadership .group {
  width: 37px;
  height: 52px;
  position: relative;
}

.leadership .text-wrapper-5 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 38px;
  left: 1px;
}

.leadership .face-book {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 0;
  left: 0;
}

.leadership .frame-3 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.leadership .linkedin {
  flex: none;
  position: relative;
}

.leadership .text-wrapper-6 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  width: fit-content;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: relative;
}

.leadership .facebook {
  width: 35px;
  height: 35px;
  position: relative;
}

.leadership .frame-4 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.leadership .logo {
  width: 116px;
  height: 56px;
  position: absolute;
  top: 39px;
  left: 24px;
}

.leadership .frame-wrapper {
  width: 295px;
  height: 64px;
  position: absolute;
  top: 732px;
  left: 48px;
}

.leadership .frame-5 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 295px;
  display: flex;
  position: relative;
}

.leadership .text-wrapper-7 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.leadership .ellipse {
  background-color: var(--grey-01);
  border-radius: 1px / 1.5px;
  width: 2px;
  height: 3px;
  position: relative;
}

.leadership .text-wrapper-8 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  text-align: center;
  align-self: stretch;
  position: relative;
}

.leadership .div-wrapper {
  background-image: url("arrow-forward-ios-1.a1198576.png");
  background-size: 100% 100%;
  width: 344px;
  height: 173px;
  position: absolute;
  top: -8388px;
  left: -10443px;
}

.leadership .frame-6 {
  background-color: var(--neutral-0);
  border-radius: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 104px;
  height: 57px;
  padding: 24px;
  display: flex;
  position: relative;
  top: 9px;
  left: 9px;
  box-shadow: 0 4px 32px #0000001f;
}

.leadership .frame-7 {
  width: 340px;
  height: 145px;
  margin-bottom: -135.51px;
  margin-right: -283.77px;
  position: relative;
}

.leadership .direction {
  width: 35px;
  height: 35px;
  position: absolute;
  top: -14467px;
  left: -11271px;
}

.leadership .text-wrapper-9 {
  color: var(--neutral-70);
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  width: 328px;
  position: absolute;
  top: 36px;
  left: 0;
}

.leadership .text-wrapper-10 {
  color: #4d9f98;
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  white-space: nowrap;
  position: absolute;
  top: 150px;
  left: 0;
}

.leadership .frame-8 {
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;
  position: absolute;
  top: 2px;
  left: 0;
}

.leadership .text-wrapper-11 {
  color: var(--secondary-04);
  letter-spacing: -.2px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  position: relative;
}

.leadership .header {
  width: 390px !important;
  height: 60px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.leadership .overlap {
  position: absolute;
  top: 89px;
  left: -4px;
}

.leadership .modern-geometrical {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.leadership .rectangle, .leadership .leadership {
  mix-blend-mode: hard-light;
  position: absolute;
}

.leadership .frame-9 {
  background-color: var(--neutral-80);
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
}

.leadership .frame-10 {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 66px;
  display: inline-flex;
  position: relative;
}

.leadership .element {
  object-fit: cover;
  width: 299px;
  height: 299px;
  position: relative;
}

.leadership .frame-11 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  width: 696px;
  display: flex;
  position: relative;
}

.leadership .frame-12 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  width: 685px;
  display: flex;
  position: relative;
}

.leadership .text-wrapper-12 {
  color: var(--neutral-30);
  letter-spacing: -.53px;
  width: 191px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 700;
  line-height: 49px;
  position: relative;
}

.leadership .text-wrapper-13 {
  color: var(--secondary-02);
  letter-spacing: -.2px;
  width: 685px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.leadership .text-wrapper-14 {
  color: #f6f7f8;
  letter-spacing: -.17px;
  width: 685px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 400;
  line-height: 23.8px;
  position: relative;
}

.leadership .linkedin-circled {
  width: 45px;
  height: 45px;
  position: absolute;
  top: 41px;
  left: 1071px;
}

.leadership .frame-13 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 49.48px;
  width: 100%;
  display: flex;
  position: relative;
}

.leadership .img {
  object-fit: cover;
  align-self: stretch;
  width: 100%;
  height: 272px;
  position: relative;
}

.leadership .frame-14 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
  display: flex;
  position: relative;
}

.leadership .frame-15 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;
  width: 513.75px;
  margin-right: -203.24px;
  display: flex;
  position: relative;
}

.leadership .text-wrapper-15 {
  color: var(--neutral-30);
  letter-spacing: -.36px;
  width: 143.25px;
  margin-top: -.75px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 33.6px;
  position: relative;
}

.leadership .linkedin-circled-2 {
  width: 34px;
  height: 34px;
  position: absolute;
  top: 0;
  left: 277px;
}

.leadership .text-wrapper-16 {
  color: var(--secondary-02);
  letter-spacing: -.16px;
  width: 513.75px;
  margin-right: -203.24px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  position: relative;
}

.leadership .text-wrapper-17 {
  color: #f6f7f8;
  letter-spacing: -.14px;
  align-self: stretch;
  font-family: Source Sans Pro, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  position: relative;
}

.leadership .frame-16 {
  background-color: var(--neutral-80);
  border-radius: 19.49px;
  flex-direction: column;
  align-items: flex-start;
  gap: 7.5px;
  width: 351px;
  padding: 30.74px 20.24px;
  display: flex;
  position: absolute;
  top: 1106px;
  left: 22px;
}

.leadership .element-2 {
  object-fit: cover;
  align-self: stretch;
  width: 100%;
  height: 273px;
  position: relative;
}

.leadership .linkedin-circled-3 {
  width: 34px;
  height: 34px;
  position: absolute;
  top: 0;
  left: 257px;
}

.leadership .frame-17 {
  flex-direction: column;
  align-items: flex-start;
  gap: 21.53px;
  display: inline-flex;
  position: absolute;
  top: 1127px;
  left: 18px;
}

.leadership .group-2 {
  width: 379.61px;
  height: 419.61px;
  margin-top: -9.84px;
  margin-left: -12.3px;
  margin-right: -12.3px;
  position: relative;
}

.leadership .group-3 {
  width: 379.61px;
  height: 457.19px;
  margin-bottom: -14.77px;
  margin-left: -12.3px;
  margin-right: -12.3px;
  position: relative;
}

.leadership .frame-18 {
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
  position: absolute;
  top: 2118px;
  left: -2px;
}

.leadership .rectangle-2 {
  background-color: #4d9f98;
  width: 392px;
  height: 484px;
  position: relative;
}

.leadership .frame-19 {
  flex-wrap: wrap;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  width: 287px;
  display: grid;
  position: absolute;
  top: 96px;
  left: 52px;
}

.leadership .frame-20 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.leadership .text-wrapper-18 {
  color: #fff;
  letter-spacing: -.75px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 50px;
  font-weight: 600;
  line-height: 60px;
  position: relative;
}

.leadership .text-wrapper-19 {
  color: #fff;
  letter-spacing: -.2px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.leadership .frame-21 {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 16px;
  display: flex;
  position: relative;
}

.leadership .text-wrapper-20 {
  color: #fff;
  letter-spacing: -.2px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-left: -3.75px;
  margin-right: -3.75px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.leadership .frame-22 {
  background-color: #2e5053;
  border-radius: 26px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 41px 66px;
  display: inline-flex;
  position: absolute;
  top: 851px;
  left: 124px;
}

.leadership .text-wrapper-21 {
  color: var(--neutral-30);
  letter-spacing: -.53px;
  width: 162px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 700;
  line-height: 49px;
  position: relative;
}

.leadership .linkedin-circled-4 {
  width: 45px;
  height: 45px;
  position: absolute;
  top: 45px;
  left: 1071px;
}

.leadership .overlap-2 {
  background-color: #f5f5f5;
  width: 1444px;
  height: 363px;
  position: absolute;
  top: 1868px;
  left: 0;
}

.leadership .frame-23 {
  flex-direction: column;
  align-items: center;
  gap: 25px;
  display: inline-flex;
  position: relative;
  top: 81px;
  left: 170px;
}

.leadership .frame-24 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 51px;
  display: inline-flex;
  position: relative;
}

.leadership .frame-25 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 26px;
  display: inline-flex;
  position: relative;
}

.leadership .frame-26 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.leadership .frame-27 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.leadership .text-wrapper-22 {
  color: var(--neutral-90);
  letter-spacing: -.2px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  position: relative;
}

.leadership .frame-28 {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 29px;
  display: inline-flex;
  position: relative;
}

.leadership .mercedes-benz-logo {
  object-fit: cover;
  width: 248px;
  height: 63px;
  position: relative;
}

.leadership .GE-healthcare-logo {
  object-fit: cover;
  width: 164px;
  height: 92px;
  position: relative;
}

.leadership .sony-logo {
  object-fit: cover;
  width: 123px;
  height: 22px;
  position: relative;
}

.leadership .shell-logo {
  object-fit: cover;
  width: 115px;
  height: 65px;
  position: relative;
}

.leadership .amazon {
  object-fit: cover;
  width: 178px;
  height: 55px;
  position: relative;
}

.leadership .wipro-logo-new-og {
  object-fit: cover;
  width: 171px;
  height: 89px;
  position: relative;
}

.leadership .overlap-3 {
  width: 1233px;
  height: 603px;
  position: absolute;
  top: 875px;
  left: 106px;
}

.leadership .group-4 {
  width: 617px;
  height: 603px;
  position: absolute;
  top: 0;
  left: 616px;
}

.leadership .group-5 {
  width: 617px;
  height: 603px;
  position: absolute;
  top: 0;
  left: 0;
}

.leadership .linkedin-circled-5 {
  width: 45px;
  height: 45px;
  position: absolute;
  top: 159px;
  left: 521px;
}

.leadership .linkedin-circled-6 {
  width: 45px;
  height: 45px;
  position: absolute;
  top: 159px;
  left: 1145px;
}

.leadership .overlap-4 {
  width: 1441px;
  height: 350px;
  position: absolute;
  top: 1518px;
  left: 3px;
}

.leadership .group-6 {
  background-color: #4d9f98;
  width: 1441px;
  height: 350px;
  position: absolute;
  top: 0;
  left: 0;
}

.leadership .frame-29 {
  justify-content: space-between;
  align-items: center;
  width: 1113px;
  display: flex;
  position: relative;
  top: 176px;
  left: 200px;
}

.leadership .frame-30 {
  flex-direction: column;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 51px;
  left: 517px;
}

.leadership .text-wrapper-23 {
  color: #fff;
  letter-spacing: -.75px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 50px;
  font-weight: 400;
  line-height: 60px;
  position: relative;
}

.leadership .header-2 {
  background-color: var(--neutral-10);
  justify-content: space-between;
  align-items: center;
  width: 1440px;
  padding: 16px 80px;
  display: flex;
  position: absolute;
  top: 0;
  left: -4px;
}

.leadership .logo-2 {
  width: 116px;
  height: 56.67px;
  position: relative;
}

.leadership .holder {
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.leadership .frame-31 {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 90.33px;
  padding: 10px 8px;
  display: flex;
  position: relative;
}

.leadership .text-wrapper-24 {
  color: var(--neutral-90);
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.leadership .text-wrapper-25 {
  color: var(--neutral-90);
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-left: -2.83px;
  margin-right: -2.83px;
  position: relative;
}

.leadership .frame-32 {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 80px;
  padding: 10px 8px;
  display: flex;
  position: relative;
}

.leadership .CTA-instance {
  border-color: var(--primary-02) !important;
  flex: none !important;
}

.leadership .instance-node {
  color: var(--primary-02) !important;
}

.leadership .overlap-group-wrapper {
  background-color: var(--white);
  width: 1441px;
  height: 292px;
  position: absolute;
  top: 2731px;
  left: 3px;
}

.leadership .overlap-group-2 {
  width: 1441px;
  height: 292px;
  position: relative;
}

.leadership .frame-33 {
  background-color: var(--neutral-90);
  width: 1441px;
  height: 292px;
  position: absolute;
  top: 0;
  left: 0;
}

.leadership .DIV-3 {
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 53px;
  left: 738px;
}

.leadership .text-wrapper-26 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1233px;
}

.leadership .text-wrapper-27 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1282px;
}

.leadership .text-wrapper-28 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1331px;
}

.leadership .text-wrapper-29 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1187px;
}

.leadership .text-wrapper-30 {
  color: var(--white);
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  white-space: nowrap;
  height: 28px;
  position: absolute;
  top: 100px;
  left: 738px;
}

.leadership .text-wrapper-31 {
  color: var(--white);
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  white-space: nowrap;
  height: 28px;
  position: absolute;
  top: 100px;
  left: 869px;
}

.leadership .frame-34 {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: absolute;
  top: 115px;
  left: 81px;
}

.leadership .group-7 {
  width: 37px;
  height: 52px;
  position: absolute;
  top: 152px;
  left: 1140px;
}

.leadership .linkedin-2 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1187px;
}

.leadership .facebook-2 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1234px;
}

.leadership .facebook-3 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1281px;
}

.leadership .facebook-4 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1328px;
}

.leadership .group-8 {
  width: 400px;
  height: 17px;
  position: absolute;
  top: 242px;
  left: 501px;
}

.leadership .frame-35 {
  justify-content: center;
  align-items: center;
  gap: 5px;
  display: inline-flex;
  position: relative;
}

.leadership .text-wrapper-32 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.leadership .ellipse-2 {
  background-color: var(--grey-01);
  border-radius: 1.5px;
  width: 3px;
  height: 3px;
  position: relative;
}

.leadership .logo-3 {
  width: 116px;
  height: 56px;
  position: absolute;
  top: 39px;
  left: 76px;
}

.CTA {
  border: none;
  border-radius: 56px;
  outline: none;
  align-items: center;
  padding: 12px 16px;
  display: inline-flex;
  position: relative;
}

.CTA.default {
  border: 1px solid #fff;
  gap: 10px;
}

.careers {
  background-color: #fff;
  flex-direction: row;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.careers .iphone {
  background-color: #fff;
  position: relative;
}

.careers .header {
  width: 390px !important;
  height: 60px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.careers .overlap, .careers .rectangle {
  position: absolute;
}

.careers .div {
  background-color: #18393c;
  border-radius: 38px 38px 0 0;
  width: 357px;
  height: 629px;
  position: absolute;
  top: 396px;
  left: 17px;
}

.careers .CTA-instance {
  border: unset !important;
  border-color: unset !important;
  background-color: #4d9f98 !important;
  justify-content: center !important;
  align-items: flex-end !important;
  width: 123px !important;
  display: flex !important;
  position: absolute !important;
  top: 263px !important;
  left: 104px !important;
}

.careers .instance-node {
  color: var(--neutral-0) !important;
  font-family: var(--subtitle-BLD-font-family) !important;
  font-size: var(--subtitle-BLD-font-size) !important;
  font-style: var(--subtitle-BLD-font-style) !important;
  font-weight: var(--subtitle-BLD-font-weight) !important;
  letter-spacing: var(--subtitle-BLD-letter-spacing) !important;
  line-height: var(--subtitle-BLD-line-height) !important;
}

.careers .rectangle-2 {
  background-color: var(--neutral-10);
  width: 1445px;
  height: 512px;
  position: absolute;
  top: 0;
  left: 270px;
}

.careers .frame {
  align-items: center;
  gap: 221px;
  width: 769px;
  display: flex;
  position: absolute;
  top: 165px;
  left: 335px;
}

.careers .frame-2 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.careers .frame-wrapper {
  flex: none;
  align-items: flex-start;
  gap: 16px;
  width: 726px;
  display: flex;
  position: relative;
}

.careers .div-wrapper {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 4px;
  display: flex;
  position: relative;
}

.careers .p {
  color: var(--secondary-04);
  letter-spacing: -1.05px;
  width: 726px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 42px;
  font-weight: 600;
  line-height: 50.4px;
  position: relative;
}

.careers .joulestowatts-is {
  color: var(--neutral-80);
  letter-spacing: -.2px;
  width: 726px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.careers .frame-3 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 900px;
  left: 1252px;
}

.careers .text-wrapper-2 {
  color: var(--neutral-10);
  letter-spacing: -.24px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  position: relative;
}

.careers .text-wrapper-3 {
  color: var(--neutral-50);
  letter-spacing: -.2px;
  width: 417px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.careers .frame-4 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 900px;
  left: 837px;
}

.careers .frame-5 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 708px;
  left: 1252px;
}

.careers .frame-6 {
  flex-direction: column;
  align-items: flex-start;
  display: inline-flex;
  position: absolute;
}

.careers .element-floor-sinman {
  color: var(--neutral-50);
  letter-spacing: -.2px;
  width: 378px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.careers .input {
  background-color: var(--neutral-10);
  color: var(--neutral-70);
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  white-space: nowrap;
  border: 0;
  border-radius: 8px;
  flex: none;
  width: 318px;
  margin-top: -1px;
  padding: 16px;
  position: relative;
}

.careers .frame-7 {
  background-color: var(--neutral-10);
  border-radius: 8px;
  width: 318px;
  height: 57px;
  position: relative;
}

.careers .drop-your-cvs-here {
  color: #0000;
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  white-space: nowrap;
  background-color: #0000;
  border: 0;
  width: 241px;
  height: 24px;
  padding: 0;
  position: absolute;
  top: 15px;
  left: 16px;
}

.careers .clip {
  object-fit: cover;
  width: 25px;
  height: 25px;
  position: absolute;
  top: -12260px;
  left: -5500px;
}

.careers .frame-8 {
  align-items: flex-start;
  gap: 16px;
  width: 437px;
  display: flex;
  position: absolute;
  top: 608px;
  left: 837px;
}

.careers .text-wrapper-4 {
  color: var(--neutral-0);
  letter-spacing: -.29px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 29px;
  font-weight: 600;
  line-height: 40.6px;
  position: relative;
}

.careers .certification-badge {
  width: 346px;
  height: 346px;
  position: absolute;
  top: 78px;
  left: 1272px;
}

.careers .a {
  background-image: url("rectangle.4bb87aeb.png");
  background-size: 100% 100%;
  width: 203px;
  height: 345px;
  position: relative;
  left: 71px;
}

.careers .seekpng {
  object-fit: cover;
  width: 781px;
  height: 387px;
  position: absolute;
  top: 681px;
  left: 0;
}

.careers .text-wrapper-5 {
  color: #fff;
  letter-spacing: -.16px;
  text-align: center;
  width: 293px;
  height: 44px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 22.4px;
  position: absolute;
  top: 311px;
  left: 48px;
}

.careers .text-wrapper-6 {
  color: #00dfc2;
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  text-align: center;
  width: 194px;
  height: 48px;
  position: absolute;
  top: 76px;
  left: 98px;
}

.careers .bring-your-ambition-wrapper {
  width: 524px;
  height: 120px;
  position: absolute;
  top: 125px;
  left: 40px;
}

.careers .bring-your-ambition {
  color: #0000;
  letter-spacing: -.6px;
  text-align: center;
  width: 304px;
  height: 144px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  position: absolute;
  top: 33px;
  left: 0;
}

.careers .span {
  color: #fff;
}

.careers .text-wrapper-7 {
  color: #00dfc2;
}

.careers .text-wrapper-8 {
  color: var(--neutral-0);
  letter-spacing: -.8px;
  text-align: center;
  width: 229px;
  height: 38px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.4px;
  position: absolute;
  top: 446px;
  left: 81px;
}

.careers .footer {
  background-color: var(--white);
  width: 390px;
  height: 860px;
  position: absolute;
  top: 2499px;
  left: 0;
  overflow: hidden;
}

.careers .overlap-group {
  width: 390px;
  height: 907px;
  position: relative;
}

.careers .frame-9 {
  background-color: var(--neutral-90);
  width: 390px;
  height: 907px;
  position: absolute;
  top: 0;
  left: 0;
}

.careers .frame-10 {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: absolute;
  top: 115px;
  left: 26px;
}

.careers .text-wrapper-9 {
  color: #fff;
  font-family: var(--paragraph-ALCPS-font-family);
  font-size: var(--paragraph-ALCPS-font-size);
  font-style: var(--paragraph-ALCPS-font-style);
  font-weight: var(--paragraph-ALCPS-font-weight);
  letter-spacing: var(--paragraph-ALCPS-letter-spacing);
  line-height: var(--paragraph-ALCPS-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.careers .text-wrapper-10 {
  color: var(--neutral-10);
  font-family: var(--paragraph-REG-font-family);
  font-size: var(--paragraph-REG-font-size);
  font-style: var(--paragraph-REG-font-style);
  font-weight: var(--paragraph-REG-font-weight);
  letter-spacing: var(--paragraph-REG-letter-spacing);
  line-height: var(--paragraph-REG-line-height);
  width: 324px;
  position: relative;
}

.careers .DIV {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 113px;
  display: flex;
  position: absolute;
  top: 272px;
  left: 195px;
}

.careers .text-wrapper-11 {
  color: var(--white);
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.careers .text-wrapper-12 {
  color: var(--white);
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.careers .DIV-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 113px;
  display: flex;
  position: absolute;
  top: 272px;
  left: 26px;
}

.careers .frame-11 {
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 583px;
  left: 69px;
}

.careers .group {
  width: 37px;
  height: 52px;
  position: relative;
}

.careers .text-wrapper-13 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 38px;
  left: 1px;
}

.careers .face-book {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 0;
  left: 0;
}

.careers .frame-12 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.careers .linkedin {
  flex: none;
  position: relative;
}

.careers .text-wrapper-14 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  width: fit-content;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: relative;
}

.careers .facebook {
  width: 35px;
  height: 35px;
  position: relative;
}

.careers .frame-13 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.careers .logo {
  width: 116px;
  height: 56px;
  position: absolute;
  top: 39px;
  left: 24px;
}

.careers .group-2 {
  width: 295px;
  height: 64px;
  position: absolute;
  top: 732px;
  left: 48px;
}

.careers .frame-14 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 295px;
  display: flex;
  position: relative;
}

.careers .text-wrapper-15 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.careers .ellipse {
  background-color: var(--grey-01);
  border-radius: 1px / 1.5px;
  width: 2px;
  height: 3px;
  position: relative;
}

.careers .text-wrapper-16 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  text-align: center;
  align-self: stretch;
  position: relative;
}

.careers .overlap-2 {
  position: absolute;
}

.careers .rectangle-3 {
  background-color: #2e5053;
  width: 1445px;
  height: 354px;
  position: absolute;
  top: 0;
  left: 5px;
}

.careers .overlap-group-wrapper {
  background-color: #fff;
  width: 1445px;
  height: 993px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.careers .overlap-group-2 {
  background-color: #2e5053;
  height: 1025px;
  position: relative;
}

.careers .overlap-3 {
  width: 1440px;
  height: 885px;
  position: absolute;
  top: 140px;
  left: 5px;
}

.careers .img {
  width: 334px;
  height: 739px;
  position: absolute;
  top: 114px;
  left: 0;
}

.careers .rectangle-4 {
  width: 329px;
  height: 739px;
  position: absolute;
  top: 114px;
  left: 1111px;
}

.careers .rectangle-5 {
  width: 933px;
  height: 628px;
  position: absolute;
  top: 0;
  left: 276px;
}

.careers .rectangle-6 {
  background-color: #18393c;
  border-radius: 38px 38px 0 0;
  width: 855px;
  height: 687px;
  position: absolute;
  top: 198px;
  left: 290px;
}

.careers .CTA-2 {
  border: unset !important;
  border-color: unset !important;
  background-color: #4d9f98 !important;
  justify-content: center !important;
  align-items: flex-end !important;
  width: 182px !important;
  display: flex !important;
  position: absolute !important;
  top: 650px !important;
  left: 623px !important;
}

.careers .text-wrapper-17 {
  color: #fff;
  font-family: var(--subtitle-BLD-font-family);
  font-size: var(--subtitle-BLD-font-size);
  font-style: var(--subtitle-BLD-font-style);
  font-weight: var(--subtitle-BLD-font-weight);
  letter-spacing: var(--subtitle-BLD-letter-spacing);
  line-height: var(--subtitle-BLD-line-height);
  text-align: center;
  width: 603px;
  height: 24px;
  position: absolute;
  top: 131px;
  left: 406px;
}

.careers .text-wrapper-18 {
  color: #00dfc2;
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  text-align: center;
  white-space: nowrap;
  height: 24px;
  position: absolute;
  top: 89px;
  left: 591px;
}

.careers .frame-15 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 377px;
  left: 388px;
}

.careers .frame-16 {
  flex: none;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.careers .frame-17 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 607px;
  display: flex;
  position: relative;
}

.careers .text-wrapper-19 {
  color: var(--neutral-0);
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  text-align: center;
  width: 651px;
  margin-top: -1px;
  margin-left: -22px;
  margin-right: -22px;
  position: relative;
}

.careers .frame-18 {
  flex: none;
  align-items: flex-start;
  gap: 29px;
  display: inline-flex;
  position: relative;
}

.careers .frame-19 {
  background-color: var(--neutral-10);
  color: var(--neutral-70);
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  white-space: nowrap;
  border: 0;
  border-radius: 8px;
  flex: none;
  width: 316px;
  margin-top: -1px;
  padding: 16px;
  position: relative;
}

.careers .message {
  background-color: var(--neutral-10);
  color: var(--neutral-70);
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  white-space: nowrap;
  border: 0;
  border-radius: 8px;
  flex: none;
  width: 661px;
  margin-top: -1px;
  padding: 16px;
  position: relative;
}

.careers .frame-20 {
  width: 524px;
  height: 120px;
  position: absolute;
  top: 128px;
  left: 451px;
}

.careers .bring-your-ambition-2 {
  color: #0000;
  letter-spacing: -.75px;
  text-align: center;
  width: 524px;
  height: 120px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 50px;
  font-weight: 700;
  line-height: 60px;
  position: absolute;
  top: -1px;
  left: 0;
}

.careers .frame-21-wrapper {
  border-radius: 8px;
  width: 656px;
  height: 57px;
  position: absolute;
  top: 637px;
  left: 388px;
}

.careers .frame-21 {
  background-color: var(--neutral-10);
  color: #0000;
  border-radius: 8px;
  width: 656px;
  height: 57px;
  position: absolute;
  top: 0;
  left: 0;
}

@media (width <= 450px) {
  .careers .frame-21 {
    width: 100%;
  }
}

.careers .frame-21:before {
  content: "Drop your CV here(pdf,doc,docx)";
  color: var(--neutral-70, #4d4d4d);
  letter-spacing: -.17px;
  font-family: Source Sans Pro;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.careers .frame-21::-webkit-file-upload-button {
  display: none;
}

.careers .frame-21 label {
  color: #000;
  position: absolute;
  top: 0;
  left: 388px;
}

.careers .clip-2 {
  object-fit: cover;
  width: 25px;
  height: 25px;
  position: absolute;
  top: 16px;
  left: 624px;
}

.careers .a-2 {
  background-image: url("rectangle.4bb87aeb.png");
  background-size: 100% 100%;
  width: 87px;
  height: 149px;
  position: relative;
  left: 31px;
}

.careers .overlap-4 {
  width: 390px;
  height: 1002px;
  position: absolute;
  top: 1497px;
  left: 0;
}

.careers .frame-22 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 338px;
  display: flex;
  position: absolute;
  top: 0;
  left: 26px;
}

.careers .frame-23 {
  flex: none;
  align-self: stretch;
  width: 100%;
  position: relative;
}

.careers .rectangle-7 {
  background-color: #2e5053;
  width: 390px;
  height: 954px;
  position: absolute;
  top: 48px;
  left: 0;
}

.careers .seekpng-2 {
  object-fit: cover;
  width: 390px;
  height: 214px;
  position: absolute;
  top: 48px;
  left: 0;
}

.careers .frame-24 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 338px;
  display: flex;
  position: absolute;
  top: 295px;
  left: 26px;
}

.careers .frame-25 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.careers .text-wrapper-20 {
  color: var(--neutral-10);
  letter-spacing: -.24px;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  position: relative;
}

.careers .text-wrapper-21 {
  color: var(--neutral-50);
  letter-spacing: -.2px;
  align-self: stretch;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.careers .frame-26 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.careers .group-3 {
  background-image: url("arrow-forward-ios-1.a1198576.png");
  background-size: 100% 100%;
  width: 344px;
  height: 173px;
  position: absolute;
  top: -8388px;
  left: -5538px;
}

.careers .frame-27 {
  background-color: var(--neutral-0);
  border-radius: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 104px;
  height: 57px;
  padding: 24px;
  display: flex;
  position: relative;
  top: 9px;
  left: 9px;
  box-shadow: 0 4px 32px #0000001f;
}

.careers .frame-28 {
  width: 340px;
  height: 145px;
  margin-bottom: -135.51px;
  margin-right: -283.77px;
  position: relative;
}

.careers .direction {
  width: 35px;
  height: 35px;
  position: absolute;
  top: -14467px;
  left: -6366px;
}

.careers .text-wrapper-22 {
  color: var(--neutral-70);
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  width: 328px;
  position: absolute;
  top: 36px;
  left: 0;
}

.careers .text-wrapper-23 {
  color: var(--primary-02);
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  white-space: nowrap;
  position: absolute;
  top: 150px;
  left: 0;
}

.careers .frame-29 {
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;
  position: absolute;
  top: 2px;
  left: 0;
}

.careers .text-wrapper-24 {
  color: var(--secondary-04);
  letter-spacing: -.2px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  position: relative;
}

.careers .frame-30 {
  align-items: center;
  gap: 221px;
  display: inline-flex;
  position: absolute;
  top: 1313px;
  left: 51px;
}

.careers .frame-31 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  width: 288px;
  display: flex;
  position: relative;
}

.careers .text-wrapper-25 {
  color: var(--secondary-04);
  letter-spacing: -.7px;
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 28px;
  font-weight: 600;
  line-height: 33.6px;
  position: relative;
}

.careers .joulestowatts-is-2 {
  color: var(--neutral-80);
  letter-spacing: -.16px;
  text-align: center;
  align-self: stretch;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  position: relative;
}

.careers .header-2 {
  background-color: var(--neutral-10);
  justify-content: space-between;
  align-items: center;
  width: 1440px;
  padding: 16px 80px;
  display: flex;
  position: absolute;
  top: 0;
  left: 8px;
}

.careers .logo-2 {
  width: 116px;
  height: 56.67px;
  position: relative;
}

.careers .holder {
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.careers .frame-32 {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 90.33px;
  padding: 10px 8px;
  display: flex;
  position: relative;
}

.careers .text-wrapper-26 {
  color: var(--neutral-90);
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.careers .text-wrapper-27 {
  color: var(--neutral-90);
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-left: -2.83px;
  margin-right: -2.83px;
  position: relative;
}

.careers .frame-33 {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 80px;
  padding: 10px 8px;
  display: flex;
  position: relative;
}

.careers .CTA-3 {
  border-color: #4d9f98 !important;
  flex: none !important;
}

.careers .CTA-4 {
  color: #4d9f98 !important;
}

.careers .overlap-wrapper {
  background-color: var(--white);
  width: 1441px;
  height: 292px;
  position: absolute;
  top: 2248px;
  left: 0;
}

.careers .overlap-5 {
  width: 1441px;
  height: 292px;
  position: relative;
}

.careers .frame-34 {
  background-color: var(--neutral-90);
  width: 1441px;
  height: 292px;
  position: absolute;
  top: 0;
  left: 0;
}

.careers .DIV-3 {
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 53px;
  left: 738px;
}

.careers .text-wrapper-28 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1233px;
}

.careers .text-wrapper-29 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1282px;
}

.careers .text-wrapper-30 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1331px;
}

.careers .text-wrapper-31 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1187px;
}

.careers .text-wrapper-32 {
  color: var(--white);
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  white-space: nowrap;
  height: 28px;
  position: absolute;
  top: 100px;
  left: 738px;
}

.careers .text-wrapper-33 {
  color: var(--white);
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  white-space: nowrap;
  height: 28px;
  position: absolute;
  top: 100px;
  left: 869px;
}

.careers .frame-35 {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: absolute;
  top: 115px;
  left: 81px;
}

.careers .group-4 {
  width: 37px;
  height: 52px;
  position: absolute;
  top: 152px;
  left: 1140px;
}

.careers .linkedin-2 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1187px;
}

.careers .facebook-2 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1234px;
}

.careers .facebook-3 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1281px;
}

.careers .facebook-4 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1328px;
}

.careers .group-5 {
  width: 400px;
  height: 17px;
  position: absolute;
  top: 242px;
  left: 501px;
}

.careers .frame-36 {
  justify-content: center;
  align-items: center;
  gap: 5px;
  display: inline-flex;
  position: relative;
}

.careers .text-wrapper-34 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.careers .ellipse-2 {
  background-color: var(--grey-01);
  border-radius: 1.5px;
  width: 3px;
  height: 3px;
  position: relative;
}

.careers .logo-3 {
  width: 116px;
  height: 56px;
  position: absolute;
  top: 39px;
  left: 76px;
}

.csr {
  background-color: #fff;
  flex-direction: row;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.csr .iphone {
  background-color: #fff;
  position: relative;
}

.csr .header {
  width: 390px !important;
  height: 60px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.csr .overlap {
  position: absolute;
  left: 0;
}

.csr .rectangle {
  background-color: #2e5053;
  width: 390px;
  height: 478px;
  position: absolute;
  top: 0;
  left: 0;
}

.csr .div {
  color: #fff;
  letter-spacing: -.2px;
  text-align: center;
  width: 258px;
  height: 84px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: absolute;
  top: 189px;
  left: 66px;
}

.csr .text-wrapper-2 {
  color: var(--secondary-02);
  letter-spacing: -.54px;
  text-align: center;
  width: 374px;
  height: 86px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 36px;
  font-weight: 400;
  line-height: 43.2px;
  position: absolute;
  top: 88px;
  left: 8px;
}

.csr .frame {
  width: 1440px;
  height: 558px;
  position: absolute;
  top: 88px;
  left: 0;
}

.csr .overlap-group {
  height: 476px;
  position: relative;
}

.csr .rectangle-2 {
  background-color: #2e5053;
  width: 1440px;
  height: 372px;
  position: absolute;
  top: 0;
  left: 0;
}

.csr .img {
  width: 459px;
  height: 476px;
  position: absolute;
  top: 0;
  left: 981px;
}

.csr .header-2 {
  background-color: var(--neutral-10);
  justify-content: space-between;
  align-items: center;
  width: 1440px;
  padding: 16px 80px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.csr .logo {
  width: 116px;
  height: 56.67px;
  position: relative;
}

.csr .holder {
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.csr .div-wrapper {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 90.33px;
  padding: 10px 8px;
  display: flex;
  position: relative;
}

.csr .text-wrapper-3 {
  color: var(--neutral-90);
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.csr .text-wrapper-4 {
  color: var(--neutral-90);
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-left: -2.83px;
  margin-right: -2.83px;
  position: relative;
}

.csr .frame-2 {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 80px;
  padding: 10px 8px;
  display: flex;
  position: relative;
}

.csr .CTA-instance {
  border-color: var(--primary-02) !important;
  flex: none !important;
}

.csr .instance-node {
  color: var(--primary-02) !important;
}

.csr .square-illusion {
  mix-blend-mode: multiply;
  width: 689px;
  height: 389px;
  position: absolute;
  top: 71px;
  left: 751px;
}

.csr .frame-3 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 204px;
  left: 277px;
}

.csr .text-wrapper-5 {
  color: var(--secondary-02, #00dfc2);
  text-align: center;
  letter-spacing: -.75px;
  text-align: center;
  width: 892px;
  margin-top: -1px;
  font-family: Source Sans Pro;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  position: relative;
}

.csr .p {
  color: #fff;
  letter-spacing: -.2px;
  text-align: center;
  width: 684px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.csr .text-wrapper-6 {
  color: var(--secondary-04);
  letter-spacing: -.75px;
  width: 424px;
  height: 60px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 50px;
  font-weight: 600;
  line-height: 60px;
  position: absolute;
  top: 500px;
  left: 77px;
}

.csr .rectangle-3 {
  height: 490px;
  position: absolute;
  left: 0;
}

.csr .frame-4 {
  background-image: url("rectangle-91.e8b43c51.webp");
  background-position: 50%;
  background-size: cover;
  width: 474px;
  height: 430px;
  position: absolute;
  top: 594px;
  left: 6px;
}

.csr .text-wrapper-7 {
  color: var(--neutral-0);
  letter-spacing: -.24px;
  text-align: center;
  white-space: nowrap;
  height: 34px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 33.6px;
  position: absolute;
  top: 324px;
  left: 176px;
}

.csr .frame-wrapper {
  background-image: url("rectangle-92.aa669717.webp");
  background-position: 50%;
  background-size: cover;
  width: 474px;
  height: 430px;
  position: absolute;
  top: 594px;
  left: 489px;
}

.csr .rural-development-wrapper {
  flex-direction: column;
  align-items: center;
  gap: 12px;
  display: inline-flex;
  position: relative;
  top: 325px;
  left: 140px;
}

.csr .text-wrapper-8 {
  color: var(--neutral-0);
  letter-spacing: -.24px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 33.6px;
  position: relative;
}

.csr .frame-5 {
  background-image: url("rectangle-93.bc772acc.webp");
  background-position: 50%;
  background-size: cover;
  width: 474px;
  height: 430px;
  position: absolute;
  top: 593px;
  left: 972px;
}

.csr .gender-equality-wrapper {
  flex-direction: column;
  align-items: center;
  gap: 12px;
  display: inline-flex;
  position: relative;
  top: 309px;
  left: 70px;
}

.csr .gender-equality {
  color: var(--neutral-0);
  letter-spacing: -.24px;
  text-align: center;
  width: 342px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 33.6px;
  position: relative;
}

.csr .square-illusion-2 {
  mix-blend-mode: multiply;
  width: 317px;
  height: 238px;
  position: absolute;
  top: 240px;
  left: 73px;
}

.csr .text-wrapper-9 {
  color: var(--secondary-04);
  letter-spacing: -.48px;
  white-space: nowrap;
  height: 38px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 600;
  line-height: 38.4px;
  position: absolute;
  top: 517px;
  left: 62px;
}

.csr .overlap-2 {
  position: absolute;
}

.csr .rectangle-4 {
  background-color: #f5f5f5;
  width: 1440px;
  height: 1369px;
  position: absolute;
  top: 0;
  left: 1px;
}

.csr .frame-6 {
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  width: 346px;
  display: flex;
  position: absolute;
  top: 265px;
  left: 24px;
}

.csr .frame-7 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 36px;
  width: 100%;
  display: flex;
  position: relative;
}

.csr .frame-8 {
  flex: none;
  align-self: stretch;
  width: 100%;
  position: relative;
}

.csr .group {
  position: absolute;
}

.csr .frame-9 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
}

.csr .text-wrapper-10 {
  color: #2e5053;
  letter-spacing: -.75px;
  text-align: center;
  width: 656px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 50px;
  font-weight: 700;
  line-height: 60px;
  position: relative;
}

.csr .text-wrapper-11 {
  color: #000;
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  text-align: center;
  width: 1086px;
  position: relative;
}

.csr .joy-of-giving {
  object-fit: cover;
  width: 904px;
  height: 705px;
  position: absolute;
  top: 202px;
  left: 91px;
}

.csr .overlap-3 {
  background-image: url("rectangle-92.aa669717.webp");
  background-position: 50%;
  background-size: cover;
  width: 390px;
  height: 352px;
  position: relative;
  top: -140px;
  left: -16px;
}

.csr .frame-10 {
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 166px;
  height: 27px;
  display: flex;
  position: relative;
  top: 267px;
  left: 115px;
}

.csr .rural-development {
  color: var(--neutral-0);
  letter-spacing: -.24px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin: -1px -17px -6px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 33.6px;
  position: relative;
}

.csr .frame-11 {
  background-image: url("rectangle-93.bc772acc.webp");
  background-position: 50%;
  background-size: cover;
  width: 390px;
  height: 352px;
  position: absolute;
  top: 221px;
  left: 0;
}

.csr .frame-12 {
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 282px;
  height: 55px;
  display: flex;
  position: relative;
  top: 254px;
  left: 57px;
}

.csr .gender-equality-2 {
  color: var(--neutral-0);
  letter-spacing: -.24px;
  text-align: center;
  width: 342px;
  margin: -1px -30px -12px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 33.6px;
  position: relative;
}

.csr .footer {
  background-color: var(--white);
  width: 1441px;
  height: 292px;
  position: absolute;
  top: 1019px;
  left: 0;
}

.csr .overlap-4 {
  width: 1441px;
  height: 292px;
  position: relative;
}

.csr .frame-13 {
  background-color: var(--neutral-90);
  width: 1441px;
  height: 292px;
  position: absolute;
  top: 0;
  left: 0;
}

.csr .DIV {
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 53px;
  left: 738px;
}

.csr .text-wrapper-12 {
  color: var(--white);
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.csr .text-wrapper-13 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1233px;
}

.csr .text-wrapper-14 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1282px;
}

.csr .text-wrapper-15 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1331px;
}

.csr .text-wrapper-16 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1187px;
}

.csr .text-wrapper-17 {
  color: var(--white);
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  white-space: nowrap;
  height: 28px;
  position: absolute;
  top: 100px;
  left: 738px;
}

.csr .text-wrapper-18 {
  color: var(--white);
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  white-space: nowrap;
  height: 28px;
  position: absolute;
  top: 100px;
  left: 869px;
}

.csr .frame-14 {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: absolute;
  top: 115px;
  left: 81px;
}

.csr .text-wrapper-19 {
  color: #fff;
  font-family: var(--paragraph-ALCPS-font-family);
  font-size: var(--paragraph-ALCPS-font-size);
  font-style: var(--paragraph-ALCPS-font-style);
  font-weight: var(--paragraph-ALCPS-font-weight);
  letter-spacing: var(--paragraph-ALCPS-letter-spacing);
  line-height: var(--paragraph-ALCPS-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.csr .text-wrapper-20 {
  color: var(--neutral-10);
  font-family: var(--paragraph-REG-font-family);
  font-size: var(--paragraph-REG-font-size);
  font-style: var(--paragraph-REG-font-style);
  font-weight: var(--paragraph-REG-font-weight);
  letter-spacing: var(--paragraph-REG-letter-spacing);
  line-height: var(--paragraph-REG-line-height);
  width: 324px;
  position: relative;
}

.csr .group-2 {
  width: 37px;
  height: 52px;
  position: absolute;
  top: 152px;
  left: 1140px;
}

.csr .text-wrapper-21 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 38px;
  left: 1px;
}

.csr .face-book {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 0;
  left: 0;
}

.csr .linkedin {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1187px;
}

.csr .facebook {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1234px;
}

.csr .facebook-2 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1281px;
}

.csr .facebook-3 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1328px;
}

.csr .group-3 {
  width: 400px;
  height: 17px;
  position: absolute;
  top: 242px;
  left: 501px;
}

.csr .frame-15 {
  justify-content: center;
  align-items: center;
  gap: 5px;
  display: inline-flex;
  position: relative;
}

.csr .text-wrapper-22 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.csr .ellipse {
  background-color: var(--grey-01);
  border-radius: 1.5px;
  width: 3px;
  height: 3px;
  position: relative;
}

.csr .logo-2 {
  width: 116px;
  height: 56px;
  position: absolute;
  top: 39px;
  left: 76px;
}

.csr .frame-16 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 1531px;
  left: 412px;
}

.csr .gift-of-knowledge {
  position: absolute;
}

.csr .we-at-joulestowatts {
  color: #0000;
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  text-align: center;
  width: 1004px;
  height: 136px;
  position: absolute;
  top: 1618px;
  left: 203px;
}

.csr .span {
  color: #000;
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
}

.csr .text-wrapper-23 {
  color: #4d9f98;
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
}

.csr .hunger-poverty-wrapper {
  background-image: url("rectangle-92.aa669717.webp");
  background-position: 50%;
  background-size: cover;
  width: 710px;
  height: 430px;
  position: absolute;
  top: 1034px;
  left: 730px;
}

.csr .hunger-poverty {
  color: var(--neutral-0);
  letter-spacing: -.24px;
  text-align: center;
  white-space: nowrap;
  height: 34px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 33.6px;
  position: absolute;
  top: 324px;
  left: 273px;
}

.csr .frame-17 {
  background-image: url("rectangle-92.aa669717.webp");
  background-position: 50%;
  background-size: cover;
  width: 710px;
  height: 430px;
  position: absolute;
  top: 1034px;
  left: 10px;
}

.csr .frame-18 {
  flex-direction: column;
  align-items: center;
  gap: 12px;
  display: inline-flex;
  position: relative;
  top: 325px;
  left: 210px;
}

.csr .overlap-group-wrapper {
  background-color: var(--white);
  width: 390px;
  height: 860px;
  position: absolute;
  top: 3645px;
  left: 0;
  overflow: hidden;
}

.csr .overlap-group-2 {
  width: 390px;
  height: 907px;
  position: relative;
}

.csr .frame-19 {
  background-color: var(--neutral-90);
  width: 390px;
  height: 907px;
  position: absolute;
  top: 0;
  left: 0;
}

.csr .frame-20 {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: absolute;
  top: 115px;
  left: 26px;
}

.csr .DIV-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 113px;
  display: flex;
  position: absolute;
  top: 272px;
  left: 195px;
}

.csr .text-wrapper-24 {
  color: var(--white);
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.csr .DIV-3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 113px;
  display: flex;
  position: absolute;
  top: 272px;
  left: 26px;
}

.csr .frame-21 {
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 583px;
  left: 69px;
}

.csr .group-4 {
  width: 37px;
  height: 52px;
  position: relative;
}

.csr .frame-22 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.csr .linkedin-2 {
  flex: none;
  position: relative;
}

.csr .text-wrapper-25 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  width: fit-content;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: relative;
}

.csr .facebook-4 {
  width: 35px;
  height: 35px;
  position: relative;
}

.csr .frame-23 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.csr .logo-3 {
  width: 116px;
  height: 56px;
  position: absolute;
  top: 39px;
  left: 24px;
}

.csr .group-5 {
  width: 295px;
  height: 64px;
  position: absolute;
  top: 732px;
  left: 48px;
}

.csr .frame-24 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 295px;
  display: flex;
  position: relative;
}

.csr .text-wrapper-26 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.csr .ellipse-2 {
  background-color: var(--grey-01);
  border-radius: 1px / 1.5px;
  width: 2px;
  height: 3px;
  position: relative;
}

.csr .text-wrapper-27 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  text-align: center;
  align-self: stretch;
  position: relative;
}

.csr .overlap-wrapper {
  width: 474px;
  height: 430px;
  position: absolute;
  top: 708px;
  left: 16px;
}

.csr .overlap-5 {
  background-image: url("rectangle-91.e8b43c51.webp");
  background-position: 50%;
  background-size: cover;
  width: 390px;
  height: 352px;
  position: relative;
  top: -62px;
  left: -16px;
}

.csr .text-wrapper-28 {
  color: var(--neutral-0);
  letter-spacing: -.24px;
  text-align: center;
  white-space: nowrap;
  height: 34px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 33.6px;
  position: absolute;
  top: 262px;
  left: 135px;
}

.csr .text-wrapper-29 {
  color: #2e5053;
  letter-spacing: -.48px;
  text-align: center;
  width: 278px;
  height: 38px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 700;
  line-height: 38.4px;
  position: absolute;
  top: 2475px;
  left: 61px;
}

.csr .we-at-joulestowatts-2 {
  color: #0000;
  letter-spacing: -.18px;
  text-align: center;
  width: 321px;
  height: 225px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
  position: absolute;
  top: 2543px;
  left: 39px;
}

.csr .text-wrapper-30 {
  color: #000;
}

.csr .text-wrapper-31 {
  color: #4d9f98;
}

.csr .overlap-6 {
  background-color: #f5f5f5;
  width: 433px;
  height: 288px;
  position: absolute;
  top: 3052px;
  left: -27px;
}

.csr .text-wrapper-32 {
  color: #2e5053;
  letter-spacing: -.48px;
  text-align: center;
  width: 228px;
  height: 38px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 700;
  line-height: 38.4px;
  position: absolute;
  top: 38px;
  left: 108px;
}

.csr .text-wrapper-33 {
  color: #000;
  letter-spacing: -.18px;
  text-align: center;
  width: 285px;
  height: 150px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
  position: absolute;
  top: 94px;
  left: 80px;
}

.csr .joy-of-giving-2 {
  object-fit: cover;
  width: 391px;
  height: 305px;
  position: absolute;
  top: 3340px;
  left: 0;
}

.csr .frame-25 {
  flex-direction: column;
  align-items: flex-start;
  gap: 4.98px;
  display: inline-flex;
  position: absolute;
  top: 1728px;
  left: 0;
}

.csr .frame-26 {
  background-image: url("rectangle-92.aa669717.webp");
  background-position: 50%;
  background-size: cover;
  width: 390px;
  height: 356.81px;
  position: relative;
}

.csr .frame-27 {
  flex-direction: column;
  align-items: center;
  gap: 9.96px;
  display: inline-flex;
  position: relative;
  top: 263px;
  left: 43px;
}

.csr .text-wrapper-34 {
  color: var(--neutral-0);
  letter-spacing: -.24px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -.83px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 33.6px;
  position: relative;
}

.csr .hunger-poverty-2 {
  color: var(--neutral-0);
  letter-spacing: -.24px;
  text-align: center;
  white-space: nowrap;
  height: 34px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 33.6px;
  position: absolute;
  top: 265px;
  left: 104px;
}

.privacy-mobile {
  background-color: #fff;
  flex-direction: row;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.privacy-mobile .div-mobile {
  background-color: #fff;
  width: 390px;
  height: 7439px;
  position: relative;
}

.privacy-mobile .header-instance {
  width: 390px !important;
  height: 60px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.privacy-mobile .overlap {
  width: 390px;
  height: 352px;
  position: absolute;
  top: 60px;
  left: 0;
}

.privacy-mobile .frame {
  width: 390px;
  height: 352px;
  position: absolute;
  top: 0;
  left: 0;
}

.privacy-mobile .frame-2 {
  height: 352px;
  position: relative;
  overflow: hidden;
}

.privacy-mobile .overlap-group {
  width: 1454px;
  height: 1178px;
  position: absolute;
  top: 0;
  left: 0;
}

.privacy-mobile .overlap-2 {
  width: 1435px;
  height: 1003px;
  position: absolute;
  top: 175px;
  left: 19px;
}

.privacy-mobile .ellipse {
  border: 2px solid;
  border-color: var(--neutral-40);
  border-radius: 261px;
  width: 522px;
  height: 522px;
  position: absolute;
  top: 14px;
  left: 816px;
}

.privacy-mobile .ellipse-2 {
  background-color: var(--neutral-0);
  border-radius: 233px;
  width: 466px;
  height: 466px;
  position: absolute;
  top: 42px;
  left: 844px;
}

.privacy-mobile .rectangle {
  mix-blend-mode: multiply;
  background: linear-gradient(#000 0%, #d9d9d900 100%);
  width: 639px;
  height: 298px;
  position: absolute;
  top: 0;
  left: 625px;
}

.privacy-mobile .rectangle-2 {
  background-color: #d9d9d9;
  width: 1435px;
  height: 498px;
  position: absolute;
  top: 505px;
  left: 0;
}

.privacy-mobile .overlap-group-2 {
  width: 394px;
  height: 519px;
  position: absolute;
  top: 0;
  left: 0;
}

.privacy-mobile .businessman-logging {
  object-fit: cover;
  width: 390px;
  height: 330px;
  position: absolute;
  top: 0;
  left: 0;
}

.privacy-mobile .rectangle-3 {
  background: linear-gradient(90deg, #fff 49.83%, #d9d9d900 146.5%);
  width: 519px;
  height: 394px;
  position: absolute;
  top: 62px;
  left: -62px;
  transform: rotate(-90deg);
}

.privacy-mobile .img {
  object-fit: cover;
  width: 436px;
  height: 436px;
  position: absolute;
  top: -8246px;
  left: -18086px;
}

.privacy-mobile .frame-3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 22px;
  display: inline-flex;
  position: absolute;
  top: 77px;
  left: 24px;
}

.privacy-mobile .text-wrapper {
  color: #2e5053;
  letter-spacing: -.96px;
  width: 335px;
  height: 85px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 700;
  line-height: 41.6px;
  position: relative;
}

.privacy-mobile .p {
  color: var(--secondary-04);
  letter-spacing: -.2px;
  width: 346px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.privacy-mobile .text-wrapper-2 {
  color: #2e5053;
  letter-spacing: -.16px;
  width: 346px;
  height: 132px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 22.4px;
  position: absolute;
  top: 439px;
  left: 25px;
}

.privacy-mobile .frame-4 {
  flex-direction: column;
  align-items: flex-end;
  gap: 25px;
  display: inline-flex;
  position: absolute;
  top: 605px;
  left: 24px;
}

.privacy-mobile .text-wrapper-3 {
  color: var(--secondary-04);
  letter-spacing: -.12px;
  width: 294px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.privacy-mobile .text-wrapper-4 {
  color: var(--secondary-04);
  letter-spacing: -.12px;
  width: 294px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.privacy-mobile .text-wrapper-5 {
  color: #2e5053;
  letter-spacing: -.16px;
  width: 346px;
  height: 172px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  position: absolute;
  top: 1179px;
  left: 25px;
}

.privacy-mobile .text-wrapper-6 {
  color: #2e5053;
  letter-spacing: -.16px;
  width: 326px;
  height: 110px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  position: absolute;
  top: 1370px;
  left: 25px;
}

.privacy-mobile .text-wrapper-7 {
  color: #2e5053;
  letter-spacing: -.16px;
  width: 326px;
  height: 132px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  position: absolute;
  top: 1480px;
  left: 25px;
}

.privacy-mobile .text-wrapper-8 {
  color: #2e5053;
  letter-spacing: -.16px;
  width: 326px;
  height: 110px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  position: absolute;
  top: 1642px;
  left: 25px;
}

.privacy-mobile .COOKIES-AND-OTHER {
  color: #1f1f1f;
  letter-spacing: -.2px;
  width: 334px;
  height: 552px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: absolute;
  top: 1785px;
  left: 25px;
}

.privacy-mobile .span {
  font-weight: 600;
}

.privacy-mobile .text-wrapper-9 {
  font-family: var(--h4-REG-font-family);
  font-size: var(--h4-REG-font-size);
  font-style: var(--h4-REG-font-style);
  font-weight: var(--h4-REG-font-weight);
  letter-spacing: var(--h4-REG-letter-spacing);
  line-height: var(--h4-REG-line-height);
}

.privacy-mobile .text-wrapper-10 {
  letter-spacing: -.16px;
  font-size: 16px;
  line-height: 22.4px;
}

.privacy-mobile .ANTI-SPAM-POLICY {
  color: #1f1f1f;
  letter-spacing: -.2px;
  width: 334px;
  height: 420px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: absolute;
  top: 2700px;
  left: 25px;
}

.privacy-mobile .ANTI-SPAM-POLICY-2 {
  color: #1f1f1f;
  letter-spacing: -.2px;
  width: 334px;
  height: 420px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: absolute;
  top: 3229px;
  left: 25px;
}

.privacy-mobile .LINKS-TO-NON {
  color: #1f1f1f;
  letter-spacing: -.2px;
  width: 334px;
  height: 712px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: absolute;
  top: 3765px;
  left: 25px;
}

.privacy-mobile .MAILERS {
  color: #1f1f1f;
  letter-spacing: -.2px;
  width: 334px;
  height: 134px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: absolute;
  top: 2527px;
  left: 25px;
}

.privacy-mobile .text-wrapper-11 {
  color: #1f1f1f;
  letter-spacing: -.2px;
  width: 303px;
  height: 56px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  position: absolute;
  top: 4665px;
  left: 25px;
}

.privacy-mobile .element-EFFECTIVE-DATE {
  color: #1f1f1f;
  letter-spacing: -.14px;
  width: 306px;
  height: 80px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  position: absolute;
  top: 4753px;
  left: 25px;
}

.privacy-mobile .text-wrapper-12 {
  color: #1f1f1f;
  letter-spacing: -.14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
}

.privacy-mobile .element-APPLICABILITY-this {
  color: #1f1f1f;
  letter-spacing: -.14px;
  width: 306px;
  height: 80px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  position: absolute;
  top: 4876px;
  left: 25px;
}

.privacy-mobile .element-DEFINITIONS {
  color: #1f1f1f;
  letter-spacing: -.14px;
  width: 306px;
  height: 100px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  position: absolute;
  top: 4999px;
  left: 25px;
}

.privacy-mobile .element-OBJECTIVE-OF {
  color: #1f1f1f;
  letter-spacing: -.14px;
  width: 306px;
  height: 140px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  position: absolute;
  top: 5140px;
  left: 25px;
}

.privacy-mobile .element-REASONABLE {
  color: #1f1f1f;
  letter-spacing: -.14px;
  width: 337px;
  height: 340px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  position: absolute;
  top: 5311px;
  left: 19px;
}

.privacy-mobile .disclosure-of {
  color: #1f1f1f;
  letter-spacing: -.16px;
  width: 345px;
  height: 132px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  position: absolute;
  top: 5693px;
  left: 24px;
}

.privacy-mobile .text-wrapper-13 {
  color: #1f1f1f;
  letter-spacing: -.16px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
}

.privacy-mobile .transfer-of {
  color: #1f1f1f;
  letter-spacing: -.16px;
  width: 345px;
  height: 110px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  position: absolute;
  top: 5853px;
  left: 24px;
}

.privacy-mobile .publication {
  color: #1f1f1f;
  letter-spacing: -.16px;
  width: 345px;
  height: 88px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  position: absolute;
  top: 5991px;
  left: 24px;
}

.privacy-mobile .right-to-opt-out {
  color: #1f1f1f;
  letter-spacing: -.16px;
  width: 345px;
  height: 154px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  position: absolute;
  top: 6107px;
  left: 24px;
}

.privacy-mobile .authenticity {
  color: #1f1f1f;
  letter-spacing: -.16px;
  width: 345px;
  height: 110px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  position: absolute;
  top: 6289px;
  left: 24px;
}

.privacy-mobile .grievance-redressal {
  color: #1f1f1f;
  letter-spacing: -.16px;
  width: 345px;
  height: 110px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  position: absolute;
  top: 6427px;
  left: 24px;
}

.privacy-mobile .footer {
  background-color: var(--white);
  width: 390px;
  height: 860px;
  position: absolute;
  top: 6579px;
  left: 2px;
  overflow: hidden;
}

.privacy-mobile .overlap-3 {
  width: 390px;
  height: 907px;
  position: relative;
}

.privacy-mobile .frame-5 {
  background-color: var(--neutral-90);
  width: 390px;
  height: 907px;
  position: absolute;
  top: 0;
  left: 0;
}

.privacy-mobile .frame-6 {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: absolute;
  top: 115px;
  left: 26px;
}

.privacy-mobile .text-wrapper-14 {
  color: var(--neutral-0);
  font-family: var(--paragraph-ALCPS-font-family);
  font-size: var(--paragraph-ALCPS-font-size);
  font-style: var(--paragraph-ALCPS-font-style);
  font-weight: var(--paragraph-ALCPS-font-weight);
  letter-spacing: var(--paragraph-ALCPS-letter-spacing);
  line-height: var(--paragraph-ALCPS-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.privacy-mobile .text-wrapper-15 {
  color: var(--neutral-10);
  font-family: var(--paragraph-REG-font-family);
  font-size: var(--paragraph-REG-font-size);
  font-style: var(--paragraph-REG-font-style);
  font-weight: var(--paragraph-REG-font-weight);
  letter-spacing: var(--paragraph-REG-letter-spacing);
  line-height: var(--paragraph-REG-line-height);
  width: 324px;
  position: relative;
}

.privacy-mobile .DIV {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 113px;
  display: flex;
  position: absolute;
  top: 272px;
  left: 195px;
}

.privacy-mobile .text-wrapper-16 {
  color: var(--white);
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.privacy-mobile .text-wrapper-17 {
  color: var(--white);
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.privacy-mobile .DIV-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 113px;
  display: flex;
  position: absolute;
  top: 272px;
  left: 26px;
}

.privacy-mobile .frame-7 {
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 583px;
  left: 69px;
}

.privacy-mobile .group {
  width: 37px;
  height: 52px;
  position: relative;
}

.privacy-mobile .text-wrapper-18 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 38px;
  left: 1px;
}

.privacy-mobile .face-book {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 0;
  left: 0;
}

.privacy-mobile .frame-8 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.privacy-mobile .linkedin {
  flex: none;
  position: relative;
}

.privacy-mobile .text-wrapper-19 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  width: fit-content;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: relative;
}

.privacy-mobile .facebook {
  width: 35px;
  height: 35px;
  position: relative;
}

.privacy-mobile .frame-9 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.privacy-mobile .logo {
  width: 116px;
  height: 56px;
  position: absolute;
  top: 39px;
  left: 24px;
}

.privacy-mobile .frame-wrapper {
  width: 295px;
  height: 64px;
  position: absolute;
  top: 732px;
  left: 48px;
}

.privacy-mobile .frame-10 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 295px;
  display: flex;
  position: relative;
}

.privacy-mobile .text-wrapper-20 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.privacy-mobile .ellipse-3 {
  background-color: var(--grey-01);
  border-radius: 1px / 1.5px;
  width: 2px;
  height: 3px;
  position: relative;
}

.privacy-mobile .text-wrapper-21 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  text-align: center;
  align-self: stretch;
  position: relative;
}

.privacy-policy {
  background-color: #f6f7f8;
  flex-direction: row;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.privacy-policy .div {
  background-color: var(--neutral-10);
  width: 1440px;
  height: 6522px;
  position: relative;
}

.privacy-policy .overlap {
  width: 1440px;
  height: 476px;
  position: absolute;
  top: 89px;
  left: 0;
}

.privacy-policy .rectangle {
  background-color: #cecfd1;
  width: 1440px;
  height: 475px;
  position: absolute;
  top: 1px;
  left: 0;
}

.privacy-policy .businessman-logging {
  object-fit: cover;
  width: 711px;
  height: 473px;
  position: absolute;
  top: 0;
  left: 729px;
}

.privacy-policy .img {
  width: 1431px;
  height: 472px;
  position: absolute;
  top: 1px;
  left: 9px;
}

.privacy-policy .p {
  color: var(--secondary-04);
  letter-spacing: -.2px;
  width: 670px;
  height: 84px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: absolute;
  top: 217px;
  left: 80px;
}

.privacy-policy .text-wrapper-2 {
  color: #2e5053;
  letter-spacing: -1.44px;
  width: 643px;
  height: 144px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 48px;
  font-weight: 700;
  line-height: 62.4px;
  position: absolute;
  top: 59px;
  left: 80px;
}

.privacy-policy .text-wrapper-3 {
  color: #2e5053;
  letter-spacing: -.32px;
  width: 1125px;
  height: 135px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 700;
  line-height: 44.8px;
  position: absolute;
  top: 649px;
  left: 85px;
}

.privacy-policy .frame {
  flex-direction: column;
  align-items: flex-start;
  gap: 41px;
  display: inline-flex;
  position: absolute;
  top: 1343px;
  left: 85px;
}

.privacy-policy .text-wrapper-4 {
  color: #2e5053;
  letter-spacing: -.24px;
  width: 1125px;
  height: 118px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
  position: relative;
}

.privacy-policy .text-wrapper-5 {
  color: #2e5053;
  letter-spacing: -.24px;
  width: 1125px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
  position: relative;
}

.privacy-policy .text-wrapper-6 {
  color: var(--secondary-04);
  letter-spacing: -.2px;
  width: 1153px;
  height: 84px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: absolute;
  top: 854px;
  left: 123px;
}

.privacy-policy .text-wrapper-7 {
  color: var(--secondary-04);
  letter-spacing: -.2px;
  width: 1153px;
  height: 28px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: absolute;
  top: 973px;
  left: 123px;
}

.privacy-policy .text-wrapper-8 {
  color: var(--secondary-04);
  letter-spacing: -.2px;
  width: 1153px;
  height: 56px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: absolute;
  top: 1036px;
  left: 123px;
}

.privacy-policy .text-wrapper-9 {
  color: var(--secondary-04);
  letter-spacing: -.2px;
  width: 1153px;
  height: 56px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: absolute;
  top: 1127px;
  left: 123px;
}

.privacy-policy .header {
  background-color: var(--neutral-10);
  justify-content: space-between;
  align-items: center;
  width: 1440px;
  padding: 16px 80px;
  display: flex;
  position: absolute;
  top: 0;
  left: 9px;
}

.privacy-policy .logo {
  width: 116px;
  height: 56.67px;
  position: relative;
}

.privacy-policy .holder {
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.privacy-policy .div-wrapper {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 90.33px;
  padding: 10px 8px;
  display: flex;
  position: relative;
}

.privacy-policy .text-wrapper-10 {
  color: #1f1f1f;
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.privacy-policy .text-wrapper-11 {
  color: #1f1f1f;
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-left: -2.83px;
  margin-right: -2.83px;
  position: relative;
}

.privacy-policy .frame-2 {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 80px;
  padding: 10px 8px;
  display: flex;
  position: relative;
}

.privacy-policy .CTA-instance {
  border-color: var(--primary-02) !important;
  flex: none !important;
}

.privacy-policy .design-component-instance-node {
  color: var(--primary-02) !important;
}

.privacy-policy .footer {
  background-color: var(--white);
  width: 1441px;
  height: 292px;
  position: absolute;
  top: 5730px;
  left: 0;
}

.privacy-policy .overlap-group {
  width: 1441px;
  height: 292px;
  position: relative;
}

.privacy-policy .frame-3 {
  background-color: #1f1f1f;
  width: 1441px;
  height: 292px;
  position: absolute;
  top: 0;
  left: 0;
}

.privacy-policy .DIV {
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 53px;
  left: 738px;
}

.privacy-policy .text-wrapper-12 {
  color: var(--white);
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.privacy-policy .text-wrapper-13 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1233px;
}

.privacy-policy .text-wrapper-14 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1282px;
}

.privacy-policy .text-wrapper-15 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1331px;
}

.privacy-policy .text-wrapper-16 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1187px;
}

.privacy-policy .text-wrapper-17 {
  color: var(--white);
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  white-space: nowrap;
  height: 28px;
  position: absolute;
  top: 100px;
  left: 738px;
}

.privacy-policy .text-wrapper-18 {
  color: var(--white);
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  white-space: nowrap;
  height: 28px;
  position: absolute;
  top: 100px;
  left: 869px;
}

.privacy-policy .frame-4 {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: absolute;
  top: 115px;
  left: 81px;
}

.privacy-policy .text-wrapper-19 {
  color: var(--neutral-0);
  font-family: var(--paragraph-ALCPS-font-family);
  font-size: var(--paragraph-ALCPS-font-size);
  font-style: var(--paragraph-ALCPS-font-style);
  font-weight: var(--paragraph-ALCPS-font-weight);
  letter-spacing: var(--paragraph-ALCPS-letter-spacing);
  line-height: var(--paragraph-ALCPS-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.privacy-policy .text-wrapper-20 {
  color: var(--neutral-10);
  font-family: var(--paragraph-REG-font-family);
  font-size: var(--paragraph-REG-font-size);
  font-style: var(--paragraph-REG-font-style);
  font-weight: var(--paragraph-REG-font-weight);
  letter-spacing: var(--paragraph-REG-letter-spacing);
  line-height: var(--paragraph-REG-line-height);
  width: 324px;
  position: relative;
}

.privacy-policy .group {
  width: 37px;
  height: 52px;
  position: absolute;
  top: 152px;
  left: 1140px;
}

.privacy-policy .text-wrapper-21 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 38px;
  left: 1px;
}

.privacy-policy .face-book {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 0;
  left: 0;
}

.privacy-policy .linkedin {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1187px;
}

.privacy-policy .facebook {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1234px;
}

.privacy-policy .facebook-2 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1281px;
}

.privacy-policy .facebook-3 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1328px;
}

.privacy-policy .frame-wrapper {
  width: 400px;
  height: 17px;
  position: absolute;
  top: 242px;
  left: 501px;
}

.privacy-policy .frame-5 {
  justify-content: center;
  align-items: center;
  gap: 5px;
  display: inline-flex;
  position: relative;
}

.privacy-policy .text-wrapper-22 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.privacy-policy .ellipse {
  background-color: var(--grey-01);
  border-radius: 1.5px;
  width: 3px;
  height: 3px;
  position: relative;
}

.privacy-policy .logo-2 {
  width: 116px;
  height: 56px;
  position: absolute;
  top: 39px;
  left: 76px;
}

.privacy-policy .rectangle-2 {
  background-color: #2e5053;
  border-radius: 15px;
  width: 15px;
  height: 14px;
  position: absolute;
  top: 862px;
  left: 85px;
}

.privacy-policy .rectangle-3 {
  background-color: #2e5053;
  border-radius: 15px;
  width: 15px;
  height: 14px;
  position: absolute;
  top: 981px;
  left: 85px;
}

.privacy-policy .rectangle-4 {
  background-color: #2e5053;
  border-radius: 15px;
  width: 15px;
  height: 14px;
  position: absolute;
  top: 1044px;
  left: 85px;
}

.privacy-policy .rectangle-5 {
  background-color: #2e5053;
  border-radius: 15px;
  width: 15px;
  height: 14px;
  position: absolute;
  top: 1135px;
  left: 85px;
}

.privacy-policy .frame-6 {
  border-radius: 15px;
  align-items: center;
  gap: 23px;
  display: inline-flex;
  position: absolute;
  top: 1219px;
  left: 85px;
}

.privacy-policy .rectangle-6 {
  background-color: #2e5053;
  border-radius: 18px;
  width: 15px;
  height: 14px;
  position: relative;
}

.privacy-policy .text-wrapper-23 {
  color: var(--secondary-04);
  letter-spacing: -.2px;
  width: 1153px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.privacy-policy .COOKIES-AND-OTHER {
  color: var(--neutral-90);
  letter-spacing: -.35px;
  width: 1115px;
  height: 427px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 400;
  line-height: 49px;
  position: absolute;
  top: 1925px;
  left: 85px;
}

.privacy-policy .span {
  font-weight: 600;
}

.privacy-policy .text-wrapper-24 {
  font-family: var(--h4-REG-font-family);
  font-size: var(--h4-REG-font-size);
  font-style: var(--h4-REG-font-style);
  font-weight: var(--h4-REG-font-weight);
  letter-spacing: var(--h4-REG-letter-spacing);
  line-height: var(--h4-REG-line-height);
}

.privacy-policy .text-wrapper-25 {
  letter-spacing: -.24px;
  font-size: 24px;
  line-height: 33.6px;
}

.privacy-policy .MAILERS {
  color: var(--neutral-90);
  letter-spacing: -.35px;
  width: 1086px;
  height: 121px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 400;
  line-height: 49px;
  position: absolute;
  top: 2589px;
  left: 85px;
}

.privacy-policy .ANTI-SPAM-POLICY {
  color: var(--neutral-90);
  letter-spacing: -.35px;
  width: 1086px;
  height: 325px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 400;
  line-height: 49px;
  position: absolute;
  top: 2807px;
  left: 85px;
}

.privacy-policy .ANTI-SPAM-POLICY-2 {
  color: var(--neutral-90);
  letter-spacing: -.35px;
  width: 1086px;
  height: 325px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 400;
  line-height: 49px;
  position: absolute;
  top: 3351px;
  left: 85px;
}

.privacy-policy .LINKS-TO-NON {
  color: var(--neutral-90);
  letter-spacing: -.35px;
  width: 1266px;
  height: 461px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 400;
  line-height: 49px;
  position: absolute;
  top: 3851px;
  left: 85px;
}

.privacy-policy .element-EFFECTIVE-DATE {
  color: var(--neutral-90);
  letter-spacing: -.24px;
  width: 1184px;
  height: 102px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
  position: absolute;
  top: 4643px;
  left: 85px;
}

.privacy-policy .text-wrapper-26 {
  color: #1f1f1f;
  letter-spacing: -.24px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
}

.privacy-policy .element-APPLICABILITY-this {
  color: var(--neutral-90);
  letter-spacing: -.24px;
  width: 1184px;
  height: 102px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
  position: absolute;
  top: 4772px;
  left: 85px;
}

.privacy-policy .element-DEFINITIONS {
  color: var(--neutral-90);
  letter-spacing: -.24px;
  width: 1184px;
  height: 102px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
  position: absolute;
  top: 4889px;
  left: 85px;
}

.privacy-policy .element-OBJECTIVE-OF {
  color: var(--neutral-90);
  letter-spacing: -.24px;
  width: 1184px;
  height: 136px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
  position: absolute;
  top: 5000px;
  left: 85px;
}

.privacy-policy .element-REASONABLE {
  color: var(--neutral-90);
  letter-spacing: -.24px;
  width: 1184px;
  height: 340px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
  position: absolute;
  top: 5186px;
  left: 85px;
}

.privacy-policy .element-REASONABLE .ul_pad_left {
  padding-left: 10px;
}

.privacy-policy .disclosure-of {
  color: var(--neutral-90);
  letter-spacing: -.24px;
  width: 1184px;
  height: 102px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
  position: absolute;
  top: 5563px;
  left: 85px;
}

.privacy-policy .transfer-of {
  color: var(--neutral-90);
  letter-spacing: -.24px;
  width: 1184px;
  height: 68px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
  position: absolute;
  top: 5677px;
  left: 85px;
}

.privacy-policy .publication {
  color: var(--neutral-90);
  letter-spacing: -.24px;
  width: 1184px;
  height: 68px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
  position: absolute;
  top: 5758px;
  left: 85px;
}

.privacy-policy .right-to-opt-out {
  color: var(--neutral-90);
  letter-spacing: -.24px;
  width: 1184px;
  height: 136px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
  position: absolute;
  top: 5843px;
  left: 85px;
}

.privacy-policy .authenticity {
  color: var(--neutral-90);
  letter-spacing: -.24px;
  width: 1184px;
  height: 68px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
  position: absolute;
  top: 5998px;
  left: 85px;
}

.privacy-policy .grievance-redressal {
  color: var(--neutral-90);
  letter-spacing: -.24px;
  width: 1184px;
  height: 68px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
  position: absolute;
  top: 6094px;
  left: 85px;
}

.privacy-policy .text-wrapper-27 {
  color: var(--neutral-90);
  letter-spacing: -.35px;
  width: 1086px;
  height: 49px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 600;
  line-height: 49px;
  position: absolute;
  top: 4550px;
  left: 85px;
}

.component {
  width: 1487px;
  height: 517px;
}

.component .overlap-2 {
  height: 517px;
}

.component .group {
  width: 1487px;
  height: 517px;
  position: absolute;
  top: 0;
  left: 0;
}

.component .group-2 {
  height: 517px;
}

.component .overlap-group-2 {
  width: 1487px;
  height: 517px;
  position: relative;
}

.component .rectangle-4 {
  background-color: #1b766e;
  width: 846px;
  height: 517px;
  position: absolute;
  top: 0;
  left: 0;
}

.component .rectangle-5 {
  width: 750px;
  height: 517px;
  position: absolute;
  top: 0;
  left: 737px;
}

.component .frame-11 {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 499px;
  display: flex;
  position: absolute;
  top: 98px;
  left: 113px;
}

.component .text-wrapper-8 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: -.53px;
  text-fill-color: transparent;
  background: linear-gradient(#00dfc2 0%, #ffffffb8 100%) text;
  width: 466px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 600;
  line-height: 42px;
  position: relative;
  -webkit-background-clip: text !important;
}

.component .an-execution-driven-2 {
  color: #fff;
  letter-spacing: -.24px;
  width: 466px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
  position: relative;
}

.component .frame-12 {
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
  width: 499px;
  display: flex;
  position: absolute;
  top: 98px;
  left: 113px;
}

.component .frame-13 {
  align-items: flex-start;
  gap: 6.56px;
  display: inline-flex;
  position: absolute;
  top: 405px;
  left: 113px;
}

.component .ellipse-13 {
  border-radius: 4.47px;
  width: 8.95px;
  height: 8.95px;
  position: relative;
}

.component .ellipse-14 {
  background-color: #c2c2c2;
  border-radius: 4.47px;
  width: 8.95px;
  height: 8.95px;
  position: relative;
}

.component .ellipse-15 {
  border-radius: 4.47px;
  width: 8.95px;
  height: 8.95px;
  position: relative;
}

.component .frame-14 {
  flex-direction: column;
  align-items: flex-start;
  width: 499px;
  display: flex;
  position: absolute;
  top: 98px;
  left: 113px;
}

.component .talent {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: -.53px;
  text-fill-color: transparent;
  background: linear-gradient(#00dfc2 0%, #ffffffb8 100%) text;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 600;
  line-height: 42px;
  position: relative;
  -webkit-background-clip: text !important;
}

.component .an-execution-driven-3 {
  color: #fff;
  letter-spacing: -.24px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
  position: relative;
}

.component .ellipse-16, .component .ellipse-17, .component .ellipse-18 {
  border-radius: 4.47px;
  width: 8.95px;
  height: 8.95px;
  position: relative;
}

.component .frame-14693, .component .frame-14695, .component .overlap-2.frame-14695 .group-2 {
  position: relative;
}

.component .overlap-2.frame-14696 .ellipse-13, .component .overlap-2.frame-14694 .ellipse-13, .component .overlap-2.frame-14697 .ellipse-13, .component .overlap-2.frame-14693 .ellipse-13 {
  background-color: #4d9f98;
}

.component .overlap-2.frame-14695 .ellipse-13, .component .overlap-2.frame-14696 .ellipse-15, .component .overlap-2.frame-14694 .ellipse-15, .component .overlap-2.frame-14697 .ellipse-15, .component .overlap-2.frame-14693 .ellipse-15 {
  background-color: #c2c2c2;
}

.component .overlap-2.frame-14695 .ellipse-15 {
  background-color: #4d9f98;
}

.component .overlap-2.frame-14696 .frame-14, .component .overlap-2.frame-14694 .frame-14 {
  gap: 18px;
}

.component .overlap-2.frame-14697 .frame-14 {
  gap: 16px;
}

.component .overlap-2.frame-14693 .frame-14, .component .overlap-2.frame-14695 .frame-14 {
  gap: 18px;
}

.component .overlap-2.frame-14696 .talent, .component .overlap-2.frame-14694 .talent {
  width: 466px;
}

.component .overlap-2.frame-14697 .talent {
  width: 499px;
}

.component .overlap-2.frame-14693 .talent, .component .overlap-2.frame-14695 .talent, .component .overlap-2.frame-14696 .an-execution-driven-3, .component .overlap-2.frame-14694 .an-execution-driven-3 {
  width: 466px;
}

.component .overlap-2.frame-14697 .an-execution-driven-3 {
  width: 486px;
}

.component .overlap-2.frame-14693 .an-execution-driven-3, .component .overlap-2.frame-14695 .an-execution-driven-3 {
  width: 466px;
}

.component .overlap-2.frame-14696 .ellipse-16 {
  background-color: #c2c2c2;
}

.component .overlap-2.frame-14694 .ellipse-16 {
  background-color: #4d9f98;
}

.component .overlap-2.frame-14697 .ellipse-16, .component .overlap-2.frame-14693 .ellipse-16, .component .overlap-2.frame-14695 .ellipse-16 {
  background-color: #c2c2c2;
}

.component .overlap-2.frame-14696 .ellipse-17 {
  background-color: #4d9f98;
}

.component .overlap-2.frame-14694 .ellipse-17, .component .overlap-2.frame-14697 .ellipse-17, .component .overlap-2.frame-14693 .ellipse-17, .component .overlap-2.frame-14695 .ellipse-17, .component .overlap-2.frame-14696 .ellipse-18, .component .overlap-2.frame-14694 .ellipse-18 {
  background-color: #c2c2c2;
}

.component .overlap-2.frame-14697 .ellipse-18 {
  background-color: #4d9f98;
}

.component .overlap-2.frame-14693 .ellipse-18, .component .overlap-2.frame-14695 .ellipse-18 {
  background-color: #c2c2c2;
}

.CTA.property-1-4-hover {
  background-color: #4d9f98;
  gap: 8px;
}

.CTA.property-1-4-default {
  border: 1px solid #fff;
  gap: 10px;
  transition: all .3s ease-in-out;
}

.CTA.property-1-4-default:hover {
  background-color: #4d9f98 !important;
  gap: 8px !important;
}

.homepage-frame {
  flex-direction: column;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.homepage-frame .rectangle-mobile {
  width: 360px;
  height: 223px;
  position: relative;
}

.homepage-frame .div-mobile {
  background-color: #1b766e;
  width: 360px;
  height: 424px;
  position: relative;
}

.homepage-frame .agile-talent {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: -.42px;
  text-fill-color: transparent;
  background: linear-gradient(#00dfc2 0%, #ffffffb8 100%) text;
  width: 298px;
  height: 68px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 28px;
  font-weight: 600;
  line-height: 33.6px;
  position: absolute;
  top: 39px;
  left: 30px;
  -webkit-background-clip: text !important;
}

.homepage-frame .an-execution-driven {
  color: #fff;
  letter-spacing: -.18px;
  width: 298px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
  position: absolute;
  top: 125px;
  left: 30px;
}

.homepage-frame .div-2 {
  align-items: flex-start;
  gap: 6.56px;
  display: inline-flex;
  position: absolute;
  top: 388px;
  left: 145px;
}

.homepage-frame .ellipse, .homepage-frame .ellipse-2, .homepage-frame .ellipse-3, .homepage-frame .ellipse-4, .homepage-frame .ellipse-5 {
  border-radius: 4.47px;
  width: 8.95px;
  height: 8.95px;
  position: relative;
}

.homepage-frame .variant-4, .homepage-frame .variant-2 {
  height: 125px;
}

.homepage-frame .variant-3, .homepage-frame .variant-5 {
  height: 150px;
}

.homepage-frame .default {
  height: 125px;
}

.homepage-frame .property-1-variant-4, .homepage-frame .property-1-variant-2, .homepage-frame .property-1-variant-3, .homepage-frame .property-1-variant-5 {
  background-color: #c2c2c2;
}

.homepage-frame .property-1-default {
  background-color: #4d9f98;
}

.homepage-frame .property-1-0-variant-4 {
  background-color: #c2c2c2;
}

.homepage-frame .property-1-0-variant-2 {
  background-color: #4d9f98;
}

.homepage-frame .property-1-0-variant-3, .homepage-frame .property-1-0-variant-5, .homepage-frame .property-1-0-default, .homepage-frame .property-1-1-variant-4, .homepage-frame .property-1-1-variant-2 {
  background-color: #c2c2c2;
}

.homepage-frame .property-1-1-variant-3 {
  background-color: #4d9f98;
}

.homepage-frame .property-1-1-variant-5, .homepage-frame .property-1-1-default {
  background-color: #c2c2c2;
}

.homepage-frame .property-1-2-variant-4 {
  background-color: #4d9f98;
}

.homepage-frame .property-1-2-variant-2, .homepage-frame .property-1-2-variant-3, .homepage-frame .property-1-2-variant-5, .homepage-frame .property-1-2-default, .homepage-frame .property-1-3-variant-4, .homepage-frame .property-1-3-variant-2, .homepage-frame .property-1-3-variant-3 {
  background-color: #c2c2c2;
}

.homepage-frame .property-1-3-variant-5 {
  background-color: #4d9f98;
}

.homepage-frame .property-1-3-default {
  background-color: #c2c2c2;
}

.carousel-no {
  width: 1440px;
  height: 580px;
  overflow: hidden;
}

.carousel-no .overlap-group {
  width: 1805px;
  height: 714px;
  position: relative;
  top: -58px;
  left: -163px;
}

.carousel-no .img {
  object-fit: cover;
  width: 1087px;
  height: 656px;
  position: absolute;
  top: 58px;
  left: 718px;
}

.carousel-no .rectangle-2 {
  filter: blur(80px);
  background: linear-gradient(#000 0%, #071103 25%, #202020 44.79%, #2f2f2f 61.46%, #0000 89.06%);
  width: 1676px;
  height: 714px;
  position: absolute;
  top: 0;
  left: 0;
}

.carousel-no .text-wrapper {
  color: #fff;
  letter-spacing: -.53px;
  width: 396px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 600;
  line-height: 49px;
  position: relative;
}

.carousel-no .frame-3 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.carousel-no .with-efficient-and {
  color: var(--neutral-10);
  letter-spacing: -.17px;
  width: 461px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 400;
  line-height: 23.8px;
  position: relative;
}

.carousel-no .button {
  all: unset;
  box-sizing: border-box;
  flex: none;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.carousel-no .text-wrapper-2 {
  color: #fff;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 23.8px;
  position: relative;
}

.carousel-no .arrow-forward-2 {
  width: 24px;
  height: 24px;
  position: relative;
}

.carousel-no .text-wrapper-3 {
  color: #fff;
  font-family: var(--h4-REG-font-family);
  font-size: var(--h4-REG-font-size);
  font-style: var(--h4-REG-font-style);
  font-weight: var(--h4-REG-font-weight);
  letter-spacing: var(--h4-REG-letter-spacing);
  line-height: var(--h4-REG-line-height);
  white-space: nowrap;
  height: 41px;
  position: absolute;
  top: 118px;
  left: 243px;
}

.carousel-no .frame-4 {
  align-items: flex-start;
  gap: 5px;
  display: inline-flex;
  position: absolute;
  top: 558px;
  left: 243px;
}

.carousel-no .ellipse-6 {
  background-color: #f6f7f852;
  border-radius: 5.5px;
  width: 11px;
  height: 11px;
  position: relative;
}

.carousel-no .ellipse-7 {
  background-color: var(--neutral-30);
  border-radius: 5.5px;
  width: 11px;
  height: 11px;
  position: relative;
}

.property-default-main {
  width: 1440px;
  height: 580px;
  position: relative;
  overflow: hidden;
}

.property-default-main .frame-15 {
  background-image: url("istockphoto-530281675-612x612-2.2e9b488c.webp");
  background-size: 100% 100%;
  width: 1440px;
  height: 580px;
  position: absolute;
  top: 0;
  left: 0;
}

.property-default-main .frame-15-2 {
  background-image: url("istockphoto-530281675-612x612-2-2.67e9024b.webp");
  background-size: 100% 100%;
  width: 1440px;
  height: 580px;
  position: absolute;
  top: 0;
  left: 0;
}

.property-default-main .frame-15-2:after {
  content: "";
  background: #00000059;
  width: 100%;
  height: 100%;
  position: absolute;
}

.property-default-main .frame-16 {
  z-index: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: absolute;
  top: 153px;
  left: 80px;
}

.property-default-main .text-wrapper-10 {
  color: #fff;
  letter-spacing: -.72px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 48px;
  font-weight: 600;
  line-height: 67.2px;
  position: relative;
}

.property-default-main .frame-17 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.property-default-main .text-wrapper-11 {
  color: var(--neutral-0);
  letter-spacing: -.2px;
  width: 523px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.property-default-main .frame-18 {
  align-items: flex-start;
  gap: 5px;
  display: inline-flex;
  position: absolute;
  top: 500px;
  left: 80px;
}

.property-default-main .ellipse-19 {
  background-color: var(--neutral-30);
  border-radius: 5.5px;
  width: 11px;
  height: 11px;
  position: relative;
}

.property-default-main .ellipse-20 {
  background-color: #ffffff52;
  border-radius: 5.5px;
  width: 11px;
  height: 11px;
  position: relative;
}

.property-default-main .frame-19 {
  width: 1440px;
  height: 580px;
  position: absolute;
  top: 0;
  left: 1440px;
  overflow: hidden;
}

.property-default-main .rectangle-6 {
  width: 1383px;
  height: 634px;
  position: absolute;
  top: -1578px;
  left: -4204px;
}

.property-default-main .overlap-group-3 {
  filter: blur(80px);
  background: linear-gradient(#000 0%, #071103 25%, #202020 44.79%, #2f2f2f 61.46%, #0000 100%);
  width: 1684px;
  height: 714px;
  position: absolute;
  top: -60px;
  left: -116px;
}

.property-default-main .frame-20 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: absolute;
  top: 242px;
  left: 196px;
}

.property-default-main .text-wrapper-12 {
  color: #fff;
  letter-spacing: -.53px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 600;
  line-height: 49px;
  position: relative;
}

.property-default-main .our-client-and-2 {
  color: var(--neutral-10);
  letter-spacing: -.17px;
  width: 436px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 400;
  line-height: 23.8px;
  position: relative;
}

.property-default-main .CTA-3 {
  all: unset;
  box-sizing: border-box;
  flex: none;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.property-default-main .text-wrapper-13 {
  color: #fff;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 23.8px;
  position: relative;
}

.property-default-main .arrow-forward-4 {
  width: 24px;
  height: 24px;
  margin-top: -1950px;
  margin-left: -4538px;
  position: relative;
}

.property-default-main .text-wrapper-14 {
  color: #fff;
  font-family: var(--h4-REG-font-family);
  font-size: var(--h4-REG-font-size);
  font-style: var(--h4-REG-font-style);
  font-weight: var(--h4-REG-font-weight);
  letter-spacing: var(--h4-REG-letter-spacing);
  line-height: var(--h4-REG-line-height);
  white-space: nowrap;
  height: 41px;
  position: absolute;
  top: 120px;
  left: 196px;
}

.property-default-main .frame-21 {
  align-items: flex-start;
  gap: 5px;
  display: inline-flex;
  position: absolute;
  top: 560px;
  left: 196px;
}

.property-default-main .ellipse-21 {
  background-color: #d9d9d952;
  border-radius: 5.5px;
  width: 11px;
  height: 11px;
  position: relative;
}

.property-default-main .carousel-instance {
  position: absolute !important;
  top: 0 !important;
  left: 2880px !important;
}

.property-default-wrapper-main {
  width: 390px;
  height: 580px;
  position: relative;
  overflow: hidden;
}

.property-default-wrapper-main .div-3 {
  width: 1440px;
  height: 580px;
  position: absolute;
  top: 0;
  left: 0;
}

.property-default-wrapper-main .frame-5-home {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 252px;
  display: flex;
  position: relative;
}

.property-default-wrapper-main .text-wrapper-4 {
  color: #fff;
  z-index: 1;
  letter-spacing: -.48px;
  width: 221px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 600;
  line-height: 44.8px;
  position: relative;
}

.property-default-wrapper-main .div-wrapper {
  z-index: 1;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  display: flex;
  position: relative;
}

.property-default-wrapper-main .p {
  color: var(--neutral-0);
  letter-spacing: -.2px;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.property-default-wrapper-main .frame-6 {
  align-items: flex-start;
  gap: 5px;
  display: inline-flex;
  position: absolute;
  top: 500px;
  left: 48px;
}

.property-default-wrapper-main .ellipse-8, .property-default-wrapper-main .ellipse-9, .property-default-wrapper-main .ellipse-10 {
  border-radius: 5.5px;
  width: 11px;
  height: 11px;
  position: relative;
}

.property-default-wrapper-main .frame-7 {
  width: 1440px;
  height: 580px;
  position: absolute;
  top: 0;
  left: 1440px;
  overflow: hidden;
}

.property-default-wrapper-main .rectangle-3 {
  width: 1383px;
  height: 634px;
  position: absolute;
  top: -1598px;
}

.property-default-wrapper-main .overlap {
  filter: blur(80px);
  background: linear-gradient(#000 0%, #071103 25%, #202020 44.79%, #2f2f2f 61.46%, #0000 100%);
  width: 1684px;
  height: 714px;
  position: absolute;
  top: -60px;
  left: -116px;
}

.property-default-wrapper-main .frame-8 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: absolute;
  top: 242px;
  left: 196px;
}

.property-default-wrapper-main .text-wrapper-5 {
  color: #fff;
  letter-spacing: -.53px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 600;
  line-height: 49px;
  position: relative;
}

.property-default-wrapper-main .frame-9 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.property-default-wrapper-main .our-client-and {
  color: var(--neutral-10);
  letter-spacing: -.17px;
  width: 436px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 400;
  line-height: 23.8px;
  position: relative;
}

.property-default-wrapper-main .CTA-2 {
  all: unset;
  box-sizing: border-box;
  flex: none;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.property-default-wrapper-main .text-wrapper-6 {
  color: #fff;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 23.8px;
  position: relative;
}

.property-default-wrapper-main .arrow-forward-3 {
  width: 24px;
  height: 24px;
  margin-top: -1970px;
  position: relative;
}

.property-default-wrapper-main .text-wrapper-7 {
  color: #fff;
  font-family: var(--h4-REG-font-family);
  font-size: var(--h4-REG-font-size);
  font-style: var(--h4-REG-font-style);
  font-weight: var(--h4-REG-font-weight);
  letter-spacing: var(--h4-REG-letter-spacing);
  line-height: var(--h4-REG-line-height);
  white-space: nowrap;
  height: 41px;
  position: absolute;
  top: 120px;
  left: 196px;
}

.property-default-wrapper-main .frame-10 {
  align-items: flex-start;
  gap: 5px;
  display: inline-flex;
  position: absolute;
  top: 560px;
  left: 196px;
}

.property-default-wrapper-main .ellipse-11 {
  background-color: #d9d9d952;
  border-radius: 5.5px;
  width: 11px;
  height: 11px;
  position: relative;
}

.property-default-wrapper-main .ellipse-12 {
  background-color: var(--neutral-30);
  border-radius: 5.5px;
  width: 11px;
  height: 11px;
  position: relative;
}

.property-default-wrapper-main .carousel-03 {
  position: absolute !important;
  top: 0 !important;
  left: 2880px !important;
}

.property-default-wrapper-main .property-1-5-default {
  background-image: url("istockphoto-530281675-612x612-2-1.5267be64.webp");
  background-size: 100% 100%;
  width: 390px;
  height: 580px;
}

.property-default-wrapper-main .property-1-5-variant-3 {
  background-image: url("istockphoto-530281675-612x612-2-2.67e9024b.webp");
  background-position: 50%;
  background-size: cover;
  width: 1170px;
  height: 652px;
  position: relative;
  top: -57px;
  left: -426px;
}

.property-default-wrapper-main .property-1-5-variant-3:after {
  content: "";
  background: #00000059;
  width: 100%;
  height: 100%;
  position: absolute;
  top: -10px;
}

.property-default-wrapper-main .property-1-5-variant-2 {
  background-image: url("istockphoto-466967554-612x612-1.26cc1f85.webp");
  background-position: 50%;
  background-size: cover;
  width: 1038px;
  height: 662px;
  position: relative;
  top: -57px;
  left: -471px;
}

.property-default-wrapper-main .property-1-7-default {
  background-color: var(--neutral-30);
}

.property-default-wrapper-main .property-1-7-variant-3, .property-default-wrapper-main .property-1-7-variant-2, .property-default-wrapper-main .property-1-8-default, .property-default-wrapper-main .property-1-8-variant-3 {
  background-color: #ffffff52;
}

.property-default-wrapper-main .property-1-8-variant-2 {
  background-color: var(--neutral-30);
}

.property-default-wrapper-main .property-1-9-default {
  background-color: #ffffff52;
}

.property-default-wrapper-main .property-1-9-variant-3 {
  background-color: var(--neutral-30);
}

.property-default-wrapper-main .property-1-9-variant-2 {
  background-color: #ffffff52;
}

.property-default-wrapper-main .property-1-10-default {
  left: -5744px;
}

.property-default-wrapper-main .property-1-10-variant-3 {
  left: -6544px;
}

.property-default-wrapper-main .property-1-10-variant-2 {
  left: -6144px;
}

.property-default-wrapper-main .property-1-11-default {
  margin-left: -6078px;
}

.property-default-wrapper-main .property-1-11-variant-3 {
  margin-left: -6878px;
}

.property-default-wrapper-main .property-1-11-variant-2 {
  margin-left: -6478px;
}

.property-default-wrapper-main .overlap-group-8.property-1-5-default .frame-5-home {
  top: 99px;
  left: 48px;
}

.property-default-wrapper-main .overlap-group-8.property-1-5-variant-3 .frame-5-home {
  top: 156px;
  left: 474px;
}

.property-default-wrapper-main .overlap-group-8.property-1-5-variant-2 .frame-5-home {
  top: 156px;
  left: 519px;
}

.homepage {
  background-color: #fff;
  flex-direction: row;
  width: 100%;
  height: fit-content;
  display: flex;
  overflow: hidden;
}

.homepage .carousel {
  width: 100%;
  height: auto;
}

.homepage .carousel-1 {
  z-index: 1;
  width: 1440px !important;
  position: absolute !important;
  top: 475px !important;
}

.homepage .carousel-11 .react-multi-carousel-dot-list {
  bottom: 100px;
  left: -1140px;
}

.homepage .carousel-12 .react-multi-carousel-dot-list {
  bottom: 24px;
}

.homepage .carousel-111 .react-multi-carousel-dot-list {
  bottom: 100px;
  left: -1243px;
}

.homepage .carousel-4 .react-multi-carousel-dot-list {
  bottom: 69px;
  left: -264px;
}

.homepage .iphone {
  background-color: #fff;
  position: relative;
}

.homepage .mobile-carousel-1 {
  width: 360px;
  position: absolute !important;
  top: 1401px !important;
  left: 16px !important;
}

.homepage .overlap-3 {
  position: absolute;
}

.homepage .frame-wrapper {
  background: linear-gradient(#000c 0%, #0000 100%);
  width: 390px;
  height: 507px;
  position: absolute;
  top: 0;
  left: 0;
}

.homepage .video-mobile {
  width: 390px;
  height: 507px;
  position: absolute;
  top: 0;
  left: 0;
}

.homepage .video-mobile:after {
  content: "";
  background: #00000029;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.homepage .frame-22 {
  flex-direction: column;
  align-items: center;
  gap: 49px;
  width: 332px;
  display: flex;
  position: relative;
  top: 170px;
  left: 29px;
}

.homepage .frame-23 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 20px;
  width: 100%;
  display: flex;
  position: relative;
}

.homepage .accelerating-your {
  color: #0000;
  letter-spacing: -1.44px;
  text-align: center;
  width: 390px;
  margin-top: -1px;
  margin-left: -29px;
  margin-right: -29px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 48px;
  font-weight: 600;
  line-height: 48.7px;
  position: relative;
}

.homepage .span {
  color: #00dfc2;
}

.homepage .text-wrapper-15 {
  color: #fff;
}

.homepage .scale-faster-with-wrapper {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 24px;
  width: 100%;
  display: flex;
  position: relative;
}

.homepage .scale-faster-with {
  color: #fff;
  letter-spacing: -.16px;
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;
  position: relative;
}

.homepage .header-instance {
  width: 390px !important;
  height: 60px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.homepage .component-2 {
  width: 1459px !important;
  position: absolute !important;
  top: 475px !important;
  left: 0 !important;
}

.homepage .component-instance {
  width: 1487px !important;
}

.homepage .component-2-instance {
  width: 1439px !important;
}

.homepage .instance-node {
  width: 702px !important;
}

.homepage .rectangle-7 {
  background-color: #fff;
  width: 1439px;
  height: 475px;
  position: absolute;
  top: 0;
  left: 0;
}

.homepage .rectangle-8 {
  width: 308px;
  height: 159px;
  position: absolute;
  top: 88px;
  left: 1131px;
}

.homepage .rectangle-9 {
  width: 300px;
  height: 159px;
  position: absolute;
  top: 247px;
  left: 831px;
}

.homepage .frame-24 {
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
  display: inline-flex;
  position: absolute;
  top: 88px;
  left: 86px;
}

.homepage .your-future {
  flex: none;
  width: 597.72px;
  margin-left: -.05px;
  position: relative;
}

.homepage .text-wrapper-16 {
  color: var(--neutral-80);
  letter-spacing: -.2px;
  width: 601px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.homepage .rectangle-10 {
  background-color: #21212a;
  width: 300px;
  height: 247px;
  position: absolute;
  top: 0;
  left: 831px;
}

.homepage .rectangle-11 {
  background-color: #1b766e;
  width: 308px;
  height: 230px;
  position: absolute;
  top: 247px;
  left: 1131px;
}

.homepage .frame-25 {
  flex-direction: column;
  align-items: center;
  gap: 4px;
  display: inline-flex;
  position: absolute;
  top: 67px;
  left: 916px;
}

.homepage .text-wrapper-17 {
  color: #fff;
  letter-spacing: -.75px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 50px;
  font-weight: 700;
  line-height: 60px;
  position: relative;
}

.homepage .text-wrapper-18 {
  color: #b6b6b6;
  white-space: nowrap;
  text-align: center;
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  width: 128px;
  height: 48px;
  position: relative;
}

.homepage .frame-26 {
  flex-direction: column;
  align-items: center;
  width: 175px;
  display: flex;
  position: absolute;
  top: 310px;
  left: 1198px;
}

.homepage .text-wrapper-19 {
  color: #fff6f6;
  text-align: center;
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  white-space: nowrap;
  width: 193px;
  height: 29px;
  margin-left: -9px;
  margin-right: -9px;
  position: relative;
}

.homepage .frame-27 {
  align-items: flex-start;
  gap: 61px;
  height: 92px;
  display: inline-flex;
  position: absolute;
  top: 118px;
  left: 1185px;
}

.homepage .frame-28 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 4px;
  margin-bottom: -20px;
  display: inline-flex;
  position: relative;
}

.homepage .text-wrapper-20 {
  color: #fff;
  white-space: nowrap;
  letter-spacing: -.75px;
  text-align: center;
  width: 69px;
  margin: -1px 0 0 -169px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 45px;
  font-weight: 700;
  line-height: 60px;
  position: relative;
}

.homepage .text-wrapper-21 {
  color: #d5d5d5;
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  white-space: nowrap;
  line-height: var(--subtitle-REG-line-height);
  width: 100%;
  position: relative;
}

.homepage .frame-29 {
  flex-direction: column;
  align-items: flex-start;
  gap: 15.84px;
  display: inline-flex;
  position: absolute;
  top: 536px;
  left: 26px;
}

.homepage .your-future-2 {
  flex: none;
  margin-left: -.02px;
  position: relative;
}

.homepage .text-wrapper-22 {
  color: var(--neutral-80);
  letter-spacing: -.16px;
  width: 338px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  position: relative;
}

.homepage .frame-30 {
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
  position: absolute;
  top: 777px;
  left: -2px;
}

.homepage .rectangle-wrapper {
  flex: none;
  align-items: flex-start;
  width: 392px;
  display: flex;
  position: relative;
}

.homepage .rectangle-12 {
  background-color: #21212a;
  width: 392px;
  height: 200px;
  position: relative;
}

.homepage .frame-31 {
  flex-direction: column;
  align-items: center;
  gap: 4px;
  display: inline-flex;
  position: absolute;
  top: 54px;
  left: 133px;
}

.homepage .text-wrapper-23 {
  white-space: nowrap;
  color: #b6b6b6;
  letter-spacing: -.17px;
  text-align: center;
  width: 128px;
  height: 48px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 400;
  line-height: 23.8px;
  position: relative;
}

.homepage .frame-32 {
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
  position: absolute;
  top: 1177px;
  left: -2px;
}

.homepage .rectangle-13 {
  background-color: #1b766e;
  width: 392px;
  height: 200px;
  position: relative;
}

.homepage .frame-33 {
  flex-direction: column;
  align-items: center;
  width: 175px;
  display: flex;
  position: absolute;
  top: 55px;
  left: 108px;
}

.homepage .frame-34 {
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
  position: absolute;
  top: 977px;
  left: -2px;
}

.homepage .rectangle-14 {
  width: 392px;
  height: 200px;
  position: relative;
}

.homepage .frame-35 {
  align-items: flex-start;
  gap: 61px;
  height: 92px;
  display: inline-flex;
  position: absolute;
  top: 54px;
  left: 93px;
}

.homepage .frame-36 {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 2086px;
  left: 0;
}

.homepage .card {
  background-color: #fafafa;
  flex-direction: column;
  align-items: center;
  gap: 26px;
  width: 390px;
  height: 490.75px;
  padding: 0 0 45.5px;
  display: flex;
  position: relative;
}

.homepage .deepmind {
  object-fit: cover;
  align-self: stretch;
  width: 100%;
  height: 260px;
  position: relative;
}

.homepage .frame-37 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 26px;
  width: 100%;
  margin-bottom: -13.75px;
  padding: 0 65px 0 45.5px;
  display: flex;
  position: relative;
}

.homepage .frame-38 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 13px;
  width: 100%;
  display: flex;
  position: relative;
}

.homepage .text-wrapper-24 {
  color: var(--neutral-70);
  letter-spacing: -.42px;
  align-self: stretch;
  margin-top: -.81px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 28px;
  font-weight: 400;
  line-height: 33.6px;
  position: relative;
}

.homepage .text-wrapper-25 {
  color: #797979;
  letter-spacing: -.16px;
  align-self: stretch;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16.2px;
  font-weight: 400;
  line-height: 22.7px;
  position: relative;
}

.homepage .card-2 {
  background-color: #fafafa;
  flex-direction: column;
  align-items: flex-start;
  gap: 26px;
  width: 390px;
  height: 490.75px;
  padding: 0 0 45.5px;
  display: flex;
  position: relative;
}

.homepage .deepmind-hereyczt {
  align-self: stretch;
  width: 100%;
  height: 260px;
  position: relative;
}

.homepage .searching-for-an {
  color: #6c6c6c;
  letter-spacing: -.16px;
  align-self: stretch;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16.2px;
  font-weight: 400;
  line-height: 22.7px;
  position: relative;
}

.homepage .frame-39 {
  background-image: url("deepmind-8hereyc6zt0-unsplash-1-1.00ebea56.png");
  background-position: 50%;
  background-size: cover;
  width: 390px;
  height: 260px;
  position: relative;
}

.homepage .frame-40 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 26px;
  width: 100%;
  margin-bottom: -13.75px;
  padding: 0 45.5px;
  display: flex;
  position: relative;
}

.homepage .frame-41 {
  flex-direction: column;
  align-items: center;
  gap: 33px;
  width: 324px;
  display: flex;
  position: absolute;
  top: 3636px;
  left: 33px;
}

.homepage .frame-42 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.homepage .text-wrapper-26 {
  color: var(--neutral-90);
  letter-spacing: -.8px;
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.4px;
  position: relative;
}

.homepage .text-wrapper-27 {
  color: var(--neutral-60);
  letter-spacing: -.16px;
  text-align: center;
  align-self: stretch;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  position: relative;
}

.homepage .CTA-instance {
  background-color: var(--primary-02) !important;
  border: unset !important;
  border-color: unset !important;
  flex: none !important;
  justify-content: center !important;
  align-items: flex-end !important;
  width: 182px !important;
  display: flex !important;
}

.homepage .CTA-4 {
  font-family: var(--subtitle-BLD-font-family) !important;
  font-size: var(--subtitle-BLD-font-size) !important;
  font-style: var(--subtitle-BLD-font-style) !important;
  font-weight: var(--subtitle-BLD-font-weight) !important;
  letter-spacing: var(--subtitle-BLD-letter-spacing) !important;
  line-height: var(--subtitle-BLD-line-height) !important;
  margin-left: -1px !important;
}

.homepage .CTA-5 {
  margin-right: -1px !important;
}

.homepage .text-wrapper-28 {
  color: var(--neutral-90);
  letter-spacing: -.8px;
  text-align: center;
  width: 311px;
  height: 76px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.4px;
  position: absolute;
  top: 5871px;
  left: 39px;
}

.homepage .we-help-you-recruit {
  color: var(--neutral-60);
  letter-spacing: -.16px;
  text-align: center;
  width: 303px;
  height: 44px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  position: absolute;
  top: 5971px;
  left: 43px;
}

.homepage .frame-43 {
  background-color: #f1f1f1;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 390px;
  height: 701px;
  padding: 31px 0;
  display: flex;
  position: absolute;
  top: 6062px;
  left: 0;
  overflow: hidden;
}

.homepage .frame-44 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 24px;
  width: 365px;
  display: flex;
  position: relative;
}

.homepage .text-wrapper-29 {
  color: #4d9f98;
  letter-spacing: -.6px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
  position: relative;
}

.homepage .india-s-first-of-its {
  color: var(--neutral-90);
  letter-spacing: -.8px;
  align-self: stretch;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.4px;
  position: relative;
}

.homepage .frame-45 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  display: flex;
  position: relative;
}

.homepage .text-wrapper-30 {
  color: var(--neutral-80);
  letter-spacing: -.16px;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  position: relative;
}

.homepage .CTA-6 {
  border: unset !important;
  border-color: unset !important;
  background-color: #4d9f98 !important;
  flex: none !important;
}

.homepage .CTA-7 {
  font-family: var(--subtitle-BLD-font-family) !important;
  font-size: var(--subtitle-BLD-font-size) !important;
  font-style: var(--subtitle-BLD-font-style) !important;
  font-weight: var(--subtitle-BLD-font-weight) !important;
  letter-spacing: var(--subtitle-BLD-letter-spacing) !important;
  line-height: var(--subtitle-BLD-line-height) !important;
}

.homepage .overlap-group-wrapper {
  width: 392px;
  height: 309px;
  margin-bottom: -31px;
  margin-left: -1px;
  margin-right: -1px;
  position: relative;
}

.homepage .smiley-businesswoman-wrapper {
  background-image: url("mask-group-2.3222cfb0.png");
  background-size: 100% 100%;
  width: 390px;
  height: 303px;
  position: relative;
  top: -16px;
  left: 1px;
}

.homepage .smiley-businesswoman {
  width: 194px;
  height: 252px;
  position: absolute;
  top: 52px;
  left: 116px;
}

.homepage .frame-46 {
  background: linear-gradient(#000c 0%, #0000 100%);
  width: 1449px;
  height: 752px;
  position: absolute;
  top: 0;
  left: -9px;
}

.homepage .video {
  width: 1449px;
  height: 752px;
  position: absolute;
  top: 0;
}

.homepage .video:after {
  content: "";
  background: #00000029;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.homepage .header-2 {
  background-color: #0000;
  justify-content: space-between;
  align-items: center;
  width: 1440px;
  padding: 16px 80px;
  display: flex;
  position: absolute;
  top: 0;
  left: 9px;
}

.homepage .logo {
  object-fit: cover;
  width: 145px;
  height: 67.67px;
  position: relative;
}

.homepage .holder {
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.homepage .frame-47 {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 90.33px;
  padding: 10px 8px;
  display: flex;
  position: relative;
}

.homepage .text-wrapper-31 {
  color: #fff;
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.homepage .text-wrapper-32 {
  color: #fff;
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-left: -2.83px;
  margin-right: -2.83px;
  position: relative;
}

.homepage .frame-48 {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 80px;
  padding: 10px 8px;
  display: flex;
  position: relative;
}

.homepage .CTA-8 {
  background-color: #fff !important;
  border-color: #4d9f98 !important;
  width: 179px !important;
  height: 69px !important;
  display: flex !important;
}

.homepage .CTA-9 {
  color: #280a4e !important;
  margin-top: unset !important;
}

.homepage .frame-49 {
  flex-direction: column;
  align-items: center;
  gap: 37px;
  display: inline-flex;
  position: absolute;
  top: 249px;
  left: 321px;
}

.homepage .frame-50 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 49px;
  display: inline-flex;
  position: relative;
}

.homepage .frame-51 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 40px;
  display: inline-flex;
  position: relative;
}

.homepage .accelerating-your-2 {
  color: #0000;
  letter-spacing: -1.92px;
  text-align: center;
  width: 755px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 64px;
  font-weight: 600;
  line-height: 65px;
  position: relative;
}

.homepage .frame-52 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 763px;
  display: flex;
  position: relative;
}

.homepage .scale-faster-with-2 {
  color: #fff;
  letter-spacing: -.2px;
  text-align: center;
  width: 675px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  position: relative;
}

.homepage .frame-53 {
  position: absolute;
}

.homepage .card-3 {
  background-color: #fafafa;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 480px;
  height: 604px;
  padding: 0 0 56px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.homepage .deepmind-2 {
  object-fit: cover;
  align-self: stretch;
  width: 100%;
  height: 320px;
  position: relative;
}

.homepage .frame-54 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 32px;
  width: 100%;
  margin-bottom: -16px;
  padding: 0 80px 0 56px;
  display: flex;
  position: relative;
}

.homepage .text-wrapper-33 {
  color: var(--neutral-70);
  letter-spacing: -.53px;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 400;
  line-height: 42px;
  position: relative;
}

.homepage .text-wrapper-34 {
  color: #797979;
  letter-spacing: -.2px;
  align-self: stretch;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.homepage .card-4 {
  background-color: #fafafa;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  width: 480px;
  height: 604px;
  padding: 0 0 56px;
  display: flex;
  position: absolute;
  top: 0;
  left: 960px;
}

.homepage .frame-55 {
  background-image: url("deepmind-8hereyc6zt0-unsplash-1-1.00ebea56.png");
  background-position: 50%;
  background-size: cover;
  width: 480px;
  height: 320px;
  position: relative;
}

.homepage .frame-56 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 32px;
  width: 100%;
  margin-bottom: -16px;
  padding: 0 56px;
  display: flex;
  position: relative;
}

.homepage .card-5 {
  background-color: #fafafa;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  width: 480px;
  height: 604px;
  padding: 0 0 56px;
  display: flex;
  position: absolute;
  top: 0;
  left: 480px;
}

.homepage .deepmind-3 {
  align-self: stretch;
  width: 100%;
  height: 320px;
  position: relative;
}

.homepage .searching-for-an-2 {
  color: #6c6c6c;
  letter-spacing: -.2px;
  align-self: stretch;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.homepage .group-wrapper {
  width: 360px;
  height: 387px;
  position: absolute;
  top: 325px;
  left: 5px;
}

.homepage .group-3 {
  height: 391px;
  position: relative;
  top: -5px;
}

.homepage .overlap-group-4 {
  width: 361px;
  height: 392px;
  position: relative;
}

.homepage .vector {
  width: 323px;
  height: 297px;
  position: absolute;
  top: 95px;
  left: 0;
}

.homepage .vector-2 {
  width: 316px;
  height: 291px;
  position: absolute;
  top: 86px;
  left: 7px;
}

.homepage .vector-3 {
  width: 343px;
  height: 315px;
  position: absolute;
  top: 53px;
  left: 17px;
}

.homepage .mask-group {
  width: 316px;
  height: 377px;
  position: absolute;
  top: 0;
  left: 7px;
}

.homepage .frame-57 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 364px;
  height: 383px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.homepage .one-stop-solution {
  color: var(--neutral-90);
  letter-spacing: -.8px;
  width: 364px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.4px;
  position: relative;
}

.homepage .frame-58 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.homepage .the-offerletter {
  color: var(--neutral-80);
  letter-spacing: -.16px;
  width: 351px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  position: relative;
}

.homepage .overlap-wrapper-home {
  width: 1440px;
  height: 356px;
  position: absolute;
  top: 5961px;
  left: 2px;
}

.homepage .overlap-4 {
  height: 356px;
  position: relative;
}

.homepage .section-industries {
  background-color: #f0f0f0;
  width: 1440px;
  height: 356px;
  position: absolute;
  top: 0;
  left: 0;
}

.homepage .frame-59 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: relative;
  top: 45px;
  left: 80px;
}

.homepage .frame-60 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: relative;
}

.homepage .text-wrapper-35 {
  color: #000;
  font-family: var(--h3-REG-font-family);
  font-size: var(--h3-REG-font-size);
  font-style: var(--h3-REG-font-style);
  font-weight: var(--h3-REG-font-weight);
  letter-spacing: var(--h3-REG-letter-spacing);
  line-height: var(--h3-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.homepage .are-we-a-good-fit {
  color: #000;
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  width: 326px;
  position: relative;
}

.homepage .CTA-wrapper {
  all: unset;
  box-sizing: border-box;
  flex: none;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.homepage .this-is-who-we-are-wrapper {
  all: unset;
  background-color: var(--primary-02);
  box-sizing: border-box;
  border-radius: 56px;
  justify-content: center;
  align-items: flex-end;
  gap: 10px;
  width: 182px;
  padding: 12px 16px;
  display: flex;
  position: relative;
}

.homepage .this-is-who-we-are-2 {
  color: #fff;
  font-family: var(--subtitle-BLD-font-family);
  font-size: var(--subtitle-BLD-font-size);
  font-style: var(--subtitle-BLD-font-style);
  font-weight: var(--subtitle-BLD-font-weight);
  letter-spacing: var(--subtitle-BLD-letter-spacing);
  line-height: var(--subtitle-BLD-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.homepage .container {
  align-items: center;
  gap: 24px;
  width: 922px;
  height: 237px;
  display: flex;
  position: absolute;
  top: 59px;
  left: 487px;
}

.homepage .frame-61 {
  flex: none;
  align-items: flex-start;
  gap: 24px;
  margin-top: -57.5px;
  margin-bottom: -57.5px;
  display: inline-flex;
  position: relative;
}

.homepage .BFSI {
  background-image: url("rectangle-24.2014435c.webp");
  background-size: 100% 100%;
  width: 264px;
  height: 240px;
  position: relative;
}

.homepage .text-wrapper-36 {
  color: #fff;
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  white-space: nowrap;
  height: 34px;
  position: absolute;
  top: 179px;
  left: 27px;
}

.homepage .IT-services {
  background: url("image-1.72e73d02.webp") 0 0 / 100% 100%;
  width: 263px;
  height: 240px;
  position: relative;
}

.homepage .telecom {
  background-image: url("rectangle-25.3ddf5fc3.webp");
  background-size: 100% 100%;
  width: 263px;
  height: 240px;
  position: relative;
}

.homepage .telecom-2 {
  background-image: url("rectangle-25-1.740fe525.webp");
  background-size: 100% 100%;
  width: 263px;
  height: 240px;
  position: relative;
}

.homepage .overlap-group-5 {
  width: 79px;
  height: 34px;
  position: relative;
  top: 179px;
  left: 27px;
}

.homepage .text-wrapper-37 {
  color: #fff;
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  white-space: nowrap;
  height: 34px;
  position: absolute;
  top: 0;
  left: 0;
}

.homepage .IT-services-2 {
  background-image: url("rectangle-23.69c6a4d4.webp");
  background-position: 50%;
  background-size: cover;
  width: 263px;
  height: 240px;
  position: relative;
}

.homepage .telecom-3 {
  background-image: url("rectangle-25-2.60d7a48a.webp");
  background-position: 50%;
  background-size: cover;
  width: 263px;
  height: 240px;
  position: relative;
}

.homepage .telecom-4 {
  background-image: url("rectangle-25-3.09a31b27.webp");
  background-position: 50%;
  background-size: cover;
  width: 263px;
  height: 240px;
  position: relative;
}

.homepage .arrow {
  background-color: #fff;
  border-radius: 30px;
  width: 60px;
  height: 60px;
  position: absolute;
  top: 148px;
  left: 1350px;
  box-shadow: -3px 1px 16px #00000040;
}

.homepage .arrow-forward-ios {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 18px;
  left: 18px;
}

.homepage .frame-62 {
  background-color: #fff;
  border: 1px solid #8b8b8b;
  border-radius: 22px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 1280px;
  padding: 50px 147px 50px 104px;
  display: flex;
  position: absolute;
  top: 6406px;
  left: 82px;
  box-shadow: 0 4px 14px #00000040;
}

.homepage .frame-63 {
  flex: none;
  align-items: center;
  gap: 203px;
  margin-right: -47px;
  display: inline-flex;
  position: relative;
}

.homepage .frame-64 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 14px;
  display: inline-flex;
  position: relative;
}

.homepage .text-wrapper-38 {
  color: var(--secondary-04);
  letter-spacing: -1.05px;
  width: 530px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 42px;
  font-weight: 400;
  line-height: 50.4px;
  position: relative;
}

.homepage .you-are-just-one {
  color: #363636;
  letter-spacing: -.2px;
  width: 638px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.homepage .CTA-10 {
  cursor: pointer;
  box-sizing: border-box;
  background-color: #05464c;
  border: none;
  border-radius: 56px;
  outline: none;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 235px;
  height: 58px;
  padding: 12px 16px;
  display: flex;
  position: relative;
}

.homepage .text-wrapper-39 {
  color: #fff;
  font-family: var(--subtitle-BLD-font-family);
  font-size: var(--subtitle-BLD-font-size);
  font-style: var(--subtitle-BLD-font-style);
  font-weight: var(--subtitle-BLD-font-weight);
  letter-spacing: var(--subtitle-BLD-letter-spacing);
  line-height: var(--subtitle-BLD-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.homepage .carousel-111 {
  width: 1440px;
  position: absolute;
  top: 5381px;
  left: 0;
}

.homepage .property-default-instance {
  position: absolute !important;
  top: 5381px !important;
  left: 0 !important;
}

.homepage .property-1-default-instance {
  top: -5369px !important;
  left: -824px !important;
}

.homepage .carousel-2 {
  margin-top: -5741px !important;
  margin-left: -1158px !important;
}

.homepage .carousel-3 {
  margin-top: -5764px !important;
  margin-left: -2598px !important;
}

.homepage .group-4 {
  width: 1440px;
  height: 1415px;
  position: absolute;
  top: 3935px;
  left: 0;
}

.homepage .frame-65 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 392px;
}

.homepage .frame-66 {
  width: 670px;
  height: 90px;
  position: relative;
}

.homepage .text-wrapper-40 {
  color: var(--neutral-90);
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  text-align: center;
  white-space: nowrap;
  height: 50px;
  position: absolute;
  top: -1px;
  left: 0;
}

.homepage .we-help-you-recruit-2 {
  color: var(--neutral-60);
  letter-spacing: -.17px;
  text-align: center;
  width: 497px;
  height: 24px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 400;
  line-height: 23.8px;
  position: absolute;
  top: 65px;
  left: 86px;
}

.homepage .group-5 {
  width: 1440px;
  height: 558px;
  position: absolute;
  top: 147px;
  left: 0;
}

.homepage .overlap-5 {
  height: 558px;
  position: relative;
}

.homepage .frame-67 {
  width: 1440px;
  height: 558px;
  position: absolute;
  top: 0;
  left: 0;
}

.homepage .overlap-group-6 {
  background-color: #f1f1f1;
  width: 1438px;
  height: 558px;
  position: relative;
}

.homepage .mask-group-2 {
  width: 690px;
  height: 558px;
  position: absolute;
  top: 0;
  left: 748px;
}

.homepage .smiley-businesswoman-2 {
  width: 382px;
  height: 489px;
  position: absolute;
  top: 69px;
  left: 935px;
}

.homepage .frame-68 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 487px;
  display: flex;
  position: absolute;
  top: 94px;
  left: 80px;
}

.homepage .text-wrapper-41 {
  color: var(--neutral-90);
  letter-spacing: -1.05px;
  width: 486px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 42px;
  font-weight: 400;
  line-height: 50.4px;
  position: relative;
}

.homepage .text-wrapper-42 {
  color: var(--neutral-80);
  letter-spacing: -.17px;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 400;
  line-height: 23.8px;
  position: relative;
}

.homepage .frame-69 {
  width: 1179px;
  height: 676px;
  position: absolute;
  top: 739px;
  left: 147px;
}

.homepage .frame-70 {
  width: 597px;
  height: 641px;
  position: absolute;
  top: 0;
  left: 0;
}

.homepage .group-6 {
  height: 649px;
  position: relative;
  top: -8px;
}

.homepage .overlap-group-7 {
  width: 598px;
  height: 650px;
  position: relative;
}

.homepage .vector-4 {
  width: 536px;
  height: 492px;
  position: absolute;
  top: 158px;
  left: 0;
}

.homepage .vector-5 {
  width: 524px;
  height: 482px;
  position: absolute;
  top: 143px;
  left: 11px;
}

.homepage .vector-6 {
  width: 569px;
  height: 522px;
  position: absolute;
  top: 88px;
  left: 29px;
}

.homepage .mask-group-3 {
  width: 524px;
  height: 625px;
  position: absolute;
  top: 0;
  left: 11px;
}

.homepage .frame-71 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  height: 383px;
  display: inline-flex;
  position: absolute;
  top: 129px;
  left: 693px;
}

.homepage .the-offerletter-2 {
  color: var(--neutral-80);
  letter-spacing: -.17px;
  width: 470px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 400;
  line-height: 23.8px;
  position: relative;
}

.homepage .frame-72 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  height: 383px;
  display: inline-flex;
  position: absolute;
  top: 7006px;
  left: 564px;
}

.homepage .CTA-11 {
  margin-top: -7290px !important;
  margin-left: -1731px !important;
}

.homepage .carousel-4 {
  width: 390px;
  position: absolute !important;
  top: 7564px !important;
  left: 0 !important;
}

.homepage .carousel-5 {
  top: -7552px !important;
  left: -2326px !important;
}

.homepage .carousel-6 {
  margin-top: -7924px !important;
  margin-left: -2660px !important;
}

.homepage .carousel-7 {
  margin-top: -7947px !important;
  margin-left: -4100px !important;
}

.homepage .frame-73 {
  flex-direction: column;
  align-items: center;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 8187px;
  left: 38px;
}

.homepage .frame-74 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 12px;
  display: inline-flex;
  position: relative;
}

.homepage .text-wrapper-43 {
  color: #000;
  font-family: var(--h3-REG-font-family);
  font-size: var(--h3-REG-font-size);
  font-style: var(--h3-REG-font-style);
  font-weight: var(--h3-REG-font-weight);
  letter-spacing: var(--h3-REG-letter-spacing);
  line-height: var(--h3-REG-line-height);
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.homepage .are-we-a-good-fit-2 {
  color: #000;
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  text-align: center;
  width: 326px;
  position: relative;
}

.homepage .container-2 {
  align-items: center;
  gap: 24px;
  width: 838px;
  height: 237px;
  padding: 56px 272px 56px 0;
  display: flex;
  position: absolute;
  top: 8483px;
  left: 18px;
  overflow: hidden;
}

.homepage .gics-gccs-wrapper {
  background-image: url("rectangle-24-1.2014435c.webp");
  background-size: 100% 100%;
  width: 264px;
  height: 240px;
  position: relative;
}

.homepage .arrow-forward-ios-wrapper {
  background-color: #fff;
  border: none;
  border-radius: 23px;
  outline: none;
  width: 46px;
  height: 46px;
  position: absolute;
  top: 95px;
  left: 300px;
}

.homepage .arrow-forward-ios-2 {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 14px;
  left: 14px;
}

.homepage .frame-75 {
  background-color: #fff;
  border: 1px solid #8b8b8b;
  border-radius: 22px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 352px;
  padding: 50px 30px;
  display: flex;
  position: absolute;
  top: 8763px;
  left: 19px;
  box-shadow: 0 4px 14px #00000040;
}

.homepage .frame-76 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 50px;
  width: 287px;
  display: flex;
  position: relative;
}

.homepage .text-wrapper-44 {
  color: var(--secondary-04);
  letter-spacing: -.8px;
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.4px;
  position: relative;
}

.homepage .you-are-just-one-2 {
  color: #363636;
  letter-spacing: -.2px;
  text-align: center;
  align-self: stretch;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.CTA .this-is-who-we-are {
  color: #fff;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: relative;
}

.CTA.default {
  cursor: pointer;
  border: 1px solid #fff;
  gap: 10px;
  transition: all .3s ease-in-out;
}

.CTA.default:hover {
  background-color: #4d9f98 !important;
  border-width: 0 !important;
  gap: 8px !important;
}

.property-default {
  background-image: url("property-1-default.75631a8c.webp");
  background-position: 50%;
  background-size: cover;
  width: 720px;
  height: 320px;
}

.property-default .overlap {
  height: 320px;
  position: relative;
}

.property-default .overlap-group-wrapper {
  background: url("frame-3792-1.4d89ba70.webp") 50% / cover;
  width: 720px;
  height: 320px;
  position: absolute;
  top: 0;
  left: 0;
}

.property-default .overlap-group {
  background-image: url("shutterstock-1485872414-1-8.53b30b61.webp");
  background-position: 50%;
  background-size: cover;
  height: 320px;
  position: relative;
}

.property-default .rectangle-5 {
  background-blend-mode: multiply;
  width: 720px;
  height: 320px;
  position: absolute;
  top: 0;
  left: 0;
}

.property-default .frame-9 {
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  height: 132px;
  display: inline-flex;
  position: absolute;
  top: 34px;
  left: 64px;
}

.property-default .div-wrapper {
  flex-direction: column;
  align-items: flex-start;
  gap: 35px;
  height: 52px;
  display: inline-flex;
  position: relative;
}

.property-default .text-wrapper-3 {
  color: #fff;
  letter-spacing: -1.05px;
  white-space: nowrap;
  width: 477px;
  height: 52px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 42px;
  font-weight: 600;
  line-height: 50.4px;
  position: relative;
}

.property-default .text-wrapper-3-p {
  color: var(--neutral-0, #fff);
  letter-spacing: -.17px;
  width: 477px;
  font-family: Source Sans Pro;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.property-default .design-component-instance-node {
  flex: none !important;
}

.property-default-wrapper-home {
  background-color: #2e5053;
}

.property-default-wrapper-home .overlap-2 {
  height: 392px;
  position: relative;
}

.property-default-wrapper-home .frame-10 {
  width: 720px;
  height: 320px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(180deg);
}

.property-default-wrapper-home .overlap-group-2 {
  height: 320px;
  position: relative;
}

.property-default-wrapper-home .shutterstock-2 {
  object-fit: cover;
  width: 720px;
  height: 320px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
}

.property-default-wrapper-home .rectangle-6 {
  background-blend-mode: multiply;
  background: linear-gradient(#0000 0%, #2c2c2c 100%);
  width: 720px;
  height: 320px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(180deg);
}

.property-default-wrapper-home .frame-11 {
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  height: 298px;
  display: inline-flex;
  position: absolute;
  top: 20px;
  left: 64px;
}

.property-default-wrapper-home .hire-train-deploy-wrapper {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  height: 208px;
  display: inline-flex;
  position: relative;
}

.property-default-wrapper-home .hire-train-deploy-3 {
  color: #fff;
  letter-spacing: -1.05px;
  flex: 1;
  width: 477px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 42px;
  font-weight: 600;
  line-height: 50.4px;
  position: relative;
}

.property-default-wrapper-home .hire-train-deploy-text {
  color: var(--neutral-0, #fff);
  letter-spacing: -.2px;
  width: 477px;
  font-family: Source Sans Pro;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.property-default-wrapper-home .CTA-3 {
  flex: none !important;
}

.service-homepage {
  width: 720px;
}

.service-homepage .frame {
  width: 720px;
  position: absolute;
  top: 0;
  left: 0;
}

.service-homepage .rectangle {
  background-blend-mode: multiply;
  background: linear-gradient(#0000 0%, #040608 100%);
  width: 720px;
  height: 398px;
  position: relative;
  top: 250px;
}

.service-homepage .shutterstock {
  object-fit: cover;
  width: 720px;
  position: absolute;
  top: 0;
  left: 0;
}

.service-homepage .div {
  background-blend-mode: multiply;
  width: 720px;
  position: absolute;
  left: 0;
}

.service-homepage .rectangle-2 {
  background-blend-mode: darken;
  background: linear-gradient(#0000 0%, #000c 100%);
  width: 720px;
  height: 322px;
  position: absolute;
  top: 0;
  left: 0;
}

.service-homepage .consulting-for {
  color: #fff;
  letter-spacing: -1.05px;
  width: 476px;
  height: 100px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 42px;
  font-weight: 600;
  line-height: 50.4px;
  position: absolute;
  top: 116px;
  left: 180px;
  transform: rotate(180deg);
}

.service-homepage .homepage {
  object-fit: cover;
  width: 720px;
  height: 320px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
}

.service-homepage .frame-2 {
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  left: 64px;
}

.service-homepage .frame-3, .service-homepage .hire-train-deploy {
  position: relative;
}

.service-homepage .master-service-homepage {
  color: #fff;
  letter-spacing: -1.05px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-right: -6px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 42px;
  font-weight: 600;
  line-height: 50.4px;
  position: relative;
}

.service-homepage .flexible-staffing {
  color: #fff;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 42px;
  font-weight: 600;
  position: relative;
}

.service-homepage .joulestowatts {
  color: #fff;
  letter-spacing: -.2px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.service-homepage .CTA-instance {
  background-color: #0000 !important;
  border: 1px solid #fff !important;
  flex: none !important;
}

.service-homepage .rectangle-3 {
  background-blend-mode: multiply;
  width: 720px;
  height: 320px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
}

.service-homepage .frame-4 {
  flex-direction: column;
  display: inline-flex;
}

.service-homepage .text-wrapper {
  color: #fff;
  letter-spacing: -1.05px;
  width: 476px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 42px;
  font-weight: 600;
  line-height: 50.4px;
  position: relative;
  transform: rotate(180deg);
}

.service-homepage .we-at-joulestowatts {
  color: #fff;
  letter-spacing: -.2px;
  width: 450px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
  transform: rotate(180deg);
}

.service-homepage .CTA-wrapper {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  width: 397px;
  display: flex;
  position: relative;
  transform: rotate(180deg);
}

.service-homepage .instance-node {
  transform: rotate(-360deg) !important;
}

.service-homepage .frame-5 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: relative;
}

.service-homepage .master-service-homepage-wrapper {
  flex: none;
  align-items: center;
  gap: 16px;
  width: 397px;
  display: flex;
  position: relative;
}

.service-homepage .p {
  color: #fff;
  letter-spacing: -.17px;
  width: 476px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 400;
  line-height: 23.8px;
  position: relative;
}

.service-homepage .frame-6 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 35px;
  display: inline-flex;
  position: relative;
}

.service-homepage .hire-train-deploy-2 {
  color: #fff;
  letter-spacing: -1.05px;
  white-space: nowrap;
  flex: 1;
  width: 477px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 42px;
  font-weight: 600;
  line-height: 50.4px;
  position: relative;
}

.service-homepage .with-over-global {
  color: #fff;
  letter-spacing: -.2px;
  width: 477px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.service-homepage .CTA-2 {
  flex: none !important;
  margin-bottom: -7px !important;
}

.service-homepage .frame-7 {
  background-image: url("shutterstock-1485872414-1-11.972cb247.webp");
  background-size: 100% 100%;
  width: 720px;
  height: 318px;
  position: absolute;
  top: 0;
  left: 0;
}

.service-homepage .rectangle-4 {
  background-blend-mode: darken;
  background: linear-gradient(#0000 0%, #000c 100%);
  width: 720px;
  height: 222px;
  position: absolute;
  top: 98px;
  left: 0;
  transform: rotate(180deg);
}

.service-homepage .frame-8 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  width: 397px;
  display: flex;
  position: absolute;
  top: 143px;
  left: 64px;
}

.service-homepage .text-wrapper-2 {
  color: #fff;
  letter-spacing: -1.05px;
  width: 286px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 42px;
  font-weight: 600;
  line-height: 50.4px;
  position: relative;
}

.service-homepage.five {
  background-color: #2e5053;
  height: 320px;
}

.service-homepage.three {
  height: 320px;
}

.service-homepage.one {
  height: 648px;
}

.service-homepage.state-default {
  background-color: #2e5053;
}

.service-homepage.two {
  height: 320px;
}

.service-homepage.six {
  background-color: #2e5053;
  height: 320px;
}

.service-homepage.four {
  background-color: #2e5053;
  height: 648px;
}

.service-homepage.state-hover.six {
  overflow: hidden;
}

.service-homepage.state-hover.one {
  background-color: #df0000b8;
}

.service-homepage.five .overlap-3, .service-homepage.three .overlap-3 {
  height: 320px;
  position: relative;
}

.service-homepage.state-default .overlap-3 {
  position: relative;
}

.service-homepage.two .overlap-3 {
  height: 320px;
  position: relative;
}

.service-homepage.six .overlap-3 {
  height: 322px;
}

.service-homepage.four .overlap-3 {
  height: 648px;
}

.service-homepage.state-hover.four .overlap-3 {
  background-image: url("shutterstock-1485872414-1-19.24f51c62.webp");
  background-position: 50%;
  background-size: cover;
}

.service-homepage.state-default.one .overlap-3 {
  height: 646px;
}

.service-homepage.state-hover.one .overlap-3 {
  background-image: url("shutterstock-1485872414-1-10.4a64e16b.png");
  background-position: 50%;
  background-size: cover;
  height: 648px;
}

.service-homepage.state-default.six .overlap-3 {
  top: -2px;
}

.service-homepage.five .frame {
  background-image: url("shutterstock-1485872414-1-16.e24ef284.webp");
  background-size: 100% 100%;
  height: 320px;
}

.service-homepage.three .frame {
  background: linear-gradient(#90909000 5.66%, #5252528f 80.22%, #969696e0 129.06%), linear-gradient(#0000 41.15%, #0000008f 90.06%, #000000e0 149.06%), url("shutterstock_1485872414_1.91c11414.webp"), #d3d3d3 50% / cover no-repeat;
  height: 320px;
}

.service-homepage.one .frame {
  height: 646px;
}

.service-homepage.two .frame {
  background-position: 50%;
  background-size: cover;
  height: 320px;
}

.service-homepage.six .frame {
  height: 322px;
  transform: rotate(180deg);
}

.service-homepage.four .frame {
  height: 648px;
}

.service-homepage.state-default.two .frame {
  background-image: url("shutterstock-1485872414-1-8.53b30b61.webp");
}

.service-homepage.state-hover.two .frame {
  background-image: url("shutterstock-1485872414-1-14.aad624b2.webp");
}

.service-homepage.five .overlap-group-3 {
  background-blend-mode: darken;
  background: linear-gradient(#0000 0%, #000c 125.47%);
  height: 320px;
}

.service-homepage.three .overlap-group-3 {
  height: 320px;
  position: relative;
}

.service-homepage.one .overlap-group-3 {
  background-image: url("shutterstock-1485872414-1.db332289.webp");
  background-position: 50%;
  background-size: cover;
  height: 648px;
}

.service-homepage.two .overlap-group-3 {
  background-blend-mode: multiply;
  height: 320px;
}

.service-homepage.six .overlap-group-3 {
  height: 322px;
  position: relative;
}

.service-homepage.four .overlap-group-3 {
  height: 648px;
  position: relative;
}

.service-homepage.state-default.two .overlap-group-3 {
  background: linear-gradient(#0000 0%, #939393 100%);
}

.service-homepage.state-hover.two .overlap-group-3 {
  background-blend-mode: multiply;
  background: linear-gradient(#0000 0%, #000 100%);
}

.service-homepage.five .shutterstock {
  height: 320px;
}

.service-homepage.three .shutterstock {
  height: 320px;
  transform: rotate(-180deg);
}

.service-homepage.one .shutterstock, .service-homepage.two .shutterstock {
  height: 320px;
}

.service-homepage.six .shutterstock {
  height: 320px;
  transform: rotate(-180deg);
}

.service-homepage.four .shutterstock {
  height: 646px;
}

.service-homepage.five .div {
  background: linear-gradient(#0000 0%, #939393 100%);
  height: 494px;
  top: 154px;
}

.service-homepage.three .div {
  background: linear-gradient(#0000 0%, #2c2c2c 100%);
  height: 320px;
  top: 0;
  transform: rotate(180deg);
}

.service-homepage.one .div, .service-homepage.two .div, .service-homepage.six .div, .service-homepage.four .div {
  background: linear-gradient(#0000 0%, #939393 100%);
  height: 494px;
  top: 154px;
}

.service-homepage.five .frame-2 {
  justify-content: center;
  gap: 17px;
  width: 397px;
  display: flex;
  top: 52px;
}

.service-homepage.three .frame-2 {
  justify-content: center;
  width: 397px;
  display: flex;
}

.service-homepage.one .frame-2 {
  gap: 32px;
  height: 298px;
  display: inline-flex;
  top: 286px;
}

.service-homepage.two .frame-2 {
  justify-content: center;
  width: 407px;
  display: flex;
}

.service-homepage.six .frame-2 {
  justify-content: center;
  gap: 24px;
  width: 397px;
  display: flex;
  top: 225px;
}

.service-homepage.four .frame-2 {
  gap: 32px;
  display: inline-flex;
  top: 341px;
}

.service-homepage.state-default.three .frame-2, .service-homepage.state-default.two .frame-2 {
  gap: 24px;
  top: 94px;
}

.service-homepage.state-hover.two .frame-2 {
  gap: 15px;
  top: 41px;
}

.service-homepage.state-hover.three .frame-2 {
  gap: 15px;
  top: 38px;
}

.service-homepage.three .frame-3 {
  color: #fff;
  letter-spacing: -1.05px;
  width: 397px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 42px;
  font-weight: 600;
  line-height: 50.4px;
}

.service-homepage.one .frame-3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 35px;
  height: 80px;
  display: inline-flex;
}

.service-homepage.two .frame-3 {
  color: #fff;
  letter-spacing: -.63px;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 42px;
  font-weight: 600;
  line-height: 48.7px;
}

.service-homepage.four .frame-3 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
}

.service-homepage.five .hire-train-deploy, .service-homepage.three .hire-train-deploy {
  flex: 1;
  width: 477px;
}

.service-homepage.one .hire-train-deploy {
  color: #fff;
  letter-spacing: -1.05px;
  flex: 1;
  width: 477px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 42px;
  font-weight: 600;
  line-height: 50.4px;
}

.service-homepage.two .hire-train-deploy, .service-homepage.six .hire-train-deploy {
  flex: 1;
  width: 477px;
}

.service-homepage.four .hire-train-deploy {
  flex: none;
  align-items: center;
  gap: 16px;
  width: 397px;
  display: flex;
}

.service-homepage.five .flexible-staffing {
  letter-spacing: -1.05px;
  width: 286px;
  line-height: 50.4px;
}

.service-homepage.three .flexible-staffing {
  letter-spacing: -1.05px;
  width: 429px;
  margin-right: -32px;
  line-height: 50.4px;
}

.service-homepage.one .flexible-staffing {
  letter-spacing: -1.05px;
  line-height: 50.4px;
}

.service-homepage.two .flexible-staffing {
  letter-spacing: -.63px;
  align-self: stretch;
  line-height: 48.7px;
}

.service-homepage.six .flexible-staffing, .service-homepage.four .flexible-staffing {
  letter-spacing: -1.05px;
  line-height: 50.4px;
}

.service-homepage.five .joulestowatts {
  width: 496px;
  margin-right: -99px;
}

.service-homepage.three .joulestowatts {
  width: 492px;
  margin-right: -95px;
}

.service-homepage.one .joulestowatts {
  width: 496px;
  margin-right: -99px;
}

.service-homepage.two .joulestowatts {
  width: 467px;
  margin-right: -70px;
}

.service-homepage.six .joulestowatts, .service-homepage.four .joulestowatts {
  width: 496px;
  margin-right: -99px;
}

.service-homepage.three .overlap-group-3 {
  height: 648px;
}

.service-homepage.one .overlap-group-3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  height: 298px;
  display: inline-flex;
  position: relative;
  top: 294px;
  left: 62px;
}

.service-homepage.six .overlap-group-3 {
  background: linear-gradient(#0000, #000 90.06% 149.06%), url("shutterstock-1485872414-1-13.ff44030e.webp"), #000 50% / cover no-repeat;
  width: 720px;
  height: 320px;
  position: relative;
  top: 2px;
}

.service-homepage.four .overlap-group-3 {
  background: linear-gradient(#0000 27.6%, #0000005c 60.42%, #0000008f 100%);
  width: 720px;
  height: 648px;
}

.service-homepage.five .frame-4, .service-homepage.three .frame-4, .service-homepage.one .frame-4, .service-homepage.two .frame-4 {
  align-items: flex-end;
  gap: 11px;
  position: absolute;
  top: 41px;
  left: 182px;
}

.service-homepage.six .frame-4 {
  flex-direction: column-reverse;
  align-items: flex-end;
  gap: 11px;
  position: absolute;
  top: 41px;
  left: 60px;
  transform: rotate(180deg);
}

.service-homepage.four .frame-4 {
  align-items: flex-start;
  gap: 23px;
  position: relative;
  top: 337px;
  left: 69px;
}

.box {
  width: 1460px;
  height: 1497px;
  position: absolute;
  top: 2400px;
}

.box .group {
  width: 1460px;
  height: 1497px;
  position: absolute;
  top: 0;
  left: 0;
}

.box .frame-12 {
  align-items: center;
  gap: 612px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 88px;
}

.box .frame-13 {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 465px;
  display: flex;
  position: relative;
}

.box .text-wrapper-4 {
  color: #1f1f1f;
  letter-spacing: -1.05px;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 42px;
  font-weight: 400;
  line-height: 50.4px;
  position: relative;
}

.box .text-wrapper-5 {
  color: #797979;
  letter-spacing: -.17px;
  align-self: stretch;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 400;
  line-height: 23.8px;
  position: relative;
}

.box .CTA-4 {
  border: unset !important;
  border-color: unset !important;
  background-color: #4d9f98 !important;
  justify-content: center !important;
  align-items: flex-end !important;
  width: 182px !important;
  display: flex !important;
}

.box .CTA-5 {
  margin-left: -1px !important;
  font-weight: 700 !important;
}

.box .CTA-6 {
  margin-right: -1px !important;
}

.box .service-instance {
  position: absolute !important;
  top: 177px !important;
  left: 740px !important;
}

.box .property-1-default {
  background-image: url("service.75631a8c.webp") !important;
  position: absolute !important;
  top: 177px !important;
  left: 3px !important;
}

.box .service-2 {
  position: absolute !important;
  top: 510px !important;
  left: 736px !important;
}

.box .property-default-instance {
  position: absolute !important;
  top: 509px !important;
  left: 0 !important;
}

.box .service-3 {
  position: absolute !important;
  top: 841px !important;
  left: 0 !important;
}

.box .service-4 {
  position: absolute !important;
  top: 843px !important;
  left: 736px !important;
}

.box .service-5 {
  position: absolute !important;
  top: 1177px !important;
  left: 0 !important;
}

.CTA {
  border-radius: 56px;
  align-items: center;
  padding: 12px 16px;
  display: inline-flex;
  position: relative;
}

.CTA .this-is-who-we-are {
  color: #fff;
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.CTA .arrow-forward {
  width: 24px;
  height: 24px;
  position: relative;
}

.CTA.hover {
  background-color: var(--primary-02);
  gap: 8px;
}

.CTA.default {
  border: 1px solid #fff;
  gap: 10px;
  height: auto;
}

.frame-mobile {
  flex-direction: column;
  align-items: flex-start;
  gap: 11.73px;
  display: inline-flex;
  position: absolute;
  top: 3850px;
}

.frame-mobile .service {
  background-image: url("service.75631a8c.webp");
  background-position: 50%;
  background-size: cover;
  width: 390px;
  height: 312.78px;
  position: relative;
  overflow: hidden;
}

.frame-mobile .overlap {
  width: 704px;
  height: 313px;
  position: relative;
}

.frame-mobile .overlap-group-wrapper {
  background: url("frame-3792.a5f9c360.webp");
  width: 704px;
  height: 313px;
  position: absolute;
  top: 0;
  left: 0;
}

.frame-mobile .overlap-group {
  background-position: 50%;
  background-size: cover;
  width: 390px;
  height: 313px;
  position: relative;
}

.frame-mobile .rectangle {
  background-blend-mode: multiply;
  width: 390px;
  height: 313px;
  position: absolute;
  top: 0;
  left: 0;
}

.frame-mobile .div {
  background-color: #0000;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  height: 129px;
  display: inline-flex;
  position: absolute;
  top: 144px;
  left: 47px;
}

.frame-mobile .div-wrapper {
  flex-direction: column;
  align-items: flex-start;
  gap: 34.21px;
  height: 50.83px;
  display: inline-flex;
  position: relative;
}

.frame-mobile .text-wrapper {
  color: #fff;
  letter-spacing: -.6px;
  width: 277px;
  height: 51px;
  margin-top: -.98px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
  position: relative;
}

.frame-mobile .CTA-instance {
  background-color: #0000 !important;
  border: .98px solid #fff !important;
  border-radius: 54.74px !important;
  flex: none !important;
  gap: 9.77px !important;
  padding: 11.73px 15.64px !important;
}

.frame-mobile .design-component-instance-node {
  font-family: Source Sans Pro, Helvetica !important;
  font-size: 16.6px !important;
  font-style: unset !important;
  letter-spacing: -.17px !important;
  margin-top: -.98px !important;
  font-weight: 600 !important;
  line-height: 23.3px !important;
}

.frame-mobile .overlap-wrapper {
  background-color: var(--secondary-03);
  width: 390px;
  height: 312.78px;
  position: relative;
  overflow: hidden;
}

.frame-mobile .overlap-group-wrapper-2 {
  width: 704px;
  height: 313px;
  position: absolute;
  top: 0;
  left: 0;
}

.frame-mobile .overlap-group-2 {
  width: 435px;
  height: 312px;
  position: relative;
}

.frame-mobile .shutterstock {
  object-fit: cover;
  width: 390px;
  height: 312px;
  position: absolute;
  top: 0;
  left: 0;
}

.frame-mobile .rectangle-2 {
  background-blend-mode: multiply;
  background: linear-gradient(#0000 0%, #939393 100%);
  width: 435px;
  height: 312px;
  position: absolute;
  top: 0;
  left: 0;
}

.frame-mobile .div-2 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 148px;
  left: 39px;
}

.frame-mobile .administrative-and {
  color: #fff;
  letter-spacing: -.36px;
  width: 230px;
  margin-top: -.98px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 27.8px;
  position: relative;
}

.frame-mobile .CTA-2 {
  width: 23.46px !important;
  height: 23.46px !important;
}

.frame-mobile .CTA-3 {
  font-family: Source Sans Pro, Helvetica !important;
  font-size: 16.6px !important;
  font-style: unset !important;
  letter-spacing: -.17px !important;
  margin-top: -.75px !important;
  font-weight: 600 !important;
  line-height: 23.3px !important;
}

.frame-mobile .overlap-group-wrapper-3 {
  width: 704px;
  height: 313px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(180deg);
}

.frame-mobile .overlap-group-3 {
  width: 435px;
  height: 674px;
  position: relative;
  left: 269px;
}

.frame-mobile .img {
  object-fit: cover;
  width: 390px;
  height: 313px;
  position: absolute;
  top: 0;
  left: 45px;
  transform: rotate(-180deg);
}

.frame-mobile .rectangle-3 {
  background-blend-mode: multiply;
  background: linear-gradient(#0000 0%, #2c2c2c 100%);
  width: 435px;
  height: 674px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(180deg);
}

.frame-mobile .div-3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 156px;
  left: 39px;
}

.frame-mobile .hire-train-deploy {
  color: #fff;
  letter-spacing: -.6px;
  width: 277px;
  margin-top: -.98px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
  position: relative;
}

.frame-mobile .overlap-2 {
  width: 461px;
  height: 312px;
  position: relative;
}

.frame-mobile .overlap-group-wrapper-4 {
  width: 461px;
  height: 312px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(180deg);
}

.frame-mobile .overlap-group-4 {
  width: 633px;
  height: 312px;
  position: relative;
  left: 71px;
}

.frame-mobile .shutterstock-2 {
  object-fit: cover;
  width: 390px;
  height: 312px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
}

.frame-mobile .rectangle-4 {
  background-blend-mode: multiply;
  background: linear-gradient(#0000 0%, #2c2c2c 100%);
  width: 435px;
  height: 312px;
  position: absolute;
  top: 0;
  left: 198px;
  transform: rotate(180deg);
}

.frame-mobile .div-4 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  width: 230px;
  display: flex;
  position: absolute;
  top: 126px;
  left: 39px;
}

.frame-mobile .text-wrapper-2 {
  color: #fff;
  letter-spacing: -.6px;
  width: 230px;
  margin-top: -.98px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
  position: relative;
}

.frame-mobile .shutterstock-wrapper {
  width: 704px;
  height: 311px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.frame-mobile .shutterstock-3 {
  width: 390px;
  height: 311px;
  position: absolute;
  top: 0;
  left: 0;
}

.frame-mobile .rectangle-5 {
  background-blend-mode: darken;
  background: linear-gradient(#0000 0%, #000c 100%);
  width: 435px;
  height: 313px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(180deg);
}

.frame-mobile .div-5 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  width: 166px;
  display: flex;
  position: absolute;
  top: 155px;
  left: 39px;
}

.frame-mobile .text-wrapper-3 {
  color: var(--neutral-0);
  letter-spacing: -.6px;
  width: 166px;
  margin-top: -.98px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
  position: relative;
}

.frame-mobile .overlap-3 {
  width: 704px;
  height: 315px;
  position: relative;
  top: -2px;
}

.frame-mobile .overlap-group-wrapper-5-mobile {
  width: 704px;
  height: 315px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(180deg);
}

.frame-mobile .overlap-group-5-mobile {
  width: 435px;
  height: 314px;
  position: relative;
  left: 269px;
}

.frame-mobile .rectangle-6 {
  background-blend-mode: darken;
  background: linear-gradient(#0000 0%, #000c 100%);
  width: 435px;
  height: 314px;
  position: absolute;
  top: 0;
  left: 0;
}

.frame-mobile .div-6 {
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  display: inline-flex;
  position: absolute;
  top: 119px;
  left: 39px;
}

.frame-mobile .consulting-for {
  color: var(--neutral-0);
  letter-spacing: -.6px;
  width: 276px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
  position: relative;
}

.frame-mobile .CTA-wrapper {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 23.46px;
  display: inline-flex;
  position: relative;
}

.advisory {
  background-color: #fff;
  flex-direction: row;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.advisory .carousel {
  width: 100%;
  height: 450px;
}

.advisory .div-2 {
  background-color: #fff;
  position: relative;
}

.advisory .header {
  width: 390px !important;
  height: 60px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.advisory .footer {
  background-color: var(--white);
  width: 390px;
  height: 860px;
  position: absolute;
  top: 4595px;
  left: 0;
  overflow: hidden;
}

.advisory .overlap-group-2 {
  width: 390px;
  height: 907px;
  position: relative;
}

.advisory .frame-4 {
  background-color: var(--neutral-90);
  width: 390px;
  height: 907px;
  position: absolute;
  top: 0;
  left: 0;
}

.advisory .frame-5 {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: absolute;
  top: 115px;
  left: 26px;
}

.advisory .text-wrapper-5 {
  color: #fff;
  font-family: var(--paragraph-ALCPS-font-family);
  font-size: var(--paragraph-ALCPS-font-size);
  font-style: var(--paragraph-ALCPS-font-style);
  font-weight: var(--paragraph-ALCPS-font-weight);
  letter-spacing: var(--paragraph-ALCPS-letter-spacing);
  line-height: var(--paragraph-ALCPS-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.advisory .p {
  color: #f6f7f8;
  font-family: var(--paragraph-REG-font-family);
  font-size: var(--paragraph-REG-font-size);
  font-style: var(--paragraph-REG-font-style);
  font-weight: var(--paragraph-REG-font-weight);
  letter-spacing: var(--paragraph-REG-letter-spacing);
  line-height: var(--paragraph-REG-line-height);
  width: 324px;
  position: relative;
}

.advisory .DIV {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 113px;
  display: flex;
  position: absolute;
  top: 272px;
  left: 195px;
}

.advisory .text-wrapper-6 {
  color: var(--white);
  letter-spacing: -.2px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.advisory .text-wrapper-7 {
  color: var(--white);
  letter-spacing: -.2px;
  white-space: nowrap;
  width: fit-content;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.advisory .DIV-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 113px;
  display: flex;
  position: absolute;
  top: 272px;
  left: 26px;
}

.advisory .frame-6 {
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 583px;
  left: 69px;
}

.advisory .group {
  width: 37px;
  height: 52px;
  position: relative;
}

.advisory .text-wrapper-8 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 38px;
  left: 1px;
}

.advisory .face-book {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 0;
  left: 0;
}

.advisory .frame-7 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.advisory .frame-8 {
  flex: none;
  position: relative;
}

.advisory .text-wrapper-9 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  width: fit-content;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: relative;
}

.advisory .facebook {
  width: 35px;
  height: 35px;
  position: relative;
}

.advisory .frame-9 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.advisory .logo {
  width: 116px;
  height: 56px;
  position: absolute;
  top: 39px;
  left: 24px;
}

.advisory .frame-wrapper {
  width: 295px;
  height: 64px;
  position: absolute;
  top: 732px;
  left: 48px;
}

.advisory .frame-10 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 295px;
  display: flex;
  position: relative;
}

.advisory .text-wrapper-10 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.advisory .ellipse-3 {
  background-color: var(--grey-01);
  border-radius: 1px / 1.5px;
  width: 2px;
  height: 3px;
  position: relative;
}

.advisory .text-wrapper-11 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  text-align: center;
  align-self: stretch;
  position: relative;
}

.advisory .overlap {
  position: absolute;
  left: 0;
}

.advisory .rectangle-2 {
  background-color: #e1e4e9;
  width: 1440px;
  height: 567px;
  position: absolute;
  top: 0;
  left: 0;
}

.advisory .element {
  object-fit: cover;
  width: 566px;
  height: 565px;
  position: absolute;
  top: 258px;
  left: 431px;
}

.advisory .frame-11 {
  justify-content: center;
  align-items: center;
  gap: 392px;
  display: inline-flex;
  position: absolute;
  top: 168px;
  left: 77px;
}

.advisory .component {
  width: 437px;
  height: 180px;
  position: relative;
}

.advisory .rely-on {
  color: #0000;
  letter-spacing: -.75px;
  width: 484px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 50px;
  font-weight: 400;
  line-height: 60px;
  position: absolute;
  top: -1px;
  left: 0;
}

.advisory .span {
  color: #2c3e47;
}

.advisory .text-wrapper-12 {
  color: #4d9f98;
}

.advisory .frame-12 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: relative;
}

.advisory .text-wrapper-13 {
  color: #000;
  letter-spacing: -.2px;
  width: 450px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.advisory .CTA-instance {
  border: unset !important;
  border-color: unset !important;
  background-color: #4d9f98 !important;
  flex: none !important;
  justify-content: center !important;
  align-items: flex-end !important;
  width: 182px !important;
  display: flex !important;
}

.advisory .instance-node {
  margin-left: -1px !important;
  font-weight: 700 !important;
}

.advisory .CTA-2 {
  margin-right: -1px !important;
}

.advisory .rectangle-3 {
  background-color: #12212ff0;
  width: 1440px;
  height: 629px;
  position: absolute;
  top: 526px;
  left: 0;
}

.advisory .rectangle-4 {
  width: 256px;
  height: 824px;
  position: absolute;
  top: 326px;
  left: 1184px;
}

.advisory .rectangle-5 {
  width: 269px;
  height: 824px;
  position: absolute;
  top: 304px;
  left: 0;
}

.advisory .rectangle-5-advisory {
  align-self: center;
  width: 969px;
  height: 524px;
  position: absolute;
  top: 80px;
  left: 150px;
}

.advisory .frame-13 {
  align-items: center;
  gap: 108px;
  display: inline-flex;
  position: absolute;
  top: 609px;
  left: 81px;
}

.advisory .frame-14 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 6px;
  display: inline-flex;
  position: relative;
}

.advisory .text-wrapper-14 {
  color: #fff;
  letter-spacing: -.68px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 45px;
  font-weight: 600;
  line-height: 63px;
  position: relative;
}

.advisory .managing-a-company-s {
  color: #d9d9d9;
  letter-spacing: -.18px;
  width: 528px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
  position: relative;
}

.advisory .div-wrapper {
  align-items: center;
  gap: 8px;
  width: 648px;
  display: flex;
  position: relative;
}

.advisory .frame-15 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 12px;
  margin-right: -23px;
  display: inline-flex;
  position: relative;
}

.advisory .frame-16 {
  flex: none;
  align-items: flex-start;
  gap: 59px;
  display: inline-flex;
  position: relative;
}

.advisory .frame-17 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 15px;
  display: inline-flex;
  position: relative;
}

.advisory .frame-18 {
  flex: none;
  align-items: center;
  gap: 17px;
  margin-top: 20px;
  display: inline-flex;
  position: relative;
}

.advisory .vector {
  width: 16px;
  height: 16px;
  position: relative;
}

.advisory .frame-19 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.advisory .text-wrapper-15 {
  color: var(--neutral-0);
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  width: 1040px;
  margin-top: -1px;
  position: relative;
}

.advisory .text-noraml {
  font-size: 16px;
}

.advisory .text-wrapper-16 {
  color: var(--neutral-0);
  letter-spacing: -.2px;
  width: 204px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.advisory .div-3 {
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.advisory .text-wrapper-17 {
  color: var(--neutral-0);
  letter-spacing: -.2px;
  width: 343px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.advisory .procurement-and {
  color: var(--neutral-0);
  letter-spacing: -.2px;
  width: 320px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.advisory .group-2 {
  background: linear-gradient(#1e454d 0%, #2967749e 100%);
  border-radius: 16px;
  width: 347px;
  height: 334px;
  position: absolute;
  top: 984px;
  left: 1013px;
}

.advisory .frame-20 {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 262px;
  display: flex;
  position: absolute;
  top: 43px;
  left: 34px;
}

.advisory .text-wrapper-18 {
  color: var(--neutral-40);
  letter-spacing: -.29px;
  width: 201px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 29px;
  font-weight: 600;
  line-height: 40.6px;
  position: relative;
}

.advisory .frame-21 {
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 262px;
  display: flex;
  position: absolute;
  top: 104px;
  left: 34px;
}

.advisory .frame-22 {
  flex: none;
  align-items: center;
  gap: 17px;
  margin-right: -40px;
  display: inline-flex;
  position: relative;
}

.advisory .text-wrapper-19 {
  color: var(--neutral-40);
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  width: 269px;
  margin-top: -1px;
  position: relative;
}

.advisory .text-wrapper-20 {
  color: var(--neutral-40);
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  width: 204px;
  margin-top: -1px;
  position: relative;
}

.advisory .frame-23 {
  flex: none;
  align-items: center;
  gap: 17px;
  margin-right: -75px;
  display: inline-flex;
  position: relative;
}

.advisory .text-wrapper-21 {
  color: var(--neutral-40);
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  width: 304px;
  margin-top: -1px;
  position: relative;
}

.advisory .text-wrapper-22 {
  color: #d9d9d9;
  letter-spacing: -.82px;
  width: 196px;
  height: 40px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 33px;
  font-weight: 400;
  line-height: 39.6px;
  position: absolute;
  top: 851px;
  left: 627px;
}

.advisory .group-3 {
  width: 504px;
  height: 442px;
  position: absolute;
  top: 930px;
  left: 81px;
}

.advisory .overlap-2 {
  width: 146px;
  height: 168px;
  position: absolute;
  top: 274px;
  left: 344px;
}

.advisory .group-4 {
  width: 22px;
  height: 135px;
  position: absolute;
  top: 0;
  left: 63px;
}

.advisory .rectangle-6 {
  background-color: #f6b3b8;
  border-radius: 4px;
  width: 160px;
  height: 37px;
  position: absolute;
  top: 39px;
  left: 0;
}

.advisory .rectangle-7 {
  background-color: #006682;
  border-radius: 4px;
  width: 160px;
  height: 37px;
  position: absolute;
  top: 85px;
  left: 0;
}

.advisory .rectangle-8 {
  background-color: #4d9f98;
  border-radius: 4px;
  width: 160px;
  height: 36px;
  position: absolute;
  top: 132px;
  left: 0;
}

.advisory .text-wrapper-23 {
  color: #212f3c;
  font-family: var(--paragraph-MED-font-family);
  font-size: var(--paragraph-MED-font-size);
  font-style: var(--paragraph-MED-font-style);
  font-weight: var(--paragraph-MED-font-weight);
  letter-spacing: var(--paragraph-MED-letter-spacing);
  line-height: var(--paragraph-MED-line-height);
  white-space: nowrap;
  width: 99px;
  height: 21px;
  position: absolute;
  top: 47px;
  left: 28px;
}

.advisory .text-wrapper-24 {
  color: #fff;
  font-family: var(--paragraph-MED-font-family);
  font-size: var(--paragraph-MED-font-size);
  font-style: var(--paragraph-MED-font-style);
  font-weight: var(--paragraph-MED-font-weight);
  letter-spacing: var(--paragraph-MED-letter-spacing);
  line-height: var(--paragraph-MED-line-height);
  white-space: nowrap;
  width: 90px;
  height: 21px;
  position: absolute;
  top: 93px;
  left: 32px;
}

.advisory .text-wrapper-25 {
  color: #fff;
  font-family: var(--paragraph-MED-font-family);
  font-size: var(--paragraph-MED-font-size);
  font-style: var(--paragraph-MED-font-style);
  font-weight: var(--paragraph-MED-font-weight);
  letter-spacing: var(--paragraph-MED-letter-spacing);
  line-height: var(--paragraph-MED-line-height);
  white-space: nowrap;
  width: 122px;
  height: 21px;
  position: absolute;
  top: 139px;
  left: 13px;
}

.advisory .group-wrapper {
  width: 498px;
  height: 274px;
  position: absolute;
  top: 0;
  left: 0;
}

.advisory .group-5 {
  width: 508px;
  height: 274px;
  position: relative;
}

.advisory .overlap-3 {
  background-color: #00dfc2;
  border-radius: 4px;
  width: 220px;
  height: 49px;
  position: absolute;
  top: 0;
  left: 136px;
}

.advisory .text-wrapper-26 {
  color: #212f3c;
  letter-spacing: -.29px;
  white-space: nowrap;
  width: 162px;
  height: 18px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 29px;
  font-weight: 600;
  line-height: 10px;
  position: absolute;
  top: 15px;
  left: 31px;
}

.advisory .overlap-4 {
  width: 344px;
  height: 186px;
  position: absolute;
  top: 55px;
  left: 0;
}

.advisory .overlap-5 {
  width: 344px;
  height: 141px;
  position: absolute;
  top: 0;
  left: 0;
}

.advisory .rectangle-9 {
  background-color: #f6b3b8;
  border-radius: 4px;
  width: 165px;
  height: 32px;
  position: absolute;
  top: 103px;
  left: 0;
}

.advisory .line {
  width: 192px;
  height: 3px;
  position: absolute;
  top: 118px;
  left: 152px;
}

.advisory .ellipse-4 {
  object-fit: cover;
  width: 42px;
  height: 42px;
  position: absolute;
  top: 97px;
  left: 173px;
}

.advisory .text-wrapper-27 {
  color: #212f3c;
  font-family: var(--subtitle-BLD-font-family);
  font-size: var(--subtitle-BLD-font-size);
  font-style: var(--subtitle-BLD-font-style);
  font-weight: var(--subtitle-BLD-font-weight);
  letter-spacing: var(--subtitle-BLD-letter-spacing);
  line-height: var(--subtitle-BLD-line-height);
  white-space: nowrap;
  width: 146px;
  height: 19px;
  position: absolute;
  top: 109px;
  left: 4px;
}

.advisory .ellipse-5 {
  object-fit: cover;
  width: 42px;
  height: 42px;
  position: absolute;
  top: 99px;
  left: 277px;
}

.advisory .group-6 {
  width: 22px;
  height: 119px;
  position: absolute;
  top: 0;
  left: 235px;
}

.advisory .group-7 {
  width: 22px;
  height: 52px;
  position: absolute;
  top: 135px;
  left: 66px;
}

.advisory .overlap-6 {
  background-color: var(--neutral-0);
  border-radius: 4px;
  width: 165px;
  height: 32px;
  position: absolute;
  top: 242px;
  left: 4px;
}

.advisory .text-wrapper-28 {
  color: #212f3c;
  font-family: var(--subtitle-BLD-font-family);
  font-size: var(--subtitle-BLD-font-size);
  font-style: var(--subtitle-BLD-font-style);
  font-weight: var(--subtitle-BLD-font-weight);
  letter-spacing: var(--subtitle-BLD-letter-spacing);
  line-height: var(--subtitle-BLD-line-height);
  white-space: nowrap;
  width: 119px;
  height: 19px;
  position: absolute;
  top: 6px;
  left: 16px;
}

.advisory .overlap-group-3 {
  background-color: var(--neutral-0);
  border-radius: 4px;
  width: 160px;
  height: 32px;
  position: absolute;
  top: 242px;
  left: 344px;
}

.advisory .text-wrapper-29 {
  color: #212f3c;
  font-family: var(--subtitle-BLD-font-family);
  font-size: var(--subtitle-BLD-font-size);
  font-style: var(--subtitle-BLD-font-style);
  font-weight: var(--subtitle-BLD-font-weight);
  letter-spacing: var(--subtitle-BLD-letter-spacing);
  line-height: var(--subtitle-BLD-line-height);
  white-space: nowrap;
  width: 46px;
  height: 19px;
  position: absolute;
  top: 7px;
  left: 60px;
}

.advisory .overlap-7 {
  width: 154px;
  height: 32px;
  position: absolute;
  top: 158px;
  left: 344px;
}

.advisory .rectangle-10 {
  background-color: #f6b3b8;
  border-radius: 4px;
  width: 160px;
  height: 32px;
  position: absolute;
  top: 0;
  left: 0;
}

.advisory .text-wrapper-30 {
  color: #212f3c;
  font-family: var(--subtitle-BLD-font-family);
  font-size: var(--subtitle-BLD-font-size);
  font-style: var(--subtitle-BLD-font-style);
  font-weight: var(--subtitle-BLD-font-weight);
  letter-spacing: var(--subtitle-BLD-letter-spacing);
  line-height: var(--subtitle-BLD-line-height);
  white-space: nowrap;
  width: 144px;
  height: 19px;
  position: absolute;
  top: 4px;
  left: 10px;
}

.advisory .group-8 {
  width: 22px;
  height: 52px;
  position: absolute;
  top: 190px;
  left: 406px;
}

.advisory .frame-24 {
  background: linear-gradient(#1e454d 0%, #22647299 100%);
  border-radius: 16px;
  width: 347px;
  height: 334px;
  position: absolute;
  top: 984px;
  left: 650px;
}

.advisory .text-wrapper-31 {
  color: var(--neutral-40);
  letter-spacing: -.29px;
  width: 360px;
  height: 41px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 29px;
  font-weight: 600;
  line-height: 40.6px;
  position: absolute;
  top: 42px;
  left: 38px;
}

.advisory .frame-25 {
  align-items: center;
  gap: 17px;
  display: inline-flex;
  position: absolute;
  top: 104px;
  left: 38px;
}

.advisory .frame-26 {
  align-items: center;
  gap: 17px;
  display: inline-flex;
  position: absolute;
  top: 136px;
  left: 38px;
}

.advisory .frame-27 {
  align-items: center;
  gap: 17px;
  display: inline-flex;
  position: absolute;
  top: 168px;
  left: 38px;
}

.advisory .frame-28 {
  align-items: center;
  gap: 17px;
  display: inline-flex;
  position: absolute;
  top: 200px;
  left: 38px;
}

.advisory .frame-29 {
  align-items: center;
  gap: 17px;
  display: inline-flex;
  position: absolute;
  top: 232px;
  left: 38px;
}

.advisory .frame-30 {
  align-items: center;
  gap: 17px;
  display: inline-flex;
  position: absolute;
  top: 264px;
  left: 38px;
}

.advisory .header-2 {
  background-color: #e1e4e9;
  justify-content: space-between;
  align-items: center;
  width: 1440px;
  padding: 16px 80px;
  display: flex;
  position: absolute;
  top: 0;
  left: 1px;
}

.advisory .logo-2 {
  object-fit: cover;
  width: 145px;
  height: 67.67px;
  position: relative;
}

.advisory .frame-31 {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 90.33px;
  padding: 10px 8px;
  display: flex;
  position: relative;
}

.advisory .text-wrapper-32 {
  color: #000;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: relative;
}

.advisory .text-wrapper-33 {
  color: #000;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-left: -2.83px;
  margin-right: -2.83px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: relative;
}

.advisory .frame-32 {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 80px;
  padding: 10px 8px;
  display: flex;
  position: relative;
}

.advisory .CTA-3 {
  border-color: var(--primary-02) !important;
  width: 179px !important;
  height: 69px !important;
  display: flex !important;
}

.advisory .CTA-4 {
  color: #000 !important;
  margin-top: unset !important;
}

.advisory .frame-33 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  width: 358px;
  display: flex;
  position: absolute;
  top: 481px;
  left: 16px;
}

.advisory .frame-34 {
  flex: none;
  width: 358px;
  position: relative;
}

.advisory .frame-35 {
  background-color: #e1e4e9;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: 390px;
  height: 535px;
  padding: 60px 16px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.advisory .rely-on-2 {
  color: #0000;
  letter-spacing: -.54px;
  align-self: stretch;
  font-family: Source Sans Pro, Helvetica;
  font-size: 36px;
  font-weight: 400;
  line-height: 43.2px;
  position: relative;
}

.advisory .frame-36 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 24px;
  width: 358px;
  display: flex;
  position: relative;
}

.advisory .text-wrapper-34 {
  color: #000;
  letter-spacing: -.2px;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.advisory .frame-37 {
  height: 63px;
  position: relative;
}

.advisory .element-2 {
  object-fit: cover;
  width: 192px;
  height: 236px;
  position: absolute;
  top: 299px;
  left: 198px;
}

.advisory .overlap-wrapper {
  width: 390px;
  height: 1642px;
  position: absolute;
  top: 535px;
  left: 0;
  overflow: hidden;
}

.advisory .overlap-8 {
  background-color: #172c36;
  height: 833px;
  position: relative;
}

.advisory .overlap-9 {
  width: 374px;
  height: 381px;
  position: absolute;
  top: 0;
  left: 16px;
}

.advisory .rectangle-11 {
  width: 262px;
  height: 367px;
  position: absolute;
  top: 0;
  left: 112px;
}

.advisory .frame-38 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  width: 358px;
  display: flex;
  position: absolute;
  top: 76px;
  left: 0;
}

.advisory .frame-39 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 6px;
  width: 100%;
  display: flex;
  position: relative;
}

.advisory .text-wrapper-35 {
  color: #fff;
  letter-spacing: -.48px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 22px;
  font-weight: 400;
  line-height: 44.8px;
  position: relative;
}

.advisory .managing-a-company-s-2 {
  color: #d9d9d9;
  letter-spacing: -.18px;
  align-self: stretch;
  font-family: Source Sans Pro, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
  position: relative;
}

.advisory .frame-40 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 8px;
  display: flex;
  position: relative;
}

.advisory .frame-41 {
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  width: 150px;
  display: flex;
  position: relative;
}

.advisory .frame-42 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 17px;
  width: 100%;
  margin-top: 10px;
  display: flex;
  position: relative;
}

.advisory .frame-43 {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  display: flex;
  position: relative;
}

.advisory .text-wrapper-36 {
  color: var(--neutral-0);
  letter-spacing: -.14px;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  position: relative;
}

.advisory .text-wrapper-37 {
  color: var(--neutral-0);
  letter-spacing: -.14px;
  flex: 1;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  position: relative;
}

.advisory .frame-44 {
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  width: 170px;
  display: flex;
  position: relative;
}

.advisory .frame-45 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.advisory .overlap-10 {
  width: 374px;
  height: 395px;
  position: absolute;
  top: 1247px;
  left: 16px;
}

.advisory .rectangle-12 {
  width: 337px;
  height: 222px;
  position: absolute;
  top: 173px;
  left: 37px;
}

.advisory .group-9 {
  background: linear-gradient(#1e454d 0%, #2967749e 100%);
  border-radius: 16px;
  width: 358px;
  height: 334px;
  position: absolute;
  top: 0;
  left: 0;
}

.advisory .frame-46 {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 267px;
  display: flex;
  position: absolute;
  top: 43px;
  left: 40px;
}

.advisory .frame-47 {
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 267px;
  display: flex;
  position: absolute;
  top: 104px;
  left: 40px;
}

.advisory .frame-48 {
  flex: none;
  align-items: center;
  gap: 17px;
  margin-right: -35.47px;
  display: inline-flex;
  position: relative;
}

.advisory .frame-49 {
  flex: none;
  align-items: center;
  gap: 17px;
  margin-right: -70.47px;
  display: inline-flex;
  position: relative;
}

.advisory .text-wrapper-38 {
  color: #d9d9d9;
  letter-spacing: -.82px;
  width: 196px;
  height: 40px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 33px;
  font-weight: 400;
  line-height: 39.6px;
  position: absolute;
  top: 456px;
  left: 97px;
}

.advisory .group-10 {
  width: 364px;
  height: 318px;
  position: absolute;
  top: 531px;
  left: 16px;
}

.advisory .overlap-11 {
  width: 105px;
  height: 121px;
  position: absolute;
  top: 197px;
  left: 247px;
}

.advisory .group-11 {
  width: 16px;
  height: 97px;
  position: absolute;
  top: 0;
  left: 46px;
}

.advisory .rectangle-13 {
  background-color: #f6b3b8;
  border-radius: 2.88px;
  width: 105px;
  height: 27px;
  position: absolute;
  top: 28px;
  left: 0;
}

.advisory .rectangle-14 {
  background-color: #006682;
  border-radius: 2.88px;
  width: 105px;
  height: 27px;
  position: absolute;
  top: 61px;
  left: 0;
}

.advisory .rectangle-15 {
  background-color: #4d9f98;
  border-radius: 2.88px;
  width: 105px;
  height: 26px;
  position: absolute;
  top: 95px;
  left: 0;
}

.advisory .text-wrapper-39 {
  color: #212f3c;
  letter-spacing: -.1px;
  white-space: nowrap;
  width: 71px;
  height: 15px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10.1px;
  font-weight: 600;
  line-height: 14.1px;
  position: absolute;
  top: 34px;
  left: 20px;
}

.advisory .text-wrapper-40 {
  color: #fff;
  letter-spacing: -.1px;
  white-space: nowrap;
  width: 65px;
  height: 15px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10.1px;
  font-weight: 600;
  line-height: 14.1px;
  position: absolute;
  top: 67px;
  left: 23px;
}

.advisory .text-wrapper-41 {
  color: #fff;
  letter-spacing: -.1px;
  white-space: nowrap;
  width: 88px;
  height: 15px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10.1px;
  font-weight: 600;
  line-height: 14.1px;
  position: absolute;
  top: 100px;
  left: 9px;
}

.advisory .group-12 {
  width: 358px;
  height: 197px;
  position: absolute;
  top: 0;
  left: 0;
}

.advisory .group-13 {
  width: 368px;
  height: 197px;
  position: relative;
}

.advisory .overlap-12 {
  background-color: #00dfc2;
  border-radius: 2.88px;
  width: 158px;
  height: 35px;
  position: absolute;
  top: 0;
  left: 98px;
}

.advisory .text-wrapper-42 {
  color: #212f3c;
  letter-spacing: -.21px;
  white-space: nowrap;
  width: 116px;
  height: 13px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20.8px;
  font-weight: 600;
  line-height: 29.2px;
  position: absolute;
  top: 11px;
  left: 22px;
}

.advisory .overlap-13 {
  width: 247px;
  height: 134px;
  position: absolute;
  top: 40px;
  left: 0;
}

.advisory .overlap-group-4 {
  width: 247px;
  height: 102px;
  position: absolute;
  top: 0;
  left: 0;
}

.advisory .rectangle-16 {
  background-color: #f6b3b8;
  border-radius: 2.88px;
  width: 109px;
  height: 23px;
  position: absolute;
  top: 74px;
  left: 0;
}

.advisory .line-2 {
  width: 138px;
  height: 2px;
  position: absolute;
  top: 85px;
  left: 109px;
}

.advisory .ellipse-6 {
  object-fit: cover;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 70px;
  left: 124px;
}

.advisory .text-wrapper-43 {
  color: #212f3c;
  letter-spacing: -.12px;
  white-space: nowrap;
  width: 105px;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 12.2px;
  font-weight: 700;
  line-height: 17.1px;
  position: absolute;
  top: 78px;
  left: 3px;
}

.advisory .ellipse-7 {
  object-fit: cover;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 71px;
  left: 199px;
}

.advisory .group-14 {
  width: 16px;
  height: 86px;
  position: absolute;
  top: 0;
  left: 169px;
}

.advisory .group-15 {
  width: 16px;
  height: 37px;
  position: absolute;
  top: 97px;
  left: 47px;
}

.advisory .overlap-14 {
  background-color: var(--neutral-0);
  border-radius: 2.88px;
  width: 105px;
  height: 23px;
  position: absolute;
  top: 174px;
  left: 3px;
}

.advisory .text-wrapper-44 {
  color: #212f3c;
  letter-spacing: -.12px;
  white-space: nowrap;
  width: 86px;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 12.2px;
  font-weight: 700;
  line-height: 17.1px;
  position: absolute;
  top: 5px;
  left: 12px;
}

.advisory .overlap-15 {
  background-color: var(--neutral-0);
  border-radius: 2.88px;
  width: 105px;
  height: 23px;
  position: absolute;
  top: 174px;
  left: 247px;
}

.advisory .text-wrapper-45 {
  color: #212f3c;
  letter-spacing: -.12px;
  white-space: nowrap;
  width: 33px;
  height: 13px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 12.2px;
  font-weight: 700;
  line-height: 17.1px;
  position: absolute;
  top: 5px;
  left: 43px;
}

.advisory .overlap-16 {
  width: 111px;
  height: 23px;
  position: absolute;
  top: 113px;
  left: 247px;
}

.advisory .rectangle-17 {
  background-color: #f6b3b8;
  border-radius: 2.88px;
  width: 105px;
  height: 23px;
  position: absolute;
  top: 0;
  left: 0;
}

.advisory .text-wrapper-46 {
  color: #212f3c;
  letter-spacing: -.12px;
  white-space: nowrap;
  width: 104px;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 12.2px;
  font-weight: 700;
  line-height: 17.1px;
  position: absolute;
  top: 3px;
  left: 7px;
}

.advisory .group-16 {
  width: 16px;
  height: 37px;
  position: absolute;
  top: 137px;
  left: 292px;
}

.advisory .overlap-group-wrapper {
  width: 347px;
  height: 334px;
  position: absolute;
  top: 881px;
  left: 18px;
}

.advisory .overlap-17 {
  width: 398px;
  height: 334px;
  position: relative;
}

.advisory .rectangle-18 {
  background: linear-gradient(#1e454d 0%, #22647299 100%);
  border-radius: 16px;
  width: 356px;
  height: 334px;
  position: absolute;
  top: 0;
  left: 0;
}

.advisory .carousel-2 {
  width: 390px;
  height: 796px;
  position: absolute;
  top: 2149px;
  left: 0;
  overflow: hidden;
}

.advisory .frame-50 {
  width: 667px;
  height: 1425px;
  position: absolute;
  top: 0;
  left: 0;
}

.advisory .overlap-group-5 {
  width: 687px;
  height: 923px;
  position: relative;
  top: -24px;
  left: -134px;
}

.advisory .rectangle-19 {
  width: 390px;
  height: 485px;
  position: absolute;
  top: 24px;
  left: 134px;
}

.advisory .frame-51 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  height: 261px;
  display: inline-flex;
  position: absolute;
  top: 166px;
  left: 214px;
}

.advisory .shutterstock {
  object-fit: cover;
  width: 390px;
  height: 441px;
  position: absolute;
  top: 379px;
  left: 134px;
}

.advisory .rectangle-20 {
  filter: blur(80px);
  background: linear-gradient(to right, #589994 50%, #fcfcfc00);
  width: 923px;
  height: 687px;
  position: absolute;
  top: 118px;
  left: -118px;
  transform: rotate(90deg);
}

.advisory .frame-52 {
  flex-direction: column;
  align-items: flex-start;
  gap: 33px;
  display: inline-flex;
  position: absolute;
  top: 106px;
  left: 150px;
}

.advisory .we-are-your-growth {
  color: #fff;
  letter-spacing: -.22px;
  width: 358px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 22px;
  font-weight: 600;
  line-height: 30.8px;
  position: relative;
}

.advisory .CTA-5 {
  border: unset !important;
  border-color: unset !important;
  background-color: #2e5053 !important;
  flex: none !important;
  justify-content: center !important;
  align-items: flex-end !important;
  width: 127px !important;
  display: flex !important;
}

.advisory .CTA-6 {
  margin-left: -2px !important;
  font-weight: 700 !important;
}

.advisory .CTA-7 {
  margin-right: -2px !important;
}

.advisory .frame-53 {
  width: 1440px;
  height: 580px;
  position: absolute;
  top: 0;
  left: 1440px;
  overflow: hidden;
}

.advisory .rectangle-21 {
  width: 1383px;
  height: 634px;
  position: absolute;
  top: -3795px;
  left: 5946px;
}

.advisory .overlap-18 {
  filter: blur(80px);
  background: linear-gradient(#000 0%, #071103 25%, #202020 44.79%, #2f2f2f 61.46%, #0000 100%);
  width: 1684px;
  height: 714px;
  position: absolute;
  top: -60px;
  left: -116px;
}

.advisory .frame-54 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: absolute;
  top: 242px;
  left: 196px;
}

.advisory .text-wrapper-47 {
  color: var(--neutral-0);
  letter-spacing: -.53px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 600;
  line-height: 49px;
  position: relative;
}

.advisory .frame-55 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.advisory .our-client-and {
  color: #f6f7f8;
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  width: 436px;
  margin-top: -1px;
  position: relative;
}

.advisory .CTA-8 {
  all: unset;
  box-sizing: border-box;
  flex: none;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.advisory .text-wrapper-48 {
  color: var(--neutral-0);
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 23.8px;
  position: relative;
}

.advisory .arrow-forward-2 {
  width: 24px;
  height: 24px;
  margin-top: -4167px;
  margin-right: -5519px;
  position: relative;
}

.advisory .text-wrapper-49 {
  color: var(--neutral-0);
  font-family: var(--h4-REG-font-family);
  font-size: var(--h4-REG-font-size);
  font-style: var(--h4-REG-font-style);
  font-weight: var(--h4-REG-font-weight);
  letter-spacing: var(--h4-REG-letter-spacing);
  line-height: var(--h4-REG-line-height);
  white-space: nowrap;
  height: 41px;
  position: absolute;
  top: 120px;
  left: 196px;
}

.advisory .frame-56 {
  align-items: flex-start;
  gap: 5px;
  display: inline-flex;
  position: absolute;
  top: 560px;
  left: 196px;
}

.advisory .ellipse-8 {
  background-color: #d9d9d952;
  border-radius: 5.5px;
  width: 11px;
  height: 11px;
  position: relative;
}

.advisory .ellipse-9 {
  background-color: var(--neutral-30);
  border-radius: 5.5px;
  width: 11px;
  height: 11px;
  position: relative;
}

.advisory .carousel-03 {
  position: absolute !important;
  top: 0 !important;
  left: 2880px !important;
}

.advisory .carousel-instance {
  margin-top: -4190px !important;
  margin-right: -4079px !important;
}

.advisory .overlap-19 {
  position: absolute;
}

.advisory .frame-57 {
  position: absolute;
  top: 0;
}

.advisory .overlap-group-6 {
  position: relative;
}

.advisory .frame-58 {
  width: 1449px;
  height: 380px;
  position: absolute;
  top: 0;
  left: 0;
}

.advisory .overlap-group-7 {
  background-color: #4d4d4d;
  width: 1456px;
  height: 380px;
}

.advisory .frame-59 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 32px;
  display: inline-flex;
  position: relative;
  top: 69px;
  left: 152px;
}

.advisory .frame-60 {
  flex-direction: column;
  flex: none;
  align-items: flex-end;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.advisory .text-wrapper-50 {
  color: #fff;
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.advisory .are-we-a-good-fit {
  color: var(--neutral-10);
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  text-align: right;
  width: 429px;
  position: relative;
}

.advisory .CTA-9 {
  all: unset;
  background-color: var(--secondary-04);
  box-sizing: border-box;
  flex: none;
  align-items: center;
  gap: 10px;
  padding: 12px 16px;
  display: inline-flex;
  position: relative;
}

.advisory .text-wrapper-51 {
  color: #fff;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: relative;
}

.advisory .arrow-forward-3 {
  width: 24px;
  height: 24px;
  position: relative;
}

.advisory .frame-61 {
  position: absolute;
  top: 0;
}

.advisory .overlap-20 {
  background-color: var(--neutral-10);
  width: 720px;
  height: 380px;
  position: absolute;
  top: 0;
  left: 0;
}

.advisory .frame-62 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 32px;
  display: inline-flex;
  position: relative;
  top: 49px;
  left: 128px;
}

.advisory .text-wrapper-52 {
  color: var(--secondary-04);
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.advisory .text-wrapper-53 {
  color: var(--neutral-80);
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  text-align: right;
  width: 500px;
  position: relative;
}

.advisory .frame-63 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 52px;
  left: 817px;
}

.advisory .text-wrapper-54 {
  color: #fff;
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.advisory .text-wrapper-55 {
  color: var(--neutral-10);
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  width: 429px;
  position: relative;
}

.advisory .CTA-10 {
  cursor: pointer;
  background-color: var(--secondary-02);
  box-sizing: border-box;
  border: none;
  outline: none;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  display: inline-flex;
  position: absolute;
  top: 259px;
  left: 817px;
}

.advisory .text-wrapper-56 {
  color: var(--neutral-0);
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.advisory .frame-64 {
  flex-direction: column;
  align-items: center;
  gap: 25px;
  display: inline-flex;
  position: absolute;
  top: 76px;
  left: 0;
}

.advisory .frame-65 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 51px;
  margin-left: 50px;
  display: inline-flex;
  position: relative;
}

.advisory .frame-66 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 26px;
  display: inline-flex;
  position: relative;
}

.advisory .frame-67 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.advisory .frame-68 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.advisory .text-wrapper-57 {
  color: var(--neutral-60);
  letter-spacing: -.2px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  position: relative;
}

.advisory .frame-69 {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 29px;
  display: inline-flex;
  position: relative;
}

.advisory .mercedes-benz-logo {
  object-fit: cover;
  width: 148px;
  height: 37px;
  position: relative;
}

.advisory .GE-healthcare-logo {
  object-fit: cover;
  width: 98px;
  height: 55px;
  position: relative;
}

.advisory .sony-logo {
  object-fit: cover;
  width: 74px;
  height: 13px;
  position: relative;
}

.advisory .shell-logo {
  object-fit: cover;
  width: 69px;
  height: 39px;
  position: relative;
}

.advisory .amazon {
  object-fit: cover;
  width: 70px;
  height: 33px;
  position: relative;
}

.advisory .wipro-logo-new-og {
  object-fit: cover;
  width: 102px;
  height: 53px;
  position: relative;
}

.advisory .footer-2 {
  background-color: var(--white);
  width: 1441px;
  height: 292px;
  position: absolute;
  top: 377px;
  left: 0;
}

.advisory .overlap-21 {
  width: 1441px;
  height: 292px;
  position: relative;
}

.advisory .frame-70 {
  background-color: var(--neutral-90);
  width: 1441px;
  height: 292px;
  position: absolute;
  top: 0;
  left: 0;
}

.advisory .DIV-3 {
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 53px;
  left: 738px;
}

.advisory .text-wrapper-58 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1233px;
}

.advisory .text-wrapper-59 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1282px;
}

.advisory .text-wrapper-60 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1331px;
}

.advisory .text-wrapper-61 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1187px;
}

.advisory .text-wrapper-62 {
  color: var(--white);
  letter-spacing: -.2px;
  white-space: nowrap;
  height: 28px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: absolute;
  top: 100px;
  left: 738px;
}

.advisory .text-wrapper-63 {
  color: var(--white);
  letter-spacing: -.2px;
  white-space: nowrap;
  height: 28px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: absolute;
  top: 100px;
  left: 869px;
}

.advisory .frame-71 {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: absolute;
  top: 115px;
  left: 81px;
}

.advisory .group-17 {
  width: 37px;
  height: 52px;
  position: absolute;
  top: 152px;
  left: 1140px;
}

.advisory .linkedin {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1187px;
}

.advisory .facebook-2 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1234px;
}

.advisory .facebook-3 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1281px;
}

.advisory .facebook-4 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1328px;
}

.advisory .group-18 {
  width: 400px;
  height: 17px;
  position: absolute;
  top: 242px;
  left: 501px;
}

.advisory .frame-72 {
  justify-content: center;
  align-items: center;
  gap: 5px;
  display: inline-flex;
  position: relative;
}

.advisory .text-wrapper-64 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.advisory .ellipse-10 {
  background-color: var(--grey-01);
  border-radius: 1.5px;
  width: 3px;
  height: 3px;
  position: relative;
}

.advisory .logo-3 {
  width: 116px;
  height: 56px;
  position: absolute;
  top: 39px;
  left: 76px;
}

.advisory .frame-73 {
  flex-direction: column;
  align-items: center;
  gap: 92px;
  display: inline-flex;
  position: absolute;
  top: 366px;
  left: 185px;
}

.advisory .frame-74 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-end;
  gap: 32px;
  width: 328px;
  display: flex;
  position: relative;
}

.advisory .frame-75 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 24px;
  width: 328px;
  display: flex;
  position: relative;
}

.advisory .text-wrapper-65 {
  color: var(--secondary-04);
  letter-spacing: -.8px;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.4px;
  position: relative;
}

.advisory .text-wrapper-66 {
  color: var(--neutral-80);
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  text-align: center;
  width: 297px;
  position: relative;
}

.advisory .group-19 {
  width: 390px;
  height: 360px;
  position: relative;
}

.advisory .frame-76 {
  height: 360px;
}

.advisory .overlap-group-8 {
  background-color: var(--neutral-70);
  width: 1440px;
  height: 360px;
}

.advisory .frame-77 {
  flex-direction: column;
  align-items: center;
  gap: 24px;
  display: inline-flex;
  position: relative;
  top: 85px;
  left: 41px;
}

.advisory .text-wrapper-67 {
  color: var(--neutral-0);
  letter-spacing: -.8px;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.4px;
  position: relative;
}

.advisory .are-we-a-good-fit-2 {
  color: #f6f7f8;
  letter-spacing: -.2px;
  text-align: center;
  width: 309px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.advisory .CTA-11 {
  cursor: pointer;
  background-color: var(--secondary-02);
  box-sizing: border-box;
  border: none;
  outline: none;
  flex: none;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  display: inline-flex;
  position: relative;
}

.advisory .frame-78 {
  align-items: flex-start;
  gap: 40px;
  width: 100%;
  position: absolute;
  top: 1561px;
  left: 46px;
  display: inline-flex !important;
}

.advisory .frame-79 {
  background-color: var(--neutral-0);
  border: 1px solid;
  border-color: var(--neutral-50);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  width: 298px;
  margin-top: -1px;
  margin-bottom: -1px;
  margin-left: -1px;
  display: flex;
  position: relative;
}

.advisory .deepmind {
  object-fit: cover;
  width: 296px;
  height: 163px;
  position: relative;
}

.advisory .frame-80 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 14px;
  width: 100%;
  padding: 0 32px;
  display: flex;
  position: relative;
}

.advisory .frame-81 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.advisory .text-wrapper-68 {
  color: #1f1f1f;
  white-space: nowrap;
  letter-spacing: -.36px;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 28.8px;
  position: relative;
}

.advisory .automate-the-entire {
  color: var(--neutral-60);
  letter-spacing: -.14px;
  align-self: stretch;
  font-family: Source Sans Pro, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  position: relative;
}

.advisory .frame-82 {
  flex: none;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.advisory .text-wrapper-69 {
  color: #4d9f98;
  letter-spacing: -.15px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 15px;
  font-weight: 700;
  line-height: 21px;
  position: relative;
}

.advisory .frame-83 {
  flex: none;
  align-items: flex-start;
  gap: 10px;
  margin-right: -2px;
  display: inline-flex;
  position: relative;
}

.advisory .frame-84 {
  background-color: var(--neutral-0);
  border: 1px solid;
  border-color: var(--neutral-50);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  width: 298px;
  margin-top: -1px;
  margin-bottom: -1px;
  display: flex;
  position: relative;
}

.advisory .frame-85 {
  background-color: var(--neutral-0);
  border: 1px solid;
  border-color: var(--neutral-50);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  width: 298px;
  margin-top: -1px;
  margin-bottom: -1px;
  margin-right: -1px;
  display: flex;
  position: relative;
}

.advisory .overlap-22 {
  width: 1483px;
  height: 1070px;
  position: absolute;
  top: 1456px;
  left: -11px;
}

.advisory .overlap-23 {
  width: 1483px;
  height: 1068px;
  position: absolute;
  top: 2px;
  left: 0;
}

.advisory .group-20 {
  width: 1483px;
  height: 416px;
  position: absolute;
  top: 652px;
  left: 0;
}

.advisory .overlap-24 {
  width: 1481px;
  height: 416px;
  position: relative;
}

.advisory .carousel-3 {
  width: 1481px;
  height: 416px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.advisory .frame-86 {
  width: 1440px;
  height: 683px;
  position: absolute;
  top: 0;
  left: 0;
}

.advisory .overlap-group-9 {
  width: 1722px;
  height: 790px;
  position: relative;
  top: -81px;
  left: -186px;
}

.advisory .rectangle-22 {
  width: 1429px;
  height: 416px;
  position: absolute;
  top: 81px;
  left: 197px;
}

.advisory .shutterstock-2 {
  object-fit: cover;
  width: 860px;
  height: 709px;
  position: absolute;
  top: 81px;
  left: 766px;
}

.advisory .rectangle-23 {
  background: linear-gradient(to right, #589994 50%, #fcfcfc00);
  width: 1722px;
  height: 790px;
  position: absolute;
  top: 0;
  left: 0;
}

.advisory .CTA-12 {
  border: unset !important;
  border-color: unset !important;
  background-color: #2e5053 !important;
  justify-content: center !important;
  align-items: flex-end !important;
  width: 182px !important;
  display: flex !important;
  position: absolute !important;
  top: 367px !important;
  left: 234px !important;
}

.advisory .CTA-13 {
  font-weight: 700 !important;
}

.advisory .rectangle-24 {
  width: 1383px;
  height: 634px;
  position: absolute;
  top: 668px;
  left: 5957px;
}

.advisory .arrow-forward-4 {
  width: 24px;
  height: 24px;
  margin-bottom: -296px;
  margin-right: -5530px;
  position: relative;
}

.advisory .carousel-03-instance {
  margin-bottom: -273px !important;
  margin-right: -4090px !important;
}

.advisory .we-are-your-growth-2 {
  color: #fff;
  letter-spacing: -.29px;
  width: 604px;
  height: 118px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 29px;
  font-weight: 600;
  line-height: 40.6px;
  position: absolute;
  top: 102px;
  left: 47px;
}

.advisory .frame-87 {
  background-color: var(--neutral-0);
  border: 1px solid;
  border-color: var(--neutral-50);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  width: 434px;
  height: 664px;
  padding: 0 0 94px;
  display: flex;
  position: absolute;
  top: -175px;
  left: 512px;
}

.advisory .deepmind-hereyczt {
  align-self: stretch;
  width: 100%;
  height: 304.5px;
  position: relative;
}

.advisory .frame-88 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 14px;
  width: 100%;
  margin-bottom: -50.5px;
  padding: 0 48px;
  display: flex;
  position: relative;
}

.advisory .text-wrapper-70 {
  color: #1f1f1f;
  letter-spacing: -.53px;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 30px;
  font-weight: 400;
  line-height: 42px;
  position: relative;
}

.advisory .text-wrapper-71 {
  color: var(--neutral-60);
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  align-self: stretch;
  position: relative;
}

.advisory .text-wrapper-72 {
  color: #4d9f98;
  font-family: var(--subtitle-BLD-font-family);
  font-size: var(--subtitle-BLD-font-size);
  font-style: var(--subtitle-BLD-font-style);
  font-weight: var(--subtitle-BLD-font-weight);
  letter-spacing: var(--subtitle-BLD-letter-spacing);
  line-height: var(--subtitle-BLD-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.advisory .frame-89 {
  background-color: var(--neutral-0);
  border: 1px solid;
  border-color: var(--neutral-50);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  width: 434px;
  height: 650px;
  padding: 0 0 94px;
  display: flex;
  position: absolute;
  top: -160px;
  left: 40px;
}

.advisory .frame-90 {
  background-color: var(--neutral-0);
  border: 1px solid;
  border-color: var(--neutral-50);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  width: 434px;
  height: 664px;
  padding: 0 0 94px;
  display: flex;
  position: absolute;
  top: -175px;
  left: 984px;
}

.advisory .frame-91 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 14px;
  width: 100%;
  margin-bottom: -50.5px;
  padding: 0 24px;
  display: flex;
  position: relative;
}

.advisory .frame-92 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.advisory .text-wrapper-73 {
  color: #1f1f1f;
  letter-spacing: -.53px;
  width: 369px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 400;
  line-height: 42px;
  position: relative;
}

.advisory .text-wrapper-74 {
  color: var(--neutral-60);
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  width: 383px;
  position: relative;
}

.advisory .overlap-25 {
  background-color: #f6f7f8;
  width: 1447px;
  height: 288px;
  position: absolute;
  top: 2491px;
  left: 0;
}

.advisory .frame-93 {
  flex-direction: column;
  align-items: center;
  gap: 25px;
  display: inline-flex;
  position: relative;
  top: 71px;
  left: 153px;
}

.advisory .mercedes-benz-logo-2 {
  object-fit: cover;
  width: 248px;
  height: 63px;
  position: relative;
}

.advisory .GE-healthcare-logo-2 {
  object-fit: cover;
  width: 164px;
  height: 92px;
  position: relative;
}

.advisory .sony-logo-2 {
  object-fit: cover;
  width: 123px;
  height: 22px;
  position: relative;
}

.advisory .shell-logo-2 {
  object-fit: cover;
  width: 115px;
  height: 65px;
  position: relative;
}

.advisory .amazon-2 {
  object-fit: cover;
  width: 178px;
  height: 55px;
  position: relative;
}

.advisory .wipro-logo-new-og-2 {
  object-fit: cover;
  width: 171px;
  height: 89px;
  position: relative;
}

.captive {
  background-color: #fff;
  flex-direction: row;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.captive .carousel {
  width: 100%;
  height: auto;
}

.captive .div-2 {
  background-color: #fff;
  position: relative;
}

.captive .overlap-wrapper {
  width: 390px;
  height: 536px;
  position: absolute;
  top: 60px;
  left: 0;
  overflow: hidden;
}

.captive .overlap {
  width: 1456px;
  height: 674px;
  position: relative;
}

.captive .frame-7 {
  background-color: var(--neutral-10);
  width: 1456px;
  height: 674px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.captive .overlap-2 {
  width: 1706px;
  height: 1182px;
  position: absolute;
  top: -4px;
  left: -252px;
}

.captive .overlap-group-3 {
  width: 1435px;
  height: 1003px;
  position: absolute;
  top: 179px;
  left: 271px;
}

.captive .ellipse-5 {
  border: 2px solid;
  border-color: var(--neutral-40);
  border-radius: 261px;
  width: 522px;
  height: 522px;
  position: absolute;
  top: 14px;
  left: 816px;
}

.captive .ellipse-6 {
  background-color: #fff;
  border-radius: 233px;
  width: 466px;
  height: 466px;
  position: absolute;
  top: 42px;
  left: 844px;
}

.captive .rectangle-4 {
  mix-blend-mode: multiply;
  background: linear-gradient(#000 0%, #d9d9d900 100%);
  width: 639px;
  height: 298px;
  position: absolute;
  top: 0;
  left: 625px;
}

.captive .rectangle-5 {
  background-color: #d9d9d9;
  width: 1435px;
  height: 498px;
  position: absolute;
  top: 505px;
  left: 0;
}

.captive .overlap-3 {
  width: 695px;
  height: 540px;
  position: absolute;
  top: 0;
  left: 0;
}

.captive .two-collegues {
  object-fit: cover;
  width: 390px;
  height: 536px;
  position: absolute;
  top: 4px;
  left: 252px;
}

.captive .friendly-partners {
  background: linear-gradient(#2c3e47 0%, #2c3e4700 100%);
  width: 695px;
  height: 540px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(180deg);
}

.captive .ellipse-7 {
  object-fit: cover;
  width: 436px;
  height: 436px;
  position: absolute;
  top: -1413px;
  left: 3072px;
}

.captive .frame-8 {
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 358px;
  padding: 0 54px;
  display: flex;
  position: absolute;
  top: 86px;
  left: 16px;
}

.captive .standardise-your {
  color: #0000;
  letter-spacing: -.8px;
  text-align: center;
  width: 304px;
  margin-top: -1px;
  margin-left: -27px;
  margin-right: -27px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 600;
  line-height: 38.4px;
  position: relative;
}

.captive .span {
  color: #fff;
}

.captive .text-wrapper-8 {
  color: #00dfc2;
}

.captive .text-wrapper-9 {
  color: var(--neutral-0);
  letter-spacing: -.16px;
  text-align: center;
  width: 282px;
  margin-left: -16px;
  margin-right: -16px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  position: relative;
}

.captive .CTA-instance {
  flex: none !important;
}

.captive .frame-wrapper {
  width: 295px;
  height: 64px;
  position: absolute;
  top: 732px;
  left: 48px;
}

.captive .frame-14 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 295px;
  display: flex;
  position: relative;
}

.captive .text-wrapper-16 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.captive .ellipse-8 {
  background-color: var(--grey-01);
  border-radius: 1px / 1.5px;
  width: 2px;
  height: 3px;
  position: relative;
}

.captive .text-wrapper-17 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  text-align: center;
  align-self: stretch;
  position: relative;
}

.captive .overlap-5 {
  position: absolute;
}

.captive .frame-15 {
  background-color: #f6f7f8;
  position: absolute;
}

.captive .frame-16 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 39px;
  left: 35px;
}

.captive .div-wrapper {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.captive .text-wrapper-18 {
  color: #294b51;
  letter-spacing: -.8px;
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 600;
  line-height: 38.4px;
  position: relative;
}

.captive .frame-17 {
  flex-wrap: wrap;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 48px 32px;
  width: 326px;
  display: flex;
  position: relative;
}

.captive .text-wrapper-19 {
  -webkit-text-stroke: .5px #2e5053;
  color: #2e5053;
  letter-spacing: -.75px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1.5px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 50px;
  font-weight: 400;
  line-height: 60px;
  position: relative;
}

.captive .text-wrapper-20 {
  color: var(--neutral-70);
  letter-spacing: -.17px;
  text-align: center;
  width: fit-content;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 400;
  line-height: 23.8px;
  position: relative;
}

.captive .frame-18 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.captive .vector {
  width: 44px;
  height: 52px;
  margin-top: -.5px;
  position: relative;
}

.captive .text-wrapper-21 {
  color: var(--neutral-70);
  letter-spacing: -.17px;
  width: 109px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 400;
  line-height: 23.8px;
  position: relative;
}

.captive .group-2 {
  width: 73.35px;
  height: 52.98px;
  margin-top: -.5px;
  position: relative;
}

.captive .text-wrapper-22 {
  color: var(--neutral-70);
  letter-spacing: -.17px;
  text-align: center;
  width: 125px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 400;
  line-height: 23.8px;
  position: relative;
}

.captive .vector-2 {
  width: 49px;
  height: 56px;
  margin-top: -.5px;
  position: relative;
}

.captive .text-wrapper-23 {
  color: var(--neutral-70);
  letter-spacing: -.17px;
  width: 123px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 400;
  line-height: 23.8px;
  position: relative;
}

.captive .frame-19 {
  flex-direction: column;
  flex: 1;
  align-items: center;
  display: flex;
  position: relative;
}

.captive .text-wrapper-24 {
  color: var(--neutral-70);
  letter-spacing: -.17px;
  text-align: center;
  width: 68px;
  height: 55px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 400;
  line-height: 23.8px;
  position: relative;
}

.captive .rectangle-6 {
  background-color: #2c3e47;
  width: 393px;
  height: 951px;
  position: absolute;
  top: 583px;
  left: 0;
}

.captive .two-collegues-2, .captive .rectangle-7 {
  position: absolute;
}

.captive .frame-20 {
  flex-direction: column;
  align-items: flex-start;
  display: inline-flex;
  position: absolute;
}

.captive .frame-21 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 16px;
  margin-bottom: 30px;
  display: inline-flex;
  position: relative;
}

.captive .standardise-your-wrapper {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.captive .standardise-your-2 {
  color: #0000;
  letter-spacing: -.53px;
  width: 565px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 700;
  line-height: 49px;
  position: relative;
}

.captive .text-wrapper-25 {
  color: #f6f7f8;
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  width: 492px;
  position: relative;
}

.captive .instance-node {
  border: unset !important;
  border-color: unset !important;
  background-color: #4d9f98 !important;
  flex: none !important;
  justify-content: center !important;
  align-items: flex-end !important;
  width: 182px !important;
  display: flex !important;
}

.captive .CTA-3 {
  font-family: var(--subtitle-BLD-font-family) !important;
  font-size: var(--subtitle-BLD-font-size) !important;
  font-style: var(--subtitle-BLD-font-style) !important;
  font-weight: var(--subtitle-BLD-font-weight) !important;
  letter-spacing: var(--subtitle-BLD-letter-spacing) !important;
  line-height: var(--subtitle-BLD-line-height) !important;
  margin-left: -1px !important;
}

.captive .CTA-4 {
  margin-right: -1px !important;
}

.captive .frame-22 {
  flex: none;
  align-items: flex-start;
  gap: 48px;
  width: 344px;
  display: flex;
  position: relative;
}

.captive .frame-23 {
  flex-direction: column;
  flex: 1;
  align-items: center;
  gap: 16px;
  display: flex;
  position: relative;
}

.captive .img-wrapper {
  background-color: #2e5053;
  border-radius: 16px;
  width: 83.55px;
  height: 82.76px;
  position: relative;
}

.captive .payroll {
  width: 44px;
  height: 44px;
  position: absolute;
  top: 19px;
  left: 20px;
}

.captive .text-wrapper-26 {
  white-space: nowrap;
  color: #fff;
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  width: 134px;
  position: relative;
}

.captive .frame-24 {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 16px;
  display: flex;
  position: relative;
}

.captive .accounting {
  width: 43px;
  height: 43px;
  position: absolute;
  top: 17px;
  left: 20px;
}

.captive .text-wrapper-27 {
  color: #fff;
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.captive .name-tag {
  width: 46px;
  height: 46px;
  position: absolute;
  top: 18px;
  left: 19px;
}

.captive .text-wrapper-28 {
  color: #fff;
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  text-align: center;
  width: 146px;
  position: relative;
}

.captive .money-yours {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 21px;
  left: 22px;
}

.captive .text-wrapper-29 {
  white-space: nowrap;
  color: #fff;
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  width: 120px;
  position: relative;
}

.captive .file {
  width: 42px;
  height: 39px;
  position: absolute;
  top: 22px;
  left: 21px;
}

.captive .insurance-agent {
  width: 42px;
  height: 42px;
  position: absolute;
  top: 20px;
  left: 21px;
}

.captive .text-wrapper-30 {
  color: #fff;
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  text-align: center;
  width: 148px;
  position: relative;
}

.captive .worker {
  width: 37px;
  height: 37px;
  position: absolute;
  top: 23px;
  left: 23px;
}

.captive .text-wrapper-31 {
  white-space: nowrap;
  color: #fff;
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  width: 148px;
  position: relative;
}

.captive .shop {
  width: 38px;
  height: 38px;
  position: absolute;
  top: 22px;
  left: 23px;
}

.captive .frame-25 {
  width: 390px;
  height: 1188px;
  position: absolute;
  top: 1534px;
  left: 0;
}

.captive .text-wrapper-32 {
  color: #1f1f1f;
  letter-spacing: -.8px;
  text-align: center;
  width: 371px;
  height: 38px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.4px;
  position: absolute;
  top: 44px;
  left: 10px;
}

.captive .overlap-group-wrapper {
  width: 278px;
  height: 340px;
  position: absolute;
  top: 104px;
  left: 48px;
}

.captive .overlap-group-4 {
  width: 296px;
  height: 329px;
  position: relative;
  top: 5px;
  left: 1px;
}

.captive .vector-3 {
  width: 254px;
  height: 254px;
  position: absolute;
  top: 16px;
  left: 10px;
}

.captive .group-3 {
  width: 275px;
  height: 329px;
  position: absolute;
  top: 0;
  left: 0;
}

.captive .text-wrapper-33 {
  color: #4d9f98;
  letter-spacing: -.36px;
  white-space: nowrap;
  width: 142px;
  height: 39px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  position: absolute;
  top: 65px;
  left: 65px;
}

.captive .online-system-driven {
  color: var(--neutral-60);
  letter-spacing: -.12px;
  width: 174px;
  height: 113px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 12px;
  font-weight: 600;
  line-height: 16.8px;
  position: absolute;
  top: 110px;
  left: 55px;
}

.captive .frame-26 {
  background-image: url("group-28.8ccfec57.png");
  background-size: 100% 100%;
  width: 160px;
  height: 160px;
  position: absolute;
  top: 169px;
  left: 136px;
}

.captive .add-user-group-man {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 58px;
  left: 70px;
}

.captive .vector-4 {
  width: 35px;
  height: 22px;
  position: absolute;
  top: 144px;
  left: -17px;
}

.captive .frame-27 {
  width: 278px;
  height: 340px;
  position: absolute;
  top: 465px;
  left: 48px;
}

.captive .text-wrapper-34 {
  color: #4d9f98;
  letter-spacing: -.36px;
  white-space: nowrap;
  height: 34px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  position: absolute;
  top: 70px;
  left: 70px;
}

.captive .system-driven {
  color: var(--neutral-60);
  letter-spacing: -.12px;
  width: 174px;
  height: 103px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 12px;
  font-weight: 600;
  line-height: 16.8px;
  position: absolute;
  top: 104px;
  left: 51px;
}

.captive .frame-28 {
  background-image: url("group-30.63c311fa.png");
  background-size: 100% 100%;
  width: 160px;
  height: 160px;
  position: absolute;
  top: 169px;
  left: 136px;
}

.captive .add-user-group-man-2 {
  width: 34px;
  height: 34px;
  position: absolute;
  top: 58px;
  left: 70px;
}

.captive .frame-29 {
  width: 278px;
  height: 340px;
  position: absolute;
  top: 826px;
  left: 48px;
}

.captive .group-4 {
  width: 275px;
  height: 275px;
  position: absolute;
  top: 0;
  left: 0;
}

.captive .text-wrapper-35 {
  color: #4d9f98;
  letter-spacing: -.36px;
  white-space: nowrap;
  height: 34px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  position: absolute;
  top: 71px;
  left: 78px;
}

.captive .online-support {
  color: var(--neutral-60);
  letter-spacing: -.12px;
  width: 174px;
  height: 68px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 12px;
  font-weight: 600;
  line-height: 16.8px;
  position: absolute;
  top: 114px;
  left: 59px;
}

.captive .customer-support {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 61px;
  left: 71px;
}

.captive .logo-2 {
  object-fit: cover;
  width: 145px;
  height: 67.67px;
  position: relative;
}

.captive .holder {
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.captive .frame-30 {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 90.33px;
  padding: 10px 8px;
  display: flex;
  position: relative;
}

.captive .text-wrapper-36 {
  color: #000;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: relative;
}

.captive .text-wrapper-37 {
  color: #000;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-left: -2.83px;
  margin-right: -2.83px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: relative;
}

.captive .frame-31 {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 80px;
  padding: 10px 8px;
  display: flex;
  position: relative;
}

.captive .CTA-5 {
  border-color: var(--primary-02) !important;
  width: 179px !important;
  height: 69px !important;
  display: flex !important;
}

.captive .CTA-6 {
  color: #000 !important;
  margin-top: unset !important;
}

.captive .carousel-2 {
  width: 390px;
  height: 796px;
  position: absolute;
  top: 3327px;
  left: 0;
  overflow: hidden;
}

.captive .frame-32 {
  width: 667px;
  height: 1425px;
  position: absolute;
  top: 0;
  left: 0;
}

.captive .overlap-group-5 {
  width: 687px;
  height: 923px;
  position: relative;
  top: -24px;
  left: -134px;
}

.captive .rectangle-8 {
  width: 390px;
  height: 485px;
  position: absolute;
  top: 24px;
  left: 134px;
}

.captive .frame-33 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  height: 261px;
  display: inline-flex;
  position: absolute;
  top: 166px;
  left: 214px;
}

.captive .shutterstock {
  width: 390px;
  height: 401px;
  position: absolute;
  top: 419px;
  left: 134px;
}

.captive .rectangle-9 {
  filter: blur(40px);
  background: linear-gradient(90deg, #589993 5.95%, #4d9f98 20.62% 48.33%, #fcfcfc00 71.42%);
  width: 923px;
  height: 687px;
  position: absolute;
  top: 118px;
  left: -118px;
  transform: rotate(90deg);
}

.captive .frame-34 {
  flex-direction: column;
  align-items: flex-start;
  gap: 33px;
  display: inline-flex;
  position: absolute;
  top: 106px;
  left: 150px;
}

.captive .we-are-your-growth {
  color: #fff;
  letter-spacing: -.22px;
  width: 358px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 22px;
  font-weight: 600;
  line-height: 30.8px;
  position: relative;
}

.captive .CTA-7 {
  border: unset !important;
  border-color: unset !important;
  background-color: #2e5053 !important;
  flex: none !important;
  justify-content: center !important;
  align-items: flex-end !important;
  width: 127px !important;
  display: flex !important;
}

.captive .CTA-8 {
  font-family: var(--subtitle-BLD-font-family) !important;
  font-size: var(--subtitle-BLD-font-size) !important;
  font-style: var(--subtitle-BLD-font-style) !important;
  font-weight: var(--subtitle-BLD-font-weight) !important;
  letter-spacing: var(--subtitle-BLD-letter-spacing) !important;
  line-height: var(--subtitle-BLD-line-height) !important;
  margin-left: -2px !important;
}

.captive .CTA-9 {
  margin-right: -2px !important;
}

.captive .frame-35 {
  width: 1440px;
  height: 580px;
  position: absolute;
  top: 0;
  left: 1440px;
  overflow: hidden;
}

.captive .rectangle-10 {
  width: 1383px;
  height: 634px;
  position: absolute;
  top: -4939px;
  left: 1101px;
}

.captive .overlap-6 {
  filter: blur(80px);
  background: linear-gradient(#000 0%, #071103 25%, #202020 44.79%, #2f2f2f 61.46%, #0000 100%);
  width: 1684px;
  height: 714px;
  position: absolute;
  top: -60px;
  left: -116px;
}

.captive .frame-36 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: absolute;
  top: 242px;
  left: 196px;
}

.captive .text-wrapper-38 {
  color: var(--neutral-0);
  letter-spacing: -.53px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 600;
  line-height: 49px;
  position: relative;
}

.captive .frame-37 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.captive .our-client-and-2 {
  color: #f6f7f8;
  letter-spacing: -.17px;
  width: 436px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 400;
  line-height: 23.8px;
  position: relative;
}

.captive .CTA-10 {
  all: unset;
  box-sizing: border-box;
  flex: none;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.captive .text-wrapper-39 {
  color: var(--neutral-0);
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 23.8px;
  position: relative;
}

.captive .arrow-forward-4 {
  width: 24px;
  height: 24px;
  margin-top: -5311px;
  margin-right: -674px;
  position: relative;
}

.captive .text-wrapper-40 {
  color: var(--neutral-0);
  font-family: var(--h4-REG-font-family);
  font-size: var(--h4-REG-font-size);
  font-style: var(--h4-REG-font-style);
  font-weight: var(--h4-REG-font-weight);
  letter-spacing: var(--h4-REG-letter-spacing);
  line-height: var(--h4-REG-line-height);
  white-space: nowrap;
  height: 41px;
  position: absolute;
  top: 120px;
  left: 196px;
}

.captive .frame-38 {
  align-items: flex-start;
  gap: 5px;
  display: inline-flex;
  position: absolute;
  top: 560px;
  left: 196px;
}

.captive .ellipse-9 {
  background-color: #d9d9d952;
  border-radius: 5.5px;
  width: 11px;
  height: 11px;
  position: relative;
}

.captive .ellipse-10 {
  background-color: #e9e9e9;
  border-radius: 5.5px;
  width: 11px;
  height: 11px;
  position: relative;
}

.captive .carousel-03 {
  position: absolute !important;
  top: 0 !important;
  left: 2880px !important;
}

.captive .carousel-instance {
  margin-top: -5334px !important;
  margin-left: -673px !important;
}

.captive .overlap-7 {
  position: absolute;
}

.captive .rectangle-11 {
  background-color: #f6f7f8;
  width: 1440px;
  height: 264px;
  display: block;
  position: absolute;
  top: 0;
  left: 6px;
}

.captive .rectangle-12 {
  background-color: #2c3e47;
  width: 1446px;
  height: 733px;
  position: absolute;
  top: 0;
  left: 0;
}

.captive .rectangle-13 {
  width: 637px;
  height: 824px;
  position: absolute;
  top: 242px;
  left: 6px;
}

.captive .rectangle-14 {
  width: 577px;
  height: 824px;
  position: absolute;
  top: 219px;
  left: 869px;
}

.captive .text-wrapper-41 {
  color: #1f1f1f;
  letter-spacing: -1.05px;
  text-align: center;
  width: 906px;
  height: 50px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 42px;
  font-weight: 400;
  line-height: 50.4px;
  position: absolute;
  top: 1050px;
  left: 257px;
}

.captive .frame-39 {
  width: 742px;
  height: 307px;
  position: absolute;
  top: 0;
  left: 0;
}

.captive .overlap-group-6 {
  height: 307px;
  position: relative;
}

.captive .rectangle-15 {
  background-color: #f6f7f8;
  width: 390px;
  height: 307px;
  position: absolute;
  top: 0;
  left: 185px;
}

.captive .frame-40 {
  flex-direction: column;
  align-items: center;
  gap: 25px;
  display: inline-flex;
  position: absolute;
  top: 76px;
  left: 0;
}

.captive .frame-41 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 51px;
  display: inline-flex;
  position: relative;
}

.captive .frame-42 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 26px;
  display: inline-flex;
  position: relative;
}

.captive .frame-43 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.captive .text-wrapper-42 {
  color: var(--neutral-60);
  letter-spacing: -.2px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  position: relative;
}

.captive .frame-44 {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 29px;
  display: inline-flex;
  position: relative;
}

.captive .mercedes-benz-logo {
  object-fit: cover;
  width: 148px;
  height: 37px;
  position: relative;
}

.captive .GE-healthcare-logo {
  object-fit: cover;
  width: 98px;
  height: 55px;
  position: relative;
}

.captive .sony-logo {
  object-fit: cover;
  width: 74px;
  height: 13px;
  position: relative;
}

.captive .shell-logo {
  object-fit: cover;
  width: 69px;
  height: 39px;
  position: relative;
}

.captive .amazon {
  object-fit: cover;
  width: 70px;
  height: 33px;
  position: relative;
}

.captive .wipro-logo-new-og {
  object-fit: cover;
  width: 102px;
  height: 53px;
  position: relative;
}

.captive .frame-45 {
  flex-direction: column;
  align-items: center;
  display: inline-flex;
  position: absolute;
}

.captive .frame-46 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-end;
  gap: 32px;
  width: 328px;
  display: flex;
  position: relative;
}

.captive .frame-47 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 24px;
  width: 328px;
  display: flex;
  position: relative;
}

.captive .text-wrapper-43 {
  color: var(--secondary-04);
  letter-spacing: -.8px;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.4px;
  position: relative;
}

.captive .text-wrapper-44 {
  color: var(--neutral-80);
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  text-align: center;
  width: 297px;
  position: relative;
}

.captive .group-5 {
  width: 390px;
  height: 360px;
  position: relative;
}

.captive .frame-48 {
  height: 360px;
}

.captive .overlap-group-7 {
  background-color: var(--neutral-70);
  width: 1440px;
  height: 360px;
}

.captive .frame-49 {
  flex-direction: column;
  align-items: center;
  gap: 24px;
  display: inline-flex;
  position: relative;
  top: 85px;
  left: 41px;
}

.captive .text-wrapper-45 {
  color: var(--neutral-0);
  letter-spacing: -.8px;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.4px;
  position: relative;
}

.captive .are-we-a-good-fit {
  color: #f6f7f8;
  letter-spacing: -.2px;
  text-align: center;
  width: 309px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.captive .CTA-11 {
  cursor: pointer;
  background-color: var(--secondary-02);
  box-sizing: border-box;
  border: none;
  outline: none;
  flex: none;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  display: inline-flex;
  position: relative;
}

.captive .text-wrapper-46 {
  color: var(--neutral-0);
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.captive .arrow-forward-5 {
  width: 24px;
  height: 24px;
  position: relative;
}

.captive .frame-50 {
  flex: none;
  width: 497.74px;
  position: relative;
}

.captive .frame-51 {
  align-items: center;
  gap: 17px;
  display: inline-flex;
  position: absolute;
  top: 91px;
  left: 86px;
}

.captive .text-wrapper-47 {
  color: #294b51;
  letter-spacing: -1.05px;
  width: 421px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 42px;
  font-weight: 600;
  line-height: 50.4px;
  position: relative;
}

.captive .frame-52 {
  flex: none;
  align-items: flex-start;
  gap: 61px;
  display: inline-flex;
  position: relative;
}

.captive .frame-53 {
  flex-direction: column;
  flex: none;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.captive .text-wrapper-48 {
  -webkit-text-stroke: .5px #2e5053;
  color: #2e5053;
  letter-spacing: -.75px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1.5px;
  margin-left: -.5px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 50px;
  font-weight: 400;
  line-height: 60px;
  position: relative;
}

.captive .frame-54 {
  flex-direction: column;
  align-items: center;
  gap: 74px;
  display: inline-flex;
  position: absolute;
  top: 512px;
  left: 220px;
}

.captive .frame-55 {
  flex: none;
  align-items: flex-start;
  gap: 155px;
  display: inline-flex;
  position: relative;
}

.captive .frame-56 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.captive .text-wrapper-49 {
  white-space: nowrap;
  color: #fff;
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  width: 168px;
  position: relative;
}

.captive .frame-57 {
  flex: none;
  align-items: flex-end;
  gap: 131px;
  width: 987px;
  display: flex;
  position: relative;
}

.captive .text-wrapper-50 {
  color: #fff;
  white-space: nowrap;
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  width: 212px;
  position: relative;
}

.captive .frame-58 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-right: -2px;
  display: inline-flex;
  position: relative;
}

.captive .joulestowatts {
  color: #0000;
  letter-spacing: -.29px;
  text-align: center;
  width: 681px;
  height: 123px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 29px;
  font-weight: 600;
  line-height: 40.6px;
  position: absolute;
  top: 305px;
  left: 361px;
}

.captive .overlap-8 {
  width: 1446px;
  height: 454px;
  position: absolute;
  top: 2207px;
  left: 0;
}

.captive .carousel-3 {
  width: 1446px;
  height: 454px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.captive .frame-59 {
  background-size: 100% 100%;
  width: 1440px;
  height: 454px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.captive .overlap-group-8 {
  width: 1722px;
  height: 790px;
  position: relative;
  top: -81px;
  left: -186px;
}

.captive .shutterstock-2 {
  width: 765px;
  height: 454px;
  position: absolute;
  top: 81px;
  left: 861px;
}

.captive .rectangle-16 {
  filter: blur(40px);
  background: linear-gradient(90deg, #589993 5.95%, #4d9f98 20.62% 48.33%, #fcfcfc00 71.42%);
  width: 1722px;
  height: 790px;
  position: absolute;
  top: 0;
  left: 0;
}

.captive .carousel-02 {
  position: absolute !important;
  top: 0 !important;
  left: 1440px !important;
}

.captive .carousel-02-instance {
  width: 2600px !important;
  height: 1265px !important;
}

.captive .frame-60 {
  width: 1383px !important;
  height: 634px !important;
  top: 631px !important;
  left: 1217px !important;
}

.captive .frame-61 {
  margin-bottom: -199px !important;
  margin-right: -674px !important;
}

.captive .carousel-03-instance {
  margin-bottom: -176px !important;
  margin-left: -673px !important;
}

.captive .CTA-12 {
  border: unset !important;
  border-color: unset !important;
  background-color: #2e5053 !important;
  justify-content: center !important;
  align-items: flex-end !important;
  width: 182px !important;
  display: flex !important;
  position: absolute !important;
  top: 318px !important;
  left: 73px !important;
}

.captive .CTA-13 {
  font-family: var(--subtitle-BLD-font-family) !important;
  font-size: var(--subtitle-BLD-font-size) !important;
  font-style: var(--subtitle-BLD-font-style) !important;
  font-weight: var(--subtitle-BLD-font-weight) !important;
  letter-spacing: var(--subtitle-BLD-letter-spacing) !important;
  line-height: var(--subtitle-BLD-line-height) !important;
}

.captive .we-are-your-growth-2 {
  color: #fff;
  letter-spacing: -.29px;
  width: 587px;
  height: 164px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 29px;
  font-weight: 600;
  line-height: 40.6px;
  position: absolute;
  top: 80px;
  left: 73px;
}

.captive .group-6 {
  width: 1440px;
  height: 380px;
  position: absolute;
  top: 3011px;
  left: 0;
}

.captive .overlap-9 {
  height: 380px;
  position: relative;
}

.captive .frame-62 {
  background-color: #4d4d4d;
  width: 1440px;
  height: 380px;
  position: absolute;
  top: 0;
  left: 0;
}

.captive .frame-63 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 69px;
  left: 152px;
}

.captive .frame-64 {
  flex-direction: column;
  flex: none;
  align-items: flex-end;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.captive .text-wrapper-51 {
  color: #fff;
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.captive .are-we-a-good-fit-2 {
  color: var(--neutral-10);
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  text-align: right;
  width: 429px;
  position: relative;
}

.captive .CTA-14 {
  all: unset;
  background-color: var(--secondary-04);
  box-sizing: border-box;
  flex: none;
  align-items: center;
  gap: 10px;
  padding: 12px 16px;
  display: inline-flex;
  position: relative;
}

.captive .text-wrapper-52 {
  color: #fff;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: relative;
}

.captive .CTA-15 {
  cursor: pointer;
  background-color: var(--secondary-02);
  box-sizing: border-box;
  border: none;
  outline: none;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  display: inline-flex;
  position: absolute;
  top: 267px;
  left: 819px;
}

.captive .frame-65 {
  background-color: var(--secondary-03);
  width: 720px;
  height: 380px;
  position: absolute;
  top: 0;
  left: 0;
}

.captive .overlap-group-9 {
  background-color: var(--neutral-10);
  width: 720px;
  height: 380px;
  position: absolute;
  top: 0;
  left: 0;
}

.captive .frame-66 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 32px;
  display: inline-flex;
  position: relative;
  top: 49px;
  left: 128px;
}

.captive .text-wrapper-53 {
  color: var(--secondary-04);
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.captive .text-wrapper-54 {
  color: var(--neutral-80);
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  text-align: right;
  width: 500px;
  position: relative;
}

.captive .frame-67 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 52px;
  left: 817px;
}

.captive .text-wrapper-55 {
  color: #fff;
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.captive .text-wrapper-56 {
  color: var(--neutral-10);
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  width: 429px;
  position: relative;
}

.captive .frame-68 {
  width: 1440px;
  height: 356px;
  position: absolute;
  top: 3391px;
  left: 2px;
}

.captive .overlap-10 {
  height: 356px;
  position: relative;
}

.captive .section-industries {
  background-color: var(--secondary-04);
  width: 1440px;
  height: 356px;
  position: absolute;
  top: 0;
  left: 0;
}

.captive .frame-69 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: relative;
  top: 75px;
  left: 80px;
}

.captive .frame-70 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: relative;
}

.captive .text-wrapper-57 {
  color: #fff;
  font-family: var(--h3-REG-font-family);
  font-size: var(--h3-REG-font-size);
  font-style: var(--h3-REG-font-style);
  font-weight: var(--h3-REG-font-weight);
  letter-spacing: var(--h3-REG-letter-spacing);
  line-height: var(--h3-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.captive .are-we-a-good-fit-3 {
  color: var(--neutral-30);
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  width: 326px;
  position: relative;
}

.captive .CTA-wrapper {
  flex: none;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.captive .CTA-16 {
  border: unset !important;
  border-color: unset !important;
  background-color: #4d9f98 !important;
  justify-content: center !important;
  align-items: flex-end !important;
  width: 182px !important;
  display: flex !important;
}

.captive .container {
  align-items: center;
  gap: 24px;
  width: 843px;
  height: 354px;
  padding: 56px 0;
  display: flex;
  position: absolute;
  top: 2px;
  left: 551px;
  overflow: hidden;
}

.captive .BFSI {
  background-image: url("rectangle-24-2.b02836c3.webp");
  background-size: 100% 100%;
  width: 264px;
  height: 240px;
  position: relative;
}

.captive .text-wrapper-58 {
  color: #fff;
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  white-space: nowrap;
  height: 34px;
  position: absolute;
  top: 179px;
  left: 27px;
}

.captive .div-wrapper-2 {
  background-image: url("rectangle-23.69c6a4d4.webp");
  background-position: 50%;
  background-size: cover;
  width: 263px;
  height: 240px;
  position: relative;
}

.captive .telecom {
  background-image: url("rectangle-25-1.740fe525.webp");
  background-size: 100% 100%;
  width: 263px;
  height: 240px;
  position: relative;
}

.captive .telecom-3 {
  background-image: url("rectangle-25-2.60d7a48a.webp");
  background-position: 50%;
  background-size: cover;
  width: 263px;
  height: 240px;
  position: relative;
}

.captive .telecom-4 {
  background-image: url("rectangle-25-3.09a31b27.webp");
  background-position: 50%;
  background-size: cover;
  width: 263px;
  height: 240px;
  position: relative;
}

.captive .overlap-group-10 {
  width: 79px;
  height: 34px;
  position: relative;
  top: 179px;
  left: 27px;
}

.captive .text-wrapper-59 {
  color: #fff;
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  white-space: nowrap;
  height: 34px;
  position: absolute;
  top: 0;
  left: 0;
}

.captive .arrow {
  background-color: #fff;
  border-radius: 30px;
  width: 60px;
  height: 60px;
  position: absolute;
  top: 151px;
  left: 1365px;
  box-shadow: -3px 1px 16px #00000040;
}

.captive .arrow-forward-ios {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 18px;
  left: 18px;
}

.captive .group-7 {
  width: 1300px;
  height: 441px;
  position: absolute;
  top: 1741px;
  left: 20px;
}

.captive .overlap-11 {
  height: 441px;
  position: relative;
}

.captive .frame-71 {
  width: 1300px;
  height: 441px;
  position: absolute;
  top: 0;
  left: 0;
}

.captive .overlap-group-11 {
  background-image: url("group-51.fbf08a4e.webp");
  background-size: 100% 100%;
  width: 1231px;
  height: 386px;
  position: relative;
  left: 70px;
}

.captive .text-wrapper-60 {
  color: #4d9f98;
  letter-spacing: -.53px;
  white-space: nowrap;
  width: 183px;
  height: 51px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 600;
  line-height: 49px;
  position: absolute;
  top: 134px;
  left: 85px;
}

.captive .text-wrapper-61 {
  color: #4d9f98;
  letter-spacing: -.53px;
  white-space: nowrap;
  width: 115px;
  height: 51px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 600;
  line-height: 49px;
  position: absolute;
  top: 122px;
  left: 527px;
}

.captive .text-wrapper-62 {
  color: #4d9f98;
  letter-spacing: -.53px;
  white-space: nowrap;
  width: 129px;
  height: 51px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 600;
  line-height: 49px;
  position: absolute;
  top: 121px;
  left: 949px;
}

.captive .online-system-driven-2 {
  color: var(--neutral-60);
  font-family: var(--paragraph-MED-font-family);
  font-size: var(--paragraph-MED-font-size);
  font-style: var(--paragraph-MED-font-style);
  font-weight: var(--paragraph-MED-font-weight);
  letter-spacing: var(--paragraph-MED-letter-spacing);
  line-height: var(--paragraph-MED-line-height);
  width: 226px;
  height: 103px;
  position: absolute;
  top: 201px;
  left: 65px;
}

.captive .system-driven-2 {
  color: var(--neutral-60);
  font-family: var(--paragraph-MED-font-family);
  font-size: var(--paragraph-MED-font-size);
  font-style: var(--paragraph-MED-font-style);
  font-weight: var(--paragraph-MED-font-weight);
  letter-spacing: var(--paragraph-MED-letter-spacing);
  line-height: var(--paragraph-MED-line-height);
  width: 226px;
  height: 103px;
  position: absolute;
  top: 192px;
  left: 506px;
}

.captive .online-support-2 {
  color: var(--neutral-60);
  font-family: var(--paragraph-MED-font-family);
  font-size: var(--paragraph-MED-font-size);
  font-style: var(--paragraph-MED-font-style);
  font-weight: var(--paragraph-MED-font-weight);
  letter-spacing: var(--paragraph-MED-letter-spacing);
  line-height: var(--paragraph-MED-line-height);
  width: 226px;
  height: 83px;
  position: absolute;
  top: 189px;
  left: 928px;
}

.captive .payroll-2 {
  width: 47px;
  height: 47px;
  position: absolute;
  top: 67px;
  left: 704px;
}

.captive .customer-support-2 {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 67px;
  left: 1135px;
}

.captive .add-user-group-man-3 {
  width: 43px;
  height: 43px;
  position: absolute;
  top: 71px;
  left: 348px;
}

.captive .overlap-12 {
  background-color: #f6f7f8;
  width: 1446px;
  height: 350px;
  position: absolute;
  top: 2661px;
  left: 0;
}

.captive .frame-72 {
  flex-direction: column;
  align-items: center;
  gap: 25px;
  display: inline-flex;
  position: relative;
  top: 103px;
  left: 156px;
}

.captive .mercedes-benz-logo-2 {
  object-fit: cover;
  width: 248px;
  height: 63px;
  position: relative;
}

.captive .GE-healthcare-logo-2 {
  object-fit: cover;
  width: 164px;
  height: 92px;
  position: relative;
}

.captive .sony-logo-2 {
  object-fit: cover;
  width: 123px;
  height: 22px;
  position: relative;
}

.captive .shell-logo-2 {
  object-fit: cover;
  width: 115px;
  height: 65px;
  position: relative;
}

.captive .amazon-2 {
  object-fit: cover;
  width: 178px;
  height: 55px;
  position: relative;
}

.captive .wipro-logo-new-og-2 {
  object-fit: cover;
  width: 171px;
  height: 89px;
  position: relative;
}

.captive .rectangle-5-captive {
  align-self: center;
  width: 969px;
  height: 524px;
  position: absolute;
  top: 80px;
  left: 210px;
}

.captives {
  background-color: #fff;
  flex-direction: row;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.captives .carousel {
  width: 100%;
  height: 450px;
}

.captives .div-2 {
  background-color: #fff;
  position: relative;
}

.captives .header {
  width: 390px !important;
  height: 60px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.captives .footer {
  background-color: var(--white);
  width: 390px;
  height: 860px;
  position: absolute;
  top: 4595px;
  left: 0;
  overflow: hidden;
}

.captives .overlap-group-2 {
  width: 390px;
  height: 907px;
  position: relative;
}

.captives .frame-4 {
  background-color: var(--neutral-90);
  width: 390px;
  height: 907px;
  position: absolute;
  top: 0;
  left: 0;
}

.captives .frame-5 {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: absolute;
  top: 115px;
  left: 26px;
}

.captives .text-wrapper-5 {
  color: #fff;
  font-family: var(--paragraph-ALCPS-font-family);
  font-size: var(--paragraph-ALCPS-font-size);
  font-style: var(--paragraph-ALCPS-font-style);
  font-weight: var(--paragraph-ALCPS-font-weight);
  letter-spacing: var(--paragraph-ALCPS-letter-spacing);
  line-height: var(--paragraph-ALCPS-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.captives .p {
  color: #f6f7f8;
  font-family: var(--paragraph-REG-font-family);
  font-size: var(--paragraph-REG-font-size);
  font-style: var(--paragraph-REG-font-style);
  font-weight: var(--paragraph-REG-font-weight);
  letter-spacing: var(--paragraph-REG-letter-spacing);
  line-height: var(--paragraph-REG-line-height);
  width: 324px;
  position: relative;
}

.captives .DIV {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 113px;
  display: flex;
  position: absolute;
  top: 272px;
  left: 195px;
}

.captives .text-wrapper-6 {
  color: var(--white);
  letter-spacing: -.2px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.captives .text-wrapper-7 {
  color: var(--white);
  letter-spacing: -.2px;
  white-space: nowrap;
  width: fit-content;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.captives .DIV-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 113px;
  display: flex;
  position: absolute;
  top: 272px;
  left: 26px;
}

.captives .frame-6 {
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 583px;
  left: 69px;
}

.captives .group {
  width: 37px;
  height: 52px;
  position: relative;
}

.captives .text-wrapper-8 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 38px;
  left: 1px;
}

.captives .face-book {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 0;
  left: 0;
}

.captives .frame-7 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.captives .frame-8 {
  flex: none;
  position: relative;
}

.captives .text-wrapper-9 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  width: fit-content;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: relative;
}

.captives .facebook {
  width: 35px;
  height: 35px;
  position: relative;
}

.captives .frame-9 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.captives .logo {
  width: 116px;
  height: 56px;
  position: absolute;
  top: 39px;
  left: 24px;
}

.captives .frame-wrapper {
  width: 295px;
  height: 64px;
  position: absolute;
  top: 732px;
  left: 48px;
}

.captives .frame-10 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 295px;
  display: flex;
  position: relative;
}

.captives .text-wrapper-10 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.captives .ellipse-3 {
  background-color: var(--grey-01);
  border-radius: 1px / 1.5px;
  width: 2px;
  height: 3px;
  position: relative;
}

.captives .text-wrapper-11 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  text-align: center;
  align-self: stretch;
  position: relative;
}

.captives .overlap {
  position: absolute;
  left: 0;
}

.captives .rectangle-2 {
  background-color: #e1e4e9;
  width: 1440px;
  height: 567px;
  position: absolute;
  top: 0;
  left: 0;
}

.captives .element {
  object-fit: cover;
  width: 566px;
  height: 565px;
  position: absolute;
  top: 258px;
  left: 431px;
}

.captives .frame-11 {
  justify-content: center;
  align-items: center;
  gap: 392px;
  display: inline-flex;
  position: absolute;
  top: 168px;
  left: 77px;
}

.captives .component {
  width: 437px;
  height: 180px;
  position: relative;
}

.captives .rely-on {
  color: #0000;
  letter-spacing: -.75px;
  width: 484px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 50px;
  font-weight: 400;
  line-height: 60px;
  position: absolute;
  top: -1px;
  left: 0;
}

.captives .span {
  color: #2c3e47;
}

.captives .text-wrapper-12 {
  color: #4d9f98;
}

.captives .frame-12 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: relative;
}

.captives .text-wrapper-13 {
  color: #000;
  letter-spacing: -.2px;
  width: 450px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.captives .CTA-instance {
  border: unset !important;
  border-color: unset !important;
  background-color: #4d9f98 !important;
  flex: none !important;
  justify-content: center !important;
  align-items: flex-end !important;
  width: 182px !important;
  display: flex !important;
}

.captives .instance-node {
  margin-left: -1px !important;
  font-weight: 700 !important;
}

.captives .CTA-2 {
  margin-right: -1px !important;
}

.captives .rectangle-3 {
  background-color: #12212ff0;
  width: 1440px;
  height: 829px;
  position: absolute;
  top: 526px;
  left: 0;
}

.captives .rectangle-4 {
  width: 256px;
  height: 824px;
  position: absolute;
  top: 326px;
  left: 1184px;
}

.captives .rectangle-5 {
  width: 269px;
  height: 824px;
  position: absolute;
  top: 304px;
  left: 0;
}

.captives .rectangle-5-advisory {
  align-self: center;
  width: 969px;
  height: 524px;
  position: absolute;
  top: 80px;
  left: 150px;
}

.captives .frame-13 {
  align-items: center;
  gap: 108px;
  display: inline-flex;
  position: absolute;
  top: 609px;
  left: 81px;
}

.captives .frame-14 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 6px;
  display: inline-flex;
  position: relative;
}

.captives .text-wrapper-14 {
  color: #fff;
  letter-spacing: -.68px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 45px;
  font-weight: 600;
  line-height: 63px;
  position: relative;
}

.captives .managing-a-company-s {
  color: #d9d9d9;
  letter-spacing: -.18px;
  width: 528px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
  position: relative;
}

.captives .div-wrapper {
  align-items: center;
  gap: 8px;
  width: 648px;
  display: flex;
  position: relative;
}

.captives .frame-15 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 12px;
  margin-right: -23px;
  display: inline-flex;
  position: relative;
}

.captives .frame-16 {
  flex: none;
  align-items: flex-start;
  gap: 59px;
  display: inline-flex;
  position: relative;
}

.captives .frame-17 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 15px;
  display: inline-flex;
  position: relative;
}

.captives .frame-18 {
  flex: none;
  align-items: center;
  gap: 17px;
  margin-top: 20px;
  display: inline-flex;
  position: relative;
}

.captives .vector {
  width: 16px;
  height: 16px;
  position: relative;
}

.captives .frame-19 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.captives .text-wrapper-15 {
  color: var(--neutral-0);
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  width: 1250px;
  margin-top: -1px;
  position: relative;
}

.captives .text-noraml {
  font-size: 16px;
}

.captives .text-wrapper-16 {
  color: var(--neutral-0);
  letter-spacing: -.2px;
  width: 204px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.captives .div-3 {
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.captives .text-wrapper-17 {
  color: var(--neutral-0);
  letter-spacing: -.2px;
  width: 343px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.captives .procurement-and {
  color: var(--neutral-0);
  letter-spacing: -.2px;
  width: 320px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.captives .group-2 {
  background: linear-gradient(#1e454d 0%, #2967749e 100%);
  border-radius: 16px;
  width: 347px;
  height: 334px;
  position: absolute;
  top: 984px;
  left: 1013px;
}

.captives .frame-20 {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 262px;
  display: flex;
  position: absolute;
  top: 43px;
  left: 34px;
}

.captives .text-wrapper-18 {
  color: var(--neutral-40);
  letter-spacing: -.29px;
  width: 201px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 29px;
  font-weight: 600;
  line-height: 40.6px;
  position: relative;
}

.captives .frame-21 {
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 262px;
  display: flex;
  position: absolute;
  top: 104px;
  left: 34px;
}

.captives .frame-22 {
  flex: none;
  align-items: center;
  gap: 17px;
  margin-right: -40px;
  display: inline-flex;
  position: relative;
}

.captives .text-wrapper-19 {
  color: var(--neutral-40);
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  width: 269px;
  margin-top: -1px;
  position: relative;
}

.captives .text-wrapper-20 {
  color: var(--neutral-40);
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  width: 204px;
  margin-top: -1px;
  position: relative;
}

.captives .frame-23 {
  flex: none;
  align-items: center;
  gap: 17px;
  margin-right: -75px;
  display: inline-flex;
  position: relative;
}

.captives .text-wrapper-21 {
  color: var(--neutral-40);
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  width: 304px;
  margin-top: -1px;
  position: relative;
}

.captives .text-wrapper-22 {
  color: #d9d9d9;
  letter-spacing: -.82px;
  width: 196px;
  height: 40px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 33px;
  font-weight: 400;
  line-height: 39.6px;
  position: absolute;
  top: 851px;
  left: 627px;
}

.captives .group-3 {
  width: 504px;
  height: 442px;
  position: absolute;
  top: 930px;
  left: 81px;
}

.captives .overlap-2 {
  width: 146px;
  height: 168px;
  position: absolute;
  top: 274px;
  left: 344px;
}

.captives .group-4 {
  width: 22px;
  height: 135px;
  position: absolute;
  top: 0;
  left: 63px;
}

.captives .rectangle-6 {
  background-color: #f6b3b8;
  border-radius: 4px;
  width: 160px;
  height: 37px;
  position: absolute;
  top: 39px;
  left: 0;
}

.captives .rectangle-7 {
  background-color: #006682;
  border-radius: 4px;
  width: 160px;
  height: 37px;
  position: absolute;
  top: 85px;
  left: 0;
}

.captives .rectangle-8 {
  background-color: #4d9f98;
  border-radius: 4px;
  width: 160px;
  height: 36px;
  position: absolute;
  top: 132px;
  left: 0;
}

.captives .text-wrapper-23 {
  color: #212f3c;
  font-family: var(--paragraph-MED-font-family);
  font-size: var(--paragraph-MED-font-size);
  font-style: var(--paragraph-MED-font-style);
  font-weight: var(--paragraph-MED-font-weight);
  letter-spacing: var(--paragraph-MED-letter-spacing);
  line-height: var(--paragraph-MED-line-height);
  white-space: nowrap;
  width: 99px;
  height: 21px;
  position: absolute;
  top: 47px;
  left: 28px;
}

.captives .text-wrapper-24 {
  color: #fff;
  font-family: var(--paragraph-MED-font-family);
  font-size: var(--paragraph-MED-font-size);
  font-style: var(--paragraph-MED-font-style);
  font-weight: var(--paragraph-MED-font-weight);
  letter-spacing: var(--paragraph-MED-letter-spacing);
  line-height: var(--paragraph-MED-line-height);
  white-space: nowrap;
  width: 90px;
  height: 21px;
  position: absolute;
  top: 93px;
  left: 32px;
}

.captives .text-wrapper-25 {
  color: #fff;
  font-family: var(--paragraph-MED-font-family);
  font-size: var(--paragraph-MED-font-size);
  font-style: var(--paragraph-MED-font-style);
  font-weight: var(--paragraph-MED-font-weight);
  letter-spacing: var(--paragraph-MED-letter-spacing);
  line-height: var(--paragraph-MED-line-height);
  white-space: nowrap;
  width: 122px;
  height: 21px;
  position: absolute;
  top: 139px;
  left: 13px;
}

.captives .group-wrapper {
  width: 498px;
  height: 274px;
  position: absolute;
  top: 0;
  left: 0;
}

.captives .group-5 {
  width: 508px;
  height: 274px;
  position: relative;
}

.captives .overlap-3 {
  background-color: #00dfc2;
  border-radius: 4px;
  width: 220px;
  height: 49px;
  position: absolute;
  top: 0;
  left: 136px;
}

.captives .text-wrapper-26 {
  color: #212f3c;
  letter-spacing: -.29px;
  white-space: nowrap;
  width: 162px;
  height: 18px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 29px;
  font-weight: 600;
  line-height: 10px;
  position: absolute;
  top: 15px;
  left: 31px;
}

.captives .overlap-4 {
  width: 344px;
  height: 186px;
  position: absolute;
  top: 55px;
  left: 0;
}

.captives .overlap-5 {
  width: 344px;
  height: 141px;
  position: absolute;
  top: 0;
  left: 0;
}

.captives .rectangle-9 {
  background-color: #f6b3b8;
  border-radius: 4px;
  width: 165px;
  height: 32px;
  position: absolute;
  top: 103px;
  left: 0;
}

.captives .line {
  width: 192px;
  height: 3px;
  position: absolute;
  top: 118px;
  left: 152px;
}

.captives .ellipse-4 {
  object-fit: cover;
  width: 42px;
  height: 42px;
  position: absolute;
  top: 97px;
  left: 173px;
}

.captives .text-wrapper-27 {
  color: #212f3c;
  font-family: var(--subtitle-BLD-font-family);
  font-size: var(--subtitle-BLD-font-size);
  font-style: var(--subtitle-BLD-font-style);
  font-weight: var(--subtitle-BLD-font-weight);
  letter-spacing: var(--subtitle-BLD-letter-spacing);
  line-height: var(--subtitle-BLD-line-height);
  white-space: nowrap;
  width: 146px;
  height: 19px;
  position: absolute;
  top: 109px;
  left: 4px;
}

.captives .ellipse-5 {
  object-fit: cover;
  width: 42px;
  height: 42px;
  position: absolute;
  top: 99px;
  left: 277px;
}

.captives .group-6 {
  width: 22px;
  height: 119px;
  position: absolute;
  top: 0;
  left: 235px;
}

.captives .group-7 {
  width: 22px;
  height: 52px;
  position: absolute;
  top: 135px;
  left: 66px;
}

.captives .overlap-6 {
  background-color: var(--neutral-0);
  border-radius: 4px;
  width: 165px;
  height: 32px;
  position: absolute;
  top: 242px;
  left: 4px;
}

.captives .text-wrapper-28 {
  color: #212f3c;
  font-family: var(--subtitle-BLD-font-family);
  font-size: var(--subtitle-BLD-font-size);
  font-style: var(--subtitle-BLD-font-style);
  font-weight: var(--subtitle-BLD-font-weight);
  letter-spacing: var(--subtitle-BLD-letter-spacing);
  line-height: var(--subtitle-BLD-line-height);
  white-space: nowrap;
  width: 119px;
  height: 19px;
  position: absolute;
  top: 6px;
  left: 16px;
}

.captives .overlap-group-3 {
  background-color: var(--neutral-0);
  border-radius: 4px;
  width: 160px;
  height: 32px;
  position: absolute;
  top: 242px;
  left: 344px;
}

.captives .text-wrapper-29 {
  color: #212f3c;
  font-family: var(--subtitle-BLD-font-family);
  font-size: var(--subtitle-BLD-font-size);
  font-style: var(--subtitle-BLD-font-style);
  font-weight: var(--subtitle-BLD-font-weight);
  letter-spacing: var(--subtitle-BLD-letter-spacing);
  line-height: var(--subtitle-BLD-line-height);
  white-space: nowrap;
  width: 46px;
  height: 19px;
  position: absolute;
  top: 7px;
  left: 60px;
}

.captives .overlap-7 {
  width: 154px;
  height: 32px;
  position: absolute;
  top: 158px;
  left: 344px;
}

.captives .rectangle-10 {
  background-color: #f6b3b8;
  border-radius: 4px;
  width: 160px;
  height: 32px;
  position: absolute;
  top: 0;
  left: 0;
}

.captives .text-wrapper-30 {
  color: #212f3c;
  font-family: var(--subtitle-BLD-font-family);
  font-size: var(--subtitle-BLD-font-size);
  font-style: var(--subtitle-BLD-font-style);
  font-weight: var(--subtitle-BLD-font-weight);
  letter-spacing: var(--subtitle-BLD-letter-spacing);
  line-height: var(--subtitle-BLD-line-height);
  white-space: nowrap;
  width: 144px;
  height: 19px;
  position: absolute;
  top: 4px;
  left: 10px;
}

.captives .group-8 {
  width: 22px;
  height: 52px;
  position: absolute;
  top: 190px;
  left: 406px;
}

.captives .frame-24 {
  background: linear-gradient(#1e454d 0%, #22647299 100%);
  border-radius: 16px;
  width: 347px;
  height: 334px;
  position: absolute;
  top: 984px;
  left: 650px;
}

.captives .text-wrapper-31 {
  color: var(--neutral-40);
  letter-spacing: -.29px;
  width: 360px;
  height: 41px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 29px;
  font-weight: 600;
  line-height: 40.6px;
  position: absolute;
  top: 42px;
  left: 38px;
}

.captives .frame-25 {
  align-items: center;
  gap: 17px;
  display: inline-flex;
  position: absolute;
  top: 104px;
  left: 38px;
}

.captives .frame-26 {
  align-items: center;
  gap: 17px;
  display: inline-flex;
  position: absolute;
  top: 136px;
  left: 38px;
}

.captives .frame-27 {
  align-items: center;
  gap: 17px;
  display: inline-flex;
  position: absolute;
  top: 168px;
  left: 38px;
}

.captives .frame-28 {
  align-items: center;
  gap: 17px;
  display: inline-flex;
  position: absolute;
  top: 200px;
  left: 38px;
}

.captives .frame-29 {
  align-items: center;
  gap: 17px;
  display: inline-flex;
  position: absolute;
  top: 232px;
  left: 38px;
}

.captives .frame-30 {
  align-items: center;
  gap: 17px;
  display: inline-flex;
  position: absolute;
  top: 264px;
  left: 38px;
}

.captives .header-2 {
  background-color: #e1e4e9;
  justify-content: space-between;
  align-items: center;
  width: 1440px;
  padding: 16px 80px;
  display: flex;
  position: absolute;
  top: 0;
  left: 1px;
}

.captives .logo-2 {
  object-fit: cover;
  width: 145px;
  height: 67.67px;
  position: relative;
}

.captives .frame-31 {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 90.33px;
  padding: 10px 8px;
  display: flex;
  position: relative;
}

.captives .text-wrapper-32 {
  color: #000;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: relative;
}

.captives .text-wrapper-33 {
  color: #000;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-left: -2.83px;
  margin-right: -2.83px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: relative;
}

.captives .frame-32 {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 80px;
  padding: 10px 8px;
  display: flex;
  position: relative;
}

.captives .CTA-3 {
  border-color: var(--primary-02) !important;
  width: 179px !important;
  height: 69px !important;
  display: flex !important;
}

.captives .CTA-4 {
  color: #000 !important;
  margin-top: unset !important;
}

.captives .frame-33 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  width: 358px;
  display: flex;
  position: absolute;
  top: 481px;
  left: 16px;
}

.captives .frame-34 {
  flex: none;
  width: 358px;
  position: relative;
}

.captives .frame-35 {
  background-color: #e1e4e9;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: 390px;
  height: 535px;
  padding: 60px 16px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.captives .rely-on-2 {
  color: #0000;
  letter-spacing: -.54px;
  align-self: stretch;
  font-family: Source Sans Pro, Helvetica;
  font-size: 36px;
  font-weight: 400;
  line-height: 43.2px;
  position: relative;
}

.captives .frame-36 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 24px;
  width: 358px;
  display: flex;
  position: relative;
}

.captives .text-wrapper-34 {
  color: #000;
  letter-spacing: -.2px;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.captives .frame-37 {
  height: 63px;
  position: relative;
}

.captives .element-2 {
  object-fit: cover;
  width: 192px;
  height: 236px;
  position: absolute;
  top: 299px;
  left: 198px;
}

.captives .overlap-wrapper {
  width: 390px;
  height: 1642px;
  position: absolute;
  top: 535px;
  left: 0;
  overflow: hidden;
}

.captives .overlap-8 {
  background-color: #172c36;
  height: 833px;
  position: relative;
}

.captives .overlap-9 {
  width: 374px;
  height: 381px;
  position: absolute;
  top: 0;
  left: 16px;
}

.captives .rectangle-11 {
  width: 262px;
  height: 367px;
  position: absolute;
  top: 0;
  left: 112px;
}

.captives .frame-38 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  width: 358px;
  display: flex;
  position: absolute;
  top: 76px;
  left: 0;
}

.captives .frame-39 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 6px;
  width: 100%;
  display: flex;
  position: relative;
}

.captives .text-wrapper-35 {
  color: #fff;
  letter-spacing: -.48px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 22px;
  font-weight: 400;
  line-height: 44.8px;
  position: relative;
}

.captives .managing-a-company-s-2 {
  color: #d9d9d9;
  letter-spacing: -.18px;
  align-self: stretch;
  font-family: Source Sans Pro, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
  position: relative;
}

.captives .frame-40 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 8px;
  display: flex;
  position: relative;
}

.captives .frame-41 {
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  width: 150px;
  display: flex;
  position: relative;
}

.captives .frame-42 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 17px;
  width: 100%;
  margin-top: 10px;
  display: flex;
  position: relative;
}

.captives .frame-43 {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  display: flex;
  position: relative;
}

.captives .text-wrapper-36 {
  color: var(--neutral-0);
  letter-spacing: -.14px;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  position: relative;
}

.captives .text-wrapper-37 {
  color: var(--neutral-0);
  letter-spacing: -.14px;
  flex: 1;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  position: relative;
}

.captives .frame-44 {
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  width: 170px;
  display: flex;
  position: relative;
}

.captives .frame-45 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.captives .overlap-10 {
  width: 374px;
  height: 395px;
  position: absolute;
  top: 1247px;
  left: 16px;
}

.captives .rectangle-12 {
  width: 337px;
  height: 222px;
  position: absolute;
  top: 173px;
  left: 37px;
}

.captives .group-9 {
  background: linear-gradient(#1e454d 0%, #2967749e 100%);
  border-radius: 16px;
  width: 358px;
  height: 334px;
  position: absolute;
  top: 0;
  left: 0;
}

.captives .frame-46 {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 267px;
  display: flex;
  position: absolute;
  top: 43px;
  left: 40px;
}

.captives .frame-47 {
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 267px;
  display: flex;
  position: absolute;
  top: 104px;
  left: 40px;
}

.captives .frame-48 {
  flex: none;
  align-items: center;
  gap: 17px;
  margin-right: -35.47px;
  display: inline-flex;
  position: relative;
}

.captives .frame-49 {
  flex: none;
  align-items: center;
  gap: 17px;
  margin-right: -70.47px;
  display: inline-flex;
  position: relative;
}

.captives .text-wrapper-38 {
  color: #d9d9d9;
  letter-spacing: -.82px;
  width: 196px;
  height: 40px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 33px;
  font-weight: 400;
  line-height: 39.6px;
  position: absolute;
  top: 456px;
  left: 97px;
}

.captives .group-10 {
  width: 364px;
  height: 318px;
  position: absolute;
  top: 531px;
  left: 16px;
}

.captives .overlap-11 {
  width: 105px;
  height: 121px;
  position: absolute;
  top: 197px;
  left: 247px;
}

.captives .group-11 {
  width: 16px;
  height: 97px;
  position: absolute;
  top: 0;
  left: 46px;
}

.captives .rectangle-13 {
  background-color: #f6b3b8;
  border-radius: 2.88px;
  width: 105px;
  height: 27px;
  position: absolute;
  top: 28px;
  left: 0;
}

.captives .rectangle-14 {
  background-color: #006682;
  border-radius: 2.88px;
  width: 105px;
  height: 27px;
  position: absolute;
  top: 61px;
  left: 0;
}

.captives .rectangle-15 {
  background-color: #4d9f98;
  border-radius: 2.88px;
  width: 105px;
  height: 26px;
  position: absolute;
  top: 95px;
  left: 0;
}

.captives .text-wrapper-39 {
  color: #212f3c;
  letter-spacing: -.1px;
  white-space: nowrap;
  width: 71px;
  height: 15px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10.1px;
  font-weight: 600;
  line-height: 14.1px;
  position: absolute;
  top: 34px;
  left: 20px;
}

.captives .text-wrapper-40 {
  color: #fff;
  letter-spacing: -.1px;
  white-space: nowrap;
  width: 65px;
  height: 15px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10.1px;
  font-weight: 600;
  line-height: 14.1px;
  position: absolute;
  top: 67px;
  left: 23px;
}

.captives .text-wrapper-41 {
  color: #fff;
  letter-spacing: -.1px;
  white-space: nowrap;
  width: 88px;
  height: 15px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10.1px;
  font-weight: 600;
  line-height: 14.1px;
  position: absolute;
  top: 100px;
  left: 9px;
}

.captives .group-12 {
  width: 358px;
  height: 197px;
  position: absolute;
  top: 0;
  left: 0;
}

.captives .group-13 {
  width: 368px;
  height: 197px;
  position: relative;
}

.captives .overlap-12 {
  background-color: #00dfc2;
  border-radius: 2.88px;
  width: 158px;
  height: 35px;
  position: absolute;
  top: 0;
  left: 98px;
}

.captives .text-wrapper-42 {
  color: #212f3c;
  letter-spacing: -.21px;
  white-space: nowrap;
  width: 116px;
  height: 13px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20.8px;
  font-weight: 600;
  line-height: 29.2px;
  position: absolute;
  top: 11px;
  left: 22px;
}

.captives .overlap-13 {
  width: 247px;
  height: 134px;
  position: absolute;
  top: 40px;
  left: 0;
}

.captives .overlap-group-4 {
  width: 247px;
  height: 102px;
  position: absolute;
  top: 0;
  left: 0;
}

.captives .rectangle-16 {
  background-color: #f6b3b8;
  border-radius: 2.88px;
  width: 109px;
  height: 23px;
  position: absolute;
  top: 74px;
  left: 0;
}

.captives .line-2 {
  width: 138px;
  height: 2px;
  position: absolute;
  top: 85px;
  left: 109px;
}

.captives .ellipse-6 {
  object-fit: cover;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 70px;
  left: 124px;
}

.captives .text-wrapper-43 {
  color: #212f3c;
  letter-spacing: -.12px;
  white-space: nowrap;
  width: 105px;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 12.2px;
  font-weight: 700;
  line-height: 17.1px;
  position: absolute;
  top: 78px;
  left: 3px;
}

.captives .ellipse-7 {
  object-fit: cover;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 71px;
  left: 199px;
}

.captives .group-14 {
  width: 16px;
  height: 86px;
  position: absolute;
  top: 0;
  left: 169px;
}

.captives .group-15 {
  width: 16px;
  height: 37px;
  position: absolute;
  top: 97px;
  left: 47px;
}

.captives .overlap-14 {
  background-color: var(--neutral-0);
  border-radius: 2.88px;
  width: 105px;
  height: 23px;
  position: absolute;
  top: 174px;
  left: 3px;
}

.captives .text-wrapper-44 {
  color: #212f3c;
  letter-spacing: -.12px;
  white-space: nowrap;
  width: 86px;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 12.2px;
  font-weight: 700;
  line-height: 17.1px;
  position: absolute;
  top: 5px;
  left: 12px;
}

.captives .overlap-15 {
  background-color: var(--neutral-0);
  border-radius: 2.88px;
  width: 105px;
  height: 23px;
  position: absolute;
  top: 174px;
  left: 247px;
}

.captives .text-wrapper-45 {
  color: #212f3c;
  letter-spacing: -.12px;
  white-space: nowrap;
  width: 33px;
  height: 13px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 12.2px;
  font-weight: 700;
  line-height: 17.1px;
  position: absolute;
  top: 5px;
  left: 43px;
}

.captives .overlap-16 {
  width: 111px;
  height: 23px;
  position: absolute;
  top: 113px;
  left: 247px;
}

.captives .rectangle-17 {
  background-color: #f6b3b8;
  border-radius: 2.88px;
  width: 105px;
  height: 23px;
  position: absolute;
  top: 0;
  left: 0;
}

.captives .text-wrapper-46 {
  color: #212f3c;
  letter-spacing: -.12px;
  white-space: nowrap;
  width: 104px;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 12.2px;
  font-weight: 700;
  line-height: 17.1px;
  position: absolute;
  top: 3px;
  left: 7px;
}

.captives .group-16 {
  width: 16px;
  height: 37px;
  position: absolute;
  top: 137px;
  left: 292px;
}

.captives .overlap-group-wrapper {
  width: 347px;
  height: 334px;
  position: absolute;
  top: 881px;
  left: 18px;
}

.captives .overlap-17 {
  width: 398px;
  height: 334px;
  position: relative;
}

.captives .rectangle-18 {
  background: linear-gradient(#1e454d 0%, #22647299 100%);
  border-radius: 16px;
  width: 356px;
  height: 334px;
  position: absolute;
  top: 0;
  left: 0;
}

.captives .carousel-2 {
  width: 390px;
  height: 796px;
  position: absolute;
  top: 2149px;
  left: 0;
  overflow: hidden;
}

.captives .frame-50 {
  width: 667px;
  height: 1425px;
  position: absolute;
  top: 0;
  left: 0;
}

.captives .overlap-group-5 {
  width: 687px;
  height: 923px;
  position: relative;
  top: -24px;
  left: -134px;
}

.captives .rectangle-19 {
  width: 390px;
  height: 485px;
  position: absolute;
  top: 24px;
  left: 134px;
}

.captives .frame-51 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  height: 261px;
  display: inline-flex;
  position: absolute;
  top: 166px;
  left: 214px;
}

.captives .shutterstock {
  object-fit: cover;
  width: 390px;
  height: 441px;
  position: absolute;
  top: 379px;
  left: 134px;
}

.captives .rectangle-20 {
  filter: blur(80px);
  background: linear-gradient(to right, #589994 50%, #fcfcfc00);
  width: 923px;
  height: 687px;
  position: absolute;
  top: 118px;
  left: -118px;
  transform: rotate(90deg);
}

.captives .frame-52 {
  flex-direction: column;
  align-items: flex-start;
  gap: 33px;
  display: inline-flex;
  position: absolute;
  top: 106px;
  left: 150px;
}

.captives .we-are-your-growth {
  color: #fff;
  letter-spacing: -.22px;
  width: 358px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 22px;
  font-weight: 600;
  line-height: 30.8px;
  position: relative;
}

.captives .CTA-5 {
  border: unset !important;
  border-color: unset !important;
  background-color: #2e5053 !important;
  flex: none !important;
  justify-content: center !important;
  align-items: flex-end !important;
  width: 127px !important;
  display: flex !important;
}

.captives .CTA-6 {
  margin-left: -2px !important;
  font-weight: 700 !important;
}

.captives .CTA-7 {
  margin-right: -2px !important;
}

.captives .frame-53 {
  width: 1440px;
  height: 580px;
  position: absolute;
  top: 0;
  left: 1440px;
  overflow: hidden;
}

.captives .rectangle-21 {
  width: 1383px;
  height: 634px;
  position: absolute;
  top: -3795px;
  left: 5946px;
}

.captives .overlap-18 {
  filter: blur(80px);
  background: linear-gradient(#000 0%, #071103 25%, #202020 44.79%, #2f2f2f 61.46%, #0000 100%);
  width: 1684px;
  height: 714px;
  position: absolute;
  top: -60px;
  left: -116px;
}

.captives .frame-54 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: absolute;
  top: 242px;
  left: 196px;
}

.captives .text-wrapper-47 {
  color: var(--neutral-0);
  letter-spacing: -.53px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 600;
  line-height: 49px;
  position: relative;
}

.captives .frame-55 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.captives .our-client-and {
  color: #f6f7f8;
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  width: 436px;
  margin-top: -1px;
  position: relative;
}

.captives .CTA-8 {
  all: unset;
  box-sizing: border-box;
  flex: none;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.captives .text-wrapper-48 {
  color: var(--neutral-0);
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 23.8px;
  position: relative;
}

.captives .arrow-forward-2 {
  width: 24px;
  height: 24px;
  margin-top: -4167px;
  margin-right: -5519px;
  position: relative;
}

.captives .text-wrapper-49 {
  color: var(--neutral-0);
  font-family: var(--h4-REG-font-family);
  font-size: var(--h4-REG-font-size);
  font-style: var(--h4-REG-font-style);
  font-weight: var(--h4-REG-font-weight);
  letter-spacing: var(--h4-REG-letter-spacing);
  line-height: var(--h4-REG-line-height);
  white-space: nowrap;
  height: 41px;
  position: absolute;
  top: 120px;
  left: 196px;
}

.captives .frame-56 {
  align-items: flex-start;
  gap: 5px;
  display: inline-flex;
  position: absolute;
  top: 560px;
  left: 196px;
}

.captives .ellipse-8 {
  background-color: #d9d9d952;
  border-radius: 5.5px;
  width: 11px;
  height: 11px;
  position: relative;
}

.captives .ellipse-9 {
  background-color: var(--neutral-30);
  border-radius: 5.5px;
  width: 11px;
  height: 11px;
  position: relative;
}

.captives .carousel-03 {
  position: absolute !important;
  top: 0 !important;
  left: 2880px !important;
}

.captives .carousel-instance {
  margin-top: -4190px !important;
  margin-right: -4079px !important;
}

.captives .overlap-19 {
  position: absolute;
}

.captives .frame-57 {
  position: absolute;
  top: 0;
}

.captives .overlap-group-6 {
  position: relative;
}

.captives .frame-58 {
  width: 1449px;
  height: 380px;
  position: absolute;
  top: 0;
  left: 0;
}

.captives .overlap-group-7 {
  background-color: #4d4d4d;
  width: 1456px;
  height: 380px;
}

.captives .frame-59 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 32px;
  display: inline-flex;
  position: relative;
  top: 69px;
  left: 152px;
}

.captives .frame-60 {
  flex-direction: column;
  flex: none;
  align-items: flex-end;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.captives .text-wrapper-50 {
  color: #fff;
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.captives .are-we-a-good-fit {
  color: var(--neutral-10);
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  text-align: right;
  width: 429px;
  position: relative;
}

.captives .CTA-9 {
  all: unset;
  background-color: var(--secondary-04);
  box-sizing: border-box;
  flex: none;
  align-items: center;
  gap: 10px;
  padding: 12px 16px;
  display: inline-flex;
  position: relative;
}

.captives .text-wrapper-51 {
  color: #fff;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  position: relative;
}

.captives .arrow-forward-3 {
  width: 24px;
  height: 24px;
  position: relative;
}

.captives .frame-61 {
  position: absolute;
  top: 0;
}

.captives .overlap-20 {
  background-color: var(--neutral-10);
  width: 720px;
  height: 380px;
  position: absolute;
  top: 0;
  left: 0;
}

.captives .frame-62 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 32px;
  display: inline-flex;
  position: relative;
  top: 49px;
  left: 128px;
}

.captives .text-wrapper-52 {
  color: var(--secondary-04);
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.captives .text-wrapper-53 {
  color: var(--neutral-80);
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  text-align: right;
  width: 500px;
  position: relative;
}

.captives .frame-63 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 52px;
  left: 817px;
}

.captives .text-wrapper-54 {
  color: #fff;
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.captives .text-wrapper-55 {
  color: var(--neutral-10);
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  width: 429px;
  position: relative;
}

.captives .CTA-10 {
  cursor: pointer;
  background-color: var(--secondary-02);
  box-sizing: border-box;
  border: none;
  outline: none;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  display: inline-flex;
  position: absolute;
  top: 259px;
  left: 817px;
}

.captives .text-wrapper-56 {
  color: var(--neutral-0);
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.captives .frame-64 {
  flex-direction: column;
  align-items: center;
  gap: 25px;
  display: inline-flex;
  position: absolute;
  top: 76px;
  left: 0;
}

.captives .frame-65 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 51px;
  margin-left: 50px;
  display: inline-flex;
  position: relative;
}

.captives .frame-66 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 26px;
  display: inline-flex;
  position: relative;
}

.captives .frame-67 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.captives .frame-68 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.captives .text-wrapper-57 {
  color: var(--neutral-60);
  letter-spacing: -.2px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  position: relative;
}

.captives .frame-69 {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 29px;
  display: inline-flex;
  position: relative;
}

.captives .mercedes-benz-logo {
  object-fit: cover;
  width: 148px;
  height: 37px;
  position: relative;
}

.captives .GE-healthcare-logo {
  object-fit: cover;
  width: 98px;
  height: 55px;
  position: relative;
}

.captives .sony-logo {
  object-fit: cover;
  width: 74px;
  height: 13px;
  position: relative;
}

.captives .shell-logo {
  object-fit: cover;
  width: 69px;
  height: 39px;
  position: relative;
}

.captives .amazon {
  object-fit: cover;
  width: 70px;
  height: 33px;
  position: relative;
}

.captives .wipro-logo-new-og {
  object-fit: cover;
  width: 102px;
  height: 53px;
  position: relative;
}

.captives .footer-2 {
  background-color: var(--white);
  width: 1441px;
  height: 292px;
  position: absolute;
  top: 377px;
  left: 0;
}

.captives .overlap-21 {
  width: 1441px;
  height: 292px;
  position: relative;
}

.captives .frame-70 {
  background-color: var(--neutral-90);
  width: 1441px;
  height: 292px;
  position: absolute;
  top: 0;
  left: 0;
}

.captives .DIV-3 {
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 53px;
  left: 738px;
}

.captives .text-wrapper-58 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1233px;
}

.captives .text-wrapper-59 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1282px;
}

.captives .text-wrapper-60 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1331px;
}

.captives .text-wrapper-61 {
  color: var(--white);
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 14px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  top: 190px;
  left: 1187px;
}

.captives .text-wrapper-62 {
  color: var(--white);
  letter-spacing: -.2px;
  white-space: nowrap;
  height: 28px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: absolute;
  top: 100px;
  left: 738px;
}

.captives .text-wrapper-63 {
  color: var(--white);
  letter-spacing: -.2px;
  white-space: nowrap;
  height: 28px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: absolute;
  top: 100px;
  left: 869px;
}

.captives .frame-71 {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: absolute;
  top: 115px;
  left: 81px;
}

.captives .group-17 {
  width: 37px;
  height: 52px;
  position: absolute;
  top: 152px;
  left: 1140px;
}

.captives .linkedin {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1187px;
}

.captives .facebook-2 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1234px;
}

.captives .facebook-3 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1281px;
}

.captives .facebook-4 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 152px;
  left: 1328px;
}

.captives .group-18 {
  width: 400px;
  height: 17px;
  position: absolute;
  top: 242px;
  left: 501px;
}

.captives .frame-72 {
  justify-content: center;
  align-items: center;
  gap: 5px;
  display: inline-flex;
  position: relative;
}

.captives .text-wrapper-64 {
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.captives .ellipse-10 {
  background-color: var(--grey-01);
  border-radius: 1.5px;
  width: 3px;
  height: 3px;
  position: relative;
}

.captives .logo-3 {
  width: 116px;
  height: 56px;
  position: absolute;
  top: 39px;
  left: 76px;
}

.captives .frame-73 {
  flex-direction: column;
  align-items: center;
  gap: 92px;
  display: inline-flex;
  position: absolute;
  top: 366px;
  left: 185px;
}

.captives .frame-74 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-end;
  gap: 32px;
  width: 328px;
  display: flex;
  position: relative;
}

.captives .frame-75 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 24px;
  width: 328px;
  display: flex;
  position: relative;
}

.captives .text-wrapper-65 {
  color: var(--secondary-04);
  letter-spacing: -.8px;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.4px;
  position: relative;
}

.captives .text-wrapper-66 {
  color: var(--neutral-80);
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  text-align: center;
  width: 297px;
  position: relative;
}

.captives .group-19 {
  width: 390px;
  height: 360px;
  position: relative;
}

.captives .frame-76 {
  height: 360px;
}

.captives .overlap-group-8 {
  background-color: var(--neutral-70);
  width: 1440px;
  height: 360px;
}

.captives .frame-77 {
  flex-direction: column;
  align-items: center;
  gap: 24px;
  display: inline-flex;
  position: relative;
  top: 85px;
  left: 41px;
}

.captives .text-wrapper-67 {
  color: var(--neutral-0);
  letter-spacing: -.8px;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.4px;
  position: relative;
}

.captives .are-we-a-good-fit-2 {
  color: #f6f7f8;
  letter-spacing: -.2px;
  text-align: center;
  width: 309px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
}

.captives .CTA-11 {
  cursor: pointer;
  background-color: var(--secondary-02);
  box-sizing: border-box;
  border: none;
  outline: none;
  flex: none;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  display: inline-flex;
  position: relative;
}

.captives .frame-78 {
  align-items: flex-start;
  gap: 40px;
  width: 100%;
  position: absolute;
  top: 1561px;
  left: 46px;
  display: inline-flex !important;
}

.captives .frame-79 {
  background-color: var(--neutral-0);
  border: 1px solid;
  border-color: var(--neutral-50);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  width: 298px;
  margin-top: -1px;
  margin-bottom: -1px;
  margin-left: -1px;
  display: flex;
  position: relative;
}

.captives .deepmind {
  object-fit: cover;
  width: 296px;
  height: 163px;
  position: relative;
}

.captives .frame-80 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 14px;
  width: 100%;
  padding: 0 32px;
  display: flex;
  position: relative;
}

.captives .frame-81 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.captives .text-wrapper-68 {
  color: #1f1f1f;
  white-space: nowrap;
  letter-spacing: -.36px;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 28.8px;
  position: relative;
}

.captives .automate-the-entire {
  color: var(--neutral-60);
  letter-spacing: -.14px;
  align-self: stretch;
  font-family: Source Sans Pro, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  position: relative;
}

.captives .frame-82 {
  flex: none;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.captives .text-wrapper-69 {
  color: #4d9f98;
  letter-spacing: -.15px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 15px;
  font-weight: 700;
  line-height: 21px;
  position: relative;
}

.captives .frame-83 {
  flex: none;
  align-items: flex-start;
  gap: 10px;
  margin-right: -2px;
  display: inline-flex;
  position: relative;
}

.captives .frame-84 {
  background-color: var(--neutral-0);
  border: 1px solid;
  border-color: var(--neutral-50);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  width: 298px;
  margin-top: -1px;
  margin-bottom: -1px;
  display: flex;
  position: relative;
}

.captives .frame-85 {
  background-color: var(--neutral-0);
  border: 1px solid;
  border-color: var(--neutral-50);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  width: 298px;
  margin-top: -1px;
  margin-bottom: -1px;
  margin-right: -1px;
  display: flex;
  position: relative;
}

.captives .overlap-22 {
  width: 1483px;
  height: 1070px;
  position: absolute;
  top: 1456px;
  left: -11px;
}

.captives .overlap-23 {
  width: 1483px;
  height: 1068px;
  position: absolute;
  top: 2px;
  left: 0;
}

.captives .group-20 {
  width: 1483px;
  height: 416px;
  position: absolute;
  top: -100px;
  left: 0;
}

.captives .overlap-24 {
  width: 1481px;
  height: 416px;
  position: relative;
}

.captives .carousel-3 {
  width: 1481px;
  height: 416px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.captives .frame-86 {
  width: 1440px;
  height: 683px;
  position: absolute;
  top: 0;
  left: 0;
}

.captives .overlap-group-9 {
  width: 1722px;
  height: 790px;
  position: relative;
  top: -81px;
  left: -186px;
}

.captives .rectangle-22 {
  width: 1429px;
  height: 416px;
  position: absolute;
  top: 81px;
  left: 197px;
}

.captives .shutterstock-2 {
  object-fit: cover;
  width: 860px;
  height: 709px;
  position: absolute;
  top: 81px;
  left: 766px;
}

.captives .rectangle-23 {
  background: linear-gradient(to right, #589994 50%, #fcfcfc00);
  width: 1722px;
  height: 790px;
  position: absolute;
  top: 0;
  left: 0;
}

.captives .CTA-12 {
  border: unset !important;
  border-color: unset !important;
  background-color: #2e5053 !important;
  justify-content: center !important;
  align-items: flex-end !important;
  width: 182px !important;
  display: flex !important;
  position: absolute !important;
  top: 367px !important;
  left: 234px !important;
}

.captives .CTA-13 {
  font-weight: 700 !important;
}

.captives .rectangle-24 {
  width: 1383px;
  height: 634px;
  position: absolute;
  top: 668px;
  left: 5957px;
}

.captives .arrow-forward-4 {
  width: 24px;
  height: 24px;
  margin-bottom: -296px;
  margin-right: -5530px;
  position: relative;
}

.captives .carousel-03-instance {
  margin-bottom: -273px !important;
  margin-right: -4090px !important;
}

.captives .we-are-your-growth-2 {
  color: #fff;
  letter-spacing: -.29px;
  width: 604px;
  height: 118px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 29px;
  font-weight: 600;
  line-height: 40.6px;
  position: absolute;
  top: 102px;
  left: 47px;
}

.captives .frame-87 {
  background-color: var(--neutral-0);
  border: 1px solid;
  border-color: var(--neutral-50);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  width: 434px;
  height: 664px;
  padding: 0 0 94px;
  display: flex;
  position: absolute;
  top: -175px;
  left: 512px;
}

.captives .deepmind-hereyczt {
  align-self: stretch;
  width: 100%;
  height: 304.5px;
  position: relative;
}

.captives .frame-88 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 14px;
  width: 100%;
  margin-bottom: -50.5px;
  padding: 0 48px;
  display: flex;
  position: relative;
}

.captives .text-wrapper-70 {
  color: #1f1f1f;
  letter-spacing: -.53px;
  align-self: stretch;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 30px;
  font-weight: 400;
  line-height: 42px;
  position: relative;
}

.captives .text-wrapper-71 {
  color: var(--neutral-60);
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  align-self: stretch;
  position: relative;
}

.captives .text-wrapper-72 {
  color: #4d9f98;
  font-family: var(--subtitle-BLD-font-family);
  font-size: var(--subtitle-BLD-font-size);
  font-style: var(--subtitle-BLD-font-style);
  font-weight: var(--subtitle-BLD-font-weight);
  letter-spacing: var(--subtitle-BLD-letter-spacing);
  line-height: var(--subtitle-BLD-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.captives .frame-89 {
  background-color: var(--neutral-0);
  border: 1px solid;
  border-color: var(--neutral-50);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  width: 434px;
  height: 650px;
  padding: 0 0 94px;
  display: flex;
  position: absolute;
  top: -160px;
  left: 40px;
}

.captives .frame-90 {
  background-color: var(--neutral-0);
  border: 1px solid;
  border-color: var(--neutral-50);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  width: 434px;
  height: 664px;
  padding: 0 0 94px;
  display: flex;
  position: absolute;
  top: -175px;
  left: 984px;
}

.captives .frame-91 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 14px;
  width: 100%;
  margin-bottom: -50.5px;
  padding: 0 24px;
  display: flex;
  position: relative;
}

.captives .frame-92 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.captives .text-wrapper-73 {
  color: #1f1f1f;
  letter-spacing: -.53px;
  width: 369px;
  margin-top: -1px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 35px;
  font-weight: 400;
  line-height: 42px;
  position: relative;
}

.captives .text-wrapper-74 {
  color: var(--neutral-60);
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  width: 383px;
  position: relative;
}

.captives .overlap-25 {
  background-color: #f6f7f8;
  width: 1447px;
  height: 288px;
  position: absolute;
  top: 1790px;
  left: 0;
}

.captives .frame-93 {
  flex-direction: column;
  align-items: center;
  gap: 25px;
  display: inline-flex;
  position: relative;
  top: 71px;
  left: 153px;
}

.captives .mercedes-benz-logo-2 {
  object-fit: cover;
  width: 248px;
  height: 63px;
  position: relative;
}

.captives .GE-healthcare-logo-2 {
  object-fit: cover;
  width: 164px;
  height: 92px;
  position: relative;
}

.captives .sony-logo-2 {
  object-fit: cover;
  width: 123px;
  height: 22px;
  position: relative;
}

.captives .shell-logo-2 {
  object-fit: cover;
  width: 115px;
  height: 65px;
  position: relative;
}

.captives .amazon-2 {
  object-fit: cover;
  width: 178px;
  height: 55px;
  position: relative;
}

.captives .wipro-logo-new-og-2 {
  object-fit: cover;
  width: 171px;
  height: 89px;
  position: relative;
}
/*# sourceMappingURL=index.bfe1763d.css.map */
