.component-about {
  height: 180px;
  position: relative;
  width: 484px;
}

.component-about .bring-the-best-out {
  color: transparent;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 50px;
  font-weight: 400;
  left: 0;
  letter-spacing: -0.75px;
  line-height: 60px;
  position: absolute;
  top: -1px;
  width: 484px;
}

.component-about .text-wrapper {
  color: #2c3e47;
  font-family: var(--h1-REG-font-family);
  font-size: var(--h1-REG-font-size);
  font-style: var(--h1-REG-font-style);
  font-weight: var(--h1-REG-font-weight);
  letter-spacing: var(--h1-REG-letter-spacing);
  line-height: var(--h1-REG-line-height);
}

.component-about .span {
  position: relative;
  width: 100px;
  color: #4d9f98;
  font-weight: 700;
  text-decoration: underline;
}
