.property-default-wrapper-home {
  background-color: #2e5053;
  /* height: 320px; */
  /* overflow: hidden; */
  /* width: 720px; */
}

.property-default-wrapper-home .overlap-2 {
  height: 392px;
  position: relative;
}

.property-default-wrapper-home .frame-10 {
  height: 320px;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(180deg);
  width: 720px;
}

.property-default-wrapper-home .overlap-group-2 {
  height: 320px;
  position: relative;
}

.property-default-wrapper-home .shutterstock-2 {
  height: 320px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 720px;
}

.property-default-wrapper-home .rectangle-6 {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgb(43.56, 43.56, 43.56) 100%);
  background-blend-mode: multiply;
  height: 320px;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(180deg);
  width: 720px;
}

.property-default-wrapper-home .frame-11 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 20px;
  height: 298px;
  left: 64px;
  position: absolute;
  top: 20px;
}

.property-default-wrapper-home .hire-train-deploy-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
  height: 208px;
  position: relative;
}

.property-default-wrapper-home .hire-train-deploy-3 {
  color: #ffffff;
  flex: 1;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 42px;
  font-weight: 600;
  letter-spacing: -1.05px;
  line-height: 50.4px;
  margin-top: -1px;
  position: relative;
  width: 477px;
}

.property-default-wrapper-home .hire-train-deploy-text {
  color: var(--neutral-0, #FFF);

  /* H6 / REG */
  font-family: Source Sans Pro;
  font-size: 20px;
  font-style: normal;
  width: 477px;
  font-weight: 400;
  line-height: 140%;
  /* 28px */
  letter-spacing: -0.2px;
}

.property-default-wrapper-home .CTA-3 {
  flex: 0 0 auto !important;
}