.property-default {
  background-color: #2e5053;
  height: 440px;
  width: 1280px;
}

.property-default .overlap-group-2 {
  height: 440px;
  position: relative;
}

.property-default .rectangle-wrapper {
  background-image: url(../../../../static/services/shutterstock-1485872414-1-6.webp);
  background-size: 100% 100%;
  height: 440px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1280px;
}

.property-default .rectangle-4 {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgb(4, 6, 8) 100%);
  background-blend-mode: multiply;
  height: 835px;
  left: 197px;
  position: relative;
  top: -198px;
  transform: rotate(90deg);
  width: 440px;
}

.property-default .frame-6 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 32px;
  left: 64px;
  position: absolute;
  top: 110px;
}

.property-default .div-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 35px;
  height: 188px !important;
  position: relative;
}

.property-default .text-wrapper-3 {
  color: #ffffff;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 42px;
  font-weight: 600;
  height: 52px;
  letter-spacing: -1.05px;
  line-height: 50.4px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: 477px;
}

.property-default .design-component-instance-node {
  flex: 0 0 auto !important;
}
