.property-default {
  background-image: url(../../../../static/homepage/property-1-default.webp);
  background-position: 50% 50%;
  background-size: cover;
  height: 320px;
  width: 720px;
}

.property-default .overlap {
  height: 320px;
  position: relative;
}

.property-default .overlap-group-wrapper {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 27.6%, rgba(0, 0, 0, 0.36) 60.42%, rgba(0, 0, 0, 0.56) 100%);
  background-image: url(../../../../static/homepage/frame-3792-1.webp);
  background-position: 50% 50%;
  background-size: cover;
  height: 320px;
  left: 0;
  position: absolute;
  top: 0;
  width: 720px;
}

.property-default .overlap-group {
  background-image: url(../../../../static/homepage/shutterstock-1485872414-1-8.webp);
  background-position: 50% 50%;
  background-size: cover;
  height: 320px;
  position: relative;
}

.property-default .rectangle-5 {
  background-blend-mode: multiply;
  height: 320px;
  left: 0;
  position: absolute;
  top: 0;
  width: 720px;
}

.property-default .frame-9 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 32px;
  height: 132px;
  left: 64px;
  position: absolute;
  top: 34px;
}

.property-default .div-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 35px;
  height: 52px;
  position: relative;
}

.property-default .text-wrapper-3 {
  color: #ffffff;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 42px;
  font-weight: 600;
  height: 52px;
  letter-spacing: -1.05px;
  line-height: 50.4px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: 477px;
}

.property-default .text-wrapper-3-p {
  color: var(--neutral-0, #FFF);
  width: 477px;
  /* Subtitle/REG */
  font-family: Source Sans Pro;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 23.8px */
  letter-spacing: -0.17px;
}

.property-default .design-component-instance-node {
  flex: 0 0 auto !important;
}