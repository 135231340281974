.property-default-wrapper {
  align-items: center;
  border-radius: 56px;
  display: inline-flex;
  padding: 12px 16px;
  position: relative;
}

.property-default-wrapper .div {
  color: var(--neutral-0);
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.property-default-wrapper .arrow-forward-2 {
  height: 24px;
  position: relative;
  width: 24px;
}

.property-default-wrapper.property-1-hover {
  background-color: #4d9f98;
  gap: 8px;
}

.property-default-wrapper.property-1-default {
  border: 1px solid;
  border-color: var(--neutral-0);
  gap: 10px;
  transition: all ease-in-out 0.3s;
}

.property-default-wrapper.property-1-default:hover {
  background-color: #4d9f98;
  gap: 8px;
}