.itcomponent {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 36px;
  overflow: hidden;
  padding: 80px 16px 96px 15px;
  position: relative;
  width: 456px;
}

.itcomponent .text-wrapper-4 {
  align-self: stretch;
  color: var(--neutral-90);
  font-family: "Source Sans Pro", Helvetica;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: -0.8px;
  line-height: 38.4px;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.itcomponent .frame-4 {
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.itcomponent .frame-5 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16px;
  width: 100%;
  position: relative;
}

.itcomponent .CTA-instance {
  align-items: flex-end !important;
  background-color: #4d9f98 !important;
  border: unset !important;
  border-color: unset !important;
  /* display: flex !important; */
  flex: 0 0 auto !important;
  justify-content: center !important;
  /* width: 127px !important; */
}

.itcomponent .instance-node {
  font-family: var(--subtitle-BLD-font-family) !important;
  font-size: var(--subtitle-BLD-font-size) !important;
  font-style: var(--subtitle-BLD-font-style) !important;
  font-weight: var(--subtitle-BLD-font-weight) !important;
  letter-spacing: var(--subtitle-BLD-letter-spacing) !important;
  line-height: var(--subtitle-BLD-line-height) !important;
}

.itcomponent .CTA-2 {
  align-items: flex-end !important;
  background-color: #e6e6e6 !important;
  border: unset !important;
  border-color: unset !important;
  flex: 0 0 auto !important;
  justify-content: center !important;
}

.itcomponent .CTA-3 {
  color: #6b6b6b !important;
  font-family: var(--subtitle-BLD-font-family) !important;
  font-size: var(--subtitle-BLD-font-size) !important;
  font-style: var(--subtitle-BLD-font-style) !important;
  font-weight: var(--subtitle-BLD-font-weight) !important;
  letter-spacing: var(--subtitle-BLD-letter-spacing) !important;
  line-height: var(--subtitle-BLD-line-height) !important;
}

.itcomponent .CTA-4 {
  align-items: flex-end !important;
  background-color: #e6e6e6 !important;
  border: unset !important;
  border-color: unset !important;
  display: flex !important;
  justify-content: center !important;
  width: 127px !important;
}

.itcomponent .class {
  align-items: flex-end !important;
  flex: 0 0 auto !important;
  justify-content: center !important;
}

.itcomponent .frame-wrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
}

.itcomponent .frame-6 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px 0px;
  position: relative;
  width: 406px;
}

.itcomponent .digital {
  color: #4d4d4d;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 35px;
  font-weight: 700;
  letter-spacing: -0.53px;
  line-height: 42px;
  margin-top: -1px;
  position: relative;
  width: 330px;
}

.itcomponent .our-digital-services {
  color: var(--neutral-60);
  font-family: "Source Sans Pro", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.2px;
  line-height: 28px;
  position: relative;
}

.itcomponent .frame-7 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.itcomponent .frame-8 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
}

.itcomponent .frame-9 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.itcomponent .vector {
  height: 16px;
  position: relative;
  width: 16px;
}

.itcomponent .time-and-material {
  color: var(--neutral-60);
  font-family: "Source Sans Pro", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.2px;
  line-height: 28px;
  margin-top: -1px;
  position: relative;
}

.itcomponent .lab-on-hire {
  color: var(--neutral-60);
  font-family: "Source Sans Pro", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.2px;
  line-height: 28px;
  margin-top: -1px;
  position: relative;
}

.itcomponent .digitalization {
  color: var(--neutral-60);
  font-family: "Source Sans Pro", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.2px;
  line-height: 28px;
  margin-top: -1px;
  position: relative;
}

.itcomponent .security-testing {
  color: var(--neutral-60);
  font-family: "Source Sans Pro", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.2px;
  line-height: 28px;
  margin-top: -1px;
  position: relative;
  width: 240px;
}

.itcomponent .functional-testing {
  color: var(--neutral-60);
  font-family: "Source Sans Pro", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.2px;
  line-height: 28px;
  margin-top: -1px;
  position: relative;
}

.itcomponent .UX-UI {
  color: var(--neutral-60);
  font-family: "Source Sans Pro", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.2px;
  line-height: 28px;
  margin-top: -1px;
  position: relative;
}

.itcomponent .ERP-testing {
  color: var(--neutral-60);
  font-family: "Source Sans Pro", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.2px;
  line-height: 28px;
  margin-top: -1px;
  position: relative;
}

.itcomponent .customer-experience {
  color: var(--neutral-60);
  font-family: "Source Sans Pro", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.2px;
  line-height: 28px;
  margin-top: -1px;
  position: relative;
}

.itcomponent .service-digital {
  align-items: center;
  flex-direction: column;
  gap: 56px;
  width: 390px;
  overflow: scroll;
}

.itcomponent .variant-4 {
  align-items: center;
  flex-direction: column;
  gap: 56px;
}

.itcomponent .variant-2 {
  align-items: flex-start;
  gap: 16px;
}

.itcomponent .variant-3 {
  align-items: flex-start;
  gap: 16px;
}

.itcomponent .variant-5 {
  align-items: center;
  flex-direction: column;
  gap: 56px;
}

.itcomponent .variant-6 {
  align-items: center;
  flex-direction: column;
  gap: 56px;
}

.itcomponent .variant-7 {
  align-items: center;
  flex-direction: column;
  gap: 56px;
}

.itcomponent .property-1 {
  width: 330px;
}

.itcomponent .property-1-service-digital {
  width: 330px;
}

.itcomponent .property-1-variant-4 {
  width: 330px;
}

.itcomponent .property-1-variant-2 {
  align-self: stretch;
}

.itcomponent .property-1-variant-3 {
  width: 330px;
}

.itcomponent .property-1-variant-5 {
  width: 330px;
}

.itcomponent .property-1-variant-6 {
  width: 330px;
}

.itcomponent .property-1-variant-7 {
  width: 330px;
}

.itcomponent .property-1-0-service-digital {
  flex-direction: column;
  width: 330px;
}

.itcomponent .property-1-0 {
  align-self: stretch;
  flex-direction: column;
  width: 100%;
}

.itcomponent .property-1-0-variant-2 {
  align-self: stretch;
  flex-direction: column;
  width: 100%;
}

.itcomponent .property-1-0-variant-3 {
  align-self: stretch;
  width: 100%;
}

.itcomponent .property-1-0-variant-5 {
  align-self: stretch;
  flex-direction: column;
  width: 100%;
}

.itcomponent .property-1-0-variant-6 {
  align-self: stretch;
  flex-direction: column;
  width: 100%;
}

.itcomponent .property-1-0-variant-7 {
  align-self: stretch;
  width: 100%;
}

.itcomponent .frame-7.property-1-0-service-digital .time-and-material {
  width: 220px;
}

.itcomponent .frame-7.property-1-0-variant-4 .time-and-material {
  width: 240px;
}

.itcomponent .frame-7.property-1-0-variant-2 .time-and-material {
  width: 240px;
}

.itcomponent .frame-7.property-1-0-variant-3 .time-and-material {
  white-space: nowrap;
  width: fit-content;
}

.itcomponent .frame-7.property-1-0-variant-5 .time-and-material {
  width: 240px;
}

.itcomponent .frame-7.property-1-0-variant-6 .time-and-material {
  white-space: nowrap;
  width: fit-content;
}

.itcomponent .frame-7.property-1-0-variant-7 .time-and-material {
  white-space: nowrap;
  width: fit-content;
}

.itcomponent .frame-7.property-1-0-service-digital .lab-on-hire {
  width: 220px;
}

.itcomponent .frame-7.property-1-0-variant-4 .lab-on-hire {
  width: 240px;
}

.itcomponent .frame-7.property-1-0-variant-2 .lab-on-hire {
  width: 240px;
}

.itcomponent .frame-7.property-1-0-variant-3 .lab-on-hire {
  white-space: nowrap;
  width: fit-content;
}

.itcomponent .frame-7.property-1-0-variant-5 .lab-on-hire {
  width: 240px;
}

.itcomponent .frame-7.property-1-0-variant-6 .lab-on-hire {
  white-space: nowrap;
  width: fit-content;
}

.itcomponent .frame-7.property-1-0-variant-7 .lab-on-hire {
  white-space: nowrap;
  width: fit-content;
}

.itcomponent .frame-7.property-1-0-service-digital .digitalization {
  width: 220px;
}

.itcomponent .frame-7.property-1-0-variant-4 .digitalization {
  width: 240px;
}

.itcomponent .frame-7.property-1-0-variant-2 .digitalization {
  width: 240px;
}

.itcomponent .frame-7.property-1-0-variant-3 .digitalization {
  white-space: nowrap;
  width: fit-content;
}

.itcomponent .frame-7.property-1-0-variant-5 .digitalization {
  width: 240px;
}

.itcomponent .frame-7.property-1-0-variant-6 .digitalization {
  white-space: nowrap;
  width: fit-content;
}

.itcomponent .frame-7.property-1-0-variant-7 .digitalization {
  width: 240px;
}

.itcomponent .frame-7.property-1-0-service-digital .functional-testing {
  width: 220px;
}

.itcomponent .frame-7.property-1-0-variant-4 .functional-testing {
  width: 220px;
}

.itcomponent .frame-7.property-1-0-variant-2 .functional-testing {
  width: 220px;
}

.itcomponent .frame-7.property-1-0-variant-3 .functional-testing {
  width: 220px;
}

.itcomponent .frame-7.property-1-0-variant-5 .functional-testing {
  width: 220px;
}

.itcomponent .frame-7.property-1-0-variant-6 .functional-testing {
  white-space: nowrap;
  width: fit-content;
}

.itcomponent .frame-7.property-1-0-variant-7 .functional-testing {
  width: 220px;
}

.itcomponent .frame-7.property-1-0-service-digital .UX-UI {
  width: 220px;
}

.itcomponent .frame-7.property-1-0-variant-4 .UX-UI {
  white-space: nowrap;
  width: fit-content;
}

.itcomponent .frame-7.property-1-0-variant-2 .UX-UI {
  width: 220px;
}

.itcomponent .frame-7.property-1-0-variant-3 .UX-UI {
  width: 220px;
}

.itcomponent .frame-7.property-1-0-variant-5 .UX-UI {
  width: 220px;
}

.itcomponent .frame-7.property-1-0-variant-6 .UX-UI {
  white-space: nowrap;
  width: fit-content;
}

.itcomponent .frame-7.property-1-0-variant-7 .UX-UI {
  width: 220px;
}

.itcomponent .frame-7.property-1-0-service-digital .ERP-testing {
  width: 220px;
}

.itcomponent .frame-7.property-1-0-variant-4 .ERP-testing {
  width: 220px;
}

.itcomponent .frame-7.property-1-0-variant-2 .ERP-testing {
  width: 220px;
}

.itcomponent .frame-7.property-1-0-variant-3 .ERP-testing {
  width: 220px;
}

.itcomponent .frame-7.property-1-0-variant-5 .ERP-testing {
  width: 220px;
}

.itcomponent .frame-7.property-1-0-variant-6 .ERP-testing {
  white-space: nowrap;
  width: fit-content;
}

.itcomponent .frame-7.property-1-0-variant-7 .ERP-testing {
  width: 220px;
}

.itcomponent .frame-7.property-1-0-service-digital .customer-experience {
  width: fit-content;
}

.itcomponent .frame-7.property-1-0-variant-4 .customer-experience {
  white-space: nowrap;
  width: fit-content;
}

.itcomponent .frame-7.property-1-0-variant-2 .customer-experience {
  width: fit-content;
}

.itcomponent .frame-7.property-1-0-variant-3 .customer-experience {
  width: fit-content;
}

.itcomponent .frame-7.property-1-0-variant-5 .customer-experience {
  width: 220px;
}

.itcomponent .frame-7.property-1-0-variant-6 .customer-experience {
  width: fit-content;
}

.itcomponent .frame-7.property-1-0-variant-7 .customer-experience {
  width: fit-content;
}
