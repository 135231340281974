.popup {
  position: fixed;
  width: 1440px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
}

.lead-form {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 1000px;
}

.lead-form .lead-div {
  background-color: var(--neutralwhite);
  height: 630px;
  position: relative;
  width: 1000px;
}

.lead-form .lead-frame {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 40px;
  left: 189px;
  position: absolute;
  top: 51px;
}

.lead-form .lead-everything-you-need {
  height: 100px;
  position: relative;
  width: 580.27px;
}

.lead-form .lead-frame-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 31px;
  position: relative;
}

.lead-form .lead-frame-3 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  position: relative;
}

.lead-form .lead-frame-4 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.lead-form .lead-frame-5 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24px;
  position: relative;
}

.lead-form .lead-component-form {
  align-items: center;
  border: 0.5px solid;
  border-color: var(--neutral-grey);
  border-radius: 4px;
  display: flex;
  gap: 362px;
  justify-content: center;
  overflow: hidden;
  padding: 14.5px 16px;
  position: relative;
  width: 316px;
}

.lead-form .lead-frame-6 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 362px;
  position: relative;
}

.lead-form input,
textarea {
  color: #6F6F6F;
  font-family: Source Sans Pro, Helvetica;
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  background: transparent;
}

.lead-form input::placeholder,
textarea::placeholder {
  color: #6F6F6F;
  font-family: Source Sans Pro, Helvetica;
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
}

.lead-form .lead-frame-wrapper {
  align-items: center;
  border: 0.5px solid;
  border-color: var(--neutral-grey);
  border-radius: 4px;
  display: flex;
  gap: 362px;
  justify-content: center;
  overflow: hidden;
  padding: 14.5px 16px;
  position: relative;
  width: 294px;
}

.lead-form .lead-div-wrapper-lead {
  align-items: center;
  border: 0.5px solid;
  border-color: var(--neutral-grey);
  border-radius: 4px;
  display: flex;
  flex: 0 0 auto;
  gap: 362px;
  justify-content: center;
  overflow: hidden;
  padding: 14.5px 16px;
  position: relative;
  width: 636px;
}

.lead-form .lead-component-2 {
  align-items: flex-start;
  border: 0.5px solid;
  border-color: var(--neutral-grey);
  border-radius: 4px;
  display: flex;
  gap: 362px;
  height: 112px;
  max-height: 150px;
  justify-content: center;
  overflow: hidden;
  padding: 14.5px 16px;
  position: relative;
  width: 636px;
  max-width: 636px;

}

.lead-form .lead-CTA {
  all: unset;
  box-sizing: border-box;
  height: 74px;
  position: relative;
  width: 636px;
}

.lead-form .lead-btn {
  all: unset;
  align-items: center;
  background-color: #4d9f98;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  height: 74px;
  justify-content: center;
  padding: 16px 126px;
  position: relative;
  width: 636px;
}

.lead-form .lead-text-wrapper {
  all: unset;
  box-sizing: border-box;
  font-family: var(--subtitle-BLD-font-family);
  color: var(--neutral-white, #FFF);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  position: relative;
  text-align: center;
  width: fit-content;
}

.lead-form .lead-frame-7 {
  align-items: center;
  background-color: var(--neutral-30);
  border-radius: 8px;
  display: flex;
  flex: 0 0 auto;
  gap: 16px;
  padding: 16px;
  position: relative;
  width: 636px;
}

.lead-form .lead-frame-8 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: space-between;
  position: relative;
}

.lead-form .lead-frame-9 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.lead-form .lead-missed-video-call {
  align-items: center;
  background-color: var(--secondary-02);
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 44px;
  justify-content: center;
  overflow: hidden;
  padding: 12px;
  position: relative;
  width: 44px;
}

.lead-form .lead-calendar-today {
  height: 24px;
  margin-bottom: -2px;
  margin-left: -2px;
  margin-right: -2px;
  margin-top: -2px;
  position: relative;
  width: 24px;
}

.lead-form .lead-group-2 {
  height: 40px;
  margin-right: -2px;
  position: relative;
  width: 296px;
}

.lead-form .lead-p {
  color: #6e6e6e;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 40px;
  left: 0;
  letter-spacing: -0.1px;
  line-height: 20px;
  position: absolute;
  top: 0;
  width: 294px;
}

.lead-form .lead-frame-10 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.lead-form .lead-btn-wrapper {
  all: unset;
  align-items: center;
  border: 1px solid;
  border-color: #000000;
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  padding: 0px 1px;
  position: relative;
}

.lead-form .lead-CTA-wrapper {
  all: unset;
  align-items: center;
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  overflow: hidden;
  padding: 16px 12px;
  position: relative;
}

.lead-form .lead-button {
  all: unset;
  box-sizing: border-box;
  color: #3d3d3d;
  font-family: "Neue Haas Grotesk Text Pro-75Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -2px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.lead-form .lead-CTA-2 {
  all: unset;
  align-items: center;
  border: 1px solid;
  border-color: #000000;
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.lead-form .lead-btn-2 {
  all: unset;
  align-items: center;
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 16px 12px;
  position: relative;
}

.lead-form .lead-close {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.lead-form .lead-close-button {
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  height: 20px;
  left: 945px;
  position: absolute;
  top: 26px;
  width: 38px;
}

@media (max-width: 450px) {

  .popup{
    width: 390px;
  }

  .lead-form {
    width: 380px;
  }

  .lead-form .lead-div{
    width: 380px;
    height: 600px;
  }

  .lead-form .lead-frame{
    top: 0;
    left: 0;
    padding: 16px;
    padding-top: 24px;
    gap: 32px;
    width: 100%;
  }
  .lead-form .lead-everything-you-need{
    width: 300px;
    height: auto;
  }
  .lead-form .lead-frame-5{
    flex-direction: column;
    gap: 16px;
  }
  .lead-form input,textarea, .lead-frame-2, .lead-frame-3, .lead-frame-4, .lead-frame-5, .lead-component-form, .lead-frame-wrapper, .lead-div-wrapper-lead, .lead-component-2, .lead-frame-7{
    width: 100% !important;
  }
  .lead-form input,textarea{
    font-size: 16px;
  }
  .lead-form input::placeholder, textarea::placeholder{
    font-size: 16px;
  }
  .lead-form .lead-CTA, .lead-btn{
    width: 100% !important;
    height: 56px !important;
  }
  .lead-form .lead-btn{
    padding: 16px 0;
  }
  .lead-form .lead-frame-8{
    flex-direction: column;
  }
  .lead-form .lead-p{
    width: 282px !important;
  }
  .lead-form .lead-frame-7{
    padding: 8px;
  }
  .lead-form .lead-close-button{
    left: 341px;
    top: 5px;
  }
  .lead-form .lead-CTA-wrapper,.lead-btn-2{
    padding: 12px !important;
  }
}