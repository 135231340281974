.mobile-nav {
  height: 844px;
  position: fixed;
  top: 0;
  z-index: 1111;
  width: 390px;
}


.mobile-nav .div-4-drop {
  align-items: flex-start;
  background-color: var(--shadeswhite);
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 24px;
  overflow-y: scroll;
  /* justify-content: space-between; */
  padding: 32px;
  width: 390px;
}

.mobile-nav .frame-94-drop {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.mobile-nav .logo-4-drop {
  height: 47px;
  object-fit: cover;
  position: relative;
  width: 100px;
}

.mobile-nav .close-drop {
  position: relative;
  width: 32px;
}

.mobile-nav .holder-wrapper-drop {
  align-items: center;
  align-self: stretch;
  display: flex;
  /* flex: 1; */
  /* flex-grow: 1; */
  gap: 10px;
  justify-content: space-around;
  position: relative;
  width: 100%;
}

.mobile-nav .holder-drop {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 16px;
  justify-content: center;
  position: relative;
}

.mobile-nav .frame-95-drop {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 10px 0px;
  position: relative;
  width: 100%;
}

.mobile-nav .frame-95-dropdown {
  align-items: center;
  align-self: stretch;
  flex-direction: column;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 10px 0px;
  position: relative;
  width: 100%;
}

.mobile-nav .dropdown-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;

}

.mobile-nav .dropdown-wrapper .submenu {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  margin-top: 16px;
  
}

.mobile-nav .dropdown-text-wrapper {
  color: #000000;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.18px;
  line-height: 25.2px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.mobile-nav .text-wrapper-75-drop {
  color: #000000;
  background: transparent;
  border: none;
  outline: none;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.18px;
  line-height: 25.2px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.mobile-nav .design-component-instance-node {
  align-self: stretch !important;
  border-color: var(--primary-02) !important;
  display: flex !important;
  height: 69px !important;
  width: 100% !important;
}

.mobile-nav .CTA-14-drop {
  color: #000000 !important;
  font-size: 18px !important;
  letter-spacing: -0.18px !important;
  line-height: 25.2px !important;
  margin-top: unset !important;
}