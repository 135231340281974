.CTA {
  align-items: center;
  border-radius: 56px;
  display: inline-flex;
  padding: 12px 16px;
  position: relative;
}

.CTA .this-is-who-we-are {
  color: #ffffff;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 17px;
  font-weight: 600;
  letter-spacing: -0.17px;
  line-height: 23.8px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.CTA .arrow-forward {
  height: 24px;
  position: relative;
  width: 24px;
}

.CTA.hover {
  background-color: var(--primary-02);
  gap: 8px;
}

.CTA.default {
  border: 1px solid;
  border-color: #ffffff;
  background-color: transparent !important;
  gap: 10px;
}
