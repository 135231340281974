.homepage {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  width: 100%;
  height: fit-content;
  /* background: red; */
  overflow: hidden;
}

.homepage .carousel {
  width: 100%;
  height: auto;
}

.homepage .carousel-1 {
  position: absolute !important;
  z-index: 1;
  top: 475px !important;
  width: 1440px !important;
}

.homepage .carousel-11 .react-multi-carousel-dot-list{
  bottom: 100px;
  left: -1140px;
}

.homepage .carousel-12 .react-multi-carousel-dot-list{
  bottom: 24px;
}

.homepage .carousel-111 .react-multi-carousel-dot-list{
  bottom: 100px;
  left: -1243px;
}

.homepage .carousel-4 .react-multi-carousel-dot-list{
  bottom: 69px;
  left: -264px;
}

/* .homepage .iphone * {
  transform: scale(0.5);
} */
.homepage .iphone {
  background-color: #ffffff;
  /* overflow: hidden; */
  position: relative;
}

.homepage .mobile-carousel-1 {
  left: 16px !important;
  position: absolute !important;
  top: 1401px !important;
  width: 360px;
}

.homepage .overlap-3 {
  position: absolute;
}

.homepage .frame-wrapper {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
  height: 507px;
  left: 0;
  position: absolute;
  top: 0;
  width: 390px;
}

.homepage .video-mobile {
  height: 507px;
  left: 0;
  position: absolute;
  top: 0;
  width: 390px;
}

.homepage .video-mobile::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.16);
}

.homepage .frame-22 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 49px;
  left: 29px;
  position: relative;
  top: 170px;
  width: 332px;
}

.homepage .frame-23 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: 100%;
}

.homepage .accelerating-your {
  color: transparent;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 48px;
  font-weight: 600;
  letter-spacing: -1.44px;
  line-height: 48.7px;
  margin-left: -29px;
  margin-right: -29px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 390px;
}

.homepage .span {
  color: #00dfc2;
}

.homepage .text-wrapper-15 {
  color: #ffffff;
}

.homepage .scale-faster-with-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.homepage .scale-faster-with {
  align-self: stretch;
  color: #ffffff;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.16px;
  line-height: 22.4px;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.homepage .header-instance {
  height: 60px !important;
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
  width: 390px !important;
}

.homepage .component-2 {
  left: 0 !important;
  position: absolute !important;
  top: 475px !important;
  width: 1459px !important;
}

.homepage .component-instance {
  width: 1487px !important;
}

.homepage .component-2-instance {
  width: 1439px !important;
}

.homepage .instance-node {
  width: 702px !important;
}

.homepage .rectangle-7 {
  background-color: #ffffff;
  height: 475px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1439px;
}

.homepage .rectangle-8 {
  height: 159px;
  left: 1131px;
  position: absolute;
  top: 88px;
  width: 308px;
}

.homepage .rectangle-9 {
  height: 159px;
  left: 831px;
  position: absolute;
  top: 247px;
  width: 300px;
}

.homepage .frame-24 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 25px;
  left: 86px;
  position: absolute;
  top: 88px;
}

.homepage .your-future {
  flex: 0 0 auto;
  margin-left: -0.05px;
  position: relative;
  width: 597.72px;
}

.homepage .text-wrapper-16 {
  color: var(--neutral-80);
  font-family: "Source Sans Pro", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.2px;
  line-height: 28px;
  position: relative;
  width: 601px;
}

.homepage .rectangle-10 {
  background-color: #21212a;
  height: 247px;
  left: 831px;
  position: absolute;
  top: 0;
  width: 300px;
}

.homepage .rectangle-11 {
  background-color: #1b766e;
  height: 230px;
  left: 1131px;
  position: absolute;
  top: 247px;
  width: 308px;
}

.homepage .frame-25 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 4px;
  left: 916px;
  position: absolute;
  top: 67px;
}

.homepage .text-wrapper-17 {
  color: #ffffff;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 50px;
  font-weight: 700;
  letter-spacing: -0.75px;
  line-height: 60px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.homepage .text-wrapper-18 {
  color: #b6b6b6;
  white-space: nowrap;
  text-align: center;
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  height: 48px;
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  position: relative;
  width: 128px;
}

.homepage .frame-26 {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 1198px;
  position: absolute;
  top: 310px;
  width: 175px;
}

.homepage .text-wrapper-19 {
  color: #fff6f6;
  text-align: center;
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  height: 29px;
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  margin-left: -9px;
  margin-right: -9px;
  position: relative;
  white-space: nowrap;
  width: 193px;
}

.homepage .frame-27 {
  align-items: flex-start;
  display: inline-flex;
  gap: 61px;
  height: 92px;
  left: 1185px;
  position: absolute;
  top: 118px;
}

.homepage .frame-28 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  margin-bottom: -20px;
  position: relative;
}

.homepage .text-wrapper-20 {
  color: #ffffff;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 45px;
  white-space: nowrap;
  font-weight: 700;
  letter-spacing: -0.75px;
  line-height: 60px;
  margin: -1px 0 0 -169px;
  position: relative;
  text-align: center;
  width: 69px;
}

.homepage .text-wrapper-21 {
  color: #d5d5d5;
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  white-space: nowrap;
  line-height: var(--subtitle-REG-line-height);
  position: relative;
  width: 100%;
}

.homepage .frame-29 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 15.84px;
  left: 26px;
  position: absolute;
  top: 536px;
}

.homepage .your-future-2 {
  flex: 0 0 auto;
  margin-left: -0.02px;
  position: relative;
}

.homepage .text-wrapper-22 {
  color: var(--neutral-80);
  font-family: "Source Sans Pro", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.16px;
  line-height: 22.4px;
  position: relative;
  width: 338px;
}

.homepage .frame-30 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  left: -2px;
  position: absolute;
  top: 777px;
}

.homepage .rectangle-wrapper {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  position: relative;
  width: 392px;
}

.homepage .rectangle-12 {
  background-color: #21212a;
  height: 200px;
  position: relative;
  width: 392px;
}

.homepage .frame-31 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 4px;
  left: 133px;
  position: absolute;
  top: 54px;
}

.homepage .text-wrapper-23 {
  white-space: nowrap;
  color: #b6b6b6;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 17px;
  font-weight: 400;
  height: 48px;
  letter-spacing: -0.17px;
  line-height: 23.8px;
  position: relative;
  text-align: center;
  width: 128px;
}

.homepage .frame-32 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  left: -2px;
  position: absolute;
  top: 1177px;
}

.homepage .rectangle-13 {
  background-color: #1b766e;
  height: 200px;
  position: relative;
  width: 392px;
}

.homepage .frame-33 {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 108px;
  position: absolute;
  top: 55px;
  width: 175px;
}

.homepage .frame-34 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  left: -2px;
  position: absolute;
  top: 977px;
}

.homepage .rectangle-14 {
  height: 200px;
  position: relative;
  width: 392px;
}

.homepage .frame-35 {
  align-items: flex-start;
  display: inline-flex;
  gap: 61px;
  height: 92px;
  left: 93px;
  position: absolute;
  top: 54px;
}

.homepage .frame-36 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 16px;
  left: 0;
  position: absolute;
  top: 2086px;
}

.homepage .card {
  align-items: center;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  gap: 26px;
  height: 490.75px;
  padding: 0px 0px 45.5px;
  position: relative;
  width: 390px;
}

.homepage .deepmind {
  align-self: stretch;
  height: 260px;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.homepage .frame-37 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 26px;
  margin-bottom: -13.75px;
  padding: 0px 65px 0px 45.5px;
  position: relative;
  width: 100%;
}

.homepage .frame-38 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 13px;
  position: relative;
  width: 100%;
}

.homepage .text-wrapper-24 {
  align-self: stretch;
  color: var(--neutral-70);
  font-family: "Source Sans Pro", Helvetica;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: -0.42px;
  line-height: 33.6px;
  margin-top: -0.81px;
  position: relative;
}

.homepage .text-wrapper-25 {
  align-self: stretch;
  color: #797979;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 16.2px;
  font-weight: 400;
  letter-spacing: -0.16px;
  line-height: 22.7px;
  position: relative;
}

.homepage .card-2 {
  align-items: flex-start;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  gap: 26px;
  height: 490.75px;
  padding: 0px 0px 45.5px;
  position: relative;
  width: 390px;
}

.homepage .deepmind-hereyczt {
  align-self: stretch;
  height: 260px;
  position: relative;
  width: 100%;
}

.homepage .searching-for-an {
  align-self: stretch;
  color: #6c6c6c;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 16.2px;
  font-weight: 400;
  letter-spacing: -0.16px;
  line-height: 22.7px;
  position: relative;
}

.homepage .frame-39 {
  background-image: url(../../../static//homepage/deepmind-8hereyc6zt0-unsplash-1-1.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 260px;
  position: relative;
  width: 390px;
}

.homepage .frame-40 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 26px;
  margin-bottom: -13.75px;
  padding: 0px 45.5px;
  position: relative;
  width: 100%;
}

.homepage .frame-41 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 33px;
  left: 33px;
  position: absolute;
  top: 3636px;
  width: 324px;
}

.homepage .frame-42 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
}

.homepage .text-wrapper-26 {
  align-self: stretch;
  color: var(--neutral-90);
  font-family: "Source Sans Pro", Helvetica;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: -0.8px;
  line-height: 38.4px;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.homepage .text-wrapper-27 {
  align-self: stretch;
  color: var(--neutral-60);
  font-family: "Source Sans Pro", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.16px;
  line-height: 22.4px;
  position: relative;
  text-align: center;
}

.homepage .CTA-instance {
  align-items: flex-end !important;
  background-color: var(--primary-02) !important;
  border: unset !important;
  border-color: unset !important;
  display: flex !important;
  flex: 0 0 auto !important;
  justify-content: center !important;
  width: 182px !important;
}

.homepage .CTA-4 {
  font-family: var(--subtitle-BLD-font-family) !important;
  font-size: var(--subtitle-BLD-font-size) !important;
  font-style: var(--subtitle-BLD-font-style) !important;
  font-weight: var(--subtitle-BLD-font-weight) !important;
  letter-spacing: var(--subtitle-BLD-letter-spacing) !important;
  line-height: var(--subtitle-BLD-line-height) !important;
  margin-left: -1px !important;
}

.homepage .CTA-5 {
  margin-right: -1px !important;
}

.homepage .text-wrapper-28 {
  color: var(--neutral-90);
  font-family: "Source Sans Pro", Helvetica;
  font-size: 32px;
  font-weight: 400;
  height: 76px;
  left: 39px;
  letter-spacing: -0.8px;
  line-height: 38.4px;
  position: absolute;
  text-align: center;
  top: 5871px;
  width: 311px;
}

.homepage .we-help-you-recruit {
  color: var(--neutral-60);
  font-family: "Source Sans Pro", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 44px;
  left: 43px;
  letter-spacing: -0.16px;
  line-height: 22.4px;
  position: absolute;
  text-align: center;
  top: 5971px;
  width: 303px;
}

.homepage .frame-43 {
  align-items: center;
  background-color: #f1f1f1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 701px;
  left: 0;
  overflow: hidden;
  padding: 31px 0px;
  position: absolute;
  top: 6062px;
  width: 390px;
}

.homepage .frame-44 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 365px;
}

.homepage .text-wrapper-29 {
  color: #4d9f98;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.6px;
  line-height: 28.8px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.homepage .india-s-first-of-its {
  align-self: stretch;
  color: var(--neutral-90);
  font-family: "Source Sans Pro", Helvetica;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: -0.8px;
  line-height: 38.4px;
  position: relative;
}

.homepage .frame-45 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
}

.homepage .text-wrapper-30 {
  align-self: stretch;
  color: var(--neutral-80);
  font-family: "Source Sans Pro", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.16px;
  line-height: 22.4px;
  margin-top: -1px;
  position: relative;
}

.homepage .CTA-6 {
  background-color: #4d9f98 !important;
  border: unset !important;
  border-color: unset !important;
  flex: 0 0 auto !important;
}

.homepage .CTA-7 {
  font-family: var(--subtitle-BLD-font-family) !important;
  font-size: var(--subtitle-BLD-font-size) !important;
  font-style: var(--subtitle-BLD-font-style) !important;
  font-weight: var(--subtitle-BLD-font-weight) !important;
  letter-spacing: var(--subtitle-BLD-letter-spacing) !important;
  line-height: var(--subtitle-BLD-line-height) !important;
}

.homepage .overlap-group-wrapper {
  height: 309px;
  margin-bottom: -31px;
  margin-left: -1px;
  margin-right: -1px;
  position: relative;
  width: 392px;
}

.homepage .smiley-businesswoman-wrapper {
  background-image: url(../../../static/homepage/mask-group-2.png);
  background-size: 100% 100%;
  height: 303px;
  left: 1px;
  position: relative;
  top: -16px;
  width: 390px;
}

.homepage .smiley-businesswoman {
  height: 252px;
  left: 116px;
  position: absolute;
  top: 52px;
  width: 194px;
}

.homepage .frame-46 {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
  height: 752px;
  left: -9px;
  position: absolute;
  top: 0;
  width: 1449px;
}

.homepage .video {
  height: 752px;
  position: absolute;
  width: 1449px;
  top: 0;
}

.homepage .video::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.16);
}

.homepage .header-2 {
  align-items: center;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  left: 9px;
  padding: 16px 80px;
  position: absolute;
  top: 0;
  width: 1440px;
}

.homepage .logo {
  height: 67.67px;
  object-fit: cover;
  position: relative;
  width: 145px;
}

.homepage .holder {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16px;
  position: relative;
}

.homepage .frame-47 {
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 10px 8px;
  position: relative;
  width: 90.33px;
}

.homepage .text-wrapper-31 {
  color: #ffffff;
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.homepage .text-wrapper-32 {
  color: #ffffff;
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  margin-left: -2.83px;
  margin-right: -2.83px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.homepage .frame-48 {
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 10px 8px;
  position: relative;
  width: 80px;
}

.homepage .CTA-8 {
  background-color: #ffffff !important;
  border-color: #4d9f98 !important;
  display: flex !important;
  height: 69px !important;
  width: 179px !important;
}

.homepage .CTA-9 {
  color: #280a4e !important;
  margin-top: unset !important;
}

.homepage .frame-49 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 37px;
  left: 321px;
  position: absolute;
  top: 249px;
}

.homepage .frame-50 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 49px;
  position: relative;
}

.homepage .frame-51 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  position: relative;
}

.homepage .accelerating-your-2 {
  color: transparent;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 64px;
  font-weight: 600;
  letter-spacing: -1.92px;
  line-height: 65px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 755px;
}

.homepage .frame-52 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  position: relative;
  width: 763px;
}

.homepage .scale-faster-with-2 {
  color: #ffffff;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.2px;
  line-height: 28px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 675px;
}

.homepage .frame-53 {
  position: absolute;
}

.homepage .card-3 {
  align-items: center;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: 604px;
  left: 0;
  padding: 0px 0px 56px;
  position: absolute;
  top: 0;
  width: 480px;
}

.homepage .deepmind-2 {
  align-self: stretch;
  height: 320px;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.homepage .frame-54 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  margin-bottom: -16px;
  padding: 0px 80px 0px 56px;
  position: relative;
  width: 100%;
}

.homepage .text-wrapper-33 {
  align-self: stretch;
  color: var(--neutral-70);
  font-family: "Source Sans Pro", Helvetica;
  font-size: 35px;
  font-weight: 400;
  letter-spacing: -0.53px;
  line-height: 42px;
  margin-top: -1px;
  position: relative;
}

.homepage .text-wrapper-34 {
  align-self: stretch;
  color: #797979;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.2px;
  line-height: 28px;
  position: relative;
}

.homepage .card-4 {
  align-items: flex-start;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: 604px;
  left: 960px;
  padding: 0px 0px 56px;
  position: absolute;
  top: 0;
  width: 480px;
}

.homepage .frame-55 {
  background-image: url(../../../static/homepage/deepmind-8hereyc6zt0-unsplash-1-1.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 320px;
  position: relative;
  width: 480px;
}

.homepage .frame-56 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  margin-bottom: -16px;
  padding: 0px 56px;
  position: relative;
  width: 100%;
}

.homepage .card-5 {
  align-items: flex-start;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: 604px;
  left: 480px;
  padding: 0px 0px 56px;
  position: absolute;
  top: 0;
  width: 480px;
}

.homepage .deepmind-3 {
  align-self: stretch;
  height: 320px;
  position: relative;
  width: 100%;
}

.homepage .searching-for-an-2 {
  align-self: stretch;
  color: #6c6c6c;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.2px;
  line-height: 28px;
  position: relative;
}

.homepage .group-wrapper {
  height: 387px;
  left: 5px;
  position: absolute;
  top: 325px;
  width: 360px;
}

.homepage .group-3 {
  height: 391px;
  position: relative;
  top: -5px;
}

.homepage .overlap-group-4 {
  height: 392px;
  position: relative;
  width: 361px;
}

.homepage .vector {
  height: 297px;
  left: 0;
  position: absolute;
  top: 95px;
  width: 323px;
}

.homepage .vector-2 {
  height: 291px;
  left: 7px;
  position: absolute;
  top: 86px;
  width: 316px;
}

.homepage .vector-3 {
  height: 315px;
  left: 17px;
  position: absolute;
  top: 53px;
  width: 343px;
}

.homepage .mask-group {
  height: 377px;
  left: 7px;
  position: absolute;
  top: 0;
  width: 316px;
}

.homepage .frame-57 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 383px;
  left: 0;
  position: absolute;
  top: 0;
  width: 364px;
}

.homepage .one-stop-solution {
  color: var(--neutral-90);
  font-family: "Source Sans Pro", Helvetica;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: -0.8px;
  line-height: 38.4px;
  position: relative;
  width: 364px;
}

.homepage .frame-58 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.homepage .the-offerletter {
  color: var(--neutral-80);
  font-family: "Source Sans Pro", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.16px;
  line-height: 22.4px;
  margin-top: -1px;
  position: relative;
  width: 351px;
}

.homepage .overlap-wrapper-home {
  height: 356px;
  left: 2px;
  position: absolute;
  top: 5961px;
  width: 1440px;
}

.homepage .overlap-4 {
  height: 356px;
  position: relative;
}

.homepage .section-industries {
  background-color: #f0f0f0;
  height: 356px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1440px;
}

.homepage .frame-59 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  left: 80px;
  position: relative;
  top: 45px;
}

.homepage .frame-60 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
}

.homepage .text-wrapper-35 {
  color: #000000;
  font-family: var(--h3-REG-font-family);
  font-size: var(--h3-REG-font-size);
  font-style: var(--h3-REG-font-style);
  font-weight: var(--h3-REG-font-weight);
  letter-spacing: var(--h3-REG-letter-spacing);
  line-height: var(--h3-REG-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.homepage .are-we-a-good-fit {
  color: #000000;
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  position: relative;
  width: 326px;
}

.homepage .CTA-wrapper {
  all: unset;
  align-items: center;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.homepage .this-is-who-we-are-wrapper {
  all: unset;
  align-items: flex-end;
  background-color: var(--primary-02);
  border-radius: 56px;
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 12px 16px;
  position: relative;
  width: 182px;
}

.homepage .this-is-who-we-are-2 {
  color: #ffffff;
  font-family: var(--subtitle-BLD-font-family);
  font-size: var(--subtitle-BLD-font-size);
  font-style: var(--subtitle-BLD-font-style);
  font-weight: var(--subtitle-BLD-font-weight);
  letter-spacing: var(--subtitle-BLD-letter-spacing);
  line-height: var(--subtitle-BLD-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.homepage .container {
  align-items: center;
  display: flex;
  gap: 24px;
  height: 237px;
  left: 487px;
  position: absolute;
  top: 59px;
  width: 922px;
}

.homepage .frame-61 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24px;
  margin-bottom: -57.5px;
  margin-top: -57.5px;
  position: relative;
}

.homepage .BFSI {
  background-image: url(../../../static/homepage/rectangle-24.webp);
  background-size: 100% 100%;
  height: 240px;
  position: relative;
  width: 264px;
}

.homepage .text-wrapper-36 {
  color: #ffffff;
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  height: 34px;
  left: 27px;
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  position: absolute;
  top: 179px;
  white-space: nowrap;
}

.homepage .IT-services {
  background: linear-gradient(180deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
  background-image: url(../../../static/homepage/image-1.webp);
  background-size: 100% 100%;
  height: 240px;
  position: relative;
  width: 263px;
}

.homepage .telecom {
  background-image: url(../../../static/homepage/rectangle-25.webp);
  background-size: 100% 100%;
  height: 240px;
  position: relative;
  width: 263px;
}

.homepage .telecom-2 {
  background-image: url(../../../static/homepage/rectangle-25-1.webp);
  background-size: 100% 100%;
  height: 240px;
  position: relative;
  width: 263px;
}

.homepage .overlap-group-5 {
  height: 34px;
  left: 27px;
  position: relative;
  top: 179px;
  width: 79px;
}

.homepage .text-wrapper-37 {
  color: #ffffff;
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  height: 34px;
  left: 0;
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.homepage .IT-services-2 {
  background-image: url(../../../static/homepage/rectangle-23.webp);
  background-position: 50% 50%;
  background-size: cover;
  height: 240px;
  position: relative;
  width: 263px;
}

.homepage .telecom-3 {
  background-image: url(../../../static/homepage/rectangle-25-2.webp);
  background-position: 50% 50%;
  background-size: cover;
  height: 240px;
  position: relative;
  width: 263px;
}

.homepage .telecom-4 {
  background-image: url(../../../static/homepage/rectangle-25-3.webp);
  background-position: 50% 50%;
  background-size: cover;
  height: 240px;
  position: relative;
  width: 263px;
}

.homepage .arrow {
  background-color: #ffffff;
  border-radius: 30px;
  box-shadow: -3px 1px 16px #00000040;
  height: 60px;
  left: 1350px;
  position: absolute;
  top: 148px;
  width: 60px;
}

.homepage .arrow-forward-ios {
  height: 24px;
  left: 18px;
  position: absolute;
  top: 18px;
  width: 24px;
}

.homepage .frame-62 {
  align-items: flex-start;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #8b8b8b;
  border-radius: 22px;
  box-shadow: 0px 4px 14px #00000040;
  display: flex;
  flex-direction: column;
  gap: 10px;
  left: 82px;
  padding: 50px 147px 50px 104px;
  position: absolute;
  top: 6406px;
  width: 1280px;
}

.homepage .frame-63 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 203px;
  margin-right: -47px;
  position: relative;
}

.homepage .frame-64 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 14px;
  position: relative;
}

.homepage .text-wrapper-38 {
  color: var(--secondary-04);
  font-family: "Source Sans Pro", Helvetica;
  font-size: 42px;
  font-weight: 400;
  letter-spacing: -1.05px;
  line-height: 50.4px;
  margin-top: -1px;
  position: relative;
  width: 530px;
}

.homepage .you-are-just-one {
  color: #363636;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.2px;
  line-height: 28px;
  position: relative;
  width: 638px;
}

.homepage .CTA-10 {
  cursor: pointer;
  align-items: center;
  outline: none;
  border: none;
  background-color: #05464c;
  border-radius: 56px;
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  height: 58px;
  justify-content: center;
  padding: 12px 16px;
  position: relative;
  width: 235px;
}

.homepage .text-wrapper-39 {
  color: #ffffff;
  font-family: var(--subtitle-BLD-font-family);
  font-size: var(--subtitle-BLD-font-size);
  font-style: var(--subtitle-BLD-font-style);
  font-weight: var(--subtitle-BLD-font-weight);
  letter-spacing: var(--subtitle-BLD-letter-spacing);
  line-height: var(--subtitle-BLD-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.homepage .carousel-111 {
  position: absolute;
  top: 5381px;
  left: 0;
  width: 1440px;
}

.homepage .property-default-instance {
  left: 0 !important;
  position: absolute !important;
  top: 5381px !important;
}

.homepage .property-1-default-instance {
  left: -824px !important;
  top: -5369px !important;
}

.homepage .carousel-2 {
  margin-left: -1158px !important;
  margin-top: -5741px !important;
}

.homepage .carousel-3 {
  margin-left: -2598px !important;
  margin-top: -5764px !important;
}

.homepage .group-4 {
  height: 1415px;
  left: 0;
  position: absolute;
  top: 3935px;
  width: 1440px;
}

.homepage .frame-65 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  left: 392px;
  position: absolute;
  top: 0;
}

.homepage .frame-66 {
  height: 90px;
  position: relative;
  width: 670px;
}

.homepage .text-wrapper-40 {
  color: var(--neutral-90);
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  height: 50px;
  left: 0;
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  position: absolute;
  text-align: center;
  top: -1px;
  white-space: nowrap;
}

.homepage .we-help-you-recruit-2 {
  color: var(--neutral-60);
  font-family: "Source Sans Pro", Helvetica;
  font-size: 17px;
  font-weight: 400;
  height: 24px;
  left: 86px;
  letter-spacing: -0.17px;
  line-height: 23.8px;
  position: absolute;
  text-align: center;
  top: 65px;
  width: 497px;
}

.homepage .group-5 {
  height: 558px;
  left: 0;
  position: absolute;
  top: 147px;
  width: 1440px;
}

.homepage .overlap-5 {
  height: 558px;
  position: relative;
}

.homepage .frame-67 {
  height: 558px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1440px;
}

.homepage .overlap-group-6 {
  background-color: #f1f1f1;
  height: 558px;
  position: relative;
  width: 1438px;
}

.homepage .mask-group-2 {
  height: 558px;
  left: 748px;
  position: absolute;
  top: 0;
  width: 690px;
}

.homepage .smiley-businesswoman-2 {
  height: 489px;
  left: 935px;
  position: absolute;
  top: 69px;
  width: 382px;
}

.homepage .frame-68 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 24px;
  left: 80px;
  position: absolute;
  top: 94px;
  width: 487px;
}

.homepage .text-wrapper-41 {
  color: var(--neutral-90);
  font-family: "Source Sans Pro", Helvetica;
  font-size: 42px;
  font-weight: 400;
  letter-spacing: -1.05px;
  line-height: 50.4px;
  position: relative;
  width: 486px;
}

.homepage .text-wrapper-42 {
  align-self: stretch;
  color: var(--neutral-80);
  font-family: "Source Sans Pro", Helvetica;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: -0.17px;
  line-height: 23.8px;
  margin-top: -1px;
  position: relative;
}

.homepage .frame-69 {
  height: 676px;
  left: 147px;
  position: absolute;
  top: 739px;
  width: 1179px;
}

.homepage .frame-70 {
  height: 641px;
  left: 0;
  position: absolute;
  top: 0;
  width: 597px;
}

.homepage .group-6 {
  height: 649px;
  position: relative;
  top: -8px;
}

.homepage .overlap-group-7 {
  height: 650px;
  position: relative;
  width: 598px;
}

.homepage .vector-4 {
  height: 492px;
  left: 0;
  position: absolute;
  top: 158px;
  width: 536px;
}

.homepage .vector-5 {
  height: 482px;
  left: 11px;
  position: absolute;
  top: 143px;
  width: 524px;
}

.homepage .vector-6 {
  height: 522px;
  left: 29px;
  position: absolute;
  top: 88px;
  width: 569px;
}

.homepage .mask-group-3 {
  height: 625px;
  left: 11px;
  position: absolute;
  top: 0;
  width: 524px;
}

.homepage .frame-71 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
  height: 383px;
  left: 693px;
  position: absolute;
  top: 129px;
}

.homepage .the-offerletter-2 {
  color: var(--neutral-80);
  font-family: "Source Sans Pro", Helvetica;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: -0.17px;
  line-height: 23.8px;
  margin-top: -1px;
  position: relative;
  width: 470px;
}

.homepage .frame-72 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
  height: 383px;
  left: 564px;
  position: absolute;
  top: 7006px;
}

.homepage .CTA-11 {
  margin-left: -1731px !important;
  margin-top: -7290px !important;
}

.homepage .carousel-4 {
  left: 0 !important;
  position: absolute !important;
  top: 7564px !important;
  width: 390px;
}

.homepage .carousel-5 {
  left: -2326px !important;
  top: -7552px !important;
}

.homepage .carousel-6 {
  margin-left: -2660px !important;
  margin-top: -7924px !important;
}

.homepage .carousel-7 {
  margin-left: -4100px !important;
  margin-top: -7947px !important;
}

.homepage .frame-73 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 32px;
  left: 38px;
  position: absolute;
  top: 8187px;
}

.homepage .frame-74 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
}

.homepage .text-wrapper-43 {
  color: #000000;
  font-family: var(--h3-REG-font-family);
  font-size: var(--h3-REG-font-size);
  font-style: var(--h3-REG-font-style);
  font-weight: var(--h3-REG-font-weight);
  letter-spacing: var(--h3-REG-letter-spacing);
  line-height: var(--h3-REG-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.homepage .are-we-a-good-fit-2 {
  color: #000000;
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  position: relative;
  text-align: center;
  width: 326px;
}

.homepage .container-2 {
  align-items: center;
  display: flex;
  gap: 24px;
  height: 237px;
  left: 18px;
  overflow: hidden;
  padding: 56px 272px 56px 0px;
  position: absolute;
  top: 8483px;
  width: 838px;
}

.homepage .gics-gccs-wrapper {
  background-image: url(../../../static/homepage/rectangle-24-1.webp);
  background-size: 100% 100%;
  height: 240px;
  position: relative;
  width: 264px;
}

.homepage .arrow-forward-ios-wrapper {
  background-color: #ffffff;
  border-radius: 23px;
  border: none;
  outline: none;
  height: 46px;
  left: 300px;
  position: absolute;
  top: 95px;
  width: 46px;
}

.homepage .arrow-forward-ios-2 {
  height: 18px;
  left: 14px;
  position: absolute;
  top: 14px;
  width: 18px;
}

.homepage .frame-75 {
  align-items: flex-start;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #8b8b8b;
  border-radius: 22px;
  box-shadow: 0px 4px 14px #00000040;
  display: flex;
  flex-direction: column;
  gap: 10px;
  left: 19px;
  padding: 50px 30px;
  position: absolute;
  top: 8763px;
  width: 352px;
}

.homepage .frame-76 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 50px;
  position: relative;
  width: 287px;
}

.homepage .text-wrapper-44 {
  align-self: stretch;
  color: var(--secondary-04);
  font-family: "Source Sans Pro", Helvetica;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: -0.8px;
  line-height: 38.4px;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.homepage .you-are-just-one-2 {
  align-self: stretch;
  color: #363636;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.2px;
  line-height: 28px;
  position: relative;
  text-align: center;
}