.CTA {
  all: unset;
  align-items: flex-end;
  background-color: #4d9f98;
  border-radius: 56px;
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 12px 16px;
  position: relative;
  width: 182px;
  transition: all ease-in-out 0.3s;
}
.CTA:hover{
  gap: 8px;
}

.CTA .text-wrapper {
  color: #ffffff;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: -0.15px;
  line-height: 21px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.CTA .arrow-forward {
  height: 20px;
  position: relative;
  width: 20px;
}
