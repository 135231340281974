.homepage-frame {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  position: relative;
}

.homepage-frame .rectangle-mobile {
  height: 223px;
  position: relative;
  width: 360px;
}

.homepage-frame .div-mobile {
  background-color: #1b766e;
  height: 424px;
  position: relative;
  width: 360px;
}

.homepage-frame .agile-talent {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(180deg, rgb(0, 223, 194) 0%, rgba(255, 255, 255, 0.72) 100%);
  background-clip: text;
  color: transparent;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 28px;
  font-weight: 600;
  height: 68px;
  left: 30px;
  letter-spacing: -0.42px;
  line-height: 33.6px;
  position: absolute;
  text-fill-color: transparent;
  top: 39px;
  width: 298px;
}

.homepage-frame .an-execution-driven {
  color: #ffffff;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 30px;
  letter-spacing: -0.18px;
  line-height: 25.2px;
  position: absolute;
  top: 125px;
  width: 298px;
}

.homepage-frame .div-2 {
  align-items: flex-start;
  display: inline-flex;
  gap: 6.56px;
  left: 145px;
  position: absolute;
  top: 388px;
}

.homepage-frame .ellipse {
  border-radius: 4.47px;
  height: 8.95px;
  position: relative;
  width: 8.95px;
}

.homepage-frame .ellipse-2 {
  border-radius: 4.47px;
  height: 8.95px;
  position: relative;
  width: 8.95px;
}

.homepage-frame .ellipse-3 {
  border-radius: 4.47px;
  height: 8.95px;
  position: relative;
  width: 8.95px;
}

.homepage-frame .ellipse-4 {
  border-radius: 4.47px;
  height: 8.95px;
  position: relative;
  width: 8.95px;
}

.homepage-frame .ellipse-5 {
  border-radius: 4.47px;
  height: 8.95px;
  position: relative;
  width: 8.95px;
}

.homepage-frame .variant-4 {
  height: 125px;
}

.homepage-frame .variant-2 {
  height: 125px;
}

.homepage-frame .variant-3 {
  height: 150px;
}

.homepage-frame .variant-5 {
  height: 150px;
}

.homepage-frame .default {
  height: 125px;
}

.homepage-frame .property-1-variant-4 {
  background-color: #c2c2c2;
}

.homepage-frame .property-1-variant-2 {
  background-color: #c2c2c2;
}

.homepage-frame .property-1-variant-3 {
  background-color: #c2c2c2;
}

.homepage-frame .property-1-variant-5 {
  background-color: #c2c2c2;
}

.homepage-frame .property-1-default {
  background-color: #4d9f98;
}

.homepage-frame .property-1-0-variant-4 {
  background-color: #c2c2c2;
}

.homepage-frame .property-1-0-variant-2 {
  background-color: #4d9f98;
}

.homepage-frame .property-1-0-variant-3 {
  background-color: #c2c2c2;
}

.homepage-frame .property-1-0-variant-5 {
  background-color: #c2c2c2;
}

.homepage-frame .property-1-0-default {
  background-color: #c2c2c2;
}

.homepage-frame .property-1-1-variant-4 {
  background-color: #c2c2c2;
}

.homepage-frame .property-1-1-variant-2 {
  background-color: #c2c2c2;
}

.homepage-frame .property-1-1-variant-3 {
  background-color: #4d9f98;
}

.homepage-frame .property-1-1-variant-5 {
  background-color: #c2c2c2;
}

.homepage-frame .property-1-1-default {
  background-color: #c2c2c2;
}

.homepage-frame .property-1-2-variant-4 {
  background-color: #4d9f98;
}

.homepage-frame .property-1-2-variant-2 {
  background-color: #c2c2c2;
}

.homepage-frame .property-1-2-variant-3 {
  background-color: #c2c2c2;
}

.homepage-frame .property-1-2-variant-5 {
  background-color: #c2c2c2;
}

.homepage-frame .property-1-2-default {
  background-color: #c2c2c2;
}

.homepage-frame .property-1-3-variant-4 {
  background-color: #c2c2c2;
}

.homepage-frame .property-1-3-variant-2 {
  background-color: #c2c2c2;
}

.homepage-frame .property-1-3-variant-3 {
  background-color: #c2c2c2;
}

.homepage-frame .property-1-3-variant-5 {
  background-color: #4d9f98;
}

.homepage-frame .property-1-3-default {
  background-color: #c2c2c2;
}
