.mobile-only {
    display: none !important;
}

.display-content {
    display: contents !important;
}

body {
    overflow-x: hidden;
}

button {
    cursor: pointer !important;
}

.error {
    color: red;
    font-family: "Source Sans Pro", Helvetica;
    font-size: 12px;
    margin: -22px 0 -15px 0;
}

.error-2 {
    color: red;
    font-family: "Source Sans Pro", Helvetica;
    font-size: 12px;
    margin-top: 5px;
}

.error-1 {
    color: red;
    font-family: "Source Sans Pro", Helvetica;
    font-size: 12px;
    margin-top: -10px;
}


.next-button {
    background: #fff;
    outline: none;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    position: absolute;
    right: 0;
    width: 60px;
    height: 60px;
}

.dot-active-green {
    border-radius: 50%;
    width: 8.95px;
    height: 10.95px;
    background: #4D9F98;
    border: none;
    margin-left: 4px;
    outline: none;
}

.dot-inactive-green {
    border-radius: 50%;
    width: 8.95px;
    margin-left: 4px;
    height: 10.95px;
    background: #C2C2C2;
    border: none;
    outline: none;
} 

.dot-active {
    border-radius: 50%;
    width: 8.95px;
    height: 10.95px;
    background: #E9E9E9;
    border: none;
    margin-left: 4px;
    outline: none;
}

.dot-inactive {
    border-radius: 50%;
    width: 8.95px;
    margin-left: 4px;
    height: 10.95px;
    background: rgba(255, 255, 255, 0.32);
    border: none;
    outline: none;
} 

@media (max-width: 450px) {
    .mobile-only {
        display: block !important;
    }

    .desktop-only {
        display: none !important;
    }
}