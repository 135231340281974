.captive {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  overflow: hidden;
  width: 100%;
}

.captive .carousel {
  width: 100%;
  height: auto;
}

.captive .div-2 {
  background-color: #ffffff;
  position: relative;
}

.captive .overlap-wrapper {
  height: 536px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 60px;
  width: 390px;
}

.captive .overlap {
  height: 674px;
  position: relative;
  width: 1456px;
}

.captive .frame-7 {
  background-color: var(--neutral-10);
  height: 674px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 1456px;
}

.captive .overlap-2 {
  height: 1182px;
  left: -252px;
  position: absolute;
  top: -4px;
  width: 1706px;
}

.captive .overlap-group-3 {
  height: 1003px;
  left: 271px;
  position: absolute;
  top: 179px;
  width: 1435px;
}

.captive .ellipse-5 {
  border: 2px solid;
  border-color: var(--neutral-40);
  border-radius: 261px;
  height: 522px;
  left: 816px;
  position: absolute;
  top: 14px;
  width: 522px;
}

.captive .ellipse-6 {
  background-color: #ffffff;
  border-radius: 233px;
  height: 466px;
  left: 844px;
  position: absolute;
  top: 42px;
  width: 466px;
}

.captive .rectangle-4 {
  background: linear-gradient(180deg, rgb(0, 0, 0) 0%, rgba(217, 217, 217, 0) 100%);
  height: 298px;
  left: 625px;
  mix-blend-mode: multiply;
  position: absolute;
  top: 0;
  width: 639px;
}

.captive .rectangle-5 {
  background-color: #d9d9d9;
  height: 498px;
  left: 0;
  position: absolute;
  top: 505px;
  width: 1435px;
}

.captive .overlap-3 {
  height: 540px;
  left: 0;
  position: absolute;
  top: 0;
  width: 695px;
}

.captive .two-collegues {
  object-fit: cover;
  height: 536px;
  left: 252px;
  position: absolute;
  top: 4px;
  width: 390px;
}

.captive .friendly-partners {
  background: linear-gradient(180deg, rgb(44, 62, 71) 0%, rgba(44, 62, 71, 0) 100%);
  height: 540px;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(180deg);
  width: 695px;
}

.captive .ellipse-7 {
  height: 436px;
  left: 3072px;
  object-fit: cover;
  position: absolute;
  top: -1413px;
  width: 436px;
}

.captive .frame-8 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  left: 16px;
  padding: 0px 54px;
  position: absolute;
  top: 86px;
  width: 358px;
}

.captive .standardise-your {
  color: transparent;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: -0.8px;
  line-height: 38.4px;
  margin-left: -27px;
  margin-right: -27px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 304px;
}

.captive .span {
  color: #ffffff;
}

.captive .text-wrapper-8 {
  color: #00dfc2;
}

.captive .text-wrapper-9 {
  color: var(--neutral-0);
  font-family: "Source Sans Pro", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.16px;
  line-height: 22.4px;
  margin-left: -16px;
  margin-right: -16px;
  position: relative;
  text-align: center;
  width: 282px;
}

.captive .CTA-instance {
  flex: 0 0 auto !important;
}

.captive .frame-wrapper {
  height: 64px;
  left: 48px;
  position: absolute;
  top: 732px;
  width: 295px;
}

.captive .frame-14 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  position: relative;
  width: 295px;
}

.captive .text-wrapper-16 {
  align-self: stretch;
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.captive .ellipse-8 {
  background-color: var(--grey-01);
  border-radius: 1px/1.5px;
  height: 3px;
  position: relative;
  width: 2px;
}

.captive .text-wrapper-17 {
  align-self: stretch;
  color: var(--grey-01);
  font-family: var(--labels-captions-REG-font-family);
  font-size: var(--labels-captions-REG-font-size);
  font-style: var(--labels-captions-REG-font-style);
  font-weight: var(--labels-captions-REG-font-weight);
  letter-spacing: var(--labels-captions-REG-letter-spacing);
  line-height: var(--labels-captions-REG-line-height);
  position: relative;
  text-align: center;
}

.captive .overlap-5 {
  position: absolute;
}

.captive .frame-15 {
  background-color: #f6f7f8;
  position: absolute;
}

.captive .frame-16 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  left: 35px;
  position: absolute;
  top: 39px;
}

.captive .div-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
}

.captive .text-wrapper-18 {
  align-self: stretch;
  color: #294b51;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: -0.8px;
  line-height: 38.4px;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.captive .frame-17 {
  align-items: flex-start;
  display: flex;
  justify-content: center;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 48px 32px;
  position: relative;
  width: 326px;
}

.captive .text-wrapper-19 {
  -webkit-text-stroke: 0.5px #2e5053;
  color: #2e5053;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 50px;
  font-weight: 400;
  letter-spacing: -0.75px;
  line-height: 60px;
  margin-top: -1.5px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.captive .text-wrapper-20 {
  color: var(--neutral-70);
  font-family: "Source Sans Pro", Helvetica;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: -0.17px;
  line-height: 23.8px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.captive .frame-18 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
}

.captive .vector {
  height: 52px;
  margin-top: -0.5px;
  position: relative;
  width: 44px;
}

.captive .text-wrapper-21 {
  color: var(--neutral-70);
  font-family: "Source Sans Pro", Helvetica;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: -0.17px;
  line-height: 23.8px;
  position: relative;
  width: 109px;
}

.captive .group-2 {
  height: 52.98px;
  margin-top: -0.5px;
  position: relative;
  width: 73.35px;
}

.captive .text-wrapper-22 {
  color: var(--neutral-70);
  font-family: "Source Sans Pro", Helvetica;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: -0.17px;
  line-height: 23.8px;
  position: relative;
  text-align: center;
  width: 125px;
}

.captive .vector-2 {
  height: 56px;
  margin-top: -0.5px;
  position: relative;
  width: 49px;
}

.captive .text-wrapper-23 {
  color: var(--neutral-70);
  font-family: "Source Sans Pro", Helvetica;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: -0.17px;
  line-height: 23.8px;
  position: relative;
  width: 123px;
}

.captive .frame-19 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

.captive .text-wrapper-24 {
  color: var(--neutral-70);
  font-family: "Source Sans Pro", Helvetica;
  font-size: 17px;
  font-weight: 400;
  height: 55px;
  letter-spacing: -0.17px;
  line-height: 23.8px;
  position: relative;
  text-align: center;
  width: 68px;
}

.captive .rectangle-6 {
  background-color: #2c3e47;
  height: 951px;
  left: 0;
  position: absolute;
  top: 583px;
  width: 393px;
}

.captive .two-collegues-2 {
  position: absolute;
}

.captive .rectangle-7 {
  position: absolute;
}

.captive .frame-20 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  position: absolute;
}

.captive .frame-21 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  margin-bottom: 30px;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.captive .standardise-your-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  position: relative;
}

.captive .standardise-your-2 {
  color: transparent;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 35px;
  font-weight: 700;
  letter-spacing: -0.53px;
  line-height: 49px;
  margin-top: -1px;
  position: relative;
  width: 565px;
}

.captive .text-wrapper-25 {
  color: #f6f7f8;
  font-family: var(--subtitle-REG-font-family);
  font-size: var(--subtitle-REG-font-size);
  font-style: var(--subtitle-REG-font-style);
  font-weight: var(--subtitle-REG-font-weight);
  letter-spacing: var(--subtitle-REG-letter-spacing);
  line-height: var(--subtitle-REG-line-height);
  position: relative;
  width: 492px;
}

.captive .instance-node {
  align-items: flex-end !important;
  background-color: #4d9f98 !important;
  border: unset !important;
  border-color: unset !important;
  display: flex !important;
  flex: 0 0 auto !important;
  justify-content: center !important;
  width: 182px !important;
}

.captive .CTA-3 {
  font-family: var(--subtitle-BLD-font-family) !important;
  font-size: var(--subtitle-BLD-font-size) !important;
  font-style: var(--subtitle-BLD-font-style) !important;
  font-weight: var(--subtitle-BLD-font-weight) !important;
  letter-spacing: var(--subtitle-BLD-letter-spacing) !important;
  line-height: var(--subtitle-BLD-line-height) !important;
  margin-left: -1px !important;
}

.captive .CTA-4 {
  margin-right: -1px !important;
}

.captive .frame-22 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 48px;
  position: relative;
  width: 344px;
}

.captive .frame-23 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 16px;
  position: relative;
}

.captive .img-wrapper {
  background-color: #2e5053;
  border-radius: 16px;
  height: 82.76px;
  position: relative;
  width: 83.55px;
}

.captive .payroll {
  height: 44px;
  left: 20px;
  position: absolute;
  top: 19px;
  width: 44px;
}

.captive .text-wrapper-26 {
  white-space: nowrap;
  color: #ffffff;
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  position: relative;
  width: 134px;
}

.captive .frame-24 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 16px;
  justify-content: center;
  position: relative;
}

.captive .accounting {
  height: 43px;
  left: 20px;
  position: absolute;
  top: 17px;
  width: 43px;
}

.captive .text-wrapper-27 {
  color: #ffffff;
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.captive .name-tag {
  height: 46px;
  left: 19px;
  position: absolute;
  top: 18px;
  width: 46px;
}

.captive .text-wrapper-28 {
  color: #ffffff;
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  position: relative;
  text-align: center;
  width: 146px;
}

.captive .money-yours {
  height: 40px;
  left: 22px;
  position: absolute;
  top: 21px;
  width: 40px;
}

.captive .text-wrapper-29 {
  white-space: nowrap;
  color: #ffffff;
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  position: relative;
  width: 120px;
}

.captive .file {
  height: 39px;
  left: 21px;
  position: absolute;
  top: 22px;
  width: 42px;
}

.captive .insurance-agent {
  height: 42px;
  left: 21px;
  position: absolute;
  top: 20px;
  width: 42px;
}

.captive .text-wrapper-30 {
  color: #ffffff;
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  position: relative;
  text-align: center;
  width: 148px;
}

.captive .worker {
  height: 37px;
  left: 23px;
  position: absolute;
  top: 23px;
  width: 37px;
}

.captive .text-wrapper-31 {
  white-space: nowrap;
  color: #ffffff;
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  position: relative;
  width: 148px;
}

.captive .shop {
  height: 38px;
  left: 23px;
  position: absolute;
  top: 22px;
  width: 38px;
}

.captive .frame-25 {
  height: 1188px;
  left: 0;
  position: absolute;
  top: 1534px;
  width: 390px;
}

.captive .text-wrapper-32 {
  color: #1f1f1f;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 32px;
  font-weight: 400;
  height: 38px;
  left: 10px;
  letter-spacing: -0.8px;
  line-height: 38.4px;
  position: absolute;
  text-align: center;
  top: 44px;
  width: 371px;
}

.captive .overlap-group-wrapper {
  height: 340px;
  left: 48px;
  position: absolute;
  top: 104px;
  width: 278px;
}

.captive .overlap-group-4 {
  height: 329px;
  left: 1px;
  position: relative;
  top: 5px;
  width: 296px;
}

.captive .vector-3 {
  height: 254px;
  left: 10px;
  position: absolute;
  top: 16px;
  width: 254px;
}

.captive .group-3 {
  height: 329px;
  left: 0;
  position: absolute;
  top: 0;
  width: 275px;
}

.captive .text-wrapper-33 {
  color: #4d9f98;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 24px;
  font-weight: 600;
  height: 39px;
  left: 65px;
  letter-spacing: -0.36px;
  line-height: 33.6px;
  position: absolute;
  top: 65px;
  white-space: nowrap;
  width: 142px;
}

.captive .online-system-driven {
  color: var(--neutral-60);
  font-family: "Source Sans Pro", Helvetica;
  font-size: 12px;
  font-weight: 600;
  height: 113px;
  left: 55px;
  letter-spacing: -0.12px;
  line-height: 16.8px;
  position: absolute;
  top: 110px;
  width: 174px;
}

.captive .frame-26 {
  background-image: url(../../../static/administrative/group-28.png);
  background-size: 100% 100%;
  height: 160px;
  left: 136px;
  position: absolute;
  top: 169px;
  width: 160px;
}

.captive .add-user-group-man {
  height: 32px;
  left: 70px;
  position: absolute;
  top: 58px;
  width: 32px;
}

.captive .vector-4 {
  height: 22px;
  left: -17px;
  position: absolute;
  top: 144px;
  width: 35px;
}

.captive .frame-27 {
  height: 340px;
  left: 48px;
  position: absolute;
  top: 465px;
  width: 278px;
}

.captive .text-wrapper-34 {
  color: #4d9f98;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 24px;
  font-weight: 600;
  height: 34px;
  left: 70px;
  letter-spacing: -0.36px;
  line-height: 33.6px;
  position: absolute;
  top: 70px;
  white-space: nowrap;
}

.captive .system-driven {
  color: var(--neutral-60);
  font-family: "Source Sans Pro", Helvetica;
  font-size: 12px;
  font-weight: 600;
  height: 103px;
  left: 51px;
  letter-spacing: -0.12px;
  line-height: 16.8px;
  position: absolute;
  top: 104px;
  width: 174px;
}

.captive .frame-28 {
  background-image: url(../../../static/administrative/group-30.png);
  background-size: 100% 100%;
  height: 160px;
  left: 136px;
  position: absolute;
  top: 169px;
  width: 160px;
}

.captive .add-user-group-man-2 {
  height: 34px;
  left: 70px;
  position: absolute;
  top: 58px;
  width: 34px;
}

.captive .frame-29 {
  height: 340px;
  left: 48px;
  position: absolute;
  top: 826px;
  width: 278px;
}

.captive .group-4 {
  height: 275px;
  left: 0;
  position: absolute;
  top: 0;
  width: 275px;
}

.captive .text-wrapper-35 {
  color: #4d9f98;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 24px;
  font-weight: 600;
  height: 34px;
  left: 78px;
  letter-spacing: -0.36px;
  line-height: 33.6px;
  position: absolute;
  top: 71px;
  white-space: nowrap;
}

.captive .online-support {
  color: var(--neutral-60);
  font-family: "Source Sans Pro", Helvetica;
  font-size: 12px;
  font-weight: 600;
  height: 68px;
  left: 59px;
  letter-spacing: -0.12px;
  line-height: 16.8px;
  position: absolute;
  top: 114px;
  width: 174px;
}

.captive .customer-support {
  height: 32px;
  left: 71px;
  position: absolute;
  top: 61px;
  width: 32px;
}


.captive .logo-2 {
  height: 67.67px;
  object-fit: cover;
  position: relative;
  width: 145px;
}

.captive .holder {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16px;
  position: relative;
}

.captive .frame-30 {
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 10px 8px;
  position: relative;
  width: 90.33px;
}

.captive .text-wrapper-36 {
  color: #000000;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 17px;
  font-weight: 600;
  letter-spacing: -0.17px;
  line-height: 23.8px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.captive .text-wrapper-37 {
  color: #000000;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 17px;
  font-weight: 600;
  letter-spacing: -0.17px;
  line-height: 23.8px;
  margin-left: -2.83px;
  margin-right: -2.83px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.captive .frame-31 {
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 10px 8px;
  position: relative;
  width: 80px;
}

.captive .CTA-5 {
  border-color: var(--primary-02) !important;
  display: flex !important;
  height: 69px !important;
  width: 179px !important;
}

.captive .CTA-6 {
  color: #000000 !important;
  margin-top: unset !important;
}

.captive .carousel-2 {
  height: 796px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 3327px;
  width: 390px;
}

.captive .frame-32 {
  height: 1425px;
  left: 0;
  position: absolute;
  top: 0;
  width: 667px;
}

.captive .overlap-group-5 {
  height: 923px;
  left: -134px;
  position: relative;
  top: -24px;
  width: 687px;
}

.captive .rectangle-8 {
  height: 485px;
  left: 134px;
  position: absolute;
  top: 24px;
  width: 390px;
}

.captive .frame-33 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
  height: 261px;
  left: 214px;
  position: absolute;
  top: 166px;
}

.captive .shutterstock {
  height: 401px;
  left: 134px;
  position: absolute;
  top: 419px;
  width: 390px;
}

.captive .rectangle-9 {
  background: linear-gradient(90deg, #589993 5.95%, #4D9F98 20.62%, #4D9F98 48.33%, rgba(252, 252, 252, 0.00) 71.42%);
  filter: blur(40px);
  height: 687px;
  left: -118px;
  position: absolute;
  top: 118px;
  transform: rotate(90deg);
  width: 923px;
}

.captive .frame-34 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 33px;
  left: 150px;
  position: absolute;
  top: 106px;
}

.captive .we-are-your-growth {
  color: #ffffff;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -0.22px;
  line-height: 30.8px;
  margin-top: -1px;
  position: relative;
  width: 358px;
}

.captive .CTA-7 {
  align-items: flex-end !important;
  background-color: #2e5053 !important;
  border: unset !important;
  border-color: unset !important;
  display: flex !important;
  flex: 0 0 auto !important;
  justify-content: center !important;
  width: 127px !important;
}

.captive .CTA-8 {
  font-family: var(--subtitle-BLD-font-family) !important;
  font-size: var(--subtitle-BLD-font-size) !important;
  font-style: var(--subtitle-BLD-font-style) !important;
  font-weight: var(--subtitle-BLD-font-weight) !important;
  letter-spacing: var(--subtitle-BLD-letter-spacing) !important;
  line-height: var(--subtitle-BLD-line-height) !important;
  margin-left: -2px !important;
}

.captive .CTA-9 {
  margin-right: -2px !important;
}

.captive .frame-35 {
  height: 580px;
  left: 1440px;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 1440px;
}

.captive .rectangle-10 {
  height: 634px;
  left: 1101px;
  position: absolute;
  top: -4939px;
  width: 1383px;
}

.captive .overlap-6 {
  background: linear-gradient(180deg,
      rgb(0, 0, 0) 0%,
      rgb(7, 17, 3) 25%,
      rgb(31.88, 31.88, 31.88) 44.79%,
      rgb(46.75, 46.75, 46.75) 61.46%,
      rgba(0, 0, 0, 0) 100%);
  filter: blur(80px);
  height: 714px;
  left: -116px;
  position: absolute;
  top: -60px;
  width: 1684px;
}

.captive .frame-36 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
  left: 196px;
  position: absolute;
  top: 242px;
}

.captive .text-wrapper-38 {
  color: var(--neutral-0);
  font-family: "Source Sans Pro", Helvetica;
  font-size: 35px;
  font-weight: 600;
  letter-spacing: -0.53px;
  line-height: 49px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.captive .frame-37 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.captive .our-client-and-2 {
  color: #f6f7f8;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: -0.17px;
  line-height: 23.8px;
  margin-top: -1px;
  position: relative;
  width: 436px;
}

.captive .CTA-10 {
  all: unset;
  align-items: center;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.captive .text-wrapper-39 {
  color: var(--neutral-0);
  font-family: "Source Sans Pro", Helvetica;
  font-size: 17px;
  font-weight: 700;
  letter-spacing: -0.17px;
  line-height: 23.8px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.captive .arrow-forward-4 {
  height: 24px;
  margin-right: -674px;
  margin-top: -5311px;
  position: relative;
  width: 24px;
}

.captive .text-wrapper-40 {
  color: var(--neutral-0);
  font-family: var(--h4-REG-font-family);
  font-size: var(--h4-REG-font-size);
  font-style: var(--h4-REG-font-style);
  font-weight: var(--h4-REG-font-weight);
  height: 41px;
  left: 196px;
  letter-spacing: var(--h4-REG-letter-spacing);
  line-height: var(--h4-REG-line-height);
  position: absolute;
  top: 120px;
  white-space: nowrap;
}

.captive .frame-38 {
  align-items: flex-start;
  display: inline-flex;
  gap: 5px;
  left: 196px;
  position: absolute;
  top: 560px;
}

.captive .ellipse-9 {
  background-color: #d9d9d952;
  border-radius: 5.5px;
  height: 11px;
  position: relative;
  width: 11px;
}

.captive .ellipse-10 {
  background-color: #e9e9e9;
  border-radius: 5.5px;
  height: 11px;
  position: relative;
  width: 11px;
}

.captive .carousel-03 {
  left: 2880px !important;
  position: absolute !important;
  top: 0 !important;
}

.captive .carousel-instance {
  margin-left: -673px !important;
  margin-top: -5334px !important;
}

.captive .overlap-7 {
  position: absolute;
}

.captive .rectangle-11 {
  background-color: #f6f7f8;
  height: 264px;
  left: 6px;
  position: absolute;
  top: 0;
  width: 1440px;
  display: block;
}

.captive .rectangle-12 {
  background-color: #2c3e47;
  height: 733px;
  left: 0;
  position: absolute;
  top: 0px;
  width: 1446px;
}

.captive .rectangle-13 {
  height: 824px;
  left: 6px;
  position: absolute;
  top: 242px;
  width: 637px;
}

.captive .rectangle-14 {
  height: 824px;
  left: 869px;
  position: absolute;
  top: 219px;
  width: 577px;
}

.captive .text-wrapper-41 {
  color: #1f1f1f;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 42px;
  font-weight: 400;
  height: 50px;
  left: 257px;
  letter-spacing: -1.05px;
  line-height: 50.4px;
  position: absolute;
  text-align: center;
  top: 1050px;
  width: 906px;
}

.captive .frame-39 {
  height: 307px;
  left: 0;
  position: absolute;
  top: 0;
  width: 742px;
}

.captive .overlap-group-6 {
  height: 307px;
  position: relative;
}

.captive .rectangle-15 {
  background-color: #f6f7f8;
  height: 307px;
  left: 185px;
  position: absolute;
  top: 0;
  width: 390px;
}

.captive .frame-40 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 25px;
  left: 0;
  position: absolute;
  top: 76px;
}

.captive .frame-41 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 51px;
  position: relative;
}

.captive .frame-42 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 26px;
  position: relative;
}

.captive .frame-43 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.captive .text-wrapper-42 {
  color: var(--neutral-60);
  font-family: "Source Sans Pro", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: -0.2px;
  line-height: 28px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.captive .frame-44 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 29px;
  justify-content: center;
  position: relative;
}

.captive .mercedes-benz-logo {
  height: 37px;
  object-fit: cover;
  position: relative;
  width: 148px;
}

.captive .GE-healthcare-logo {
  height: 55px;
  object-fit: cover;
  position: relative;
  width: 98px;
}

.captive .sony-logo {
  height: 13px;
  object-fit: cover;
  position: relative;
  width: 74px;
}

.captive .shell-logo {
  height: 39px;
  object-fit: cover;
  position: relative;
  width: 69px;
}

.captive .amazon {
  height: 33px;
  object-fit: cover;
  position: relative;
  width: 70px;
}

.captive .wipro-logo-new-og {
  height: 53px;
  object-fit: cover;
  position: relative;
  width: 102px;
}

.captive .frame-45 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  position: absolute;
}

.captive .frame-46 {
  align-items: flex-end;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  position: relative;
  width: 328px;
}

.captive .frame-47 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 328px;
}

.captive .text-wrapper-43 {
  color: var(--secondary-04);
  font-family: "Source Sans Pro", Helvetica;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: -0.8px;
  line-height: 38.4px;
  margin-top: -1px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.captive .text-wrapper-44 {
  color: var(--neutral-80);
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  position: relative;
  text-align: center;
  width: 297px;
}

.captive .group-5 {
  height: 360px;
  position: relative;
  width: 390px;
}

.captive .frame-48 {
  height: 360px;
}

.captive .overlap-group-7 {
  background-color: var(--neutral-70);
  height: 360px;
  width: 1440px;
}

.captive .frame-49 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
  left: 41px;
  position: relative;
  top: 85px;
}

.captive .text-wrapper-45 {
  color: var(--neutral-0);
  font-family: "Source Sans Pro", Helvetica;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: -0.8px;
  line-height: 38.4px;
  margin-top: -1px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.captive .are-we-a-good-fit {
  color: #f6f7f8;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.2px;
  line-height: 28px;
  position: relative;
  text-align: center;
  width: 309px;
}

.captive .CTA-11 {
  cursor: pointer;
  border: none;
  outline: none;
  align-items: center;
  background-color: var(--secondary-02);
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  padding: 12px 24px;
  position: relative;
}

.captive .text-wrapper-46 {
  color: var(--neutral-0);
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.captive .arrow-forward-5 {
  height: 24px;
  position: relative;
  width: 24px;
}

.captive .frame-50 {
  flex: 0 0 auto;
  position: relative;
  width: 497.74px;
}

.captive .frame-51 {
  align-items: center;
  display: inline-flex;
  gap: 17px;
  left: 86px;
  position: absolute;
  top: 91px;
}

.captive .text-wrapper-47 {
  color: #294b51;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 42px;
  font-weight: 600;
  letter-spacing: -1.05px;
  line-height: 50.4px;
  margin-top: -1px;
  position: relative;
  width: 421px;
}

.captive .frame-52 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 61px;
  position: relative;
}

.captive .frame-53 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.captive .text-wrapper-48 {
  -webkit-text-stroke: 0.5px #2e5053;
  color: #2e5053;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 50px;
  font-weight: 400;
  letter-spacing: -0.75px;
  line-height: 60px;
  margin-left: -0.5px;
  margin-top: -1.5px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.captive .frame-54 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 74px;
  left: 220px;
  position: absolute;
  top: 512px;
}

.captive .frame-55 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 155px;
  position: relative;
}

.captive .frame-56 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  position: relative;
}

.captive .text-wrapper-49 {
  white-space: nowrap;
  color: #ffffff;
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  position: relative;
  width: 168px;
}

.captive .frame-57 {
  align-items: flex-end;
  display: flex;
  flex: 0 0 auto;
  gap: 131px;
  position: relative;
  width: 987px;
}

.captive .text-wrapper-50 {
  color: #ffffff;
  white-space: nowrap;
  font-family: var(--subtitle-MED-font-family);
  font-size: var(--subtitle-MED-font-size);
  font-style: var(--subtitle-MED-font-style);
  font-weight: var(--subtitle-MED-font-weight);
  letter-spacing: var(--subtitle-MED-letter-spacing);
  line-height: var(--subtitle-MED-line-height);
  position: relative;
  width: 212px;
}

.captive .frame-58 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  margin-right: -2px;
  position: relative;
}

.captive .joulestowatts {
  color: transparent;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 29px;
  font-weight: 600;
  height: 123px;
  left: 361px;
  letter-spacing: -0.29px;
  line-height: 40.6px;
  position: absolute;
  text-align: center;
  top: 305px;
  width: 681px;
}

.captive .overlap-8 {
  height: 454px;
  left: 0;
  position: absolute;
  top: 2207px;
  width: 1446px;
}

.captive .carousel-3 {
  height: 454px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 1446px;
}

.captive .frame-59 {
  background-size: 100% 100%;
  height: 454px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 1440px;
}

.captive .overlap-group-8 {
  height: 790px;
  left: -186px;
  position: relative;
  top: -81px;
  width: 1722px;
}

.captive .shutterstock-2 {
  height: 454px;
  left: 861px;
  position: absolute;
  top: 81px;
  width: 765px;
}

.captive .rectangle-16 {
  background: linear-gradient(90deg, #589993 5.95%, #4D9F98 20.62%, #4D9F98 48.33%, rgba(252, 252, 252, 0.00) 71.42%);
  filter: blur(40px);
  height: 790px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1722px;
}

.captive .carousel-02 {
  left: 1440px !important;
  position: absolute !important;
  top: 0 !important;
}

.captive .carousel-02-instance {
  height: 1265px !important;
  width: 2600px !important;
}

.captive .frame-60 {
  height: 634px !important;
  left: 1217px !important;
  top: 631px !important;
  width: 1383px !important;
}

.captive .frame-61 {
  margin-bottom: -199px !important;
  margin-right: -674px !important;
}

.captive .carousel-03-instance {
  margin-bottom: -176px !important;
  margin-left: -673px !important;
}

.captive .CTA-12 {
  align-items: flex-end !important;
  background-color: #2e5053 !important;
  border: unset !important;
  border-color: unset !important;
  display: flex !important;
  justify-content: center !important;
  left: 73px !important;
  position: absolute !important;
  top: 318px !important;
  width: 182px !important;
}

.captive .CTA-13 {
  font-family: var(--subtitle-BLD-font-family) !important;
  font-size: var(--subtitle-BLD-font-size) !important;
  font-style: var(--subtitle-BLD-font-style) !important;
  font-weight: var(--subtitle-BLD-font-weight) !important;
  letter-spacing: var(--subtitle-BLD-letter-spacing) !important;
  line-height: var(--subtitle-BLD-line-height) !important;
}

.captive .we-are-your-growth-2 {
  color: #ffffff;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 29px;
  font-weight: 600;
  height: 164px;
  left: 73px;
  letter-spacing: -0.29px;
  line-height: 40.6px;
  position: absolute;
  top: 80px;
  width: 587px;
}

.captive .group-6 {
  height: 380px;
  left: 0;
  position: absolute;
  top: 3011px;
  width: 1440px;
}

.captive .overlap-9 {
  height: 380px;
  position: relative;
}

.captive .frame-62 {
  background-color: #4d4d4d;
  height: 380px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1440px;
}

.captive .frame-63 {
  align-items: flex-end;
  display: inline-flex;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  left: 152px;
  position: absolute;
  top: 69px;
}

.captive .frame-64 {
  align-items: flex-end;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.captive .text-wrapper-51 {
  color: #ffffff;
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  margin-top: -1px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.captive .are-we-a-good-fit-2 {
  color: var(--neutral-10);
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  position: relative;
  text-align: right;
  width: 429px;
}

.captive .CTA-14 {
  all: unset;
  align-items: center;
  background-color: var(--secondary-04);
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 12px 16px;
  position: relative;
}

.captive .text-wrapper-52 {
  color: #ffffff;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 17px;
  font-weight: 600;
  letter-spacing: -0.17px;
  line-height: 23.8px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.captive .CTA-15 {
  cursor: pointer;
  border: none;
  outline: none;
  align-items: center;
  background-color: var(--secondary-02);
  box-sizing: border-box;
  display: inline-flex;
  gap: 8px;
  left: 819px;
  padding: 12px 24px;
  position: absolute;
  top: 267px;
}

.captive .frame-65 {
  background-color: var(--secondary-03);
  height: 380px;
  left: 0;
  position: absolute;
  top: 0;
  width: 720px;
}

.captive .overlap-group-9 {
  background-color: var(--neutral-10);
  height: 380px;
  left: 0;
  position: absolute;
  top: 0;
  width: 720px;
}

.captive .frame-66 {
  align-items: flex-end;
  display: inline-flex;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  left: 128px;
  position: relative;
  top: 49px;
}

.captive .text-wrapper-53 {
  color: var(--secondary-04);
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  margin-top: -1px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.captive .text-wrapper-54 {
  color: var(--neutral-80);
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  position: relative;
  text-align: right;
  width: 500px;
}

.captive .frame-67 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  left: 817px;
  position: absolute;
  top: 52px;
}

.captive .text-wrapper-55 {
  color: #ffffff;
  font-family: var(--h2-REG-font-family);
  font-size: var(--h2-REG-font-size);
  font-style: var(--h2-REG-font-style);
  font-weight: var(--h2-REG-font-weight);
  letter-spacing: var(--h2-REG-letter-spacing);
  line-height: var(--h2-REG-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.captive .text-wrapper-56 {
  color: var(--neutral-10);
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  position: relative;
  width: 429px;
}

.captive .frame-68 {
  height: 356px;
  left: 2px;
  position: absolute;
  top: 3391px;
  width: 1440px;
}

.captive .overlap-10 {
  height: 356px;
  position: relative;
}

.captive .section-industries {
  background-color: var(--secondary-04);
  height: 356px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1440px;
}

.captive .frame-69 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  left: 80px;
  position: relative;
  top: 75px;
}

.captive .frame-70 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
}

.captive .text-wrapper-57 {
  color: #ffffff;
  font-family: var(--h3-REG-font-family);
  font-size: var(--h3-REG-font-size);
  font-style: var(--h3-REG-font-style);
  font-weight: var(--h3-REG-font-weight);
  letter-spacing: var(--h3-REG-letter-spacing);
  line-height: var(--h3-REG-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.captive .are-we-a-good-fit-3 {
  color: var(--neutral-30);
  font-family: var(--h6-REG-font-family);
  font-size: var(--h6-REG-font-size);
  font-style: var(--h6-REG-font-style);
  font-weight: var(--h6-REG-font-weight);
  letter-spacing: var(--h6-REG-letter-spacing);
  line-height: var(--h6-REG-line-height);
  position: relative;
  width: 326px;
}

.captive .CTA-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.captive .CTA-16 {
  align-items: flex-end !important;
  background-color: #4d9f98 !important;
  border: unset !important;
  border-color: unset !important;
  display: flex !important;
  justify-content: center !important;
  width: 182px !important;
}

.captive .container {
  align-items: center;
  display: flex;
  gap: 24px;
  height: 354px;
  left: 551px;
  overflow: hidden;
  padding: 56px 0 56px 0px;
  position: absolute;
  top: 2px;
  width: 843px;
}

.captive .BFSI {
  background-image: url(../../../static/administrative/rectangle-24-2.webp);
  background-size: 100% 100%;
  height: 240px;
  position: relative;
  width: 264px;
}

.captive .text-wrapper-58 {
  color: #ffffff;
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  height: 34px;
  left: 27px;
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  position: absolute;
  top: 179px;
  white-space: nowrap;
}

.captive .div-wrapper-2 {
  background-image: url(../../../static/homepage/rectangle-23.webp);
  background-position: 50% 50%;
  background-size: cover;
  height: 240px;
  position: relative;
  width: 263px;
}

.captive .telecom {
  background-image: url(../../../static/homepage/rectangle-25-1.webp);
  background-size: 100% 100%;
  height: 240px;
  position: relative;
  width: 263px;
}

.captive .telecom-3 {
  background-image: url(../../../static/homepage/rectangle-25-2.webp);
  background-position: 50% 50%;
  background-size: cover;
  height: 240px;
  position: relative;
  width: 263px;
}

.captive .telecom-4 {
  background-image: url(../../../static/homepage/rectangle-25-3.webp);
  background-position: 50% 50%;
  background-size: cover;
  height: 240px;
  position: relative;
  width: 263px;
}

.captive .overlap-group-10 {
  height: 34px;
  left: 27px;
  position: relative;
  top: 179px;
  width: 79px;
}

.captive .text-wrapper-59 {
  color: #ffffff;
  font-family: var(--h5-REG-font-family);
  font-size: var(--h5-REG-font-size);
  font-style: var(--h5-REG-font-style);
  font-weight: var(--h5-REG-font-weight);
  height: 34px;
  left: 0;
  letter-spacing: var(--h5-REG-letter-spacing);
  line-height: var(--h5-REG-line-height);
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.captive .arrow {
  background-color: #ffffff;
  border-radius: 30px;
  box-shadow: -3px 1px 16px #00000040;
  height: 60px;
  left: 1365px;
  position: absolute;
  top: 151px;
  width: 60px;
}

.captive .arrow-forward-ios {
  height: 24px;
  left: 18px;
  position: absolute;
  top: 18px;
  width: 24px;
}

.captive .group-7 {
  height: 441px;
  left: 20px;
  position: absolute;
  top: 1741px;
  width: 1300px;
}

.captive .overlap-11 {
  height: 441px;
  position: relative;
}

.captive .frame-71 {
  height: 441px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1300px;
}

.captive .overlap-group-11 {
  background-image: url(../../../static/administrative/group-51.webp);
  background-size: 100% 100%;
  height: 386px;
  left: 70px;
  position: relative;
  width: 1231px;
}

.captive .text-wrapper-60 {
  color: #4d9f98;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 35px;
  font-weight: 600;
  height: 51px;
  left: 85px;
  letter-spacing: -0.53px;
  line-height: 49px;
  position: absolute;
  top: 134px;
  white-space: nowrap;
  width: 183px;
}

.captive .text-wrapper-61 {
  color: #4d9f98;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 35px;
  font-weight: 600;
  height: 51px;
  left: 527px;
  letter-spacing: -0.53px;
  line-height: 49px;
  position: absolute;
  top: 122px;
  white-space: nowrap;
  width: 115px;
}

.captive .text-wrapper-62 {
  color: #4d9f98;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 35px;
  font-weight: 600;
  height: 51px;
  left: 949px;
  letter-spacing: -0.53px;
  line-height: 49px;
  position: absolute;
  top: 121px;
  white-space: nowrap;
  width: 129px;
}

.captive .online-system-driven-2 {
  color: var(--neutral-60);
  font-family: var(--paragraph-MED-font-family);
  font-size: var(--paragraph-MED-font-size);
  font-style: var(--paragraph-MED-font-style);
  font-weight: var(--paragraph-MED-font-weight);
  height: 103px;
  left: 65px;
  letter-spacing: var(--paragraph-MED-letter-spacing);
  line-height: var(--paragraph-MED-line-height);
  position: absolute;
  top: 201px;
  width: 226px;
}

.captive .system-driven-2 {
  color: var(--neutral-60);
  font-family: var(--paragraph-MED-font-family);
  font-size: var(--paragraph-MED-font-size);
  font-style: var(--paragraph-MED-font-style);
  font-weight: var(--paragraph-MED-font-weight);
  height: 103px;
  left: 506px;
  letter-spacing: var(--paragraph-MED-letter-spacing);
  line-height: var(--paragraph-MED-line-height);
  position: absolute;
  top: 192px;
  width: 226px;
}

.captive .online-support-2 {
  color: var(--neutral-60);
  font-family: var(--paragraph-MED-font-family);
  font-size: var(--paragraph-MED-font-size);
  font-style: var(--paragraph-MED-font-style);
  font-weight: var(--paragraph-MED-font-weight);
  height: 83px;
  left: 928px;
  letter-spacing: var(--paragraph-MED-letter-spacing);
  line-height: var(--paragraph-MED-line-height);
  position: absolute;
  top: 189px;
  width: 226px;
}

.captive .payroll-2 {
  height: 47px;
  left: 704px;
  position: absolute;
  top: 67px;
  width: 47px;
}

.captive .customer-support-2 {
  height: 50px;
  left: 1135px;
  position: absolute;
  top: 67px;
  width: 50px;
}

.captive .add-user-group-man-3 {
  height: 43px;
  left: 348px;
  position: absolute;
  top: 71px;
  width: 43px;
}

.captive .overlap-12 {
  background-color: #f6f7f8;
  height: 350px;
  left: 0;
  position: absolute;
  top: 2661px;
  width: 1446px;
}

.captive .frame-72 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 25px;
  left: 156px;
  position: relative;
  top: 103px;
}

.captive .mercedes-benz-logo-2 {
  height: 63px;
  object-fit: cover;
  position: relative;
  width: 248px;
}

.captive .GE-healthcare-logo-2 {
  height: 92px;
  object-fit: cover;
  position: relative;
  width: 164px;
}

.captive .sony-logo-2 {
  height: 22px;
  object-fit: cover;
  position: relative;
  width: 123px;
}

.captive .shell-logo-2 {
  height: 65px;
  object-fit: cover;
  position: relative;
  width: 115px;
}

.captive .amazon-2 {
  height: 55px;
  object-fit: cover;
  position: relative;
  width: 178px;
}

.captive .wipro-logo-new-og-2 {
  height: 89px;
  object-fit: cover;
  position: relative;
  width: 171px;
}

.captive .rectangle-5-captive{
  height: 524px;
  left: 210px;
  position: absolute;
  top: 80px;
  width: 969px;
  align-self: center;
}